import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import studentAPI from "../../../../App/Apis/RidingSchoolApis/studentApi/studentAPI";
import Loader from "../../../components/Loader";
import Avtar from "../../../components/Avtar/Avtar";
import "./StudentProfileEdit.css";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import api from "../../../../Apis";

const StudentProfileEdit = () => {
  const [loading, setLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [studentData, setStudentData] = useState({
    image: null,
    first_name: null,
    last_name: null,
    date_of_birth: null,
    age: null,
    email: null,
    mobile: null,
    country_id: null,
    state: null,
    city: null,
    address: null,
    riding_experience: null,
  });

  const studentId = useParams().id;

  const GetCountries = async () => {
    try {
      const response = await api.get(`get_country`);
      setCountryData(response.data);
    } catch (error) {
      console.log("Failed to contries data");
    }
  };

  useEffect(() => {
    GetCountries();
  }, []);

  const initial = {
    image: null,
    first_name: null,
    last_name: null,
    date_of_birth: null,
    age: null,
    email: null,
    mobile: null,
    country_id: null,
    state: null,
    city: null,
    address: null,
    riding_experience: null,
  };
  const { handleChange, setFieldValue, values, handleSubmit } = useFormik({
    initialValues: initial,
    onSubmit: async (value, actions) => {
      setLoading(true);
      try {
        // const id = values.id
        delete values.id;
        delete values.image;
        const res = await studentAPI.updateStudent(values, studentId);
        setFieldValue("image", res.data?.image);
        toast.success("Student Profile Updated Successfully");
        console.log("profileDatas", value);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
  });

  // const { id } = useParams()
  const studentDatas = localStorage.getItem("student_data");
  const id = studentDatas ? JSON.parse(studentDatas).id : null;

  const uplaodAvtar = async (e) => {
    try {
      const payload = {
        id: values.id,
        image: e.target.files[0],
      };
      console.log(payload);
      const res = await studentAPI.updateStudent(payload, values.id);
      setFieldValue("image", res.data?.image);
      console.log("res", res);
    } catch (error) {
      console.log("error", error);
    }
  };
  const prefilled = async () => {
    setLoading(true);
    try {
      const studentRes = await studentAPI.getstudentById(id);
      const prefilledData = {
        id: studentRes.data.id,
        image: studentRes.data.image ? studentRes.data.image : null,
        first_name: studentRes.data.first_name
          ? studentRes.data.first_name
          : null,
        last_name: studentRes.data.last_name ? studentRes.data.last_name : null,
        date_of_birth: studentRes.data.date_of_birth
          ? studentRes.data.date_of_birth
          : null,
        age: studentRes.data.age ? studentRes.data.age : null,
        email: studentRes.data.email ? studentRes.data.email : null,
        mobile: studentRes.data.mobile ? studentRes.data.mobile : null,
        country_id: studentRes.data.country_id.id
          ? studentRes.data.country_id.id
          : null,
        state: studentRes.data.state ? studentRes.data.state : null,
        city: studentRes.data.city ? studentRes.data.city : null,
        address: studentRes.data.address ? studentRes.data.address : null,
        riding_experience: studentRes.data.riding_experience
          ? studentRes.data.riding_experience
          : null,
      };
      // console.log(prefilledData);
      Object.keys(prefilledData).map((k) => {
        setFieldValue(k, prefilledData[k]);
      });
      setStudentData(prefilledData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    prefilled();
    console.log(values);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        marginTop: "5rem",
        paddingInline: "1.5rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="content">
        <label htmlFor="image">
          <Avtar path={values.image} />
          <input
            onChange={uplaodAvtar}
            type="file"
            name="image"
            id="image"
            style={{ position: "absolute", left: "-100000000000px" }}
          />
        </label>
        {values.first_name !== null && (
          <div class="text" style={{ textTransform: "capitalize" }}>
            {values.first_name} {values.last_name}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <div className="input">
              <label className="input-label">First name</label>
              <div className="field">
                <span className="fa fa-user"></span>
                <input
                  onChange={handleChange}
                  name="first_name"
                  value={values.first_name}
                  type="text"
                  placeholder="Enter first name"
                />
              </div>
            </div>
            <div className="input">
              <label className="input-label">Last name</label>
              <div className="field">
                <span className="fa fa-user"></span>
                <input
                  onChange={handleChange}
                  name="last_name"
                  value={values.last_name}
                  type="text"
                  placeholder="Enter last name"
                />
              </div>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input">
              <label className="input-label">Date of birth</label>
              <div className="field">
                <span className="fa fa-calendar"></span>
                <input
                  onChange={handleChange}
                  name="date_of_birth"
                  value={values.date_of_birth}
                  type="date"
                />
              </div>
            </div>
            <div className="input">
              <label className="input-label">Email</label>
              <div className="field">
                <span className="fa fa-envelope"></span>
                <input
                  onChange={handleChange}
                  name="email"
                  value={values.email}
                  placeholder="Enter your email"
                  type="email"
                />
              </div>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input">
              <label className="input-label">Mobile number</label>
              <div className="field">
                <span className="fa fa-mobile"></span>
                <input
                  onChange={handleChange}
                  name="mobile"
                  value={values.mobile}
                  placeholder="Enter your mobile number"
                  type="tel"
                />
              </div>
            </div>
            <div className="input">
              <label className="input-label">Country</label>
              <div className="field">
                <span className="fa fa-globe"></span>
                <select
                  onChange={handleChange}
                  name="country_id"
                  value={values.country_id}
                  id=""
                >
                  <option value="">Select Country</option>
                  {countryData.map((c, i) => (
                    <option
                      key={i}
                      value={c.id}
                      selected={studentData.country_id === c.id}
                    >
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input">
              <label className="input-label">State</label>
              <div className="field">
                <span className="fa fa-globe"></span>
                <input
                  onChange={handleChange}
                  name="state"
                  value={values.state}
                  type="text"
                  placeholder="Enter your state"
                />
              </div>
            </div>
            <div className="input">
              <label className="input-label">Cty</label>
              <div className="field">
                <span className="fa fa-globe"></span>
                <input
                  onChange={handleChange}
                  name="city"
                  value={values.city}
                  placeholder="Enter your city"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input">
              <label className="input-label">Addres</label>
              <div className="field">
                <span className="fa fa-address-card-o"></span>
                <input
                  onChange={handleChange}
                  name="address"
                  value={values.address}
                  placeholder="Enter your address"
                  type="text"
                />
              </div>
            </div>
            <div className="input">
              <label className="input-label">Riding experience</label>
              <div className="field">
                <span className="fa fa-snowflake-o"></span>
                <input
                  onChange={handleChange}
                  name="riding_experience"
                  value={values.riding_experience}
                  placeholder="Enter your riding experience"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="icon-button">
            {/* <span class="facebook"><i class="fa fa-facebook"></i>  Facebook</span>

                        <span><i class="fa fa-google"></i>  Google</span> */}
            <button className="facebook">Update</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StudentProfileEdit;
