import React, { useEffect } from "react";
import '../../Calender2.css'
import './MonthStyle.css'
import { useDispatch, useSelector } from "react-redux";
import { DeleteEvent, GetEvents, GetUpdatingEvent } from "../../../App/Reducers/EventReducer";
import { EventFormStatus } from "../../../App/Reducers/ModalReducer";
import { useCookies } from "react-cookie";

const MonthCal = ({ date, days, dates, events, goToNextMonth, goToPreviousMonth }) => {

  const dispatch = useDispatch()
  const create = useSelector((state) => state.modal.create);
  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;


  const getCurrentDate = () => {
    const options = { weekday: "short", day: "numeric" };
    return date.toLocaleString("en-US", options);
  };

  const getCurrentMonth = () => {
    // const currentDate = new Date();
    const options = { month: "short", year: "numeric" };
    return date.toLocaleString("en-US", options);
  };
  const getmonthInnumber = () => {
    // const currentDate = new Date();
    const options = { month: "numeric", year: "numeric" };
    return date.toLocaleString("en-US", options);
  };

  //curent date
  // const current_day =() => {
  //   var d = new Date().getDate();
  //   document.getElementById(d).classList.add('today');
  // }
  // current_day()
  const current_day =(d) => {
    
    const dd = new Date().getDate();
   
    if (dd==d) {
      return (<span style={{color:'black', padding:".875rem", margin:"5px", borderRadius: "50%", width:"50px", height:"50px", background:"rgb(124, 94, 30, 0.75)", border:"0px solid #000"}}>{d}</span>)
    }
    else{
      return d

    }
    // document.getElementById(d).classList.add('today');
  }
  const x =  current_day()
  const currDate = getCurrentDate()
  const currentMonth = getCurrentMonth()
  const currentMonthInnumber = getmonthInnumber()

  useEffect(() => {
    dispatch(GetEvents(token))
  }, [dispatch])
  return (
    <div>
      <main className="card2 dashboard_month_calendar">
        <section className="card__header">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%"
            }}
          >
            <button className="calendar-btn" onClick={goToPreviousMonth}>&lt;</button>
            <div> <span style={{ fontSize: "1.5rem", fontWeight: "600" }}>{currDate}</span> &nbsp; <span style={{ fontSize: "1.5rem", fontWeight: "600" }}>{currentMonth}</span></div>
            <button className="calendar-btn" onClick={goToNextMonth}>&gt;</button>
          </div>
        </section>
        <section className="card__body">
          <ul className="card__body--days">
            {days.map((day, index) => (
              <div>
                <li key={index}>{day}</li>
              </div>
            ))}
          </ul>
          <ul className="card__body--dates">
            {dates.map((d, index) => (
              <div>
                <li
                  style={{ cursor: 'pointer'}}
                  key={index}
                  onClick={(e) => { dispatch(EventFormStatus({ "open": true, "create": !create, calDate: d, currentMonth: currentMonthInnumber })) }}
                >
                  {
                  current_day(d)
                  }

                </li>
                {events(d)}
              </div>
            ))}
          </ul>
        </section>
      </main>
    </div>
  );
};

export default MonthCal;
