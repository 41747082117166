import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Main_Horse_Profile.css";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { getHorseProfileData } from "../../App/Reducers/HorseRegistrationReducer";
import { useCookies } from "react-cookie";
import { ImgUrl } from "../../Config/Config";
import { AiOutlineCaretRight } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import StableLoading from "../../SharedComponets/Loading/StableLoading";

const Main_Horse_Profile = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const { hProfileId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const HorseData = useSelector(
    (state) => state.horseRegistration.updatedHorseProfileData
  );
  console.log("HorseData profile", HorseData);
  const [activeLink, setActiveLink] = useState("Documents");

  const handleLinkClicked = (linkName) => {
    setActiveLink(linkName);
  };

  // Date Time formate

  const formatDate = (dateString) => {
    const date = dateString ? new Date(dateString) : null;
    const formattedDate = date
      ? new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Etc/GMT",
      }).format(date)
      : null;

    return formattedDate;
  };

  useEffect(() => {
    dispatch(getHorseProfileData({ id: hProfileId, token }));
    AOS.init();
  }, []);


  return HorseData ? (
    <>
      <div className="form-wrapper-container">
        <div data-aos="fade-left" data-aos-duration="1000">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              alignItems: "center",
              paddingBlock: "30px 10px",
            }}
            className="welcome-area"
          >
            <img
              src={`${ImgUrl}${HorseData && HorseData.horse.image}`}
              alt="Horse Profile"
              style={{
                width: "3.5rem",
                height: "3.5rem",
                display: "inline-block",
                borderRadius: "50%",
              }}
            />
            <p style={{ display: "inline-block", fontWeight: "bolder" }}>
              <span style={{ fontSize: "18px" }}>
                Welcome to the extraordinary world of
              </span>{" "}
              <span style={{ fontSize: "40px", color: "#302924" }}>
                {HorseData ? HorseData.horse.name : "Horse Name"}{" "}
              </span>
            </p>
          </div>
          {/* <div style={{display:"flex", alignItems: "start", width:"100%"}}> */}
          <div className="horse-profile-container">
            <div className="profile-details-container">
              <div className="profile-details-detail">
                <div className="profile-common-layout horse-info" id="h-info">
                  <a href="#h-info" id="h-info" className="bookmark">
                    <h3>Horse Information</h3>
                  </a>
                </div>
                <div className="profile-ditails-single-data-container">
                  <div className="profile-image-side-div-top">
                    <img
                      src={`${ImgUrl}${HorseData && HorseData.horse.image}`}
                      alt="Horse Profile"
                      className="profile-image-side-top"
                    />
                  </div>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Reservation Id&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.quotation.code}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Horse Name&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.name}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Country of Birth&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.birth_origin.name}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Breed&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.breed.name}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Breeder&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.breeder_master.name}</span>
                    </li>
                  </ul>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Color&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.color.name}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Date of Birth&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.dob}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Gender&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.gender.name}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Horse Height&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.height}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Horse Weight&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.weight}</span>
                    </li>
                  </ul>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Country Of Residence&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.country_of_residence.name}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Microchip Number&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.microchip_number}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Blood Group&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.blood_group}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Special Ability&nbsp;:&nbsp;
                      </span>
                      <span>{HorseData.horse.ability}</span>
                    </li>
                  </ul>
                </div>
                <div className="data-image-container">
                  <div>
                    <div className="profile-common-layout fei-reg" id="fei-reg">
                      <a href="#fei-reg" id="fei-reg" className="bookmark">
                        <h3>FEI Registration</h3>
                      </a>
                    </div>
                    <div className="profile-ditails-single-data-container">
                      <ul style={{ listStyleType: "none" }}>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;FEI Passport Number&nbsp;:&nbsp;
                          </span>
                          <span>{HorseData.horse.fei_passport_number}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;FEI Passport Expiry Date&nbsp;:&nbsp;
                          </span>
                          <span
                            style={{
                              color:
                                new Date(
                                  HorseData.horse.fei_passport_expiry_date
                                ) < new Date()
                                  ? "#ff0000"
                                  : "black",
                            }}
                          >
                            {HorseData.horse.fei_passport_expiry_date}&nbsp;
                            <span
                              style={{
                                color:
                                  new Date(
                                    HorseData.horse.fei_passport_expiry_date
                                  ) < new Date()
                                    ? "#ff0000"
                                    : "black",
                              }}
                            >
                              {new Date(
                                HorseData.horse.fei_passport_expiry_date
                              ) < new Date()
                                ? "Expired"
                                : null}
                            </span>
                            &nbsp;
                            {new Date(
                              HorseData.horse.fei_passport_expiry_date
                            ) < new Date() && <span onClick={() => { navigate(`/dashboard/horse/registration_form/${HorseData.horse.id}#doc`) }}>
                                <FaEdit style={{ fontSize: '1.125rem', color: "#000", cursor: 'pointer' }} />
                              </span>
                            }
                          </span>
                        </li>

                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;FEI Registration Number&nbsp;:&nbsp;
                          </span>
                          <span>{HorseData.horse.fei_registration_number}</span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="profile-common-layout owner-info"
                      id="owner-info"
                    >
                      <a
                        href="#owner-info"
                        id="owner-info"
                        className="bookmark"
                      >
                        <h3>Owner Information</h3>
                      </a>
                    </div>
                    <div className="profile-ditails-single-data-container">
                      <ul style={{ listStyleType: "none" }}>
                        {HorseData &&
                          HorseData.horse.owner_id &&
                          HorseData.horse.owner_id.map((item, i) => (
                            <li key={item.id}>
                              <span style={{ fontWeight: "500" }}>
                                <AiOutlineCaretRight />
                                &nbsp;Owner {i + 1}&nbsp;:&nbsp;
                              </span>
                              <span>{item.name}</span>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div
                      className="profile-common-layout trainer-info"
                      id="trainer-info"
                    >
                      <a
                        href="#trainer-info"
                        id="trainer-info"
                        className="bookmark"
                      >
                        <h3>Trainer Information</h3>
                      </a>
                    </div>
                    <div className="profile-ditails-single-data-container">
                      <ul style={{ listStyleType: "none" }}>
                        {HorseData &&
                          HorseData.horse.trainer_id &&
                          HorseData.horse.trainer_id.map((item, i) => (
                            <li key={item.id}>
                              <span style={{ fontWeight: "500" }}>
                                <AiOutlineCaretRight />
                                &nbsp;Trainer {i + 1}&nbsp;:&nbsp;
                              </span>
                              <span>{item.name}</span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  <div className="profile-image-side-div">
                    <Link
                      to={`${ImgUrl}${HorseData.horse.image}`}
                      download
                      target="_blank"
                    >
                      {" "}
                      <img
                        src={`${ImgUrl}${HorseData && HorseData.horse.image}`}
                        alt="Horse Profile"
                        className="profile-image-side"
                      />
                    </Link>
                  </div>
                </div>
                <div className="profile-common-layout doc-info" id="doc-info">
                  <a href="#doc-info" id="doc-info" className="bookmark">
                    <h3>Other Details</h3>
                  </a>
                </div>

                <div className="horse-profile-multiple-values-container">
                  <div className="multiple-values-heading">
                    <ul>
                      <li>
                        <NavLink
                          className={
                            activeLink === "Documents"
                              ? "active-link"
                              : "inactive-link"
                          }
                          onClick={() => handleLinkClicked("Documents")}
                        >
                          Documents
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={
                            activeLink === "Planners"
                              ? "active-link"
                              : "inactive-link"
                          }
                          onClick={() => handleLinkClicked("Planners")}
                        >
                          Planners
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={
                            activeLink === "TemporaryOutpass"
                              ? "active-link"
                              : "inactive-link"
                          }
                          onClick={() => handleLinkClicked("TemporaryOutpass")}
                        >
                          Temporary OutPass
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={
                            activeLink === "TemporaryInpass"
                              ? "active-link"
                              : "inactive-link"
                          }
                          onClick={() => handleLinkClicked("TemporaryInpass")}
                        >
                          Temporary InPass
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={
                            activeLink === "Events"
                              ? "active-link"
                              : "inactive-link"
                          }
                          onClick={() => handleLinkClicked("Events")}
                        >
                          Events
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="multiple-values-details">
                    <div
                      className="profile-common-layout doc-mb-info"
                      id="doc-info"
                    >
                      <a href="#doc-info" id="doc-info" className="bookmark">
                        <h3>Documents</h3>
                      </a>
                    </div>
                    {activeLink === "Documents" && (
                      <div className="profile-ditails-single-data-container-mb">
                        <div className="health-vacc-file">
                          <div style={{ width: "100%" }}>
                            <table
                              style={{ width: "90%", margin: "30px 50px" }}
                            >
                              <thead>
                                <tr
                                  className="tableHeader  table-list"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  <th>Health File</th>
                                  <th>Vaccine File </th>
                                </tr>
                              </thead>
                              <tbody>
                                {HorseData.horse && HorseData.horse
                                  ? HorseData.horse.files.map((file) => (
                                    <tr className="table-list">
                                      <td>
                                        {" "}
                                        {HorseData.horse &&
                                          HorseData.horse.health_file ? (
                                          <Link
                                            to={`${ImgUrl}${HorseData.horse.health_file}`}
                                            download
                                            target="_blank"
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                border: "1px solid  #9f8754",
                                                borderRadius: "3px",
                                                marginBlock: "10px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontSize: "1.3rem",
                                                  color: "white",
                                                  background:
                                                    "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                                                  borderRadiusTopleft: "2px",
                                                  borderRadiusBottomleft:
                                                    "2px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  width: "36px",
                                                  height: "36px",
                                                }}
                                              >
                                                <FaFilePdf />
                                              </div>
                                              <div
                                                style={{
                                                  padding: "0px 10px",
                                                  fontSize: "0.8rem",
                                                }}
                                              >
                                                {
                                                  HorseData.horse.health_file.split(
                                                    "/media/horse_health_File/"
                                                  )[1]
                                                }
                                              </div>
                                            </div>
                                          </Link>
                                        ) : null}
                                      </td>
                                      <td>
                                        {HorseData.horse &&
                                          HorseData.horse.vaccine_file ? (
                                          <Link
                                            to={`${ImgUrl}${HorseData.horse.vaccine_file}`}
                                            download
                                            target="_blank"
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                border: "1px solid  #9f8754",
                                                borderRadius: "3px",
                                                marginBlock: "10px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontSize: "1.3rem",
                                                  color: "white",
                                                  background:
                                                    "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                                                  borderRadiusTopleft: "2px",
                                                  borderRadiusBottomleft:
                                                    "2px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  width: "36px",
                                                  height: "36px",
                                                }}
                                              >
                                                <FaFilePdf />
                                              </div>
                                              <div
                                                style={{
                                                  padding: "0px 10px",
                                                  fontSize: "0.8rem",
                                                }}
                                              >
                                                {
                                                  HorseData.horse.vaccine_file.split(
                                                    "/media/horse_vaccine_File/"
                                                  )[1]
                                                }
                                              </div>
                                            </div>
                                          </Link>
                                        ) : null}
                                      </td>
                                    </tr>
                                  ))
                                  : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div>
                          <div style={{ width: "100%" }}>
                            <table
                              style={{ width: "90%", margin: "30px 50px" }}
                            >
                              <thead>
                                <tr
                                  className="tableHeader  table-list"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  <th>Document Name</th>
                                  <th>Issue Date</th>
                                  <th>Expiry Date</th>
                                  <th>Uploaded File</th>
                                </tr>
                              </thead>
                              <tbody>
                                {HorseData.horse && HorseData.horse.files
                                  ? HorseData.horse.files.map((file) => (
                                    <tr className="table-list">
                                      <td>{file.document_type.name}</td>
                                      <td>{file.issue_date}</td>
                                      <td
                                        style={{
                                          color:
                                            new Date(file.expiry_date) <
                                              new Date()
                                              ? "#ff0000"
                                              : "black",
                                        }}
                                      >
                                        {file.expiry_date}&nbsp;{" "}

                                        {new Date(file.expiry_date) <
                                          new Date()
                                          ? "Expired"
                                          : null}
                                        {new Date(
                                          file.expiry_date
                                        ) < new Date() && <span onClick={() => { navigate(`/dashboard/horse/registration_form/${HorseData.horse.id}#doc`) }}>
                                            &nbsp;<FaEdit style={{ fontSize: '1.125rem', color: "#000", cursor: 'pointer' }} />
                                          </span>
                                        }

                                      </td>

                                      <td>
                                        <Link
                                          to={`${ImgUrl}${file.uploaded_file}`}
                                          download
                                          target="_blank"
                                          style={{ display: "inline-block" }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-start",
                                              alignItems: "center",
                                              border: "1px solid  #9f8754",
                                              borderRadius: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "1.3rem",
                                                color: "white",
                                                background:
                                                  "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                                                borderRadiusTopleft: "2px",
                                                borderRadiusBottomleft: "2px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "36px",
                                                height: "36px",
                                              }}
                                            >
                                              <FaFilePdf />
                                            </div>
                                            <div
                                              style={{
                                                padding: "0px 10px",
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              {
                                                file.uploaded_file.split(
                                                  "/media/horse_file/"
                                                )[1]
                                              }
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                                  : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="profile-common-layout planner-mb-info"
                      id="doc-info"
                    >
                      <a href="#doc-info" id="doc-info" className="bookmark">
                        <h3>Planners</h3>
                      </a>
                    </div>

                    {activeLink === "Planners" && (
                      <div style={{ width: "100%" }}>
                        <table style={{ width: "90%", margin: "30px 50px" }}>
                          <thead>
                            <tr
                              className="tableHeader  table-list"
                              style={{ textAlign: "center", color: "white" }}
                            >
                              <th>Planner Id</th>
                              <th>Horse Name</th>
                              <th>Planner Type</th>
                              <th>Vitamins Nutrients</th>
                              <th>Staff</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {HorseData.planner && HorseData.planner
                              ? HorseData.planner.map((item) => (
                                <tr className="table-list">
                                  <td>{item.code ? item.code : null}</td>
                                  <td>
                                    {item.horse_checkin.horse.name
                                      ? item.horse_checkin.horse.name
                                      : null}
                                  </td>
                                  <td>
                                    {item.planner.name
                                      ? item.planner.name
                                      : null}
                                  </td>
                                  <td>
                                    {item.vitamins_nutrients
                                      ? item.vitamins_nutrients
                                      : null}
                                  </td>
                                  <td>
                                    {item.staff
                                      ? item.staff.map((staff) => {
                                        return (
                                          <p key={staff.id}>
                                            {staff.first_name}
                                          </p>
                                        );
                                      })
                                      : null}
                                  </td>
                                  <td>
                                    {item.start_date
                                      ? formatDate(item.start_date)
                                      : null}
                                  </td>
                                  <td>
                                    {item.end_date
                                      ? formatDate(item.end_date)
                                      : null}
                                  </td>
                                </tr>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div
                      className="profile-common-layout temp-outpass-mb-info"
                      id="doc-info"
                    >
                      <a href="#doc-info" id="doc-info" className="bookmark">
                        <h3>Temporary Outpss</h3>
                      </a>
                    </div>

                    <div className="profile-ditails-single-data-container-mb  temp-outpass-mb-info">
                      <span>Work in progress...</span>
                      {/* Temporary Outpass content */}
                    </div>

                    <div
                      className="profile-common-layout temp-inpass-mb-info"
                      id="doc-info"
                    >
                      <a href="#doc-info" id="doc-info" className="bookmark">
                        <h3>Temporary Inpass</h3>
                      </a>
                    </div>

                    <div className="profile-ditails-single-data-container-mb  temp-inpass-mb-info">
                      <>
                        <span>Work in progress...</span>
                        {/* Temporary Outpass content */}
                      </>
                    </div>

                    <div
                      className="profile-common-layout events-mb-info"
                      id="doc-info"
                    >
                      <a href="#doc-info" id="doc-info" className="bookmark">
                        <h3>Events</h3>
                      </a>
                    </div>

                    <div className="profile-ditails-single-data-container-mb  events-mb-info">
                      <>
                        <span>Work in progress...</span>
                        {/* Temporary Outpass content */}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <StableLoading />
  );
};

export default Main_Horse_Profile;
