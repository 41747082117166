import "./Quotation.css";
import { TextField, Grid, Autocomplete, Button, Checkbox, Paper } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceData } from "../../App/Reducers/ServiceReducer";
import { getCountries } from "../../App/Reducers/AdminSettingReducer/CountryReducer";
import { setUpdateQuotationData } from "../../App/Reducers/QuotationReducer";
import { getData } from '../../App/Reducers/FacilityTypeReducer';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getTax } from "../../App/Reducers/AdminSettingReducer/TaxReducer";
import api from "../../Apis";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AOS from "aos";
import "aos/dist/aos.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getService } from '../../App/Apis/quotationApis'
import numeral from "numeral";
import CommonLoading from '../../SharedComponets/Loading/CommonLoading';
import StableLoading from '../../SharedComponets/Loading/StableLoading';
import AlertMessage from "../../App/Helpers/AlertMessage";
import { FetchAllService } from "../../App/Apis/Service";


const CloseQuotation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { quot_id } = useParams()

  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;

  const facilityData = useSelector((state) => state.facilityTypes.facilityData);
  // const serviceData = useSelector((state) => state.services.serviceData)
  const country = useSelector((state) => state.countries.countriesData)
  const taxData = useSelector((state) => state.tax.taxData)
  const updatedData = useSelector((state) => state.quotation.updateQuotationData)


  const paymentTerms = {
    Yearly: "Yearly - Every 365 Days",
    Half_Yearly: "Half Yearly - Every 180 Days",
    Quarterly: "Quarterly - Every 90 Days",
    Monthly: "Monthly - Every 30 Days",
    FortNight: "FortNight - Every 14 Days",
    Daily: "Daily - Every Day",
    Cash: "Cash - Paid All With First Invoice"

  }

  // console.log("keys--->",Object.keys(paymentTerms));


  const [checkoutType, setCheckoutType] = useState('Early Checkout')
  const [serviceData, setServiceData] = useState(null)
  const [IsServiceData, setIsServiceData] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isVisibilaEmail, setIsVisibleEmail] = useState(false)
  const [isVisibilaPhone, setIsVisiblePhone] = useState(false)
  const [emailResponse, setEmailResponse] = useState(null)
  const [phoneResponse, setPhoneResponse] = useState(null)
  const [emailError, setEmailError] = useState('')
  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [plannersData, setPlannersData] = useState([]);
  const [formData, setFormData] = useState({

    name: null,

    email: null,

    city: null,

    phone: null,

    country: null,

    address: null,

    payment_terms: null,

    from_date: null,

    to_date: null,

    total_days: null,

    total_horse: null,

    total_amount: null,

    total_discount: null,

    total_taxable: null,

    total_tax: null,

    total_net_amount: null,

    paid_amount: 0,
    paid_amount_formated: 0,

    balance_amount: null,



    facility: [null],

    service: [null],

    gross_price: [null],

    quantity: [null],

    amount: [null],

    discount: [0],

    taxable_amount: [null],

    tax: [null],

    net_amount: [null]

  })


  // Function to add a new row
  const addRow = () => {
    const len = formData.facility.length

    if (formData.facility[len - 1] !== null && formData.service[len - 1] !== null && formData.gross_price[len - 1] !== null && formData.quantity[len - 1] !== null && formData.amount[len - 1] !== null && formData.discount[len - 1] !== null && formData.taxable_amount[len - 1] !== null && formData.tax[len - 1] !== null && formData.net_amount[len - 1] !== null) {
      setFormData((prevData) => ({
        ...prevData,
        facility: [...prevData.facility, null],
        service: [...prevData.service, null],
        gross_price: [...prevData.gross_price, null],
        quantity: [...prevData.quantity, null],
        amount: [...prevData.amount, null],
        discount: [...prevData.discount, 0],
        taxable_amount: [...prevData.taxable_amount, null],
        tax: [...prevData.tax, null],
        net_amount: [...prevData.net_amount, null],
        // Add other fields as needed
      }));
    }
    else {
      toast.error(
        "Fill all required fields. ",
        {
          autoClose: 6000,
        }
      );
      return
    }
  }

  // Function to remove a row
  const removeRow = (e, i) => {
    const index = +e.target.id
    // console.log(typeof (index));
    setFormData((prevData) => {
      const updatedFacility = [...prevData.facility];
      const updatedService = [...prevData.service];
      const updatedGrossPrice = [...prevData.gross_price];
      const updatedQuantity = [...prevData.quantity];
      const updatedAmount = [...prevData.amount];
      const updatedDiscount = [...prevData.discount];
      const updatedTaxable_amount = [...prevData.taxable_amount];
      const updatedTax = [...prevData.tax];
      const updatedNetAmount = [...prevData.net_amount];

      updatedFacility.splice(i, 1);
      updatedService.splice(i, 1);
      updatedGrossPrice.splice(i, 1);
      updatedQuantity.splice(i, 1);
      updatedAmount.splice(i, 1);
      updatedDiscount.splice(i, 1);
      updatedTaxable_amount.splice(i, 1);
      updatedTax.splice(i, 1);
      updatedNetAmount.splice(i, 1);

      // Remove other fields as needed
      let totalAmount = 0, totalQuantity = 0, totalDiscount = 0,
        totalTaxable = 0, totalTax = 0, totalNetAmount = 0

      for (var index = 0; index < updatedQuantity.length; index++) {
        totalQuantity += +(updatedQuantity[index]);
        totalAmount += +updatedAmount[index];
        totalDiscount += (+updatedAmount[index]) - (+updatedTaxable_amount[index]);
        totalTaxable += +updatedTaxable_amount[index];
        totalTax += +updatedNetAmount[index] - +updatedTaxable_amount[index];
        totalNetAmount += +updatedNetAmount[index]
      }

      return {
        ...prevData,
        facility: updatedFacility,
        service: updatedService,
        gross_price: updatedGrossPrice,
        quantity: updatedQuantity,
        amount: updatedAmount,
        discount: updatedDiscount,
        taxable_amount: updatedTaxable_amount,
        tax: updatedTax,
        net_amount: updatedNetAmount,
        // total_horse: totalQuantity,
        total_amount: parseFloat(totalAmount).toFixed(2),
        total_discount: parseFloat(totalDiscount).toFixed(2),
        total_taxable: parseFloat(totalTaxable).toFixed(2),
        total_tax: parseFloat(totalTax).toFixed(2),
        total_net_amount: parseFloat(totalNetAmount).toFixed(2),
        balance_amount: prevData.paid_amount === null ? parseFloat(totalNetAmount).toFixed(2) : parseFloat(+totalNetAmount - (+prevData.paid_amount)).toFixed(2),
        // Update other fields
      };
    });
  };

  const handleEmailChange = (e) => {
    const { name, value, } = e.target;
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (value.match(validRegex)) {
      setEmailError('')
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setEmailResponse('');
      setPhoneResponse('');
    } else {
      setEmailError("Invalid email address!");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: null,
      }));

    }
  }

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'to_date') {
      const fromDate = new Date(formData.from_date);
      const toDays = new Date(value)
      const totalDays = (toDays - fromDate) / (1000 * 60 * 60 * 24);

      setFormData((prevFormData) => ({
        ...prevFormData,
        to_date: value,
        total_days: totalDays,
      }));
    }
    else if (name === 'paid_amount') {

      const TotalNetAmount = formData.total_net_amount;
      const parsedValue = value ? parseFloat(value) : value; // Parse the value
      const balanceAmount = parseFloat(TotalNetAmount) - parsedValue

      setFormData((prevFormData) => ({
        ...prevFormData,
        paid_amount: parsedValue,
        balance_amount: balanceAmount.toFixed(2),

      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  }


  const getServicePlanners = (serviceIds) => {
    console.log("serviceData", serviceData);


    for (const serviceId of serviceIds) {
      try {
        const planners = serviceData.filter(item => item.service.id === serviceId)
          .map(item => { return { price: item.price, id: item.planner.id, name: item.planner.name } });
        console.log("planners", planners);
        setPlannersData((prevFormData) => ([
          ...prevFormData,
          { service: serviceId, planner: planners },
        ]));

      } catch (error) {
        console.error(`Error fetching planners for service ID ${serviceId}: ${error}`);
      }

    }
  }
  function sumPlannerPrices(data) {
    let totalPrice = 0;
    console.log('sumPlannerPrices', data);
    // console.log(serviceNumber);
    for (const plannerItem of data) {

      totalPrice += parseFloat(plannerItem.price);
    }

    return totalPrice.toFixed(2); // Convert the total price to a string with 2 decimal places
  }


  const NumberFormater = (amount) => {
    return numeral(amount).format('0,0')
  }

  const handleAutoComplete = async (event, value, fieldName, index) => {
    console.log(value);
    console.log(index);
    if (value === null) {
      if (fieldName === "facility") {

        setFormData((prevFormData) => ({
          ...prevFormData,
          ["facility"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["service"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["gross_price"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["quantity"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["discount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["taxable_amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["tax"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["net_amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          total_amount: null,
        }));

      }
      else if (fieldName === "service") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ["service"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["gross_price"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["quantity"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["discount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["taxable_amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["tax"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["net_amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          total_amount: null
        }));
      }
      else if (fieldName === "quantity") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ["quantity"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["discount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["taxable_amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["tax"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["net_amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          total_amount: null
        }));
      }
      else if (fieldName === "discount") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ["discount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["taxable_amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["tax"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["net_amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
        }));
      }
      else if (fieldName === "tax") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ["tax"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
          ["net_amount"]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
        }));
      }
      else {
        // setFormData((prevFormData) => ({
        //   ...prevFormData,
        //   [fieldName]: [...prevFormData[fieldName].slice(0, index), null, ...prevFormData[fieldName].slice(index + 1)],
        // }));
        const selectedValue = value === null ? null : value
        setFormData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: selectedValue,
        }));

      }


    } else {
      if (fieldName === "facility") {

        getService(value.id, token, index)
          .then((res) => {
            const sdata = res.map((item) => {
              return { id: item.id, name: item.name }
            })
            setServiceTypeData((prevData) => {
              const optionData = [...prevData]
              optionData[index] = sdata
              return optionData;
            })
            console.log("serviceTypeData", serviceTypeData);
          })
          .catch((error) => {
            console.log("error", error);
          })



        // Handle facility autocomplete logic
        const selectedValue = value === null ? null : value.id;
        if (value !== null) {
          setFormData((prevData) => {
            const updatedFacility = [...prevData.facility];
            console.log(updatedFacility);
            updatedFacility[index] = selectedValue;

            return {
              ...prevData,
              facility: updatedFacility,
            };
          });
        }
        else {
          setFormData((prevData) => {
            const updatedFacility = [...prevData.facility];
            console.log(updatedFacility);
            updatedFacility[index] = null;

            return {
              ...prevData,
              facility: updatedFacility,
              // facility: [...prevData.facility[index], null],
              service: [...prevData.service[index], null],
              gross_price: [...prevData.gross_price[index], null],
              quantity: [...prevData.quantity[index], null],
              amount: [...prevData.amount[index], null],
              discount: [...prevData.discount[index], null],
              taxable_amount: [...prevData.taxable_amount[index], null],
              tax: [...prevData.tax[index], null],
              net_amount: [...prevData.net_amount[index], null],
            };
          });
        }
        // ...
      } else if (fieldName === "service") {
        // Handle service autocomplete logic
        const selectedValue = value.id === null ? null : value.id
        const planners = serviceData.filter(item => item.service.id === value.id)
          .map(item => { return { price: item.price, id: item.planner.id, name: item.planner.name } });
        console.log("planners", planners);
        setPlannersData((prevFormData) => ([
          ...prevFormData,
          { service: selectedValue, planner: planners },
        ]));

        const totalAmount = sumPlannerPrices(planners)

        setFormData((prevData) => {
          const updatedService = [...prevData.service];
          const updatedGrossPrice = [...prevData.gross_price];
          // console.log(updatedService);
          // console.log(updatedGrossPrice);
          updatedService[index] = selectedValue;
          updatedGrossPrice[index] = totalAmount;

          return {
            ...prevData,
            service: updatedService,
            gross_price: updatedGrossPrice,
          };
        });
        // ...
      } else if (fieldName === "quantity") {

        const selectedValue = value === null || value === "" ? null : value
        console.log(selectedValue);
        const amount = (+formData.gross_price[index] * +value) * +formData.total_days
        const taxableAmount = formData.discount[index] === null ? amount : amount - amount * (+formData.discount[index]) / 100
        const netAmount = formData.tax === null ? taxableAmount : taxableAmount + taxableAmount * (+formData.tax[index]) / 100

        setFormData((prevData) => {
          const updatedQuantity = [...prevData.quantity];
          const updatedAmount = [...prevData.amount];
          const updatedTaxableAmount = [...prevData.taxable_amount];
          const updatedNetAmount = [...prevData.net_amount];

          updatedQuantity[index] = selectedValue;
          updatedAmount[index] = parseFloat(amount).toFixed(2);
          updatedTaxableAmount[index] = parseFloat(taxableAmount).toFixed(2)
          updatedNetAmount[index] = parseFloat(netAmount).toFixed(2)

          let totalAmount = 0, totalQuantity = 0, totalDiscount = 0,
            totalTaxable = 0, totalTax = 0, totalNetAmount = 0

          for (var i = 0; i < updatedQuantity.length; i++) {
            totalQuantity += +(updatedQuantity[i]);
            totalAmount += +updatedAmount[i];
            totalDiscount += (+updatedAmount[i]) - (+updatedTaxableAmount[i]);
            totalTaxable += +updatedTaxableAmount[i];
            totalTax += +updatedNetAmount[i] - +updatedTaxableAmount[i];
            totalNetAmount += +updatedNetAmount[i]
          }

          return {
            ...prevData,
            quantity: updatedQuantity,
            amount: updatedAmount,
            taxable_amount: updatedTaxableAmount,
            net_amount: updatedNetAmount,
            total_horse: totalQuantity,
            total_amount: parseFloat(totalAmount).toFixed(2),
            total_discount: parseFloat(totalDiscount).toFixed(2),
            total_taxable: parseFloat(totalTaxable).toFixed(2),
            total_tax: parseFloat(totalTax).toFixed(2),
            total_net_amount: parseFloat(totalNetAmount).toFixed(2),
            balance_amount: prevData.paid_amount === null ? parseFloat(totalNetAmount).toFixed(2) : parseFloat(+totalNetAmount - (+prevData.paid_amount)).toFixed(2),
          };
        });
      } else if (fieldName === "tax") {
        console.log("fieldName", fieldName);
        console.log("value", value);
        const selectedValue = value.id === null || value.id === "" ? null : value.id
        const selectedRate = value.rate === null || value.rate === "" ? 0 : value.rate

        const amount = ((+formData.gross_price[index] * +formData.quantity[index]) * +formData.total_days)
        const taxableAmount = formData.discount[index] === null ? amount : amount - amount * (+formData.discount[index]) / 100
        const netAmount = selectedRate === null ? taxableAmount : taxableAmount + taxableAmount * (+selectedRate) / 100


        setFormData((prevData) => {
          const updatedTax = [...prevData.tax];
          const updatedQuantity = [...prevData.quantity];
          const updatedAmount = [...prevData.amount];
          const updatedTaxableAmount = [...prevData.taxable_amount];
          const updatedNetAmount = [...prevData.net_amount];


          updatedTax[index] = selectedValue;
          updatedAmount[index] = parseFloat(amount).toFixed(2);
          updatedTaxableAmount[index] = parseFloat(taxableAmount).toFixed(2)
          updatedNetAmount[index] = parseFloat(netAmount).toFixed(2)

          let totalAmount = 0, totalQuantity = 0, totalDiscount = 0,
            totalTaxable = 0, totalTax = 0, totalNetAmount = 0

          for (var i = 0; i < updatedQuantity.length; i++) {
            totalQuantity += +(updatedQuantity[i]);
            totalAmount += +updatedAmount[i];
            totalDiscount += (+updatedAmount[i]) - (+updatedTaxableAmount[i]);
            totalTaxable += +updatedTaxableAmount[i];
            totalTax += +updatedNetAmount[i] - +updatedTaxableAmount[i];
            totalNetAmount += +updatedNetAmount[i]
          }

          return {
            ...prevData,
            tax: updatedTax,
            amount: updatedAmount,
            taxable_amount: updatedTaxableAmount,
            net_amount: updatedNetAmount,
            total_horse: totalQuantity,
            total_amount: parseFloat(totalAmount).toFixed(2),
            total_discount: parseFloat(totalDiscount).toFixed(2),
            total_taxable: parseFloat(totalTaxable).toFixed(2),
            total_tax: parseFloat(totalTax).toFixed(2),
            total_net_amount: parseFloat(totalNetAmount).toFixed(2),
            balance_amount: prevData.paid_amount === null ? parseFloat(totalNetAmount).toFixed(2) : parseFloat(+totalNetAmount - (+prevData.paid_amount)).toFixed(2),
          };
        });
      } else if (fieldName === "discount") {

        const selectedValue = value === null || value === "" ? null : value

        // const TaxableAmount = +formData.amount[index] - (+formData.amount[index] * (+value) / 100)
        // console.log("TaxableAmount", TaxableAmount);
        const amount = (+formData.gross_price[index] * +formData.quantity[index]) * +formData.total_days
        const taxableAmount = selectedValue === null ? amount : amount - amount * (+selectedValue) / 100
        const netAmount = formData.tax === null ? taxableAmount : taxableAmount + taxableAmount * (+formData.tax[index]) / 100



        setFormData((prevData) => {
          const updatedDiscount = [...prevData.discount];
          const updatedQuantity = [...prevData.quantity];
          const updatedAmount = [...prevData.amount];
          const updatedTaxableAmount = [...prevData.taxable_amount];
          const updatedNetAmount = [...prevData.net_amount];

          updatedDiscount[index] = selectedValue;
          updatedAmount[index] = parseFloat(amount).toFixed(2);
          updatedTaxableAmount[index] = parseFloat(taxableAmount).toFixed(2)
          updatedNetAmount[index] = parseFloat(netAmount).toFixed(2)

          let totalAmount = 0, totalQuantity = 0, totalDiscount = 0,
            totalTaxable = 0, totalTax = 0, totalNetAmount = 0

          for (var i = 0; i < updatedQuantity.length; i++) {
            totalQuantity += +(updatedQuantity[i]);
            totalAmount += +updatedAmount[i];
            totalDiscount += (+updatedAmount[i]) - (+updatedTaxableAmount[i]);
            totalTaxable += +updatedTaxableAmount[i];
            totalTax += +updatedNetAmount[i] - +updatedTaxableAmount[i];
            totalNetAmount += +updatedNetAmount[i]
          }

          return {
            ...prevData,
            discount: updatedDiscount,
            amount: updatedAmount,
            taxable_amount: updatedTaxableAmount,
            net_amount: updatedNetAmount,
            total_horse: totalQuantity,
            total_amount: parseFloat(totalAmount).toFixed(2),
            total_discount: parseFloat(totalDiscount).toFixed(2),
            total_taxable: parseFloat(totalTaxable).toFixed(2),
            total_tax: parseFloat(totalTax).toFixed(2),
            total_net_amount: parseFloat(totalNetAmount).toFixed(2),
            balance_amount: prevData.paid_amount === null ? parseFloat(totalNetAmount).toFixed(2) : parseFloat(+totalNetAmount - (+prevData.paid_amount)).toFixed(2),
          };
        });
      }
      else if (fieldName === "country") {
        const selectedValue = value === null ? null : value.id
        setFormData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: selectedValue,
        }));
      }
      else if (fieldName === "payment_terms") {
        const selectedValue = value === null ? null : value
        const selectedKey = Object.keys(paymentTerms).find(
          (key) => paymentTerms[key] === value
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: selectedKey,
        }));
      }
    }

  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (quot_id) {
      setLoading(true)
      try {
        const response = await api.put(`update_quotation/${quot_id}`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        })
        if (response.statusText === "OK" || response.status === "200") {
          setLoading(false)
          navigate('/dashboard/quotation/quotation_list')
          toast.success("Quotation updated successfully")

        }
      } catch (error) {
        if (error.response.data.email) {
          setEmailResponse(error.response.data.email[0])
          window.scrollTo(0, 0); // Scroll to the top of the page
        }
        if (error.response.data.phone) {
          setPhoneResponse(error.response.data.phone[0])
          window.scrollTo(0, 0); // Scroll to the top of the page
        }
      }
    }
    else {
      setLoading(true)
      try {
        const response = await api.post("create_quotation", formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          }
        })
        if (response.statusText === "Created" || response.status === "201") {
          setLoading(false)
          navigate('/dashboard/quotation/quotation_list')
          toast.success("Quotation created successfully")
        }

      } catch (error) {
        if (error.response.data.email) {
          setIsVisibleEmail(true)
          setEmailResponse(error.response.data.email[0])
        }
        if (error.response.data.phone) {
          setIsVisiblePhone(true)
          setPhoneResponse(error.response.data.phone[0])
        }
        setTimeout(() => {
          setIsVisibleEmail(false);
          setIsVisiblePhone(false);
        }, 3000);
      }
    }
  }

  async function fetchServiceTypesForFacilities(facilityIds) {
    const serviceTypes = [];

    for (const facilityId of facilityIds) {
      try {
        const serviceType = await getService(facilityId, token); // Replace with your API call or data fetching logic
        serviceTypes.push(serviceType);
      } catch (error) {
        console.error(`Error fetching service type for facility ID ${facilityId}: ${error.message}`);
      }
    }
    console.log('serviceTypes', serviceTypes);
    setServiceTypeData(serviceTypes)
  }

  const PreFilledData = async (id, serviceData) => {
    try {
      setIsLoading(true)
      const response = await api.get(`get_quotation/${id}`, {
        headers: {
          Authorization: `token ${token}`
        }
      });
      // Assuming the response data contains the trainer's information
      const updatedReservationData = response.data;
      const facilityIdArray = updatedReservationData && updatedReservationData.quotation_header.map((item, index) => item.facility.id);
      await fetchServiceTypesForFacilities(facilityIdArray)
      console.log("updatedReservationData.quotation_header", updatedReservationData.quotation_header);
      const ServiceIdArray = updatedReservationData && updatedReservationData.quotation_header.map((item) => item.service.id ? item.service.id : null)
      console.log('serviceData>>>>>', serviceData);
      getServicePlanners(ServiceIdArray)


      setFormData(
        {

          name: updatedReservationData && updatedReservationData.name ? updatedReservationData.name : null,

          email: updatedReservationData && updatedReservationData.email ? updatedReservationData.email : null,

          city: updatedReservationData && updatedReservationData.city ? updatedReservationData.city : null,

          phone: updatedReservationData && updatedReservationData.phone ? updatedReservationData.phone : null,

          country: updatedReservationData && updatedReservationData.country && updatedReservationData.country.id ? updatedReservationData.country.id : null,

          address: updatedReservationData && updatedReservationData.address ? updatedReservationData.address : null,

          payment_terms: updatedReservationData && updatedReservationData.payment_terms ? updatedReservationData.payment_terms : null,

          from_date: updatedReservationData && updatedReservationData.from_date ? updatedReservationData.from_date : null,

          to_date: updatedReservationData && updatedReservationData.to_date ? updatedReservationData.to_date : null,

          total_days: updatedReservationData && updatedReservationData.total_days ? updatedReservationData.total_days : null,

          total_horse: updatedReservationData && updatedReservationData.total_horse ? updatedReservationData.total_horse : null,

          total_amount: updatedReservationData && updatedReservationData.total_amount ? updatedReservationData.total_amount : null,

          total_discount: updatedReservationData && updatedReservationData.total_discount ? updatedReservationData.total_discount : null,

          total_taxable: updatedReservationData && updatedReservationData.total_taxable ? updatedReservationData.total_taxable : null,

          total_tax: updatedReservationData && updatedReservationData.total_tax ? updatedReservationData.total_tax : null,

          total_net_amount: updatedReservationData && updatedReservationData.total_net_amount ? updatedReservationData.total_net_amount : null,

          paid_amount: updatedReservationData && updatedReservationData.paid_amount ? updatedReservationData.paid_amount : null,

          balance_amount: updatedReservationData && updatedReservationData.balance_amount ? updatedReservationData.balance_amount : null,

          facility: facilityIdArray,

          service: ServiceIdArray,

          gross_price: updatedReservationData && updatedReservationData.quotation_header.map((item) => item.gross_price ? item.gross_price : null),

          quantity: updatedReservationData && updatedReservationData.quotation_header.map((item) => item.quantity ? item.quantity : null),

          amount: updatedReservationData && updatedReservationData.quotation_header.map((item) => item.amount ? item.amount : null),

          discount: updatedReservationData && updatedReservationData.quotation_header.map((item) => item.discount ? item.discount : null),

          taxable_amount: updatedReservationData && updatedReservationData.quotation_header.map((item) => item.taxable_amount ? item.taxable_amount : null),

          tax: updatedReservationData && updatedReservationData.quotation_header.map((item) => item.tax.id ? item.tax.id : null),

          net_amount: updatedReservationData && updatedReservationData.quotation_header.map((item) => item.net_amount ? item.net_amount : null),
        }
      )
    } catch (error) {
      console.error(error);

    } finally {
      setIsLoading(false)
    }
  }


  useEffect(() => {
    const getAllService = async () => {
      try {
        const res = await FetchAllService(token)
        console.log("Service::res", res);
        setServiceData(res.data)
        if (res.status === 200) {

          setIsServiceData(true)
        }
      } catch (error) {
        console.error(`Error fetching all service`)
      }

    }
    getAllService()
  }, [])

  useEffect(() => {
    dispatch(getData(token));
    // dispatch(getServiceData(token));
    dispatch(getCountries(token));
    dispatch(getTax(token))
    AOS.init();
  }, [dispatch, updatedData, token]);

  useEffect(() => {

    // console.log(formData);
    let updateReservationId = null
    try {
      updateReservationId = location.pathname.split('/')[4]
    }
    catch {
    }
    if (updateReservationId) {
      console.log('serviceData>>>>>', serviceData);
      PreFilledData(updateReservationId, serviceData)
      console.log("plannerData", plannersData);
    }

  }, [IsServiceData])

  console.log("plannersData", plannersData);

  return (

    <>
      <div style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}>  <AlertMessage message={emailResponse} isVisibila={isVisibilaEmail} severity="error" />
        <br />
        <AlertMessage message={phoneResponse} isVisibila={isVisibilaPhone} severity="error" /></div>

      {isLoading ?
        <StableLoading /> : <div className="form-wrapper-container">

          <div className="registration-card" data-aos="fade-left" data-aos-duration="1000" >

            <header>
              <div className="registration_top_header">
                <p>{quot_id ? (<p> Update Reservation</p>) : (<p>Create Reservation</p>)}</p>
                <span> Please fill in all  fields, attach/upload all supporting documents to proceed with the registration.</span>
              </div>
            </header>

            <form onSubmit={handleSubmit}>
              <div className=" q-date quotationregistrationh3">
                <a href="#q-date" id="q-date" className="bookmark">
                  <h3>Date</h3>
                </a>
              </div>

              <div className="date-container">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      FROM DATE  <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      className="bg-color"
                      type="date"
                      name="from_date"
                      value={formData.from_date || ''}
                      onChange={handleChange}
                      required
                      disabled={true}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      TO DATE <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      className="bg-color"
                      type="date"
                      name="to_date"
                      value={formData.to_date || ''}
                      onChange={handleChange}
                      required
                      disabled={true}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} >
                    <label>
                      DAYS TO STAY
                    </label>
                    <TextField
                      className="inputfield not-allowed"
                      value={formData.total_days}
                      fullWidth
                      name="total_net_amount"
                      disabled

                      sx={{
                        marginTop: "8px", // Add top margin of 8 pixels
                        '& input': {
                          cursor: 'no-drop', // Apply the cursor style to the input field
                        }
                      }}

                    />
                  </Grid>
                </Grid>
              </div>

              <div className=" quotation_info quotationregistrationh3">
                <a href="#quotationinfo" id="quotationinfo" className="bookmark">
                  <h3>Reservation Information</h3>
                </a>
              </div>

              <div style={{ paddingBottom: "30px" }} className="inputparts">
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      CUSTOMER NAME <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      type="text"
                      className="inputfield bg-color"
                      name="name"
                      value={formData.name || ''}
                      onChange={handleChange}
                      required
                      disabled={true}
                      fullWidth
                      placeholder="Customer Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      EMAIL <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      type="email"
                      className="inputfield bg-color"
                      name="email"
                      value={formData.email}
                      onChange={handleEmailChange}
                      required
                      disabled={true}
                      fullWidth
                      placeholder="username@example.com"
                    />
                    <p style={{ color: 'red' }}>{emailError ? emailError : emailResponse}</p>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      MOBILE NO.<span style={{ color: "red" }}>*</span>
                    </label>

                    <TextField
                      className="inputfield bg-color"
                      name="phone"
                      type="number"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      disabled={true}
                      fullWidth
                      placeholder="Mobile Number"
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                          display: "none",
                        },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}

                    />
                    <p style={{ color: 'red' }}>{phoneResponse}</p>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      COUNTRY <span style={{ color: "red" }}>*</span>
                    </label>
                    {country && (
                      <Autocomplete
                        disableClearable
                        name="country"
                        value={formData.country && country && country.find((item) => item.id === formData.country)}
                        onChange={(event, value) => handleAutoComplete(event, value, "country")}
                        disablePortal
                        disabled={true}
                        id="country"
                        options={country}
                        getOptionLabel={(option) => option.name}
                        sx={{ color: "#302924", }}
                        renderInput={(params) => (
                          <TextField
                            required
                            className="bg-color"
                            placeholder="Select Country"
                            {...params}
                          />
                        )}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      CITY <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      className="inputfield bg-color"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                      disabled={true}
                      fullWidth
                      placeholder="City"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      ADDRESS <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      className="inputfield bg-color"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                      disabled={true}
                      fullWidth
                      placeholder="Address"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      PAYMENT TERMS <span style={{ color: "red" }}>*</span>
                    </label>
                    <Autocomplete
                      disableClearable
                      value={paymentTerms[formData.payment_terms]}
                      name="payment_terms"
                      onChange={(event, value) => handleAutoComplete(event, value, "payment_terms")}
                      disablePortal
                      disabled={true}
                      id="combo-box-demo"
                      options={Object.values(paymentTerms)}
                      sx={{ color: "#302924", }}
                      renderInput={(params) => (
                        <TextField
                          required
                          className="bg-color"
                          placeholder="Payment Terms"
                          {...params}
                        />
                      )}
                    />

                  </Grid>

                </Grid>
              </div>

              <Grid item xs={12} sm={12} md={12}>
                <div className=" quotation_service quotationregistrationh3 service-addbtn">
                  <a href="#services" id="services" className="bookmark">
                    <h3>Services</h3>
                  </a>
                  <span className="add-remove-btns">
                    {/* <Button className="addRow" onClick={(e) => addRow(e)}><span>Add</span> <AddIcon /></Button> */}
                    {/* <Button title="Add Fields" onClick={(e) => addRow(e)} style={{ backgroundColor: "#826527", color: "white", fontSize: "12px", float: "right", marginBlock: "10px" }}><AddIcon style={{ fontSize: "15px", fontWeight: "bold" }} />&nbsp;ADD</Button> */}
                    {/* <span className="addRow" onClick={(e) => removeRow(e)}><span>Delete</span><DeleteIcon /></span> */}
                    {/* <Button onClick={(e) => addRow(e)} variant="contained" className="btn-bgColor">+</Button>
       <Button onClick={(e) => removeRow(e)} variant="contained" className="btn-bgColor">-</Button> */}
                  </span>
                </div>
                <div className="table-container">
                  <table >
                    <thead>
                      <tr className="tableHeader  table-list" >
                        <th style={{ width: "30vw" }} >Facility Type</th>
                        <th style={{ width: "30vw" }}>Services</th>
                        <th style={{ width: "15vw" }}>Gross Amount</th>
                        <th style={{ width: "15vw" }} title="Unit of Measurement">UoM</th>
                        <th style={{ width: "5vw" }}>Quantity</th>
                        <th style={{ width: "15vw" }}>Amount</th>
                        <th style={{ width: "5vw" }}>Discount(%)</th>
                        <th style={{ width: "15vw" }}>Taxable Amount</th>
                        <th style={{ width: "5vw" }}>Tax</th>
                        <th style={{ width: "15vw" }}>Net Amount</th>
                        {/* <th style={{ width: "20vw" }}>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {formData.facility.map((facilityItem, i) => (
                        <tr key={i} className="tableBody table-list">
                          <td>
                            <Autocomplete
                              name={`facility-${i}`}
                              // Implement Autocomplete props here
                              // Example: value={formData.facility[i]}
                              // onChange={(event, value) => handleAutoComplete(event, value, "facility", i)}
                              required
                              disabled={true}
                              id="facility"
                              options={facilityData}
                              getOptionLabel={(option) => option.name}
                              value={formData.facility[i] && facilityData && facilityData.find(
                                (item) => item.id === Number(formData.facility[i])
                              )}
                              onChange={(event, value) =>
                                handleAutoComplete(event, value, "facility", i)
                              }
                              // sx={{ width: 300 }}
                              sx={{ width: "10vw", color: "#302924", fontWeight: "600", marginBottom: "10px" }}
                              renderInput={(params) => <TextField {...params} required placeholder="Select Facility Type" />}

                            />
                          </td>
                          <td>
                            <Autocomplete
                              name={`service-${i}`}
                              // Implement Autocomplete props here
                              // Example: value={formData.service[i]}
                              // onChange={(event, value) => handleAutoComplete(event, value, "service", i)}

                              id="combo-box-demo"
                              options={serviceTypeData[i] !== undefined ? serviceTypeData[i] : [{ id: null, name: 'No service data' }]}
                              getOptionLabel={(option) => option.name}
                              value={formData.service[i] && serviceTypeData.length > 0 && serviceTypeData[i] !== undefined && serviceTypeData[i].find(
                                (item) => item.id === Number(formData.service[i])
                              )}
                              disabled={true}
                              onChange={(event, value) =>
                                handleAutoComplete(event, value, "service", i)
                              }
                              // sx={{ width: 300 }}
                              sx={{ width: "10vw", color: "#302924", fontWeight: "600", marginBottom: "10px" }}
                              renderInput={(params) => <TextField {...params} required placeholder="Select Services" />}


                            />
                          </td>
                          <td className="gross-price" style={{ width: "50px" }}>
                            {NumberFormater(formData.gross_price[i])}
                            {
                              formData.service[i] && plannersData && (<Paper className="planner-container" elevation={16}>
                                <div className="planner-content">
                                  <div className="planner-heading">Planners</div>
                                  {/* {JSON.stringify(plannersData)} */}
                                  <hr />
                                  <ul className="planner-body">
                                    {plannersData.map((item) => {
                                      return (
                                        item.service === formData.service[i] && item.planner.map((plannerItem) => (
                                          <>
                                            <li className="planner-list" key={plannerItem.id}>
                                              <div className="planner-name">{plannerItem.name}</div>
                                              <div className="planner-price"> {NumberFormater(plannerItem.price)}</div>
                                            </li>
                                          </>
                                        ))
                                      )
                                    })}
                                  </ul>
                                  <div className="planner-footer">
                                    <div>
                                      Total
                                    </div>
                                    <div>
                                      {NumberFormater(formData.gross_price[i])}
                                    </div>
                                  </div>
                                </div>
                              </Paper>)
                            }
                          </td>

                          <td>Day</td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              id={`quantity-${i}`}
                              value={formData.quantity[i]}
                              placeholder="Ex: 5"
                              name={`quantity-${i}`}
                              onChange={(e) => { handleAutoComplete(e, e.target.value, "quantity", i) }} // Attach the handleChange function to quantity input
                              required
                              disabled={true}
                              style={{ width: "7vw", padding: "14px 5px", borderRadius: "12px", border: "1px solid #dddddd" }}
                            />

                          </td>
                          <td>{NumberFormater(formData.amount[i])}</td>
                          <td>
                            <input type="number"
                              min={0}
                              id={`discount-${i}`}
                              value={formData.discount[i]}
                              placeholder="eg. 10"
                              name={`discount-${i}`}
                              disabled={true}
                              onChange={(e) => { handleAutoComplete(e, e.target.value, "discount", i) }}
                              style={{ width: "7vw", padding: "14px 5px", borderRadius: "12px", border: "1px solid #dddddd" }} />
                          </td>
                          <td>{NumberFormater(formData.taxable_amount[i])}</td>
                          <td>
                            <Autocomplete
                              name={`tax-${i}`}
                              // Implement Autocomplete props here
                              // Example: value={formData.facility[i]}
                              // onChange={(event, value) => handleAutoComplete(event, value, "facility", i)}
                              disabled={true}
                              sx={{ width: "10vw", color: "#302924", fontWeight: "600", marginBottom: "10px" }}
                              value={formData.tax[i] && taxData && taxData.find((item) => item.id === formData.tax[i])}
                              onChange={(event, value) =>
                                handleAutoComplete(event, value, "tax", i)
                              }
                              disablePortal
                              id="combo-box-demo"
                              options={taxData}
                              getOptionLabel={(option) => `${option.name}@${option.rate}`} // Replace "name" with the property that holds the name of the nationality
                              renderInput={(params) => (
                                <TextField
                                  required
                                  className="bg-color"
                                  placeholder="Select tax"
                                  {...params}
                                />
                              )}
                            />
                          </td>
                          <td>{NumberFormater(formData.net_amount[i])}</td>
                          {/* <td><span className="removeRow" onClick={(e) => removeRow(e, i)}><span id={i}></span><DeleteIcon /></span></td> */}

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </Grid>

              <Grid xs={12} sm={12} md={12} sx={{ marginBlock: '4rem', display: "flex", justifyContent: "flex-end", textAlign: "right" }}>
                <Grid md={4}>

                  <Grid item xs={12} sm={12} md={12} >
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center", textAlign: "center" }}>

                      <label style={{
                        flex: '1',
                        textAlign: 'right',
                        marginRight: '100px'
                      }}>
                        TOTAL AMOUNT :
                      </label>
                      <TextField
                        className="inputfield"
                        value={NumberFormater(formData.total_amount)}
                        id="total_amounts"
                        onChange={handleChange}
                        fullWidth
                        name="total_amount"
                        disabled
                        sx={{
                          width: "150px",
                          textAlign: "end",
                          border: "none"
                        }}
                      />
                    </div>
                  </Grid>
                  &nbsp;
                  <Grid item xs={12} sm={12} md={12} >
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center" }}>

                      <label style={{ textAlign: "right" }}>
                        TOTAL DISCOUNT :
                      </label>
                      <TextField
                        className="inputfield"
                        value={NumberFormater(formData.total_discount)}
                        id="total_discounts"
                        onChange={handleChange}
                        fullWidth
                        name="total_discount"
                        // placeholder="Ex: 1000Rs."
                        disabled
                        sx={{
                          width: "150px",

                        }}
                      />
                    </div>
                  </Grid>
                  &nbsp;
                  <Grid item xs={12} sm={12} md={12} >
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center" }}>

                      <label style={{ textAlign: "right" }}>
                        TOTAL TAXABLE AMOUNT :
                      </label>
                      <TextField
                        className="inputfield"
                        value={NumberFormater(formData.total_taxable)}
                        id="total_taxable_amounts"
                        // onChange={handleChange}
                        fullWidth
                        name="total_taxable"
                        // placeholder="Ex: 1000Rs."
                        disabled
                        sx={{
                          width: "150px",
                        }}
                      />
                    </div>
                  </Grid>
                  &nbsp;
                  <Grid item xs={12} sm={12} md={12} >
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center" }}>

                      <label style={{ textAlign: "right" }}>
                        TOTAL TAX :
                      </label>
                      <TextField
                        className="inputfield"
                        value={NumberFormater(formData.total_tax)}
                        id="total_taxes"
                        // onChange={handleTaxChange}
                        fullWidth
                        name="total_tax"
                        // placeholder="Ex: 10%"
                        disabled
                        sx={{
                          width: "150px",
                          // height : "30px"
                        }}
                      />
                    </div>
                  </Grid>
                  &nbsp;
                  <Grid item xs={12} sm={12} md={12} >
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center" }}>

                      <label style={{ textAlign: "right" }}>
                        NET AMOUNT :
                      </label>
                      <TextField
                        className="inputfield"
                        value={NumberFormater(formData.total_net_amount)}
                        id="net_amounts"
                        fullWidth
                        name="total_net_amount"
                        disabled
                        // onChange={handleChange}
                        sx={{
                          width: "150px",
                        }}
                      />
                    </div>
                  </Grid>
                  &nbsp;
                  <Grid item xs={12} sm={12} md={12} >
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center" }}>

                      <label style={{ textAlign: "right" }}>
                        PAID :
                      </label>
                      <TextField
                        className="inputfield"
                        type="number"
                        value={formData.paid_amount}
                        // value={Intl.NumberFormat().format(formData.paid_amount)}
                        id="paid_amounts"
                        fullWidth
                        disabled={true}
                        name="paid_amount"
                        // inputProps={{ inputMode: 'numeric' }}
                        onChange={handleChange}
                        // sx={{
                        //   width: "150px",
                        // }}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                          width: "150px",
                        }}
                      />
                    </div>
                  </Grid>
                  &nbsp;
                  <Grid item xs={12} sm={12} md={12} >
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center", textAlign: "end" }}>

                      <label style={{ textAlign: "right" }}>
                        BALANCE :
                      </label>
                      <TextField
                        className="inputfield"
                        value={NumberFormater(formData.balance_amount || 0)}
                        id="balances"
                        fullWidth
                        name="balance_amount"
                        disabled
                        sx={{
                          width: "150px",
                          height: "30px",
                          color: "black",
                          textAlign: "end",

                        }}
                      />
                    </div>
                  </Grid>


                  {
                    checkoutType === 'Early Checkout' ? (<>
                      &nbsp;
                      <Grid item xs={12} sm={12} md={12} >
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center", textAlign: "end" }}>

                          <label style={{ textAlign: "right" }}>
                            Early Checkout Charges (+) :
                          </label>
                          <TextField
                            className="inputfield"
                            value={NumberFormater(formData.balance_amount || 0)}
                            id="balances"
                            fullWidth
                            name="balance_amount"
                            disabled
                            sx={{
                              width: "150px",
                              height: "30px",
                              color: "black",
                              textAlign: "end",

                            }}
                          />
                        </div>
                      </Grid>
                      &nbsp;
                      <Grid item xs={12} sm={12} md={12} >
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center", textAlign: "end" }}>

                          <label style={{ textAlign: "right" }}>
                            Early Checkout Deduction (-)
                          </label>
                          <TextField
                            className="inputfield"
                            value={NumberFormater(formData.balance_amount || 0)}
                            id="balances"
                            fullWidth
                            name="balance_amount"
                            disabled
                            sx={{
                              width: "150px",
                              height: "30px",
                              color: "black",
                              textAlign: "end",

                            }}
                          />
                        </div>
                      </Grid>
                      &nbsp;
                      <Grid item xs={12} sm={12} md={12} >
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center", textAlign: "end" }}>

                          <label style={{ textAlign: "right" }}>
                            Gross
                          </label>
                          <TextField
                            className="inputfield"
                            value={NumberFormater(formData.balance_amount || 0)}
                            id="balances"
                            fullWidth
                            name="balance_amount"
                            disabled
                            sx={{
                              width: "150px",
                              height: "30px",
                              color: "black",
                              textAlign: "end",

                            }}
                          />
                        </div>
                      </Grid>
                      &nbsp;
                      <Grid item xs={12} sm={12} md={12} >
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center", textAlign: "end" }}>

                          <label style={{ textAlign: "right" }}>
                            Discount 10%
                          </label>
                          <TextField
                            className="inputfield"
                            value={NumberFormater(formData.balance_amount || 0)}
                            id="balances"
                            fullWidth
                            name="balance_amount"
                            disabled
                            sx={{
                              width: "150px",
                              height: "30px",
                              color: "black",
                              textAlign: "end",

                            }}
                          />
                        </div>
                      </Grid>
                      &nbsp;
                      <Grid item xs={12} sm={12} md={12} >
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center", textAlign: "end" }}>

                          <label style={{ textAlign: "right" }}>
                            Net Pay
                          </label>
                          <TextField
                            className="inputfield"
                            value={NumberFormater(formData.balance_amount || 0)}
                            id="balances"
                            fullWidth
                            name="balance_amount"
                            disabled
                            sx={{
                              width: "150px",
                              height: "30px",
                              color: "black",
                              textAlign: "end",

                            }}
                          />
                        </div>
                      </Grid>
                    </>)
                      : checkoutType === 'Extra Stay' ? (<Grid item xs={12} sm={12} md={12} >
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center", textAlign: "end" }}>

                          <label style={{ textAlign: "right" }}>
                            Extra Stay
                          </label>
                          <TextField
                            className="inputfield"
                            value={NumberFormater(formData.balance_amount || 0)}
                            id="balances"
                            fullWidth
                            name="balance_amount"
                            disabled
                            sx={{
                              width: "150px",
                              height: "30px",
                              color: "black",
                              textAlign: "end",

                            }}
                          />
                        </div>
                      </Grid>)
                        : (<></>)
                  }

                </Grid>

              </Grid>

              {loading ? <CommonLoading /> : null}

              <div className="btn-submit" style={{ gap: "15px" }}>
                <Button type="submit" variant="contained" className="btn-bgColor" onClick={() => navigate("/dashboard/quotation/quotation_list")}>
                  Cancle
                </Button>
                {quot_id ? (
                  // If updatedData exists, it's an update form, show the "Update" button
                  <Button type="submit" variant="contained" className="btn-bgColor">
                    Update
                  </Button>
                ) : (
                  // If updatedData is null, it's a new form, show the "Create" button
                  <Button type="submit" variant="contained" className="btn-bgColor">
                    Create
                  </Button>
                )}
              </div>

            </form>
          </div >

        </div>}
    </>
  );
};

export default CloseQuotation;



