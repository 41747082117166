import React from 'react';
import * as XLSX from 'xlsx';


const DownloadTemplate = () => {
    const data = {

        "Users": [
            {
                "horse_id": null,
                "name": null,
                "image": null,
                "dob": null,
                "gender": null,
                "birth_origin": null,
                "breed": null,
                "breeder_master": null,
                "color": null,
                "country_of_residence": null,
                "microchip_number": null,
                "height": null,
                "weight": null,
                "ability": null,
                "blood_group": null,
                "fei_passport_number": null,
                "fei_passport_expiry_date": null,
                "fei_registration_number": null,
                "owner_id": null,
                "trainer_id": null,
                "health_file": null,
                "vaccine_file": null,
            }
        ],
        "docs": [
            {
                "horse_id": null,
                "doc_id": null,
                "document_type": null,
                "issue_date": null,
                "expiry_date": null,
                "uploaded_file": null,
            }
        ]

    };

    const jsonToSheet = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);
        return ws;
    };

    const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, jsonToSheet(data), 'Horse Data');
    Object.keys(data).map((d) => {

        XLSX.utils.book_append_sheet(wb, jsonToSheet(data[d]), d);
    })

    XLSX.writeFile(wb, 'HorseData.xlsx', { bookSST: true });
};

export default DownloadTemplate;
