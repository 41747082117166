

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getStallCellData } from "../../App/Reducers/Stable Slices/CellSlices";
import { useNavigate } from "react-router-dom";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../App/Helpers/DeleteModal";
import api from "../../Apis";
import AlertMessage from "../../App/Helpers/AlertMessage";

const CheckIn = () => {

  const [cookies, setCookies] = useCookies(["token"]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);
  const token = cookies.token;


  const dispatch = useDispatch();
  const navigate = useNavigate();


  const cellData = useSelector((state) => state.cell.stallCellData);

  const permissions = JSON.parse(localStorage.getItem('userData'))
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false
  const addCheckInPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "add_horsecheckin") : false;
  const changeCheckInPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "change_horsecheckin") : false;
  const deleteCheckInPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "delete_horsecheckin") : false;


  const header = [
    {
      Header: "Reservation No.",
      accessor: "quotation.id",
    },

    {
      Header: "Horse Name",
      accessor: "horse.name",
    },
    {
      Header: "Staff",
      accessor: "staff",
      Cell: ({ row }) => {
        const first_name = row.original.staff?.first_name
        const last_name = row.original.staff?.last_name
        const usertype = row.original.staff?.usertype
        return first_name ? `${first_name} ${last_name} (${usertype.name})` : "";
      },
    },
    {
      Header: "Facility Type",
      accessor: "facility_type.name",
    },
    {
      Header: "Facility Code",
      accessor: "facility_code.name",
    },
    {
      Header: "Service Type",
      accessor: "service_type.name",
    },
    {
      Header: "Checkin Type",
      accessor: "checkin_type",
    },
    {
      Header: "Health Status",
      accessor: "health_status",
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },
    },
    {
      Header: "Checkin Time",
      accessor: "time",
    },
  ];

  const createHandler = () => {
    navigate("/dashboard/stable/check_in_registration");
  };


  const updateHandler = async (id) => {
    navigate(`/dashboard/stable/check_in_registration/${id}`);
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };


  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_checkin/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getStallCellData(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  useEffect(() => {
    dispatch(getStallCellData(token));
  }, [dispatch, token]);


  return (
    <>
  <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      <ToastContainer />
      {cellData ? (
        <BasicTable
          colHeader={header}
          rowData={cellData}
          actionFlag={(deleteCheckInPermission || changeCheckInPermission) || (isUserType) ? false : true}

          // createHandler={createHandler}
          createHandler={(addCheckInPermission || isUserType) ? createHandler : null}

          // deleteHandler={deleteHandler}
          deleteHandler={(deleteCheckInPermission || isUserType) ? deleteHandler : null}

          // updateHandler={updateHandler}
          updateHandler={(changeCheckInPermission || isUserType) ? updateHandler : null}

          tableHeading="Check In"

        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={(deleteCheckInPermission || changeCheckInPermission) || (isUserType) ? false : true}

          // createHandler={createHandler}
          createHandler={(addCheckInPermission || isUserType) ? createHandler : null}

          // deleteHandler={deleteHandler}
          deleteHandler={(deleteCheckInPermission || isUserType) ? deleteHandler : null}

          // updateHandler={updateHandler}
          updateHandler={(changeCheckInPermission || isUserType) ? updateHandler : null}

          tableHeading="Check In"

        />
      )}
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Check In"
        deleteData={deleteData}
      />
    </>

  );
};

export default CheckIn;
