import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../../Apis";

export const getAllCellHorses = createAsyncThunk(
  "getAllCellHorses",
  async (payload) => {
    try {
      const res = await api.get(`get_cell_by_checkin/${payload.id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
        },
      });
      const Apidata = res.data;
      console.log(Apidata, payload.id, "getAllCellHorses");
      return Apidata;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
);
export const createPermOpData = createAsyncThunk(
  "createPermOpData",
  async (payload) => {
    try {
      console.log(payload.Data, " payload.Data;---------------------------");
      const res = await api.post(`create_permanent_checkout`, payload.Data, {
        headers: {
          Authorization: `token ${payload.token}`,
          "Content-Type": "application/json",
        },
      });
      const Apidata = res.data;

      return Apidata;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
);


export const getPermOpData = createAsyncThunk(
  "getPermOpData",
  async (token) => {
    try {
      const res = await api.get("get_all_checkout", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const Apidata = res.data;
      return Apidata;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
);

const initialState = {
  PermOpData: null,
  AllCellhorseData: null,


  status: {
    Get: "",
    Create: "",
    Updating: "",
    Update: "",
    Delete: "",
  },
  errors: {
    Get: "",
    Create: "",
    Update: "",
    Delete: "",
  },
};

const PermanentOutpassSlice = createSlice({
  name: "permanent_outpass",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCellHorses.pending, (state) => {
      console.log(`Pending State`);
    });
    builder.addCase(getAllCellHorses.fulfilled, (state, action) => {
      state.AllCellhorseData = action.payload;
    });
    builder.addCase(getAllCellHorses.rejected, (state) => {
      console.log(`You got an error`);
    });

    builder.addCase(getPermOpData.pending, (state) => {
      state.status.Get = "pending";
    });
    builder.addCase(getPermOpData.fulfilled, (state, action) => {
      state.PermOpData = action.payload;
      state.status.Get = "Success";
    });
    builder.addCase(getPermOpData.rejected, (state) => {
      console.log(`You got an error`);
      state.status.Get = "failed";
    });
    builder.addCase(createPermOpData.pending, (state) => {
      state.status.Create = "pending";
    });
    builder.addCase(createPermOpData.fulfilled, (state, action) => {
      state.status.Create = "Success";
    });
    builder.addCase(createPermOpData.rejected, (state, action) => {
      console.log(`You got an error`);
      state.status.Create = "failed";
      state.errors.Create = action.payload;
    });
    // builder.addCase(getTempOpDataID.pending, (state) => {
    //   state.status.Updating = "pending";
    // });
    // builder.addCase(getTempOpDataID.fulfilled, (state, action) => {
    //   state.status.Updating = "Success";
    //   state.getDataById = action.payload;
    // });
    // builder.addCase(getTempOpDataID.rejected, (state, action) => {
    //   console.log(`You got an error`);
    //   state.status.Updating = "failed";
    //   state.errors.Create = action.payload;
    // });
    // builder.addCase(updateTempOpDataID.pending, (state) => {
    //   state.status.Update = "pending";
    // });
    // builder.addCase(updateTempOpDataID.fulfilled, (state, action) => {
    //   state.status.Update = "Success";

    //   console.log(action.payload, "0000000000000000000000000000000000000000");
    // });
    // builder.addCase(updateTempOpDataID.rejected, (state, action) => {
    //   console.log(`You got an error`);
    //   state.status.Update = "failed";
    //   state.errors.Update = action.payload;
    // });
    // builder.addCase(updateTempOpData.pending, (state) => {
    //   state.status.Update = "pending";
    // });
    // builder.addCase(updateTempOpData.fulfilled, (state, action) => {
    //   state.status.Update = "Success";

    //   console.log(action.payload, "0000000000000000000000000000000000000000");
    // });
    // builder.addCase(updateTempOpData.rejected, (state, action) => {
    //   console.log(`You got an error`);
    //   state.status.Update = "failed";
    //   state.errors.Update = action.payload.msg;
    // });
  },
});
export default PermanentOutpassSlice.reducer;
