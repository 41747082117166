import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getTrainingMasterData = createAsyncThunk("getTrainingMasterData", async (token) => {
    try {
        const response = await api.get("get_all_training", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createTrainingMasterData = createAsyncThunk("createTrainingMasterData", async (payload) => {
    try {
        const response = await api.post("create_training", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteTrainingMasterData = createAsyncThunk("deleteTrainingMasterData", async (id, token) => {
    try {
        const response = await api.delete(`delete_training/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateTrainingMasterData = createAsyncThunk("updateTrainingMasterData", async (payload) => {
    console.log("payload", payload)
    try {
        const response = await api.put(`update_training/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateTrainingMasterData = createAsyncThunk("getupdateTrainingMasterData", async (id, token) => {
    try {
        const response = await api.get(`get_training/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const TrainingMasterSlice = createSlice({
    name: "TrainingMaster",
    initialState: {
        status: "",
        TrainingMasterData: null,
        error: null,
        updateTrainingMasterData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getTrainingMasterData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getTrainingMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.TrainingMasterData = action.payload
            
        })
        builder.addCase(getTrainingMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteTrainingMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteTrainingMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteTrainingMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createTrainingMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createTrainingMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createTrainingMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateTrainingMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateTrainingMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateTrainingMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateTrainingMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateTrainingMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateTrainingMasterData = action.payload

        })
        builder.addCase(getupdateTrainingMasterData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default TrainingMasterSlice.reducer;