import React from "react";
import "./HeroImage.css";
import { ImgUrl } from "../Config/Config";
const HeroImage = (props) => {
  
  
  const modalSelectorId = props.modalSelector && props.modalSelector.find(item => item.id === 1);
  console.log("default_image",props.default_image);  
  
  const myStyle = {
    backgroundImage: modalSelectorId && modalSelectorId.image
        ? `url("${ImgUrl}${modalSelectorId.image}")`
        : `url(${props.default_image})`
        
};

  


  return (
    <div>
      <div className="Img_div" style={myStyle}>
        <div className="Img_text_div">
          <div>
          {modalSelectorId && modalSelectorId.image_title ? (
              <p>{modalSelectorId.image_title}</p>           
            ) : (
              <p>{props.default_image_title}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
