import React, { useState, useEffect } from "react";
import "./ArticalSection.css";
import { ChangeTheme } from "../App/Reducers/ThemeReducer";
import { useSelector, useDispatch } from "react-redux";



const ArticalSection = (props) => {

  const modalSelectorId = props.modalSelector && props.modalSelector.find(item => item.id === 1);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const isloggedIn = localStorage.getItem("adminloggedIn");

  // Theme selector function and variables --Start
  const [headerdivClass, setHeaderDivClass] = useState("artical-section");
  const [mainTheme, setMainTheme] = React.useState("default");
  const themeSelector = useSelector((state) => state.theme.ThemeColor);

  useEffect(() => {
    switch (themeSelector) {
      case "header-white":
        setHeaderDivClass("artical-section header-white");
        break;
      case "header-roseate":
        setHeaderDivClass("artical-section header-roseate");
        break;
      case "header-skyBlue":
        setHeaderDivClass("artical-section header-skyBlue");
        break;
      case "header-grassy":
        setHeaderDivClass("artical-section header-grassy");
        break;
      case "header-pink":
        setHeaderDivClass("artical-section header-pink");
        break;
      case "header-gold":
        setHeaderDivClass("artical-section header-gold");
        break;
      case "header-orange":
        setHeaderDivClass("artical-section header-orange");
        break;
      default:
        setHeaderDivClass("artical-section header-gold ");
        break;
    }
  }, [themeSelector]);

  //  Theme selector function and variables --End
  const [themetoggle, setThemetoggle] = useState(true);
  const handleTheme = () => {
    console.log("shdgasgdasgdgsaig");
    if (themetoggle) {
      setThemetoggle(false);
    } else {
      setThemetoggle(true);
    }
  };

  return (

    <div style={{ backgroundColor: "black", paddingBlock: "50px" }} className={headerdivClass}>

      <div style={{ backgroundColor: "#222222", borderRadius: "10px", padding: "10px", width: "100%", margin: "auto" }}>

        <div style={{ display: "flex", justifyContent: "space-between", marginBlock: "20px", borderBottom:"2px solid #bebb9e"}}>
          {modalSelectorId && modalSelectorId.body_title ? (
            <p style={{ display: "inline-block", color: "#bebb9e", fontFamily: "var(--main-font)", fontSize: "2.5rem" }}>{modalSelectorId.body_title}</p>
          ) : (
            <p style={{ display: "inline-block", color: "#bebb9e", fontFamily: "var(--main-font)", fontSize: "2.5rem" }}>{props.default_image_title}</p>
          )}

          {isloggedIn ? userData && userData.usertype?.name === "Admin" && (
            <i class="fas fa-edit" style={{ fontSize: "20px", color: "#bebb9e", cursor: "pointer", float: "right" }}
              onClick={props.headingOpenModal}
              title="Edit Heading" />
          ) : null
          }

        </div>

          {isloggedIn ? userData && userData.usertype?.name === "Admin" && (
            <i class="fas fa-edit" style={{ fontSize: "20px", color: "#bebb9e", cursor: "pointer", float: "right" }}
              onClick={props.openQuill}
              title="Edit Paragraph" />
          ) : null
          }

        <div className="body" style={{color:"white"}}>
          <div>{props.bodyComponent ? props.bodyComponent : null}</div>

        </div>
      </div>


    </div>


  );
};
ArticalSection.defaultProps = {
  displayCalander: false,
};

export default ArticalSection;
