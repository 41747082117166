import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseURL } from '../../Apis';
import api from "../../Apis";

// ################################
// it's planer not Food planner 
// ################################
export const GetFoodPlanerList = createAsyncThunk('GetFoodPlanerList', async (token) => {
  try {
    const response = await api.get(`get_all_planer`,{
      headers:{
        Authorization:`token ${token}`
      }
    });
    return response.data

  } catch (error) {
    console.log(error)
  }

})
export const CreateFoodPlaner = createAsyncThunk('CreateFoodPlaner', async (payload , { rejectWithValue }) => {
    try {
        const response = await api.post(`create_planer`,
        payload.plannerData , 
        {headers:{
            "Content-Type": "application/json",
            Authorization:`token ${payload.token}`
          }});
        console.log(response, "/api/create_planer")
        return response.data
  
      } catch (error) {
        return rejectWithValue(error.response.data)
      }
  
  })
  export const GetUpdatingFoodPlaner = createAsyncThunk('GetUpdatingFoodPlaner', async (payload , { rejectWithValue }) => {
    try {
      const response = await api.get(`get_food_planer/${payload.id}`, {
        headers:{
          Authorization : `token ${payload.token}`
        }
      });
      return response.data
  
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
  
  })

  export const UpdateFoodPlaner1 = createAsyncThunk('UpdateFoodPlaner1', async (payload, {rejectWithValue}) => {
    try {
      const response = await api.put(`update_planer/${payload.plannerData.id}`,
      payload.plannerData,
      {headers:{
          "Content-Type": "application/json",
          Authorization : `token ${payload.token}`
        }});
      return response.data

    } catch (error) {
      return rejectWithValue(error.response.data)
    }  
  
  })


  export const DeleteFoodPlaner = createAsyncThunk('DeleteFoodPlaner', async (payload , { rejectWithValue }) => {
    try {
        const response = await api.delete(`delete_planer/${payload.id}`, {
          headers:{
            Authorization : `token ${payload.token}`
          }
        })      
        return response.data
  
      } catch (error) {

         return rejectWithValue(error.response.data)
      }  
  })
const initialState = {
  PlanerList: null ,
  UpdatingPlaner : null,
  status :{
    Get :"",
    Create:"",
    Updating:"",
    Update:"",
    Delete:""
  },
  errors :{
    Get:"",
    Create:"",
    Update:"",
    Delete:"",

  }
};

const FoodPlanerSlice = createSlice({
  name: "FoodPlaner",
  initialState,
  reducers: {
    changeState: (state, action) => {
      
     },
  },
  extraReducers : (builder) =>{
    builder
    .addCase(GetFoodPlanerList.pending , (state)=>{
        state.status.Get = "loading"
    })
    .addCase(GetFoodPlanerList.fulfilled , (state , action)=>{
        state.status.Get = "succeeded"
        state.status.Create = ""
        state.status.Updating = ""
        state.PlanerList = action.payload
        console.log(action.payload , "success")
    })
    .addCase(GetFoodPlanerList.rejected , (state , action)=>{
        state.status.Get = "failed"
        console.log("failed")
       
    })
    .addCase(CreateFoodPlaner.pending , (state)=>{
        state.status.Create = "loading"
    })
    .addCase(CreateFoodPlaner.fulfilled , (state , action)=>{
        state.status.Create = "succeeded"
        state.status.Updating = ""
        state.status.Get = ""
        state.UpdatingPlaner = null
        console.log(action.payload , "succeeded")
    })
    .addCase(CreateFoodPlaner.rejected , (state , action)=>{
        state.status.Create = "failed"
        console.log("failed")  
    })
    .addCase(GetUpdatingFoodPlaner.pending , (state)=>{
        state.status.Updating = "loading"
    })
    .addCase(GetUpdatingFoodPlaner.fulfilled , (state , action)=>{
        state.status.Updating = "succeeded"
        state.status.Get = ""
        state.status.Create = ""
    
        state.UpdatingPlaner  = action.payload
        console.log(action.payload , "succeeded")
        console.log(state.UpdatingPlaner  , "updating")
    })
    .addCase(GetUpdatingFoodPlaner.rejected , (state , action)=>{
        state.status.Updating = "failed"
        console.log("failed")  
    })
    .addCase(UpdateFoodPlaner1.pending , (state)=>{
        state.status.Update = "loading"
    })
    .addCase(UpdateFoodPlaner1.fulfilled , (state , action)=>{
        state.status.Update = "succeeded"
        state.status.Updating = ""
        state.status.Get = ""
        state.status.Create = ""
        console.log(action.payload , "succeeded")

    })
    .addCase(UpdateFoodPlaner1.rejected , (state , action)=>{
        state.status.Update = "failed"
        state.errors.Update = action.payload
        console.log("failed")  
      
    })
}
})


export const {changeState} = FoodPlanerSlice.actions;
export default FoodPlanerSlice.reducer;
