import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteTrainer,
  GetTrainers,
  GetUpdatingTrainer,
} from "../../App/Reducers/TrainerReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { useNavigate } from "react-router-dom";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import { useCookies } from "react-cookie";
import { ImgUrl } from "../../Config/Config";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../App/Helpers/DeleteModal";
import api from "../../Apis";
import userImg from "../../Assets/Images/user.jpg";
import AlertMessage from "../../App/Helpers/AlertMessage";


const TrainerList = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const trainerList = useSelector((state) => state.trainers.Trainers);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addTrainerPermission = permissions?.user_permissions
    ? permissions.user_permissions.some((obj) => obj.codename === "add_trainer")
    : false;
  const changeTrainerPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_trainer"
      )
    : false;
  const deleteTrainerPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_trainer"
      )
    : false;

  const profileUpdation = (id) => {
    if (id) {
      navigate(`/dashboard/trainer/trainer-profile/${id}`);
    }
  };


  const header = [
    { Header: "Trainer Id", accessor: "code" },
    {
      Header: "Profile",
      accessor: "profile",
      Cell: ({ row }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              row.original.image === null
                ? userImg
                : `${ImgUrl}${row.original.image}`
            }
            width={50}
            height={50}
            style={{ borderRadius: "50%", cursor: "pointer" }}
            onClick={() => profileUpdation(row.original.id)}
          />
          <span
            onClick={() => profileUpdation(row.original.id)}
            style={{
              cursor: "pointer",
              color: "#7c5e1e",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
            title="View profile"
          >
            {row.original.name}
          </span>
        </div>
      ),
    },
    { Header: "Email", accessor: "email" },
    { Header: "Mobile", accessor: "mobile",   Cell: ({ row }) => (
      
      <span>{`+${row.original.mobile}`}</span>
  ) },
    { Header: "DOB", accessor: "date_of_birth" },
    { Header: "Blood Group", accessor: "blood_group" },
    { Header: "Gender", accessor: "gender" },
    { Header: "Nationality", accessor: "nationality.name" },
    { Header: "City", accessor: "city" },
    { Header: "Country", accessor: "country.name" },
    { Header: "Address", accessor: "address" },
    { Header: "Post Box", accessor: "post_box" },
    { Header: "Training Experience", accessor: "training_experience" },
    { Header: "Achievements", accessor: "achievements" },
    { Header: "Visa Category", accessor: "visa_category.name" },
    { Header: "Division", accessor: "division.name" },
  ];

  const UpdateHandler = (id) => {
    dispatch(GetUpdatingTrainer({ id, token }));
    navigate(`/dashboard/trainer/update_form/${id}`);
  };

  const CreateHandler = () => {
    navigate("/dashboard/traineragreement/registration_form");
  };

  const deleteHandler = async (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    try {
      const response = await api.delete(`delete_trainer/${deleteId}`, {
        headers: { Authorization: `token ${token}` },
      });
      if (response.status === "200" || response.statusText === "OK") {
        setDeleteModalOpen(false);
        toast.success("Deleted successfully");
        dispatch(GetTrainers(token));
      }
      return response;
    } catch (error) {
      if (error.response.data.Error) {
        setIsErrorVisible(true);
        setIsErrorResponse(error.response.data.Error);
      }

      setTimeout(() => {
        setIsErrorVisible(false);
      }, 6000);
    }
  };

  useEffect(() => {
    dispatch(GetTrainers(token));
  }, []);

  return (
    <div className="all_table_list">
      <ToastContainer />
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>
      {trainerList && header ? (
        <BasicTable
          colHeader={header}
          rowData={trainerList}
          actionFlag={
            deleteTrainerPermission || changeTrainerPermission || isUserType
              ? false
              : true
          }
          //  deleteHandler={deleteHandler}
          deleteHandler={
            deleteTrainerPermission || isUserType ? deleteHandler : null
          }
          //  updateHandler={UpdateHandler}
          updateHandler={
            changeTrainerPermission || isUserType ? UpdateHandler : null
          }
          //  createHandler={CreateHandler}
          createHandler={
            addTrainerPermission || isUserType ? CreateHandler : null
          } // Only allow create if the user has permission
          tableHeading="Trainer list"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            deleteTrainerPermission || changeTrainerPermission || isUserType
              ? false
              : true
          }
          //  deleteHandler={deleteHandler}
          deleteHandler={
            deleteTrainerPermission || isUserType ? deleteHandler : null
          }
          //  updateHandler={UpdateHandler}
          updateHandler={
            changeTrainerPermission || isUserType ? UpdateHandler : null
          }
          //  createHandler={CreateHandler}
          createHandler={
            addTrainerPermission || isUserType ? CreateHandler : null
          } // Only allow create if the user has permission
          tableHeading="Trainer list"
        />
      )}
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Trainer"
        deleteData={deleteData}
      />
    </div>
  );
};

export default TrainerList;
