import React from "react";
import CommonEditablePage from "../../SharedComponets/CommonEditablePage";
import { useSelector } from "react-redux";
import {
  createRidingSchool,
  getRidingschool,
  updateRidingschool,
} from "../../App/Reducers/HeroQuillReducer";
import { ridingSchool } from "../../Assets/DefaultImagesCarousel";
import RideView from "../../Ridingschool/pages/ridingSchoolDashboard/RideView";

const AboutUs = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.ridingschool);

  const defaultContent = `India’s premier horse riding school, run according to British Horse Society (BHS)standards. Our 240-acre campus is in Devanahalli, just 27km north of the city.
  
  We offer you:
  Structured riding lessons for all levels from beginner to advanced conducted by highly experienced trainers teaching on the same lines as BHS levels.
  Pony Club activities for children of all ages,
  Accommodation Facility – Spend a relaxing weekend amidst unparalleled greenery and serenity, just half an hour from the city.
  Ai Cavalli Italian restaurant – Dine at Ai Cavalli, one of the city’s longstanding Italian restaurants widely known for its simple, rustic appeal and delicious home-style cooking.
  Horses for Sale – The opportunity you have been waiting for – buy horses with premium pedigree.
  Livery charges – Find a detailed breakdown of monthly charges for stabling your horse with EIRS.
  Horse Assisted Leadership Training -The concept of “Horse Assisted Education”, incepted in Germany is currently used by management experts & coaches across USA, Russia, Europe & UAE. Our uniquely designed programme specializes in building Leadership skills in corporate teams.`

  return (
    <>
      <div className="rideView__container">
        <RideView />
      </div>
      <CommonEditablePage
        updateApiFunc={updateRidingschool}
        getApiFunc={getRidingschool}
        createApiFunc={createRidingSchool}
        modalSelector={modalSelector}
        default_image_title={ridingSchool.image_title}
        default_image={ridingSchool.image}
        default_body_content={defaultContent}
      />
    </>
  );
};

export default AboutUs;
