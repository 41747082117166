import React, { useEffect , useState } from 'react'
import {FaPlus} from "react-icons/fa"
import {useNavigate} from "react-router-dom"
import { useDispatch , useSelector } from 'react-redux'
import { DeleteTrainingPlaner, GetTrainingPlanerList, GetUpdatingTrainingPlaner } from '../../App/Reducers/TrainingPlanerReducer'
import { BasicTable } from '../../SharedComponets/Table list/BasicTable'
import { format } from "date-fns";
import StableLoading from '../../SharedComponets/Loading/StableLoading'
import { useCookies } from 'react-cookie'
const TrainingPlanerList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [cookie , setCookie] = useCookies()
    const token = cookie.token
    const State = useSelector((state)=>state.TrainingPlaner)
    const [status , setStatus] = useState(State.status.Updating)
    console.log(status)
    const header = [
      { Header: "ID", accessor: "id" },
      { Header: "Horse Name", accessor: "horse_id.name" },
      { Header: "Training Type", accessor: "training_type.name" },
      { Header: "Discription", accessor: "discription" },
      { Header: "Price", accessor: "price" },
      {
        Header: "Staff",
        accessor: "staff",
        Cell: ({ row }) => {
          const { first_name, last_name } = row.original.staff;
          return `${first_name} ${last_name}`;
        },
      },
      { Header: "Other Staff", accessor: "other_staff"},
      { Header: "Start Date", accessor: "start_date" ,
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },},
      { Header: "End Date", accessor: "end_date",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },},
      // { Header: "Is Done", accessor: "is_done" },
     
    ]
    const CreateHandler = () =>{
      navigate("/dashboard/planer/training");
    }
    const UpdateHandler = (id) =>{
        dispatch(GetUpdatingTrainingPlaner({id , token}))
    
    }
    const DeleteHandler = (id) =>{
      dispatch(DeleteTrainingPlaner({id , token}))
    }
    useEffect(()=>{
        setStatus(State.status.Updating)
        if(status === "succeeded"){
          navigate("/dashboard/planer/training")
       }
         dispatch(GetTrainingPlanerList(token))
    },[dispatch , State.status.Updating,status,navigate])
  return (
  
    <div className="all_table_list">
    {(State.List && header) ? <BasicTable colHeader={header} rowData={State.List} deleteHandler={DeleteHandler} updateHandler={UpdateHandler} createHandler={CreateHandler} tableHeading={"Training Planner List"}/> : null }
  </div>
  )
}

export default TrainingPlanerList