import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ImgUrl } from "../../Config/Config";
import { AiOutlineCaretRight } from "react-icons/ai";
import { FaEdit, FaFilePdf } from "react-icons/fa";
import { getUpdateOwnerData } from "../../App/Reducers/OwnerReducer";
import AOS from "aos";
import "aos/dist/aos.css";
import StableLoading from "../../SharedComponets/Loading/StableLoading";

const OwnerProfile = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const { oProfileId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const OwnerData = useSelector((state) => state.owner.updateOwnerData);
  const [activeLink, setActiveLink] = useState("Documents");

  const handleLinkClicked = (linkName) => {
    setActiveLink(linkName);
  };
  const [topDetailsActiveLink, setTopDetailsActiveLink] =
    useState("OwnerInformation");

  const handleTopDetailsLinkClicked = (linkName) => {
    setTopDetailsActiveLink(linkName);
  };

  useEffect(() => {
    // dispatch(getUpdateOwnerData({ id: oProfileId, token }));
    dispatch(getUpdateOwnerData({ id: oProfileId, token }));
    AOS.init();
  }, []);


  return OwnerData ? (
    <>
      <div className="form-wrapper-container">
        <div data-aos="fade-left" data-aos-duration="1000">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              alignItems: "center",
              paddingBlock: "30px 10px",
            }}
            className="welcome-area"
          >
            {/* <img src={`${ImgUrl}${TrainerData && TrainerData.image}`} alt="Owner Profile" style={{ width: "5.5rem", height: "5.5rem", display: "inline-block", borderRadius: "50%" }} /> */}
            <p style={{ display: "inline-block", fontWeight: "bolder" }}>
              <span style={{ fontSize: "18px" }}> </span>Welcome to the alluring
              profile of{" "}
              <span style={{ fontSize: "40px", color: "#302924" }}>
                {" "}
                {OwnerData ? OwnerData.name : "Owner Name"}{" "}
              </span>
            </p>
          </div>
          {/* <div style={{display:"flex", alignItems: "start", width:"100%"}}> */}
          <div className="horse-profile-container">
            <div className="profile-details-container">
              <div className="profile-details-detail">
                <div className="profile-common-layout horse-info" id="h-info">
                  <a href="#h-info" id="h-info" className="bookmark">
                    <h3>Registration Information</h3>
                  </a>
                </div>
                <div className="profile-ditails-single-data-container">
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;National Id&nbsp;:&nbsp;
                      </span>
                      <span>{OwnerData.national_id}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Visa Category&nbsp;:&nbsp;
                      </span>
                      <span>{OwnerData.visa_category.name}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Discipline&nbsp;:&nbsp;
                      </span>
                      <span>{OwnerData.divison.name}</span>
                    </li>
                  </ul>
                </div>
                <div className="data-image-container">
                  <div>
                    <div className="profile-common-layout fei-reg" id="fei-reg">
                      <a href="#fei-reg" id="fei-reg" className="bookmark">
                        <h3>Owner Information</h3>
                      </a>
                    </div>
                    <div className="profile-ditails-single-data-container">
                      <ul style={{ listStyleType: "none" }}>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Name&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.name}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Gender&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.gender}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Date of Birth&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.date_of_birth}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Nationality&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.nationality.name}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;City&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.city}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Address&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.address}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Post Box&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.post_box}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Country&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.country_id.name}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Blood Group&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.blood_group}</span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="profile-common-layout owner-info"
                      id="owner-info"
                    >
                      <a
                        href="#owner-info"
                        id="owner-info"
                        className="bookmark"
                      >
                        <h3>Owner Contact Information</h3>
                      </a>
                    </div>
                    <div className="profile-ditails-single-data-container">
                      <ul style={{ listStyleType: "none" }}>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Email&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.email}</span>
                        </li>
                        {/* <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;ISD Code&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.isd_code.country}</span>
                        </li> */}
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Mobile No&nbsp;:&nbsp;
                          </span>
                          <span>{OwnerData.mobile}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="profile-common-layout doc-info" id="doc-info">
                  <a href="#doc-info" id="doc-info" className="bookmark">
                    <h3>Documents</h3>
                  </a>
                </div>

                <div style={{ width: "100%" }}>
                  <table style={{ width: "90%", margin: "30px 50px" }}>
                    <thead>
                      <tr
                        className="tableHeader  table-list"
                        style={{ textAlign: "center", color: "white" }}
                      >
                        <th>Document Name</th>
                        <th>Issue Date</th>
                        <th>Expiry Date</th>
                        <th>Uploaded File</th>
                      </tr>
                    </thead>
                    <tbody>
                      {OwnerData && OwnerData.files
                        ? OwnerData.files.map((file) => (
                          <tr className="table-list">
                            <td>{file.document_type.name}</td>
                            <td>{file.issue_date}</td>
                            <td
                              style={{
                                color:
                                  new Date(file.expiry_date) < new Date()
                                    ? "#ff0000"
                                    : "black",
                              }}
                            >
                              {file.expiry_date}&nbsp;{" "}
                              {new Date(file.expiry_date) < new Date()
                                ? "Expired"
                                : null}
                              {new Date(file.expiry_date) < new Date() &&
                                <span onClick={() => { navigate(`/dashboard/owner/update_form/${OwnerData.id}#doc`) }}>
                                  &nbsp;<FaEdit style={{ fontSize: '1.125rem', color: "#000", cursor: 'pointer' }} />
                                </span>

                              }
                            </td>
                            <td>
                              <Link
                                to={`${ImgUrl}${file.uploaded_file}`}
                                download
                                target="_blank"
                                style={{ display: "inline-block" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    border: "1px solid  #9f8754",
                                    borderRadius: "3px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "1.3rem",
                                      color: "white",
                                      background:
                                        "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                                      borderRadiusTopleft: "2px",
                                      borderRadiusBottomleft: "2px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "36px",
                                      height: "36px",
                                    }}
                                  >
                                    <FaFilePdf />
                                  </div>
                                  <div
                                    style={{
                                      padding: "0px 10px",
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    {
                                      file.uploaded_file.split(
                                        "/media/owner_file/"
                                      )[1]
                                    }
                                  </div>
                                </div>
                              </Link>
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <StableLoading />
  );
};

export default OwnerProfile;
