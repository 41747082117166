import React from 'react'

import { TextField, Typography, Button, Modal, Box } from '@mui/material'
const ArticleSectionHeadingModal = (props) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#e0e0e0",
        border: "3px solid #bebb9e",
        borderRadius: "10px",
        boxShadow: 24,
        width: "fit-content",
        height: "fit-content",
        p: 4,
    };

    return (
        <>
            <Modal
                open={props.headingModalOpen}         
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Button
                        variant="outlined"
                        sx={{
                            color: "#302924",
                            border: "0px",
                            "&:hover": {
                                border: "0px",
                                backgroundColor: "#e0e0e0",
                                color: "black",
                            },

                            float: "right",
                            padding: "0px",
                            justifyContent: "right",
                            fontSize: "18px",
                        }}
                        onClick={props.headingCloseModal}
                    >
                        <i class="fa fa-close" />
                    </Button>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ color: "#1c1816", marginBottom: "5vh" }}
                    >
                        EDIT HEADING
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        type='text'
                        name="body_title"
                        variant="outlined"
                        onChange={props.handleModalInputChange}
                        value={props.formData.body_title || ''}
                        sx={{
                            width: "98%",
                            margin: "10px",
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgb(190, 187, 158) !important",
                            },
                            "& input": {
                                color: "#302924", // Replace "blue" with your desired text color
                            },
                        }}
                    />
           

                    <Button
                        variant="outlined"
                        sx={{
                            color: "#fff",
                            borderColor: "#302924",
                            "&:hover": {
                                borderColor: "#302924",
                                color: "#302924",
                            },
                            marginTop: "15px",
                            float: "right",
                            fontSize: "12px",
                            backgroundColor: "#302924",
                        }}
                        onClick={props.handleUpdate}
                    >
                        SAVE
                    </Button>
                    <Button onClick={props.headingCloseModal} sx={{
                        color: "#fff",
                        borderColor: "#302924",
                        "&:hover": {
                            borderColor: "#302924",
                            color: "#302924",
                        },
                        marginTop: "15px",
                        float: "right",
                        fontSize: "12px",
                        backgroundColor: "#302924",
                        marginRight: "5px"
                    }}>Close</Button>
                </Box>
            </Modal>
        </>
    )
}

export default ArticleSectionHeadingModal