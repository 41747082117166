import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Apis";
import { useCookies } from 'react-cookie';
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export const GetLogo = createAsyncThunk("GetLogo", async (token,{rejectWithValue}) => {
  
  try {
    const tokendefault= "63367e16b50f1fdabebd3678b2e751a270c90a94"
    const response = await api.get(
      `get_logo/1`, 
      {
        headers: {
          // Authorization: `token ${token}`
          Authorization: `token ${tokendefault}`
        }
      }
    );
    
    console.log(response, "sahjfgdeudjgfvbdrvjfdk");
    return response.data
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
}
);
export const CreateLogo = createAsyncThunk("CreateLogo", async (LogoData , {rejectWithValue}) => {
  const token = "c50d37f0f6fc6c95a04a2bbd1ed2a4c005b2e472"
  try {
    const response = await api.get(
      `upload_logo`, 
      {LogoData},
      {
        headers: {
          "Content-Type": "multipart/form-data application/json",
          Authorization: `token ${token}`
        }
      }
    );
    console.log(response, "sahjfgdeudjgfvbdrvjfdk");
    return response.data
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
}
);
export const UpdateLogo = createAsyncThunk("UpdateLogo", async (id , {rejectWithValue}) => {
  const token = "c50d37f0f6fc6c95a04a2bbd1ed2a4c005b2e472"
  try {
    const response = await api.put(
      `get_logo/${id}`, 
      {
        headers: {
          Authorization: `token ${token}`
        }
      }
    );
    console.log(response, "sahjfgdeudjgfvbdrvjfdk");
    return response.data
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
}
);
const initialState = {
  logo: null,
  status: "",
  error: "",
};

const LogoSlice = createSlice({
  name: "logo",
  initialState,
  reducers: {
    Update: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder
    .addCase(GetLogo.rejected, (state, action) => {
      console.log("failed" , action.payload)
    })
    .addCase(GetLogo.pending, (state, action) => {
      console.log(action.payload);
    })
    .addCase(GetLogo.fulfilled, (state, action) => {
      state.logo = action.payload
      console.log(action.payload, "success");
    })
    .addCase(CreateLogo.rejected, (state, action) => {
      console.log(action.payload);
    })
    .addCase(CreateLogo.pending, (state, action) => {
      console.log(action.payload);
    })
    .addCase(CreateLogo.fulfilled, (state, action) => {
      console.log(action.payload, "success");
    })
    .addCase(UpdateLogo.rejected, (state, action) => {
      console.log(action.payload);
    })
    .addCase(UpdateLogo.pending, (state, action) => {
      console.log(action.payload);
    })
    .addCase(UpdateLogo.fulfilled, (state, action) => {
      state.logo = action.payload
      console.log(action.payload, "success");
    })
  },
});

export const {} = LogoSlice.actions;
export default LogoSlice.reducer;
