import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";
import { actions } from "react-table";

export const getBreedData = createAsyncThunk("getBreedData", async (token) => {
    try {
        const response = await api.get("get_all_breed", {
            headers: {
                Authorization: `token ${token}`,
            },
        })
        return response.data
    } catch (error) {
        throw error;
    }
})


export const createBreedData = createAsyncThunk("createBreedData", async (payload) => {
    try {
        const response = await api.post("create_breed", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.msg
    }
})

export const deleteBreedData = createAsyncThunk("deleteBreedData", async (id, token) => {
    try {
        const response = await api.delete(`delete_breed/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateBreedData = createAsyncThunk("updateBreedData", async (payload) => {

    try {
        const response = await api.put(`update_breed/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateBreedData = createAsyncThunk("getupdateBreedData", async (payload) => {
    try {
        const response = await api.get(`get_breed/${payload.id}`,
            {
                headers: {
                    Authorization: `token ${payload.token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const breedSlice = createSlice({
    name: "breed",
    initialState: {
        status: {
            get : null,
            create : null,
            update : null,
            updating : null,
            delete: null
        },
        breedData: null,
        error: null,
        updateBreedData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getBreedData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getBreedData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.breedData = action.payload
            
        })
        builder.addCase(getBreedData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteBreedData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteBreedData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteBreedData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createBreedData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createBreedData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createBreedData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateBreedData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateBreedData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateBreedData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateBreedData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateBreedData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateBreedData = action.payload

        })
        builder.addCase(getupdateBreedData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default breedSlice.reducer;