import React from 'react'

function Badge({ sx, content, children }) {
    const styles = {
        width: '20px',
        height: '20px',
        background: '#cd5c5c',
        color: '#fff',
        borderRadius: '50%',
        position: 'absolute',
        top: '-4px',
        right: '7px'
    }
    return (
        <>
            <div style={sx ? sx : styles}>{content}</div>
            {children}
        </>
    )
}

export default Badge