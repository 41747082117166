import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";




export const getUpdateFNData = createAsyncThunk(
  "getUpdateFNData",
  async (id, token) => {
    try {
      const response = await api.get(`servise_type_facility_name/${id}`, {

        headers: {
          Authorization: `token ${token}`,
        },
      });
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);


const FacilityNameSlice = createSlice({
  name: "FacilityName",
  initialState: {
    facilityNameData: null,
    update: null,
    status: "",
    updateFacilityNameData: null,
  },
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getUpdateFNData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getUpdateFNData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateFacilityNameData = action.payload;
    });
    builder.addCase(getUpdateFNData.rejected, (state, action) => {
      state.status = "failed";
    });
 
  },
});

export default FacilityNameSlice.reducer;
