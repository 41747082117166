import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../../Apis";

export const getAllCellHorses = createAsyncThunk(
  "getAllCellHorses",
  async (payload) => {
    try {
      const res = await api.get(`get_cell_by_checkin/${payload.id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
        },
      });
      const Apidata = res.data;
      console.log(Apidata, payload.id, "getAllCellHorses");
      return Apidata;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
);
export const createTempInData = createAsyncThunk(
  "createTempInData",
  async (payload) => {
    try {
      const res = await api.post(`create_temporary_inpass`, payload.Data, {
        headers: {
          Authorization: `token ${payload.token}`,
          "Content-Type": "application/json",
        },
      });
      const Apidata = res.data;

      return Apidata;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
);
export const getAllConfirmQuotationData = createAsyncThunk(
  "getAllConfirmQuotationData",
  async (payload) => {
    try {
      const res = await api.post(`all_confirm_quotation`, payload.Data, {
        headers: {
          Authorization: `token ${payload.token}`,
          "Content-Type": "application/json",
        },
      });
      const Apidata = res.data;

      return Apidata;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
);

export const getConfirmQuotationHorse  = createAsyncThunk(
  "getConfirmQuotationHorse",
  async (payload) => {
    try {
      const res = await api.get(`get_quotation_checkin_horse/${payload.Id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
          "Content-Type": "application/json",
        },
      });
      const Apidata = res.data;

      return Apidata;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
)
// use for update 
// export const getTempOpDataID = createAsyncThunk(
//   "getTempOpDataID",
//   async (payload) => {
//     try {
//       const res = await api.get(`get_pass/${payload.Id}`, {
//         headers: {
//           Authorization: `token ${payload.token}`,
//         },
//       });
//       const Apidata = res.data;

//       return Apidata;
//     } catch (error) {
//       console.log(`Error : ${error}`);
//     }
//   }
// );
export const getTempInDataID = createAsyncThunk(
  "getTempInDataID",
  async (payload) => {
    console.log("i am inside getTempInDataId",payload)
    try {
      const res = await api.get(`get_temporary_inpass/${payload.id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
        },
      });
      const Apidata = res.data;
      console.log("GET TEMPORARY OUTPASS DATA-;;;***------>>>",Apidata)

      return res.data;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
);

// delete temporary outpass data
export const deleteTempInpass = createAsyncThunk(
  "deleteTempInpass",
  async (payload) => {
   
    try {
      const res = await api.delete(`delete_temporary_inpass/${payload.id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
        },
      });
      const Apidata = res.data;
      console.log("Delete TEMPORARY OUTPASS DATA-;;;***------>>>",Apidata)

      return res.data;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
);

export const updateTempInData = createAsyncThunk(
  "updateTempInData",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.put(
        `update_temporary_inpass/${payload.id}`,
        payload.UpdatedData,
        {
          headers: {
            Authorization: `token ${payload.token}`,
          },
        }
      );
      const Apidata = res.data;
      return Apidata;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// export const updateTempOpDataID = createAsyncThunk(
//   "updateTempOpDataID",
//   async (payload) => {
//     try {
//       const res = await api.put(`get_pass/${payload.Id}`, {
//         headers: {
//           Authorization: `token ${payload.token}`,
//         },
//       });
//       const Apidata = res.data;

//       return Apidata;
//     } catch (error) {
//       console.log(`Error : ${error}`);
//     }
//   }
// );

export const getTempInData = createAsyncThunk(
  "getTempInData",
  async (token) => {
    try {
      const res = await api.get("get_all_temporary_inpass", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const Apidata = res.data;
      return Apidata;
    } catch (error) {
      console.log(`Error : ${error}`);
    }
  }
);

const initialState = {
  TempInData: null,
  AllCellhorseData: null,

  getDataById: null,
  DropDownData: {
    HorseData: null,
    StallData: null,
  },

  status: {
    Get: "",
    Create: "",
    Updating: "",
    Update: "",
    Delete: "",
  },
  errors: {
    Get: "",
    Create: "",
    Update: "",
    Delete: "",
  },
};

const TemperoryInpassSlice = createSlice({
  name: "temperory_inpass",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCellHorses.pending, (state) => {
      console.log(`Pending State`);
    });
    builder.addCase(getAllCellHorses.fulfilled, (state, action) => {
      state.AllCellhorseData = action.payload;
    });
    builder.addCase(getAllCellHorses.rejected, (state) => {
      console.log(`You got an error`);
    });

    builder.addCase(getTempInData.pending, (state) => {
      state.status.Get = "pending";
    });
    builder.addCase(getTempInData.fulfilled, (state, action) => {
      state.TempInData = action.payload;
      state.status.Get = "Success";
    });
    builder.addCase(getTempInData.rejected, (state) => {
      console.log(`You got an error`);
      state.status.Get = "failed";
    });
    builder.addCase(createTempInData.pending, (state) => {
      state.status.Create = "pending";
    });
    builder.addCase(createTempInData.fulfilled, (state, action) => {
      state.status.Create = "Success";
    });
    builder.addCase(createTempInData.rejected, (state, action) => {
      console.log(`You got an error`);
      state.status.Create = "failed";
      state.errors.Create = action.payload;
    });
    builder.addCase(getTempInDataID.pending, (state) => {
      state.status.Updating = "pending";
    });
    builder.addCase(getTempInDataID.fulfilled, (state, action) => {
      state.status.Updating = "Success";
      console.log("---------------------.....>>>>", action.payload)
      state.getDataById = action.payload;
    });
    builder.addCase(getTempInDataID.rejected, (state, action) => {
      console.log(`You got an error`);
      state.status.Updating = "failed";
      state.errors.Create = action.payload;
    });
    // builder.addCase(updateTempOpDataID.pending, (state) => {
    //   state.status.Update = "pending";
    // });
    // builder.addCase(updateTempOpDataID.fulfilled, (state, action) => {
    //   state.status.Update = "Success";

    //   console.log(action.payload, "0000000000000000000000000000000000000000");
    // });
    // builder.addCase(updateTempOpDataID.rejected, (state, action) => {
    //   console.log(`You got an error`);
    //   state.status.Update = "failed";
    //   state.errors.Update = action.payload;
    // });
    builder.addCase(updateTempInData.pending, (state) => {
      state.status.Update = "pending";
    });
    builder.addCase(updateTempInData.fulfilled, (state, action) => {
      state.status.Update = "Success";

      console.log("GET TEMPERORY OUTPASS DATA------->",action.payload);
    });
    builder.addCase(updateTempInData.rejected, (state, action) => {
      console.log(`You got an error`);
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    builder.addCase(getAllConfirmQuotationData.pending, (state) => {
      state.status.Updating = "pending";
    });
    builder.addCase(getAllConfirmQuotationData.fulfilled, (state, action) => {
      state.status.Updating = "Success";
      state.getDataById = action.payload;
    });
    builder.addCase(getAllConfirmQuotationData.rejected, (state, action) => {
      console.log(`You got an error`);
      state.status.Updating = "failed";
      state.errors.Create = action.payload;
    });
    builder.addCase(getConfirmQuotationHorse.pending, (state) => {
      state.status.Updating = "pending";
    });
    builder.addCase(getConfirmQuotationHorse.fulfilled, (state, action) => {
      state.status.Updating = "Success";
      state.DropDownData.HorseData = action.payload;
    });
    builder.addCase(getConfirmQuotationHorse.rejected, (state, action) => {
      console.log(`You got an error`);
      state.status.Updating = "failed";
      state.errors.Create = action.payload;
    });

    //deletehandler
    builder.addCase(deleteTempInpass.pending, (state) => {
      state.status.Updating = "pending";
    });
    builder.addCase(deleteTempInpass.fulfilled, (state, action) => {
      state.status.Delete = "Success";
      
    });
    builder.addCase(deleteTempInpass.rejected, (state, action) => {
      console.log(`You got an error`);
      state.status.Updating = "failed";
      state.errors.Create = action.payload;
    });
  },
});
export default TemperoryInpassSlice.reducer;
