import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getStableClassData = createAsyncThunk("getStableClassData", async (token) => {
    try {
        const response = await api.get("get_all_class", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createStableClassData = createAsyncThunk("createStableClassData", async (payload) => {
    try {
        const response = await api.post("create_class", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteStableClassData = createAsyncThunk("deleteStableClassData", async (id, token) => {
    try {
        const response = await api.delete(`delete_class/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateStableClassData = createAsyncThunk("updateStableClassData", async (payload) => {
    console.log("payload", payload)
    try {
        const response = await api.put(`update_class/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateStableClassData = createAsyncThunk("getupdateStableClassData", async (id, token) => {
    try {
        const response = await api.get(`get_class/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const StableClassSlice = createSlice({
    name: "StableClass",
    initialState: {
        status: "",
        StableClassData: null,
        error: null,
        updateStableClassData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getStableClassData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getStableClassData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.StableClassData = action.payload
            
        })
        builder.addCase(getStableClassData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteStableClassData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteStableClassData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteStableClassData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createStableClassData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createStableClassData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createStableClassData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateStableClassData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateStableClassData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateStableClassData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateStableClassData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateStableClassData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateStableClassData = action.payload

        })
        builder.addCase(getupdateStableClassData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default StableClassSlice.reducer;