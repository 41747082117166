import React, { useState } from "react";
import styled from 'styled-components';
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { Autocomplete, Modal } from "@mui/material";
import { TextField, Button, Grid, Typography } from "@mui/material";
import FileInput from '../../SharedComponets/Components/excelParser/FileInput'
import { tr } from "date-fns/locale";
import { BsUpload } from "react-icons/bs";


const styles = {
    hTblContainer: {
        margin: '20px',
        overflow: 'auto'
    },
    hTbl: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    },
    hTblTh: {
        backgroundColor: 'rgba(124, 94, 30,0.6)',
        color: '#ffffff',
        fontWeight: 'bold',
        padding: '10px',
        textAlign: 'center',
    },
    hTblTd: {
        padding: '10px',
        borderBottom: '1px solid #ecf0f1',
    },
    hTblEvenRow: {
        // backgroundColor: '#f2f2f2',
        backgroundColor: '#f1ebda',
        borderBottom: '3px solid #ffffff',
    },
    hTblHoverRow: {
        '&:hover': {
            backgroundColor: '#dfe6e9',
        },
    },
};



const HTableContainer = styled.div`
  ${styles.hTblContainer}
`;

const HTable = styled.table`
  ${styles.hTbl}
`;

const HTableTh = styled.th`
  ${styles.hTblTh}
`;

const HTableTd = styled.td`
  ${styles.hTblTd}
`;

const HTableEvenRow = styled.tr`
  ${styles.hTblEvenRow}
`;

const HTableHoverRow = styled.tr`
  ${styles.hTblHoverRow}
`;

// doc styledCss
const docTableTh = styled.th`
  ${styles.hTblTh}
`;

const isArrayData = (data, column = false, PropsData, index) => {
    console.log("data>>", data);
    console.log("index>>", index);
    // console.log("PropsData.modalData.excelData.Users.docs[index] >>", PropsData?.modalData?.excelData?.Users[0].docs);
    // console.log("PropsData?.modalData?.excelData?.Users[index.column_index] >>", PropsData?.modalData?.excelData?.Users[index.horse_index].docs);
    // console.log('PropsData.docType', PropsData?.docType);

    // Date Formator @start
    function DateFormator(dateValue) {
        // Assuming dateValue is in the format MM/DD/YY
        console.log('DateFormator::dateValue', dateValue);
        const [month, day, year] = dateValue.split('/');
        const fullYear = Number(year) >= 50 ? 1900 + Number(year) : 2000 + Number(year);

        // Create a Date object using the parsed values
        const excelDate = new Date(fullYear, Number(month) - 1, Number(day) + 1);

        // Format the date as YYYY-MM-DD
        const formattedDate = excelDate.toISOString().split('T')[0];

        return formattedDate;
    }

    // Date Formator @end

    const cols = Object.keys(data[0]);
    console.log(cols);
    if (column) {
        return (<>
            <HTableTh style={{ padding: '0px' }}>
                <HTableEvenRow style={{ border: 'none' }} >
                    <HTableTh style={{ background: '#a3916b' }} colSpan={5}>Documents</HTableTh>
                </HTableEvenRow>
                <HTableEvenRow style={{ border: 'none' }}>
                    {
                        cols.map((c, i) => (<HTableTh style={i == 0 ? { minWidth: '4.8rem', border: 'none', background: '#a3916b' } : { minWidth: '14rem', border: 'none', background: '#a3916b' }} key={i}>{c}</HTableTh>))
                    }
                </HTableEvenRow>
            </HTableTh>
        </>

        )
    }
    // else if (!(data[0].document_type !== null && data[0].issue_date !== null && data[0].expiry_date !== null)) {
    //     return data.map((d) => (<HTableEvenRow>
    //         {cols.map((c, i) => (<HTableTd key={i}> {d[c]} </HTableTd>))}
    //     </HTableEvenRow>))
    // }
    else {
        return data.map((item, i) => {
            return (<HTableEvenRow style={{ border: 'none' }}>
                <HTableTd style={{ minWidth: '4.8rem' }}>
                    {i + 1}
                    {/* {item.document_type} */}
                    {/* {PropsData?.modalData?.excelData?.Users[index.horse_index].docs[0].document_type} */}
                    {/* {JSON.stringify(PropsData?.modalData?.excelData?.Users[index.horse_index].docs[i])} */}

                </HTableTd>
                <HTableTd style={{ minWidth: '14rem' }}>
                    <Autocomplete
                        name="document_type"
                        value={item.document_type && PropsData.docType && PropsData.docType.find((v) => (v.name === item.document_type) || v.id === item.document_type)}
                        // value={PropsData?.modalData?.excelData?.Users[index.horse_index].docs.length &&
                        //     PropsData?.modalData?.excelData?.Users[index.horse_index].docs[0].document_type &&
                        //     PropsData.docType.find((item) => item.name == String(PropsData?.modalData?.excelData?.Users[index.horse_index].docs[0].document_type))}
                        onChange={(event, value) => PropsData.handleACDocFields(event, value, `document_type`, { sheetName: index.sheetName, fieldName: index.fieldName, sheetIndex: index.sheetIndex, row_number: index.horse_index, column_number: index.column_index, doc_index: i })}
                        disablePortal
                        id="combo-box-demo"
                        options={PropsData.docType ? PropsData.docType : []}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                required
                                className="bg-color"
                                {...params}
                                placeholder="Select Document Type"
                            />
                        )}
                    />
                </HTableTd>
                <HTableTd style={{ minWidth: '14rem' }}>
                    <TextField
                        className="bg-color"
                        type="date"
                        value={item.issue_date && DateFormator(item.issue_date)}
                        name={`issue_date`}
                        // inputProps={{ max: today }}
                        onChange={(event) => PropsData.handleACDocFields(event, event.target.value, `issue_date`, { sheetName: index.sheetName, fieldName: index.fieldName, sheetIndex: index.sheetIndex, row_number: index.horse_index, column_number: index.column_index, doc_index: i })}
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true, }}
                    />
                </HTableTd>
                <HTableTd style={{ minWidth: '14rem' }}>
                    <TextField
                        className="bg-color"
                        type="date"
                        // value={PropsData?.modalData?.excelData?.Users[index.horse_index].docs[0].expiry_date}
                        value={item.expiry_date && DateFormator(item.expiry_date)}
                        name={`expiry_date`}
                        //   inputProps={{ min: today }}
                        onChange={(event) => PropsData.handleACDocFields(event, event.target.value, `expiry_date`, { sheetName: index.sheetName, fieldName: index.fieldName, sheetIndex: index.sheetIndex, row_number: index.horse_index, column_number: index.column_index, doc_index: i })}
                        fullWidth
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </HTableTd>
                <HTableTd style={{ minWidth: '14rem', paddingTop: '20px' }}>
                    <input
                        className="eq-file-input"
                        type="file"
                        required
                        name="uploaded_file"
                        value={item.uploaded_file?.File}
                        id={`h-upload-doc-${index.horse_index}${i}`}
                        onChange={(event) => PropsData.handleACDocFields(event, event.target.files[0], `uploaded_file`, { sheetName: index.sheetName, fieldName: index.fieldName, sheetIndex: index.sheetIndex, row_number: index.horse_index, column_number: index.column_index, doc_index: i })}
                    />
                    {/* <label htmlFor={`h-upload-doc-${index.horse_index}${i}`}>
                        <BsUpload style={{ fontSize: '35px' }} />
                    </label> */}
                </HTableTd>
            </HTableEvenRow>)

        })
    }
}
const HorseTable = ({ horseData, PropsData, sheetIndex, sheetName }) => {
    console.log("horseData", horseData);
    if (horseData.length) {
        const columns = Object.keys(horseData[0]);
        return (
            <HTableContainer>
                <HTable>
                    <thead>
                        <tr>
                            {
                                columns.map((column) => Array.isArray(horseData[0][column]) ? isArrayData(horseData[0][column], true) : (<HTableTh key={column}>{column}</HTableTh>))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {horseData.map((horse, horse_index) => (
                            <HTableEvenRow key={horse_index}>
                                {columns.map((column, column_index) => Array.isArray(horse[column]) ? isArrayData(horse[column], false, PropsData, { sheetName, fieldName: column, sheetIndex, horse_index, column_index }) : (
                                    <HTableTd key={column}>
                                        {
                                            column === 'image' ? <input
                                                className="eq-file-input"
                                                style={{ paddingInline: '5px', width: '11rem' }}
                                                type="file"
                                                required
                                                name="horse_profile"
                                                value={horse[column]?.File}
                                                id={`horse_profile-${horse_index}${column_index}`}
                                                onChange={(event) => PropsData.handleACDocFields(event, event.target.files[0], `horse_profile`, { sheetName: sheetName, fieldName: column, sheetIndex: sheetIndex, row_number: horse_index, column_number: column_index })}
                                            /> :
                                                column === 'health_file' ? <input
                                                    className="eq-file-input"
                                                    style={{ paddingInline: '5px', width: '11rem' }}
                                                    type="file"
                                                    required
                                                    name="health_file"
                                                    value={horse[column]?.File}
                                                    id={`health_file-${horse_index}${column_index}`}
                                                    onChange={(event) => PropsData.handleACDocFields(event, event.target.files[0], `health_file`, { sheetName: sheetName, fieldName: column, sheetIndex: sheetIndex, row_number: horse_index, column_number: column_index })}
                                                />
                                                    :
                                                    column === 'vaccine_file' ? <input
                                                        className="eq-file-input"
                                                        style={{ paddingInline: '5px', width: '11rem' }}
                                                        type="file"
                                                        required
                                                        name="vaccine_file"
                                                        value={horse[column]?.File}
                                                        id={`vaccine_file-${horse_index}${column_index}`}
                                                        onChange={(event) => PropsData.handleACDocFields(event, event.target.files[0], `vaccine_file`, { sheetName: sheetName, fieldName: column, sheetIndex: sheetIndex, row_number: horse_index, column_number: column_index })}
                                                    />
                                                        :
                                                        // <input
                                                        //     className="eq-file-input"
                                                        //     style={{ paddingInline: '5px', width: '11rem' }}
                                                        //     type="text"
                                                        //     required
                                                        //     name="vaccine_file"
                                                        //     value={horse[column]}
                                                        //     // disabled={horse[column] !== undefined ? true : false}
                                                        //     id={`${column}-${horse_index}${column_index}`}
                                                        //     onChange={(event) => PropsData.handleACDocFields(event, event.target.value, column, { sheetName: sheetName, fieldName: column, sheetIndex: sheetIndex, row_number: horse_index, column_number: column_index })}
                                                        // />
                                                        horse[column]


                                        }
                                    </HTableTd>
                                ))}
                            </HTableEvenRow>
                        ))}
                    </tbody>
                </HTable>
            </HTableContainer >
        );

    } else {
        return (<>
            <h1>Please check your excel</h1>
        </>)
    }

}






const ModalComponent = (props) => {

    const [formData, setFormData] = useState()

    const handleSubmit = (e) => {

        props.createOrUpdateHandler()
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#e0e0e0",
        border: "5px solid #bebb9e",
        borderRadius: "10px",
        minWidth: "350px",
        maxWidth: "100%",
        minHeight: "350px",
        maxHeight: "100%",
        overflow: "auto",
        p: 4,
        "@media (max-width: 576px)": {
            width: "90%",
        },
    };

    const customStyle = {
        'container': { marginTop: ".5rem", },
        'label': { marginTop: ".5rem" },
        'inputContent': { textAlign: 'center', padding: '1rem' },
        'inputContentIcon': { cursor: 'pointer', fontSize: '5rem', color: '#107c41' },
        'fileInput': { position: 'absolute', left: '-10000000px' },
    }

    return (
        <>
            <Modal open={props.modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} className="scroll-bar">
                    <Button
                        sx={{
                            minWidth: '30px',
                            color: "#302924",
                            border: "0px",
                            float: "right",
                            padding: "0px",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                                backgroundColor: "#e0e0e0",
                            },
                        }}
                    >
                        <RxCross2 style={{ color: "#302924", fontSize: "1.7rem" }} onClick={props.closeModal} />
                    </Button>

                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#302924", marginBottom: "5vh", fontWeight: "600", fontSize: "18px" }}>
                        Import Horse Data
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        <Grid xs={12} sm={6} md={4}>
                            <Grid item xs={12} sm={6} md={4}>
                                <label>{'Select Quotation'.toUpperCase()}</label>
                                <Autocomplete
                                    name="quotation"
                                    className="autocomplete-mt"
                                    options={props.contract ? props.contract : []}
                                    getOptionLabel={(option) => `${option.name} (${option.code})`}
                                    value={props.contract && props.modalData.quotation && props.contract.find(
                                        (item) => item.id === Number(props.modalData.quotation)
                                    )}
                                    onChange={(event, value) =>
                                        props.handleAutoComplete(event, value, "quotation")
                                    }
                                    disablePortal
                                    id="combo-box-demo"
                                    required
                                    sx={{ color: "#fff " }}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            placeholder="Select props.Contract"
                                            {...params}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FileInput customStyle={customStyle} onFileSelect={props.handleFileSelect} />
                                {/* {props.modalData.excelData && <HorseTable horseData={props.modalData.excelData} />} */}
                                {props.modalData.excelData
                                    &&
                                    Object.keys(props.modalData.excelData).map((item, index) => {

                                        return (< HorseTable key={index}
                                            PropsData={props}
                                            horseData={props.modalData.excelData[item]}
                                            sheetIndex={index}
                                            sheetName={item}
                                        />)
                                    })

                                }


                            </Grid>

                        </Grid>
                        <Button
                            variant="outlined"
                            type="submit"
                            sx={{
                                color: "#fff",
                                borderColor: "#302924",
                                "&:hover": {
                                    borderColor: "#302924",
                                    color: "#302924",
                                },
                                marginTop: "15px",
                                float: "right",
                                fontSize: "12px",
                                backgroundColor: "#302924",
                            }}
                        // onClick={props.createOrUpdateHandler}
                        >
                            Import
                        </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default ModalComponent;
