import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./partners.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ImgUrl } from "../../Config/Config";
import { footerCarouselDelete } from "../../App/Reducers/SharedReducer";
import { deletePartners, getAllPartners, uploadPartners } from "../../App/Reducers/FooterReducre";
import { useCookies } from "react-cookie";
import { partnersData } from "../../Assets/DefaultImagesCarousel";

const Partners = () => {

  const Images = useSelector((state) => state.footer.partnersData);
  console.log("Images partner", Images);

  const [defaultImages, setDefaultImages] = useState(partnersData);
  const isloggedIn = localStorage.getItem("adminloggedIn");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;

  const [formData, setFormData] = useState({ images: null, });


  const [headerdivClass, setHeaderDivClass] = useState("sponsors-main-div");
  const themeSelector = useSelector((state) => state.theme.ThemeColor);



  const dispatch = useDispatch();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 200,
    cssEase: "linear",
    nextArrow: null,
    variableWidth: true,
  };

  // =====================Modal variables and functions Start==============================
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    boxShadow: 24,
    height: "400px",
    overflow: "scroll",
    p: 4,
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };
  
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    handleOpen();
  };

  // =====================Modal variables and functions Start==============================

  useEffect(() => {
    switch (themeSelector) {
      case "header-white":
        setHeaderDivClass("sponsors-main-div header-white");
        break;
      case "header-roseate":
        setHeaderDivClass("sponsors-main-div header-roseate");
        break;
      case "header-skyBlue":
        setHeaderDivClass("sponsors-main-div header-skyBlue");
        break;
      case "header-grassy":
        setHeaderDivClass("sponsors-main-div header-grassy");
        break;
      case "header-pink":
        setHeaderDivClass("sponsors-main-div header-pink");
        break;
      case "header-gold":
        setHeaderDivClass("sponsors-main-div header-gold");
        break;
      case "header-orange":
        setHeaderDivClass("sponsors-main-div header-orange");
        break;
      default:
        setHeaderDivClass("sponsors-main-div header-gold ");
        break;
    }
  }, [themeSelector]);


  const handleImageChange = (e) => {
    const { files } = e.target;

    if (files) {
      const selectedFile = files[0];
      setFormData((previous) => ({
        ...previous,
        image: selectedFile,
      }));

    }

  }

  const deleteHandler = (id) => {
    dispatch(deletePartners({ id, token }))
    dispatch(getAllPartners(token))
    dispatch(getAllPartners(token))
  }

  const removeItem = (i) => {
    const updatedImages = [...defaultImages];
    updatedImages.splice(i, 1);
    setDefaultImages(updatedImages);
};

  const handleSubmit = (e) => {
    if (formData) {
      dispatch(uploadPartners({ formData, token }))
      dispatch(getAllPartners(token))
    }
    dispatch(getAllPartners(token))
  };

  useEffect(() => {
    dispatch(getAllPartners(token))
  }, [])


  return (
    <>
      <div className={headerdivClass} style={{ position: "relative" }}>
        <h2 className="sponser-title">OUR SPONSORS</h2>
        <div style={{ position: "absolute", top: "-60px", right: "15px" }}>
          {isloggedIn ? userData && userData.usertype?.name === "Admin" && (
            <i class="fas fa-edit" style={{ fontSize: "20px", color: "#e7d9ce", margin: "80px 0px", cursor: "pointer", }} title="Edit Paragraph" onClick={handleEdit} />)
            : null}
        </div>
        <div className="slider-div" style={{ height: "150px", overflow: "hidden", backgroundColor: "#bebb9e00" }}>
          <Slider {...settings}>
            {Images && Images.length > 0 ?
              (Images.length > 0 && Images.map((item, i) =>

              (
                <div className="slide-child">
                  <img
                    style={{ width: "130px", height: "130px" }}
                    src={`${ImgUrl}${item.image}`}
                    alt="not found"
                  />
                </div>
              )
              )) : (
                defaultImages.length > 0 && defaultImages.map((item, i) => (
                  <div className="slide-child">
                    <img
                      style={{ width: "130px", height: "130px" }}
                      src={item.image && item.image}
                      alt="not found"
                    />
                  </div>
                ))
              )}


          </Slider>
          {/* ==========EDIT MODAL START========= */}

          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} className="scroll-bar">
              <Button
                variant="outlined" sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, margin: "5px", float: "right", fontSize: "12px", backgroundColor: "#302924", }}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                variant="outlined" sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, margin: "5px", float: "right", fontSize: "12px", backgroundColor: "#302924", }}
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button variant="outlined" sx={{ color: "#302924", borderColor: "#bebb9e", "&:hover": { borderColor: "#302924 ", color: "black", }, margin: "5px", float: "right", fontSize: "12px" }}>
                <input type="file" name="images" onChange={handleImageChange} required />
              </Button>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ color: "#302924", marginBottom: "5vh", fontWeight: "600" }}
              >
                EDIT IMAGES
              </Typography>

              <div className="img-list-div">
                <div className="img-list" >

                  {Images && Images.length > 0 ?
                    (Images.length > 0 && Images.map((item, i) => (
                      <div className="img-list-left">
                        <div className="img-list-with-remove">
                          <img src={`${ImgUrl}${item.image}`} alt={item.image && item.id} />
                          <Button variant="outlined" onClick={() => deleteHandler(item.id)} sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, fontSize: "12px", backgroundColor: "#302924", }}>Remove</Button>
                        </div>
                      </div>
                    ))) :
                    (
                      defaultImages.length > 0 && defaultImages.map((item, i) => (
                        <div className="img-list-left">
                          <div className="img-list-with-remove">
                            <img src={item.image} alt={item.id} />
                            <Button variant="outlined" onClick={() => removeItem(i)} sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, fontSize: "12px", backgroundColor: "#302924", }}>Remove</Button>
                          </div>
                        </div>
                      ))
                    )
                  }


                </div>
              </div>
            </Box>
          </Modal >
          {/* ==========EDIT MODAL END========= */}
        </div>
      </div>
    </>
  );
};

export default Partners;
