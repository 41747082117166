import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { uploadCarousel, getAllCarousel, deleteCarousel, } from "../App/Reducers/HomeCarouselReducer";
import { useCookies } from 'react-cookie';
import { ImgUrl } from "../Config/Config";
import { defaultImages } from "../Assets/DefaultImagesCarousel";

const Carousel = () => {

  const dispatch = useDispatch();

  const Images = useSelector((state) => state.homeCarousel.homecarouselApiDataAll);
  const [defaultImage, setDefaultImages] = useState(defaultImages)

  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;

  const isloggedIn = localStorage.getItem("adminloggedIn");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [editmodalopen, setEditModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    images: null, // Initialize an empty array for multiple images
  });

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false, // Disable pause on hover
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    boxShadow: 24,
    height: "400px",
    overflow: "auto",
    p: 4,
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };


  const handleClose = () => {
    setEditModalOpen(false);
  };

  const handleOpenHeroCaresoul = () => {
    setEditModalOpen(true);
  };

  const handleImageChange = (e) => {
    const { files } = e.target;

    if (files) {
      const selectedFile = files[0];
      setFormData((previous) => ({
        ...previous,
        image: selectedFile,
      }));

    }

  }

  const deleteHandler = (id) => {
    dispatch(deleteCarousel({ id, token }))
    dispatch(getAllCarousel(token))
    dispatch(getAllCarousel(token))
  }

  const removeItem = (i) => {
    const updatedImages = [...defaultImage];
    updatedImages.splice(i, 1);
    setDefaultImages(updatedImages);
};

  const handleSubmit = (e) => {
    if (formData) {
      dispatch(uploadCarousel({ formData, token }))
      dispatch(getAllCarousel(token))
    }
    dispatch(getAllCarousel(token))
  };

  useEffect(() => {
    dispatch(getAllCarousel(token))
  }, [])

  return (
    <>
      <div className="imgslider">
        <Slider {...settings}>
          {Images && Images.length > 0 ?
            (Images.length > 0 && Images.map((item) => (
              <div className="modifyimage" key={item.id}>
                <img src={item.image && `${ImgUrl}${item.image}`} alt={item.image && item.id} />
              </div>
            ))) :
            (
              defaultImages.length > 0 && defaultImages.map((item) => (
                <div className="modifyimage" key={item.id}>
                  <img src={item.image} alt={item.id} />
                </div>
              ))
            )
          }
        </Slider>
        <div
          style={{
            position: "absolute",
            bottom: "2rem",
            right: "2rem",
            zIndex: "2000",
          }}
        >

          {isloggedIn ? userData && userData.usertype?.name === "Admin" && (
            <i class="fas fa-edit" style={{ fontSize: "15px", color: "#302924", margin: "10px 0px", cursor: "pointer", }} onClick={handleOpenHeroCaresoul} title=" Edit Header Section" />
          ) : null}
        </div>
      </div>

      <Modal open={editmodalopen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="scroll-bar">
          <Button
            variant="outlined" sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, margin: "5px", float: "right", fontSize: "12px", backgroundColor: "#302924", }}
            onClick={() => setEditModalOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="outlined" sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, margin: "5px", float: "right", fontSize: "12px", backgroundColor: "#302924", }}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button variant="outlined" sx={{ color: "#302924", borderColor: "#bebb9e", "&:hover": { borderColor: "#302924 ", color: "black", }, margin: "5px", float: "right", fontSize: "12px" }}>
            <input type="file" name="images" onChange={handleImageChange} required />
          </Button>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#302924", marginBottom: "5vh", fontWeight: "600" }}
          >
            EDIT IMAGES
          </Typography>

          <div className="img-list-div">
            <div className="img-list" >

              {Images && Images.length > 0 ?
                (Images.length > 0 && Images.map((item, i) => (
                  <div className="img-list-left">
                    <div className="img-list-with-remove">
                      <img src={`${ImgUrl}${item.image}`} alt={item.image && item.id} />
                      <Button variant="outlined" onClick={() => deleteHandler(item.id)} sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, fontSize: "12px", backgroundColor: "#302924", }}>Remove</Button>
                    </div>
                  </div>
                ))) :
                (
                  defaultImage.length > 0 && defaultImage.map((item, i) => (
                    <div className="img-list-left">
                      <div className="img-list-with-remove">
                        <img src={item.image} alt={item.id} />
                        <Button variant="outlined" onClick={() => removeItem(i)} sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, fontSize: "12px", backgroundColor: "#302924", }}>Remove</Button>
                      </div>
                    </div>
                  ))
                )
              }


            </div>
          </div>
        </Box>
      </Modal >

    </>
  );
};
export default Carousel;
