import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AboutComp from '../pages/AboutUs/AboutComp';
import HeroSectionModal from './HeroSectionModal';
import HeroImage from "./HeroImage"
import ArticalSection from "./ArticalSection"
import ArticleSectionHeadingModal from './ArticleSectionHeadingModal';
const
  CommonEditablePage = (props) => {
    const toolbarOptions = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["code-block"],

        [{ header: 1 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ],
    };

    const modalSelectorId = props.modalSelector && props.modalSelector.find(item => item.id === 1);

    const [cookies, setCookies] = useCookies(["token"])
    const token = cookies.token;

    const dispatch = useDispatch();

    const isloggedIn = localStorage.getItem("adminloggedIn");
    const userData = JSON.parse(localStorage.getItem("userData"));

    const [quillModal, setQuillModal] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [headingModalOpen, sethHeadingModalOpen] = useState(false)
    const [value, setValue] = useState(modalSelectorId && modalSelectorId.body_content ? modalSelectorId.body_content : props.default_body_content)


    const [formData, setFormData] = useState(
      {
        id: null,
        image_title: null,
        image: null,
        body_title: null,
        body_content: null,
      }
    )

    const closeModal = () => {
      setModalOpen(false)
    }

    const openModal = () => {
      setModalOpen(true)
    }

    const headingCloseModal = () => {
      sethHeadingModalOpen(false)
    }

    const headingOpenModal = () => {
      sethHeadingModalOpen(true)
    }

    const openQuill = () => {
      setQuillModal(true)
    }

    const closeQuill = () => {
      setQuillModal(false)
    }

    const handleModalInputChange = (e) => {
      const { name, value } = e.target
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

    }

    const handleImageChange = (e) => {
      const { files } = e.target;
      setFormData((previous) => ({
        ...previous,
        image: files[0],
      }))

    }



    //HandleBhlur is a function for modifying in image_title
    const handleBlur = () => {

      if (modalSelectorId) {
        dispatch(props.updateApiFunc({ token, id: modalSelectorId.id, data: { image_title: formData.image_title } }));
      } else {
        dispatch(props.createApiFunc({ token, data: { image_title: formData.image_title } }))
      }

      dispatch(props.getApiFunc(token));

    }

    //HandleBhlur is a function for modifying in image
    const handleSubmit = () => {

      if (modalSelectorId) {
        dispatch(props.updateApiFunc({ token, id: modalSelectorId.id, data: { image: formData.image } }))
      } else {
        dispatch(props.createApiFunc({ token, data: { image: formData.image } }))
      }
      dispatch(props.getApiFunc(token));
      closeModal();
    }

    //HandleBhlur is a function for modifying in body_title
    const handleUpdate = () => {
      if (modalSelectorId) {
        dispatch(props.updateApiFunc({
          token: token,
          id: modalSelectorId.id,
          data: {
            image_title: formData.image_title,
            body_title: formData.body_title,
            body_content: formData.body_content
          }
        }))
      } else {
        dispatch(props.createApiFunc({ token, data: { body_title: formData.body_title } }))
      }
      dispatch(props.getApiFunc(token))
      headingCloseModal()
    }
    //HandleBhlur is a function for modifying in body_content
    const saveQuill = () => {
      setQuillModal(false)
      if (modalSelectorId) {

        dispatch(props.updateApiFunc({
          token: token,
          id: modalSelectorId.id,
          data: {
            body_content: value
          }
        }))
      } else {
        dispatch(props.createApiFunc({
          token, data: {
            body_content: value
          }
        }))
      }
      dispatch(props.getApiFunc(token));
    }

    useEffect(() => {
      dispatch(props.getApiFunc(token))
    }, [dispatch]);



  return (
    
      <div style={{ position: "relative" }}>
        {/* -----------------Hero image Section Start----------------- */}
        <div style={{ position: "relative" }}>

          <HeroImage formData={formData} modalSelector={props.modalSelector} default_image_title={props.default_image_title} default_image={props.default_image} />

          <div style={{ position: "absolute", bottom: "10px", right: "20px", zIndex: "1000" }}>
          {  isloggedIn ? userData && userData.usertype?.name === "Admin" && (
              <i class="fas fa-edit" style={{ fontSize: "20px", color: "#bebb9e", cursor: "pointer", float: "right" }} onClick={openModal} title=" Edit Header Section" />
            ) : null}
          </div>
        </div>

        <HeroSectionModal modalOpen={modalOpen} closeModal={closeModal} handleImageChange={handleImageChange} handleModalInputChange={handleModalInputChange} formData={formData} handleBlur={handleBlur} handleSubmit={handleSubmit} modalSelector={props.modalSelector} default_image_title={props.default_image_title} default_image={props.default_image} />

        {quillModal && <>
          <Button variant="outlined" sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, marginTop: "15px", marginRight: "10px", float: "right", fontSize: "12px", backgroundColor: "#302924", }}
            onClick={saveQuill}>Save
          </Button>
          <Button variant="outlined" sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, marginTop: "15px", marginRight: "10px", float: "right", fontSize: "12px", backgroundColor: "#302924", }}
            onClick={closeQuill}
          >
            CLOSE
          </Button>

          <ReactQuill
            theme="snow"
            modules={toolbarOptions}
            value={value}
            onChange={setValue}
          />
        </>}

        <ArticalSection
          formData={formData}
          openQuill={openQuill}
          headingOpenModal={headingOpenModal}
          bodyComponent={<AboutComp value={`${value}`} />}
          default_image_title={props.default_image_title}
          modalSelector={props.modalSelector}
        />

        <ArticleSectionHeadingModal formData={formData} headingModalOpen={headingModalOpen} headingCloseModal={headingCloseModal} headingOpenModal={headingOpenModal} handleModalInputChange={handleModalInputChange} handleUpdate={handleUpdate} />

      </div>

    );
  };

export default CommonEditablePage;