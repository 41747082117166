import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { useCookies } from "react-cookie";
import { Modal } from "@mui/material";
import { TextField, Button, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import {
  createTax,
  deleteTax,
  getTax,
  updateTax,
} from "../../App/Reducers/AdminSettingReducer/TaxReducer";
import ModalComp from "./Modal";
import DeleteModal from "../../App/Helpers/DeleteModal";
import api from "../../Apis";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../App/Helpers/AlertMessage";

const TaxMaster = () => {
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const taxData = useSelector((state) => state.tax.taxData);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [ErrorValue, setErrorValue] = useState(false);
  const [modalData, setModalData] = useState({
    name: "",
    rate: "",
  });

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addTaxMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_taxmaster"
      )
    : false;
  const changeTaxMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_taxmaster"
      )
    : false;
  const deleteTaxMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_taxmaster"
      )
    : false;

  const openModal = () => {
    setModalData({
      name: "",
      rate: "",
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "rate" && !/^\d*$/.test(value) && modalData.capacity !== "") {
      setErrorValue(true);
      return;
    } else {
      setErrorValue(false);
      setModalData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const createOrUpdateHandler = () => {
    if (modalData.id) {
      dispatch(
        updateTax({
          id: modalData.id,
          updatedData: {
            name: modalData.name,
            rate: modalData.rate,
          },
          token,
        })
      );
    } else {
      dispatch(createTax({ modalData, token }));
    }
    closeModal();
    dispatch(getTax(token));
  };

  const editHandler = (id) => {
    const editData = taxData.find((data) => data.id === id);
    if (editData) {
      setModalData(editData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_tax/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getTax(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  useEffect(() => {}, [taxData]);

  useEffect(() => {
    dispatch(getTax(token));
  }, [dispatch, token, modalOpen]);

  const header = [
    {
      Header: "Tax Name",
      accessor: "name",
    },
    {
      Header: "Tax Percentage",
      accessor: "rate",
    },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {taxData ? (
        <BasicTable
          colHeader={header}
          rowData={taxData}
          actionFlag={
            deleteTaxMasterPermission || changeTaxMasterPermission || isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeTaxMasterPermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteTaxMasterPermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addTaxMasterPermission || isUserType ? openModal : null
          }
          tableHeading="Tax"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            deleteTaxMasterPermission || changeTaxMasterPermission || isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeTaxMasterPermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteTaxMasterPermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addTaxMasterPermission || isUserType ? openModal : null
          }
          tableHeading="Tax"
        />
      )}

      <ModalComp
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        label="ADD TAX MASTER"
        heading="Tax Master"
        rateFieldData={modalData.rate}
        ErrorValue={ErrorValue}
        showTaxPercentageField="true"
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Tax"
        deleteData={deleteData}
      />

      <ToastContainer />
    </>
  );
};

export default TaxMaster;
