import React, { useContext, useEffect, useMemo, useState } from "react";
import card1 from "../../Assets/RidingSchoolAssets/defaultimg2.jpg";
import { IoIosArrowDown } from "react-icons/io";
import "../pages/ridedetails.css";
import BasicDateCalendar from "../components/DateCalendar";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import courses from "../../App/Apis/RidingSchoolApis/courseApi/course";
import { ImgUrl } from "../../Config/Config";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import SlotApi from "../../App/Apis/RidingSchoolApis/SlotApis/SlotApi";
import { useStripe } from "@stripe/react-stripe-js";
import PaymentModal from "./PaymentModal/PaymentModal";
import Context from "../../Store/Context/Context";

const RidingDetails = () => {
  const { getLocalStorage } = useContext(Context);
  const stripe = useStripe();
  //   console.log("stripe", stripe);
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [courseDetail, setCourseDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [slotactive, setSlotActive] = useState(null);
  const [slots, setSlots] = useState([]);
  const [availableSlotInfo, setAvailableSlotInfo] = useState({
    color: null,
    msg: null,
    seats: 1,
  });
  const [counts, setCounts] = useState({ child: 0, adult: 0 });
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState({
    additionalInfo: false,
    cancellationInfo: false,
  });
  const isStudentLoggedIn = JSON.parse(localStorage?.getItem("isStudent"));
  const studentData = JSON.parse(localStorage?.getItem("student_data"));
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [, setCompany__UID] = useState("");

  //   console.log("studentData", studentData);

  const handleBookNow = () => {
    if (!isStudentLoggedIn) {
      // navigate to login
      navigate("/student-login");
    } else {
      if (!availableSlotInfo.msg) {
        toast.warning("Please Select Slot First");
      } else {
        setPaymentModalOpen(!paymentModalOpen);
      }
    }
  };

  const handleAddToCart = () => {
    // console.log('courseDetail', courseDetail);
    const cart = JSON.parse(localStorage.getItem("Cart"));
    // console.log('Cart', cart);
    const slotForCart = slots.filter((item) => item.id === slotactive);
    console.log("slots", slotactive);
    const cartPayload = {
      image: courseDetail.image,
      counts,
      courseId: courseId,
      coursedata: courseDetail?.course_name,
      company: {
        id: courseDetail.company.id,
        company_name: courseDetail.company.company_name,
      },
      slot: {
        id: slotForCart[0].id,
        time: slotForCart[0].time,
        lesson: courseDetail.slot,
        availableSlotInfo,
      },
      price: courseDetail.price,
      // child_price: courseDetail.child_price,
      total_price: total,
      studenId: studentData?.id,
      persons: counts.adult,
      // childs: counts.child,
    };

    if (cart) {
      // cart items alredy exists
      const cartArray = JSON.parse(localStorage.getItem("Cart"));
      const itemAlreadyExists = cartArray.filter(
        (d) => d.slot.id === cartPayload.slot.id
      );
      if (Boolean(itemAlreadyExists.length)) {
        toast.error("Item Already Exists!");
      } else {
        cartArray.push(cartPayload);
        localStorage.setItem("Cart", JSON.stringify(cartArray));
        toast.success("Item added to cart successfully!");
        setCounts({ child: 0, adult: 0 });
        setTotal(0);
        // window.location.reload();
      }
    } else {
      // no cart items exists
      const cartArray = [];
      cartArray.push(cartPayload);
      localStorage.setItem("Cart", JSON.stringify(cartArray));
      toast.success("Item added to cart successfully!");
      setCounts({ child: 0, adult: 0 });
      setTotal(0);
      // window.location.reload();
    }
    getLocalStorage();
  };

  const handleOpen = (sectionName) => {
    setOpen((prev) => {
      const updatedState = {
        ...prev,
        [sectionName]: !prev[sectionName],
      };

      // Close the other property if it's open

      if (updatedState[sectionName]) {
        const otherDescName =
          sectionName === "additionalInfo"
            ? "cancellationInfo"
            : "additionalInfo";
        updatedState[otherDescName] = false;
      }

      return updatedState;
    });
  };
  useMemo(() => {
    setTotal(
      counts.adult * courseDetail?.price
      // counts.child * courseDetail?.child_price
    );
  }, [counts.adult]);

  const handleCount = (name, type) => {
    if (!availableSlotInfo.msg) {
      toast.warning("Please Select Slot First");
      return;
    }
    setCounts((prevCounts) => {
      const newCounts = { ...prevCounts };
      if (type === "minus" && newCounts[name] > 0) {
        newCounts[name] -= 1;
      } else if (type === "plus") {
        newCounts[name] += 1;
      }
      return newCounts;
    });
  };

  const handleSlotActive = async (item, index) => {
    setSlotActive(item.id);
    // setIsLoading(true);
    try {
      const res = await SlotApi.availableSlotSteats(item.id);
      if (res.data > 0) {
        setAvailableSlotInfo({
          color: "green",
          msg: `${res.data} Seats are available`,
          seats: res.data,
        });
      } else {
        setAvailableSlotInfo({
          color: "red",
          msg: `0 Seats are available`,
          seats: 0,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };
  const getCourseDetails = async () => {
    setIsLoading(true);
    try {
      const res = await courses.getCourseDetail(courseId);
      setCourseDetail(res.data.data);
      setCompany__UID(res.data?.data?.company?.id);
      const response = await SlotApi.getCourseSlots(courseId);
      setSlots(response.data);
    } catch (error) {
      console.log("eroro", error);
    }
    setIsLoading(false);
  };

  const handleBuyNow = async () => {
    setIsLoading(true);
    setPaymentModalOpen(false);
    try {
      const res = await courses.buyCourse({
        total_price: total,
        courseId: courseId,
        studenId: studentData?.id,
        coursedata: courseDetail?.course_name,
        slot_id: slotactive,
        persons: counts.adult,
        childs: counts.child,
      });
      stripe.redirectToCheckout({ sessionId: res.data.sessionId });
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCourseDetails();
  }, []);

  useEffect(() => {}, [courseDetail]);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (e) => {
    console.log("e", e);
    setSelectedOption(e);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      width: "20rem",
    }),
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <header className="ridedetails__container">
          <main className="ridedetails__main">
            {/* <h1>One Hour Ride- 10:00 am</h1> */}
            <h1>{courseDetail?.course_name}</h1>
            <section className="ridedetails__section1">
              <img
                src={
                  courseDetail?.image
                    ? `${ImgUrl}${courseDetail?.image}`
                    : card1
                }
                alt=""
              />
              <div>
                {/* <span>
              <RxStopwatch />
            </span>
            <p>1h</p> */}
                <h1>{courseDetail?.company?.company_name}</h1>
              </div>
              <h5>Slots</h5>
              <div className="slots_clock">
                {slots?.map((item, index) => (
                  <div
                    className={`slot-clock-div ${
                      item.id == slotactive ? "slot-active" : null
                    }`}
                    onClick={(e) => handleSlotActive(item, index)}
                  >
                    <p>{item?.time}</p>
                  </div>
                ))}
              </div>

              {/* <p>
            Come join us for Hourly Rides along the dyke at the confluence of
            the Ryan and Lillooet River. The Hourly Rides continue through quiet
            wooded forests, grassy fields bright with a display of wild flowers
            and even through one of the local rivers (levels permitting). The
            Hourly Rides show off the tranquility of the Pemberton Valley
            nestled under the watchful eye of Mount Currie.
          </p> */}
              <p>{courseDetail?.description}</p>
            </section>
            <hr />

            <section className="ridedetails__section2">
              <h3>Notes & Restrictions</h3>
              {/* <p>
            All riders except for pony rides must be 4' tall Please wear
            closed-toed shoes or boots and long pants Seemless sign in,
            Smartwaiver and payment must be completed prior to arrival during
            the online booking process. We limit our online booking to a maximum
            of 6 riders but we can accommodate larger groups. If you are trying
            to book a larger group please email or call.
          </p> */}
              <p>{courseDetail?.note_and_restrication}</p>
            </section>
            <hr />

            <section className="ridedetails__section3">
              <div onClick={() => handleOpen("additionalInfo")}>
                <h3>Additional Info</h3>
                <span className={open.additionalInfo ? "detail__open" : ""}>
                  <IoIosArrowDown />
                </span>
              </div>
              <p
                className={
                  open.additionalInfo ? "detail__show" : "detail__hide"
                }
              >
                {courseDetail?.additional_info}
              </p>
            </section>
            <hr />

            <section className="ridedetails__section4">
              <div onClick={() => handleOpen("cancellationInfo")}>
                <h3>Cancellation Policy</h3>
                <span className={open.cancellationInfo ? "detail__open" : ""}>
                  <IoIosArrowDown />
                </span>
              </div>
              <p
                className={
                  open.cancellationInfo ? "detail__show" : "detail__hide"
                }
              >
                {courseDetail?.cancellation_policy}
              </p>
            </section>
            <hr />
          </main>

          <main className="ridedetails__checkout__container">
            <div className="ridedetails__checkout">
              <section className="checkOut__calendarSection">
                <BasicDateCalendar />
              </section>

              <section className="checkout__section">
                <span>Guests</span>
                <div className="checkout__booking__details">
                  <div className="checkout__booking__cart1">
                    <span>Rider(s)</span>
                    <span>Rs. {courseDetail?.price}</span>
                  </div>
                  <div className="checkout__booking__cart2">
                    <button
                      className={counts.adult === 0 ? "checkout__inActive" : ""}
                      onClick={() => handleCount("adult", "minus")}
                    >
                      <FaMinus />{" "}
                    </button>
                    <span> {counts.adult} </span>
                    <button
                      disabled={
                        counts.adult + counts.child === availableSlotInfo.seats
                          ? true
                          : false
                      }
                      className={
                        availableSlotInfo.seats
                          ? counts.adult + counts.child ===
                            availableSlotInfo.seats
                            ? "checkout__inActive"
                            : null
                          : null
                      }
                      onClick={() => handleCount("adult", "plus")}
                    >
                      <FaPlus />{" "}
                    </button>
                  </div>
                </div>
                {/* <div className="checkout__booking__details">
                  <div className="checkout__booking__cart1">
                    <span>Children</span>
                    <span>Rs. {courseDetail?.child_price}</span>
                  </div>
                  <div className="checkout__booking__cart2">
                    <button
                      className={counts.child === 0 ? "checkout__inActive" : ""}
                      onClick={() => handleCount("child", "minus")}
                    >
                      <FaMinus />{" "}
                    </button>
                    <span> {counts.child} </span>
                    <button
                      className={
                        availableSlotInfo.seats
                          ? counts.adult + counts.child ===
                            availableSlotInfo.seats
                            ? "checkout__inActive"
                            : null
                          : null
                      }
                      disabled={
                        counts.adult + counts.child === availableSlotInfo.seats
                          ? true
                          : false
                      }
                      onClick={() => handleCount("child", "plus")}
                    >
                      <FaPlus />{" "}
                    </button>
                  </div>
                </div> */}

                <div className="checkout__total">
                  <span>Total: &nbsp;Rs. {total ? total : 0}</span>
                </div>
                {/* <NavLink
              to={isStudentLoggedIn ? "/buynow" : "/student-login"}
              className="checkout__buyNow"
              
            >
              <>Buy Now</
              button>
            </NavLink> */}
                {availableSlotInfo && (
                  <span
                    style={{
                      color: availableSlotInfo.color,
                      marginLeft: "3rem",
                    }}
                  >
                    {availableSlotInfo.msg}
                  </span>
                )}
                <button
                  disabled={total ? false : true}
                  className="checkout__buyNow"
                  style={{ background: total ? "" : "#D3D3D3" }}
                  onClick={handleAddToCart}
                >
                  Add to Cart
                </button>

                <button
                  disabled={total ? false : true}
                  className="checkout__buyNow"
                  style={{ background: total ? "" : "#D3D3D3" }}
                  onClick={handleBookNow}
                >
                  Buy Now
                </button>
              </section>
            </div>
            <div>
              {paymentModalOpen ? (
                <PaymentModal
                  handleBuyNow={handleBuyNow}
                  setPaymentModalOpen={setPaymentModalOpen}
                  isLoading={isLoading}
                  total_price={total}
                  courseId={courseId}
                  studentId={studentData?.id}
                  coursedata={courseDetail?.course_name}
                  slot_id={slotactive}
                  persons={counts.adult}
                />
              ) : null}
            </div>
          </main>
        </header>
      )}
    </>
  );
};

export default RidingDetails;
