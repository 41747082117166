import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Apis";


export const getHorseData = createAsyncThunk("getHorseData", async (token) => {
  try {
    const response = await api.get("get_all_horse", {
      headers: {
        Authorization: `token ${token}`,
      }
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
})

export const getQuotationHorse = createAsyncThunk(
  "getQuotationHorse",
  async (id, token) => {
    try {
      const response = await api.get(`get_quotation_horse/${id}`, {

        headers: {
          Authorization: `token ${token}`,
        },
      });
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const createHorseData = createAsyncThunk("createHorseData", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("create_horse", payload.fData, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `token ${payload.token}`
      }
    })
    return response.data
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const deleteHorseData = createAsyncThunk("deleteHorseData", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.delete(`delete_horse/${payload.id}`, {
      headers: {
        Authorization: `token ${payload.token}`
      }
    })
    return response.data
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const updateHorseData = createAsyncThunk("updateHorseData", async (payload) => {
  try {
    const response = await api.put(`update_horse/${payload.id}`, payload.fData, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `token ${payload.token}`
      }
    })
    return response.data
  } catch (error) {
   throw error
  }
})

export const getUpdateHorseData = createAsyncThunk("getUpdateHorseData", async (payload) => {
  try {
    const response = await api.get(`get_horse/${payload.id}`, {
      headers: {
        Authorization: `token ${payload.token}`
      }
    })
    return response.data
  } catch (error) {
    throw error
  }
})
export const getHorseProfileData = createAsyncThunk("getHorseProfileData", async (payload) => {
  try {
    const response = await api.get(`get_horse_profile/${payload.id}`, {
      headers: {
        Authorization: `token ${payload.token}`
      }
    })
    return response.data.data
  } catch (error) {
    throw error
  }
})
const HorseRegistrationReducer = createSlice({
  name: "horseRegistration",
  initialState: {
    status: "",
    data: null,
    updateHorseData: null,
    updatedHorseProfileData: null,
    quotationHorse: null
  },
  reducers: {
    addHorseData(state, action) {
      state.status = action.payload.status
      state.updateHorseData = action.payload.data
    },
    getHorseDataFromStore(state, action) {
      state.status = action.payload.status
      state.updateHorseData = action.payload.data
    },
    AddDoc(state, action) {
      // console.log(state, "from  rr AddDoc-----------");
      state[0].documents.push(action.payload);
    },
    removeDoc(state, action) {
      // state[0].documents.splice(action.payload, 1);
      state.documents.splice(action.payload, 1);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getHorseData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getHorseData.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.data = action.payload
    })

    builder.addCase(getHorseData.rejected, (state) => {
      state.status = "failed"
    })
    builder.addCase(createHorseData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(createHorseData.fulfilled, (state, action) => {
      state.status = "succeeded"
    })

    builder.addCase(createHorseData.rejected, (state) => {
      state.status = "failed"
    })

    builder.addCase(deleteHorseData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(deleteHorseData.fulfilled, (state, action) => {
      state.status = "succeded"

    })

    builder.addCase(deleteHorseData.rejected, (state, action) => {
      state.status = "failed"
    })
    builder.addCase(updateHorseData.pending, (state) => {
      state.state = "loading"
    })
    builder.addCase(updateHorseData.fulfilled, (state) => {
      state.status = "succeded"
    })
    builder.addCase(updateHorseData.rejected, (state) => {
      state.status = "failed"
    })
    builder.addCase(getUpdateHorseData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getUpdateHorseData.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.updateHorseData = action.payload
    })
    builder.addCase(getUpdateHorseData.rejected, (state) => {
      state.status = "failed"
    })
    builder.addCase(getQuotationHorse.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getQuotationHorse.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.quotationHorse = action.payload
    })
    builder.addCase(getQuotationHorse.rejected, (state) => {
      state.status = "failed"
    })

    //Horse Profile Data 
    builder.addCase(getHorseProfileData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getHorseProfileData.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.updatedHorseProfileData = action.payload
    })
    builder.addCase(getHorseProfileData.rejected, (state) => {
      state.status = "failed"
    })
  },

});

export default HorseRegistrationReducer.reducer;
export const { AddDoc, removeDoc, addHorseData, getHorseDataFromStore } = HorseRegistrationReducer.actions;
