import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  createData,
  deleteData,
  getData,
  updateData,
} from "../../App/Reducers/FacilityTypeReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import ModalComp from "./Modal";
import DeleteModal from "../../App/Helpers/DeleteModal";
import api from "../../Apis";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../App/Helpers/AlertMessage";

const FacilityTypeList = () => {
  const dispatch = useDispatch();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const facilityData = useSelector((state) => state.facilityTypes.facilityData);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ name: null });
  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addFacilityTypePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_facilitymaster"
      )
    : false;
  const changeFacilityTypePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_facilitymaster"
      )
    : false;
  const deleteFacilityTypePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_facilitymaster"
      )
    : false;

  const openModal = () => {
    setModalData({ name: null });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createOrUpdateHandler = () => {
    if (modalData.id) {
      dispatch(
        updateData({
          id: modalData.id,
          updatedData: {
            name: modalData.name,
          },
          token,
        })
      );
    } else {
      dispatch(createData({ modalData, token }));
    }
    closeModal();
    dispatch(getData(token));
  };

  const editHandler = (id) => {
    const editData = facilityData.find((data) => data.id === id);
    if (editData) {
      setModalData(editData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_facility/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "OK" || response.status === "200") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getData(token))
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  useEffect(() => {
    dispatch(getData(token));
  }, [dispatch, token, modalOpen]);

  const header = [
    {
      Header: "Facility Type",
      accessor: "name",
    },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {facilityData ? (
        <BasicTable
          colHeader={header}
          rowData={facilityData}
          actionFlag={
            deleteFacilityTypePermission ||
            changeFacilityTypePermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeFacilityTypePermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteFacilityTypePermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addFacilityTypePermission || isUserType ? openModal : null
          } // Only allow create if the user has permission
          tableHeading="Facility Type"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            addFacilityTypePermission ||
            changeFacilityTypePermission ||
            isUserType
              ? false
              : true
          }
          updateHandler={
            changeFacilityTypePermission || isUserType ? editHandler : null
          }
          deleteHandler={
            deleteFacilityTypePermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addFacilityTypePermission || isUserType ? openModal : null
          }
          tableHeading="Facility Type"
        />
      )}

      <ModalComp
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        label="ADD FACILITY TYPE"
        heading="Facility Type"
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Facility Type"
        deleteData={deleteData}
      />

    <ToastContainer/>

    </>
  );
};

export default FacilityTypeList;
