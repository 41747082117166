import React, { useState } from "react";
import "./paymentModal.css";
import { IoIosCash } from "react-icons/io";
import { FaCreditCard } from "react-icons/fa6";
import horse1 from "../../../Assets/RidingSchoolAssets/payment_horse1.jpg";
import horse2 from "../../../Assets/RidingSchoolAssets/payment_horse2.jpg";
import { RxCross2 } from "react-icons/rx";
import Loader from "../../components/Loader";
import PaymentConfirmation from "./PaymentConfirmation";
import { useNavigate } from "react-router-dom";

const PaymentModal = ({
  handleBuyNow,
  setPaymentModalOpen,
  isLoading,
  total_price,
  courseId,
  studentId,
  coursedata,
  slot_id,
  persons,
  isCart,
  handleCheckout,
  payloadData,
  cashPayment,
  setPayloadData,
}) => {
  const [paymentConfirmationOpen, setPaymentConfirmationOpen] = useState(false);
  const [cashSinglePayload, setCashSinglePayload] = useState([]);
  const navigate = useNavigate();

  const handleConfirmation = () => {
    const isStudent = localStorage.getItem("isStudent");

    if (isStudent) {
      setPaymentConfirmationOpen(!paymentConfirmationOpen);
      if (isCart) {
        cashPayment();
      } else {
        const p = {
          total_price: total_price,
          courseId: courseId,
          studentId: studentId,
          coursedata: coursedata,
          slot_id: slot_id,
          persons: persons,
        };
        setCashSinglePayload(() => [p]);
      }
    } else {
      navigate("/student-login");
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="Payment__modal__wrapper">
      <div className="paymentModal__container ">
        <div className="paymentModal__icons">
          <RxCross2 onClick={() => setPaymentModalOpen(false)} />
        </div>
        <div className="paymentModal__top">
          <div className="paymentModal__content">
            <h2>Payment Methods</h2>
            <p>Choose in which method you want to pay</p>
          </div>
        </div>
        <div className="paymentModal__img">
          <img src={horse1} alt="horse1" />
          <img src={horse2} alt="horse2" />
        </div>
        <div className="paymentModal__bottom">
          <div className="paymentModal__button">
            <button className="btn_cash" onClick={handleConfirmation}>
              <IoIosCash style={{ fontSize: "1.2rem" }} />
              Cash
            </button>
            <button
              className="btn_online"
              onClick={isCart ? handleCheckout : () => handleBuyNow()}
            >
              <FaCreditCard style={{ fontSize: "1.2rem" }} /> Online
            </button>
          </div>
        </div>
      </div>
      <div>
        {paymentConfirmationOpen ? (
          <PaymentConfirmation
            setPaymentConfirmationOpen={setPaymentConfirmationOpen}
            payloadData={payloadData}
            isCart={isCart}
            setPayloadData={setPayloadData}
            cashSinglePayload={cashSinglePayload}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PaymentModal;
