import React from "react";
import "./buycourse.css";
import imgg from "../../../Assets/Images/others/flat race.jpg";
import { ImgUrl } from "../../../Config/Config";
import { useNavigate } from "react-router-dom";

function CourseCard({ itemData, index, studentId }) {
  const usertypes = JSON.parse(localStorage.getItem("userData"))?.usertype
    ?.name;

  const navigate = useNavigate();

  const handleClick = () => {
    if (usertypes === "Admin") {
      navigate(
        `/riding-club/student-lession-list/${itemData?.id}/${itemData?.courses_type?.id}/${studentId}`
      );
    } else {
      navigate(
        `/student-dashboard/${itemData?.id}/${itemData?.courses_type?.id}/${studentId}`
      );
    }
  };
  return (
    <>
      <div className="card-container" onClick={handleClick}>
        <img
          alt="img"
          src={
            itemData?.courses_type?.image
              ? `${ImgUrl}${itemData?.courses_type?.image}`
              : imgg
          }
          style={{
            height: "10rem",
            width: "100%",
            border: "1px solid black",
            borderRadius: "1rem",
            objectFit: "cover",
          }}
        />
        <div className="info_coursed">
          <p className="p-text-gray">{itemData?.company?.company_name}</p>
          <p className="p-text-gray">{itemData?.course_lesson} lessons</p>
          <p className="p-text-black" style={{ fontWeight: "bold" }}>
            {itemData?.course_name}
          </p>
          <p
            className="p-text-black"
            style={{ fontWeight: "500", color: "green" }}
          >
            $ {itemData?.course_total_price}
          </p>

          <p className="p-text-black1">
            Category : {itemData?.courses_type?.category}
          </p>
          <p className="p-text-black1">Persons : {itemData?.course_persons}</p>
          {itemData?.is_payment_done ? (
            <p className="p-purshed">Purchased</p>
          ) : (
            <p className="p-pending">Pending</p>
          )}
        </div>
      </div>
    </>
  );
}

export default CourseCard;
