import React, { useState, useEffect } from "react";
import "aos/dist/aos.css";
import { TextField, Grid, Autocomplete, Button } from "@mui/material";

import "../../../pages/AdminSetting/User/User.css";
import { useNavigate, useParams } from "react-router-dom";
import studentAPI from "../../../App/Apis/RidingSchoolApis/studentApi/studentAPI";
import courses from "../../../App/Apis/RidingSchoolApis/SlotApis/SlotApi";
import slotmapping from "../../../App/Apis/RidingSchoolApis/slotmappingAPI/slotmapping";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { companyId } from "../../../App/Apis/RidingSchoolReuseIds/Ids";
const CreateSlotMap = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [fieldDisable, setFieldDisable] = useState(false);
  const [slotAllotmentId, setSlotAllotmentId] = useState(null);
  const [slotOptions, setSlotOptions] = useState([]);
  const [horseOptions, setHorseOptions] = useState([]);
  const [trainerOptions, setTrainerOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [assinableMsg, setAssinableMsg] = useState({
    color: null,
    show: false,
    msg: null,
  });

  const [statusOptions, setStatusOptions] = useState([
    {
      name: "Occupied",
    },
    {
      name: "Vacant",
    },
  ]);

  const [formData, setFormData] = useState({
    slot: null,
    student: null,
    horse: null,
    trainer: null,
    status: null,
    image: null,
  });

  const handleAutoComplete = async (e, value, name) => {
    if (name === "slot") {
      try {
        const res = await slotmapping.assignable_slot_map(value.id);

        if (res?.data?.assinable_slot_map > 0) {
          setFieldDisable(false);
          setAssinableMsg({
            show: true,
            msg: `${res?.data?.assinable_slot_map} slots are available to map`,
            color: "green",
          });
        } else {
          setFieldDisable(true);
          setAssinableMsg({
            show: true,
            msg: "No available slots",
            color: "red",
          });
        }
      } catch (error) {
        console.log("error", error);
      }

      setFormData((prev) => {
        return {
          ...prev,
          [name]: value ? value?.id : null,
        };
      });
    } else if (name === "status") {
      // console.log("value?.name", value);
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value ? value?.name : null,
        };
      });
    }
    // console.log(e, value, name);
    else {
      setFormData((prev) => {
        console.log("okeuh");
        return {
          ...prev,
          [name]: value ? value?.id : null,
        };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (slotAllotmentId) {
      try {
        const res = await slotmapping.update_slot_map(
          slotAllotmentId,
          formData
        );
        toast.success("Slot Map Updated");
        navigate("/riding-club/slot-mapping-list");
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        const res = await slotmapping.create_slot_map(formData);
        toast.success("Slot Map Created");
        navigate("/riding-club/slot-mapping-list");
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const getRequiredData = async () => {
    setIsLoading(true);
    try {
      const student = await studentAPI.get_all_students(companyId);
      setStudentOptions(student.data);
      const horse = await studentAPI.all_horse();
      setHorseOptions(horse.data);
      const slots = await courses.getSlotList();
      setSlotOptions(slots.data);
      const trainers = await slotmapping.get_all_trainer();
      setTrainerOptions(trainers.data);
      //   console.log("studetb"    , student, "horse", horse, "slots", slots);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const getPrefilledData = async (id) => {
    setIsLoading(true);
    try {
      const res = await slotmapping.get_slot_map(id);
      // console.log("response ", res);
      setFormData({
        slot: res?.data?.data?.slot ? res?.data?.data?.slot?.id : null,
        student: res?.data?.data?.student ? res?.data?.data?.student?.id : null,
        horse: res?.data?.data?.horse ? res?.data?.data?.horse?.id : null,
        trainer: res?.data?.data?.trainer ? res?.data?.data?.trainer?.id : null,
        status: res?.data?.data?.status ? res?.data?.data?.status : null,
      });
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    getRequiredData();

    if (id) {
      setSlotAllotmentId(id);
      getPrefilledData(id);
    }
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="form-wrapper-container">
          <div
            className="registration-card"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <header>
              <div className="registration_top_header">
                {slotAllotmentId ? (
                  <p>Update Slot Allotment</p>
                ) : (
                  <p>Slot Allotment</p>
                )}
                <span>Please fill in all required fields</span>
              </div>
            </header>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="inputparts-checkIn">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      Slot <span style={{ color: "red" }}>*</span>
                    </label>
                    {
                      <Autocomplete
                        name="slot_id"
                        disablePortal
                        id="slot"
                        options={slotOptions?.data}
                        getOptionLabel={(option) =>
                          `${option?.course?.course_name} ${option?.time}`
                        }
                        value={
                          formData &&
                          formData?.slot &&
                          slotOptions?.data &&
                          slotOptions?.data.find(
                            (item) => item.id === Number(formData.slot)
                          )
                        }
                        onChange={(event, value) =>
                          handleAutoComplete(event, value, "slot")
                        }
                        sx={{
                          color: "#302924",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            className="bg-color"
                            placeholder="Select Slot"
                          />
                        )}
                      />
                    }
                    {assinableMsg?.show && (
                      <p style={{ color: assinableMsg?.color }}>
                        {assinableMsg?.msg}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      Horse <span style={{ color: "red" }}>*</span>
                    </label>
                    {
                      <Autocomplete
                        name="horse_id"
                        disablePortal
                        id="horse"
                        disabled={fieldDisable}
                        options={horseOptions}
                        getOptionLabel={(option) =>
                          `${option?.name} (${option?.code})`
                        }
                        value={
                          formData &&
                          formData.horse &&
                          horseOptions &&
                          horseOptions.find(
                            (item) => item.id === Number(formData.horse)
                          )
                        }
                        onChange={(event, value) =>
                          handleAutoComplete(event, value, "horse")
                        }
                        sx={{
                          color: "#302924",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            className="bg-color"
                            placeholder="Select Horse"
                          />
                        )}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      Trainer <span style={{ color: "red" }}>*</span>
                    </label>
                    {
                      <Autocomplete
                        name="trainer_id"
                        disablePortal
                        id="trainer"
                        disabled={fieldDisable}
                        options={trainerOptions}
                        getOptionLabel={(option) =>
                          `${option?.name} (${option?.code})`
                        }
                        value={
                          formData &&
                          formData.trainer &&
                          trainerOptions &&
                          trainerOptions.find(
                            (item) => item.id === Number(formData.trainer)
                          )
                        }
                        onChange={(event, value) =>
                          handleAutoComplete(event, value, "trainer")
                        }
                        sx={{
                          color: "#302924",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            className="bg-color"
                            placeholder="Select Trainer"
                          />
                        )}
                      />
                    }
                  </Grid>
                </Grid>
              </div>
              <div className="inputparts-checkIn">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      Student
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </label>
                    {
                      <Autocomplete
                        name="student_id"
                        disablePortal
                        id="student"
                        disabled={fieldDisable}
                        options={studentOptions}
                        getOptionLabel={(option) =>
                          `${option?.first_name} ${option.last_name} (${option?.student_id})`
                        }
                        value={
                          formData &&
                          formData.slot &&
                          studentOptions &&
                          studentOptions.find(
                            (item) => item.id === Number(formData.student)
                          )
                        }
                        onChange={(event, value) =>
                          handleAutoComplete(event, value, "student")
                        }
                        sx={{
                          color: "#302924",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            //   required
                            className="bg-color"
                            placeholder="Select Student"
                          />
                        )}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <label>
                      Status <span style={{ color: "red" }}>*</span>
                    </label>
                    {
                      <Autocomplete
                        name="status_id"
                        disablePortal
                        id="status"
                        disabled={fieldDisable}
                        options={statusOptions}
                        getOptionLabel={(option) => option?.name}
                        value={
                          formData &&
                          formData.status &&
                          statusOptions &&
                          statusOptions.find(
                            (item) => item.name === formData.status
                          )
                        }
                        onChange={(event, value) =>
                          handleAutoComplete(event, value, "status")
                        }
                        sx={{
                          color: "#302924",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            className="bg-color"
                            placeholder="Select Status"
                          />
                        )}
                      />
                    }
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                  <label>
                    Trainer <span style={{ color: "red" }}>*</span>
                  </label>
                  {
                    <Autocomplete
                      name="country_id"
                      disablePortal
                      id="country"
                      options={trainerOptions}
                      getOptionLabel={(option) => option?.name}
                      value={
                        formData &&
                        formData.trainer &&
                        trainerOptions &&
                        trainerOptions.find(
                          (item) => item.id === Number(formData.trainer)
                        )
                      }
                      onChange={(event, value) =>
                        handleAutoComplete(event, value, "country_id")
                      }
                      sx={{
                        color: "#302924",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          className="bg-color"
                          placeholder="Select Slot"
                        />
                      )}
                    />
                  }
                </Grid> */}
                </Grid>
              </div>
              <div className="btn-submit" style={{ gap: "15px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  className="btn-bgColor"
                  onClick={() => navigate("/riding-club/slot-mapping-list")}
                >
                  Cancel
                </Button>

                {slotAllotmentId ? (
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-bgColor"
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-bgColor"
                  >
                    Create
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateSlotMap;
