import React from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import './Settings.css'
import { useNavigate } from 'react-router';
import horseGenderMaster from "../../Assets/Images/Setting-Master/horse_gender_master.png";
import { FaUsers, FaCity, FaCcVisa } from "react-icons/fa";
import { SiEnvoyproxy, SiTrakt } from "react-icons/si";
import { GrServices } from "react-icons/gr";
import { GiStable } from "react-icons/gi";
import { HiReceiptTax } from "react-icons/hi";
import { RiUserSettingsFill } from "react-icons/ri";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { RiMastercardFill } from "react-icons/ri";
import { TbServerCog, TbGenderAgender } from "react-icons/tb";
import { CgPathDivide } from "react-icons/cg";
import { MdOutlineAccountTree, MdEventAvailable, MdBloodtype, MdHealthAndSafety, MdOutlineInvertColors, MdMiscellaneousServices } from "react-icons/md";
import { useEffect } from 'react';

const Settings = () => {
    const navigate = useNavigate()

    const iconStyle = {
        fontSize: "45px",
        color: "#9f8754"
    }

    const permissions = JSON.parse(localStorage.getItem('userData'))

    // Permissions
    const addCustomPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "add_customuser") : false;
    const viewCustomPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_customuser") : false;
    const viewFacilityMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_facilitymaster") : false;
    const viewServiceTypePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_servicetype") : false;
    const viewPlannerMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_planermaster") : false;
    const viewStableServicePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_stableservice") : false;
    const viewFacilityTreePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_facility") : false;
    const viewEventTypePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_eventtype") : false;
    const viewTaxPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_taxmaster") : false;
    const viewBreedPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_breedmaster") : false;
    const viewBreederPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_breedermaster") : false;
    const viewStableClassPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_stableclass") : false;
    const viewDivisionMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_divisionmaster") : false;
    const viewHorseGenderMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_horsegender") : false;
    const viewTrainingMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_trainingmaster") : false;
    const viewColorMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_colormaster") : false;
    const viewVisaMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_visamaster") : false;
    const viewDocTypePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_documenttype") : false;
    const viewHeathMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_healthmaster") : false;

    // MenuItems
    const settingsMenuItems = [
        (viewHeathMasterPermission || permissions?.usertype?.name === "Admin") && {
            title: "User Type",
            image: <RiUserSettingsFill style={iconStyle} />,
            url: "/dashboard/settings/usertype_master",
            content: "Tailor user experience. Define type. Ready to personalize? Specify details now!",
            duration: 200,
        },
        (viewCustomPermission || permissions?.usertype?.name === "Admin") && {
            title: "Users",
            image: <FaUsers style={iconStyle} />,
            url: "/dashboard/user_list",
            content: "Add details, let User Master handle seamless profile management effortlessly.",
            duration: 200,
        },
        (viewFacilityMasterPermission || permissions?.usertype?.name === "Admin") && {
            title: "Facility Type",
            image: <FaCity style={iconStyle} />,
            url: "/dashboard/settings/facility",
            content: "Categorize and manage facilities effortlessly. Add your facility type now!",
            duration: 400,
        },
        (viewServiceTypePermission || permissions?.usertype?.name === "Admin") && {
            title: "Service Type",
            image: <MdMiscellaneousServices style={iconStyle} />,
            url: "/dashboard/settings/service-type",
            content: "Customize service. Share details, define type. Ready? Add specifics now!",
            duration: 600,
        },
        (viewStableServicePermission || permissions?.usertype?.name === "Admin") && {
            title: "Services",
            image: <TbServerCog style={iconStyle} />,
            url: "/dashboard/settings/services",
            content: "Craft personalized service. Share details, define preferences. Get started now!",
            duration: 800,
        },
        (viewPlannerMasterPermission || permissions?.usertype?.name === "Admin") && {
            title: "Planner Type",
            image: <RiMastercardFill style={iconStyle} />,
            url: "/dashboard/settings/planner",
            content: "Tailor planning, define type. Start organized journey – add specifics now!",
            duration: 1000,
        },
        (viewFacilityTreePermission || permissions?.usertype?.name === "Admin") && {
            title: "Facility Tree",
            image: <MdOutlineAccountTree style={iconStyle} />,
            url: "/dashboard/settings/facility-tree",
            content: "Effortless hierarchy. Share details, shape landscape now!",
            duration: 1200,
        },
        (viewEventTypePermission || permissions?.usertype?.name === "Admin") && {
            title: "Event Type",
            image: <MdEventAvailable style={iconStyle} />,
            url: "/dashboard/settings/event_type",
            content: "Define events seamlessly. Share details, plan the perfect occasion now!",
            duration: 1400,
        },

        (viewTaxPermission || permissions?.usertype?.name === "Admin") && {
            title: "Tax",
            image: <HiReceiptTax style={iconStyle} />,
            url: "/dashboard/settings/tax",
            content: "Optimize tax strategy. Provide details, maximize returns. Share tax specifics now!",
            duration: 1600,
        },
        (viewBreedPermission || permissions?.usertype?.name === "Admin") && {
            title: "Breed",
            image: <MdBloodtype style={iconStyle} />,
            url: "/dashboard/settings/breed",
            content: "Personalize your pet's profile. Share breed specifics. Showcase uniqueness. Add now!",
            duration: 1800,
        },
        (viewBreederPermission || permissions?.usertype?.name === "Admin") && {
            title: "Breeder",
            image: <SiEnvoyproxy style={iconStyle} />,
            url: "/dashboard/settings/breeder",
            content: "Elevate breeding. Define profile. Connect with enthusiasts. Add specifics now!",
            duration: 2000,
        },
        (viewStableClassPermission || permissions?.usertype?.name === "Admin") && {
            title: "Stable Class",
            image: <GiStable style={iconStyle} />,
            url: "/dashboard/settings/stable_class",
            content: "Curate stable class. Define equestrian experience. Ready to saddle up?",
            duration: 2200,
        },

        (viewDivisionMasterPermission || permissions?.usertype?.name === "Admin") && {
            title: "Division Master",
            image: <CgPathDivide style={iconStyle} />,
            url: "/dashboard/settings/division_master",
            content: "Lead with precision. Share details, take charge. Specify division now!",
            duration: 2400,
        },
        (viewHorseGenderMasterPermission || permissions?.usertype?.name === "Admin") && {
            title: "Horse Gender Master",
            image: horseGenderMaster,
            image: <TbGenderAgender style={iconStyle} />,
            url: "/dashboard/settings/horse_gender_master",
            content: "Sculpt horse's identity. Share gender details. Ready to personalize? Specify now!",
            duration: 2600,
        },
        (viewTrainingMasterPermission || permissions?.usertype?.name === "Admin") && {
            title: "Training Master",
            image: <SiTrakt style={iconStyle} />,
            url: "/dashboard/settings/training_master",
            content: "Shape training journey. Share specifics, elevate skills. Specify training details now!",
            duration: 2800,
        },
        (viewColorMasterPermission || permissions?.usertype?.name === "Admin") && {
            title: "Horse Color Master",
            image: <MdOutlineInvertColors style={iconStyle} />,
            url: "/dashboard/settings/color_master",
            content: "Craft horse's palette. Share color specifics, showcase unique hue. Specify now!",
            duration: 3000,
        },
        (viewVisaMasterPermission || permissions?.usertype?.name === "Admin") && {
            title: "Visa Master",
            image: <FaCcVisa style={iconStyle} />,
            url: "/dashboard/settings/visa_master",
            content: "Unlock visa experience. Share details, navigate effortlessly. Specify now!",
            duration: 3000,
        },
        (viewDocTypePermission || permissions?.usertype?.name === "Admin") && {
            title: "Document Type ",
            image: <HiClipboardDocumentList style={iconStyle} />,
            url: "/dashboard/settings/document_type_master",
            content: "Streamline doc experience. Share details, organize effortlessly. Specify type now!",
            duration: 3000,
        },
        (viewHeathMasterPermission || permissions?.usertype?.name === "Admin") && {
            title: "Health Master",
            image: <MdHealthAndSafety style={iconStyle} />,
            url: "/dashboard/settings/health_master",
            content: "Optimize well-being. Share details, thrive. Specify health particulars now!",
            duration: 3000,
        },
    ].filter(Boolean)

    useEffect(() => {
        AOS.init();
    })

    return (
        <div className="form-wrapper-container">
            <div className="registration-card"  >

                <header>
                    <div className="registration_top_header">
                        <p>Settings</p>
                        <span>Explore customization options on the Settings page. Modify and tailor your experience to suit your preferences.</span>
                    </div>
                </header>
                <body>
                    <div className="settings-container">
                        {settingsMenuItems ? settingsMenuItems.map((item, i) => {
                            return (
                                <div className="settings-card-container"
                                    onClick={() => navigate(`${item.url}`)}
                                    data-aos="zoom-in-up"
                                    data-aos-duration={item.duration}  // Set the duration here
                                    key={i}>
                                    <div className='setting-card-top-container'>
                                        <span className="settings-images"> {item.image}</span>
                                        <span className="settings-text">{item.title}</span>
                                    </div>
                                    <div>
                                        <span style={{ fontSize: "0.9rem" }}>{item.content}</span>
                                    </div>
                                </div>
                            );
                        }) : "loading"}
                    </div>
                </body>
            </div>
        </div>
    )
}

export default Settings