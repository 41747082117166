import React, { useEffect, useState } from "react";
import "./Planer.css";
import {
  TextField,
  Button,
  Grid,
  Autocomplete,
  TextareaAutosize,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateMedicalPlaner,
  UpdateMedicalPlaner,
} from "../../App/Reducers/MedicalPlanerReducer";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { GetUsers } from "../../App/Reducers/UserReducer";
import { getHorseData } from "../../App/Reducers/HorseRegistrationReducer";

// import TextareaAutosize from '@mui/base/TextareaAutosize';

const MedicalPlaner = () => {
  const [cookies, setCookie] = useCookies();
  const token = cookies.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const State = useSelector((state) => state.MedicalPlaner);
  const Data = useSelector((state) => state.MedicalPlaner.UpdatingPlaner);
  const [status, setStatus] = useState(State.status.Create);
  const Horses_list = useSelector((state) => state.horseRegistration.data);
  const users = useSelector((state) => state.getUsers.users);
  const [staffs, setStaffs] = useState(users);
  const [medicalData, setmedicalData] = useState({
    horse_id: null,
    medical: "",
    vaccination: "",
    staff: "",
    other_staff: "",
    price: "",
    discription: "",
    start_date: null,
    end_date: null,
  });
  const colors = ["1", "2", "3", "4", "5"];
  console.log("MedicalPlaner", medicalData);
  const EventHandler = (e) => {
    const { name, value } = e.target;
    setmedicalData({ ...medicalData, [name]: value });
  };
  const EventAutoCompleteHandler = (e, value, fieldName) => {
    setmedicalData({ ...medicalData, [fieldName]: value });
  };
  const CreateHandler = () => {
    dispatch(CreateMedicalPlaner({ medicalData, token }));
    if (status === "succeeded") {
      navigate("/dashboard/planer/food_list");
    }
  };
  const UpdateHandler = () => {
    console.log("medicalData", medicalData);
    dispatch(UpdateMedicalPlaner({ medicalData, token }));
  };
  useEffect(() =>{
    dispatch(getHorseData(token));
    dispatch(GetUsers(token))

},[token])
  useEffect(() => {
    setStaffs(users)
    setStatus(State.status.Create);
    if (Data) {
      setmedicalData({
        id: Data.id,
        horse_id: String(Data.horse_id.id),
        medical: Data.medical,
        vaccination: Data.vaccination,
        staff: String(Data.staff.id),
        other_staff: Data.other_staff,
        price: String(Data.price),
        discription: Data.discription,
        start_date: Data.start_date
          ? new Date(Data.start_date).toISOString().slice(0, -1)
          : "",
        end_date: Data.end_date
          ? new Date(Data.end_date).toISOString().slice(0, -1)
          : "",
      });
    }
    if (State.status.Update === "succeeded") {
      navigate("/dashboard/planer/medical_list");
    }
  }, [users , State.status.Create, Data]);
  return (
    <div className="form-wrapper-container">
    <div className="form-container">
      <h2 className="planer-header">Medical Planner Of Horse</h2>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <label>Horse Name</label>
            <Autocomplete
                  id="combo-box-demo"
                name="horse_id"
                value={
                  Horses_list &&
                  medicalData.horse_id &&
                  Horses_list.find((item) => item.id === Number(medicalData.horse_id))
                }
                onChange={(event, value) =>
                  EventAutoCompleteHandler(event, value, "horse_id")
                }
                required
                fullWidth
                options={Horses_list && Horses_list}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField className="bg-color" {...params} />
                )}

                /> 
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Medical</label>
            <TextField
              type="text"
              name="medical"
              onChange={EventHandler}
              value={medicalData.medical}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Vaccination</label>
            <TextField
              type="text"
              name="vaccination"
              onChange={EventHandler}
              value={medicalData.vaccination}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <label>Staff</label>
            <Autocomplete
                name="staff"
                disablePortal
                id="combo-box-demo"
                value={
                  staffs &&
                  medicalData.staff &&
                  staffs.find((item) => item.id === Number(medicalData.staff))
                }
                onChange={(event, value) =>
                  EventAutoCompleteHandler(event, value, "staff")
                }
                required
                fullWidth
                options={staffs && staffs}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                renderInput={(params) => (
                  <TextField className="bg-color" {...params} />
                )}
              />
          </Grid>
       
          <Grid item xs={12} sm={6} md={4}>
            <label>Others Staff</label>
            <TextField
              type="text"
              name="other_staff"
              value={medicalData.other_staff}
              onChange={EventHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Price</label>
            <TextField
              type="text"
              name="price"
              value={medicalData.price}
              onChange={EventHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <label>Description</label>
            <TextareaAutosize
              rows={4}
              placeholder="Empty"
              value={medicalData.discription}
              name="discription"
              onChange={EventHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Start Date</label>
            <TextField
              type="datetime-local"
              name="start_date"
              onChange={EventHandler}
              value={medicalData.start_date}
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>End Date</label>
            <TextField
              type="datetime-local"
              name="end_date"
              onChange={EventHandler}
              value={medicalData.end_date}
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        {!Data ? (
          <Button
            variant="outlined"
            sx={{
              color: "#fff",
              borderColor: "#302924",
              "&:hover": {
                borderColor: "#302924",
                color: "#302924",
              },

              marginTop: "2rem",
              fontSize: "12px",
              marginInline: "0.5rem",
              backgroundColor: "#302924",
            }}
            onClick={CreateHandler}
          >
            CREATE
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{
              color: "#fff",
              borderColor: "#302924",
              "&:hover": {
                borderColor: "#302924",
                color: "#302924",
              },

              marginInline: "0.5rem",
              marginTop: "2rem",
              fontSize: "12px",
              backgroundColor: "#302924",
            }}
            onClick={UpdateHandler}
          >
            UPDATE
          </Button>
        )}
      </form>
    </div>
    </div>
  );
};

export default MedicalPlaner;
