import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseURL } from '../../Apis';
import api from "../../Apis";
export const GetMedicalPlanerList = createAsyncThunk('GetMedicalPlanerList', async (token) => {
  try {
    const response = await api.get(`get_all_medical_planer` , {headers : {Authorization : `token ${token}`}});
    console.log(response, "api/get_all_food_planer")
    return response.data

  } catch (error) {
    console.log(error)
  }

})
export const CreateMedicalPlaner = createAsyncThunk('CreateMedicalPlaner', async (payload , { rejectWithValue }) => {
    try {
        const response = await api.post(`create_medical_planer`,
        payload.medicalData , 
        {headers:{
            "Content-Type": "application/json" , 
            Authorization : `token ${payload.token}`
          }});
        console.log(response, "/api/create_food_planer")
        return response.data
  
      } catch (error) {
        return rejectWithValue(error.response.data)
      }
  
  })
  export const GetUpdatingMedicalPlaner = createAsyncThunk('GetUpdatingMedicalPlaner', async (payload , { rejectWithValue }) => {
    try {
      const response = await api.get(`get_medical_planer/${payload.id}`, {headers:{Authorization : `token ${payload.token}`}});
      console.log(response, "api/get_food_planer/id")
      return response.data
  
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
  
  })

  export const UpdateMedicalPlaner = createAsyncThunk('UpdateMedicalPlaner', async (payload) => {
    console.log('sdbsjdhsjdhjsdh',payload.medicalData);
    try {
      const response = await api.put(`update_medical_planer/${payload.medicalData.id}`,
      payload.medicalData,
      {headers:{
          "Content-Type": "application/json" ,
          Authorization : `token ${payload.token}`

        }});
      console.log(response, "sahjfgdeudjgfvbdrvjfdk")
      return response.data

    } catch (error) {
      return console.log(error)
    }  
  
  })
  export const DeleteMedicalPlaner = createAsyncThunk('DeleteMedicalPlaner', async (payload, { rejectWithValue }) => {
    try {
        const response = await api.delete(`delete_medical_planer/${payload.id}` , {headers : {Authorization : `token ${payload.token}`}})      
        console.log(response, "Delete")
        return response.data
  
      } catch (error) {
        console.log(error)
         return rejectWithValue(error.response.data)
      }  
  })
const initialState = {
  PlanerList: null ,
  UpdatingPlaner : null,
  status :{
    Get :"",
    Create:"",
    Updating:"",
    Update:"",
    Delete:""
  },
  errors :{
    Get:"",
    Create:"",
    Update:"",
    Delete:"",

  }
};

const MedicalPlanerSlice = createSlice({
  name: "MedicalPlaner",
  initialState,
  reducers: {
    Update: (state, action) => { },
  },
  extraReducers : (builder) =>{
    builder
    .addCase(GetMedicalPlanerList.pending , (state)=>{
        state.status.Get = "loading"
    })
    .addCase(GetMedicalPlanerList.fulfilled , (state , action)=>{
        state.status.Get = "succeeded"
        state.PlanerList = action.payload
        console.log(action.payload , "success")
    })
    .addCase(GetMedicalPlanerList.rejected , (state , action)=>{
        state.status.Get = "failed"
        console.log("failed")
       
    })
    .addCase(CreateMedicalPlaner.pending , (state)=>{
        state.status.Create = "loading"
    })
    .addCase(CreateMedicalPlaner.fulfilled , (state , action)=>{
        state.status.Create = "succeeded"
        console.log(action.payload , "succeeded")
    })
    .addCase(CreateMedicalPlaner.rejected , (state , action)=>{
        state.status.Create = "failed"
        console.log("failed")  
    })
    .addCase(GetUpdatingMedicalPlaner.pending , (state)=>{
        state.status.Updating = "loading"
    })
    .addCase(GetUpdatingMedicalPlaner.fulfilled , (state , action)=>{
        state.status.Updating = "succeeded"
        state.UpdatingPlaner  = action.payload
        console.log(action.payload , "succeeded")
        console.log(state.UpdatingPlaner  , "updating")
    })
    .addCase(GetUpdatingMedicalPlaner.rejected , (state , action)=>{
        state.status.Updating = "failed"
        console.log("failed")  
    })
    .addCase(UpdateMedicalPlaner.pending , (state)=>{
        state.status.Update = "loading"
    })
    .addCase(UpdateMedicalPlaner.fulfilled , (state , action)=>{
        state.status.Update = "succeeded"
        console.log(action.payload , "succeeded")

    })
    .addCase(UpdateMedicalPlaner.rejected , (state , action)=>{
        state.status.Update = "failed"
        console.log("failed")  
      
    })
}
})


export const { } = MedicalPlanerSlice.actions;
export default MedicalPlanerSlice.reducer;
