import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  TextField,
  Grid,
  Autocomplete,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData } from "../../App/Reducers/FacilityTypeReducer";
import { GetUsers } from "../../App/Reducers/UserReducer";
import { getServiceTypeData } from "../../App/Reducers/ServiceTypeReducer";
import { getFacilityTreeData } from "../../App/Reducers/FacilityTreeReducer";
import { getAllConfirmQuotation } from "../../App/Reducers/QuotationReducer";
import api from "../../Apis";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getCheckInByID } from "../../App/Apis/CheckinApis";
import "./CheckIn.css";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import CommonLoading from "../../SharedComponets/Loading/CommonLoading";

const Checkout = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkout_id = useParams();

  const today = new Date().toISOString().slice(0, -8); // This will include both date and time

  const QuotationData = useSelector(
    (state) => state.quotation.confirmQuotation
  );

  const staffData = useSelector((state) => state.getUsers.users);

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [horseName, setHorseName] = useState([]);

  const [formData, setFormData] = useState({
    reservation: null,
    horse: null,
    staff: null,
    date: today,
    reason: null,
    description: null,
  });

  const getHorseDataByquotationId = async (id, token) => {
    try {
      const res = await api.get(`get_quotation_tempin_horse/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      console.log("res.data checkout", res.data);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const handleAutoComplete = async (event, value, fieldName) => {
    if (value === null) {
      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
        horse: null,
      }));
    } else if (fieldName === "reservation") {
      const horseData = await getHorseDataByquotationId(value.id, token);
      console.log("horseData", horseData);

      const hData = horseData.map((item) => {
        return { id: item.id, name: item.name };
      });
      setHorseName(() => hData);

      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else {
      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkout_id.id) {
      try {
        setLoading(true);
        const response = await api.put(
          `update_checkout/${checkout_id.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `token ${token}`,
            },
          }
        );

        if (response.statusText === "OK" || response.status === "200") {
          setLoading(false);
          navigate("/dashboard/stable/permanent_outpass");
          toast.success("Checkout successfully");
        }
      } catch (error) {
        console.error(`Error found during checkout updation: ${error}`);
      }
    } else {
      try {
        setLoading(true);
        const response = await api.post("create_checkout", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "Created" || response.status === "201") {
          setLoading(false);
          navigate("/dashboard/stable/permanent_outpass");
          toast.success("Checkout  successfully");
        }
      } catch (error) {
        console.error(`Error found during checkout : ${error}`);
      }
    }
  };

  useEffect(() => {
    dispatch(GetUsers(token));
    dispatch(getAllConfirmQuotation(token));
  }, []);

  return isLoading ? (
    <StableLoading />
  ) : (
    <div className="form-wrapper-container">
      <div
        className="registration-card"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <header>
          <div className="registration_top_header">
            <p>Permanent Outpass</p>
          </div>
        </header>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <label>
                RESERVATION <span style={{ color: "red" }}>*</span>
              </label>
              {
                <Autocomplete
                  name="reservation"
                  value={
                    formData &&
                    formData.reservation &&
                    QuotationData &&
                    QuotationData.find(
                      (item) => item.id === Number(formData.reservation)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "reservation")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={
                    QuotationData !== undefined
                      ? QuotationData
                      : [{ id: null, name: "No Quotation Data" }]
                  }
                  getOptionLabel={(option) => `${option.name} (${option.code})`}
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Reservation"
                      {...params}
                    />
                  )}
                />
              }
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>
                HORSE NAME <span style={{ color: "red" }}>*</span>
              </label>
              {
                <Autocomplete
                  name="horse"
                  value={
                    formData &&
                    formData.horse &&
                    horseName.find((item) => item.id === Number(formData.horse))
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "horse")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  disablePortal
                  id="horse"
                  options={horseName}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Horse"
                      {...params}
                    />
                  )}
                />
              }
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>
                STAFF <span style={{ color: "red" }}>*</span>
              </label>
              {
                <Autocomplete
                  name="staff"
                  value={
                    formData.staff
                      ? staffData !== null &&
                        staffData.find(
                          (item) => item.id === Number(formData.staff)
                        )
                      : null
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "staff")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={staffData}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name} (${
                      option.usertype && option.usertype.name
                    })`
                      ? `${option.first_name} ${option.last_name} (${
                          option.usertype && option.usertype.name
                        })`
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Staff"
                      {...params}
                    />
                  )}
                />
              }
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>
                CHECKOUT DATE <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                type="datetime-local"
                className="inputfield  bg-color"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
                fullWidth
                placeholder="Select Date"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
            <label>DESCRIPTION</label>
            <TextareaAutosize
              style={{ width: "100%", fontSize: "16px", padding: "10px" }}
              minRows={6}
              multiline
              value={formData.description}
              name="description"
              onChange={handleChange}
              className="bg-color"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={{ marginBlock: "20px" }}>
            <label>REASON</label>
            <TextareaAutosize
              style={{ width: "100%", fontSize: "16px", padding: "10px" }}
              minRows={6}
              multiline
              value={formData.reason}
              name="reason"
              onChange={handleChange}
              className="bg-color"
              fullWidth
            />
          </Grid>

          <div className="btn-submit" style={{ gap: "15px" }}>
            <Button
              type="submit"
              variant="contained"
              className="btn-bgColor"
              onClick={() => navigate("/dashboard/stable/permanent_outpass")}
            >
              Cancel
            </Button>

            {loading ? (
              <CommonLoading />
            ) : (
              <Button type="submit" variant="contained" className="btn-bgColor">
                {checkout_id.id ? "Update" : "Create"}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Checkout;
