import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { ImgUrl } from "../../Config/Config";
import userImg from "../../Assets/Images/user.jpg";
import { toast } from "react-toastify";
import DropdownMenu from "../../SharedComponets/Components/DropdownMenu";
import courses from "../../App/Apis/RidingSchoolApis/courseApi/course";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import DeleteModal from "../../App/Helpers/DeleteModal";
import Loader from "../../Ridingschool/components/Loader";
import Registration from "../../App/Apis/RidingSchoolApis/studentApi/registration";
import { companyId } from "../../App/Apis/RidingSchoolReuseIds/Ids";

const StudentList = () => {
  const [studentList, setStudentsList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);

  const UpdateHandler = (id) => {
    if (id) {
      navigate(`../course/student-update-registration/${id}`);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const CourseHandler = (row) => {
    console.log("Row:", row);
    navigate(`/riding-club/course/${row}`);
  };
  const header = [
    { Header: "Id", accessor: "student_id" },

    {
      Header: "Name",
      accessor: "profile",
      Cell: ({ row }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              row.original.image === null
                ? userImg
                : `${ImgUrl}${row.original.image}`
            }
            alt="img"
            width={50}
            height={50}
            style={{ borderRadius: "50%", cursor: "pointer" }}
            onClick={() => CourseHandler(row.original.id)}
          />
          <span
            onClick={() => CourseHandler(row.original.id)}
            style={{
              cursor: "pointer",
              color: "#7c5e1e",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
            title="View profile"
          >
            {`${row.original.first_name ? row.original.first_name : ""} ${
              row.original.last_name ? row.original.last_name : ""
            }`}
          </span>
        </div>
      ),
    },

    { Header: "Mobile", accessor: "mobile" },
    { Header: "Email", accessor: "email" },
    { Header: "Age", accessor: "age" },
    { Header: "City", accessor: "city" },
    // { Header: "State", accessor: "state" },
    {
      Header: "Action",
      accessor: "action",
      show: true,
      Cell: ({ row }) => {
        return (
          <DropdownMenu
            menus={
              <>
                <MenuItem
                  onClick={() => UpdateHandler(row.original.id)}
                  disableRipple
                >
                  <EditIcon />
                  Edit
                </MenuItem>

                {/* <MenuItem
                  onClick={() => deleteHandler(row.original.id)}
                  disableRipple
                >
                  <DeleteIcon sx={{ fontSize: "40px", color: "red" }} />
                  <span>Delete</span>
                </MenuItem> */}
              </>
            }
          />
        );
      },
    },
  ];

  const fetchingAllStudentsList = async () => {
    try {
      const res = await Registration.getAllStudents(companyId);
      console.log("student list", res.data);
      setStudentsList(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchingAllStudentsList();
  }, []);

  const CreateHandler = () => {
    navigate("/riding-club/course/student-create-registration");
  };

  const deleteData = async () => {
    setLoading(true);
    try {
      await Registration.deleteStudent(deleteId);
      fetchingAllStudentsList();
      toast.success("Student Deleted Successfully");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <BasicTable
        colHeader={header}
        rowData={studentList}
        actionFlag={true}
        updateHandler={UpdateHandler}
        deleteHandler={deleteHandler}
        // createHandler={CreateHandler}
        tableHeading="Student List"
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Planner"
        deleteData={deleteData}
      />
    </div>
  );
};

export default StudentList;
