import React, { useState } from "react";
import LoginPageLeftContainer from "../../LoginRegister/LoginPageLeftContainer";
import Loader from "../../../Ridingschool/components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import LoginApi from "../../../App/Apis/RidingSchoolApis/studentApi/login";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";

const StudentLogin = () => {
  const [isremeberMe, setIsRemeberMe] = useState(
    false || JSON.parse(localStorage.getItem("isRemeberMe"))
  );
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };

  const studentLogin = async (payload, email) => {
    setIsLoading(true);
    try {
      const studentLoginRes = await LoginApi.studentLoginOtp(payload);
      console.log("studentLoginRes", studentLoginRes);
      navigate(`/student-login-otp?${email}`);
    } catch (err) {
      console.log(err);
      toast.error("Invalid user email ! please check and try again later ");
    }
    setIsLoading(false);
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: (value, action) => {
      console.log(value);
      let email = value?.email;
      studentLogin(value, email);
      action.resetForm();
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="login-section login-page">
        <LoginPageLeftContainer />

        <div className="login-right-container">
          <div className="login-module-container">
            <div className="login-portal-container">
              <h2 style={{ color: "#826426" }}>PORTAL</h2>
              <p
                style={{
                  color: "#222222",
                  fontSize: "12.5px",
                  fontWeight: "bold",
                }}
              >
                PLEASE LOGIN YOUR ACCOUNT BELOW:
              </p>
            </div>

            <div className="container">
              <form className="login-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label" htmlFor="username">
                    EMAIL ADDRESS
                  </label>
                  <input
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    className="input-field login-input-field"
                    id="username"
                    type="email"
                    placeholder="Enter Your Email"
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    paddingBlock: "20px",
                  }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => setIsRemeberMe(e.target.checked)}
                    checked={isremeberMe}
                  />
                  <label style={{ paddingBottom: "3px" }}>REMEMBER ME</label>
                </div>
                {isLoading ? <Loader /> : null}
                <button className="login-button" type="submit">
                  Login{" "}
                </button>
              </form>
              <div
                style={{
                  marginTop: "0.5rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {/* <Link to="#" style={{ color: "#bebb9e", fontSize: "0.95rem" }}> Forget Password ?</Link> */}
                <Link
                  to="/login"
                  style={{
                    color: "#836628",
                    fontSize: "0.95rem",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}><FaArrowLeft /></span>
                  <span> Back</span>
                </Link>
                <Link
                  to="/student-registrations"
                  style={{
                    color: "#836628",
                    fontSize: "0.95rem",
                    fontWeight: "bold",

                  }}
                >
                  Create{" "}
                  <span style={{ textTransform: "lowercase" }}>an account</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="login-right-container-mobile">
        <div>
          <div>
            <h2 style={{ color: "#826426" }}>PORTAL</h2>
            <p
              style={{
                color: "#222222",
                fontSize: "12.5px",
                fontWeight: "bold",
              }}
            >
              PLEASE LOGIN YOUR ACCOUNT BELOW:
            </p>
          </div>

          <div>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="form-label" htmlFor="username">
                  EMAIL ADDRESS
                </label>
                <input
                  //   value={user.email}
                  name="email"
                  //   onChange={(e) => {
                  //     setUser({ ...user, [e.target.name]: e.target.value });
                  //   }}
                  className="input-field login-input-field"
                  id="username"
                  type="text"
                  placeholder="Enter Your Email"
                  required
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                  paddingBlock: "20px",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => setIsRemeberMe(e.target.checked)}
                  checked={isremeberMe}
                />
                <label style={{ paddingBottom: "3px" }}>REMEMBER ME</label>
              </div>
              {isLoading ? <Loader /> : null}
              <button className="login-button" type="submit">
                Login{" "}
              </button>
            </form>
            <div
              style={{
                marginTop: "0.5rem",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <Link
                to="/forget_password"
                style={{
                  color: "#836628",
                  fontSize: "0.95rem",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Forget Password ?
              </Link>
              <Link
                to="/register"
                style={{
                  color: "#836628",
                  fontSize: "0.95rem",
                  fontWeight: "bold",
                }}
              >
                Create{" "}
                <span style={{ textTransform: "lowercase" }}>an account</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentLogin;
