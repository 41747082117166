import React from "react";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { Autocomplete, Modal } from "@mui/material";
import { TextField, Button, Grid, Typography } from "@mui/material";
// import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import moment from "moment";
import dayjs from "dayjs";
import TimePicker from '../components/TimePicker/TimePicker'




const CommonModal = ({ inputs, modalOpen, closeModal, modalData, heading, label, handleModalInputChange, createOrUpdateHandler }) => {

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#e0e0e0",
        border: "5px solid #bebb9e",
        borderRadius: "10px",
        width: '30%',
        height: "fit-content",
        p: 4,
        "@media (max-width: 576px)": {
            width: "100% !important",
        },
        "@media (max-width: 992px)": {
            width: "60%",
        },
    };



    return (
        <>
            <Modal open={modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} className="scroll-bar">
                    <Button
                        sx={{
                            minWidth: '30px',
                            color: "#302924",
                            border: "0px",
                            float: "right",
                            padding: "0px",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                                backgroundColor: "#e0e0e0",
                            },
                        }}
                    >
                        <RxCross2 style={{ color: "#302924", fontSize: "1.7rem" }} onClick={closeModal} />
                    </Button>

                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#302924", marginBottom: "5vh", fontWeight: "600", fontSize: "18px" }}>
                        {modalData.id ? `UPDATE ${heading.toUpperCase()}` : `CREATE ${heading.toUpperCase()}`}
                    </Typography>

                    <form onSubmit={createOrUpdateHandler}>
                        <Grid xs={12} sm={6} md={4}>

                            {/* <Grid item xs={12} sm={6} md={4}>
                                <label>{label.toUpperCase()}</label>
                                <TextField
                                    type="text"
                                    name="name"
                                    onChange={handleModalInputChange}
                                    value={modalData.name}
                                    placeholder={`Enter ${heading}`}
                                    fullWidth
                                    required
                                    style={{ marginBlock: "6px" }}
                                />
                            </Grid> */}
                            {/* {modalData.course}
                            {modalData.time} */}
                            {
                                inputs.map((input, index) => input.type === 'select' ? (<Grid item xs={12} sm={6} md={4} key={index}>
                                    <label>{input.label.toUpperCase()}</label>
                                    <Autocomplete
                                        name={input.name}
                                        className="autocomplete-mt"
                                        options={input.options ? input.options : []}
                                        getOptionLabel={(option) => option.course_name ? option.course_name : option.name ? option.name : option}
                                        value={
                                            input.options &&
                                            input.value &&
                                            input.options.find(
                                                (item) => String(item.id) === String(input.value)
                                            )
                                        }
                                        onChange={(event, value) =>
                                            input.handleOnChange(event, value, input.name)
                                        }
                                        disablePortal
                                        disabled={!input.disabled ? false : modalData.id ? true : false}
                                        id={input.name}
                                        required={input.required}
                                        sx={{ color: "#fff " }}
                                        renderInput={(params) => (
                                            <TextField

                                                required={input.required}
                                                placeholder={input.placeholder}
                                                {...params}
                                            />
                                        )}
                                    />
                                    {input.slotCapacity && <span style={input?.slotCapacity.assignable_slots_no <= 0 ? { color: 'red' } : { color: 'green' }}>
                                        Available slot is {input?.slotCapacity.assignable_slots_no <= 0 ? 0 : input?.slotCapacity.assignable_slots_no}
                                    </span>}

                                </Grid>) : (<Grid item xs={12} sm={6} md={4} key={index}>
                                    <label>{input.label.toUpperCase()}</label>
                                    {input.type === 'time' ? (
                                        <div>
                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                <TimePicker
                                                    // label="Controlled field"
                                                    value={input.value ? input.value : null}
                                                    onChange={(v) => {
                                                        console.log('v', v);
                                                        var formatted = moment(v.$d, "HH:mm").format("hh:mm A");
                                                        console.log(formatted);
                                                        const customEvent = { target: { name: input.name, value: formatted } }
                                                        input.handleOnChange(customEvent)
                                                    }}
                                                    name={input.name}
                                                    // defaultValue={moment(new Date())}
                                                    format="hh:mm a"
                                                    // views={['hours', 'minutes']}
                                                    fullWidth
                                                    required
                                                    style={{ marginBlock: "6px", height: '45px !important' }}
                                                />
                                            </LocalizationProvider> */}
                                            <TimePicker name={input.name} timeValue={input.value} handleTimeOnChange={(t) => {
                                                console.log("time", t);
                                                const customEvent = { target: { name: input.name, value: t } }
                                                input.handleOnChange(customEvent)
                                            }} />
                                        </div>
                                    ) : (<TextField
                                        type={input.type}
                                        name={input.name}
                                        onChange={input.handleOnChange}
                                        value={input.value}
                                        placeholder={`Enter ${heading}`}
                                        fullWidth
                                        required
                                        style={{ marginBlock: "6px" }}
                                    />)}
                                </Grid>))
                            }

                        </Grid>
                        <Button
                            variant="outlined"
                            type="submit"
                            sx={{
                                color: "#fff",
                                borderColor: "#302924",
                                "&:hover": {
                                    borderColor: "#302924",
                                    color: "#302924",
                                },
                                marginTop: "15px",
                                float: "right",
                                fontSize: "12px",
                                backgroundColor: "#302924",
                            }}

                        >
                            {modalData.id ? "UPDATE" : "CREATE"}
                        </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default CommonModal;
