import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getAllRidingtype = createAsyncThunk("getAllRidingtype", async ({token, company}) => {
    try {
       
        const response = await api.get(`get_all_ridingtype/${company}`, {
            headers: {
                Authorization: `token ${token}`,
            },
        })
       
        return response.data
    } catch (error) {
        throw error;
    }
})


export const createRidingtype = createAsyncThunk("createRidingtype", async (payload) => {
    try {
        const response = await api.post("create_ridingtype", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.msg
    }
})

export const deleteRidingtype = createAsyncThunk("deleteRidingtype", async (id, token) => {
    try {
        const response = await api.delete(`delete_ridingtype/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateRidingtype = createAsyncThunk("updateRidingtype", async (payload) => {

    try {
        const response = await api.put(`update_ridingtype/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateRidingtype = createAsyncThunk("getupdateRidingtype", async (id, token) => {
    try {
        const response = await api.get(`get_category/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const RidingtypeSlice = createSlice({
    name: "RidingtypeSlice",
    initialState: {
        status: false,
        ridingType: null,
        error: null,
        updateRidingtype: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getAllRidingtype.pending, (state) => {
            state.status = true
        })
        builder.addCase(getAllRidingtype.fulfilled, (state, action) => {
            state.status = false
            state.ridingType = action.payload
            
        })
        builder.addCase(getAllRidingtype.rejected, (state) => {
            state.status = false
        })
        builder.addCase(deleteRidingtype.pending, (state) => {
            state.status = true
        })

        builder.addCase(deleteRidingtype.fulfilled, (state) => {
            state.status = false

        })
        builder.addCase(deleteRidingtype.rejected, (state) => {
            state.status = false
        })
        builder.addCase(createRidingtype.pending, (state) => {
            state.status = true
        })

        builder.addCase(createRidingtype.fulfilled, (state) => {
            state.status = false

        })
        builder.addCase(createRidingtype.rejected, (state) => {
            state.status = false
        })
        builder.addCase(updateRidingtype.pending, (state) => {
            state.status = true
        })

        builder.addCase(updateRidingtype.fulfilled, (state) => {
            state.status = false

        })
        builder.addCase(updateRidingtype.rejected, (state) => {
            state.status = false
        })
        builder.addCase(getupdateRidingtype.pending, (state) => {
            state.status = true
        })

        builder.addCase(getupdateRidingtype.fulfilled, (state, action) => {
            state.status = false
            state.updateRidingtype = action.payload

        })
        builder.addCase(getupdateRidingtype.rejected, (state) => {
            state.status = false
        })
    }
})

export default RidingtypeSlice.reducer;