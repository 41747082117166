import React, { useEffect } from "react";
import { createContext, useState } from "react";

export const Context = createContext();

export const ContextProvider = ({ children }) => {
  // Initial States @start
  const [localStorageData, setLocalStorageData] = useState(null);

  // Initial States @end

  // Action jaisa hai @start
  // @Action start
  const getLocalStorage = (data) => {
    const cartData = localStorage.getItem("Cart")
      ? JSON.parse(localStorage.getItem("Cart"))
      : null;
    if (cartData !== null) {
      setLocalStorageData(cartData);
    }
  };

  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  // @Action  end

  // Action jaisa hai @end
  return (
    <Context.Provider
      value={{
        localStorageData,
        getLocalStorage,
        theme,
        toggleTheme,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
