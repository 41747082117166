import React, { useContext, useEffect, useState } from "react";
import "./paymentModal.css";
import Loader from "../../components/Loader";
import { RxCross2 } from "react-icons/rx";
import horse1 from "../../../Assets/RidingSchoolAssets/payment_horse3.jpg";
import horse2 from "../../../Assets/RidingSchoolAssets/payment_horse4.jpg";
import courses from "../../../App/Apis/RidingSchoolApis/courseApi/course";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Context from "../../../Store/Context/Context";

const PaymentConfirmation = ({
  setPaymentConfirmationOpen,
  payloadData,
  isCart,
  setPayloadData,
  cashSinglePayload,
}) => {
  const [arr, setArr] = useState({
    arr: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { getLocalStorage } = useContext(Context);

  useEffect(() => {
    console.log("payload data", payloadData);
    console.log("cashSinglePayload", cashSinglePayload);
    setArr({ arr: isCart ? payloadData : cashSinglePayload });
  }, [payloadData, cashSinglePayload]);

  const handleCashPayment = async () => {
    setIsLoading(true);
    try {
      console.log("payload data", payloadData);
      await courses.throughCashPayment(arr);
      toast.success("Course created successfully");
      const cartData = localStorage.getItem("Cart")
        ? JSON.parse(localStorage.getItem("Cart"))
        : null;
      const afterDeletedData = cartData.filter((item) =>
        payloadData.some((pData) => pData.slot_id !== item.slot.id)
      );
      console.log("afterDeletedData", afterDeletedData);
      localStorage.setItem("Cart", JSON.stringify(afterDeletedData));
      getLocalStorage();
      navigate("/student-dashboard");
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="Payment__modal__wrapper">
      <div className="paymentModal__container ">
        <div className="paymentModal__icons">
          <RxCross2
            onClick={() => {
              setPaymentConfirmationOpen(false);
              if (isCart) {
                setPayloadData([]);
              }
            }}
          />
        </div>
        <div className="paymentModal__top">
          <div className="paymentModal__content">
            <h2>Booking Confirmation</h2>
            <p>Are you sure about purchasing this course via cash?</p>
          </div>
        </div>
        <div className="paymentModal__img">
          <img src={horse1} alt="horse1" style={{ objectFit: "cover" }} />
          <img src={horse2} alt="horse2" />
        </div>
        <div className="paymentModal__bottom">
          <div
            className="paymentModal__button"
            style={{ flexDirection: "row" }}
          >
            <button
              className="btn__no"
              onClick={() => {
                setPaymentConfirmationOpen(false);
                if (isCart) {
                  setPayloadData([]);
                }
              }}
            >
              No
            </button>
            <button className="btn__yes" onClick={handleCashPayment}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
