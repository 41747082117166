import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Apis";

export const getPlannerData = createAsyncThunk("getPlannerData", async (token) => {

  try {
    const response = await api.get("get_all_planner", {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  } catch (error) {

    throw error;
  }
});

export const createPlannerData = createAsyncThunk("createPlannerData", async (payload) => {
  console.log("payload", payload)
  try {
    const response = await api.post("create_planner", payload.modalData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deletePlannerData = createAsyncThunk("deletePlannerData", async ({ id, token }) => {
  try {
    const response = await api.delete(`delete_planner/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updatePlannerData = createAsyncThunk("updatePlannerData", async (payload) => {
  console.log("payload", payload)
  try {
    const response = await api.put(`update_planner/${payload.id}`, payload.updatedData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getUpdatePlannerData = createAsyncThunk(
  "getUpdatePlannerData",
  async (id, token) => {
    try {
      const response = await api.get(`get_planner/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const PlannerSlice = createSlice({
  name: "services",
  initialState: {
    plannerData: null,
    update: null,
    status: "",
    updatePlannerData: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlannerData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getPlannerData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.plannerData = action.payload;
    });
    builder.addCase(getPlannerData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(createPlannerData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createPlannerData.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(createPlannerData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(deletePlannerData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(deletePlannerData.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(deletePlannerData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(getUpdatePlannerData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getUpdatePlannerData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updatePlannerData = action.payload;
    });
    builder.addCase(getUpdatePlannerData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(updatePlannerData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updatePlannerData.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.updatePlannerData = action.payload
    });
    builder.addCase(updatePlannerData.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default PlannerSlice.reducer;
