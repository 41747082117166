import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Apis";

export const getServiceData = createAsyncThunk("getServiceData", async (token) => {

  try {
    const response = await api.get("get_all_services", {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  } catch (error) {

    throw error;
  }
});

export const createServiceData = createAsyncThunk("createServiceData", async (payload) => {
  console.log("payload", payload)
  try {
    const response = await api.post("create_service", payload.modalData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteServiceData = createAsyncThunk("deleteServiceData", async ({ id, token }) => {
  try {
    const response = await api.delete(`delete_service/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updateServiceData = createAsyncThunk("updateServiceData", async (payload) => {
  console.log("payload", payload)
  try {
    const response = await api.put(`update_service/${payload.id}`, payload.updatedData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getUpdateServiceData = createAsyncThunk(
  "getUpdateServiceData",
  async (id, token) => {
    try {
      const response = await api.get(`get_service/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);


const serviceSlice = createSlice({
  name: "services",
  initialState: {
    serviceData: null,
    facilityServiceTypeData: null,
    update: null,
    status: "",
    updateData: "",
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getServiceData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.serviceData = action.payload;
    });
    builder.addCase(getServiceData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(createServiceData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createServiceData.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(createServiceData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(deleteServiceData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(deleteServiceData.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(deleteServiceData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(getUpdateServiceData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getUpdateServiceData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateData = action.payload;
    });
    builder.addCase(getUpdateServiceData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(updateServiceData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateServiceData.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.updateData = action.payload
    });
    builder.addCase(updateServiceData.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default serviceSlice.reducer;
