import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getTax = createAsyncThunk("getTax", async (token) => {

  try {
    const response = await api.get("get_all_tax", {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  } catch (error) {

    throw error;
  }
});

export const createTax = createAsyncThunk("createTax", async (payload) => {
  console.log("payload", payload)
  try {
    const response = await api.post("create_tax", payload.modalData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteTax = createAsyncThunk("deleteTax", async ({ id, token }) => {
  try {
    const response = await api.delete(`delete_tax/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updateTax = createAsyncThunk("updateTax", async (payload) => {
  console.log("payload", payload)
  try {
    const response = await api.put(`update_tax/${payload.id}`, payload.updatedData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getUpdateTax = createAsyncThunk(
  "getUpdateTax",
  async (id, token) => {
    try {
      const response = await api.get(`get_tax/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const taxSlice = createSlice({
  name: "tax",
  initialState: {
    taxData: null,
    update: null,
    status: "",
    updateTax: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTax.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getTax.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.taxData = action.payload;
    });
    builder.addCase(getTax.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(createTax.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createTax.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(createTax.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(deleteTax.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(deleteTax.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(deleteTax.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(getUpdateTax.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getUpdateTax.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateTax = action.payload;
    });
    builder.addCase(getUpdateTax.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(updateTax.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateTax.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.updateTax = action.payload
    });
    builder.addCase(updateTax.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default taxSlice.reducer;
