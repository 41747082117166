

import React from "react";
import CommonEditablePage from "../../../SharedComponets/CommonEditablePage";
import { useSelector } from "react-redux";
import {
  createTransportation,
  getTransportation,
  updateTransportation,
} from "../../../App/Reducers/HeroQuillReducer";
import { transportation } from "../../../Assets/DefaultImagesCarousel";

const AboutUs = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.transportation);

  const defaultContent = `Horse transportation refers to the process of moving horses from one location to another, whether it's for purposes such as relocation, competition, breeding, or veterinary care. Proper horse transportation requires careful planning and adherence to safety guidelines to ensure the well-being of the animals throughout the journey. Here are some key considerations and methods for horse transportation:
  Horse Trailer: The most common method of horse transportation is by using a horse trailer. Trailers come in various sizes and designs, including bumper-pull and gooseneck trailers. It's important to choose a trailer that provides sufficient space, ventilation, and safety features such as dividers, padding, and secure tie-downs.
  Professional Transport Services: For long-distance or specialized transportation needs, hiring professional horse transport services may be advisable. These companies have experience in handling horses and provide vehicles specifically designed for equine transportation. They typically follow safety protocols, have trained staff, and can provide necessary paperwork and permits for interstate or international travel.
  Preparation: Before transportation, ensure that the horse is in good health, up to date on vaccinations, and has the required travel documentation. It's recommended to acclimate the horse to the trailer gradually to reduce stress and ensure it is comfortable with loading and unloading procedures.
  Safety Measures: During transportation, safety measures are crucial. Securely tie the horse with a properly fitted halter and lead rope or use trailer-specific safety restraints. Ensure good ventilation and temperature control inside the trailer, and periodically check on the horse during stops to offer water, hay, and monitor their condition.
  Breaks and Rest: For long journeys, schedule regular breaks to allow horses to stretch their legs, drink water, and rest. This helps prevent fatigue, stiffness, and dehydration.
  Proper Driving: If you are personally driving the trailer, be aware of traffic conditions, road surfaces, and weather conditions that may affect the safety of the journey. Drive smoothly, avoiding sudden accelerations, decelerations, and sharp turns to minimize stress on the horses.
  Legal and Regulatory Requirements: Familiarize yourself with any legal and regulatory requirements related to horse transportation, including health certificates, travel permits, and border crossing regulations. Different countries, states, or regions may have specific requirements for transporting horses.
  Always prioritize the safety, comfort, and well-being of the horses during transportation. Consulting with equine professionals or experienced horse transport services can provide valuable guidance and assistance in ensuring a smooth and safe journey for your horses`
  
  return (
    <>
      <CommonEditablePage
        updateApiFunc={updateTransportation}
        getApiFunc={getTransportation}
        createApiFunc = {createTransportation}
        modalSelector={modalSelector}
        default_image_title="Transportation"
        default_image={transportation.image}
        default_body_content ={defaultContent}
      />
    </>
  );
};

export default AboutUs;
