import React, { useEffect, useState } from "react";
import { MdEventAvailable, MdPendingActions } from "react-icons/md";
import api from "../../../Apis";
import { useCookies } from "react-cookie";
import Moment from "react-moment";
import { GrPlan } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Aos from "aos";

const TodaysPendingWork = () => {
  const navigate = useNavigate();

  const [eventData, setEventData] = useState(null);
  const [plannerData, setPlannerData] = useState(null);

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const eventHandler = async () => {
    try {
      const response = await api.get("/today_pending_event", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      setEventData(response.data);
    } catch (error) {
      throw error;
    }
  };

  const plannerhandler = async () => {
    try {
      const response = await api.get("/today_pending_planner", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      setPlannerData(response.data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    eventHandler();
    plannerhandler();
    Aos.init();
    console.log("plannerData", plannerData);
    console.log("eventData", eventData);
  }, []);

  return (
    <div className="status-container">
      <div className="status-top">
        <h4 className="dashboard-header">
          <MdPendingActions
            style={{
              backgroundColor: "#e7e2d5",
              borderRadius: "50%",
              padding: "5px",
              fontSize: "2rem",
            }}
          />
          &nbsp;<span>Today's Pending Work</span>
        </h4>
      </div>
      <div style={{ display: "flex", margin: "0px" }}></div>
      <div className="chart-container">
        <div className="events-container" data-aos="zoom-in-up" duration="3000">
          <h4>
            <MdEventAvailable style={{ fontSize: "20px" }} />
            &nbsp;&nbsp;Events
          </h4>
          <div className="events-notification">
            {eventData && eventData.length > 0 ? (
              <ul className="scrolling-text">
                {eventData.map((item) => (
                  <ul key={item.id} className="scrolling-container">
                    <li
                      onClick={() => navigate("/dashboard/events_calendar")}
                      style={{ cursor: "pointer" }}
                    >
                      <strong>{item.name && item.name}</strong> is starting from
                      <strong>
                        {new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                          timeZone: "Etc/GMT",
                        }).format(new Date(item.start_date && item.start_date))}
                      </strong>
                      .
                    </li>
                  </ul>
                ))}
              </ul>
            ) : (
              <p>No scheduled events for today.</p>
            )}
          </div>
        </div>

        <div
          className="planners-container"
          data-aos="zoom-in-up"
          duration="3000"
        >
          <h4>
            <GrPlan style={{ fontSize: "16px", fontWeight: "bold" }} />
            &nbsp;&nbsp;Planner
          </h4>
          <div className="planners-notification">
            {plannerData && plannerData.length > 0 ? (
              <ul className="scrolling-text">
                {plannerData.map((item, i) => (
                  <ul key={item.id} className="scrolling-container">
                    <li
                      onClick={() => navigate("/dashboard/planer/planer_list")}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <strong>{item.planner.name }</strong> */}
                      <strong>{item.name && item.name}</strong> of <strong>{item.horse_checkin && item.horse_checkin.horse && item.horse_checkin.horse.name && item.horse_checkin.horse.name}</strong> is pending on
                      <strong>
                        {new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                          timeZone: "Etc/GMT",
                        }).format(new Date(item.start_date && item.start_date))}
                      </strong>
                      .
                    </li>
                  </ul>
                ))}
              </ul>
            ) : (
              <p>No scheduled planners for today.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodaysPendingWork;
