

import React, { useEffect } from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ShowMobileNavbar } from '../../../App/Reducers/ModalReducer';

const DropdownItems = ({ submenus, location }) => {
  const userdaata=JSON.parse(localStorage.getItem('userData'))
  const dispatch = useDispatch();


  const handleItemClick = (e) => {
    e.stopPropagation();
    dispatch(ShowMobileNavbar(false));
  };
  


  return (
        <ul className="dropdown">
          {submenus.map((submenu, index) => (
            <li key={index} onClick={(e) => handleItemClick(e)} className={location.pathname === submenu.url ? 'active' : ''}>
              <Link to={submenu.url} className={location.pathname === submenu.url ? 'active' : ''} style={{ height:"100%", width:"100%"}}>
                <MdOutlineKeyboardArrowRight />
                {submenu.title}
              </Link>
            </li>
          ))}
        </ul>
      );
};

export default DropdownItems;







