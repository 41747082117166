import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseURL } from '../../Apis';
import api from "../../Apis";
export const GetGroomingPlanerList = createAsyncThunk('GetGroomingPlanerList', async (token) => {
  try {
    const response = await api.get(`get_all_grooming_planer`, {
      headers:{
        Authorization : `token ${token}`
      }
    });
    return response.data
  } catch (error) {
    console.log(error)
  }

})
export const CreateGroomingPlaner = createAsyncThunk('CreateGroomingPlaner', async (payload , { rejectWithValue }) => {
    try {
        const response = await api.post(`create_grooming_planer`,
        payload.formData , 
        {headers:{
            "Content-Type": "application/json",
            Authorization: `token ${payload.token}`
          }});
   
        return response.data
  
      } catch (error) {
        return rejectWithValue(error.response.data)
      }
  
  })
  export const GetUpdatingGroomingPlaner = createAsyncThunk('GetUpdatingGroomingPlaner', async (payload , { rejectWithValue }) => {
    try {
      const response = await api.get(`get_grooming_planer/${payload.id}`, {
        headers :{
          Authorization :`token ${payload.token}`
        }
      });
 
      return response.data
  
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
  
  })

  export const UpdateGroomingPlaner = createAsyncThunk('UpdateGroomingPlaner', async (payload) => {
    try {
      const response = await api.put(`update_grooming_planer/${payload.id}`,
      payload,
      {headers:{
          "Content-Type": "application/json",
          Authorization: `token ${payload.token}`
        }});
      return response.data

    } catch (error) {
      return console.log(error)
    }  
  
  })
 
  export const DeleteGroomingPlaner = createAsyncThunk('DeleteGroomingPlaner', async (payload , { rejectWithValue }) => {
    try {
        const response = await api.delete(`delete_grooming_planer/${payload.id}`, {
          headers:{
            Authorization:  `token ${payload.token}`
          }
        })      
        return response.data
  
      } catch (error) {
         return rejectWithValue(error.response.data)
      }  
  })
const initialState = {
  PlanerList: null ,
  UpdatingPlaner : null,
  status :{
    Get :"",
    Create:"",
    Updating:"",
    Update:"",
    Delete:""
  },
  errors :{
    Get:"",
    Create:"",
    Update:"",
    Delete:"",

  }
};

const GroomingPlanerSlice = createSlice({
  name: "GroomingPlaner",
  initialState,
  reducers: {
    Update: (state, action) => { },
  },
  extraReducers : (builder) =>{
    builder
    .addCase(GetGroomingPlanerList.pending , (state)=>{
        state.status.Get = "loading"
    })
    .addCase(GetGroomingPlanerList.fulfilled , (state , action)=>{
        state.status.Get = "succeeded"
        state.PlanerList = action.payload
        console.log(action.payload , "success")
    })
    .addCase(GetGroomingPlanerList.rejected , (state , action)=>{
        state.status.Get = "failed"
        console.log("failed")
       
    })
    .addCase(CreateGroomingPlaner.pending , (state)=>{
        state.status.Create = "loading"
    })
    .addCase(CreateGroomingPlaner.fulfilled , (state , action)=>{
        state.status.Create = "succeeded"
        console.log(action.payload , "succeeded")
    })
    .addCase(CreateGroomingPlaner.rejected , (state , action)=>{
        state.status.Create = "failed"
        console.log("failed")  
    })
    .addCase(GetUpdatingGroomingPlaner.pending , (state)=>{
        state.status.Updating = "loading"
    })
    .addCase(GetUpdatingGroomingPlaner.fulfilled , (state , action)=>{
        state.status.Updating = "succeeded"
        state.UpdatingPlaner  = action.payload
        console.log(action.payload , "succeeded")
        console.log(state.UpdatingPlaner  , "updating")
    })
    .addCase(GetUpdatingGroomingPlaner.rejected , (state , action)=>{
        state.status.Updating = "failed"
        console.log("failed")  
    })
    .addCase(UpdateGroomingPlaner.pending , (state)=>{
        state.status.Update = "loading"
    })
    .addCase(UpdateGroomingPlaner.fulfilled , (state , action)=>{
        state.status.Update = "succeeded"
        console.log(action.payload , "succeeded")

    })
    .addCase(UpdateGroomingPlaner.rejected , (state , action)=>{
        state.status.Update = "failed"
        console.log("failed")  
      
    })
}
})


export const { } = GroomingPlanerSlice.actions;
export default GroomingPlanerSlice.reducer;
