import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getColorMasterData = createAsyncThunk("getColorMasterData", async (token) => {
    try {
        const response = await api.get("get_all_color", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createColorMasterData = createAsyncThunk("createColorMasterData", async (payload) => {
    try {
        const response = await api.post("create_color", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteColorMasterData = createAsyncThunk("deleteColorMasterData", async (id, token) => {
    try {
        const response = await api.delete(`delete_color/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateColorMasterData = createAsyncThunk("updateColorMasterData", async (payload) => {
    console.log("payload", payload)
    try {
        const response = await api.put(`update_color/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateColorMasterData = createAsyncThunk("getupdateColorMasterData", async (id, token) => {
    try {
        const response = await api.get(`get_color/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const ColorMasterSlice = createSlice({
    name: "ColorMaster",
    initialState: {
        status: "",
        ColorMasterData: null,
        error: null,
        updateColorMasterData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getColorMasterData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getColorMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.ColorMasterData = action.payload
            
        })
        builder.addCase(getColorMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteColorMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteColorMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteColorMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createColorMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createColorMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createColorMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateColorMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateColorMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateColorMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateColorMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateColorMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateColorMasterData = action.payload

        })
        builder.addCase(getupdateColorMasterData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default ColorMasterSlice.reducer;