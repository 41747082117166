import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import courses from "../../../../App/Apis/RidingSchoolApis/courseApi/course";
import "./courselessons.css";
import { FcOk } from "react-icons/fc";
import { FaCircle } from "react-icons/fa6";
import { ImgUrl } from "../../../../Config/Config";
import { AiFillClockCircle } from "react-icons/ai";
import { IoCalendar } from "react-icons/io5";
import { GiHorseHead } from "react-icons/gi";
import { BsPersonCircle } from "react-icons/bs";
import Loader from "../../../components/Loader";
import moment from "moment";

const CourseLessons = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { course, booking, student } = useParams();
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const date = new Date();

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await courses.getCourseLessons({
        booking: Number(booking),
        course: Number(course),
        student: Number(student),
      });
      console.log(res);
      setData(res.data.sort((a, b) => a.lesson_no - b.lesson_no));
      setDataLength(res.data.length - 1);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="lesson-container">
          {/* <div className="lesson-course-section-md"></div> */}
          {/* <div className="lesson-sub-container"> */}
          {data.length === 0 ? (
            <h1 style={{ textAlign: "center", marginTop: "2rem" }}>
              No lesson has schedule yet
            </h1>
          ) : (
            data?.map((item, index) => (
              <>
                <div className="lesson-track-info">
                  <div className="lesson-track">
                    {item?.status === "Completed" ? (
                      <FcOk
                        size={"60px"}
                        style={{
                          marginTop: "0",
                        }}
                        title="Completed"
                        aria-label="Completed"
                      />
                    ) : (
                      //   <div className="track__circle"></div>
                      <FaCircle
                        size={"60px"}
                        style={{
                          marginTop: "0",
                        }}
                        color="#f7be16"
                        title="Pending"
                        aria-label="Pending"
                      />
                    )}

                    <div
                      className="track__line"
                      style={{
                        width: index === dataLength ? "0px" : "4px",
                        background:
                          item?.status === "Completed" ? "#4caf50" : "#f7be16",
                      }}
                    ></div>
                  </div>
                  <div className="lesson-info">
                    <div
                      className="lesson-schedule-info"
                      // style={{ display: "flex", gap: "2.65rem" }}
                    >
                      <p
                        className="center-p-span"
                        style={{
                          fontWeight: "700",
                          color:
                            item?.status === "Pending"
                              ? "rgb(159, 158, 158)"
                              : "black",
                        }}
                      >
                        <span className="center-p-span">
                          <AiFillClockCircle size={20} />
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        {item?.slot?.time}
                      </p>
                      <p
                        className="center-p-span"
                        style={{
                          fontWeight: "600",
                          color:
                            item?.status === "Pending"
                              ? "rgb(159, 158, 158)"
                              : "black",
                        }}
                      >
                        <span className="center-p-span">
                          <IoCalendar size={20} />
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        {item?.schedule_date
                          ? // ? date.toDateString(item?.schedule_date)

                            moment(item?.schedule_date).format(
                              "dddd, MMMM Do YYYY,"
                            )
                          : "Not Schedule yet"}
                      </p>
                    </div>
                    <div
                      className="lesson-horse-trainer-info"
                      //   style={{ display: "flex", gap: "1rem", marginTop: "2rem" }}
                    >
                      <p
                        className="center-p-span"
                        style={{
                          fontWeight: "400",
                          color:
                            item?.status === "Pending"
                              ? "rgb(159, 158, 158)"
                              : "black",
                        }}
                      >
                        <span className="center-p-span">
                          <GiHorseHead size={20} />
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        {item?.horse?.name} (Horse)
                      </p>
                      <p
                        className="center-p-span"
                        style={{
                          fontWeight: "400",
                          color:
                            item?.status === "Pending"
                              ? "rgb(159, 158, 158)"
                              : "black",
                        }}
                      >
                        <span className="center-p-span">
                          <BsPersonCircle size={20} />
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        {item?.trainer?.name} (Trainer)
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ))
          )}
          {/* <FcOk size={"60px"} />
          <div className="track__line"></div>
        <div className="lesson-info"></div> */}
          {/* </div> */}
          {/* <div className="lesson-course-section">
          <img
            className="lesson-course-imag"
            src={
              data[0]?.course?.image
                ? `${ImgUrl}${data[0]?.course?.image}`
                : null
            }
            alt=""
          />
        </div> */}
        </div>
      )}
    </>
  );
};

export default CourseLessons;
