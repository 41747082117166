import React, { useState, useEffect, useMemo } from 'react';
import DayView from './DayView';
import WeekView from './WeekView';
import MonthCal from './Months/MonthCal';
import MonthView from './Months/MonthView';
import EventForm from './EventForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetEvents } from '../../../App/Reducers/EventReducer';
import { useDispatch, useSelector } from 'react-redux';
import ListView from './ListView';
import { useCookies } from 'react-cookie';

function EventCalendar() {
  const dispatch = useDispatch()
  const [date, setDate] = useState(new Date());
  const [selectedView, setSelectedView] = useState(<MonthView />);
  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;
  const Events = useSelector((state) => state.events.EventsList)
  const navigate = useNavigate();
  const events = [
    { id: 1, sdate: '2023-07-03T10:24:10Z', title: 'Meeting', end_date: '2023-07-13T10:24:10Z' },
    { id: 2, sdate: '2023-07-03T10:24:10Z', title: 'Lunch', end_date: '2023-07-13T10:24:10Z' },
    { id: 3, sdate: '2023-07-03T10:24:10Z', title: 'Presentation', end_date: '2023-07-13T10:24:10Z' },
    // Add more events here
  ];
  // const nextBTN1 = useMemo(() => nextBTN(date), [date])
  // const previousBTN1 = useMemo(() => previousBTN(date), [date])
  const nextBTN = () => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 1);
    setDate(newDate);
  };

  const location = useLocation()
  const path_name = location.pathname;

  const previousBTN = () => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - 1);
    setDate(newDate);
  };
  const Buttonstyle = {
    marginInline: "0.8rem ",
    padding: "0.3rem 0.7rem",
    borderRadius: "0.5rem",
    border: "1px solid #7c5e1e ",
    color: " #fff",
    backgroundColor: "#7c5e1e",
    fontSize: "0.85rem",
    boxShadow: "0px 0px 3px rgba(0,0,0,0.75)",
    cursor: "pointer"
  }
  const navigationMenu = (e) => {
    const selectedOption = e.target.innerText;
    switch (selectedOption) {
      case 'Day View':
        setSelectedView(
          <DayView
          // events={Events}
          // currentDate={date}
          // next={nextBTN}
          // previous={previousBTN}
          />
        );
        break;
      case 'Week View':
        setSelectedView(
          <WeekView
          // events={events}
          // currentDate={date}
          // next={nextBTN}
          // previous={previousBTN}
          />
        );
        break;
      case 'Month View':
        setSelectedView(
          <>
            <MonthView />
            <EventForm />
          </>
        );
        break;
      case 'List View':
        setSelectedView(
          <ListView />
        )
        break;
      default:
        setSelectedView(null);
        break;
    }
  };
  useEffect(() => {
    dispatch(GetEvents(token))
  }, [dispatch])

  return (
    <>
      <h1>calendar</h1>
      {path_name === "/riding-club/events" ? null : <EventForm />}
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        margin: "2rem 0 1rem",


      }}>
        <button style={Buttonstyle} onClick={(e) => navigationMenu(e)}>Month View</button>
        <button style={Buttonstyle} onClick={(e) => navigationMenu(e)}>Day View</button>
        <button style={Buttonstyle} onClick={(e) => navigationMenu(e)}>Week View</button>
        <button style={Buttonstyle} onClick={(e) => navigationMenu(e)}>List View</button>
      </div>

      {selectedView && selectedView}

      {/* Render other components or elements */}
    </>
  );
}

export default EventCalendar;
