import React from 'react'
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom'
import horse from "../../../Assets/Images/horse2.svg";
import horse_trainer from "../../../Assets/Images/trainer2.svg";
import horse_owner from '../../../Assets/Images/owner2.svg';

const DocExpire = (props) => {
    const navigate = useNavigate()
    console.log(" props.horseFEIData", props.horseFEIData);

    if (props.DashboardName && props.DashboardName === 'riding-club') {
        return (
            <>
                <div style={{ border: "1px solid #baa987", borderRadius: "8px", padding: "10px", marginBlock: "10px", maxHeight: "460px", minHeight: "460px", overflow: "auto", paddingBottom: "20px", display: "flex", gap: "5px", flexDirection: "column" }}>
                    {/* Horse Expired Documents @start */}

                    {
                        props.rindingTrainer ? props.rindingTrainer.map((item) => {
                            return (
                                <div key={item.id} style={{ border: "1px solid #baa987", borderRadius: "8px", padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                                        <img src={horse} alt="" style={{ width: "30px" }} />
                                        <p style={{ display: "flex", flexDirection: 'column' }}>
                                            {item.horse_id && <p style={{ fontSize: "13px", fontWeight: "bold" }}>{item.document_type.name ? item.document_type.name : null} was expired for {item.horse_id && item.horse_id.name ? item.horse_id.name : null} </p>}
                                            <span style={{ color: "#9f8754" }}><Moment format='MMM DD, YYYY'>{item.expiry_date ? item.expiry_date : null}</Moment> </span>
                                        </p>
                                    </div>
                                    <div style={{ maxWidth: "250px", minWidth: "100px", display: "flex", justifyContent: "flex-end" }}> {item.horse_id && < button onClick={() => navigate(`/dashboard/horse/horse-profile/${item.horse_id && item.horse_id.id ? item.horse_id.id : null}`)} style={{ backgroundColor: "#f1ebda", fontSize: "12px", color: "black", border: "none", padding: "0.5rem", cursor: "pointer", borderRadius: "5px", fontWeight: "bold" }}>Renew now</button>}</div>
                                </div>
                            )
                        })
                            : null
                    }
                </div>
            </>)
    }
    else {
        return (
            <>
                <div style={{ border: "1px solid #baa987", borderRadius: "8px", padding: "10px", marginBlock: "10px", maxHeight: "460px", minHeight: "460px", overflow: "auto", paddingBottom: "20px", display: "flex", gap: "5px", flexDirection: "column" }}>
                    {/* Horse Expired Documents @start */}

                    {
                        props.horseData ? props.horseData.map((item) => {
                            return (
                                <div key={item.id} style={{ border: "1px solid #baa987", borderRadius: "8px", padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                                        <img src={horse} alt="" style={{ width: "30px" }} />
                                        <p style={{ display: "flex", flexDirection: 'column' }}>
                                            {item.horse_id && <p style={{ fontSize: "13px", fontWeight: "bold" }}>{item.document_type.name ? item.document_type.name : null} was expired for {item.horse_id && item.horse_id.name ? item.horse_id.name : null} </p>}
                                            <span style={{ color: "#9f8754" }}><Moment format='MMM DD, YYYY'>{item.expiry_date ? item.expiry_date : null}</Moment> </span>
                                        </p>
                                    </div>
                                    <div style={{ maxWidth: "250px", minWidth: "100px", display: "flex", justifyContent: "flex-end" }}> {item.horse_id && < button onClick={() => navigate(`/dashboard/horse/horse-profile/${item.horse_id && item.horse_id.id ? item.horse_id.id : null}`)} style={{ backgroundColor: "#f1ebda", fontSize: "12px", color: "black", border: "none", padding: "0.5rem", cursor: "pointer", borderRadius: "5px", fontWeight: "bold" }}>Renew now</button>}</div>
                                </div>
                            )
                        })
                            : null
                    }

                    {/* Horse Expired Documents @end */}


                    {/* Horse FEI Expired Documents @start */}

                    {
                        props.horseFEIData ? props.horseFEIData.map((item) => {
                            return (
                                <div key={item.id} style={{ border: "1px solid #baa987", borderRadius: "8px", padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                                        <img src={horse} alt="" style={{ width: "30px" }} />
                                        <p style={{ display: "flex", flexDirection: 'column' }}>
                                            {item.id && <p style={{ fontSize: "13px", fontWeight: "bold" }}>FEI passport with the number {item.fei_registration_number && item.fei_registration_number ? item.fei_registration_number : null} has expired</p>}
                                            <span style={{ color: "#9f8754" }}><Moment format='MMM DD, YYYY'>{item.fei_passport_expiry_date ? item.fei_passport_expiry_date : null}</Moment> </span>
                                        </p>
                                    </div>
                                    <div style={{ maxWidth: "250px", minWidth: "100px", display: "flex", justifyContent: "flex-end" }}> {item.id && < button onClick={() => navigate(`/dashboard/horse/horse-profile/${item.id ? item.id : null}`)} style={{ backgroundColor: "#f1ebda", fontSize: "12px", color: "black", border: "none", padding: "0.5rem", cursor: "pointer", borderRadius: "5px", fontWeight: "bold" }}>Renew now</button>}</div>
                                </div>
                            )
                        })
                            : null
                    }
                    {/* Horse FEI Expired Documents @end */}


                    {/* Owner Expired Documents @start */}
                    {
                        props.ownerData ? props.ownerData.map((item) => {
                            return (
                                <div key={item.id} style={{ border: "1px solid #baa987", borderRadius: "8px", padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                                        <img src={horse_owner} alt="" style={{ width: "30px" }} />
                                        <p style={{ display: "flex", flexDirection: 'column' }}>
                                            {item.owner_id && < p style={{ fontSize: "13px", fontWeight: "bold" }}> {item.document_type.name ? item.document_type.name : null} was expired for {item.owner_id && item.owner_id.name ? item.owner_id.name : null} </p>}

                                            <span style={{ color: "#9f8754" }}><Moment format='MMM DD, YYYY'>{item.expiry_date ? item.expiry_date : null}</Moment> </span>
                                        </p>
                                    </div>
                                    <div style={{ maxWidth: "250px", minWidth: "100px", display: "flex", justifyContent: "flex-end" }}> {item.owner_id && <button onClick={() => navigate(`/dashboard/owner/owner-profile/${item.owner_id && item.owner_id.id ? item.owner_id.id : null}`)} style={{ backgroundColor: "#f1ebda", fontSize: "12px", color: "black", border: "none", padding: "0.5rem", cursor: "pointer", borderRadius: "5px", fontWeight: "bold" }}>Renew now</button>}</div>
                                </div>
                            )
                        })
                            : null
                    }

                    {/* Owner Expired Documents @end */}

                    {/* Trainer Expired Documents @start */}
                    {
                        props.trainerData ? props.trainerData.map((item) => {
                            return (
                                <div key={item.id} style={{ border: "1px solid #baa987", borderRadius: "8px", padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                                        <img src={horse_trainer} alt="" style={{ width: "30px" }} />
                                        <p style={{ display: "flex", flexDirection: 'column' }}>
                                            {item.trainer_id && <p style={{ fontSize: "13px", fontWeight: "bold" }}>{item.document_type.name ? item.document_type.name : null} was expired for {item.trainer_id && item.trainer_id.name ? item.trainer_id.name : null} </p>}
                                            <span style={{ color: "#9f8754" }}><Moment format='MMM DD, YYYY'>{item.expiry_date ? item.expiry_date : null}</Moment> </span>
                                        </p>
                                    </div>
                                    <div style={{ maxWidth: "250px", minWidth: "100px", display: "flex", justifyContent: "flex-end" }}> {item.trainer_id && <button onClick={() => navigate(`/dashboard/trainer/trainer-profile/${item.trainer_id && item.trainer_id.id ? item.trainer_id.id : null}`)} style={{ backgroundColor: "#f1ebda", fontSize: "12px", color: "black", border: "none", padding: "0.5rem", cursor: "pointer", borderRadius: "5px", fontWeight: "bold" }}>Renew now</button>}</div>

                                </div>
                            )
                        })
                            : null
                    }
                    {/* Trainer Expired Documents @end */}
                </div >
            </>
        )
    }
}

export default DocExpire