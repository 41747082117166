import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ModalComp from "./Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  getCategoryMasterData,
  createCategoryMasterData,
  updateCategoryMasterData,
  deleteCategoryMasterData,
  getupdateCategoryMasterData,
} from "../../App/Reducers/AdminSettingReducer/CategoryMasterReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import api from "../../Apis";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../App/Helpers/AlertMessage";
import DeleteModal from "../../App/Helpers/DeleteModal";
const CategoryMaster = () => {
  const dispatch = useDispatch();
  const CategoryMasterData = useSelector(
    (state) => state.categoryMaster.CategoryMasterData
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);
  const [modalData, setModalData] = useState({
    name: "",
  });

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createOrUpdateHandler = () => {
    if (modalData.id) {
      dispatch(
        updateCategoryMasterData({
          id: modalData.id,
          updatedData: {
            name: modalData.name,
          },
          token,
        })
      );
    } else {
      dispatch(createCategoryMasterData({ modalData, token }));
    }
    closeModal();
    dispatch(getCategoryMasterData(token));
  };

  const editHandler = (id) => {
    const editData = CategoryMasterData.find((data) => data.id === id);
    if (editData) {
      setModalData(editData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_category/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getCategoryMasterData(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };
  const openModal = () => {
    setModalData({
      name: "",
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    dispatch(getCategoryMasterData(token));
  }, [dispatch, token, modalOpen]);

  const header = [
    {
      Header: "Category Master",
      accessor: "name",
    },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>
      {CategoryMasterData ? (
        <BasicTable
          colHeader={header}
          rowData={CategoryMasterData}
          updateHandler={editHandler}
          deleteHandler={deleteHandler}
          createHandler={openModal}
          tableHeading="Category Master List"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          updateHandler={editHandler}
          deleteHandler={deleteHandler}
          createHandler={openModal}
          tableHeading="Category Master List"
        />
      )}

      <ModalComp
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        label="ADD Category Master"
        heading="Category Master"
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Category Master"
        deleteData={deleteData}
      />
      <ToastContainer />
    </>
  );
};
export default CategoryMaster;
