import Api from "../ApiConfig";
import { companyId } from "../../RidingSchoolReuseIds/Ids";

const courses = {
  getCourseList: () => Api.get(`compiens_courses/${companyId}`),
  getAllCourses: () => Api.get(`get_all_course`),
  getAllCompanies: () => Api.get(`get_all_company`),
  getAllCoursesPagination: () => Api.get(`courses_pagination`),
  getRidingTypesList: (id) => Api.get(`get_all_ridingtype/${id}`),
  getSingleCourseData: (id) => Api.get(`get_course/${id}`),
  getCourseDetail: (id) => Api.get(`courses_details/${id}`),
  createCourse: (payload) =>
    Api.post(`create_course`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updateCourse: (id, payload) =>
    Api.put(`update_course/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteCourse: (id) => Api.delete(`delete_course/${id}`),
  pagination: (page, search, price) =>
    Api.get(
      search
        ? `courses_pagination?page=${page}&search=${search}&price_range=${
            price ? price : ""
          }`
        : `courses_pagination?page=${page}&price_range=${price ? price : ""}`
    ),
  buyCourse: (payload) => Api.post(`stripe/checkout-create-session`, payload),
  createBooking: (payload) => Api.post("bookings/create", payload),
  buyCartCourse: (payload) =>
    Api.post(`stripe/bulk/checkout-create-session`, payload),
  createCartBooking: (payload) => Api.post("bookings/bulk_create", payload),
  getEnrolledCourse: (studentId) => Api.get(`bookings/${studentId}`),
  getCourseLessons: (payload) => Api.post("student/lessons", { ...payload }),
  getStudentEvents: (payload) => Api.post("lesson/events", { ...payload }),
  updateCourseLesson: (payload) => {
    const { id } = payload;
    delete payload.id;
    return Api.put(`update_lesson/${id}`, payload);
  },
  minMaxPrice: () => Api.get("min_max_prices"),
  throughCashPayment: (payload) => Api.post("bookings/create/pending", payload),
};

export default courses;
