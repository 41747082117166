import { Autocomplete, Button, Grid, ImageList, ImageListItem, TextField, TextareaAutosize } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { companyId } from '../../../App/Apis/RidingSchoolReuseIds/Ids'
import { useFormik } from 'formik'
import courses from '../../../App/Apis/RidingSchoolApis/courseApi/course'
import { ImgUrl } from '../../../Config/Config'
import Loader from '../../components/Loader'
import { toast } from 'react-toastify'

const CourseCreate = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const company_UID = companyId
    const [ridingOptions, setRidingOptions] = useState([])
    const [singleData, setSingleData] = useState()
    const [loading, setLoading] = useState(false)

    const fetchingData = async () => {
        try {
            const res = await courses.getRidingTypesList(company_UID)
            setRidingOptions(res.data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchingData()
    }, [])



    const initialValues = {
        course_name: "",
        category: "",
        sub_category: "",
        price: "",
        child_price: "",
        description: "",
        riding_type: "",
        lessons: "",
        slot: "",
        seats: "",
        image: null,
        note_and_restrication: "",
        additional_info: "",
        cancellation_policy: "",
        company: company_UID
    }



    const createCourses = async (payload) => {
        setLoading(true)
        try {
            await courses.createCourse(payload)
            navigate('/riding-club/course/course_list')
        }
        catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    const updateCourses = async (id, payload) => {
        setLoading(true)
        try {
            await courses.updateCourse(id, payload)
            navigate('/riding-club/course/course_list')
        }
        catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        onSubmit: (value, actions) => {
            const formdata = new FormData();
            formdata.append("course_name", values?.course_name);
            formdata.append("category", values?.category);
            formdata.append("sub_category", values?.sub_category);
            formdata.append("price", values?.price);
            formdata.append("child_price", values?.child_price);
            formdata.append("description", values?.description);
            formdata.append("riding_type", values?.riding_type);
            formdata.append("lessons", values?.lessons);
            formdata.append("slot", values?.slot);
            formdata.append("seats", values?.seats);
            formdata.append("note_and_restrication", values?.note_and_restrication);
            formdata.append("additional_info", values?.additional_info);
            formdata.append("cancellation_policy", values?.cancellation_policy);
            formdata.append("company", values?.company);
            if (values.image instanceof File) {
                formdata.append("image", values?.image);
            }

            console.log(value)
            if (!id) {
                createCourses(formdata)
                toast.success("Course Created Successfully")
            }
            else {
                updateCourses(id, formdata)
                toast.success("Course Updated Successfully")
            }
            const doc = document.getElementById('upload-photo')
            doc.value = null
            actions.resetForm()
        }
    })


    useEffect(() => {
        if (id) {
            const fetchingSingleData = async () => {
                setLoading(true)
                try {
                    const res = await courses.getSingleCourseData(id);
                    setSingleData(res.data);
                } catch (err) {
                    console.log(err);
                }
                setLoading(false)
            };

            fetchingSingleData();
        }
    }, [id]);


    useEffect(() => {
        if (singleData) {
            setFieldValue("course_name", singleData?.course_name || '');
            setFieldValue("category", singleData?.category || '');
            setFieldValue("sub_category", singleData?.sub_category || '');
            setFieldValue("price", singleData?.price || '');
            setFieldValue("child_price", singleData?.child_price || '');
            setFieldValue("description", singleData?.description || '');
            setFieldValue("riding_type", singleData?.riding_type?.id || '');
            setFieldValue("image", singleData?.image);
            setFieldValue("lessons", singleData?.lessons || '');
            setFieldValue("slot", singleData?.slot || '');
            setFieldValue("seats", singleData?.seats || '');
            setFieldValue("note_and_restrication", singleData?.note_and_restrication || '');
            setFieldValue("additional_info", singleData?.additional_info || '');
            setFieldValue("cancellation_policy", singleData?.cancellation_policy || '');
        }
    }, [setFieldValue, singleData]);


    const ridingTypeOptions = ridingOptions.map((rideType) => (
        { value: rideType.id, label: rideType.name }
    ))

    function handleRidingType(e, data) {
        const ridingType = data?.value;
        setFieldValue("riding_type", ridingType)
    }


    function handleFile(e) {
        const { files } = e.target;

        if (files) {
            const selectedFile = files[0];
            console.log(selectedFile)

            if (!selectedFile) {
                return;
            }
            const allowedExtensions = ["jpg", "jpeg", "png"];
            const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
            console.log(fileExtension)

            if (!allowedExtensions.includes(fileExtension)) {
                e.target.value = null;
                setFieldValue("image", null)
                toast.error("Please upload a file with a .jpg, .jpeg, or .png extension.")
            } else if (selectedFile.size > 1024 * 1024) {
                e.target.value = null;
                setFieldValue("image", null)
                toast.error("Please upload an image that is less than 1MB in size.");
            } else {
                setFieldValue("image", e.target.files[0])
            }

        }
    }


    if (loading) {
        return <Loader />
    }

    const ridingCategoryOptions = [
        { value: 1, label: "Individual" },
        { value: 2, label: "Group" }
    ]


    function handleCategoryType(e, data) {
        const ridingCategoryType = data?.label;
        setFieldValue("category", ridingCategoryType)
    }

    const ridingSubCategoryOptions = [
        { value: 1, label: "Horse" },
        { value: 2, label: "Pony Club" }
    ]


    function handleSubCategoryType(e, data) {
        const ridingSubCategoryType = data?.label;
        setFieldValue("sub_category", ridingSubCategoryType)
    }

    return (
        <div className="form-wrapper-container">
            <div className="registration-card form-container" data-aos="fade-left" data-aos-duration="1000">
                <header>
                    <div className="registration_top_header">
                        <p>{id ? <p>Update Courses</p> : <p>Courses</p>}</p>
                    </div>
                </header>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={6} md={4}>
                            <label>Package <span style={{ color: "red" }}>*</span></label>
                            <TextField
                                type="text"
                                name="course_name"
                                onChange={handleChange}
                                value={values.course_name}
                                fullWidth
                                required
                                className="bg-color"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} style={{ marginBottom: "2rem" }}>
                            <label>Category<span style={{ color: "red" }}>*</span></label>

                            <Autocomplete
                                id="combo-box-demo"
                                name="category"
                                value={values.category && ridingCategoryOptions.find((v) => values.category === v.label)}
                                onChange={handleCategoryType}
                                required
                                fullWidth
                                options={ridingCategoryOptions}
                                renderInput={(params) => (
                                    <TextField required className="bg-color" {...params} />
                                )}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ marginBottom: "2rem" }}>
                            <label>Sub Category<span style={{ color: "red" }}>*</span></label>

                            <Autocomplete
                                id="combo-box-demo"
                                name="sub_category"
                                value={values.sub_category && ridingSubCategoryOptions.find((v) => values.sub_category === v.label)}
                                onChange={handleSubCategoryType}
                                required
                                fullWidth
                                options={ridingSubCategoryOptions}
                                renderInput={(params) => (
                                    <TextField required className="bg-color" {...params} />
                                )}

                            />
                        </Grid>



                    </Grid>

                    <Grid container spacing={3}>


                        {/* <Grid item xs={12} sm={6} md={4}>
                            <label>Child Price<span style={{ color: "red" }}>*</span></label>
                            <TextField
                                type="number"
                                name="child_price"
                                onChange={handleChange}
                                value={values.child_price}
                                fullWidth
                                required
                                className="bg-color"
                            />
                        </Grid> */}

                        <Grid item xs={12} sm={6} md={4}>
                            <label>Price <span style={{ color: "red" }}>*</span></label>
                            <TextField
                                type="number"
                                name="price"
                                onChange={handleChange}
                                value={values.price}
                                fullWidth
                                required
                                className="bg-color"
                            />
                        </Grid>


                        <Grid item xs={12} sm={6} md={4}>
                            <label>Lesson <span style={{ color: "red" }}>*</span></label>
                            <TextField
                                type="number"
                                name="lessons"
                                onChange={handleChange}
                                value={values.lessons}
                                fullWidth
                                required
                                className="bg-color"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <label>Riding Discipline <span style={{ color: "red" }}>*</span></label>
                            <Autocomplete
                                id="combo-box-demo"
                                name="horse_checkin"
                                value={values.riding_type && ridingTypeOptions.find((v) => values.riding_type === v.value)}
                                onChange={handleRidingType}
                                required
                                fullWidth
                                options={ridingTypeOptions}
                                renderInput={(params) => (
                                    <TextField required className="bg-color" {...params} />
                                )}

                            />
                        </Grid>

                    </Grid>

                    <Grid container spacing={3} marginTop={"0.1rem"}>

                        <Grid item xs={12} sm={6} md={4}>
                            <label>Slot<span style={{ color: "red" }}>*</span></label>
                            <TextField
                                type="number"
                                name="slot"
                                onChange={handleChange}
                                value={values.slot}
                                fullWidth
                                required
                                className="bg-color"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <label>Seats <span style={{ color: "red" }}>*</span></label>
                            <TextField
                                type="number"
                                name="seats"
                                onChange={handleChange}
                                value={values.seats}
                                fullWidth
                                required
                                className="bg-color"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <label htmlFor={`upload-photo`}>
                                Attach File</label>
                            <TextField
                                className="bg-color"
                                name="image"
                                type="file"
                                id={`upload-photo`}
                                // required
                                onChange={handleFile}
                                accept=".jpg, .jpeg, .png"
                                fullWidth
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <div style={{ marginBlock: "5%", marginTop: "1rem" }}>
                                        <ImageList
                                            sx={{ width: 400, height: "auto" }}
                                            cols={2}
                                            rowHeight={"auto"}
                                        >
                                            <ImageListItem>
                                                {typeof values.image === "string" && (
                                                    <img
                                                        src={`${ImgUrl}${values.image}`}
                                                        alt="Uploaded file"
                                                        loading="lazy"
                                                    />
                                                )}
                                                {values.image instanceof File && (
                                                    <img
                                                        src={URL.createObjectURL(values.image)}
                                                        alt="Uploaded file"
                                                        loading="lazy"
                                                    />
                                                )}
                                            </ImageListItem>
                                        </ImageList>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    {/* Description */}

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                            <label>Description <span style={{ color: "red" }}>*</span></label>
                            <TextareaAutosize
                                minRows={6}
                                // placeholder="Empty"
                                value={values.description}
                                onChange={handleChange}
                                name="description"
                                className="bg-color"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                            <label>Note And Restrication</label>
                            <TextareaAutosize
                                minRows={6}
                                // placeholder="Empty"
                                value={values.note_and_restrication}
                                onChange={handleChange}
                                name="note_and_restrication"
                                className="bg-color"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                            <label>Additional Information</label>
                            <TextareaAutosize

                                minRows={6}
                                // placeholder="Empty"
                                value={values.additional_info}
                                onChange={handleChange}
                                name="additional_info"
                                className="bg-color"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                            <label>Cancellation Policy</label>
                            <TextareaAutosize
                                minRows={6}
                                // placeholder="Empty"
                                value={values.cancellation_policy}
                                onChange={handleChange}
                                name="cancellation_policy"
                                className="bg-color"
                            />
                        </Grid>
                    </Grid>



                    <div className="btn-submit" style={{ gap: "15px" }}>
                        <Button type="submit" variant="contained" className="btn-bgColor" onClick={() => navigate("/riding-club/course/course_list")}>
                            Cancel
                        </Button>
                        {id ? (

                            <Button type="submit" variant="contained" className="btn-bgColor">
                                Update
                            </Button>
                        ) : (

                            <Button type="submit" variant="contained" className="btn-bgColor">
                                Create
                            </Button>
                        )}
                    </div>

                </form>

            </div>

        </div >
    )
}

export default CourseCreate
