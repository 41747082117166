import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseURL } from '../../Apis';
import api from "../../Apis";
export const GetHealthPlanerList = createAsyncThunk('GetHealthPlanerList', async (token) => {
  try {
    const response = await api.get(`get_all_health_planer`, {
      headers:{
        Authorization : `token ${token}`
      }
    });
    console.log(response, "api/get_all_food_planer")
    return response.data

  } catch (error) {
    console.log(error)
  }

})
export const CreateHealthPlaner = createAsyncThunk('CreateHealthPlaner', async (payload , { rejectWithValue }) => {
    try {
        const response = await api.post(`create_healt_planer`,
        payload , 
        {headers:{
            "Content-Type": "application/json",
            Authorization : `token ${payload.token}`
          }});
        console.log(response, "/api/create_food_planer")
        return response.data
  
      } catch (error) {
        return rejectWithValue(error.response.data)
      }
  
  })
  export const GetUpdatingHealthPlaner = createAsyncThunk('GetUpdatingHealthPlaner', async (payload , { rejectWithValue }) => {
    try {
      const response = await api.get(`get_health_planer/${payload.id}`, {
        headers :{
          Authorization : `token ${payload.token}`
        }
      });
      return response.data
  
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
  
  })

  export const UpdateHealthPlaner = createAsyncThunk('UpdateHealthPlaner', async (Data) => {
    console.log('sdbsjdhsjdhjsdh',Data);
    try {
      const response = await api.put(`update_health_planer/${Data.id}`,
      Data,
      {headers:{
          "Content-Type": "application/json",
          Authorization : ` token ${Data.token}`
        }});
      console.log(response, "sahjfgdeudjgfvbdrvjfdk")
      return response.data

    } catch (error) {
      return console.log(error)
    }  
  
  })
 

  export const DeleteHealthPlaner = createAsyncThunk('DeleteHealthPlaner', async (payload , { rejectWithValue }) => {
    try {
        const response = await api.delete(`delete_health/${payload.id}`, {
          headers:{
            Authorization :`token ${payload.token}`
          }
        })      
        console.log(response, "Delete")
        return response.data
  
      } catch (error) {
        console.log(error)
         return rejectWithValue(error.response.data)
      }  
  })
const initialState = {
  List: null ,
  UpdatingPlaner : null,
  status :{
    Get :"",
    Create:"",
    Updating:"",
    Update:"",
    Delete:""
  },
  errors :{
    Get:"",
    Create:"",
    Update:"",
    Delete:"",

  }
};

const HealthPlanerSlice = createSlice({
  name: "HealthPlaner",
  initialState,
  reducers: {
    Update: (state, action) => { },
  },
  extraReducers : (builder) =>{
    builder
    .addCase(GetHealthPlanerList.pending , (state)=>{
        state.status.Get = "loading"
    })
    .addCase(GetHealthPlanerList.fulfilled , (state , action)=>{
        state.status.Get = "succeeded"
        state.List = action.payload
        console.log(action.payload , "success")
    })
    .addCase(GetHealthPlanerList.rejected , (state , action)=>{
        state.status.Get = "failed"
        console.log("failed")
       
    })
    .addCase(CreateHealthPlaner.pending , (state)=>{
        state.status.Create = "loading"
    })
    .addCase(CreateHealthPlaner.fulfilled , (state , action)=>{
        state.status.Create = "succeeded"
        console.log(action.payload , "succeeded")
    })
    .addCase(CreateHealthPlaner.rejected , (state , action)=>{
        state.status.Create = "failed"
        console.log("failed")  
    })
    .addCase(GetUpdatingHealthPlaner.pending , (state)=>{
        state.status.Updating = "loading"
    })
    .addCase(GetUpdatingHealthPlaner.fulfilled , (state , action)=>{
        state.status.Updating = "succeeded"
        state.UpdatingPlaner  = action.payload
        console.log(action.payload , "succeeded")
        console.log(state.UpdatingPlaner  , "updating")
    })
    .addCase(GetUpdatingHealthPlaner.rejected , (state , action)=>{
        state.status.Updating = "failed"
        console.log("failed")  
    })
    .addCase(UpdateHealthPlaner.pending , (state)=>{
        state.status.Update = "loading"
    })
    .addCase(UpdateHealthPlaner.fulfilled , (state , action)=>{
        state.status.Update = "succeeded"
        console.log(action.payload , "succeeded")

    })
    .addCase(UpdateHealthPlaner.rejected , (state , action)=>{
        state.status.Update = "failed"
        console.log("failed")  
      
    })
}
})



export default HealthPlanerSlice.reducer;
