import React, { useEffect, useState } from "react";
import "./register.css";
import api from "../../Apis";
import {  useSelector } from "react-redux";
import { TextField, Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoginPageLeftContainer from "./LoginPageLeftContainer";
import { Link, useNavigate } from "react-router-dom";
import CommonLoading from "../../SharedComponets/Loading/CommonLoading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Register = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    phone_number: "",
    company_name: "",
    address: "",
    password: "",
    city: "",
    state: "",
    country: "",
    remark: "",
  });

  const adminLoading = useSelector((state) => state.registration.adminstatus);
  const [countries, setCountries] = useState(null);
  const [isemailExit, setIsEmailExit] = useState("");
  const [confirmPswd, setConfirmPswd] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneChange = (value) => {
    // Update the phoneNumber state
    setPhoneNumber(value);

    // Assuming 'phoneNumber' is the name of the field in your form data
    setUser((prevFormData) => ({
      ...prevFormData,
      phone_number: value, // Set the phone number in the form data
    }));
  };


  const handleUser = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.post(`create_company`, user, {
        headers: {
          "content-type": "application/json",
        },
      });
      if (response.statusText === "Created" || response.status === "201") {
        setIsLoading(false);
        navigate("/login");
      }

      return response.data;
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleAutoComplete = (event, newValue, fieldName) => {
    const selectedValue = newValue ? newValue.id : null;
    setUser((preData) => ({
      ...preData,
      [fieldName]: selectedValue,
    }));
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const handlePasswordValidation = (e) => {
    const { value } = e.target;
    const passwordRegex =
      /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

    // Check if password meets the required conditions
    setIsPasswordValid(passwordRegex.test(value));
    setUser((prevData) => ({ ...prevData, password: value }));
  };

  const IsEmailExit = async () => {
    try {
      const response = await api.post(
        `check_email`,
        {
          email: user.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { message } = response;
    } catch (error) {
      console.log(error);
    }
  };

  const GetCountries = async () => {
    try {
      const response = await api.get(`get_country`);
      setCountries(response.data);
    } catch (error) {
      console.log("Failed to contries data");
    }
  };

  useEffect(() => {
    GetCountries();
  }, []);

  return (
    <>
      <div className="login-section register-page">
        <LoginPageLeftContainer />
        <div className="login-right-container">
          <div className="register-module-container">
            <div className="login-portal-container">
              <h2 style={{ color: "#826426" }}>PORTAL</h2>
              <p
                style={{
                  color: "#222222",
                  fontSize: "12.5px",
                  fontWeight: "bold",
                }}
              >
                PLEASE REGISTER YOUR ACCOUNT BELOW:
              </p>
            </div>

            <div className="register-container">
              <form className="login-form" onSubmit={handleRegister}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="company">
                        COMPANY NAME
                      </label>
                      <input
                        value={user.company_name}
                        name="company_name"
                        onChange={handleUser}
                        className="input-field login-input-field"
                        id="company"
                        type="text"
                        placeholder="Company Name"
                        required
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="email">
                        EMAIL
                      </label>
                      <input
                        value={user.email}
                        name="email"
                        onChange={handleUser}
                        onBlur={() => IsEmailExit()}
                        className="input-field login-input-field"
                        id="email"
                        type="email"
                        placeholder="example@google.com"
                        required
                      />
                      <span style={{ color: "red", fontSize: "0.8rem" }}>
                        {isemailExit}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="phone">
                        PHONE NUMBER
                      </label>
                      {/* <input
                        value={user.phone_number}
                        name="phone_number"
                        onChange={handleUser}
                        className="input-field login-input-field"
                        id="phone"
                        type="tel"
                        placeholder="Phone Number"
                        required
                      /> */}
                      <PhoneInput
                        country={"in"}
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        style={{ marginTop: "8px" }}
                        enableSearch={true}
                        countryCodeEditable={false}
                        disableSearchIcon={true}
                        inputProps={{
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} style={{ marginTop: "8px" }}>
                    <label className="form-label" htmlFor="country">
                      SELECT COUNTRY
                    </label>

                    <Autocomplete
                      name="country"
                      value={
                        countries
                          ? countries.find((item) => item.id === user.country)
                          : null
                      }
                      onChange={(event, value) =>
                        handleAutoComplete(event, value, "country")
                      }
                      disablePortal
                      id="combo-box-demo"
                      options={countries ? countries : []}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          required
                          className="bg-color"
                          placeholder="Select Country"
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="city">
                        SELECT CITY
                      </label>
                      <input
                        value={user.city}
                        name="city"
                        onChange={handleUser}
                        className="input-field login-input-field"
                        id="city"
                        type="text"
                        placeholder="City"
                        required
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="state">
                        SELECT STATE
                      </label>
                      <input
                        type="text"
                        value={user.state}
                        name="state"
                        onChange={handleUser}
                        className="input-field login-input-field"
                        id="state"
                        placeholder="state"
                        required
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="address">
                        ADDRESS
                      </label>
                      <input
                        value={user.address}
                        name="address"
                        onChange={handleUser}
                        className="input-field login-input-field"
                        id="address"
                        type="textarea"
                        placeholder="Address"
                        required
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="remark">
                        REMARK
                      </label>
                      <input
                        value={user.remark}
                        name="remark"
                        onChange={handleUser}
                        className="input-field login-input-field"
                        id="remark"
                        type="textarea"
                        placeholder="Remark"
                        required
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="password">
                        PASSWORD
                      </label>
                      <div className="password-field">
                        <input
                          value={user.password}
                          name="password"
                          onChange={handlePasswordValidation}
                          className="input-field login-input-field"
                          id="password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          required
                        />

                        {showPassword ? (
                          <VisibilityOff
                            className="password-icon"
                            onClick={toggleShowPassword}
                          />
                        ) : (
                          <Visibility
                            className="password-icon"
                            onClick={toggleShowPassword}
                          />
                        )}
                      </div>
                      {!isPasswordValid && (
                        <span style={{ color: "red", fontSize: "0.8rem" }}>
                          Password must contain at least 8 characters, including
                          uppercase, lowercase, and special characters.
                        </span>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="confirm-password">
                        CONFIRM PASSWORD
                      </label>
                      <div className="password-field">
                        <input
                          value={confirmPswd}
                          name="password"
                          onChange={(e) => setConfirmPswd(e.target.value)}
                          className="input-field login-input-field"
                          id="confirm-password"
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Password"
                          required
                        />
                        {showConfirmPassword ? (
                          <VisibilityOff
                            className="password-icon"
                            onClick={toggleShowConfirmPassword}
                          />
                        ) : (
                          <Visibility
                            className="password-icon"
                            onClick={toggleShowConfirmPassword}
                          />
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>

                {isLoading ? <CommonLoading /> : null}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <button
                    className="login-button"
                    style={{ width: "100%", marginTop: "8px" }}
                  >
                    Register
                  </button>
                </div>
              </form>
              <div
                style={{
                  marginTop: "0.5rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link
                  to="/login"
                  style={{
                    color: "#836628",
                    fontSize: "0.95rem",
                    fontWeight: "bold",
                  }}
                >
                  Back to login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}

      <div className="register-right-container-mobile">
        <div>
          <div>
            <h2 style={{ color: "#826426" }}>PORTAL</h2>
            <p
              style={{
                color: "#222222",
                fontSize: "12.5px",
                fontWeight: "bold",
              }}
            >
              PLEASE REGISTER YOUR ACCOUNT BELOW:
            </p>
          </div>

          <div>
            <form className="login-form" onSubmit={handleRegister}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="company">
                      COMPANY NAME
                    </label>
                    <input
                      value={user.company_name}
                      name="company_name"
                      onChange={handleUser}
                      className="input-field login-input-field"
                      id="company"
                      type="text"
                      placeholder="Company Name"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      EMAIL
                    </label>
                    <input
                      value={user.email}
                      name="email"
                      onChange={handleUser}
                      onBlur={() => IsEmailExit()}
                      className="input-field login-input-field"
                      id="email"
                      type="email"
                      placeholder="example@google.com"
                      required
                    />
                    <span style={{ color: "red", fontSize: "0.8rem" }}>
                      {isemailExit}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="phone">
                      PHONE
                    </label>
                    <input
                      value={user.phone_number}
                      name="phone_number"
                      onChange={handleUser}
                      className="input-field login-input-field"
                      id="phone"
                      type="tel"
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ marginTop: "8px" }}>
                  <label className="form-label" htmlFor="country">
                    SELECT COUNTRY
                  </label>

                  <Autocomplete
                    name="country"
                    value={
                      countries
                        ? countries.find((item) => item.id === user.country)
                        : null
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "country")
                    }
                    disablePortal
                    id="combo-box-demo"
                    options={countries ? countries : []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Country"
                        {...params}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="city">
                      SELECT CITY
                    </label>
                    <input
                      value={user.city}
                      name="city"
                      onChange={handleUser}
                      className="input-field login-input-field"
                      id="city"
                      type="text"
                      placeholder="City"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="state">
                      SELECT STATE
                    </label>
                    <input
                      type="text"
                      value={user.state}
                      name="state"
                      onChange={handleUser}
                      className="input-field login-input-field"
                      id="state"
                      placeholder="state"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="address">
                      ADDRESS
                    </label>
                    <input
                      value={user.address}
                      name="address"
                      onChange={handleUser}
                      className="input-field login-input-field"
                      id="address"
                      type="textarea"
                      placeholder="Address"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="remark">
                      REMARK
                    </label>
                    <input
                      value={user.remark}
                      name="remark"
                      onChange={handleUser}
                      className="input-field login-input-field"
                      id="remark"
                      type="textarea"
                      placeholder="Remark"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="password">
                      PASSWORD
                    </label>
                    <div className="password-field">
                      <input
                        value={user.password}
                        name="password"
                        onChange={handlePasswordValidation}
                        className="input-field login-input-field"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        required
                      />

                      {showPassword ? (
                        <VisibilityOff
                          className="password-icon"
                          onClick={toggleShowPassword}
                        />
                      ) : (
                        <Visibility
                          className="password-icon"
                          onClick={toggleShowPassword}
                        />
                      )}
                    </div>
                    {!isPasswordValid && (
                      <span style={{ color: "red", fontSize: "0.8rem" }}>
                        Password must contain at least 8 characters, including
                        uppercase, lowercase, and special characters.
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="confirm-password">
                      CONFIRM PASSWORD
                    </label>
                    <div className="password-field">
                      <input
                        value={confirmPswd}
                        name="password"
                        onChange={(e) => setConfirmPswd(e.target.value)}
                        className="input-field login-input-field"
                        id="confirm-password"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Password"
                        required
                      />
                      {showConfirmPassword ? (
                        <VisibilityOff
                          className="password-icon"
                          onClick={toggleShowConfirmPassword}
                        />
                      ) : (
                        <Visibility
                          className="password-icon"
                          onClick={toggleShowConfirmPassword}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>

              {isLoading ? <CommonLoading /> : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <button
                  className="login-button"
                  style={{ width: "100%", marginTop: "8px" }}
                >
                  Register
                </button>
              </div>
            </form>
            <div
              style={{
                marginTop: "0.5rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to="/login"
                style={{
                  color: "#836628",
                  fontSize: "0.95rem",
                  fontWeight: "bold",
                }}
              >
                Back to login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
