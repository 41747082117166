import { createSlice } from "@reduxjs/toolkit";
import { pageNavigator } from "../../Assets/DefaultImagesCarousel";

const SharedReducer = createSlice({


  name: "home",
  initialState: {
    pageNavigatorData: pageNavigator,
    status: null
  },
  reducers: {},
  extraReducers: {

  }
})

export default SharedReducer.reducer;
