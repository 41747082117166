import tachometer from "../../Assets/Images/tachometer.png";
import ridingClub from "../../Assets/Images/riding_club.png";
import sloticon from "../../Assets/Images/slot.png";
import calendar from "../../Assets/RidingSchoolAssets/calendar.svg";
import trainer from "../../Assets/RidingSchoolAssets/trainer.svg";
import courses from "../../Assets/RidingSchoolAssets/courses.svg";
import student from "../../Assets/RidingSchoolAssets/student.svg";
import slot_allotment from "../../Assets/RidingSchoolAssets/slot allotment.svg";
import slot from "../../Assets/RidingSchoolAssets/slot.svg";
import riding_type from "../../Assets/RidingSchoolAssets/riding_type.svg";
import tcourse from "../../Assets/RidingSchoolAssets/tcourse.svg";
import rcard from "../../Assets/RidingSchoolAssets/card.svg";

import { IoTimeOutline } from "react-icons/io5";

const MenuItems = [
  {
    title: "STABLE",
    img: tachometer,
    url: "/dashboard",
  },
  {
    title: "DASHBOARD",
    img: ridingClub,
    url: "/riding-club",
    styles: { filter: "brightness(20.5)" },
  },

  {
    header: "REGISTRATION",
    mainmenu: [
      {
        title: "PENDING BOOKINGS",
        img: rcard,
        url: "/riding-club/pending/booking",   
        submenu: [
          {
            title: "PENDING BOOKINGS",
            url: "/riding-club/pending/booking",
          },
        ],
      },
      {
        title: "COURSES",
        img: tcourse,
        url: "/riding-club/course",
        submenu: [
          {
            title: "COURSE LIST",
            url: "/riding-club/course/course_list",
          },
          {
            title: "CREATE COURSE",
            url: "/riding-club/course/create_course",
          },
        ].filter(Boolean),
      },
    ],
  },
  {
    title: "STUDENT",
    img: student,
    url: "/riding-club/course",
    submenu: [
      {
        title: "LIST",
        url: "/riding-club/course/student-registration",
      },
      // {
      //   title: "REGISTRATION",
      //   url: "/riding-club/course/student-create-registration",
      // },
    ].filter(Boolean),
  },
  {
    title: "SLOT",
    img: slot,
    url: "/riding-club/horses",
    submenu: [
      {
        title: "SLOT lIST",
        url: "/riding-club/slot/slot-list",
      },
      // {
      //   title: "SLOT REGISTRATION",
      //   url: "/riding-club/register-slot",
      // },
    ].filter(Boolean),
  },
  {
    title: "SLOT ALLOTMENT",
    img: slot_allotment,
    url: "/riding-club/horses",
    submenu: [
      {
        title: "List",
        url: "/riding-club/slot-mapping-list",
      },
      {
        title: "SLOT Allotment",
        url: "/riding-club/slot-mapping-create",
      },
    ].filter(Boolean),
  },
  {
    title: "RIDING DISCIPLINE",
    img: riding_type,
    url: "/riding-club/riding-type",
    submenu: [
      {
        title: "List",
        url: "/riding-club/riding-type-list",
      },
    ].filter(Boolean),
  },
  {
    title: "TRAINER",
    img: trainer,
    url: "/riding-club/riding-type",
    submenu: [
      {
        title: "List",
        url: "/riding-club/traineragreement/trainers_list",
      },

      {
        title: "REGISTER NEW TRAINER",
        url: "/riding-club/traineragreement/registration_form",
      },
    ].filter(Boolean),
  },
  {
    title: "CALENDAR",
    img: calendar,
    url: "/riding-club/events",
  },
];

export default MenuItems;
