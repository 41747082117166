import React, { useEffect, useState } from 'react';
import './dayview.css'
import { FaGreaterThan, FaLessThan } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux';
import { EventFormStatus } from '../../../App/Reducers/ModalReducer';
import { GetUpdatingEvent, UpdateEvent } from '../../../App/Reducers/EventReducer';
import { IoIosAddCircle } from 'react-icons/io';
import { useCookies } from 'react-cookie';


const DayView = () => {
    const [currentDate, setcurrentDate] = useState(new Date());
    const events = useSelector((state) => state.events.EventsList)
    const create = useSelector((state) => state.modal.create);
    const update = useSelector((state) => state.modal.update);
    const dispatch = useDispatch()
    const [cookies, setCookies] = useCookies(["token"])
    const token = cookies.token;
    console.log(currentDate, " currentDate")
    const nextBTN = () => {
        console.log("ghgjhgh");
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + 1);
        setcurrentDate(newDate);
        console.log(newDate)
    };

    const previousBTN = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - 1);
        setcurrentDate(newDate);
    };
    const getCurrentDate = () => {
        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${weekdays[currentDate.getUTCDay()]}, 
        ${currentDate.getUTCDate().toString().padStart(2, '0')} 
        ${months[currentDate.getUTCMonth()]} 
        ${currentDate.getUTCFullYear()}`;
    };



    const currDate = getCurrentDate()
    console.log(currDate);

    const updateEvent = (item) => {
        dispatch(EventFormStatus({ "open": true, "update": !update }))
        dispatch(GetUpdatingEvent({ id: item.id, token: token }))
    }

    const eventDatas = (event, wd, hh) => {
        console.log("events", event);
        console.log('wd =>', wd);
        console.log('hh =>', hh);
        const eventDate = new Date(event.start_date);
        console.log('eventDate =>', eventDate);

        const startdate = eventDate.toLocaleDateString("en-US", {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',

        });
        console.log("startdate>>>", startdate);
        // // const eventTime = eventDate.toLocaleTimeString("en-US", { hour: "2-digit", hour12: false });
        // const Time = event.start_date.split("T")[1]
        // const eventTime = Time.split(":")[0]

        // console.log("Event start date", startdate);
        // console.log("eventTime", eventTime); // Output: 2:00 PM
        // console.log("wd.date", wd.date);
        // console.log("HH>>>", hh);
        // const match = startdate === wd.date && eventTime === hh ? true : false
        // console.log("match", match);
        // return match
    }
    // const eventDatas = (d) => {
    //     // const dateAndMonth = currentDate.getDate() + " " + currentMonth
    //     const dateAndMonth = date.getDate() + " " + currentMonth

    //     console.log("dateAndMonth", dateAndMonth);
    //     console.log("d", d);
    //     const match = dateAndMonth === d ? true : false
    //     return match
    // }

    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day = weekdays[currentDate.getDay()];
    // const formattedDate = date.toLocaleDateString();

    useEffect(() => {

    }, [currentDate])
    return (
        <div className="event-day-view">
            <div className="date-header">
                <button onClick={() => previousBTN()}><FaLessThan /></button>
                <h2>{`${currDate}`}</h2>
                <button onClick={() => nextBTN()}><FaGreaterThan /></button>
            </div>
            <div className="event-list">
                {Array.from({ length: 24 }, (_, index) => {
                    const hour = index.toString().padStart(2, '0');
                    // const sTime = `${hour}:00 AM`;
                    // const eTime = `${hour}:59 PM`;
                    const sTime = `${hour}`;
                    const eTime = `${hour}`;
                    console.log("events", events);

                    const hourEvents = events?.filter((event) => {
                        const startDate = new Date(event.start_date);
                        const formattedCurrentDate = currentDate.toISOString().substring(0, 10);
                        const formattedStartDate = startDate.toISOString().substring(0, 10);
                        // console.log("formattedDate", formattedCurrentDate);
                        // console.log("formattedStartDate", formattedStartDate);
                        const Time = event.start_date.split("T")[1]
                        const eventStartTime = Time.split(":")[0]
                        const endDate = new Date(event.end_date);
                        return formattedCurrentDate === formattedStartDate && eventStartTime >= sTime && eventStartTime <= eTime
                    });
                    console.log("hourEvents", hourEvents);
                    // console.log("hour", hour);
                    // console.log("hourEvents", hourEvents);
                    return (
                        <div key={index} className="hour-events">
                            <div className="time-column">{hour}:00</div>
                            <div className="event-column"
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '2.5rem', paddingInline: '1rem' }}
                            >
                                {hourEvents?.length ? (
                                    <>
                                        <div style={{ display: 'flex', gap: '1rem' }}>
                                            {hourEvents?.map((event, i) => (
                                                <div
                                                    key={event.id}
                                                    className="event"
                                                    onClick={(e) => { updateEvent(event) }}
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    {i + 1}. {event.name}
                                                    {/* <div className="event-time">{event.start_date}</div> */}
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <div className="event-title" onClick={(e) => { dispatch(EventFormStatus({ "open": true, "create": !create })) }}><IoIosAddCircle style={{ fontSize: "20px", cursor: "pointer" }} /></div>
                                        </div>
                                    </>
                                ) : (
                                    <div style={{ width: '100%', height: '100%' }} onClick={(e) => { dispatch(EventFormStatus({ "open": true, "create": !create })) }}>
                                        {/* <div className="no-events">&nbsp;</div> */}
                                        {/* <div className="no-events">&nbsp;</div> */}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DayView;
