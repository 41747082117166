import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Apis";
export const getOwnerData = createAsyncThunk("getOwnerData", async (token) => {
  try {
    const response = await api.get("get_all_owner",  {
      headers:{
        Authorization: `token ${token}`,
      }
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
})


export const createOwnerData =   async ({fData, token}) => {
    try {
      const response = await api.post("create_owner", fData, {
        headers: {
          Authorization: `token ${token}`,
          'content-type': 'multipart/form-data'
        },
      });
      return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        return error.response
    }
  }


export const deleteOwnerData = createAsyncThunk("deleteOwnerData", async (payload) => {
  try {
    const response = await api.delete(`delete_owner/${payload.id}`, {
      headers:{
        Authorization : `token ${payload.token}`
      }
    })
    return response.data
  } catch (error) {
    console.log(error.message)
  }
})
export const updateOwnerData =  async (payload) => {
  try {
    const response = await api.put(`update_owner/${payload.id}`,  payload.fData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization : `token ${payload.token}`
      }
    })
   
    return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
      
        return error.response
    }
}

export const getUpdateOwnerData = createAsyncThunk("getUpdateOwnerData", async (payload) => {
  try {
    const response = await api.get(`get_owner/${payload.id}`, {
      headers:{
        Authorization:` token ${payload.token}`
      }
    })
    
    return response.data
  } catch (error) {
    console.log(error)
  }
})

const ownerSlice = createSlice({
  name: "owner",
  initialState: {
    status: null,
    ownerData: null,
    updateOwnerData: null
  },
  reducers: {
    
  },

  extraReducers: (builder) => {
    builder.addCase(getOwnerData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getOwnerData.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.ownerData = action.payload
    })

    builder.addCase(getOwnerData.rejected, (state) => {
      state.status = "failed"
    })
    // builder.addCase(createOwnerData.pending, (state) => {
    //   state.status = "loading"
    // })
    // builder.addCase(createOwnerData.fulfilled, (state) => {
    //   state.status = "succeeded"
    // })

    // builder.addCase(createOwnerData.rejected, (state) => {
    //   state.status = "failed"
    // })

    builder.addCase(deleteOwnerData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(deleteOwnerData.fulfilled, (state, action) => {
      state.status = "succeded"

    })

    builder.addCase(deleteOwnerData.rejected, (state, action) => {
      state.status = "failed"
    })
    // builder.addCase(updateOwnerData.pending, (state) => {
    //   state.state = "loading"
    // })
    // builder.addCase(updateOwnerData.fulfilled, (state) => {
    //   state.status = "succeded"
    // })
    // builder.addCase(updateOwnerData.rejected, (state) => {
    //   state.status = "failed"
    // })
    builder.addCase(getUpdateOwnerData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getUpdateOwnerData.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.updateOwnerData = action.payload
    })
    builder.addCase(getUpdateOwnerData.rejected, (state) => {
      state.status = "failed"
    })

  }
})

export default ownerSlice.reducer;