import React from 'react'
import './DashOverview.css'
// import {BsCalendar2Week} from 'react-icons/bs'
import Calendar from "../../../Assets/Images/calendar.png";
import { Link } from 'react-router-dom';
const EventCalendarOverview = ({ DashboardName }) => {
  return (
    <Link to={DashboardName && DashboardName === 'riding-club' ? '/riding-club/events' : '/dashboard/events_calendar'}>
      <div className='event-calendar-container' >
        <div>
          <h3>Events Calendar</h3>
          {/* <span>Endurance-2022-2023</span> */}
        </div>
        <div>
          {/* <a><BsCalendar2Week/></a> */}
          <img src={Calendar} alt='calender' />
        </div>
      </div>

    </Link>
  )
}

export default EventCalendarOverview