import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./weekview.css";
import { EventFormStatus } from "../../App/Reducers/ModalReducer";
import { GetUpdatingEvent, UpdateEvent } from "../../App/Reducers/EventReducer";
import { parseJSON } from "date-fns";
import { IoIosAddCircle } from "react-icons/io";
import { useCookies } from "react-cookie";

// import DayView from './DayView';

const DayView = ({ dates, previous, next, events }) => {
  const create = useSelector((state) => state.modal.create);
  const update = useSelector((state) => state.modal.update);
  const dispatch = useDispatch();
  const [cookie, setCookies] = useCookies();
  const token = cookie.token;
  // const filteredEvents = events.filter((event) => event.date === date);
  // const eventDatas = (d, wd) => {

  //     // const dateAndMonth = currentDate.getDate() + " " + currentMonth
  //     const dateAndMonth = wd.date
  //     console.log("dateAndMonth", dateAndMonth);
  //     console.log("d", d);
  //     const match = dateAndMonth === d ? true : false
  //     return match
  // }
  const updateEvent = (id) => {
    dispatch(EventFormStatus({ open: true, update: !update }));
    dispatch(GetUpdatingEvent({ id, token }));
  };
  const eventDatas = (event, wd, hh) => {
    const eventDate = new Date(event.start_date);
    const startdate = eventDate.toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const Time = event.start_date.split("T")[1];
    const eventTime = Time.split(":")[0];
    const match = startdate === wd.date && eventTime === hh ? true : false;
    // return match;
    if (match) {
      return (
        <tr className="event-column event" key={event.id}>
          <div
            onClick={(e) => {
              updateEvent(event.id);
            }}
          >
            <div className="event-title">
              {event.name}
              {/* <div className="event-details">
                <div className="event-time">
                  {event.start_date}
                </div>
                <div className="event-time">
                  {event.end_date}
                </div>
              </div> */}
            </div>
          </div>

          {/* <div
            className="event-title"
            onClick={(e) => {
              dispatch(
                EventFormStatus({
                  open: true,
                  create: !create,
                })
              );
            }}
          >
            <IoIosAddCircle
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </div> */}



        </tr>)
    }

  };

  return (
    <div className="day-view my_class">
      <table>
        <thead>
          <tr className="date-header">
            <th>
              {" "}
              <button onClick={previous}>&lt;</button>
            </th>
            {dates.map((weekDate) => (
              <th key={weekDate.key}>{weekDate.date}</th>
            ))}
            <th>
              {" "}
              <button onClick={next}>&gt;</button>{" "}
            </th>
          </tr>
          {/* <h5 key={weekDate.key}>{weekDate.date} </h5> */}
        </thead>
        <div className="event-list">
          {Array.from({ length: 24 }, (_, index) => {
            const hour = index.toString().padStart(2, "0");
            const sTime = `${hour}:00 AM`;
            const eTime = `${hour}:59 PM`;
            console.log("events", events);

            // const hourEvents =
            //   events &&
            //   events.filter((event) => {
            //     const startDate = new Date(event.start_date);
            //     const endDate = new Date(event.end_date);
            //     const startTime = startDate.toLocaleTimeString([], {
            //       hour: "2-digit",
            //       minute: "2-digit",
            //     });
            //     const endTime = startDate.toLocaleTimeString([], {
            //       hour: "2-digit",
            //       minute: "2-digit",
            //     });
            //     return startTime >= sTime && endTime <= eTime;
            //   });
            // console.log("hourEvents", hourEvents.length);

            return (
              <tr key={index} className="hour-events">
                <td className="time-column" style={{ width: "fit-content" }}>
                  {hour}:00
                </td>
                {dates.map((weekDate, index) => (
                  <td className="week-date" key={index}>
                    {events?.map((event, i) => (eventDatas(event, weekDate, hour)))}
                  </td>
                ))}
              </tr>
            );
          })}
        </div>
      </table>
    </div>
  );
};

const WeekView = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const Events = useSelector((state) => state.events.EventsList);
  const getCurrentDate = () => {
    // const date = new Date();
    // const options = { weekday: "long", day: "numeric" };
    const options = { weekday: "short", day: "numeric" };
    return currentDate.toLocaleString("en-US", options);
  };

  const getCurrentMonth = () => {
    // const date = new Date();
    const options = { month: "long", year: "numeric" };
    return currentDate.toLocaleString("en-US", options);
  };

  const currDate = getCurrentDate();
  const currentMonth = getCurrentMonth();

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const generateWeekDates = (startDate) => {
    const weekDates = [];
    const currDate = new Date(startDate);
    const wd = new Date(startDate);

    for (let i = 0; i < 7; i++) {
      const formattedDate = currDate.toLocaleDateString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      const day = weekdays[currDate.getDay()];
      weekDates.push({ day, date: formattedDate });
      currDate.setDate(currDate.getDate() + 1);
    }

    return weekDates;
  };

  const weekDates = generateWeekDates(currentDate);

  const nextBTN = () => {
    console.log("ghgjhgh");
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);
    console.log(newDate);
  };

  const previousBTN = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);
  };

  return (
    <div className="week-view">
      {/* <div className="date-header">
                <button onClick={previous}>&lt;</button>
                <h2>{`${currDate}, ${currentMonth}`}</h2>
                <button onClick={next}>&gt;</button>
            </div> */}

      <div className="week-events">
        <DayView
          dates={weekDates}
          events={Events}
          currentMonth={currentMonth}
          currentDate={currentDate}
          next={nextBTN}
          previous={previousBTN}
        />
      </div>
    </div>
  );
};

export default WeekView;
