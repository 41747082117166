import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  getVisaMasterData,
  createVisaMasterData,
  updateVisaMasterData,
  deleteVisaMasterData,
  getupdateVisaMasterData,
} from "../../App/Reducers/AdminSettingReducer/VisaMasterReducer";
import {
  getEventTypeData,
  createEventTypeData,
  updateEventTypeData,
} from "../../App/Reducers/AdminSettingReducer/EventTypeReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import ModalComp from "./Modal";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import api from "../../Apis";
import { toast } from "react-toastify";
import DeleteModal from "../../App/Helpers/DeleteModal";
import AlertMessage from "../../App/Helpers/AlertMessage";

const EventType = () => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const [userType, setUserType] = useState(null);
  const [modalData, setModalData] = useState({ name: "" });

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addUserTypeMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_usertypemaster"
      )
    : false;
  const changeUserTypeMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_usertypemaster"
      )
    : false;
  const deleteUserTypeMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_usertypemaster"
      )
    : false;

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createOrUpdateHandler = async () => {
    if (modalData.id) {
      try {
        const res = await api.put(
          `update_usertype/${modalData.id}`,
          modalData,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        const res = await api.post("create_usertype", modalData, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    }
    closeModal();
  };

  const editHandler = (id) => {
    const editData = userType.find((data) => data.id === id);
    if (editData) {
      setModalData(editData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_usertype/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Deleted successfully");
          dispatch(eventLsitapi(token));
        }
        return response;
      } catch (error) {
        // if (error.response.data.Error) {
        //   setIsErrorVisible(true);
        //   setIsErrorResponse(error.response.data.Error);
        // }
        setIsErrorVisible(true);

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
    eventLsitapi();
  };

  const openModal = () => {
    setModalData({
      name: "",
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const eventLsitapi = async () => {
    try {
      const res = await api.get("get_all_usertype", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      setUserType(res.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    eventLsitapi();
  }, []);

  const header = [
    {
      Header: "User Type",
      accessor: "name",
    },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {userType ? (
        <BasicTable
          colHeader={header}
          rowData={userType}
          actionFlag={
            deleteUserTypeMasterPermission ||
            changeUserTypeMasterPermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeUserTypeMasterPermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteUserTypeMasterPermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addUserTypeMasterPermission || isUserType ? openModal : null
          }
          tableHeading="User Type"
        />
      ) : (
        <StableLoading />
      )}

      <ModalComp
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        label="ADD USER TYPE"
        heading="USER TYPE"
      />
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="User Type"
        deleteData={deleteData}
      />
    </>
  );
};
export default EventType;
