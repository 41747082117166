import React, { useEffect, useState } from 'react'
import './timePicker.css'

const Styles = {
    selectStyle: {
        appearance: 'none',
        fontSize: '1.125rem',
        border: 'none',
        width: '30%',
        textAlign: 'center',
        height: '100%',
        borderRadius: '10px',
        background: '#e0e0e0'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        // background: '#fff',
        border: '1px solid #00000040',
        height: '45px',
        borderRadius: '10px',
        alignItems: 'center'
    }
}




function TimePicker({ name, timeValue, handleTimeOnChange }) {
    const [time, setTime] = useState({
        hh: '01',
        mm: '00',
        aa: 'AM'
    })

    const hoursArray = Array.from({ length: 12 }, (_, i) => i < 9 ? '0' + (i + 1) : '' + (i + 1));
    const minutesArray = Array.from({ length: 60 }, (_, i) => i < 10 ? '0' + i : '' + i);
    const handleTimePickerChange = (e) => {
        console.log(e.target);
        const { name, value } = e.target
        const timeDuplicate = { ...time }
        timeDuplicate[name] = value
        setTime(timeDuplicate)
        handleTimeOnChange(`${timeDuplicate.hh}:${timeDuplicate.mm} ${timeDuplicate.aa}`)

    }

    useEffect(() => {
        const regex = /^(\d{1,2}):(\d{2}) (AM|PM)$/i;
        const match = timeValue !== null ? timeValue.match(regex):null;

        if (match) {
            const hh = match[1];
            const mm = match[2];
            const aa = match[3].toUpperCase();
            const timeObject = { hh, mm, aa };
            console.log(timeObject);
            setTime(timeObject)
        } else {
            console.log("Invalid time format");
        }
    }, [])

    return (
        <div style={Styles.container}>
            {/* <div>{`${time.hh}:${time.mm} ${time.aa}`}</div> */}
            <select className='timepicker' style={Styles.selectStyle} name='hh' id='hh' onChange={handleTimePickerChange}>
                {hoursArray.map((hh, i) => (<option key={`${i}-hh`} value={hh} selected={time.hh === hh}> {hh}</option>))}

            </select>
            <p style={{ fontSize: '1.25rem', width: '10%', height: '100%', marginTop: '10px', textAlign: 'center', }}>:</p>
            <select className='timepicker' style={Styles.selectStyle} name='mm' id='mm' onChange={handleTimePickerChange}>
                {minutesArray.map((mm, i) => (<option key={`${i}-mm`} value={mm} selected={time.mm === mm} >{mm}</option>))}
            </select>
            <select className='timepicker' style={Styles.selectStyle} name='aa' id='aa' onChange={handleTimePickerChange}>
                <option value="AM" selected={time.aa === 'AM'}>AM</option>
                <option value="PM" selected={time.aa === 'PM'}>PM</option>
            </select >
        </div>
    )
}

export default TimePicker