import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteHealthPlaner,
  GetHealthPlanerList,
  GetUpdatingHealthPlaner,
} from "../../App/Reducers/HealthPlanerReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { format } from "date-fns";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import { useCookies } from 'react-cookie';
const HealthPlanerList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const State = useSelector((state) => state.HealthPlaner);
  const [status, setStatus] = useState(State.status.Updating);
  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;
  console.log(status);
  const header = [
    { Header: "ID", accessor: "id" },
    { Header: "Horse Name", accessor: "horse_id.name" },
    { Header: "Health Type", accessor: "health_type.name" },
    { Header: "Health Status", accessor: "health_status" },
    { Header: "Discription", accessor: "discription" },
    { Header: "Price", accessor: "price" },
    {
      Header: "Staff",
      accessor: "staff",
      Cell: ({ row }) => {
        const { first_name, last_name } = row.original.staff;
        return `${first_name} ${last_name}`;
      },
    },
    { Header: "Other Staff", accessor: "other_staff" },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },
    },
    {
      Header: "End Date",
      accessor: "end_date",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },
    },
    // { Header: "Is Done", accessor: "is_done" },
  ];

  const CreateHandler = () =>{
    navigate("/dashboard/planer/health");
  }
  const UpdateHandler = (id) => {
    dispatch(GetUpdatingHealthPlaner({id, token}));
  };
  const DeleteHandler = (id) => {
    dispatch(DeleteHealthPlaner({id, token}));
  };
  useEffect(() => {
    setStatus(State.status.Updating);
    if (status === "succeeded") {
      navigate("/dashboard/planer/health");
    }
    dispatch(GetHealthPlanerList(token));
  }, [dispatch, State.status.Updating, status, navigate]);
  return (
    <div className="all_table_list">
      {State.List && header ? (
        <BasicTable
          colHeader={header}
          rowData={State.List}
          deleteHandler={DeleteHandler}
          updateHandler={UpdateHandler}
          createHandler={CreateHandler}
          tableHeading={"Health Planner List"}
        />
      ) : <StableLoading/>}
    </div>
  )
};

export default HealthPlanerList;
