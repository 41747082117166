import React, { useEffect, useState } from "react";
import "./EventForm.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { BiUpload } from "react-icons/bi";
import { TbReplaceFilled } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EventFormStatus } from "../../App/Reducers/ModalReducer";
import { DeleteEvent, GetEvents, GetUpdatingEvent } from '../../App/Reducers/EventReducer';
import { CreateEvent, UpdateEvent } from "../../App/Reducers/EventReducer";
import { getHorseData } from "../../App/Reducers/HorseRegistrationReducer";
import { GetTrainers } from "../../App/Reducers/TrainerReducer";
import { getStableClassData } from "../../App/Reducers/AdminSettingReducer/StableClassReducer";
import api from "../../Apis";
import moment from "moment";


import {
  Fab,
  Checkbox,
  TextField,
  Button,
  Grid,
  ImageList,
  ImageListItem,
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { GetUsers } from "../../App/Reducers/UserReducer";
import { useCookies } from "react-cookie";
import { loopHooks } from "react-table";
import { Today } from "@mui/icons-material";


const EventForm = () => {
  const isShow = useSelector((state) => state.modal.EventForm);
  const date = useSelector((state) => state.modal.date);
  const update = useSelector((state) => state.modal.update);
  const calDate = useSelector((state) => state.modal.calDate);
  const currentMonth = useSelector((state) => state.modal.currentMonth)
  const create = useSelector((state) => state.modal.create);
  const HorseData = useSelector((state) => state.horseRegistration.data);
  const UpdatingEventData = useSelector((state) => state.events.UpdatingEvent);
  const UpdatingEventDataStatus = useSelector((state) => state.events.Updatestatus);
  const creatingEventDataStatus = useSelector((state) => state.events.Createstatus);


  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, '0');

  const formatMonth = (calDate, currentMonth) => {

    calDate = calDate.length === 1 ? `0${calDate}` : calDate
    const d = currentMonth?.split('/')
    return `${d[1]}-${d[0]}-${calDate}T${hours}:${minutes}`
  }


  const endFormatMonth = (calDate, currentMonth) => {
    let now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, '0');

    calDate = calDate.length === 1 ? `0${calDate}` : calDate;
    const d = currentMonth?.split('/');

    return `${d[1]}-${d[0]}-${calDate}T${hours}:${minutes}`;
  }

  const formattedMonth = currentMonth && formatMonth(calDate, currentMonth);
  const endFormattedMonth = currentMonth && endFormatMonth(calDate, currentMonth);
  const TrainerData = useSelector((state) => state.trainers.Trainers);
  const ClassData = useSelector((state) => state.stableClass.StableClassData);
  const users = useSelector((state) => state.getUsers.users);
  const staffData = users && users.filter((user) => user.usertype?.name  === "Rider").map((user, index) => { return { id: user.id, first_name: user.first_name, last_name: user.last_name } })
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(null)
  const [divisionMaster, setDivisionMaster] = useState(null)
  const [eventType, setEventType] = useState(null)


  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;

  const [disableSubmit, setDisableSubmit] = useState(false);


  const [eventData, setEventData] = useState({
    name: null,
    others: null,
    start_date: null,
    end_date: null,
    rider: null,
    classes: null,
    trainer: null,
    horse_name: null,
    category: null,
    event_type: null,
  });
  const handleAutoComplete = (event, value, fieldName) => {
    const selectedValue = value ? value.id : null;
    setEventData((preveventData) => ({
      ...preveventData,
      [fieldName]: selectedValue,
    }));
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "3px solid #bebb9e",
    borderRadius: "10px",
    height: "fit-content",
    width: "fit-content",
    overflow: "auto",
    p: 4,
  };

  const textStyle = {
    fontSize: "5px",
    marginTop: " 0.5rem",
    color: "black", borderRadius: "12px"
  }
  const handleCloseModal = () => {
    dispatch(EventFormStatus({ "open": false, "create": false, "update": false, }))
    setErrorMsg(null)
  }


  const EventHandler = (e) => {

    const { name, value } = e.target;

    if (name === "start_date" || name === "end_date") {
      const stDate = name === "start_date" ? value : eventData.start_date;
      const edDate = name === "end_date" ? value : eventData.end_date;

      if (stDate && edDate && new Date(stDate) > new Date(edDate)) {
        setErrorMsg("Start Date should not be greater than end date");
        setDisableSubmit(true);
      } else {
        setErrorMsg(null);
        setDisableSubmit(false);
      }
    }

    setEventData((predata) => {
      return { ...predata, [name]: value };
    });
  };

  const CreateHandler = () => {
    dispatch(CreateEvent({ event: eventData, token: token }));

    dispatch(GetEvents(token))
    dispatch(GetEvents(token))
    dispatch(EventFormStatus(false))


  };

  const UpdateHandler = () => {

    dispatch(UpdateEvent({ eventData, token }));


    dispatch(GetEvents(token))
    dispatch(EventFormStatus(false))


  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (create) {
      CreateHandler()
      if (creatingEventDataStatus === "succeeded") {
        dispatch(GetEvents(token))
      }
    }
    else {
      UpdateHandler()
      if (UpdatingEventDataStatus === "succeeded") {
        dispatch(GetEvents(token))
      }
    }
  }

  const getDivisionMasterData = async () => {
    try {
      const res = await api.get('get_all_division', {
        headers: {
          Authorization: `token ${token}`
        }
      })
      setDivisionMaster(res.data)
    } catch (error) {
      console.log('stableclass error');
    }
    try {
      const res = await api.get('get_event_type_list', {
        headers: {
          Authorization: `token ${token}`
        }
      })
      setEventType(res.data)
    } catch (error) {
      console.log('stableclass error');
    }
  }

  useEffect(() => {
    if (update && update !== undefined && UpdatingEventData) {
      
      const formattedData = {
        id: UpdatingEventData.id,
        name: UpdatingEventData.name,
        others: UpdatingEventData.others,
        start_date: UpdatingEventData.start_date
          ? new Date(UpdatingEventData.start_date).toISOString().slice(0, -1)
          : "",
        end_date: UpdatingEventData.end_date
          ? new Date(UpdatingEventData.end_date).toISOString().slice(0, -1)
          : "",
        rider: String(UpdatingEventData.rider.id),
        classes: String(UpdatingEventData.classes.id),
        trainer: String(UpdatingEventData.trainer.id),
        horse_name: String(UpdatingEventData.horse_name.id),
        category: String(UpdatingEventData.category.id),
        event_type: String(UpdatingEventData.event_type.id),
      };

      setEventData({ ...formattedData });
    } else {


      const startDate = formattedMonth
      const endDate = endFormattedMonth
      // console.log(`startDatedddddddddddddddddddddddddddddd------------------>${startDate}`)

      setEventData({
        name: null,
        others: null,
        start_date: startDate,
        end_date: endDate,
        rider: null,
        classes: null,
        trainer: null,
        horse_name: null,
        category: null,
        event_type: null
      })

    }
    dispatch(getHorseData(token));
    dispatch(GetTrainers(token));
    dispatch(getStableClassData(token));
    dispatch(GetUsers(token));
    getDivisionMasterData()
  }, [UpdatingEventData, dispatch, getHorseData, GetTrainers, GetUsers, update, create]);

  return (
    <>
      <Modal
        open={isShow}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll-bar">

          <Button
            sx={{
              minWidth: '30px',
              color: "#302924",
              border: "0px",
              float: "right",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#e0e0e0", // Remove the border on hover
              },
            }}
          >
            <RxCross2
              style={{ color: "#302924", fontSize: "1.7rem" }}
              onClick={handleCloseModal} />
          </Button>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#302924", marginBottom: "5vh", fontWeight: "600" }}>
            {!update ? <h3>Create Event</h3> : <h3>Update Event</h3>}
            <p style={{ color: 'red' }}>{errorMsg ? errorMsg : null}</p>
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>EVENT TITLE <span style={{ color: "red" }}>*</span></label>
                <TextField
                  type="text"
                  name="name"
                  onChange={EventHandler}
                  value={eventData.name}
                  style={textStyle}
                  placeholder='Enter Title'
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label >START DATE <span style={{ color: "red" }}>*</span></label>
                <TextField
                  type="datetime-local"
                  name="start_date"
                  // id="starting_date"
                  onChange={EventHandler}
                  value={eventData.start_date}
                  inputProps={{
                    // min: new Date().toISOString().slice(0, 16),
                    min: formattedMonth
                  }}
                  fullWidth
                  style={textStyle}
                  // required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>END DATE <span style={{ color: "red" }}>*</span></label>
                <TextField
                  type="datetime-local"
                  name="end_date"
                  // id="ending_date"
                  onChange={EventHandler}
                  value={eventData.end_date}
                  inputProps={{

                    min: formattedMonth
                  }}
                  fullWidth
                  style={textStyle}
                  // required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>

                <label  >HORSE NAME <span style={{ color: "red" }}>*</span></label>
                <Autocomplete
                  // value={10}
                  value={eventData.horse_name && HorseData && HorseData.find((horse) => horse.id === Number(eventData.horse_name))}
                  name="horse_name"
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "horse_name")
                  }
                  options={HorseData}
                  getOptionLabel={(horse) => horse.name}
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params} variant="outlined" style={textStyle} required placeholder='Select Horse' />
                  )}

                />

              </Grid>

              <Grid item xs={12} sm={6} md={4}>

                <label >TRAINER <span style={{ color: "red" }}>*</span></label>
                <Autocomplete
                  value={eventData.trainer && TrainerData && TrainerData.find((trainer) => trainer.id === Number(eventData.trainer))}
                  name="trainer"
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "trainer")
                  }
                  options={TrainerData}
                  getOptionLabel={(trainer) => trainer.name}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" style={textStyle} placeholder='Select Trainer' required />
                  )}
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                />

              </Grid>
              <Grid item xs={12} sm={6} md={4}>

                <label >RIDER <span style={{ color: "red" }}>*</span></label>
                <Autocomplete
                  value={eventData.rider && staffData && staffData.find((trainer) => trainer.id === Number(eventData.rider))}
                  name="rider"
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "rider")
                  }
                  options={staffData}
                  getOptionLabel={(rider) => `${rider.first_name} ${rider.last_name}`}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" style={textStyle} placeholder='Select Rider' required />
                  )}
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                />

              </Grid>
              <Grid item xs={12} sm={6} md={4}>

                <label >CLASSES <span style={{ color: "red" }}>*</span></label>
                <Autocomplete
                  value={eventData.classes && ClassData && ClassData.find((classes) => classes.id === Number(eventData.classes))}
                  name="classes"
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "classes")
                  }
                  options={ClassData}
                  getOptionLabel={(Class) => Class.name}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" style={textStyle} placeholder='Select Class' required />
                  )}
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                />

              </Grid>
              <Grid item xs={12} sm={6} md={4}>

                <label >CATEGORY <span style={{ color: "red" }}>*</span></label>
                <Autocomplete
                  value={eventData.category && divisionMaster && divisionMaster.find((master) => master.id === Number(eventData.category))}
                  name="category"
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "category")
                  }
                  options={divisionMaster}
                  getOptionLabel={(Master) => Master.name}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" style={textStyle} placeholder='Select Category' required />
                  )}
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                />

              </Grid>
              <Grid item xs={12} sm={6} md={4}>

                <label>EVENT TYPE <span style={{ color: "red" }}>*</span></label>
                <Autocomplete
                  value={eventType && eventData.event_type && eventType.find((eventType) => eventType.id === Number(eventData.event_type))}
                  name="event_type"
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "event_type")
                  }
                  options={eventType}
                  getOptionLabel={(eventType) => eventType.name}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" style={textStyle} placeholder='Select Event Type' required />
                  )}
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}

                />

              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label>OTHER</label>
                <TextField
                  type="text"
                  name="others"
                  value={eventData.others}
                  onChange={EventHandler}
                  placeholder='Other'
                  className="bg-color"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },
                float: "right",
                marginTop: "2rem",
                fontSize: "12px",
                marginInline: "0.5rem",
                backgroundColor: "#302924",
              }}
              onClick={handleCloseModal}

            >
              Close
            </Button>
            {
              !update ?
                <Button
                  type="submit"
                  variant="outlined"

                  sx={{
                    color: "#fff",
                    borderColor: "#302924",
                    "&:hover": {
                      borderColor: "#302924",
                      color: "#302924",
                    },
                    "&:disabled": {
                      color: "#fff",
                      cursor: 'not-allowed',
                      pointerEvents: 'all !important'
                    },
                    float: "right",
                    marginTop: "2rem",
                    fontSize: "12px",
                    marginInline: "0.5rem",
                    backgroundColor: "#302924",
                  }}
                  disabled={disableSubmit}
                >
                  CREATE
                </Button>
                :
                <Button
                  variant="outlined"
                  type="submit"
                  sx={{
                    color: "#fff",
                    borderColor: "#302924",
                    "&:hover": {
                      borderColor: "#302924",
                      color: "#302924",
                    },
                    marginTop: "2rem",
                    float: "right",
                    // width: "80px",
                    fontSize: "12px",
                    marginInline: "0.5rem",
                    backgroundColor: "#302924",
                  }}

                  disabled={disableSubmit}
                >
                  UPDATE
                </Button>

            }
          </form>

        </Box>
      </Modal>
    </>
  );
};

export default EventForm;
