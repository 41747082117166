import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const loaderContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    alignContent: "center"
}
function Loader() {
    return (<div style={loaderContainer}>
        <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#9f8653"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    </div>)

}

export default Loader