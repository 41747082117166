import api from "../../Apis";

export const Create_Quotation = async (payload) => {
    try {
        const response = await api.post("create_quotation", payload.formData, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        console.log("response", response.status);
        return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        console.log("error.response.data", error.response.data);
        return error.response
    }
}

export const updateQuotation = async (payload) => {
    try {
        const res = await api.put(`update_quotation/${payload.id}`, payload.formData, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `token ${payload.token}`,
            },
        })
        return res
    } catch (error) {
        console.log("error", error)
        return error.response
    }
}
export const getQuotationDataById = async (payload) => {
    try {
        // console.log("payload", payload);
        const res = await api.get(`get_quotation/${payload.id}`, { headers: { Authorization: `token ${payload.token}` } })
        // console.log("res.data", res)
        return res.data
    } catch (error) {
        // console.log("error", error)
        return error
    }
}

// export const getService = async (id, token) => {
//     console.log("token", token);
//     console.log("id", id);
//     try {
//         const response = await api.get(`facility_service_type/${id}`, {
//             headers: {
//                 Authorization: `token ${token}`,
//                 // "content-type": "application/json",
//             }
//         });

//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return error;
//     }
// }

export const getService = (id, token) => {
    const fetchData = async () => {
        try {
            const response = await api.get(`facility_service_type/${id}`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    // const logValues = () => {
    //     console.log("token", token);
    //     console.log("id", id);
    // };

    return Promise.all([fetchData()]).then(([data]) => data);
};
