import React from "react";
import { Link } from "react-router-dom";
const RegistrationCard = ({ title, count, pic, path, duration}) => {
  return (
    <div
      className="registrationCard"
      data-aos="zoom-in-up"
      data-aos-duration={duration}
    >
      <Link to={path}>
        <div>
          <img src={pic} />
          <span>{title}</span>
        </div>
        <div>
          <span>{count}</span>
        </div>
      </Link>
    </div>
  );
};

export default RegistrationCard;
