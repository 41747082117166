import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Apis";

const token = localStorage.getItem('token');

// ========================AboutUs======================================


export const getAboutUs = createAsyncThunk('getAboutUs', async (token) => {
  try {

    if (token) {
      const res = await api.get('gets_all_about_us', {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      return res.data;
    } else {

      return null;
    }
  } catch (error) {

    throw error;
  }
});


export const createAboutUs = createAsyncThunk('createAboutUs', async (payload) => {
  console.log("payload", payload);
  try {
    if (token) {
      const res = await api.post('add_about_us', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateAboutUs = createAsyncThunk("updateAboutUs", async (payload) => {
  try {
    if (token) {

      const res = await api.put(
        `/update_about_us/${payload.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } else {
      return null;
    }

  } catch (error) {
    return error.response.data;
  }
}
);

// ========================Discipline======================================
export const getDiscipline = createAsyncThunk(
  "getDiscipline",
  async (token) => {
    try {
      const res = await api.get("get_all_deciplines", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      console.log("res.data get", res.data);
      return res.data;
    } catch (error) {
      console.log(`You Got an Error: ${error}`);
    }
  }
);

export const createDiscipline = createAsyncThunk('createDiscipline', async (payload) => {
  console.log("payload", payload);
  try {
    if (token) {
      const res = await api.post('add_deciplines', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})


export const updateDiscipline = createAsyncThunk(
  "updateDiscipline",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `update_deciplines/${payload.id}`,
        payload.data,
        {
          headers: {
            // "Content-Type": "json/application",
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// ========================Flat Race======================================
export const getFlatRace = createAsyncThunk("getFlatRace", async (token) => {
  try {
    const res = await api.get("get_all_flatrace", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
  
    return res.data;
  } catch (error) {
    throw error
  }
});

export const createFlatrace = createAsyncThunk('createFlatrace', async (payload) => {
  console.log("payload", payload);
  try {
    if (token) {
      const res = await api.post('add_flatrace', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})


export const updateFlatRace = createAsyncThunk(
  "updateFlatRace",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `/update_flatrace/${payload.id}`,
        payload.data,
        {
          headers: {
            // "Content-Type": "json/application",
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// =======================Arabian Show=============================
export const getArabian = createAsyncThunk("getArabian", async (token) => {
  try {
    const res = await api.get("get_all_arebian_shows", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(`You Got an Error: ${error}`);
  }
});

export const createArabianShow = createAsyncThunk('createArabianShow', async (payload) => {
  try {
    if (token) {
      const res = await api.post('add_arebian_show', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateArabian = createAsyncThunk(
  "updateArabian",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `/update_arebian_show/${payload.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);


// =======================Show Jumping=============================
export const getShowJumping = createAsyncThunk(
  "getShowJumping",
  async (token) => {
    try {
      const res = await api.get("get_all_jumping_show", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log(`You Got an Error: ${error}`);
    }
  }
);

export const createShowJumping = createAsyncThunk('createShowJumping', async (payload) => {
  try {
    if (token) {
      const res = await api.post('add_jumping_show', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateShowJumping = createAsyncThunk(
  "updateShowJumping",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `/update_jumping_show/${payload.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// =======================Services Show=============================
export const getServices = createAsyncThunk("getServices", async (token) => {
  try {
    const res = await api.get("gets_all_services", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(`You Got an Error: ${error}`);
  }
});

export const createServices = createAsyncThunk('createServices', async (payload) => {
  console.log("payload", payload);
  try {
    if (token) {
      const res = await api.post('adds_services', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateServices = createAsyncThunk(
  "updateServices",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `/updates_services/${payload.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// =======================horse livery Show=============================
export const getHorselivery = createAsyncThunk(
  "getHorselivery",
  async (token) => {
    try {
      const res = await api.get("gets_all_horse_livery", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log(`You Got an Error: ${error}`);
    }
  }
);

export const createHorseLivery = createAsyncThunk('createHorseLivery', async (payload) => {

  try {
    if (token) {
      const res = await api.post('add_horse_livery', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateHorselivery = createAsyncThunk(
  "updateHorselivery",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `/update_horse_livery/${payload.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// =======================Stables rents Show=============================
export const getStablerents = createAsyncThunk(
  "getStablerents",
  async (token) => {
    try {
      const res = await api.get("gets_all_stable_rent", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log(`You Got an Error: ${error}`);
    }
  }
);

export const createStableRent = createAsyncThunk('createStableRent', async (payload) => {

  try {
    if (token) {
      const res = await api.post('add_stable_rent', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateStablerents = createAsyncThunk(
  "updateStablerents",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `/update_stable_rent/${payload.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// =======================Qurantine Show=============================
export const getQurantine = createAsyncThunk("getQurantine", async (token) => {
  try {
    const res = await api.get("get_quarantine", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(`You Got an Error: ${error}`);
  }
});

export const createQuatantine = createAsyncThunk('createQuatantine', async (payload) => {

  try {
    if (token) {
      const res = await api.post('add_quarantine', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateQurantine = createAsyncThunk(
  "updateQurantine",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `/update_quarantine/${payload.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// =======================Transportation Show=============================
export const getTransportation = createAsyncThunk(
  "getTransportation",
  async (token) => {
    try {
      const res = await api.get("get_transportation", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log(`You Got an Error: ${error}`);
    }
  }
);

export const createTransportation = createAsyncThunk('createTransportation', async (payload) => {

  try {
    if (token) {
      const res = await api.post('add_transportation', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateTransportation = createAsyncThunk(
  "updateTransportation",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `/update_transportation/${payload.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// =======================Ridingschool Show=============================
export const getRidingschool = createAsyncThunk(
  "getRidingschool",
  async (token) => {
    try {
      const res = await api.get("get_all_riding_school", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log(`You Got an Error: ${error}`);
    }
  }
);

export const createRidingSchool = createAsyncThunk('createRidingSchool', async (payload) => {

  try {
    if (token) {
      const res = await api.post('add_riding_school', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateRidingschool = createAsyncThunk(
  "updateRidingschool",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(
        `/update_riding_school/${payload.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// =======================Contact=============================
export const getContact = createAsyncThunk("getContact", async (token) => {
  try {
    const res = await api.get("get_all_contact", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(`You Got an Error: ${error}`);
  }
});


export const createContact = createAsyncThunk('createContact', async (payload) => {

  try {
    if (token) {
      const res = await api.post('add_contact', payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });
      return res.data;
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
})

export const updateContact = createAsyncThunk(
  "updateContact",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(`update_contact/${payload.id}`, payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`,
        },
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// =======================Photo Gallary=============================
export const getPhotos = createAsyncThunk("getPhotos", async (token) => {
  try {
    const res = await api.get("gets_all_images", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(`You Got an Error: ${error}`);
  }
});

export const createPhotos = createAsyncThunk("createPhotos", async (payload) => {
  try {
    const res = await api.post("add_images", payload.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `token ${payload.token}`,
      }
    })
    return res.data
  } catch (error) {
    console.log(`You Got an Error: ${error}`);
  }
})
export const updatePhotos = createAsyncThunk(
  "updatePhotos",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload.data);
    try {
      const res = await api.put(`update_images`, payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`,
        },
      });
      console.log("res.data update", res);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// =======================Video Gallary=============================
export const getVideos = createAsyncThunk("getVideos", async (token) => {
  try {
    const res = await api.get("gets_all_videos", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(`You Got an Error: ${error}`);
  }
});



const initialState = {
  aboutUsData: [],
  discipline: [],
  flatRace: [],
  arabianshowData: [],
  showJumping: [],
  contact: [],
  services: [],
  horselivery: [],
  stablerents: [],
  qurantine: [],
  transportation: [],
  ridingschool: [],
  photos: [],
  videos: [],

  updateAboutUs: null,
  status: {
    Get: "",
    Create: "",
    Updating: "",
    Update: "",
    Delete: "",
  },
  errors: {
    Get: "",
    Create: "",
    Update: "",
    Delete: "",
  },
};

const HeroQuillReducer = createSlice({
  name: "HeroQuill",
  initialState,
  extraReducers: (builder) => {
    // ========================AboutUs======================================
    builder.addCase(getAboutUs.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getAboutUs.fulfilled, (state, action) => {
      state.aboutUsData = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getAboutUs.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createAboutUs.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createAboutUs.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createAboutUs.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateAboutUs.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateAboutUs.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateAboutUs.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // ========================Discipline======================================
    builder.addCase(getDiscipline.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getDiscipline.fulfilled, (state, action) => {
      state.discipline = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getDiscipline.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createDiscipline.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createDiscipline.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createDiscipline.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateDiscipline.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateDiscipline.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateDiscipline.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // ========================Flat Race======================================
    builder.addCase(getFlatRace.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getFlatRace.fulfilled, (state, action) => {
      state.flatRace = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getFlatRace.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createFlatrace.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createFlatrace.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createFlatrace.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateFlatRace.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateFlatRace.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateFlatRace.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // =======================Arabian Show=============================
    builder.addCase(getArabian.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getArabian.fulfilled, (state, action) => {
      state.arabianshowData = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getArabian.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createArabianShow.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createArabianShow.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createArabianShow.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateArabian.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateArabian.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateArabian.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });

    // =======================Show Jumping=============================
    builder.addCase(getShowJumping.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getShowJumping.fulfilled, (state, action) => {
      state.showJumping = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getShowJumping.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createShowJumping.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createShowJumping.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createShowJumping.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateShowJumping.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateShowJumping.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateShowJumping.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    
    // =======================Services Show=============================
    builder.addCase(getServices.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.services = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getServices.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createServices.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createServices.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createServices.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateServices.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateServices.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateServices.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // =======================Horse livery Show=============================
    builder.addCase(getHorselivery.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getHorselivery.fulfilled, (state, action) => {
      state.horselivery = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getHorselivery.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createHorseLivery.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createHorseLivery.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createHorseLivery.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateHorselivery.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateHorselivery.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateHorselivery.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // =======================Stable rents Show=============================
    builder.addCase(getStablerents.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getStablerents.fulfilled, (state, action) => {
      state.stablerents = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getStablerents.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createStableRent.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createStableRent.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createStableRent.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateStablerents.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateStablerents.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateStablerents.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // =======================Qurantine Show=============================
    builder.addCase(getQurantine.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getQurantine.fulfilled, (state, action) => {
      state.qurantine = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getQurantine.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createQuatantine.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createQuatantine.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createQuatantine.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateQurantine.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateQurantine.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateQurantine.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // =======================Transportation Show=============================
    builder.addCase(getTransportation.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getTransportation.fulfilled, (state, action) => {
      state.transportation = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getTransportation.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createTransportation.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createTransportation.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createTransportation.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateTransportation.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateTransportation.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateTransportation.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // =======================Ridingschool Show=============================
    builder.addCase(getRidingschool.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getRidingschool.fulfilled, (state, action) => {
      state.ridingschool = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getRidingschool.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(createRidingSchool.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createRidingSchool.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createRidingSchool.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateRidingschool.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateRidingschool.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateRidingschool.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });

    // =======================Contact=============================
    builder.addCase(getContact.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getContact.fulfilled, (state, action) => {
      state.contact = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getContact.rejected, (state) => {
      state.status.Get = "failed";
    });

    
    builder.addCase(createContact.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createContact.fulfilled, (state) => {
      state.status.Create = "succeed";
    });
    builder.addCase(createContact.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateContact.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateContact.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updateContact.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // =======================Photo Gallary=============================
    builder.addCase(getPhotos.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getPhotos.fulfilled, (state, action) => {
      state.photos = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getPhotos.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(updatePhotos.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updatePhotos.fulfilled, (state) => {
      state.status.Update = "succeed";
    });
    builder.addCase(updatePhotos.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });
    // =======================Video Gallary=============================
    builder.addCase(getVideos.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getVideos.fulfilled, (state, action) => {
      state.videos = action.payload;
      state.status.Get = "succeed";
    });
    builder.addCase(getVideos.rejected, (state) => {
      state.status.Get = "failed";
    });

  },
});

export default HeroQuillReducer.reducer;
