import React, { useEffect, useRef, useState } from "react";
import "./studentRegistrations.css";
import api from "../../Apis";
import { TextField, Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import LoginPageLeftContainer from "./LoginPageLeftContainer";
import { Link, useNavigate } from "react-router-dom";
import CommonLoading from "../../SharedComponets/Loading/CommonLoading";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Registration from "../../App/Apis/RidingSchoolApis/studentApi/registration";
import LoginApi from "../../App/Apis/RidingSchoolApis/studentApi/login";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const StudentRegistrations = () => {
  const inputs = useRef([]);

  const navigate = useNavigate();
  const [countries, setCountries] = useState(null);
  const [isemailExit, setIsEmailExit] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [otpField, setOtpField] = useState(false);
  const [otp, setOtp] = useState("");
  const [payload, setPayload] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  console.log("payload", payload);
  console.log("phoneNumber", phoneNumber);
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: phoneNumber,
    country_id: "",
    date_of_birth: "",
  };
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };
  const IsEmailExit = async () => {
    try {
      setEmail(values.email ? values.email : null);
      const response = await api.post(
        `student_check_email`,
        { email: values.email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsEmailExit(response.data.msg);
      if (response.data.msg !== "") {
        setFieldValue("email", "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => {
      if (!loading && !isSubmitting) {
        setIsSubmitting(true);
        const formData = {
          ...values,
          mobile: phoneNumber,
        };
        setPayload(formData);
        handleGetOTP();
      }
    },
  });

  const GetCountries = async () => {
    try {
      const response = await api.get(`get_country`);
      setCountries(response.data);
    } catch (error) {
      console.log("Failed to contries data");
    }
  };

  useEffect(() => {
    GetCountries();
  }, []);

  const handleGetOTP = async () => {
    if (email != null && !isSubmitting) {
      try {
        setIsSubmitting(true);
        setLoading(true);
        const res = await LoginApi.studentLogin({ email: email });
        if (res.status === 200 || res.statusText === "OK") {
          setLoading(false);
          setOtpField(true);
          toast.success("OTP send successfully, please check on your email");
        }
      } catch (error) {
        console.log("opt not send", error);
      } finally {
        setLoading(false);
        setIsSubmitting(false);
      }
    }
  };

  const handleOtpVerify = async (e, index) => {
    const otpValue = e.target.value;
    console.log("otpValue", otpValue);
    const newOtp =
      otp.substring(0, index) + otpValue + otp.substring(index + 1);
    setOtp(newOtp);

    if (otpValue.length >= 1 && index < inputs.current.length - 1) {
      inputs.current[index + 1].focus();
    }

    if (newOtp.length >= inputs.current.length) {
      try {
        const res = await LoginApi.studentLogin_OTPVerify({
          email: email,
          otp: Number(newOtp),
        });
        if (res.status === 200 || res.statusText === "OK") {
          const res = await Registration.createRegistration(payload);

          if (res.status === 201 || res.status === "OK") {
            toast.success("Registered successfully");
            navigate("/student-login");
          }
        } else {
          console.log("Failed to register");
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        toast.error("Invalid OTP");
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && e.target.value === "" && index > 0) {
      inputs.current[index - 1].focus();
    }
  };
  console.log("otp", otp);
  return (
    <>
      <div className="login-section register-page">
        <LoginPageLeftContainer />
        <div className="login-right-container">
          <div className="register-module-container">
            <div className="login-portal-container">
              <h2 style={{ color: "#826426", textAlign: "center" }}>PORTAL</h2>
              <p
                style={{
                  color: "#222222",
                  fontSize: "12.5px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {otpField
                  ? `PLEASE ENTER YOUR OTP HERE`
                  : `PLEASE REGISTER YOUR ACCOUNT BELOW`}
              </p>
            </div>

            <div className="register-container">
              <form className="login-form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {otpField ? (
                    <div
                      style={{
                        color: "#222222",
                        fontSize: "12.5px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      <lable>Enter OTP here </lable>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          marginBlock: "20px",
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {[...Array(6)].map((_, index) => (
                          <input
                            key={index}
                            name="otp"
                            style={{ width: "40px", height: "40px" }}
                            onChange={(e) => handleOtpVerify(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            value={otp[index]}
                            ref={(el) => (inputs.current[index] = el)}
                            className="input-field login-input-field"
                            type="text"
                            required
                          />
                        ))}
                      </Grid>
                    </div>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label className="form-label" htmlFor="company">
                            First Name
                          </label>
                          <input
                            value={values.first_name}
                            name="first_name"
                            onChange={handleChange}
                            className="input-field login-input-field"
                            id="firstName"
                            type="text"
                            placeholder="First Name"
                            required
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label className="form-label" htmlFor="lastName">
                            Last Name
                          </label>
                          <input
                            value={values.last_name}
                            name="last_name"
                            onChange={handleChange}
                            className="input-field login-input-field"
                            id="lastName"
                            type="text"
                            placeholder="Last Name"
                            required
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label className="form-label" htmlFor="email">
                            EMAIL
                          </label>
                          <input
                            value={values.email}
                            name="email"
                            onChange={handleChange}
                            onBlur={() => IsEmailExit()}
                            className="input-field login-input-field"
                            id="email"
                            type="email"
                            placeholder="example@google.com"
                            required
                          />
                          <span style={{ color: "red", fontSize: "0.8rem" }}>
                            {isemailExit != null ? isemailExit : null}
                          </span>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label className="form-label" htmlFor="phone">
                            PHONE NUMBER
                          </label>
                          {/* <input
                            value={values.mobile}
                            name="mobile"
                            onChange={handleChange}
                            className="input-field login-input-field"
                            id="phone"
                            type="tel"
                            placeholder="Phone Number"
                            required
                          /> */}
                          <PhoneInput
                            country={"in"}
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            style={{ marginTop: "8px" }}
                            enableSearch={true}
                            countryCodeEditable={false}
                            disableSearchIcon={true}
                            inputProps={{
                              required: true,
                              autoFocus: true,
                            }}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label className="form-label" htmlFor="date_of_birth">
                            DOB
                          </label>
                          <input
                            value={values.date_of_birth}
                            name="date_of_birth"
                            onChange={handleChange}
                            className="input-field login-input-field"
                            id="date_of_birth"
                            type="date"
                            placeholder=""
                            required
                          />
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ marginTop: "8px" }}
                      >
                        <label className="form-label" htmlFor="country">
                          SELECT COUNTRY
                        </label>
                        <Autocomplete
                          name="country_id"
                          value={
                            countries
                              ? countries.find(
                                  (item) => item.id === values.country_id
                                )
                              : null
                          }
                          onChange={(event, value) => {
                            setFieldValue("country_id", value.id);
                          }}
                          disablePortal
                          disableClearable
                          id="combo-box-demo"
                          options={countries ? countries : []}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              required
                              className="bg-color"
                              placeholder="Select Country"
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                {otpField ? null : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <button
                      className="login-button"
                      style={{
                        cursor:
                          email === null || isSubmitting
                            ? "no-drop"
                            : "pointer",
                      }}
                      type="submit"
                    >
                      {" "}
                      {loading ? (
                        <CommonLoading
                          height="15px"
                          width="15px"
                          color="#fff"
                        />
                      ) : (
                        "Register"
                      )}
                    </button>
                  </div>
                )}
              </form>

              {otpField ? null : (
                <div
                  style={{
                    marginTop: "0.5rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to="/student-login"
                    style={{
                      color: "#836628",
                      fontSize: "0.95rem",
                      fontWeight: "bold",
                    }}
                  >
                    Back to login
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentRegistrations;
