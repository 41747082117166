import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { BasicTable } from "../../../SharedComponets/Table list/BasicTable";
import { GetUsers } from "../../../App/Reducers/UserReducer";
import api from "../../../Apis";
import DeleteModal from "../../../App/Helpers/DeleteModal";
import AlertMessage from "../../../App/Helpers/AlertMessage";
import { ImgUrl } from "../../../Config/Config";
import { FaUserCircle } from "react-icons/fa";

function UserList() {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserData = useSelector((state) => state.getUsers.users);

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addUserPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_customuser"
      )
    : false;
  const changeUserPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_customuser"
      )
    : false;
  const deleteUserPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_customuser"
      )
    : false;

  const header = [
    {
      Header: "Profile",
      accessor: "profile",
      Cell: ({ row }) => {
        console.log('row.original.profile_image', `${ImgUrl}${row.original.profile_image}`);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={
                row.original.profile_image === null
                  ? <FaUserCircle />
                  : `${ImgUrl}${row.original.profile_image}`
              }
              width={50}
              height={50}
              style={{ borderRadius: "50%" }}
            />
            <span
              style={{ color: "#7c5e1e", fontWeight: "bold", marginLeft: "10px" }}
              title="View profile"
            >
              {row.original.first_name} {row.original.last_name}
            </span>
          </div> )
      }
    },

    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone_number",
      Cell : ({row}) => (
        <span>{`+${row.original.phone_number}`}</span>
      )
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "State",
      accessor: "state",
    },
    {
      Header: "Country",
      accessor: "country.name",
    },
    {
      Header: "User Type",
      accessor: "usertype.name",
    },
  ];

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);


  const createHandler = () => {
    navigate("/dashboard/user_register");
  };

  const updateHandler = (id) => {
    navigate(`/dashboard/user_update/${id}`);
  };

  const deleteHandler = async (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      // dispatch(deleteUser({ id: deleteId, token }));
      try {
        const response = await api.delete(`delete_user/${deleteId}`, {
          headers: { Authorization: `token ${token}` },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Deleted successfully");
          dispatch(GetUsers(token));
        }
        return response;
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  useEffect(() => {
    dispatch(GetUsers(token));
  }, [dispatch, token]);

  return (
    <>
      <ToastContainer />
   
      <div style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}>
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>
      
      {UserData ? (
        <BasicTable
          profile="User Profile"
          colHeader={header}
          rowData={UserData}
          actionFlag={
            deleteUserPermission || changeUserPermission || isUserType
              ? false
              : true
          }
          //  deleteHandler={deleteHandler}
          deleteHandler={
            deleteUserPermission || isUserType ? deleteHandler : null
          }
          //  updateHandler={updateHandler}
          updateHandler={
            changeUserPermission || isUserType ? updateHandler : null
          }
          //  createHandler={createHandler}
          createHandler={addUserPermission || isUserType ? createHandler : null} // Only allow create if the user has permission
          tableHeading="User list"
        />
      ) : (
        <BasicTable
          profile="User Profile"
          colHeader={header}
          rowData={[]}
          //  deleteHandler={deleteHandler}
          deleteHandler={
            deleteUserPermission || isUserType ? deleteHandler : null
          }
          //  updateHandler={updateHandler}
          updateHandler={
            changeUserPermission || isUserType ? updateHandler : null
          }
          //  createHandler={createHandler}
          createHandler={addUserPermission || isUserType ? createHandler : null} // Only allow create if the user has permission
          tableHeading="User list"
        />
      )}
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="User"
        deleteData={deleteData}
      />
    </>
  );
}

export default UserList;
