import React, { useEffect, useState } from "react";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  getOwnerData,
  deleteOwnerData,
  getUpdateOwnerData,
} from "../../App/Reducers/OwnerReducer";
import { useNavigate } from "react-router-dom";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../App/Helpers/DeleteModal";
import api from "../../Apis";
import AlertMessage from "../../App/Helpers/AlertMessage";

function OwnerList() {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const OwnerData = useSelector((state) => state.owner.ownerData);
  const status = useSelector((state) => state.owner.status);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addOwnerPermission = permissions?.user_permissions
    ? permissions.user_permissions.some((obj) => obj.codename === "add_owner")
    : false;
  const changeOwnerPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_owner"
      )
    : false;
  const deleteOwnerPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_owner"
      )
    : false;

  const profileUpdation = (id) => {
    if (id) {
      navigate(`/dashboard/owner/owner-profile/${id}`);
    }
  };

  const header = [
    {
      Header: "Owner Id",
      accessor: "code",
    },
    {
      Header: "Owner Name",
      accessor: "name",
      Cell: ({ row }) => (
        <span
          onClick={() => profileUpdation(row.original.id)}
          style={{ cursor: "pointer", color: "#7c5e1e", fontWeight: "bold" }}
          title="View profile"
        >
          {row.original.name}
        </span>
      ),
    },

    {
      Header: "Email Id",
      accessor: "email",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "Blood Group",
      accessor: "blood_group",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Post Box",
      accessor: "post_box",
    },
    {
      Header: "Date Of Birth",
      accessor: "date_of_birth",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },
    },

    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Mobile Number",
      accessor: "mobile",
      Cell: ({ row }) => (
      
        <span>{`+${row.original.mobile}`}</span>
    )
    },
    {
      Header: "Visa Category",
      accessor: "visa_category.name",
    },
    {
      Header: "Discipline",
      accessor: "divison.name",
    },
    {
      Header: "Nationality",
      accessor: "nationality.name",
    },
    {
      Header: "Country",
      accessor: "country_id.name",
    },
  ];

  const deleteHandler = async (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_owner/${deleteId}`, {
          headers: { Authorization: `token ${token}` },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Deleted successfully");
          dispatch(getOwnerData(token));
        }
        return response;
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  const createHandler = () => {
    navigate("/dashboard/owneragreement/registration_form");
  };

  const updateHandler = (id) => {
    // dispatch(getUpdateOwnerData({id, token}));
    if (status === "succeeded") {
      navigate(`/dashboard/owner/update_form/${id}`);
    }
  };

  useEffect(() => {
    dispatch(getOwnerData(token));
  }, [token]);
  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      <ToastContainer />
      {OwnerData ? (
        <BasicTable
          colHeader={header}
          rowData={OwnerData}
          actionFlag={
            deleteOwnerPermission || changeOwnerPermission || isUserType
              ? false
              : true
          }
          // createHandler={createHandler}
          createHandler={
            addOwnerPermission || isUserType ? createHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteOwnerPermission || isUserType ? deleteHandler : null
          }
          // updateHandler={updateHandler}
          updateHandler={
            changeOwnerPermission || isUserType ? updateHandler : null
          }
          tableHeading="Owner List"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            deleteOwnerPermission || changeOwnerPermission || isUserType
              ? false
              : true
          }
          // createHandler={createHandler}
          createHandler={
            addOwnerPermission || isUserType ? createHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteOwnerPermission || isUserType ? deleteHandler : null
          }
          // updateHandler={updateHandler}
          updateHandler={
            changeOwnerPermission || isUserType ? updateHandler : null
          }
          tableHeading="Owner List"
        />
      )}
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Owner"
        deleteData={deleteData}
      />
    </>
  );
}

export default OwnerList;
