import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ModalComp from "./Modal.jsx";
import { ImgUrl } from "../../../Config/Config.js";
import { useSelector, useDispatch } from "react-redux";
import { getAllRidingtype } from "../../../App/Reducers/RidingSchoolReducers/RidingType.js";
import { BasicTable } from "../../../SharedComponets/Table list/BasicTable.js";
import api from "../../../Apis.js";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../../App/Helpers/AlertMessage.jsx";
import DeleteModal from "../../../App/Helpers/DeleteModal.jsx";
import ridingTypesApi from "../../../App/Apis/RidingSchoolApis/RidingType/ridingtype.js";

const RidingType = () => {
  const dispatch = useDispatch();

  const Ridingtype = useSelector((state) => state.RidingType.ridingType);

  // Get Company Data by localstorage
  const companies = JSON.parse(localStorage.getItem("userData"));
  const company =
    companies && companies.company && companies.company.id
      ? companies.company.id
      : null;

  // Set Cookies
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);
  const [modalData, setModalData] = useState({
    company: "",
    name: "",
    // image: "",
  });

  const openModal = () => {
    setModalData({
      name: "",
      //   image: "",
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleModalInputChange = (e) => {
    let { name, value, files } = e.target;
    const inputValue = files?.length ? files[0] : value;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: inputValue,
    }));
  };

  const createOrUpdateHandler = async (e) => {
    e.preventDefault();

    if (modalData.id) {
      try {
        const res = await ridingTypesApi.updateRidingType(
          {
            id: modalData.id,
            updatedData: {
              name: modalData.name,
              //   image: modalData.image,
              company: company,
            },
          },
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
        if (res.status == 200 || res.status == 201) {
          closeModal();
          toast.success("Riding Type updated");
          dispatch(getAllRidingtype({ token, company }));
        } else {
          toast.error("Riding Type not updated");
        }
      } catch (error) {
        console.log("error", error);
      }
      dispatch(getAllRidingtype({ token, company }));
    } else {
      try {
        const payload = {
          name: modalData.name ? modalData.name : "",
          //   image: modalData.image ? modalData.image : "",
          company: company ? company : "",
        };
        if (company) {
          const res = await ridingTypesApi.createRidingType(payload, {
            headers: {
              "content-type": "multipart/form-data",
            },
          });

          if (res.status == 200 || res.status == 201) {
            closeModal();
            toast.success("Riding Type created");
            dispatch(getAllRidingtype({ token, company }));
          } else {
            toast.error("Riding Type not created");
          }
        }
      } catch (error) {
        console.log("error", error);
      }
      dispatch(getAllRidingtype({ token, company }));
    }
  };

  const editHandler = (id) => {
    const editData = Ridingtype.data.find((data) => data.id === id);

    if (editData) {
      setModalData(editData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_ridingtype/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });

        if (response.status === 200 || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getAllRidingtype({ token, company }));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllRidingtype({ token, company }));
  }, []);

  const header = [
    {
      Header: "Name",
      accessor: "name",
    },
    // {
    //     Header: "Riding Discipline",
    //     accessor: "image",
    //     Cell: ({ row }) => (
    //         <div style={{ display: "flex", alignItems: "center" }}>
    //             <img
    //                 src={`${ImgUrl}${row.original.image}`}
    //                 width={50}
    //                 height={50}
    //                 style={{ borderRadius: "50%" }}
    //                 alt="Riding Type"
    //             // onClick={() => profileUpdation(row.original.id)}
    //             />
    //             <span
    //                 // onClick={() => profileUpdation(row.original.id)}
    //                 style={{
    //                     // cursor: "pointer",
    //                     color: "#252525",
    //                     fontWeight: "bold",
    //                     marginLeft: "10px",
    //                 }}
    //                 title="View profile"
    //             >
    //                 {row.original.name}
    //             </span>
    //         </div>
    //     ),
    // },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>
      {Ridingtype ? (
        <BasicTable
          colHeader={header}
          rowData={Ridingtype.data}
          updateHandler={editHandler}
          deleteHandler={deleteHandler}
          createHandler={openModal}
          tableHeading="Riding Discipline List"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          updateHandler={editHandler}
          deleteHandler={deleteHandler}
          createHandler={openModal}
          tableHeading="Riding Discipline List"
        />
      )}

      <ModalComp
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        label={["ADD Riding Discipline"]}
        heading="Riding Discipline"
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Riding Discipline"
        deleteData={deleteData}
      />
      <ToastContainer />
    </>
  );
};
export default RidingType;
