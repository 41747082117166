import { companyId } from "../../RidingSchoolReuseIds/Ids";
import Api from "../ApiConfig";


const Slot = {
    createSlot: (payload) => Api.post(`create_slot`, payload),
    updateSlot: (payload) => Api.put(`update_slot/${payload.id}`, payload),
    getSlotById: (id) => Api.get(`get_slot/${id}`),
    availableSlotSteats: (id) => Api.get(`available_slot_steats/${id}`),
    getCourseSlots: (id) => Api.get(`get_course_slots/${id}`),
    deleteSlot: (id) => Api.delete(`delete_slots/${id}`),
    getSlotList: () => Api.get(`get_all_slots/${companyId}`),
    checkAssignableStots: (courseId) => Api.get(`assinable_slots/${courseId}`),
    getCartAssignableStots: (payload) => Api.post(`available_slot_steats_card`, payload)
};

export default Slot;
