import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Apis";

export const getStallCellData = createAsyncThunk(
  "getStallCellData",
  async (token) => {
    try {
      const res = await api.get("get_all_checkin", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const data = res.data;
      console.log(data)
      return data;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);
export const deleteStallData = createAsyncThunk(
  "deleteCheckInData",
  async (id) => {
    try {
      const res = await api.delete(
        `http://127.0.0.1:8000/api/delete_checkin/${id}`
      );
      const data = res.data;

      return data;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);
export const createStallCellData = createAsyncThunk(
  "createStallCellData",
  async (payload, { rejectWithValue }) => {
    try {
      console.log(
        "createStallCellData---------------================++++++++++++++++",
        payload
      );
      const res = await api.post("create_cell", payload.data, {
        headers: {
          "Content-Type": "multipart/form-data,application/json",
          Authorization: `token ${payload.token}`,
        },
      });
      const ApiData = res.data;
      console.log("Create response=---------------", ApiData);
      return ApiData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getStallCellDataID = createAsyncThunk(
  "getCheckInDataID",
  async (payload) => {
    try {
      console.log("h");
      const res = await api.get(`get_cell/${payload.id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
        },
      });
      const ApiData = res.data;
      console.log(
        ApiData,
        "Get Cell data from Async func/////////////////////////////////////////////////"
      );
      return ApiData;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);
export const getAllCheckinHorse = createAsyncThunk(
  "getAllCheckinHorse",
  async (token) => {
    try {
      const res = await api.get("get_all_checkin", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const ApiData = res.data;
      console.log(ApiData, "Horse Checkin Data===========");
      return ApiData;
    } catch (error) {
      console.log(`You got into error : ${error}`);
    }
  }
);
export const getAllStall = createAsyncThunk("getAllStall", async (token) => {
  try {
    console.log(
      "ApiData",
      "==============================================Stall Data==========="
    );
    const res = await api.get("get_all_stall", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    const ApiData = res.data;
    console.log(
      ApiData,
      "==============================================Stall Data==========="
    );
    return ApiData;
  } catch (error) {
    console.log(`You got into error : ${error}`);
  }
});

export const updateStallCellData = createAsyncThunk(
  "updateStallCellData",
  async (updatedData, { rejectWithValue }) => {
    try {
      console.log(updatedData);
      const res = await api.put(
        `update_cell/${updatedData.id}`,
        updatedData.UpdatedData,
        {
          headers: {
            "Content-Type": "multipart/form-data,application/json",
            Authorization: `token ${updatedData.token}`,
          },
        }
      );
      const ApiData = res.data;
      console.log(ApiData, "Updated Api data");

      return ApiData;
    } catch (error) {
      return rejectWithValue(error.response.data);
      console.log(`You got an Error : ${error}`);
    }
  }
);
const initialState = {
  stallCellData: null,
  getDataById: null,
  DropDownData: {
    HorseData: null,
    StallData: null,
  },

  status: {
    Get: "",
    Create: "",
    Updating: "",
    Update: "",
    Delete: "",
  },
  errors: {
    Get: "",
    Create: "",
    Update: "",
    Delete: "",
  },
};

const StallSlice = createSlice({
  name: "cell",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getStallCellData.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getStallCellData.fulfilled, (state, action) => {
      state.status.Get = "succeed";
      state.stallCellData = action.payload;
      console.log(action.payload, "state.stallData = action.payload;");
    });
    builder.addCase(getStallCellData.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(deleteStallData.pending, (state) => {
      state.status.Delete = "loading";
    });
    builder.addCase(deleteStallData.fulfilled, (state, action) => {
      state.status.Delete = "succeed";
    });
    builder.addCase(deleteStallData.rejected, (state) => {
      state.status.Delete = "failed";
    });

    builder.addCase(createStallCellData.pending, (state) => {
      state.status.Create = "loading";
      state.errors.Create = "";
    });
    builder.addCase(createStallCellData.fulfilled, (state, action) => {
      state.status.Create = "succeed";
      console.log(action.payload);
    });
    builder.addCase(createStallCellData.rejected, (state, action) => {
      state.status.Create = "failed";
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateStallCellData.pending, (state) => {
      state.status.Update = "loading";
      state.errors.Update = "";
    });
    builder.addCase(updateStallCellData.fulfilled, (state, action) => {
      state.status.Update = "succeed";
      console.log(action.payload);
    });
    builder.addCase(updateStallCellData.rejected, (state, action) => {
      state.status.Update = "failed";
      state.errors.Update = action.payload.msg;
    });

    builder.addCase(getStallCellDataID.pending, (state) => {
      state.status.Updating = "loading";
    });
    builder.addCase(getStallCellDataID.fulfilled, (state, action) => {
      state.status.Updating = "succeed";
      state.getDataById = action.payload;
      console.log(action.payload, "Get obj by data-----------");
    });
    builder.addCase(getStallCellDataID.rejected, (state) => {
      state.status.Updating = "failed";
    });

    // builder.addCase(getAllCheckinHorse.pending, (state) => {
    //   state.status.Updating = "loading";
    // });
    builder.addCase(getAllCheckinHorse.fulfilled, (state, action) => {
      // state.status.Updating = "succeed";
      state.DropDownData.HorseData = action.payload;
      console.log(action.payload, "Horse data for dropdown-----------");
    });
    // builder.addCase(getAllCheckinHorse.rejected, (state) => {
    //   state.status.Updating = "failed";
    // });

    // builder.addCase(getAllCheckinHorse.pending, (state) => {
    //   state.status.Updating = "loading";
    // });
    builder.addCase(getAllStall.fulfilled, (state, action) => {
      state.status.Stall = "succeed";
      state.DropDownData.StallData = action.payload;
      console.log(action.payload, "Stall data for dropdown-----------");
    });
    // builder.addCase(getAllCheckinHorse.rejected, (state) => {
    //   state.status.Updating = "failed";
    // });
  },
});
export default StallSlice.reducer;
