import React from 'react'
import './ContentBar.css'
import { Outlet } from 'react-router-dom'
const ContentBar = ({ sx }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <div style={sx ? { ...sx } : {}} className='dashboard-contentbar ' >
      <Outlet />
    </div >
  )
}

export default ContentBar