import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import RegistrationListOverview from "../../SharedComponets/Dashboard/DashOverview/RegistrationListOverview";
import Need from "../../SharedComponets/Dashboard/Need/Need";
import EventCalendarOverview from "../../SharedComponets/Dashboard/DashOverview/EventCalendarOverview";
import UserDp from "../../SharedComponets/Dashboard/DashOverview/UserDp";
import AOS from "aos";
import TodaysPendingWork from "../../SharedComponets/Dashboard/DashOverview/TodaysPendingWork";
import DocExpire from "../../SharedComponets/Dashboard/DashOverview/DocExpire";
import api from "../../Apis";
import { useCookies } from "react-cookie";
import Chart from "../../SharedComponets/Charts/BarChart";
import LineChartComponent from "../../SharedComponets/Charts/LIneChart";

const Dashboard = ({ DashboardName }) => {
  const permissions = JSON.parse(localStorage.getItem("userData"));

  const [cookies, setCookie] = useCookies(["token"]);
  const token = cookies.token;

  const [ownerData, setOwnerData] = useState(null);
  const [trainerData, setTrainerData] = useState(null);
  const [horseData, setHorseData] = useState(null);

  const OwnerDataHandler = async () => {
    try {
      const response = await api.get("owner_expired_docs", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.status === 201 ||
        response.statusText === "Created"
      ) {
        setOwnerData(response.data);
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const TrainerDataHandler = async () => {
    try {
      const response = await api.get("trainer_expired_docs", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.status === 201 ||
        response.statusText === "Created"
      ) {
        setTrainerData(response.data);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const horseDataHandler = async () => {
    try {
      const response = await api.get("horse_fei_docs_expiry", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.status === 201 ||
        response.statusText === "Created"
      ) {
        setHorseData(response.data);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const [windowwidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {}, [permissions]);

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (DashboardName && DashboardName === "riding-club") {
    } else {
      OwnerDataHandler();
      TrainerDataHandler();
      horseDataHandler();
    }
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard-left-container">
        <RegistrationListOverview DashboardName={DashboardName} />
        {/* <StatusComponent /> */}
        {/* {!DashboardName && DashboardName !== "riding-club" && (
          <TodaysPendingWork />
        )} */}
        <TodaysPendingWork />
        {/* <SmartService /> */}
        <div style={{ display: "flex", gap: "0.8rem" }}>
          <Chart />
          <LineChartComponent />
        </div>
      </div>
      {windowwidth > 1025 && (
        <div className="dashboard-right-container" data-aos="fade-left">
          <UserDp />
          <h2 className="dashboard-header">
            <span>Recent Notifications ! </span>
          </h2>
          <DocExpire
            DashboardName={DashboardName}
            horseData={
              horseData && horseData.expire_horse_docs
                ? horseData.expire_horse_docs
                : []
            }
            horsePath="/dashboard/horseagreement/horses_list"
            horseFEIData={
              horseData && horseData.expireFEI ? horseData.expireFEI : []
            }
            horseFEIPath="/dashboard/horseagreement/horses_list"
            ownerData={
              ownerData && ownerData.expire_owner_docs
                ? ownerData.expire_owner_docs
                : ["Document never expires."]
            }
            ownerPath="/dashboard/owneragreement/owners_list"
            trainerData={
              trainerData && trainerData.expire_trainer_docs
                ? trainerData.expire_trainer_docs
                : []
            }
            trainerPath="/dashboard/traineragreement/trainers_list"
          />

          <EventCalendarOverview DashboardName={DashboardName} />
          <Need />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
