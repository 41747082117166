import React, { useEffect } from 'react';
import CommonEditablePage from '../../SharedComponets/CommonEditablePage';
import { useDispatch, useSelector } from 'react-redux';
import { createAboutUs, getAboutUs, updateAboutUs } from '../../App/Reducers/HeroQuillReducer';
import { about } from '../../Assets/DefaultImagesCarousel';

const AboutUs = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.aboutUsData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAboutUs());
  }, []);

  const defaultContent = `
  A stable is a building in which livestock, especially horses, are kept. It most commonly means a building that is divided into separate stalls for individual animals and livestock. There are many different types of stables in use today; the American-style barn, for instance, is a large barn with a door at each end and individual stalls inside or free-standing stables with top and bottom-opening doors. The term "stable" is also used to describe a group of animals kept by one owner, regardless of housing or location.
  The exterior design of a stable can vary widely, based on climate, building materials, historical period and cultural styles of architecture. A wide range of building materials can be used, including masonry (bricks or stone), wood and steel. Stables also range widely in size, from a small building housing one or two animals to facilities at agricultural shows or race tracks that can house hundreds of animals.
`;

  return (
    <>
      <CommonEditablePage
        updateApiFunc={updateAboutUs}
        createApiFunc={createAboutUs}
        getApiFunc={getAboutUs}
        modalSelector={modalSelector}
        default_image_title={about.image_title}
        default_image= {about.image}
        default_body_content={defaultContent}
      />
    </>
  );
};

export default AboutUs;
