import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { ImgUrl } from "../Config/Config";
import { photoGallary } from "../Assets/DefaultImagesCarousel";

const itemsPerPage = 12; // Number of items per page

const PhotoGallary = (props) => {

  const userData = JSON.parse(localStorage.getItem("userData"));
  const isloggedIn = localStorage.getItem("adminloggedIn");


  const Images = useSelector((state) => state.photogallary.photoGallary)
  const defaultImages = photoGallary

  console.log("Images", Images);

  // Theme selector function and variables --Start
  const [headerdivClass, setHeaderDivClass] = useState("artical-section");
  const [mainTheme, setMainTheme] = React.useState("default");
  const themeSelector = useSelector((state) => state.theme.ThemeColor);

  useEffect(() => {
    switch (themeSelector) {
      case "header-white":
        setHeaderDivClass("artical-section header-white");
        break;
      case "header-roseate":
        setHeaderDivClass("artical-section header-roseate");
        break;
      case "header-skyBlue":
        setHeaderDivClass("artical-section header-skyBlue");
        break;
      case "header-grassy":
        setHeaderDivClass("artical-section header-grassy");
        break;
      case "header-pink":
        setHeaderDivClass("artical-section header-pink");
        break;
      case "header-gold":
        setHeaderDivClass("artical-section header-gold");
        break;
      case "header-orange":
        setHeaderDivClass("artical-section header-orange");
        break;
      default:
        setHeaderDivClass("artical-section header-gold ");
        break;
    }
  }, [themeSelector]);

  //  Theme selector function and variables --End
  const [themetoggle, setThemetoggle] = useState(true);
  const handleTheme = () => {
    console.log("shdgasgdasgdgsaig");
    if (themetoggle) {
      setThemetoggle(false);
    } else {
      setThemetoggle(true);
    }
  };

  return (

    <div style={{ backgroundColor: "black", paddingBlock: "50px" }} className={headerdivClass}>

      <div style={{ backgroundColor: "#222222", borderRadius: "10px", padding: "10px", width: "80%", margin: "auto" }}>

        <div style={{ height: "20px", display: "flex", justifyContent: "space-between", marginBlock: "20px" }}>
          <p style={{ display: "inline-block", color: "#bebb9e", fontFamily: "var(--main-font)", fontSize: "2.5rem" }}>Photos</p>

          {isloggedIn ? userData && userData.usertype?.name === "Admin" && (
            <i class="fas fa-edit" style={{ fontSize: "20px", color: "#bebb9e", cursor: "pointer", float: "right" }}
              onClick={props.handleModalOpen}
              title="Customize Photos" />
          ) : null
          }

        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(10rem, 1fr))",
            gap: "10px",
            paddingBlock: "20px"
          }}
        >
          {Images ? Images.length > 0 &&
            (Images.length > 0 && Images.map((item, index) => (
              <div style={{ display: "flex", flexDirection: "column" }} key={index}>
                <img
                  src={`${ImgUrl}${item.image}`}
                  style={{ width: "10rem", height: "10rem", objectFit: "cover", borderRadius: "2px" }}
                />
              </div>
            )))
            : (defaultImages && defaultImages.length > 0 && defaultImages.map((item, index) => (
              <div style={{ display: "flex", flexDirection: "column" }} key={index}>
                <img
                  src={item.image}
                  style={{ width: "10rem", height: "10rem", objectFit: "cover", borderRadius: "2px" }}
                />
              </div>
            )))
          }
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default PhotoGallary;
