import api from "../../Apis";

const FETCH_USER_BY_ID = 'FETCH_USER_BY_ID';

export const CreateUsers = async (payload) => {

    try {

        const response = await api.post("create_user", payload.fData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `token ${payload.token}`
            }
        })
        return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        return error.response
    }
}

export const UpdateUser = async (payload) => {
    // console.log(userId,fData,token);
    try {
        const response = await api.put(`update_user/${payload.userId}`, payload.fData, {
            headers: {
                Authorization: `token ${payload.token}`
            }
        })
        return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        return error.response
    }
}

export const GetUser = async (id, token) => {
    try {
        const response = await api.get(`get_user/${id}`, { headers: { Authorization: `token ${token}` } })
        return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        return error.response
    }
}

export const GetUsersMaster = async (token) => {
    try {
      const response = await api.get("get_all_users", { headers: { Authorization: `token ${token}` } });
      // console.log("response", response);
      // return { data: response.data, status: response.status, statusText: response.statusText }
      return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        return error.response
    }
  }