import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  index:0,
  previousIndex : 0,
}

export const generalSlice = createSlice({
  name: 'General',
  initialState,
  reducers: {
    ListItemIndex: (state, action) => {
      console.log(action.payload)
      state.index = action.payload
      state.previousIndex = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {ListItemIndex} = generalSlice.actions

export default generalSlice.reducer