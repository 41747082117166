import { createSlice } from "@reduxjs/toolkit";

const DataSetReducer = createSlice({
  name: "data",
  initialState: {
    About: `<div class="contentDiv">
    <div>
      <p>
          <strong><u>ABOUT US</u></strong></p>
  </div>
        <p>
          The Sharjah Equestrian and Racing Club, the first equestrian club in
          the UAE, was established in 1982 under the patronage of H.H. Sheikh
          Dr. Sultan Bin Mohammed Al Qasimi, Member of the Supreme Council and
          Ruler of Sharjah.
        </p>
        <p>
          The Sharjah Equestrian and Racing Club is located on the Al Dhaid Road
          (Sharjah Airport Road) at Interchange 6. This comprehensive equine
          establishment covers an area of 2.8 km2 and is home to the one of the
          largest indoor riding facilities in the Middle East.
        </p>
        <p>
          The club has been at the forefront of encouraging equine sports in the
          UAE. The Sharjah Racetrack, constructed to global specifications, was
          used to organize the first official flat race in UAE in 1983.
        </p>
        <p>
          With the continuous support and guidance of His Highness, Sharjah
          Equestrian and Racing Club management strive to keep the club up to
          date with the club now offering the following disciplines and
          services:
        </p>
        <h4>A British Horse Society (BHS) Approved Riding School</h4>
        <h4>Sharjah Arabian Horse Stud</h4>
        <h4>Show Jumping</h4>
        <h4>Flat Racing and Sharjah Longines Racecourse</h4>
        <h4>Visiting Stables for Competing Horses</h4>
        <h4>Approved Quarantine</h4>
        <h4>Medical Centre to Treat Horses</h4>
        <h4>Breeding Centre</h4>
        <h4>Equine Hydrotherapy Centre</h4>
        <h4>Horse Swimming Pool</h4>
        <h4>Horse Souq</h4>
        <h4>Horse Transportation Services</h4>
        <h4>Pony Club for Children</h4>
        <h4>Ladies Only Arena</h4>
        <h4>Restaurant</h4>
        <br />
        <h4>Quality policy and complaints and events sustainability:</h4>
        <p style={{ textAlign: "justify" }}>
          Quality policy and complaints and events sustainability In the context
          of preserving the heritage of the ancestors and leaving a mark of
          glories and championships for future generations, forming the
          integrated personality of the youth, spreading the equestrian sport
          and spreading the national spirit among the club’s pioneers, We,
          (Sharjah Equestrian and Racing Club), are committed to actively
          contributing to the sports development plan in the country and the
          emirate, to promoting equestrianism and competitions, and to achieving
          the policy of the Sharjah Sports Council towards a sports environment
          that meets the requirements of standard specifications for quality
          management and event sustainability ISO 9001 & ISO 20121to provide
          high quality services that contribute to enhancing competitiveness in
          sports events, achieving the principles of sustainable development,
          human rights, preserving the environment, work rules, combating
          corruption, supervision, integration, inclusiveness and transparency,
          fulfilling the requirements of customers, partners, and stakeholders,
          and bearing responsibility. community and strengthening the club's
          position and achieving its goals to achieve championships and glories,
          reduce costs, rationalize energy and reduce waste and pollution
          through event projects and the supply chain We also commit the
          participation of employees and defining their responsibilities in the
          continuous improvement of the quality system and the sustainability of
          events and creative thought and spreading a culture of focus on
          dealings and providing resources for planning, implementing and
          reviewing the performance of events and post-event activities , We
          also commit, in accordance with our conviction, the right of dealers
          to express their complaints to welcome complaints and address them
          effectively and efficiently and to fulfill the requirements of the
          international standard ISO 10002 through a system characterized by
          clarity, communication, objectivity, confidentiality, accountability,
          openness, perfection, equality, justice, impartiality and speed
          Responding, and considering complaints as an effective way to improve
          our services and gain customer satisfaction in accordance with the
          requirements of International Standard ISO 10004. In this context, we
          are committed to fulfilling the legal, regulatory, operational and
          financial requirements, taking into account the social, political,
          economic and heritage aspects, while applying the best practices in
          accordance with the original equestrian ethics. General Manager
          HE/Sultan Al Yahyai
        </p>
        </div>
        `,
    Discipline: "helloDicsipline",
  },
  reducers: {
    update(state, action) {
      console.log("update called", action.payload);
      state.About = action.payload;
    },
  },
});
export const { update } = DataSetReducer.actions;
export default DataSetReducer.reducer;
