
import React from 'react'
import CommonEditablePage from '../../../SharedComponets/CommonEditablePage'
import { useSelector } from 'react-redux'
import { createArabianShow, getArabian, updateArabian } from '../../../App/Reducers/HeroQuillReducer'
import { arabianShow } from '../../../Assets/DefaultImagesCarousel'


const ArabianShows = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.arabianshowData);

  const defaultContent = `Arabians are one of the oldest, purest breeds of horses to walk the earth. Noted for their incredible beauty and elegance, Arabian horse shows are popular worldwide.
  The most popular Arabian horse shows are Scottsdale, U.S. Nationals, Arabian Horse World Championship, Dubai International, Qatar Nationals, UK International, Youth Nationals, European Championships, and Canadian Nationals.
  These are among the most prestigious horse shows that showcase the best Arabian horses in the world. They offer a variety of classes and attract competitors from all over.
  Arabian horses stand out in the crowd thanks to their finely chiseled heads, dished noses, arching necks, and high tail carriage.
  These intelligent, athletic, and energetic horses are also famous for having a “floating trot.” As one of the most versatile breeds, they excel at a variety of divisions in western, hunt seat, saddle seat, dressage, and driving disciplines.`
  
  return (
    <>
    <CommonEditablePage  
    updateApiFunc ={updateArabian}
     getApiFunc={getArabian} 
     createApiFunc = {createArabianShow}
     modalSelector={modalSelector}
     default_image_title="Arabian Show"
     default_image= {arabianShow.image}
     default_body_content= {defaultContent}/>
    </>
  )
}

export default ArabianShows