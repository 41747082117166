
import React from 'react'
import CommonEditablePage from '../../../SharedComponets/CommonEditablePage'
import { useSelector } from 'react-redux'
import { createFlatrace, getFlatRace, updateFlatRace } from '../../../App/Reducers/HeroQuillReducer'
import { flatRace } from '../../../Assets/DefaultImagesCarousel'


const FlatRace = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.flatRace);
  const defaultContent = `
 Flat racing is a test of speed, stamina, and the skill of the jockey in choosing the right tactics; knowing where to position their horse in the race, when to restrain their horse or to ask it to make an effort.
  Races are held over a variety of distances from 5F to over 2 miles and under conditions with eligibility based on the sex, age or ability of the horse.
  The majority of Flat races in the UK are run on grass, but some are run on synthetic or all weather surfaces. These tracks are called all weather because the materials they are made of have better drainage than turf.  In cold weather, these surfaces allow racing to continue when it might otherwise be cancelled due to frost or frozen ground.
  Examples of flat races include
  Classics
  Group and listed races
  Maidens
  Handicaps
  Fast Facts 
  There are 35 racecourses in the United Kingdom that stage Flat racing
  The highlights of Flat racing include the Guineas Festival, the Derby Festival and Royal Ascot.
  What is a Classic? - Racing Explained
  The Classics are the crown jewels of British Flat racing;  five races that are considered the ultimate test of a generation of horses.`
  return (
    <>
      <CommonEditablePage
        updateApiFunc={updateFlatRace}
        getApiFunc={getFlatRace}
        createApiFunc={createFlatrace}
        modalSelector={modalSelector}
        default_image_title={flatRace.image_title}
        default_image={flatRace.image}
        default_body_content={defaultContent}
      />
    </>
  )
}

export default FlatRace