
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../Apis';
import { videoGallary } from '../../Assets/DefaultImagesCarousel';


export const getVideoData = createAsyncThunk("getVideoData", async (token) => {
  try {
    if (token) {
      const response = await api.get("gets_all_videos", {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      return response.data
    } else {
      return null
    }
  } catch (error) {
    console.log("Photo Error", error)
  }
})


export const createVideoData = createAsyncThunk("createVideoData", async (payload) => {
  try {
    if (payload.token) {
      const response = await api.post("add_video", payload.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data, application/json",
            Authorization: `token ${payload.token}`,
          }
        })
      console.log("create photo response", response.data)
      return response.data
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
})

export const deleteVideoData = createAsyncThunk("deleteVideoData", async ({ id, token }) => {
  try {
    if (token) {

    }
    const response = await api.delete(`delete_video/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      }
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
})


export const videoGallarySlice = createSlice({
  name: 'videogallary',
  initialState: {
    status: null,
    videoGallary: videoGallary,
    error: null
  },
  reducers: {},
  
  extraReducers: (builder) => {
    builder.addCase(getVideoData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getVideoData.fulfilled, (state, action) => {
      state.status = "succeded"
      state.videoGallary = action.payload
    })
    builder.addCase(getVideoData.rejected, (state) => [
      state.status = "failed"
    ])

    builder.addCase(createVideoData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(createVideoData.fulfilled, (state) => {
      state.status = "succeded"
    })
    builder.addCase(createVideoData.rejected, (state) => [
      state.status = "failed"
    ])

    builder.addCase(deleteVideoData.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(deleteVideoData.fulfilled, (state) => {
      state.status = "succeded"
    })
    builder.addCase(deleteVideoData.rejected, (state) => [
      state.status = "failed"
    ])
 
  }
})


export const { Upload, Edit, Delete } = videoGallarySlice.actions

export default videoGallarySlice.reducer