import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
const PageNotFound = () => {
  localStorage.getItem("token", "");
  const Token = localStorage.getItem("token") ? localStorage.getItem("token") : "";
  const studentToken = localStorage.getItem("student_token") ? localStorage.getItem("student_token") : "";
  console.log("token", Token);
  console.log("studentToken", studentToken);
  const path = !Token && !studentToken ? "/" : Token ? "/dashboard" : "student-dashboard"
  console.log("path", path);

  return (
    <>

      <div style={{ textAlign: "center", marginTop: "10%" }}>
        <img src="https://media.istockphoto.com/id/983435100/vector/galloping-horse-black-and-white.jpg?s=612x612&w=0&k=20&c=3k06-KIoQIa5H4QFjMRGV6z1L9JA7AylJ0yxliVyogs=" style={{ width: "10%" }} />
        <h1 style={{ marginBlock: "20px" }}>404 - Page Not Found</h1>
        <p >The page you are looking for might have been removed </p>
        <p>had its name changed or is temporarily unavailable.</p>
        <Link to={path}> <Button
          variant="outlined"
          sx={{
            color: "#fff",
            borderColor: "#302924",
            "&:hover": {
              borderColor: "#302924",
              color: "#302924",
            },
            fontSize: "12px",
            marginBlock: "25px",
            backgroundColor: "#302924",
          }}
        >
          BACK TO HOME
        </Button></Link>
      </div>

    </>
  )
}

export default PageNotFound