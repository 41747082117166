import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Alert } from '@mui/material';
const AlertMessage = (props) => {

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    props.isVisibila ?
      <Alert severity= {props.severity} style={{ width: "fit-content", float: "right" }} data-aos="fade-left" data-aos-duration="1000">{props.message}</Alert> 
      : null
  )
}

export default AlertMessage