import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Apis";

export const GetUsers = createAsyncThunk("GetUsers", async (token, { rejectWithValue }) => {
  try {
    const response = await api.get("get_all_users", { headers: { Authorization: `token ${token}` } });
  
    return response.data
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const CreateUser = createAsyncThunk("CreateUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("create_user", payload.fData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });

    return { status: response.status, statusText: response.statusText }
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const UpdateUser = createAsyncThunk("UpdateUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.put(`update_user/${payload.userId}`, payload.fData, {
      headers: {
        Authorization: `token ${payload.token}`
      }
    })

    return { status: response.status, statusText: response.statusText }
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteUser = createAsyncThunk("deleteUser", async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await api.delete(`delete_user/${id}`, { headers: { Authorization: `token ${token}` } });
    return ({ status: response.status, statusText: response.statusText })
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const FETCH_USER_BY_ID = 'FETCH_USER_BY_ID';

export const fetchUserById = (id, token) => async (dispatch) => {
  try {
    const response = await api.get(`get_user/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    dispatch({
      type: FETCH_USER_BY_ID,
      payload: response.data,
    });
  } catch (error) {
    throw error;
  }
};

const GetUsersReducer = createSlice({
  name: "GetUsers",
  initialState: {
    status: { Get: " ", Create: " ", Delete: " ", Update: " " },
    users: null,
    userDataByID: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetUsers.rejected, (state, action) => {
        state.status.Get = "failed";
        state.error = action.payload.message;
      })
      .addCase(GetUsers.pending, (state, action) => {
        state.status.Get = "loading";

      })
      .addCase(GetUsers.fulfilled, (state, action) => {
        state.status.Get = "succeeded";
        state.users = action.payload;
      })
      .addCase(CreateUser.rejected, (state, action) => {
        state.status.Create = "failed";
        state.error = action.payload.message;
      })
      .addCase(CreateUser.pending, (state, action) => {
        state.status.Create = "loading";
      })
      .addCase(CreateUser.fulfilled, (state, action) => {
        state.status.Create = "succeeded";
      })
      .addCase(UpdateUser.rejected, (state, action) => {
        state.status.Update = "failed";
        state.error = action.payload.message;
      })
      .addCase(UpdateUser.pending, (state, action) => {
        state.status.Update = "loading";
        console.log(action.payload);
      })
      .addCase(UpdateUser.fulfilled, (state, action) => {
        state.status.Update = "succeeded";
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status.Delete = "failed";
        state.error = action.payload.message;
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.status.Delete = "loading";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status.Delete = "succeeded";
      })
      .addCase(FETCH_USER_BY_ID, (state, action) => {
        state.userDataByID = action.payload;
      });
  },
});

export const { } = GetUsersReducer.actions;
export default GetUsersReducer.reducer;
