import React, { useEffect, useState } from "react";
import { BasicTable } from "../../../SharedComponets/Table list/BasicTable";
import { useNavigate } from "react-router-dom";
import courses from "../../../App/Apis/RidingSchoolApis/courseApi/course";
import DropdownMenu from "../../../SharedComponets/Components/DropdownMenu";
import { MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../../../App/Helpers/DeleteModal";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const Courses = () => {
  const [courseList, setCourseList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);

  const UpdateHandler = (id) => {
    navigate(`../course/create_course/${id}`);
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const header = [
    { Header: "Course Name", accessor: "course_name" },
    { Header: "Price", accessor: "price" },
    { Header: "Category", accessor: "category" },
    { Header: "Sub Category", accessor: "sub_category" },
    // { Header: "Child Price", accessor: "child_price" },
    // {
    //     Header: "Description", accessor: "description", Cell: ({ cell }) => {
    //         return <div style={{ width: "40rem" }}>
    //             {cell?.row?.original?.description}
    //         </div>
    //     }
    // },
    {
      Header: "Riding Discipline",
      accessor: "riding_type",
      Cell: ({ cell }) => {
        return cell?.row.original?.riding_type?.name;
      },
    },
    { Header: "Lessons", accessor: "lessons" },
    { Header: "Slot", accessor: "slot" },
    { Header: "Seats", accessor: "seats" },
    {
      Header: "Action",
      accessor: "action",
      show: true,
      Cell: ({ row }) => {
        return (
          <DropdownMenu
            menus={
              <>
                <MenuItem
                  onClick={() => UpdateHandler(row.original.id)}
                  disableRipple
                >
                  <EditIcon />
                  Edit
                </MenuItem>

                <MenuItem
                  onClick={() => deleteHandler(row.original.id)}
                  disableRipple
                >
                  <DeleteIcon sx={{ fontSize: "40px", color: "red" }} />
                  <span>Delete</span>
                </MenuItem>
              </>
            }
          />
        );
      },
    },
  ];

  const fetchingCoursesList = async () => {
    try {
      const res = await courses.getCourseList();
      setCourseList(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchingCoursesList();
  }, []);

  const CreateHandler = () => {
    navigate("/riding-club/course/create_course");
  };

  const deleteData = async () => {
    setLoading(true);
    try {
      await courses.deleteCourse(deleteId);
      fetchingCoursesList();
      toast.success("Course Deleted Successfully");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <BasicTable
        colHeader={header}
        rowData={courseList}
        actionFlag={true}
        updateHandler={UpdateHandler}
        deleteHandler={deleteHandler}
        createHandler={CreateHandler}
        tableHeading="Courses"
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Planner"
        deleteData={deleteData}
      />
    </div>
  );
};

export default Courses;
