

import React from "react";
import CommonEditablePage from "../../../SharedComponets/CommonEditablePage";
import { useSelector } from "react-redux";
import {
  createStableRent,
  getStablerents,
  updateStablerents,
} from "../../../App/Reducers/HeroQuillReducer";
import { stableRent } from "../../../Assets/DefaultImagesCarousel";

const AboutUs = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.stablerents);
  const defaultContent =`Stable rent typically refers to a rental agreement or arrangement in which the amount of rent remains constant or experiences minimal changes over a certain period of time. It is the opposite of volatile or fluctuating rent, which may vary frequently based on market conditions or other factors.
  In a stable rent situation, tenants can anticipate and budget for consistent rental payments without significant increases or surprises. This type of arrangement is often desirable for both tenants and landlords as it provides stability and predictability for financial planning.
  Stable rent can be achieved through various means, such as long-term lease agreements, rent control policies imposed by local governments, or contractual agreements between the landlord and tenant to maintain a fixed rent amount for a specified period.
  It's worth noting that the term "stable rent" may have different interpretations depending on the context and local regulations. Therefore, it's important to consider the specific circumstances and legal framework in a particular jurisdiction when discussing stable rent.`
  return (
    <>
      <CommonEditablePage
        updateApiFunc={updateStablerents}
        getApiFunc={getStablerents}
        createApiFunc = {createStableRent}
        modalSelector={modalSelector}
        default_image_title={stableRent.image_title}
        default_image={stableRent.image}
        default_body_content={defaultContent}
      />
    </>
  );
};

export default AboutUs;
