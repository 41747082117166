import api from "../../Apis";

export const AdminLogin = async (data, { rejectWithValue }) => {
    try {
        const response = await api.post(`user_login`, data, {
            headers: {
                "content-type": "application/json",
            },
        });
      
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}

export const USERLOGOUT =
    async (token) => {
        try {
            const response = await api.post(
                `user_logout`,
                { key: token },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `token ${token}`,
                    },
                }
            );
          
            return { data: response.data, status: response.status, statusText: response.statusText }
        } catch (error) {
            console.log("error.response.data", error.response.data);
         
        }
    }

