import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteFoodPlaner,
  GetFoodPlanerList,
  GetUpdatingFoodPlaner,
} from "../../App/Reducers/FoodPlanerReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { format } from "date-fns";
import { useCookies } from "react-cookie";
import { Badge, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import StableLoading from "../../SharedComponets/Loading/StableLoading";
import DropdownMenu from "../../SharedComponets/Components/DropdownMenu";
import api from "../../Apis";
import DeleteModal from "../../App/Helpers/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../App/Helpers/AlertMessage";
const FoodPlanerList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const State = useSelector((state) => state.FoodPlaner);
  const [status, setStatus] = useState(State.status.Updating);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addPlannerPermission = permissions?.user_permissions
    ? permissions.user_permissions.some((obj) => obj.codename === "add_planer")
    : false;
  const changePlannerPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
      (obj) => obj.codename === "change_planer"
    )
    : false;
  const deletePlannerPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
      (obj) => obj.codename === "delete_planer"
    )
    : false;

  const header = [
    { Header: "Planner Id", accessor: "code" },
    { Header: "CheckIn Horse", accessor: "horse_checkin.horse.name" },
    { Header: "Planner Type", accessor: "planner.name" },
    { Header: "Vitamins Nutrients", accessor: "vitamins_nutrients" },

    {
      Header: "Staff",
      accessor: "staff",

      Cell: ({ cell }) => {
        const staff = cell.value
          .map((item) =>
            item.first_name
              ? `${item.first_name} ${item.last_name} (${item.usertype && item.usertype.name
              })`
              : null
          )
          .join(", ");
        return <span>{staff}</span>;
      },
    },

    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: ({ value }) => {
        const date = new Date(value);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          // second: '2-digit',
          hour12: true,
          timeZone: "Etc/GMT",
        }).format(date);
        return formattedDate;
      },
    },
    {
      Header: "End Date",
      accessor: "end_date",
      Cell: ({ value }) => {
        const date = new Date(value);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          // second: '2-digit',
          hour12: true,
          timeZone: "Etc/GMT",
        }).format(date);
        return formattedDate;
      },
    },
    {
      Header: "Status",
      accessor: "is_done",
      style: { width: "80px" },
      Cell: ({ value }) => {
        const status = String(value) === "true" ? "Completed" : "Pending";

        return (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <Badge
              style={{ position: "absolute" }}
              color={
                status === "Completed"
                  ? "success"
                  : status === "Pending"
                    ? "warning"
                    : "error"
              }
              badgeContent={status}
            />
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      show: changePlannerPermission || isUserType,
      Cell: ({ row }) => {
        const status = String(row.original.is_done);
        return (
          <>
            {status === "false" ? (
              <DropdownMenu
                menus={
                  <>
                    {/* <MenuItem onClick={() => handlePrint(row.original.id)} */}
                    <MenuItem disableRipple>
                      {/* <PrintIcon /> */}
                      {/* <Link to={`/dashboard/quotation/print-quotation/${row.original.id}`} target="_blank">Print</Link> */}
                      {/* <button style={{ border: "none", background: "transparent", cursor: "pointer" }}>Print</button> */}
                    </MenuItem>
                    {/* <MenuItem onClick={(e) => handlePrintFlag(e)}
              disableRipple>
              <PrintIcon />
              <button style={{ border: "none", background: "transparent", cursor: "pointer" }}>Print</button>
            </MenuItem> */}
                    {(changePlannerPermission || isUserType) && (
                      <MenuItem
                        onClick={() => UpdateHandler(row.original.id)}
                        disableRipple
                      >
                        <EditIcon />
                        Edit
                      </MenuItem>
                    )}
                    {/* {row.original.status !== "Rejected" && (<MenuItem onClick={() => RejectHandler(row.original.id)}
              disableRipple
            >
              <CancelIcon />
              <span>Reject</span>
            </MenuItem>)} */}
                    {(changePlannerPermission || isUserType) && (
                      <MenuItem
                        // onClick={() => ConfirmHandler(row.original.id, row.original.status)}
                        onClick={() => IsCompeleteHandler(row.original.id)}
                        disableRipple
                      >
                        <CheckIcon sx={{ fontSize: "40px" }} />
                        <span>Completed</span>
                      </MenuItem>
                    )}
                    {(deletePlannerPermission || isUserType) && (
                      <MenuItem
                        // onClick={() => ConfirmHandler(row.original.id, row.original.status)}
                        onClick={() => deleteHandler(row.original.id)}
                        disableRipple
                      >
                        <DeleteIcon sx={{ fontSize: "40px", color: "red" }} />
                        <span>Delete</span>
                      </MenuItem>
                    )}
                  </>
                }
              />
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  const columns =
    changePlannerPermission || deletePlannerPermission || isUserType
      ? header
      : header.filter((col) => col.Header !== "Action");

  const CreateHandler = () => {
    navigate("/dashboard/planer/create");
  };
  const UpdateHandler = (id) => {
    navigate(`/dashboard/planer/update_planer/${id}`);
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_planer/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(GetFoodPlanerList(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  const IsCompeleteHandler = async (id) => {
    setIsLoading(true);
    try {
      const res = await api.get(`done_planner/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
    } catch (error) {
      console.log("error", error);
    }
    dispatch(GetFoodPlanerList(token));
    setIsLoading(false);
  };

  useEffect(() => {
    setStatus(State.status.Updating);
    if (status === "succeeded") {
      navigate("/dashboard/planer/");
    }
    dispatch(GetFoodPlanerList(token));
  }, [dispatch, State.status.Updating, status, navigate]);

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      <div className="all_table_list">
        {/* {(State.PlanerList && header) ? <BasicTable actionFlag={true} colHeader={header} rowData={State.PlanerList} deleteHandler={DeleteHandler} updateHandler={UpdateHandler}  createHandler={CreateHandler} tableHeading="Planner List"/> : <StableLoading/> } */}
        {isLoading ? (
          <StableLoading />
        ) : State.PlanerList && header ? (
          <BasicTable
            actionFlag={true}
            colHeader={columns}
            rowData={State.PlanerList}
            deleteHandler={deleteHandler}
            updateHandler={UpdateHandler}
            // createHandler={CreateHandler}
            createHandler={
              addPlannerPermission || isUserType ? CreateHandler : null
            }
            tableHeading="Planner"
          />
        ) : (
          <BasicTable
            actionFlag={true}
            colHeader={header}
            rowData={[]}
            deleteHandler={deleteHandler}
            updateHandler={UpdateHandler}
            // createHandler={CreateHandler}
            createHandler={
              addPlannerPermission || isUserType ? CreateHandler : null
            }
            tableHeading="Planner"
          />
        )}

        <DeleteModal
          open={deleteModalOpen}
          handleClose={() => setDeleteModalOpen(false)}
          title="Planner"
          deleteData={deleteData}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default FoodPlanerList;
