
import React from 'react'
import CommonEditablePage from '../../../SharedComponets/CommonEditablePage'
import { useSelector } from 'react-redux'
import { createShowJumping, getShowJumping, updateShowJumping } from '../../../App/Reducers/HeroQuillReducer'
import { showJumping } from '../../../Assets/DefaultImagesCarousel'



const ShowJumping = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.showJumping);
  const defaultContent = `Show jumping, competitive equestrian event in which horse and rider are required to jump, usually within a time limit, a series of obstacles that have been designed for a particular show.

Study the rider's half-seat position as it guides the horse over a vertical fence.
If possible, the horse is warmed up by walking and trotting for at least half an hour before entering the arena. The horse is guided by the rider toward the centre of each obstacle, his speed being contingent upon the breadth of each fence. The fences, which are not more than 1.6 metres (5.25 feet) high, often are placed less than 12 metres (40 feet) apart.`
  return (
    <>
      <CommonEditablePage
        updateApiFunc={updateShowJumping}
        getApiFunc={getShowJumping}
        createApiFunc={createShowJumping}
        modalSelector={modalSelector}
        default_image_title="Show Jumping"
        default_image={showJumping.image}
        default_body_content={defaultContent}
      />
    </>
  )
}

export default ShowJumping