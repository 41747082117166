import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FcOk } from "react-icons/fc";
import courses from "../../App/Apis/RidingSchoolApis/courseApi/course";
import { refresh } from "aos";

const PaymentThankyouPage = () => {
  const {
    courseId,
    sessionId,
    studentId,
    slot_id,
    // childs,
    persons,
    total_price,
    session_id,
  } = useParams();

  const navigate = useNavigate();
  console.log(courseId, sessionId, studentId);
  const [data, setData] = useState("data");

  const createBooking = async () => {
    try {
      const res = await courses.createBooking({
        courseId: courseId,
        sessionId: sessionId,
        studentId: studentId,
        slot_id: slot_id,
        // childs: childs,
        persons: persons,
        total_price: total_price,
      });
      // navigate("/student-dashboard");
    } catch (error) {
      console.log("errorr...", error);
    }
  };
  const createCartBooking = async () => {
    try {
      const data = JSON.parse(localStorage.getItem("cart-payload"));
      console.log("data", data);
      const payload = {
        arr: data,
        sessionId: session_id,
      };
      const res = await courses.createCartBooking(payload);
      console.log("res", res);
      if (res.data.Message === "Booking Created") {
        localStorage.removeItem("Cart");
        localStorage.removeItem("cart-payload");
        navigate("/student-dashboard");
      }
    } catch (error) {
      console.log("errorr...", error);
    }
  };
  const handleKeyDown = (event) => {
    // Prevent F5 key and Ctrl + R from refreshing the page
    if (event.keyCode === 116 || (event.ctrlKey && event.keyCode === 82)) {
      event.preventDefault();
      alert("You cann't refresh page it may ccause payment issue..!");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (session_id) {
        createCartBooking();
      } else {
        console.log("ok");
        createBooking();
        navigate("/student-dashboard");
      }
    }, 3000);

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //   useEffect(() => {
  //     createBooking();
  //   }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
        }}
      >
        <FcOk size={300} />
      </div>
      <h4 style={{ textAlign: "center" }}>Payment Successfull</h4>
      <p style={{ textAlign: "center" }}>Please Do not refresh this page </p>
      <p style={{ textAlign: "center" }}>
        your redirecting to your profile page
      </p>
      {/* <h1>{courseId}</h1>
      <h1>{sessionId}</h1>
      <h1>{studentId}</h1> */}
      {/* <h1>{data}</h1> */}
    </>
  );
};

export default PaymentThankyouPage;
