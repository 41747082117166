import React, { useEffect, useState } from "react";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import api from "../../Apis";
import { getAllConfirmQuotation } from "../../App/Reducers/QuotationReducer";
import {
  deleteTempInpass,
  getTempInData,
} from "../../App/Reducers/Stable Slices/TemperoryInpassSlice";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { Autocomplete, Modal } from "@mui/material";
import { TextField, Button, Grid, Typography } from "@mui/material";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import { useCookies } from "react-cookie";
import { GetUsers } from "../../App/Reducers/UserReducer";
import {
  createTempInData,
  getTempInDataID,
  updateTempInData,
} from "../../App/Reducers/Stable Slices/TemperoryInpassSlice";
import DeleteModal from "../../App/Helpers/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../App/Helpers/AlertMessage";
const TemperoryOutpass = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "3px solid #bebb9e",
    borderRadius: "10px",
    height: "fit-content",
    width: "fit-content",
    overflow: "auto",
    p: 4,
  };

  const textStyle = {
    fontSize: "5px",
    marginTop: " 0.5rem",
    color: "black",
    borderRadius: "12px",
  };

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const dispatch = useDispatch();

  const [updateBtnToggler, setUpdateBtnToggler] = useState(false);
  const [horseName, setHorseName] = useState([]);
  const [createModalToggler, setCreateModalToggler] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const quotation_datas = useSelector(
    (state) => state.quotation.confirmQuotation
  );
  const tempInPassData = useSelector(
    (state) => state.temperory_inpass.TempInData
  );
  const quotationHorse = useSelector(
    (state) => state.temperory_inpass.DropDownData.HorseData
  );
  const staffData = useSelector((state) => state.getUsers.users);
  const UpdatetempOutPassData = useSelector(
    (state) => state.temperory_inpass.getDataById
  );
  const tempOutPassStatus = useSelector(
    (state) => state.temperory_inpass.status.Updating
  );

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addTempInPassPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_temporaryinpass"
      )
    : false;
  const changeTempInPassPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_temporaryinpass"
      )
    : false;
  const deleteTempInPassPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_temporaryinpass"
      )
    : false;

  const [formData, setFormData] = useState({
    reservation: null,
    horse: null,
    staff: null,
    other: null,
    transport: null,
    health_status: null,
    reason: null,
    date: null,
  });

  const getHorseDataByquotationIds = async (id, token) => {
    try {
      const res = await api.get(`get_quotation_tempout_horse/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutoComplete = async (event, value, fieldName) => {
    if (value === null) {
      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,

        // reservation: null,
        // horse: null,
        // staff: null,
        // other: null,
        // transport: null,
        // health_status: null,
        // reason: null,
        // date: null,
      }));
    } else if (fieldName === "reservation") {
      const horseData = await getHorseDataByquotationIds(value.id, token);

      const hData = horseData.map((item) => {
        return { id: item.id, name: item.name };
      });
      setHorseName(() => hData);

      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else {
      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    }
  };

  const HandleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log("name", name);
    console.log("value", value);
    setFormData((preData) => {
      return { ...preData, [name]: value };
    });
  };

  const CreateHandlerCheckin = (id) => {
    dispatch(
      createTempInData({
        Data: formData,
        token: token,
      })
    );
    dispatch(getTempInData(token));
    setCreateModalToggler(false);
  };

  const UpdateHandlerOutpass = () => {
    dispatch(
      updateTempInData({
        id: formData.id,
        token: token,
        UpdatedData: {
          reservation: formData.reservation,
          horse: formData.horse,
          staff: formData.staff,
          other: formData.other,
          transport: formData.transport,
          health_status: formData.health_status,
          reason: formData.reason,
          date: formData.date,
        },
      })
    );
    setCreateModalToggler(false);
  };

  const colHeader = [
    {
      Header: "Reservation",
      accessor: "reservation.name",
    },
    {
      Header: "Horse",
      accessor: "horse.name",
    },

    {
      Header: "Staff",
      accessor: "staff",
      Cell: ({ row }) => {
        const first_name = row.original.staff?.first_name;
        const last_name = row.original.staff?.last_name;
        const usertype = row.original.staff?.usertype.name;
        return first_name ? `${first_name} ${last_name} (${usertype})` : "";
      },
    },
    {
      Header: "Other",

      accessor: "other",
    },
    {
      Header: "Transport",

      accessor: "transport",
    },
    {
      Header: "Health status",

      accessor: "health_status",
    },
    {
      Header: "Reason",

      accessor: "reason",
    },
    {
      Header: "Date and Time",

      accessor: "date",
      Cell: ({ row }) => {
        const date = new Date(row.original.date);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          // second: '2-digit',
          hour12: true,
          timeZone: "Etc/GMT",
        }).format(date);
        return formattedDate;
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllConfirmQuotation(token));
    dispatch(GetUsers(token));
    dispatch(getTempInData(token));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllConfirmQuotation(token));
    dispatch(getTempInData(token));
    dispatch(GetUsers(token));
    dispatch(getTempInData(token));
  }, [createModalToggler]);

  useEffect(() => {
    if (UpdatetempOutPassData) {
    } else {
      setFormData({
        reservation: null,
        horse: null,
        staff: null,
        other: null,
        transport: null,
        health_status: null,
        reason: null,
        date: null,
      });
    }
  }, [UpdatetempOutPassData]);

  const handleUpdate = async (id) => {
    try {
      const res = await api.get(`get_temporary_inpass/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const respo = await api.get(
        `get_quotation_tempout_horse/${res.data.reservation.id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      if (Array.isArray(respo.data)) {
        setHorseName(
          respo.data.map((item) => ({ id: item.id, name: item.name }))
        );
      } else {
        console.error("Response is not an array:", respo);
      }
      console.log("Response from get_quotation_tempout_horse:", respo);
      setFormData({
        id: res.data.id,
        reservation: res.data.reservation.id,
        horse: res.data.horse.id,
        staff: res.data.staff.id,
        other: res.data.other,
        transport: res.data.transport,
        health_status: res.data.health_status,
        reason: res.data.reason,
        date: new Date(res.data.date).toISOString().slice(0, 16),
      });
    } catch (error) {
      console.log("errrororbf", error);
    }
    dispatch(getTempInData(token));
    setUpdateBtnToggler(true);
    setCreateModalToggler(true);
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(
          `delete_temporary_inpass/${deleteId}`,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getTempInData(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  const closeModal = () => {
    setCreateModalToggler(false);
  };

  const handleCreate = () => {
    setFormData({
      reservation: null,
      horse: null,
      staff: null,
      other: null,
      transport: null,
      health_status: null,
      reason: null,
      date: null,
    });
    setUpdateBtnToggler(false);
    setCreateModalToggler(true);
  };

  return (
    <div>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {tempInPassData ? (
        <BasicTable
          colHeader={colHeader}
          actionFlag={true}
          rowData={tempInPassData}
          // updateHandler={handleUpdate}
          updateHandler={
            changeTempInPassPermission || isUserType ? handleUpdate : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteTempInPassPermission || isUserType ? deleteHandler : null
          }
          // createHandler={handleCreate}
          createHandler={
            addTempInPassPermission || isUserType ? handleCreate : null
          }
          tableHeading="Temporary Inpass"
        />
      ) : (
        <BasicTable
          colHeader={colHeader}
          rowData={[]}
          actionFlag={true}
          // updateHandler={handleUpdate}
          updateHandler={
            changeTempInPassPermission || isUserType ? handleUpdate : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteTempInPassPermission || isUserType ? deleteHandler : null
          }
          // createHandler={handleCreate}
          createHandler={
            addTempInPassPermission || isUserType ? handleCreate : null
          }
          tableHeading="Temporary Inpass"
        />
      )}

      {/* ==========================Create/UpdateCheck_in Modal=============================== */}

      <Modal
        open={createModalToggler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll-bar">
          <Button
            sx={{
              minWidth: "30px",
              color: "#302924",
              border: "0px",
              float: "right",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#e0e0e0", // Remove the border on hover
              },
            }}
          >
            <RxCross2
              style={{ color: "#302924", fontSize: "1.7rem" }}
              onClick={closeModal}
            />
          </Button>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#302924", marginBottom: "5vh", fontWeight: "600" }}
          >
            {updateBtnToggler ? (
              <h3>Update Temporary InPass</h3>
            ) : (
              <h3>Generate Temporary InPass</h3>
            )}
          </Typography>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (updateBtnToggler) {
                UpdateHandlerOutpass();
              } else {
                CreateHandlerCheckin();
              }
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  RESERVATION <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    name="reservation"
                    value={
                      formData &&
                      formData.reservation &&
                      quotation_datas &&
                      quotation_datas.find(
                        (item) => item.id === Number(formData.reservation)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "reservation")
                    }
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    disablePortal
                    id="reservation"
                    options={
                      quotation_datas !== undefined
                        ? quotation_datas
                        : [{ id: null, name: "No reservation Data" }]
                    }
                    getOptionLabel={(option) =>
                      `${option.name} (${option.code})`
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        style={textStyle}
                        placeholder="Select reservation"
                        {...params}
                      />
                    )}
                  />
                }
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  HORSE <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  name="horse"
                  value={
                    formData &&
                    formData.horse &&
                    // horseData &&
                    horseName.find((item) => item.id === Number(formData.horse))
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "horse")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  disablePortal
                  id="horse"
                  options={horseName}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      required
                      style={textStyle}
                      placeholder="Select Horse"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  STAFF <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  disableClearable
                  name="staff"
                  value={
                    formData &&
                    formData.staff &&
                    staffData.find((item) => item.id === Number(formData.staff))
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "staff")
                  }
                  disablePortal
                  id="staff"
                  options={staffData}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name} (${
                      option.usertype && option.usertype.name
                    })`
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      style={textStyle}
                      placeholder="Select staff"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>OTHER</label>
                <TextField
                  type="text"
                  name="other"
                  style={textStyle}
                  value={formData.other}
                  onChange={HandleInputChange}
                  fullWidth
                  // required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  TRANSPORT TYPE <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="text"
                  name="transport"
                  style={textStyle}
                  value={formData.transport}
                  onChange={HandleInputChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  HEALTH STATUS <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="text"
                  name="health_status"
                  style={textStyle}
                  value={formData.health_status}
                  onChange={HandleInputChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  DATE AND TIME <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="datetime-local"
                  name="date"
                  style={textStyle}
                  value={formData.date}
                  onChange={HandleInputChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={8} md={8}>
                <label>
                  REASON <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="textarea"
                  name="reason"
                  style={textStyle}
                  value={formData.reason}
                  onChange={HandleInputChange}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },
                float: "right",
                marginTop: "2rem",
                fontSize: "12px",
                marginInline: "0.5rem",
                backgroundColor: "#302924",
              }}
              // onClick={()=>updateBtnToggler ? UpdateHandlerOutpass():CreateHandlerCheckin()}
              type="submit"
            >
              {updateBtnToggler ? "UPDATE" : "GENRATE"}
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },
                float: "right",
                marginTop: "2rem",
                fontSize: "12px",
                marginInline: "0.5rem",
                backgroundColor: "#302924",
              }}
              onClick={closeModal}
              // type="submit"
            >
              Close
            </Button>
          </form>
        </Box>
      </Modal>

      {/* ==========================Create /Update Check_in Modal=============================== */}

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Temporary InPass"
        deleteData={deleteData}
      />
      <ToastContainer />
    </div>
  );
};

export default TemperoryOutpass;
