

import React from "react";
import CommonEditablePage from "../../../SharedComponets/CommonEditablePage";
import { useSelector } from "react-redux";
import {
  createQuatantine,
  getQurantine,
  updateQurantine,
} from "../../../App/Reducers/HeroQuillReducer";
import { quarantine } from "../../../Assets/DefaultImagesCarousel";

const AboutUs = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.qurantine);
  const defaultContent =`Quarantine refers to the isolation and monitoring of horses to prevent the spread of contagious diseases. Quarantine measures are implemented to protect the health and well-being of horses and to minimize the risk of disease transmission within and between equine populations.
  Quarantine protocols can vary depending on the specific circumstances and the regulations of the particular country or region. However, some common aspects of horse quarantine include:
  Isolation Facility: Horses are typically quarantined in a designated facility or area separate from other horses. This facility should have appropriate stabling, fencing, and biosecurity measures in place to prevent contact between quarantined horses and the general horse population.
  Duration: The length of the quarantine period can vary depending on the specific disease and local regulations. It is typically determined by the incubation period of the target disease and can range from a few days to several weeks.
  Health Monitoring: During quarantine, horses are closely monitored for signs of illness. Temperature checks, physical examinations, and laboratory testing may be conducted to detect the presence of any contagious diseases.
  Biosecurity Measures: Strict biosecurity protocols are implemented to prevent the introduction or spread of diseases. These measures may include restricted access to the quarantine facility, disinfection procedures, personal protective equipment (PPE) requirements, and proper waste disposal.
  Movement Restrictions: While in quarantine, horses are generally not allowed to have contact with other horses outside the quarantine facility. Their movement may be restricted to prevent disease transmission.
  It's important to consult with local veterinary authorities, regulatory agencies, or equine health professionals for specific guidelines and requirements regarding horse quarantine in your area. They can provide detailed information on the necessary protocols to follow to ensure the health and safety of horses and prevent the spread of contagious diseases.`
  return (
    <>
      <CommonEditablePage
        updateApiFunc={updateQurantine}
        getApiFunc={getQurantine}
        createApiFunc = {createQuatantine}
        modalSelector={modalSelector}
        default_image_title="Quarantine"
        default_image={quarantine.image}
        default_body_content={defaultContent}
      />
    </>
  );
};

export default AboutUs;
