import { configureStore } from "@reduxjs/toolkit";
import TitleReducer from "../App/Reducers/TitleReducer";
import FlatRaceReducer from "../App/Reducers/FlatRaceReducer";
import Login from "../App/Reducers/LoginReducer";
import ThemeReducer from "../App/Reducers/ThemeReducer";
import HorseRegistrationReducer from "../App/Reducers/HorseRegistrationReducer";
import GeneralReducer from "../App/Reducers/GeneralReducer";
import SharedReducer from "../App/Reducers/SharedReducer";
import HomeCarouselReducer from "../App/Reducers/HomeCarouselReducer";
import FooterReducre from "../App/Reducers/FooterReducre";
import PhotoGallaryReducer from "../App/Reducers/PhotoGallaryReducer";
import VideoGallaryReducer from "../App/Reducers/VideoGallaryReducer";
import ModalReducer from "../App/Reducers/ModalReducer";
import EventReducer from "../App/Reducers/EventReducer";
import TrainerReducer from "../App/Reducers/TrainerReducer";
import FoodPlanerReducer from "../App/Reducers/FoodPlanerReducer";
import MedicalPlanerReducer from "../App/Reducers/MedicalPlanerReducer";
import TrainingPlanerReducer from "../App/Reducers/TrainingPlanerReducer";
import HealthPlanerReducer from "../App/Reducers/HealthPlanerReducer";
import GroomingPlanerReducer from "../App/Reducers/GroomingPlanerReducer";
import FacilityTypeReducer from "../App/Reducers/FacilityTypeReducer";
import ServiceReducer from "../App/Reducers/ServiceReducer";
import BreedReducer from "../App/Reducers/AdminSettingReducer/BreedReducer";
import BreederReducer from "../App/Reducers/AdminSettingReducer/BreederReducer";
import StableClassReducer from "../App/Reducers/AdminSettingReducer/StableClassReducer";
import CategoryMasterReducer from "../App/Reducers/AdminSettingReducer/CategoryMasterReducer";
import DataSetReducer from "../App/Reducers/DataSetReducer";
import LogoReducer from "../App/Reducers/LogoReducer";
import DivisionMasterReducer from "../App/Reducers/AdminSettingReducer/DivisionMasterReducer";
import GenderMasterReducer from "../App/Reducers/AdminSettingReducer/GenderMasterReducer";
import CheckinSlice from "../App/Reducers/Avaiblity Slices/CheckinSlice";
import TrainingMasterReducer from "../App/Reducers/AdminSettingReducer/TrainingMasterReducer";
import ColorMasterReducer from "../App/Reducers/AdminSettingReducer/ColorMasterReducer";
import VisaMasterReducer from "../App/Reducers/AdminSettingReducer/VisaMasterReducer";
import DocumentTypeMasterReducer from "../App/Reducers/AdminSettingReducer/DocumentTypeMasterReducer";
import HealthMasterReducer from "../App/Reducers/AdminSettingReducer/HealthMasterReducer";
import OwnerReducer from "../App/Reducers/OwnerReducer";
import QuotationReducer from "../App/Reducers/QuotationReducer";
import GetUsersReducer from "../App/Reducers/UserReducer";
import CountryReducer from "../App/Reducers/AdminSettingReducer/CountryReducer";
import StallSlice from "../App/Reducers/Stable Slices/StallSlice";
import CellSlices from "../App/Reducers/Stable Slices/CellSlices";
import TemperoryOutpassSlice from "../App/Reducers/Stable Slices/TemperoryOutpassSlice";
import TemperoryInpassSlice from "../App/Reducers/Stable Slices/TemperoryInpassSlice";
import PermanentOutpassSlice from "../App/Reducers/Avaiblity Slices/PermanentOutpassSlice";
import HeroQuillReducer from "../App/Reducers/HeroQuillReducer";
import TaxReducer from "../App/Reducers/AdminSettingReducer/TaxReducer";
import ServiceTypeReducer from "../App/Reducers/ServiceTypeReducer";
import PlannerReducer from "../App/Reducers/PlannerReducer";
import FacilityTreeReducer from "../App/Reducers/FacilityTreeReducer";
import FacilityNameReducer from "../App/Reducers/Stable Slices/FacilityNameReducer";
import RegisterReducer from "../App/Reducers/RegisterReducer";
import RidingTypeReducer from "../App/Reducers/RidingSchoolReducers/RidingType.js";
import RidingTrainerReducer from "../App/Reducers/RidingSchoolReducers/TrainerReducer.js"
import EventTypeReducer from "../App/Reducers/AdminSettingReducer/EventTypeReducer.jsx";
const store = configureStore({
  reducer: {

    Title: TitleReducer,
    Para: FlatRaceReducer,
    data: DataSetReducer,
    Logo: LogoReducer,
    loginStatus: Login,
    registration : RegisterReducer,
    horseRegistration: HorseRegistrationReducer,
    theme: ThemeReducer,
    general: GeneralReducer,
    home: SharedReducer,
    homeCarousel: HomeCarouselReducer,
    Logo: LogoReducer,
    photogallary: PhotoGallaryReducer,
    videogallary: VideoGallaryReducer,
    footer: FooterReducre,
    modal: ModalReducer,
    events: EventReducer,
    trainers: TrainerReducer,
    FoodPlaner: FoodPlanerReducer,
    MedicalPlaner: MedicalPlanerReducer,
    TrainingPlaner: TrainingPlanerReducer,
    HealthPlaner: HealthPlanerReducer,
    GroomingPlaner: GroomingPlanerReducer,
    facilityTypes: FacilityTypeReducer,
    ServiceType: ServiceTypeReducer,
    Planner: PlannerReducer,
    services: ServiceReducer,
    facilityTree: FacilityTreeReducer,
    tax: TaxReducer,
    breed: BreedReducer,
    breeder: BreederReducer,
    stableClass: StableClassReducer,
    categoryMaster: CategoryMasterReducer,
    divisionMaster: DivisionMasterReducer,
    GenderMaster: GenderMasterReducer,
    checkin: CheckinSlice,
    TrainingMaster: TrainingMasterReducer,
    ColorMaster: ColorMasterReducer,
    VisaMaster: VisaMasterReducer,
    DocumentTypeMaster: DocumentTypeMasterReducer,
    HealthMaster: HealthMasterReducer,
    owner: OwnerReducer,
    quotation: QuotationReducer,
    getUsers: GetUsersReducer,
    countries: CountryReducer,
    stall: StallSlice,
    cell: CellSlices,
    temperory_outpass: TemperoryOutpassSlice,
    temperory_inpass: TemperoryInpassSlice,
    permanent_outpass: PermanentOutpassSlice,
    HeroQuill: HeroQuillReducer,
    FacilityName: FacilityNameReducer,
    RidingType:RidingTypeReducer,
    RidingTrainer : RidingTrainerReducer,
    EventType : EventTypeReducer

  },
});

export default store;

