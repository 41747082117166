import React, { useState, useEffect } from "react";
import MenuData from "./SidebarData/MenuItems";
import MenuItems from "./MenuItems";
import RidingSchoolSideBarMenus from '../../../Ridingschool/Utils/SidebarMenuData'
import { useSelector, useDispatch } from "react-redux";
import { ListItemIndex } from "../../../App/Reducers/GeneralReducer";
import "./Sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { siteLogo } from "../../../Assets/Images/logo";
import api, { ImgApi } from "../../../Apis";
import { baseURL, ImgUrl } from "../../../Config/Config";
import { FaTachometerAlt } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import UserDp from "../DashOverview/UserDp";
import AOS from "aos";
import { ShowMobileNavbar } from "../../../App/Reducers/ModalReducer";
import Need from "../Need/Need";
const SideBar = ({ Layout }) => {
  const dispatch = useDispatch();
  const [cookies, removeCookie] = useCookies(["token"]);
  const token = cookies.token;
  const permissions = JSON.parse(localStorage.getItem('userData'))
  const mobileNavbar = useSelector((state) => state.modal.mobileNavbar);
  const activeIndex = useSelector((state) => state.general.index);
  const isloggedIn = useSelector((state) => state.loginStatus.adminloggedIn);
  const islogout = useSelector((state) => state.loginStatus.islogout);
  const [logo, setLogo] = useState(siteLogo);
  const [apilogo, setApilogo] = useState();
  const userData = useSelector((state) => state.loginStatus.userData);
  const [isshowNavbar, setIsShowNavbar] = useState(mobileNavbar);
  const [windowwidth, setWindowWidth] = useState(window.innerWidth);


  const GetLogo = async () => {
    try {
      const response = await api.get(
        `get_logo/${userData && userData.company.id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      setApilogo(response.data.logo);

    } catch (error) {
      // throw error
      console.log(error);
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (isloggedIn) {
      GetLogo();
    }
    AOS.init({
      duration: 650,
      once: true,
    });
  }, []);

  useEffect(() => {
    setIsShowNavbar(mobileNavbar);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobileNavbar, window.innerWidth]);


  useEffect(() => {

  }, [permissions])




  return (
    <>
      <div
        className={
          windowwidth <= 1025
            ? "dashboard-sidebar laptopscreen"
            : "dashboard-sidebar"
        }
        style={
          windowwidth <= 1025
            ? (isshowNavbar
              ? { display: "block" }
              : { display: "none" })
            : ({ display: "block" })
        }
        data-aos="fade-right"
      >
        {windowwidth <= 1025 && (
          <button
            className="cross-btn"
            onClick={() => dispatch(ShowMobileNavbar(false))}
          >
            <ImCross />
          </button>
        )}
        <div className="logo-imgBx">
          {apilogo ? (
            <Link to="/">
              <img src={`${ImgUrl}${apilogo}`} alt="logo"></img>
            </Link>
          ) : (
            <Link to="/">
              <img src={logo} alt="logo"></img>
            </Link>
          )}
        </div>
        {windowwidth <= 1025 && <UserDp />}

        {Layout === 'ridingclub' ?
          (<nav>
            {RidingSchoolSideBarMenus.map((menu, index) => {
              return menu.header ? (
                <>
                  <h2 key={index} className="sidemenu-header">
                    {menu.header}
                  </h2>
                  <ul>
                    {menu.mainmenu.map((menuData, index) => {
                      return (
                        <MenuItems items={menuData} key={index} index={index} />
                      );
                    })}
                  </ul>
                </>
              ) : (
                <ul>
                  <MenuItems items={menu} key={index} />
                </ul>
              );
            })}
          </nav>) :
          (<nav>
            {MenuData.map((menu, index) => {
              return menu.header ? (
                <>
                  <h2 key={index} className="sidemenu-header">
                    {menu.header}
                  </h2>
                  <ul>
                    {menu.mainmenu.map((menuData, index) => {
                      return (
                        <MenuItems items={menuData} key={index} index={index} />
                      );
                    })}
                  </ul>
                </>
              ) : (
                <ul>
                  <MenuItems items={menu} key={index} />
                </ul>
              );
            })}
          </nav>)}
        {
          windowwidth <= 1025 && <Need />
        }
      </div>
    </>
  );
};

export default SideBar;
