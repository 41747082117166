import React, { useEffect, useState } from "react";
import "./Planer.css";
import {
  TextField,
  Button,
  Grid,
  Autocomplete,
  TextareaAutosize,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateTrainingPlaner,
  UpdateTrainingPlaner,
} from "../../App/Reducers/TrainingPlanerReducer";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { GetUsers } from "../../App/Reducers/UserReducer";
import { getHorseData } from "../../App/Reducers/HorseRegistrationReducer";
import { getTrainingMasterData } from "../../App/Reducers/AdminSettingReducer/TrainingMasterReducer";
// import TextareaAutosize from '@mui/base/TextareaAutosize';

const TrainingPlaner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const State = useSelector((state) => state.TrainingPlaner);
  const Horses_list = useSelector((state) => state.horseRegistration.data);
  const Training_type_List = useSelector(
    (state) => state.TrainingMaster.TrainingMasterData
  );
 
  const users = useSelector((state) => state.getUsers.users);
  const [staffs, setStaffs] = useState(users);
  const Data = useSelector((state) => state.TrainingPlaner.UpdatingPlaner);
  const [status, setStatus] = useState(State.status.Create);
  const [tainingType, setTrainingType] = useState(Training_type_List);
  const [trainingData, setTrainingData] = useState({
    horse_id: null,
    training_type: "",
    staff: "",
    other_staff: "",
    price: "",
    discription: "",
    start_date: null,
    end_date: null,
  });
  const colors = ["1", "2", "3", "4", "5"];
  console.log("TrainingPlaner", trainingData);
  const EventHandler = (e) => {
    const { name, value } = e.target;
    setTrainingData({ ...trainingData, [name]: value });
  };
  const EventAutoCompleteHandler = (e, value, fieldName) => {
    setTrainingData({ ...trainingData, [fieldName]: value });
  };
  const CreateHandler = () => {
    dispatch(CreateTrainingPlaner({trainingData , token}));
    if (status === "succeeded") {
      navigate("/dashboard/planer/training_list");
    }
  };
  const UpdateHandler = () => {
    console.log("trainingData", trainingData);
    dispatch(UpdateTrainingPlaner({trainingData, token}));
  };
  useEffect(() => {
    dispatch(getHorseData(token));
    dispatch(GetUsers(token));
    dispatch(getTrainingMasterData(token));
  }, [token]);
  useEffect(() => {
    setStaffs(users);
    setTrainingType(Training_type_List)
    console.log(Training_type_List)
    setStatus(State.status.Create);
    if (Data) {
      setTrainingData({
        id: Data.id,
        horse_id: String(Data.horse_id.id),
        training_type: (Data.training_type.id) ,
        staff: String(Data.staff.id),
        other_staff: Data.other_staff,
        price: String(Data.price),
        discription: Data.discription,
        start_date: Data.start_date
          ? new Date(Data.start_date).toISOString().slice(0, -1)
          : "",
        end_date: Data.end_date
          ? new Date(Data.end_date).toISOString().slice(0, -1)
          : "",
      });
    }
  }, [State.status.Create, Data, users , Training_type_List]);
  return (
    <div className="form-wrapper-container">
    <div className="form-container">
      <h2 className="planer-header">Tainning Planner of Horse</h2>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <label>Horse Name</label>
            <Autocomplete
              id="combo-box-demo"
              name="horse_id"
              value={
                Horses_list &&
                trainingData.horse_id &&
                Horses_list.find(
                  (item) => item.id === Number(trainingData.horse_id)
                )
              }
              onChange={(event, value) =>
                EventAutoCompleteHandler(event, value, "horse_id")
              }
              required
              fullWidth
              options={Horses_list && Horses_list}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField className="bg-color" {...params} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Training Type</label>
            <Autocomplete
              id="combo-box-demo"
              name="training_type"
              value={
                tainingType &&
                trainingData.training_type &&
                tainingType.find(
                  (item) => item.id === Number(trainingData.training_type)
                )
              }
              onChange={(event, value) =>
                EventAutoCompleteHandler(event, value, "training_type")
              }
              required
              fullWidth
              options={tainingType && tainingType}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField className="bg-color" {...params} />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <label>Staff</label>

            <Autocomplete
              name="staff"
              disablePortal
              id="combo-box-demo"
              value={
                staffs &&
                trainingData.staff  &&
                staffs.find((item) => item.id === Number(trainingData.staff))
              }
              onChange={(event, value) =>
                EventAutoCompleteHandler(event, value, "staff")
              }
              required
              fullWidth
              options={staffs && staffs}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              renderInput={(params) => (
                <TextField className="bg-color" {...params} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Others Staff</label>
            <TextField
              type="text"
              name="other_staff"
              value={trainingData.other_staff}
              onChange={EventHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Price</label>
            <TextField
              type="text"
              name="price"
              value={trainingData.price}
              onChange={EventHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <label>Description</label>
            <TextareaAutosize
              rows={4}
              placeholder="Empty"
              value={trainingData.discription}
              name="discription"
              onChange={EventHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Start Date</label>
            <TextField
              type="datetime-local"
              name="start_date"
              onChange={EventHandler}
              value={trainingData.start_date}
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>End Date</label>
            <TextField
              type="datetime-local"
              name="end_date"
              onChange={EventHandler}
              value={trainingData.end_date}
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        {!Data ? (
          <Button
            variant="outlined"
            sx={{
              color: "#fff",
              borderColor: "#302924",
              "&:hover": {
                borderColor: "#302924",
                color: "#302924",
              },

              marginTop: "2rem",
              fontSize: "12px",
              marginInline: "0.5rem",
              backgroundColor: "#302924",
            }}
            onClick={CreateHandler}
          >
            CREATE
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{
              color: "#fff",
              borderColor: "#302924",
              "&:hover": {
                borderColor: "#302924",
                color: "#302924",
              },

              marginInline: "0.5rem",
              marginTop: "2rem",
              fontSize: "12px",
              backgroundColor: "#302924",
            }}
            onClick={UpdateHandler}
          >
            UPDATE
          </Button>
        )}
      </form>
    </div>
    </div>
  );
};

export default TrainingPlaner;
