import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getCheckInData = createAsyncThunk(
  "getCheckInData",
  async (token) => {
    try {
      const res = await api.get("get_all_checkin", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const data = res.data;
      console.log(data, "data getCheckInData==================");
      return data;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);
export const deleteCheckInData = createAsyncThunk(
  "deleteCheckInData",
  async (payload) => {
    try {
      const res = await api.delete(`delete_checkin/${payload.id}`,{
        headers:{
          Authorization:` token ${payload.token}`
        }
      });
      const data = res.data;
      console.log("data deleteCheckInData==================");
      return data;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);
export const createCheckInData = createAsyncThunk(
  "createCheckInData",
  async (payload, { rejectWithValue }) => {
    console.log("payload--->",payload)
    try {
      const res = await api.post(
        "create_checkin",
        payload.formData,

        {
          headers: {
            "Content-Type": "multipart/form-data,application/json",
            Authorization: `token ${payload.token}`,
          },
        }
      );
      const ApiData = res.data;
      console.log("Create response=---------------", ApiData);
      return ApiData;
    } catch (error) {
      console.log(`You got an error ${error}`);
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCheckInDataID = createAsyncThunk(
  "getCheckInDataID",
  async (payload) => {
    try {
      const res = await api.get(`get_checkin/${payload.id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
        },
      });
      const ApiData = res.data;
    
      return ApiData;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);
export const getAllHorse = createAsyncThunk("getAllHorse", async (token) => {
  try {
    const res = await api.get("get_all_horses", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    const ApiData = res.data;
    console.log(ApiData, "Horse Data===========");
    return ApiData;
  } catch (error) {
    console.log(`You got into error : ${error}`);
  }
});
export const getAllStaff = createAsyncThunk("getAllStaff", async (token) => {
  try {
    const res = await api.get("get_all_users", {
      header: {
        Authorization: `token ${token}`,
      },
    });
    const ApiData = res.data;
    console.log(ApiData, "Staff Data===========");
    return ApiData;
  } catch (error) {
    console.log(`You got into error : ${error}`);
  }
});

export const updateCheckInData = createAsyncThunk(
  "updateCheckInData",
  async (payload) => {
    // const gg = "506df827cc61ee7a69400b7904bda3150e8dcce7";
    try {
      console.log(payload);
      const res = await api.put(
        `update_checkin/${payload.id}`,
        payload.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data, application/json",
            // Authorization: `token ${updatedData.token}`,
            Authorization: `token ${payload.token}`,
          },
        }
      );
      const ApiData = res.data;
      console.log(ApiData, "Updated Api data");

      return ApiData;
    } catch (error) {
      console.log(`You got an Error : ${error}`);
    }
  }
);

export const getCellsDetailsByStallCreate = createAsyncThunk(
  "getCellsDetailsByStallCreate",
  async (payload) => {
    try {
      console.log("getCellsDetailsByStallCreate Called", payload);
      const res = await api.get(`get_cell_by_stall_id/${payload.id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
        },
      });

      return res.data;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);

export const getCellsDetailsByStallUpdate = createAsyncThunk(
  "getCellsDetailsByStallUpdate",
  async (payload) => {
    try {
      console.log("getCellsDetailsByStallUpdate Called", payload);
      const res = await api.get(`get_cell_by_stall_id/${payload.id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
        },
      });

      return res.data;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);

const initialState = {
  checkinData: null,
  updateCheckInData: null,
  DropDownData: {
    HorseData: null,
    StaffData: null,
  },

  cellDataCreate: [],
  cellDataUpdate: [],
  status: {
    Get: "",
    Create: "",
    Updating: "",
    Update: "",
    Delete: "",
  },
  errors: {
    Get: "",
    Create: "",
    Update: "",
    Delete: "",
  },
};

const CheckinSlice = createSlice({
  name: "checkin",
  initialState,
  reducers: {
    EmptyCellData(state, action) {
      state.cellData = [];
      console.log(" dispatch(EmptyCellData()); Called===================");
    },
    ResetErr(state) {
      state.errors.Create = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCheckInData.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getCheckInData.fulfilled, (state, action) => {
      state.status.Get = "succeed";
      state.checkinData = action.payload;
      state.status.Create = "";
    });
    builder.addCase(getCheckInData.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(deleteCheckInData.pending, (state) => {
      state.status.Delete = "loading";
    });
    builder.addCase(deleteCheckInData.fulfilled, (state, action) => {
      state.status.Delete = "succeed";
    });
    builder.addCase(deleteCheckInData.rejected, (state) => {
      state.status.Delete = "failed";
    });

    builder.addCase(createCheckInData.pending, (state) => {
      state.status.Create = "loading";
      state.errors.Create = "";
    });
    builder.addCase(createCheckInData.fulfilled, (state, action) => {
      state.status.Create = "succeed";
      state.errors.Create = "";
      // console.log(action.payload);
    });
    builder.addCase(createCheckInData.rejected, (state, action) => {
      state.status.Create = "failed";
      // console.log(action.payload, "======");
      state.errors.Create = action.payload.msg;
    });

    builder.addCase(updateCheckInData.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateCheckInData.fulfilled, (state, action) => {
      state.status.Update = "succeed";
      // console.log(action.payload);
    });
    builder.addCase(updateCheckInData.rejected, (state) => {
      state.status.Update = "failed";
    });

    builder.addCase(getCheckInDataID.pending, (state) => {
      state.status.Updating = "loading";
    });
    builder.addCase(getCheckInDataID.fulfilled, (state, action) => {
      state.status.Updating = "succeed";
      state.updateCheckInData = action.payload;
      console.log("action.payload",action.payload);
    });
    builder.addCase(getCheckInDataID.rejected, (state) => {
      state.status.Updating = "failed";
    });

    // builder.addCase(getAllHorse.pending, (state) => {
    //   state.status.Updating = "loading";
    // });
    builder.addCase(getAllHorse.fulfilled, (state, action) => {
      // state.status.Updating = "succeed";
      state.DropDownData.HorseData = action.payload;
    });
    // builder.addCase(getAllHorse.rejected, (state) => {
    //   state.status.Updating = "failed";
    // });
    // builder.addCase(getAllHorse.pending, (state) => {
    //   state.status.Updating = "loading";
    // });
    builder.addCase(getAllStaff.fulfilled, (state, action) => {
      // state.status.Updating = "succeed";
      state.DropDownData.StaffData = action.payload;
    });
    // builder.addCase(getAllHorse.rejected, (state) => {
    //   state.status.Updating = "failed";
    // });
    builder.addCase(getCellsDetailsByStallCreate.fulfilled, (state, action) => {
      // state.status.Updating = "succeed";
      state.cellDataCreate = action.payload;
    });
    builder.addCase(getCellsDetailsByStallUpdate.fulfilled, (state, action) => {
      // state.status.Updating = "succeed";
      state.cellDataUpdate = action.payload;
    });
  },
});
export default CheckinSlice.reducer;
export const { EmptyCellData, ResetErr } = CheckinSlice.actions;
