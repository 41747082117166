
import React, { useEffect, useState } from "react";
import DropdownItems from "./DropdownItems";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ListItemIndex } from "../../../App/Reducers/GeneralReducer";
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { ShowMobileNavbar } from "../../../App/Reducers/ModalReducer";

const MenuItems = ({ items, index }) => {
  const activeItem = useSelector((state) => state.general.index);
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeIndex, setActiveIndex] = useState(activeItem);
  const [ismenuShow, setIsmenuShow] = useState(false)

  const permissions = JSON.parse(localStorage.getItem('userData'))
  const handleItemClick = (e, title) => {
    setIsmenuShow(!ismenuShow);
    dispatch(ListItemIndex(title));
    localStorage.setItem("activeList", title)
  };

  useEffect(() => {
    setActiveIndex(localStorage.getItem('activeList'))
  })

  return (

    <li className="menu-items" key={index} onClick={(e) => handleItemClick(e, items.title)}>
      {items.submenu ? (
        <>
          <div id={index + items.title}>
            <div>
              <div className="img-Bx">
                <img src={items.img} />
              </div>
              <span>{items.title}</span>
            </div>
            {ismenuShow && activeIndex === items.title ? (
              <MdKeyboardArrowDown className="icon" />
            ) : (
              <MdOutlineKeyboardArrowRight />
            )}
          </div>
          {ismenuShow && activeIndex === items.title ? (
            <DropdownItems submenus={items.submenu} location={location} />
          ) : null}
        </>
      ) : (

        <Link to={items.url}>
          <div
            onClick={() => { dispatch(ShowMobileNavbar(false)); }}
            className={`menu-items2 ${location.pathname === items.url ? "active" : ""}`}
          >
            <div>
              <div className="img-Bx">
                <img style={items.title === "RIDING CLUB" ? { filter: 'brightness(20)' } : {}} src={items.img} />
              </div>
              <span>{items.title}</span>
            </div>
          </div>
        </Link>
      )
      }
    </li >
  );
};

export default MenuItems;
