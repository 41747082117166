import React, { useEffect, useState } from "react";
import MonthCal from "./MonthCal";
import { useSelector, useDispatch } from "react-redux";
import {
  GetEvents,
  GetUpdatingEvent,
  UpdateEvent,
} from "../../../../App/Reducers/EventReducer";
import { EventFormStatus } from "../../../../App/Reducers/ModalReducer";
import { useCookies } from "react-cookie";
import { elements } from "chart.js";

const MonthView = () => {
  const [clickVal, setClickVal] = useState(false);
  const dispatch = useDispatch();
  const update = useSelector((state) => state.modal.update);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [eventDate, setEventDate] = useState([
    "21 June 2023",
    "23 June 2023",
    "20 June 2023",
  ]);
  const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const Events = useSelector((state) => state.events.EventsList);
  let arr = [];
  const [activeEvents, setActiveEvents] = useState(
    Events && Events.map((e, i) => arr.push(e.start_date))
  );

  // console.log(activeEvents, arr, "activeEvents")
  const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const getCurrentMonthDates = () => {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const numDaysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay(); // 0 (Sunday) to 6 (Saturday)
    const dates = [];

    // Determine the offset for the first day of the month
    let offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    // Generate the dates array
    for (let i = 0; i < offset; i++) {
      dates.push(""); // Add empty strings for the preceding days
    }

    for (let i = 1; i <= numDaysInMonth; i++) {
      dates.push(i.toString()); // Add the dates for the current month
    }

    return dates;
  };
  const currentTime = getCurrentTime();
  const calendarDates = getCurrentMonthDates();
  const goToPreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    );
  };

  const goToNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    );
  };
  const updateEvent = (item) => {
    console.log("item", item.id);
    dispatch(GetUpdatingEvent({ id: item.id, token }));
    dispatch(EventFormStatus({ open: true, update: !update }));
  };
  const eventDatas = (d) => {
    console.log("d");
    let cd = new Date(currentDate);
    cd.setDate(d);
    const formattedDate = cd.toISOString().split("T")[0];
    // console.log("Events", Events);
    const filteredEvent =
      Events &&
      Events.filter(
        (item) => formattedDate === item.start_date.substring(0, 10)
      );

    // console.log("filteredEvent", filteredEvent);
    const flag = false;

    const showMorEvents = (event) => {
      // console.log("Event", event);
      event.target.nextSibling.setAttribute("id", "More-Event");
      const elementUL = document.getElementById("More-Event");
      elementUL.style = "display:block;transform: scale(1);";
      window.addEventListener("click", closeEvent);
    };
    const closeEvent = (e) => {
      // console.log('Close', e.target.parentNode);
      const moreEvent = document.getElementById("More-Event");
      // console.log(moreEvent.parentNode.contains(e.target.parentNode));
      if (!moreEvent.parentNode.contains(e.target.parentNode)) {
        moreEvent.removeAttribute("id");
        moreEvent.removeAttribute("style");
        window.removeEventListener("click", closeEvent);
      }
    };
    if (filteredEvent && filteredEvent.length === 1) {
      return (
        <div>
          <p
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              updateEvent(filteredEvent[0]);
            }}
          >
            {filteredEvent[0].name}
          </p>
        </div>
      );
    } else if (filteredEvent && filteredEvent.length > 1) {
      return (
        <div>
          <p style={{ cursor: "pointer" }} onClick={showMorEvents}>
            {" "}
            + {filteredEvent.length} events
          </p>
          {/* <p style={{ cursor: 'pointer' }} > + {filteredEvent.length} events</p> */}
          <ul>
            {filteredEvent.map((item, index) => (
              <li
                key={index}
                onClick={(e) => {
                  updateEvent(item);
                }}
              >
                <div className="event-title">
                  {index + 1}. {item.name}
                  {/* <div className="event-details">
                    <div className="event-time">
                      {item.start_date}
                    </div>
                    <div className="event-time">
                      {item.end_date}
                    </div>
                  </div> */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return null; // Return null if no events are found for the given date
  };

  useEffect(() => {
    dispatch(GetEvents(token));
  }, [dispatch]);

  // useEffect(() => {
  //   const showMorEvents = (e) => {
  //     const elementUL = document.getElementById("more-events")
  //     console.log('elementUL', elementUL)

  //     const isStyle = elementUL?.style?.display
  //     if (isStyle?.toLowerCase() === 'block') {
  //       elementUL.style = 'display:none;transform: scale(.2);'
  //     }
  //     // else {
  //     //   elementUL.style = 'display:block;transform: scale(1);'
  //     // }
  //   }

  //   window.addEventListener("click", showMorEvents);
  //   return () => {
  //     window.removeEventListener("click", showMorEvents);
  //   };
  // }, []);

  return (
    <div>
      <MonthCal
        time={currentTime}
        date={currentDate}
        // month={currentMonth}
        days={daysOfWeek}
        dates={calendarDates}
        goToNextMonth={goToNextMonth}
        goToPreviousMonth={goToPreviousMonth}
        events={eventDatas}
        // setEvent={}
      />
    </div>
  );
};

export default MonthView;
