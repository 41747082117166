import React from 'react'
import { ImgUrl } from '../Config/Config';
import { TextField, Typography, Button, Modal, Box } from '@mui/material'
const HeroSectionModal = (props) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#e0e0e0",
        border: "3px solid #bebb9e",
        borderRadius: "10px",
        boxShadow: 24,
        width: "fit-content",
        height: "fit-content",
        p: 4,
    };
    const modalSelectorId = props.modalSelector && props.modalSelector.find(item => item.id === 1);

    return (
        <>
            <Modal
                open={props.modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Button
                        variant="outlined"
                        sx={{
                            color: "#302924",
                            border: "0px",
                            "&:hover": {
                                border: "0px",
                                backgroundColor: "#e0e0e0",
                                color: "black",
                            },

                            float: "right",
                            padding: "0px",
                            justifyContent: "right",
                            fontSize: "18px",
                        }}
                        onClick={props.closeModal}
                    >
                        <i class="fa fa-close" />
                    </Button>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ color: "#1c1816" }}
                    >
                        EDIT HEADER AND IMAGE
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        type='text'
                        name="image_title"
                        variant="outlined"
                        onChange={props.handleModalInputChange}
                        value={props.formData.image_title && props.formData.image_title.slice(0, 24)}
                        onBlur={props.handleBlur}
                        sx={{
                            width: "98%",
                            margin: "10px",
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgb(190, 187, 158) !important",
                            },
                            "& input": {
                                color: "#302924",
                            },
                        }}
                    />
                    {
                        props.formData.image_title &&
                        <p style={{
                            fontSize: "16px",
                            marginBottom: "10px",
                            color: props.formData.image_title.length > 19 ? "red" : "black"
                        }}>
                            You have {25 - props.formData.image_title.length} characters left!
                        </p>
                    }

                    <div className="img-list">
                        <div className="img-list-left">
                            {modalSelectorId && modalSelectorId.image ? <img src={`${ImgUrl}${modalSelectorId.image}`} /> : "Image not uploded by comapny"}
                        </div>
                        <div className="img-list-right">
                            <input
                                type="file"
                                name="image"
                                onChange={props.handleImageChange}
                            />
                        </div>
                    </div>

                    <Button onClick={props.handleSubmit} sx={{
                        color: "#fff",
                        borderColor: "#302924",
                        "&:hover": {
                            borderColor: "#302924",
                            color: "#302924",
                        },
                        marginTop: "15px",
                        float: "right",
                        fontSize: "12px",
                        backgroundColor: "#302924",
                    }}>Save</Button>
                    <Button onClick={props.closeModal} sx={{
                        color: "#fff",
                        borderColor: "#302924",
                        "&:hover": {
                            borderColor: "#302924",
                            color: "#302924",
                        },
                        marginTop: "15px",
                        float: "right",
                        fontSize: "12px",
                        backgroundColor: "#302924",
                        marginRight: "5px"
                    }}>Close</Button>

                </Box>
            </Modal>
        </>
    )
}

export default HeroSectionModal