import React from "react";
import "./chart.css";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  Legend,
} from "recharts";

export default function LineChartComponent({ customData }) {
  const pdata = [
    {
      name: "Horse 1",
      value: 50,
    },
    {
      name: "Horse 2",
      value: 20,
    },
    {
      name: "Horse 3",
      value: 45,
    },
    {
      name: "Horse 4",
      value: 69,
    },
    {
      name: "Horse 5",
      value: 80,
    },
  ];
  return (
    <div className="chart">
      <div className="chartTitle">
        <h3>Horse breakdown</h3>
        {/* <p>
          Explore insights visually with our Lead Breakdown Chart for a
          comprehensive view of your sales pipeline.
        </p> */}
      </div>
      <ResponsiveContainer width="95%" aspect={5 / 2.5}>
        <LineChart
          data={pdata}
          width={500}
          height={200}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          {/* <Legend /> */}
          <Tooltip />
          <Line
            connectNulls
            type="monotone"
            dataKey="value"
            stroke="#7c5e1e"
            fill="#7c5e1e"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
