// ExcelParser.js
import * as XLSX from 'xlsx';



const convertToArrayOfObject = (arrayOfArrays) => {

    console.log('arrayOfArrays', Object.keys(arrayOfArrays))
    const sheetsName = Object.keys(arrayOfArrays);
    
    
    
    if(sheetsName.length){
           const sheets = {}
        
        sheetsName.map((item) => {
            console.log('arrayOfArrays[item]', arrayOfArrays[item][0])
            const keys = arrayOfArrays[item][0]
    
            // Remove the header row from the array
            const dataWithoutHeader = arrayOfArrays[item].slice(1);
            console.log('dataWithoutHeader',dataWithoutHeader);
    
            // Convert the array of arrays into an array of objects
            const arrayOfObjects = dataWithoutHeader.filter(row => row.length > 0).map(row => {
                const obj = {};
                keys.forEach((key, index) => {
                    obj[key] = row[index];
                });
                return obj;
            });
    
            console.log('arrayOfObjects',arrayOfObjects);
            sheets[item]=arrayOfObjects
        })
        console.log('sheets',sheets);
         return sheets
       }

}

const ParseExcel = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
        reader.onload = (e) => {
            let sheet = [], jsonData = {}
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames;
            console.log(sheetName);
            sheetName && sheetName.map((item, i) => {
                sheet[sheetName[i]] = workbook.Sheets[sheetName[i]];
                jsonData[sheetName[i]] = XLSX.utils.sheet_to_json(sheet[sheetName[i]], {
                     header: 1,
                     blankrows: false,
                     raw: false,
                     NumberFmt:"dd/mm/yyyy" 
                    });
            })
            console.log('data', data);
            console.log('workbook', workbook);
            console.log('sheetName', sheetName);
            console.log('sheet', sheet);
            console.log('jsonData>>>>', jsonData);
            console.log('jsonData', JSON.stringify(jsonData));
            const arrayOfObject = convertToArrayOfObject(jsonData)
            console.log('arrayOfObject', arrayOfObject);
            resolve(arrayOfObject);
        };
        reader.readAsArrayBuffer(file);
    });
};

export default ParseExcel;






    // const convertToArrayOfObject = (arrayOfArrays) => {

    //     // Extract the header row to use as keys for the objects
    //     const keys = arrayOfArrays[0];
    
    //     // Remove the header row from the array
    //     const dataWithoutHeader = arrayOfArrays.slice(1);
    
    //     // Convert the array of arrays into an array of objects
    //     const arrayOfObjects = dataWithoutHeader.filter(row => row.length > 0).map(row => {
    //         const obj = {};
    //         keys.forEach((key, index) => {
    //             obj[key] = row[index];
    //         });
    //         return obj;
    //     });
    
    //     console.log(arrayOfObjects);
    
    //     return arrayOfObjects
    
    // }

// const ParseExcel = async (file) => {
//     const reader = new FileReader();
//     return new Promise((resolve) => {
//         reader.onload = (e) => {
//             const data = new Uint8Array(e.target.result);
//             const workbook = XLSX.read(data, { type: 'array' });
//             const sheetName = workbook.SheetNames[0];
//             const sheet = workbook.Sheets[sheetName];
//             const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
//             console.log('data', data);
//             console.log('workbook', workbook);
//             console.log('sheetName', sheetName);
//             console.log('sheet', sheet);
//             console.log('jsonData', JSON.stringify(jsonData));
//             const arrayOfObject = convertToArrayOfObject(jsonData)
//             console.log('arrayOfObject', arrayOfObject);
//             resolve(arrayOfObject);
//         };
//         reader.readAsArrayBuffer(file);
//     });
// };

// export default ParseExcel;
