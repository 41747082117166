import api from "../../Apis";

export const FetchAllService = async (token) => {
    const fetchData = async () => {
        try {
            const response = await api.get("get_all_services", {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            return { data: response.data, status: response.status, statusText: response.statusText }
        } catch (error) {
            console.log("error.response.data", error.response.data);
            return error.response

        }

    }
    return Promise.all([fetchData()]).then(([data]) => data);
}