import React, { useEffect, useState } from "react";
import "./footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Partners from "./Partners/Partners";
import { ChangeTheme } from "../App/Reducers/ThemeReducer";
import { Link } from "react-router-dom";
import { FaEdit, FaRegCheckCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Edit } from '../App/Reducers/FooterReducre';

function Footer() {
  const [headerdivClass, setHeaderDivClass] = useState("footer");

  const themeSelector = useSelector((state) => state.theme.ThemeColor);
  useEffect(() => {
    switch (themeSelector) {
      case "header-white":
        setHeaderDivClass("footer header-white");
        break;
      case "header-roseate":
        setHeaderDivClass("footer header-roseate");
        break;
      case "header-skyBlue":
        setHeaderDivClass("footer header-skyBlue");
        break;
      case "header-grassy":
        setHeaderDivClass("footer header-grassy");
        break;
      case "header-pink":
        setHeaderDivClass("footer header-pink");
        break;
      case "header-gold":
        setHeaderDivClass("footer header-gold");
        break;
      case "header-orange":
        setHeaderDivClass("footer header-orange");
        break;
      default:
        setHeaderDivClass("footer header-gold ");
        break;
    }
  }, [themeSelector]);
  return (
    <>
      <section style={{ background: "black", marginBottom: "10px" }}>
        <Partners />
      </section>
      <section className={headerdivClass}>
        <div className="footer-container">
          <div
            className="footer-contents"
            style={
              headerdivClass == "footer"
                ? { color: "white" }
                : { color: "black" }
            }
          >
            <h2>COPYRIGHT</h2>
            <p>All copyright reserved by EQI5 2024</p>
            <p>
              Powered by{" "}
              <a href="https://nableinvent.com/" target="_blank">
                NABLE INVENT SOLUTIONS{" "}
              </a>
            </p>
          </div>
          <div
            className="footer-contents"
            style={
              headerdivClass == "footer"
                ? { color: "white" }
                : { color: "black" }
            }
          >
            <h2>CONTACT US</h2>
            <p>
              Tell Administration: <b>07752415058</b>{" "}
            </p>
            <p>
              Tell Riding school: <b> 07752415058</b>{" "}
            </p>

          </div>
          <div
            className="footer-contents"
            style={
              headerdivClass == "footer"
                ? { color: "white" }
                : { color: "black" }
            }
          >
            <h2>FOLLOW US</h2>
            <ul class="social-icon">
              <li>
                <Link to="https://www.facebook.com/nableinvent/?ref=pages_you_manage" class="face" target="_blank">
                  <FacebookIcon sx={{ color: "#1565c0;" }} />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2Fnableinvent" class="twitter" target="_blank">
                  <TwitterIcon sx={{ color: "#428bca" }} />
                </Link>
              </li>

              <li>
                <Link to="https://www.linkedin.com/company/nable-invent-solution/" class="google" target="_blank">
                  <LinkedInIcon sx={{ color: "#0077b5" }} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
