import React, { useEffect, useState } from "react";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import {
  getStallCellData,
  deleteStallData,
  createStallCellData,
  getStallCellDataID,
  updateStallCellData,
  getAllCheckinHorse,
  getAllStall,
} from "../../App/Reducers/Stable Slices/CellSlices";
import {
  getCellsDetailsByStallCreate,
  getCellsDetailsByStallUpdate,
  EmptyCellData,
} from "../../App/Reducers/Avaiblity Slices/CheckinSlice";

import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { BsFillBookmarkCheckFill } from "react-icons/bs";
import { Modal } from "@mui/material";
import { useCookies } from "react-cookie";
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import WarningMessage from "../../SharedComponets/WarningMessage";

const Cell = () => {
  const CellCreateError = useSelector((state) => state.cell.errors.Create);
  const CellUpdateError = useSelector((state) => state.cell.errors.Update);
  const HorseDropDownSelector = useSelector(
    (state) => state.cell.DropDownData.HorseData
  );

  const StallDropDownSelector = useSelector(
    (state) => state.cell.DropDownData.StallData
  );

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const updateSelector = useSelector((state) => state.cell.getDataById);
  const CellDataCreate = useSelector((state) => state.checkin.cellDataCreate);
  const CellDataUpdate = useSelector((state) => state.checkin.cellDataUpdate);

  const [celldata, setCelldata] = useState();

  const [formData, setFormData] = useState({
    Stall: "",
    CellNo: "",
    horse_checkin: "",
  });
  const [formDataUpdate, setFormDataUpdate] = useState({
    Id: "",
    Stall: "",
    CellNo: "",
    horse_checkin: "",
  });
  const HandleInputChange = (e) => {
    console.log("e.target", e.target);
    const name = e.target.name;
    const value = e.target.value;
    if (name === "Stall") {
      dispatch(getCellsDetailsByStallCreate({ id: value, token: token }));
    }
    setFormData((preData) => {
      return { ...preData, [name]: value };
    });
  };
  const HandleUpdateChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormDataUpdate((preData) => {
      return { ...preData, [name]: value };
    });
  };
  const CreateHandlerStall = () => {
    dispatch(
      createStallCellData({
        token: token,
        data: {
          stall: formData.Stall,
          cell_number: formData.CellNo,
          horse_checkin: formData.horse_checkin,
        },
      })
    );
    setCreateModalToggler(false);
  };
  const UpdateHandlerCheckin = () => {
    dispatch(
      updateStallCellData({
        id: formDataUpdate.Id,
        token: token,

        UpdatedData: {
          stall: formDataUpdate.Stall,
          cell_number: formDataUpdate.CellNo,
          horse_checkin: formDataUpdate.horse_checkin,
        },
      })
    );
    setUpdateModalToggler(false);
  };

  const dispatch = useDispatch();
  const StallCellApiData = useSelector((state) => state.cell.stallCellData);

  //   Create Check in modal start
  const [createModalToggler, setCreateModalToggler] = useState(false);
  const [updateModalToggler, setUpdateModalToggler] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    height: "350px",
    overflow: "auto",
    p: 4,
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };
  //   Create Check in modal end

  const colHeader = [
    {
      Header: "Stall Name",
      accessor: "stall.stall_name",
    },
    {
      Header: "Cell Number",
      accessor: "cell_number",
    },
    {
      Header: "Horse Checkin",
      accessor: "horse_checkin.horse_id.name",
    },
    {
      Header: "Date",
      accessor: "created_date",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },
    },
  ];

  const handleUpdate = (id) => {
    dispatch(getStallCellDataID({ id, token }));
    setUpdateModalToggler(true);
  };
  const handleDelete = (id) => {
    console.log(id);
  };

  const closeModal = () => {
    setCreateModalToggler(false);
  };
  const closeUpdateModal = () => {
    setUpdateModalToggler(false);
  };
  const handleCreate = () => {
    setCreateModalToggler(true);
    dispatch(EmptyCellData());
  };

  useEffect(() => {
    console.log("hello");
    dispatch(getStallCellData(token));
    dispatch(getAllStall(token));
    dispatch(getAllCheckinHorse(token));
    setCelldata(CellDataCreate);

    if (updateSelector) {
      dispatch(
        getCellsDetailsByStallUpdate({
          id: updateSelector.stall.id,
          token: token,
        })
      );
      setFormDataUpdate({
        Id: String(updateSelector.id),
        Stall: String(updateSelector.stall.id),
        CellNo: String(updateSelector.cell_number),
        horse_checkin: String(updateSelector.horse_checkin.id),
      });
    }
    return;
  }, [
    dispatch,
    updateSelector,
    createModalToggler,
    updateModalToggler,
    createStallCellData,
    // CellCreateError,
    // CellUpdateError

    // CellData,
  ]);
  return (
    <div>
      {CellCreateError && (
        <WarningMessage warnMsg={CellCreateError} delay={2000} />
      )}
      {CellUpdateError && (
        <WarningMessage warnMsg={CellUpdateError} delay={2000} />
      )}
      {StallCellApiData ? (
        <BasicTable
          colHeader={colHeader}
          rowData={StallCellApiData}
          updateHandler={handleUpdate}
          deleteHandler={handleDelete}
          createHandler={handleCreate}
          tableHeading="Horse Livery"
        />
      ) : (
        <StableLoading />
      )}

      {/* ==========================Create Check_in Modal=============================== */}
      <Modal
        open={createModalToggler}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll-bar">
          <Button
            sx={{
              color: "#302924",
              border: "0px",
              float: "right",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#e0e0e0", // Remove the border on hover
              },
            }}
          >
            <RxCross2
              style={{ color: "#302924", fontSize: "1.7rem" }}
              onClick={closeModal}
            />
          </Button>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: "#302924",
              marginBottom: "5vh",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            <BsFillBookmarkCheckFill
              style={{ fontSize: "15px", marginRight: "5px" }}
            />
            Cell Allotment
          </Typography>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              CreateHandlerStall();
            }}
          >
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={10} md={10} sx={{ fontFamily: "Poppins" }}>
                <FormControl sx={{ width: "100%" }}>
                  <label style={{ fontSize: "15px" }}>Select Stall </label>
                  <Select
                    name="Stall"
                    onChange={HandleInputChange}
                    value={formData.Stall}
                    required
                  >
                    {StallDropDownSelector &&
                      StallDropDownSelector.map((item, i) => {
                        return (
                          <MenuItem value={item.id}>{item.stall_name}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              {CellDataCreate.length > 0 ? (
                <p style={{ fontSize: "15px" }}>
                  This stall has cell capacity of{" "}
                  {CellDataCreate[0].stall.capacity}
                </p>
              ) : formData.Stall ? (
                <p style={{ fontSize: "15px" }}>
                  There is no cell in this stall
                </p>
              ) : null}

              <Grid
                item
                xs={12}
                sm={10}
                md={10}
                sx={{ fontFamily: "Poppins", display: "flex", gap: "5px" }}
              >
                {CellDataCreate ? (
                  CellDataCreate.map((item) => {
                    return (
                      <div
                        style={{
                          height: "40px",
                          width: "40px",

                          border: "1px solid black",
                          fontSize: "13px",

                          borderRadius: "5px",
                          backgroundColor: item.is_empty
                            ? "green"
                            : "#d642428a",
                        }}
                      >
                        Cell No: {item.cell_number}
                      </div>
                    );
                  })
                ) : (
                  <h1>null</h1>
                )}
              </Grid>

              <Grid item xs={12} sm={10} md={10} sx={{ fontFamily: "Poppins" }}>
                <label style={{ fontSize: "15px" }}>Cell Number</label>

                <TextField
                  type="text"
                  name="CellNo"
                  value={formData.CellNo}
                  onChange={HandleInputChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={10} md={10} sx={{ fontFamily: "Poppins" }}>
                <FormControl sx={{ width: "100%" }}>
                  <label style={{ fontSize: "15px" }}>Select Horse</label>
                  <Select
                    name="horse_checkin"
                    value={formData.horse_checkin}
                    onChange={HandleInputChange}
                    required
                  >
                    {HorseDropDownSelector &&
                      HorseDropDownSelector.map((item, i) => {
                        return (
                          <MenuItem value={item.id}>
                            {item.horse_id.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6} sx={{ fontFamily: "Poppins" }}>
                <label style={{ fontSize: "15px" }}>Capacity</label>
                <TextField
                  type="text"
                  name="Capacity"
                  value={formData.Capacity}
                  onChange={HandleInputChange}
                  // onChange={EventHandler}
                  // value={foodData.vitamins_nutrients}
                  fullWidth
                  required
                />
              </Grid> */}
            </Grid>

            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },

                marginTop: "2rem",
                fontSize: "12px",
                marginInline: "0.5rem",
                backgroundColor: "#302924",
              }}
              type="submit"
              // onClick={CreateHandlerStall}
            >
              CREATE
            </Button>
          </form>
        </Box>
      </Modal>
      {/* ==========================Create Check_in Modal=============================== */}
      {/* ==========================Update Check_in Modal=============================== */}
      {updateSelector && (
        <Modal
          open={updateModalToggler}
          onClose={closeUpdateModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="scroll-bar">
            <Button
              sx={{
                color: "#302924",
                border: "0px",
                float: "right",
                padding: "0px",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#e0e0e0", // Remove the border on hover
                },
              }}
            >
              <RxCross2
                style={{ color: "#302924", fontSize: "1.7rem" }}
                onClick={closeUpdateModal}
              />
            </Button>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                color: "#302924",
                marginBottom: "5vh",
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              <BsFillBookmarkCheckFill
                style={{ fontSize: "15px", marginRight: "5px" }}
              />
              Update
            </Typography>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                UpdateHandlerCheckin();
              }}
            >
              <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  sx={{ fontFamily: "Poppins" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <label style={{ fontSize: "15px" }}>Select Stall</label>

                    <Select
                      name="Stall"
                      onChange={HandleUpdateChange}
                      value={formDataUpdate.Stall}
                      required
                    >
                      {StallDropDownSelector &&
                        StallDropDownSelector.map((item, i) => {
                          return (
                            <MenuItem value={item.id}>
                              {item.stall_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                {CellDataUpdate.length > 0 ? (
                  <p style={{ fontSize: "15px" }}>
                    This stall has cell capacity of{" "}
                    {CellDataUpdate[0].stall.capacity}
                  </p>
                ) : (
                  <p style={{ fontSize: "15px" }}>
                    There is no cell in this stall
                  </p>
                )}
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  sx={{ fontFamily: "Poppins", display: "flex", gap: "5px" }}
                >
                  {CellDataUpdate ? (
                    CellDataUpdate.map((item) => {
                      return (
                        <div
                          style={{
                            height: "40px",
                            width: "40px",

                            border: "1px solid black",
                            fontSize: "13px",

                            borderRadius: "5px",
                            backgroundColor: item.is_empty
                              ? "green"
                              : "#d642428a",
                          }}
                        >
                          Cell No: {item.cell_number}
                        </div>
                      );
                    })
                  ) : (
                    <h1>null</h1>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  sx={{ fontFamily: "Poppins" }}
                >
                  <label style={{ fontSize: "15px" }}>Cell Number</label>

                  <TextField
                    type="text"
                    name="CellNo"
                    value={formDataUpdate.CellNo}
                    onChange={HandleUpdateChange}
                    // onChange={EventHandler}
                    // value={foodData.vitamins_nutrients}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  sx={{ fontFamily: "Poppins" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <label style={{ fontSize: "15px" }}>Select Horse</label>
                    <Select
                      name="horse_checkin"
                      value={formDataUpdate.horse_checkin}
                      onChange={HandleUpdateChange}
                      required
                    >
                      {HorseDropDownSelector &&
                        HorseDropDownSelector.map((item, i) => {
                          return (
                            <MenuItem value={item.id}>
                              {item.horse_id.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                variant="outlined"
                sx={{
                  color: "#fff",
                  borderColor: "#302924",
                  "&:hover": {
                    borderColor: "#302924",
                    color: "#302924",
                  },

                  marginTop: "2rem",
                  fontSize: "12px",
                  marginInline: "0.5rem",
                  backgroundColor: "#302924",
                }}
                // onClick={UpdateHandlerCheckin}
                type="submit"
              >
                UPDATE
              </Button>
            </form>
          </Box>
        </Modal>
      )}
      {/* ==========================Update Check_in Modal=============================== */}
    </div>
  );
};

export default Cell;
