import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getDivisionMasterData = createAsyncThunk("getDivisionMasterData", async (token) => {
    try {
        const response = await api.get("get_all_division", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createDivisionMasterData = createAsyncThunk("createDivisionMasterData", async (payload) => {
    try {
        const response = await api.post("create_division", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteDivisionMasterData = createAsyncThunk("deleteDivisionMasterData", async (id, token) => {
    try {
        const response = await api.delete(`delete_division/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateDivisionMasterData = createAsyncThunk("updateDivisionMasterData", async (payload) => {
    console.log("payload", payload)
    try {
        const response = await api.put(`update_division/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateDivisionMasterData = createAsyncThunk("getupdateDivisionMasterData", async (id, token) => {
    try {
        const response = await api.get(`get_division/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const DivisionMasterSlice = createSlice({
    name: "DivisionMaster",
    initialState: {
        status: "",
        DivisionMasterData: null,
        error: null,
        updateDivisionMasterData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getDivisionMasterData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getDivisionMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.DivisionMasterData = action.payload
            
        })
        builder.addCase(getDivisionMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteDivisionMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteDivisionMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteDivisionMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createDivisionMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createDivisionMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createDivisionMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateDivisionMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateDivisionMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateDivisionMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateDivisionMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateDivisionMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateDivisionMasterData = action.payload

        })
        builder.addCase(getupdateDivisionMasterData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default DivisionMasterSlice.reducer;