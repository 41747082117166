
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import PhotoGallary from '../../../SharedComponets/PhotoGallary'
import PhotosModal from './PhotosModal'
import { createPhotoData, getPhotoData } from '../../../App/Reducers/PhotoGallaryReducer'

const Photo = () => {

  const [formData, setFormData] = useState({
    id: null,
    image: null
  })
  const dispatch = useDispatch()

  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;


  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }


  const handleImageChange = (e) => {
    const { files } = e.target;

    if (files) {
      const selectedFile = files[0];
      setFormData((previous) => ({
        ...previous,
        image: selectedFile,
      }));

    }

  }


  const handleSubmit = (e) => {
    if (formData) {
      dispatch(createPhotoData({ formData, token }))
      dispatch(getPhotoData(token))
    }
    dispatch(getPhotoData(token))
  };


  useEffect(() => {
    dispatch(getPhotoData(token))
  }, [])

  return (
    <>
      <PhotoGallary formData={formData} handleModalOpen={handleModalOpen} />
      <PhotosModal formData={formData} handleModalOpen={handleModalOpen} handleModalClose={handleModalClose} modalOpen={modalOpen} handleImageChange={handleImageChange} handleSubmit={handleSubmit} />
    </>
  )
}

export default Photo


