import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdateQuotationData } from '../../App/Reducers/QuotationReducer';
import { Cookies } from 'react-cookie';
import { Link, useParams } from 'react-router-dom';
import StableLoading from '../../SharedComponets/Loading/StableLoading'
import { AiOutlineCaretRight } from 'react-icons/ai';
import numeral from "numeral";


const QuotationProfile = () => {
  const dispatch = useDispatch();
  const { qId } = useParams()

  const cookie = new Cookies();
  const token = cookie.get('token');

  const ReservationData = useSelector((state) => state.quotation.updateQuotationData);
  const NumberFormater = (amount) => { return numeral(amount).format('0,0') }

  useEffect(() => {
    // Make the API call when the component mounts
    dispatch(
      getUpdateQuotationData({
        id: qId,
        token: token,
      })
    );
  }, [dispatch, token]);

  return (
    ReservationData ?
      <>
        <div className="form-wrapper-container" >
          <div data-aos="fade-left" data-aos-duration="1000">
            <div style={{ display: "flex", justifyContent: "center", gap: "15px", alignItems: "center", paddingBlock: "30px 10px" }} className='welcome-area'>
              {/* <img src={`${ImgUrl}${TrainerData && TrainerData.image}`} alt="Owner Profile" style={{ width: "5.5rem", height: "5.5rem", display: "inline-block", borderRadius: "50%" }} /> */}
              <p style={{ display: "block", fontWeight: "bolder", textAlign: "center", width: "30%", borderRadius: "12px", borderBottom: "4px solid #9d8551" }}><span style={{ fontSize: "18px" }}> </span><span style={{ fontSize: "20px", color: "#302924" }}>Reservation Viewing Screen</span></p>

            </div>
            {/* <div style={{display:"flex", alignItems: "start", width:"100%"}}> */}
            <div className="horse-profile-container">

              <div className="profile-details-container">

                <div className="profile-details-detail">

                  <div className="profile-common-layout horse-info" id="h-info"><a href="#h-info" id="h-info" className="bookmark"><h3>Customer Information</h3></a></div>

                  <div className='profile-ditails-single-data-container'>

                    <ul style={{ listStyleType: "none" }}>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Customer Name&nbsp;:&nbsp;</span><span>{ReservationData.name}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Email&nbsp;:&nbsp;</span><span>{ReservationData.email}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Mobile No&nbsp;:&nbsp;</span><span>{ReservationData.phone}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Country&nbsp;:&nbsp;</span><span>{ReservationData.country.name}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;City&nbsp;:&nbsp;</span><span>{ReservationData.city}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Address&nbsp;:&nbsp;</span><span>{ReservationData.address}</span></li>
                    </ul>
                  </div>

                  <div className="profile-common-layout fei-reg" id="fei-reg"><a href="#fei-reg" id="fei-reg" className="bookmark"><h3>Reservation Information</h3></a></div>
                  <div className='profile-ditails-single-data-container'>

                    <ul style={{ listStyleType: "none" }}>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Payment Terms&nbsp;:&nbsp;</span><span>{ReservationData.payment_terms}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;From Date&nbsp;:&nbsp;</span><span>{ReservationData.from_date}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;To Date&nbsp;:&nbsp;</span><span>{ReservationData.to_date}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Total Days&nbsp;:&nbsp;</span><span>{ReservationData.total_days}</span></li>
                    </ul>
                  </div>


                  <div className="profile-common-layout owner-info" id="owner-info"><a href="#owner-info" id="owner-info" className="bookmark"><h3>Services</h3></a></div>
                  <div style={{ width: "100%" }} className='profile-ditails-single-data-container'>
                    <table style={{ width: "90%", margin: "30px 50px" }}>
                      <thead>
                        <tr className='tableHeader  table-list' style={{ textAlign: "center", color: "white" }}>
                          <th>Facility Type</th>
                          <th>Service</th>
                          <th>Gross Amount</th>
                          <th>Quantity</th>
                          <th>Amount</th>
                          <th>Discount</th>
                          <th>Taxable Amount</th>
                          <th>Tax</th>
                          <th>Net Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ReservationData && ReservationData.quotation_header ? ReservationData.quotation_header.map((item) => (

                          <tr className='table-list' key={item.id}>
                            <td>{item.facility.name}</td>
                            <td>{item.service.name}</td>
                            <td>{NumberFormater(item.gross_price || 0)}</td>
                            <td>{item.quantity}</td>
                            <td>{NumberFormater(item.amount || 0)}</td>
                            <td>{NumberFormater(item.discount || 0)}</td>
                            <td>{NumberFormater(item.taxable_amount || 0)}</td>
                            <td>{item.tax.name}</td>
                            <td>{NumberFormater(item.net_amount || 0)}</td>
                          </tr>

                        )) : null}
                      </tbody>
                    </table>

                  </div>


                  <div className="profile-common-layout doc-info" id="doc-info"><a href="#doc-info" id="doc-info" className="bookmark"><h3>Transactions</h3></a></div>
                  <div className='profile-ditails-single-data-container'>
                    <ul style={{ listStyleType: "none" }}>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Total Horse&nbsp;:&nbsp;</span><span>{ReservationData.total_horse}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Total Amount&nbsp;:&nbsp;</span><span>{NumberFormater(ReservationData.total_amount || 0)}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Total Discount&nbsp;:&nbsp;</span><span>{NumberFormater(ReservationData.total_discount || 0)}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Total Taxable Amount&nbsp;:&nbsp;</span><span>{NumberFormater(ReservationData.total_taxable || 0)}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Total Tax&nbsp;:&nbsp;</span><span>{NumberFormater(ReservationData.total_tax || 0)}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Net Amount&nbsp;:&nbsp;</span><span>{NumberFormater(ReservationData.total_net_amount || 0)}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Paid&nbsp;:&nbsp;</span><span>{NumberFormater(ReservationData.paid_amount || 0)}</span></li>
                      <li><span style={{ fontWeight: "500" }}><AiOutlineCaretRight />&nbsp;Balance&nbsp;:&nbsp;</span><span>{NumberFormater(ReservationData.balance_amount || 0)}</span></li>
                    </ul>
                  </div>
                </div>
              </div>


            </div>
          </div >
        </div>
      </>
      :
      <StableLoading />
  );
};

export default QuotationProfile;
