import React from "react";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { Modal } from "@mui/material";
import { TextField, Button, Grid, Typography } from "@mui/material";
const ModalComp = (props) => {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    height: "fit-content",
    width : '30rem',
    overflow: "auto",
    p: 4,
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };

  return (
    <>
      <Modal open={props.modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="scroll-bar">
          <Button
            sx={{
              minWidth: '30px',
              color: "#302924",
              border: "0px",
              float: "right",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
            }}
          >
            <RxCross2 style={{ color: "#302924", fontSize: "1.7rem" }} onClick={props.closeModal} />
          </Button>

          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#302924", marginBottom: "5vh", fontWeight: "600", fontSize: "18px" }}>
            {props.modalData.id ? `UPDATE ${props.heading.toUpperCase()}` : `CREATE ${props.heading.toUpperCase()}`}
          </Typography>

          <form onSubmit={props.createOrUpdateHandler}>
            <Grid xs={12} sm={6} md={4}>

              <Grid item xs={12} sm={6} md={4}>
                <label>{props.label.toUpperCase()}</label>
                <TextField
                  type="text"
                  name="name"
                  onChange={props.handleModalInputChange}
                  value={props.modalData.name}
                  placeholder={`Enter ${props.heading}`}
                  fullWidth
                  required
                  style={{ marginBlock: "6px" }}
                />
              </Grid>

              {/* Add a new Grid item for the TAX PERCENTAGE field */}
              {props.showTaxPercentageField ? <Grid item xs={12} sm={6} md={4}>
                <label>TAX PERCENTAGE</label>
                <TextField
                  type="text"
                  name="rate"
                  onChange={props.handleModalInputChange}
                  value={props.rateFieldData}  // Use rateFieldData prop here
                  placeholder="Ex: 5%"
                  fullWidth
                  required
                  error={props.ErrorValue}
                  helperText={props.ErrorValue ? 'Please enter a valid Tax Percentage.' : ''}
                  style={{ marginBlock: "5px" }}
                />
              </Grid> : null}


            </Grid>
            <Button
              variant="outlined"
              type="submit"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },
                marginTop: "15px",
                float: "right",
                fontSize: "12px",
                backgroundColor: "#302924",
              }}
            // onClick={props.createOrUpdateHandler}
            >
              {props.modalData.id ? "UPDATE" : "CREATE"}
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ModalComp;
