import React, { useState } from 'react'
import './Need.css'
import { AiFillStar } from 'react-icons/ai'
import { MdSupportAgent } from 'react-icons/md'
import FeedbackModal from '../../../App/Helpers/FeedbackModal'
import { useNavigate } from 'react-router-dom'
const Need = () => {
  const [feedOpen, setFeedOpen] = useState(false)
  const feedClose = () => {
    setFeedOpen(false)
  }
  const navigate = useNavigate()
  return (
    <>
      <div className='need-container'>
        <div>
          <h2>Need Help ?</h2>
          <ul>
            <li style={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard/quotation/support')}><a><MdSupportAgent />Support Center</a></li>
            <li style={{ cursor: 'pointer' }} onClick={() => setFeedOpen(true)}><a><AiFillStar />Give us your feedback</a></li>
          </ul>
        </div>
        <div>
          <img></img>
        </div>
      </div>

      <FeedbackModal feedOpen={feedOpen} feedClose={feedClose} />
    </>
  )
}

export default Need