import React, { useEffect, useState } from "react";
import { BasicTable } from "../../../../SharedComponets/Table list/BasicTable";
import Loader from "../../../components/Loader.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import courseApi from "../../../../App/Apis/RidingSchoolApis/courseApi/course";
import CommonModal from "../../../components/Modal";
import studentApi from "../../../../App/Apis/RidingSchoolApis/studentApi/studentAPI";
import slotmapping from "../../../../App/Apis/RidingSchoolApis/slotmappingAPI/slotmapping";
import { Badge } from "@mui/material";

const StudentLessionList = () => {
  const [loading, setLoding] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    horse: null,
    trainer: null,
    schedule_date: null,
    status: null,
  });
  const [lessons, setLessons] = useState([]);
  const navigate = useNavigate();
  const { booking_id, course_id, student_id } = useParams();
  const colHeader = [
    {
      Header: "Lesson",

      accessor: "lesson_no",
    },
    {
      Header: "Student",

      accessor: "student",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.student.first_name
              ? row.original.student.first_name
              : ""}{" "}
            {row.original.student.last_name
              ? row.original.student.last_name
              : ""}
          </span>
        );
      },
    },
    {
      Header: "Course",

      accessor: "course",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.course.course_name
              ? row.original.course.course_name
              : ""}
          </span>
        );
      },
    },
    {
      Header: "Slot",

      accessor: "slot",
      Cell: ({ row }) => {
        return (
          <span>{row.original.slot.time ? row.original.slot.time : ""}</span>
        );
      },
    },
    {
      Header: "Schedule Date",

      accessor: "schedule_date",
      // Cell: ({ row }) => {
      //   return (<span>{row.original.schedule_date !== null ? row.original.schedule_date : 'Not Scheduled'}</span>)
      // },
      Cell: ({ row }) =>
        row.original.schedule_date !== null ? (
          <div style={{ textAlign: "center" }}>
            {row.original.schedule_date}
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <Badge
              style={{ position: "absolute", textWrap: "nowrap" }}
              color="warning"
              badgeContent={
                row.original.schedule_date !== null
                  ? row.original.schedule_date
                  : "Not Scheduled"
              }
            />
          </div>
        ),
    },
    {
      Header: "Horse Name",

      accessor: "horse",
      Cell: ({ row }) => {
        return (
          <span>{row.original.horse.name ? row.original.horse.name : ""}</span>
        );
      },
    },
    {
      Header: "Trainer",
      accessor: "trainer",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.trainer.name ? row.original.trainer.name : ""}
          </span>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Badge
            style={{ position: "absolute" }}
            color={
              row.original.status === "Completed"
                ? "success"
                : row.original.status === "Pending"
                ? "warning"
                : "error"
            }
            badgeContent={row.original.status}
          />
        </div>
      ),
    },
  ];

  const updateHandler = async (id) => {
    setLoding(true);
    try {
      const lesson = lessons.filter((l) => l.id === id)[0];
      console.log(lesson);
      const setDataPayload = {
        id: lesson.id,
        horse: lesson.horse.id,
        trainer: lesson.trainer.id,
        schedule_date:
          lesson.schedule_date !== null ? lesson.schedule_date : null,
        status: lesson.status,
      };
      setModalData(setDataPayload);
      const horseList = await studentApi.all_horse();
      const trainerList = await slotmapping.get_all_trainer();
      // console.log('horseList', horseList.data);

      const updatedFields = [...modalInputFields];
      const horseField = updatedFields.find((field) => field.name === "horse");
      horseField.options = horseList.data;
      horseField.value = lesson.horse.id;
      const trainerField = updatedFields.find(
        (field) => field.name === "trainer"
      );
      trainerField.options = trainerList.data;
      trainerField.value = lesson.trainer.id;
      const scheduleDateField = updatedFields.find(
        (field) => field.name === "schedule_date"
      );
      scheduleDateField.value =
        lesson.schedule_date !== null ? lesson.schedule_date : null;
      const statusField = updatedFields.find(
        (field) => field.name === "status"
      );
      statusField.value = lesson.status;
      setModalInputFields(updatedFields);

      setModalOpen(true);
    } catch (error) {
      console.log("error", error);
    }
    setLoding(false);
  };

  const handleModalInputChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const updatedFields = [...modalInputFields];
    const courseField = updatedFields.find((field) => field.name === name);
    courseField.value = value;
    setModalInputFields(updatedFields);
  };
  const handleAutoComplete = async (event, value, fieldName) => {
    const selectedValue = value ? value.id : null;
    console.log("selectedValue", selectedValue);
    setModalData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValue,
    }));

    const updatedFields = [...modalInputFields];
    const courseField = updatedFields.find((field) => field.name === fieldName);
    courseField.value = selectedValue;
    setModalInputFields(updatedFields);
  };

  const formFields = [
    {
      label: "Horse",
      type: "select",
      name: "horse",
      handleOnChange: handleAutoComplete,
      value: modalData.horse,
      placeholder: "Select horse",
      fullWidth: "fullWidth",
      required: true,
      disabled: false,
      options: [],
    },
    {
      label: "Trainer",
      type: "select",
      name: "trainer",
      handleOnChange: handleAutoComplete,
      value: modalData.trainer,
      placeholder: "Select trainer",
      fullWidth: "fullWidth",
      required: true,
      disabled: false,
      options: [],
    },
    {
      label: "Schedule date",
      type: "date",
      name: "schedule_date",
      handleOnChange: handleModalInputChange,
      value: modalData.schedule_date,
      placeholder: "Schedule date",
      fullWidth: "fullWidth",
      required: true,
      disabled: false,
    },
    {
      label: "Status",
      type: "select",
      name: "status",
      handleOnChange: handleAutoComplete,
      value: modalData.status,
      placeholder: "Select status",
      fullWidth: "fullWidth",
      required: true,
      disabled: false,
      options: [
        { id: "Pending", name: "Pending" },
        { id: "Completed", name: "Completed" },
      ],
    },
  ];
  const [modalInputFields, setModalInputFields] = useState(formFields);
  console.log("modalInputFields", modalInputFields);

  const openModal = () => {
    setModalData(formFields);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalData(formFields);
    setModalOpen(false);
  };

  const createOrUpdateHandler = async (e) => {
    setLoding(true);
    e.preventDefault();
    try {
      if (modalData.id) {
        const updateRes = await courseApi.updateCourseLesson(modalData);
      } else {
      }
      closeModal();
    } catch (error) {
      console.log(error);
    }
    getCourseLession();
    setLoding(false);
  };

  const getCourseLession = async () => {
    const lessionRes = await courseApi.getCourseLessons({
      booking: Number(booking_id),
      course: Number(course_id),
      student: Number(student_id),
    });
    lessionRes.data.sort((a, b) => a.lesson_no - b.lesson_no);
    console.log("lessionRes", lessionRes.data);
    setLessons(lessionRes.data);
  };

  useEffect(() => {
    getCourseLession();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      ></div>
      {lessons ? (
        <BasicTable
          colHeader={colHeader}
          rowData={lessons}
          updateHandler={updateHandler}
          tableHeading="Student Lesson List"
        />
      ) : (
        <BasicTable
          colHeader={colHeader}
          rowData={[]}
          updateHandler={updateHandler}
          tableHeading="Student Lesson List"
        />
      )}
      <CommonModal
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        inputs={modalInputFields}
        label="ADD Lesson"
        heading="Lesson"
      />
      <ToastContainer />
    </div>
  );
};

export default StudentLessionList;
