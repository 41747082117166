import React from 'react'
import setting from "../../../Assets/Images/setting.png";
import quotation from "../../../Assets/Images/quotation.png";
import Calendar from "../../../Assets/Images/calendar.png";
import hamburger from "../../../Assets/Images/hamburger.png";
import home from "../../../Assets/Images/home.png";
import { Link } from 'react-router-dom';
import { ShowMobileNavbar } from '../../../App/Reducers/ModalReducer';
import { useSelector, useDispatch } from 'react-redux'
const MobileSidebar = () => {
  const dispatch = useDispatch()
  return (
    <div style={{ zIndex: "900" }} className='bottom-navbar tab-screen mobile-screen'>
      <div>
        <div>
          <Link to="/dashboard/quotation/quotation_list">
            <img src={quotation} style={{ filter: "invert(100%)" }} />
            <span>Quotation</span>
          </Link>
        </div>
        <div>
          <Link to="/dashboard/events_calendar">
            <img src={Calendar} style={{ filter: "invert(100%)" }} />
            <span>Events</span>
          </Link>
        </div>
        <div onClick={() => dispatch(ShowMobileNavbar(true))}>
          <Link className='hamburger'>
            <img src={hamburger} />
          </Link>
        </div>
        <div>
          <Link to="/">
            <img src={setting} style={{ filter: "invert(100%)" }} />
            <span>Setting</span>
          </Link>
        </div>
        <div>
          <Link to="/dashboard">
            <img src={home} />
            <span>Home</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MobileSidebar