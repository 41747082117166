import React from "react";
import "./chart.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

export default function Chart({ customData }) {
  const data = [
    { name: "Horse 1", total: 50 },
    { name: "Horse 2", total: 60 },
    { name: "Horse 3", total: 80 },
    { name: "Horse 4", total: 66 },
    { name: "Horse 5", total: 45 },
  ];

  return (
    <div className="chart">
      <div className="chartTitle">
        <h3>Horse Average Activity rating</h3>
        {/* <p>
          Visualize team performance with our Average Activity Rating Chart for
          insightful analytics and efficient monitoring
        </p> */}
      </div>

      <ResponsiveContainer width="100%" aspect={5 / 2.5}>
        <BarChart data={data}>
          <XAxis dataKey="name" style={{ fontSize: "0.8rem" }} />
          <YAxis dataKey="total" />
          <Tooltip cursor={{ fill: "transparent" }} />
          <Bar dataKey="total" fill="#7c5e1e" barSize={40} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
