import React, { useState } from 'react';
import './feedback.css'; // Import the CSS file
import { Link } from 'react-router-dom';

const FeedBack = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [satisfaction, setSatisfaction] = useState(0);
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ name, email, message, satisfaction });
        setSubmitted(true);
    };

    const satisfactionOptions = [
        { id: 1, label: "Very satisfied" },
        { id: 2, label: "Satisfied" },
        { id: 3, label: "Neutral" },
        { id: 4, label: "Unsatisfied" },
        { id: 5, label: "Very Unsatisfied" },
    ];

    return (
        <div className='feedback__container'>
            <h1>Course Feedback Form</h1>
            <div className='feedback-form'>
                {!submitted ? (
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            style={{ maxWidth: "560px", minWidth: "560px", minHeight: "160px" }}
                        />
                        <label>Overall Satisfaction:</label>
                        <div className="satisfaction-options">
                            {satisfactionOptions.map((option) => (
                                <label key={option.id}>
                                    <input
                                        type="radio"
                                        name="satisfaction"
                                        value={option.id}
                                        checked={satisfaction === option.id}
                                        onChange={() => setSatisfaction(option.id)}
                                    />
                                    {option.label}
                                </label>
                            ))}
                        </div>
                        <button type="submit" className="submit-btn">Submit Feedback</button> {/* Add className 'submit-btn' */}
                    </form>
                ) : (
                    <div>
                        <h2>Thank you for your feedback!</h2>
                        <p>We appreciate your input.</p>

                        <div className='feedback__bottom'>
                            <Link to={"/student-dashboard"}><button className='feedback__btn'>Back to home</button></Link>
                            <h1>😀</h1>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FeedBack;
