import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  getTrainingMasterData,
  createTrainingMasterData,
  updateTrainingMasterData,
} from "../../App/Reducers/AdminSettingReducer/TrainingMasterReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import ModalComp from "./Modal";
import api from "../../Apis";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../App/Helpers/DeleteModal";
import AlertMessage from "../../App/Helpers/AlertMessage";

const TrainingMaster = () => {
  const dispatch = useDispatch();
  const TrainingMasterData = useSelector(
    (state) => state.TrainingMaster.TrainingMasterData
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const [modalData, setModalData] = useState({
    name: "",
  });

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addTrainingMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_trainingmaster"
      )
    : false;
  const changeTrainingMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_trainingmaster"
      )
    : false;
  const deleteTrainingMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_trainingmaster"
      )
    : false;

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createOrUpdateHandler = () => {
    if (modalData.id) {
      dispatch(
        updateTrainingMasterData({
          id: modalData.id,
          updatedData: {
            name: modalData.name,
          },
          token,
        })
      );
    } else {
      dispatch(createTrainingMasterData({ modalData, token }));
    }
    closeModal();
    dispatch(getTrainingMasterData(token));
  };

  const editHandler = (id) => {
    const editData = TrainingMasterData.find((data) => data.id === id);
    if (editData) {
      setModalData(editData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_training/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getTrainingMasterData(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  const openModal = () => {
    setModalData({
      name: "",
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    dispatch(getTrainingMasterData(token));
  }, [dispatch, token, modalOpen]);

  const header = [
    {
      Header: "Training Master",
      accessor: "name",
    },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>
      {TrainingMasterData ? (
        <BasicTable
          colHeader={header}
          rowData={TrainingMasterData}
          actionFlag={
            deleteTrainingMasterPermission ||
            changeTrainingMasterPermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeTrainingMasterPermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteTrainingMasterPermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addTrainingMasterPermission || isUserType ? openModal : null
          }
          tableHeading="Training Master"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            deleteTrainingMasterPermission ||
            changeTrainingMasterPermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeTrainingMasterPermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteTrainingMasterPermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addTrainingMasterPermission || isUserType ? openModal : null
          }
          tableHeading="Training Master"
        />
      )}

      <ModalComp
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        label="ADD TRAINING MASTER"
        heading="Training Master"
      />
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Training Master"
        deleteData={deleteData}
      />
      <ToastContainer />
    </>
  );
};
export default TrainingMaster;
