import "./HorseRegistration.css";
import {
  Checkbox,
  TextField,
  Button,
  Grid,
  ImageList,
  ImageListItem,
  Autocomplete,
  TextareaAutosize,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getColorMasterData } from "../../App/Reducers/AdminSettingReducer/ColorMasterReducer";
import { getCountries } from "../../App/Reducers/AdminSettingReducer/CountryReducer";
import { getBreedData } from "../../App/Reducers/AdminSettingReducer/BreedReducer";
import { getOwnerData } from "../../App/Reducers/OwnerReducer";
import { GetTrainers } from "../../App/Reducers/TrainerReducer";
import { getDocumentTypeMasterData } from "../../App/Reducers/AdminSettingReducer/DocumentTypeMasterReducer";
import { getBreederData } from "../../App/Reducers/AdminSettingReducer/BreederReducer";
import { getGenderMasterData } from "../../App/Reducers/AdminSettingReducer/GenderMasterReducer";
import { getAllConfirmQuotation } from "../../App/Reducers/QuotationReducer";
import { useCookies } from "react-cookie";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ImgUrl } from "../../Config/Config";
import { FaFilePdf } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Apis";
import { AiFillDelete } from "react-icons/ai";
import CommonLoading from "../../SharedComponets/Loading/CommonLoading";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import { toast } from "react-toastify";
import { GetAllOwner } from "../../App/Apis/OwnerApi";
import { GetAllTrainer } from "../../App/Apis/TrainerApi";
import DeleteModal from "../../App/Helpers/DeleteModal";

const HorseRegistration = () => {
  let updatedHorse = null;

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const { horse_id } = useParams();
  const fData = new FormData();


  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Data Formate
  const today = new Date().toISOString().split("T")[0];

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [index, setIndex] = useState(null)
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imgError, setImgError] = useState(null);
  const [vacPdfError, setVacPdfError] = useState(null);
  const [healthPdfError, setHealthPdfError] = useState(null);
  const [attachDoc, setAttachDoc] = useState(null);

  //Formdata
  const [formData, setFormData] = useState({
    quotation: null,
    name: null,
    color: null,
    birth_origin: null,
    breed: null,
    breeder_master: null,
    dob: null,
    gender: null,
    country_of_residence: null,
    microchip_number: null,
    height: null,
    weight: null,
    ability: null,
    blood_group: null,
    image: null,
    image1: null,
    fei_passport_number: null,
    fei_passport_expiry_date: null,
    fei_registration_number: null,
    owner_id: [],
    trainer_id: [],
    health_file: null,
    health_file1: null,
    vaccine_file: null,
    vaccine_file1: null,
    remarks: null,
    files: [
      {
        // id: 0,
        // document_type: null,
        // issue_date: null,
        // expiry_date: null,
        // uploaded_file: null
      },
    ],
    files1: [],
    remDoc: [],
    checkedvalue: [],
  });
  console.log('formData', formData.health_file);
  //Get data by store
  const colors = useSelector((state) => state.ColorMaster.ColorMasterData);
  const country = useSelector((state) => state.countries.countriesData);
  const breed = useSelector((state) => state.breed.breedData);
  const breeder = useSelector((state) => state.breeder.BreederData);
  const horseGender = useSelector(
    (state) => state.GenderMaster.GenderMasterData
  );
  // const ownerName = useSelector((state) => state.owner.ownerData);
  // const trainerName = useSelector((state) => state.trainers.Trainers);
  const contract = useSelector((state) => state.quotation.confirmQuotation);
  const docType = useSelector(
    (state) => state.DocumentTypeMaster.DocumentTypeMasterData
  );
  const [ownerName, setOwnerName] = useState([]);
  const [trainerName, setTrainerName] = useState([]);

  // Select IMAGE Handler or  onchange values
  const handleImageChange = (e) => {
    const { files } = e.target;

    if (files) {
      const selectedFile = files[0];

      if (!selectedFile) {
        // No file selected, clear any previous errors
        setImgError("");
        return;
      }

      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Invalid file format
        e.target.value = null;
        setFormData((previous) => ({
          ...previous,
          image: null,
        }));
        setImgError(
          "Please upload a file with a .jpg, .jpeg, or .png extension."
        );
      } else if (selectedFile.size > 1024 * 1024) {
        // 1MB
        // File size exceeds 1MB
        e.target.value = null;
        setFormData((previous) => ({
          ...previous,
          image: null,
        }));
        setImgError("Please upload an image that is less than 1MB in size.");
      } else {
        // Valid file format and size, update the form data and clear any errors
        setFormData((previous) => ({
          ...previous,
          image: selectedFile,
        }));
        setImgError("");
      }
    }
  };

  // Select Health and Vaccine PDF Handler or  onchange values
  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;

    if (type === "file") {
      if (name === "health_file" || name === "vaccine_file") {
        if (files.length > 0) {
          const file = files[0];
          const fileSizeLimit = 1 * 1024 * 1024;
          const fileType = file.type;

          if (fileType === "application/pdf") {
            if (file.size <= fileSizeLimit) {
              setFormData((previous) => ({
                ...previous,
                [name]: file,
              }));

              // Clear the PDF error based on the input field name
              if (name === "health_file") {
                setHealthPdfError("");
              } else if (name === "vaccine_file") {
                setVacPdfError("");
              }
            } else {
              e.target.value = null;

              // Set the PDF error based on the input field name
              if (name === "health_file") {
                setHealthPdfError("PDF file size exceeds 1 MB limit");
              } else if (name === "vaccine_file") {
                setVacPdfError("PDF file size exceeds 1 MB limit");
              }
            }
          } else {
            e.target.value = null;

            // Set the PDF error based on the input field name
            if (name === "health_file") {
              setHealthPdfError("Please select a PDF file");
            } else if (name === "vaccine_file") {
              setVacPdfError("Please select a PDF file");
            }
          }
        }
      }
    } else if (name === "checkedvalue") {
      if (checked) {
        setFormData((previous) => ({
          ...previous,
          checkedvalue: [...previous.checkedvalue, value],
        }));

        // Clear the PDF error based on the input field name
        if (name === "health_file") {
          setHealthPdfError("");
        } else if (name === "vaccine_file") {
          setVacPdfError("");
        }
      } else {
        setFormData((previous) => ({
          ...previous,
          checkedvalue: previous.checkedvalue.filter((c) => c !== value),
        }));
      }
    } else {
      // For other input fields, update the corresponding value in the formData state
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  // Handler for Autocomplete changes
  const handleAutoComplete = (event, value, fieldName) => {
    if (fieldName === "owner_id" || fieldName === "trainer_id") {
      const oid = value !== null ? value.map((i) => i.id) : null;
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [fieldName]: oid,
        };
      });
    } else {
      const selectedValue = value ? value.id : null;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    }
  };

  const handleACDocTypeFields = (event, value, field, index) => {
    const updatedFiles = [...formData.files];
    const updatedFile = { ...updatedFiles[index] }; // Create a copy of the object
    updatedFile[field] = value ? value.id : null; // Update the desired property
    updatedFiles[index] = updatedFile; // Replace the object in the array
    setFormData({ ...formData, files: updatedFiles });
  };

  const handleACDocFields = (event, newValue, fieldName) => {
    const actualVal = event.target.value == 0 ? newValue : event.target.value;
    setFormData((prevState) => {
      const updatedDocuments = [...prevState.files];

      updatedDocuments[updatedDocuments.length - 1] = {
        ...updatedDocuments[updatedDocuments.length - 1],
        ...{ [fieldName]: actualVal },
      };

      return {
        ...prevState,
        files: updatedDocuments,
      };
    });
  };

  const docUpload = (event, index) => {
    const file = event.target.files[0];
    const { name, value, type } = event.target;

    setFormData((prevState) => {
      const updatedDocuments = [...prevState.files];
      updatedDocuments[index] = {
        ...updatedDocuments[index],
        [name]: value,
        uploaded_file: file,
      };

      if (type === "file" && name === "uploaded_file") {
        // If it's a file input
        if (event.target.files.length > 0) {
          const file = event.target.files[0];
          const fileSizeLimit = 1 * 1024 * 1024; // 1 MB in bytes
          const fileType = file.type;

          // Check if the file is a PDF
          if (fileType === "application/pdf") {
            // Check if the file size is within the limit
            if (file.size <= fileSizeLimit) {
              // Update the files array in the formData state
              setFormData((previous) => ({
                ...previous,
                files: updatedDocuments,
              }));
              setAttachDoc(" ");
            } else {
              // Handle validation error (e.g., display an error message)
              setAttachDoc("PDF file size exceeds 1 MB limit");
            }
          } else {
            // Handle validation error (e.g., display an error message)
            event.target.value = null;
            setAttachDoc("Please select a PDF file");
          }
        }
      }

      return {
        ...prevState,
        files: updatedDocuments,
      };
    });
  };

  const docRender = () => {
    setFormData((prevState) => ({
      ...prevState,
      files: [
        ...prevState.files,
        {
          // id: formData.documents.id + 1,
          // id: formData.documents[0].id + 1,
          // document_type: null,
          // issue_date: null,
          // expiry_date: null,
          // uploaded_file: []
        },
      ],
    }));
  };


  const handleremove = () => {
    if (horse_id) {

      try {
        api.delete(`delete_horse_file/${formData.files1[index].id}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        setFormData((prevState) => {
          const updateDocs = [...prevState.files1];
          updateDocs.splice(index, 1);
          return {
            ...prevState,
            files1: updateDocs,
          };
        });
      } catch (error) {
        throw error;
      }

    }
  };

  const deleteHandler = async (index) => {
    setIndex(index)
    setDeleteModalOpen(true);
  };
  const handleremove1 = (index) => {
    if (horse_id) {
      setFormData((prevState) => {
        const updateDocs = [...prevState.files];
        updateDocs.splice(index, 1);
        return {
          ...prevState,
          files: updateDocs,
        };
      });
    }
    if (!horse_id && formData.files.length !== 1) {
      setFormData((prevState) => {
        const updateDocs = [...prevState.files];
        updateDocs.splice(index, 1);
        return {
          ...prevState,
          files: updateDocs,
        };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (horse_id) {
      fData.append("quotation", formData.quotation);
      fData.append("name", formData.name);
      fData.append("color", formData.color);
      fData.append("birth_origin", formData.birth_origin);
      fData.append("breed", formData.breed);
      fData.append("breeder_master", formData.breeder_master);
      fData.append("dob", formData.dob);
      fData.append("gender", formData.gender);
      fData.append("country_of_residence", formData.country_of_residence);
      fData.append("microchip_number", formData.microchip_number);
      fData.append("height", formData.height);
      fData.append("weight", formData.weight);
      fData.append("ability", formData.ability);
      fData.append("blood_group", formData.blood_group);
      fData.append("fei_passport_number", formData.fei_passport_number);
      fData.append(
        "fei_passport_expiry_date",
        formData.fei_passport_expiry_date
      );
      fData.append("fei_registration_number", formData.fei_registration_number);
      formData.owner_id.forEach((id) => {
        fData.append("owner_id", id.toString());
      });
      formData.trainer_id.forEach((id) => {
        fData.append("trainer_id", id.toString());
      });
      if (formData.remarks !== null) {
        fData.append("remarks", formData.remarks);
      }
      if (formData.image) {
        fData.append("image", formData.image);
      }
      if (formData.health_file) {
        fData.append("health_file", formData.health_file);
      }
      if (formData.vaccine_file) {
        fData.append("vaccine_file", formData.vaccine_file);
      }

      formData.files.forEach((file) => {
        if (!file.id) {
          fData.append(`document_type`, file.document_type);
          fData.append(`issue_date`, file.issue_date);
          fData.append(`expiry_date`, file.expiry_date);
          fData.append(`uploaded_file`, file.uploaded_file);
        }
      });

      try {
        setLoading(true);
        const response = await api.put(`update_horse/${horse_id}`, fData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "OK" || response.status === "200") {
          setLoading(false);
          navigate("/dashboard/horseagreement/horses_list");
          toast.success("Horse updated successfully");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      fData.append("quotation", formData.quotation);
      fData.append("name", formData.name);
      fData.append("color", formData.color);
      fData.append("birth_origin", formData.birth_origin);
      fData.append("breed", formData.breed);
      fData.append("breeder_master", formData.breeder_master);
      fData.append("dob", formData.dob);
      fData.append("gender", formData.gender);
      fData.append("country_of_residence", formData.country_of_residence);
      fData.append("microchip_number", formData.microchip_number);
      fData.append("height", formData.height);
      fData.append("weight", formData.weight);
      fData.append("ability", formData.ability);
      fData.append("blood_group", formData.blood_group);
      fData.append("image", formData.image);
      fData.append("fei_passport_number", formData.fei_passport_number);
      fData.append(
        "fei_passport_expiry_date",
        formData.fei_passport_expiry_date
      );
      fData.append("fei_registration_number", formData.fei_registration_number);
      fData.append("health_file", formData.health_file);
      fData.append("vaccine_file", formData.vaccine_file);
      if (formData.remarks) {
        fData.append("remarks", formData.remarks);
      }
      formData.owner_id.forEach((id) => {
        fData.append("owner_id", id.toString());
      });
      formData.trainer_id.forEach((id) => {
        fData.append("trainer_id", id.toString());
      });

      formData.files.forEach((file, index) => {
        fData.append(`document_type`, file.document_type);
        fData.append(`issue_date`, file.issue_date);
        fData.append(`expiry_date`, file.expiry_date);
        fData.append(`uploaded_file`, file.uploaded_file);
      });

      try {
        setLoading(true);
        const response = await api.post("create_horse", fData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "Created" || response.status === "201") {
          setLoading(false);
          navigate("/dashboard/horseagreement/horses_list");
          toast.success("Horse created successfully");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleupdateTrainer = async (id) => {
    try {
      setIsLoading(true);
      const response = await api.get(`get_horse/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const updatedHorse = response.data;
      setFormData({
        id: updatedHorse && updatedHorse.id ? updatedHorse.id : null,
        quotation:
          updatedHorse && updatedHorse.quotation.id
            ? updatedHorse.quotation.id
            : null,
        name: updatedHorse && updatedHorse.name ? updatedHorse.name : null,
        color:
          updatedHorse && updatedHorse.color.id ? updatedHorse.color.id : null,
        birth_origin:
          updatedHorse && updatedHorse.birth_origin.id
            ? updatedHorse.birth_origin.id
            : null,
        breed:
          updatedHorse && updatedHorse.breed.id ? updatedHorse.breed.id : null,
        breeder_master:
          updatedHorse && updatedHorse.breeder_master.id
            ? updatedHorse.breeder_master.id
            : null,
        dob: updatedHorse && updatedHorse.dob ? updatedHorse.dob : null,
        gender:
          updatedHorse && updatedHorse.gender.id
            ? updatedHorse.gender.id
            : null,
        country_of_residence:
          updatedHorse && updatedHorse.country_of_residence.id
            ? updatedHorse.country_of_residence.id
            : null,
        microchip_number:
          updatedHorse && updatedHorse.microchip_number
            ? updatedHorse.microchip_number
            : null,
        height:
          updatedHorse && updatedHorse.height ? updatedHorse.height : null,
        weight:
          updatedHorse && updatedHorse.weight ? updatedHorse.weight : null,
        ability:
          updatedHorse && updatedHorse.ability ? updatedHorse.ability : null,
        blood_group:
          updatedHorse && updatedHorse.blood_group
            ? updatedHorse.blood_group
            : null,
        image1: updatedHorse && updatedHorse.image ? updatedHorse.image : null,
        imgFile: updatedHorse && updatedHorse.image ? updatedHorse.image : null,
        fei_passport_number:
          updatedHorse && updatedHorse.fei_passport_number
            ? updatedHorse.fei_passport_number
            : null,
        fei_passport_expiry_date:
          updatedHorse && updatedHorse.fei_passport_expiry_date
            ? updatedHorse.fei_passport_expiry_date
            : null,
        fei_registration_number:
          updatedHorse && updatedHorse.fei_registration_number
            ? updatedHorse.fei_registration_number
            : null,
        owner_id:
          updatedHorse && updatedHorse.owner_id
            ? updatedHorse.owner_id.map((item) => item.id)
            : null,
        trainer_id:
          updatedHorse && updatedHorse.trainer_id
            ? updatedHorse.trainer_id.map((item) => item.id)
            : null,
        health_file1:
          updatedHorse && updatedHorse.health_file
            ? updatedHorse.health_file
            : null,
        PdfFilesHealth:
          updatedHorse && updatedHorse.health_file
            ? updatedHorse.health_file
            : null,
        vaccine_file1:
          updatedHorse && updatedHorse.vaccine_file
            ? updatedHorse.vaccine_file
            : null,
        PdfFilesVaccine:
          updatedHorse && updatedHorse.vaccine_file
            ? updatedHorse.vaccine_file
            : null,
        remarks:
          updatedHorse && updatedHorse.remarks ? updatedHorse.remarks : null,
        files: [],
        files1: updatedHorse && updatedHorse.files ? updatedHorse.files : null,
        PdfFiles:
          updatedHorse && updatedHorse.files ? updatedHorse.files : null,
        checkedvalue: [],
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetAllOwner({ token })
      .then((res) => {
        setOwnerName(res.data);
      })
      .catch((error) => { });
    GetAllTrainer({ token })
      .then((res) => {
        setTrainerName(res.data);
      })
      .catch((error) => { });
  }, [ownerName.hasOwnProperty(), trainerName.hasOwnProperty()]);

  useEffect(() => {
    dispatch(getColorMasterData(token));
    dispatch(getCountries(token));
    dispatch(getBreedData(token));
    dispatch(getBreederData(token));
    dispatch(getOwnerData(token));
    dispatch(GetTrainers(token));
    dispatch(getGenderMasterData(token));
    dispatch(getDocumentTypeMasterData(token));
    dispatch(getAllConfirmQuotation(token));
    AOS.init();
  }, [dispatch, updatedHorse, token]);

  useEffect(() => {
    let updatedHorseId = null;
    try {
      updatedHorseId = location.pathname.split("/")[4];
    } catch { }
    if (updatedHorseId) {
      handleupdateTrainer(updatedHorseId);
    }
  }, []);

  useEffect(() => {
    const sectionId = window.location.hash
    if (sectionId) {
      const section = document.getElementById(sectionId.split("#")[1]);
      console.log("sectionId", sectionId.split("#"));
      console.log("section", section);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [formData]);

  return isLoading ? (
    <StableLoading />
  ) : (
    <div className="form-wrapper-container">
      <div
        className="registration-card"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <header>
          <div className="registration_top_header">
            <p>
              {horse_id ? (
                <p>Update Horse Registration</p>
              ) : (
                <p>Horse Registration</p>
              )}
            </p>
            <span>
              Please fill in all required fields, attach/upload all supporting
              documents to proceed with the registration.
            </span>
          </div>
        </header>

        {/* Form Controllers */}
        <form onSubmit={handleSubmit}>
          {/* Horse Information */}
          <div>
            <div className="horse-information horseregistrationh3">
              <a href="#horse" id="horse" className="bookmark">
                <h3>Horse Information</h3>
              </a>
            </div>
            <div className="inputparts">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    RESERVATION ID <span style={{ color: "red" }}>*</span>
                  </label>

                  <Autocomplete
                    name="quotation"
                    className="autocomplete-mt"
                    options={contract ? contract : []}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.code})`
                    }
                    value={
                      contract &&
                      formData.quotation &&
                      contract.find(
                        (item) => item.id === Number(formData.quotation)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "quotation")
                    }
                    disablePortal
                    id="combo-box-demo"
                    required
                    sx={{ color: "#fff " }}
                    renderInput={(params) => (
                      <TextField
                        required
                        placeholder="Select Contract"
                        {...params}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    HORSE NAME <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    className="inputfield  bg-color"
                    name="name"
                    type="text"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Horse Name"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    COLORS <span style={{ color: "red" }}>*</span>
                  </label>

                  <Autocomplete
                    name="color"
                    value={
                      colors &&
                      formData.color &&
                      colors.find((item) => item.id === Number(formData.color))
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "color")
                    }
                    disablePortal
                    id="combo-box-demo"
                    sx={{ color: "#fff " }}
                    options={colors ? colors : []}
                    getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Color"
                        {...params}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    COUNTRY OF BIRTH <span style={{ color: "red" }}>*</span>
                  </label>

                  <Autocomplete
                    name="birth_origin"
                    value={
                      country &&
                      formData.birth_origin &&
                      country.find(
                        (item) => item.id === Number(formData.birth_origin)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "birth_origin")
                    }
                    disablePortal
                    sx={{ color: "#fff " }}
                    id="combo-box-demo"
                    options={country ? country : []}
                    getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Birth Origin"
                        {...params}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    BREED <span style={{ color: "red" }}>*</span>
                  </label>
                  <Autocomplete
                    name="breed"
                    value={
                      breed &&
                      formData.breed &&
                      breed.find((item) => item.id === Number(formData.breed))
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "breed")
                    }
                    sx={{ color: "#fff " }}
                    disablePortal
                    id="combo-box-demo"
                    options={breed ? breed : []}
                    getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Breed"
                        {...params}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    BREEDER <span style={{ color: "red" }}>*</span>
                  </label>
                  <Autocomplete
                    name="breeder_master"
                    value={
                      breeder &&
                      formData.breeder_master &&
                      breeder.find(
                        (item) => item.id === Number(formData.breeder_master)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "breeder_master")
                    }
                    sx={{ color: "#fff " }}
                    disablePortal
                    id="combo-box-demo"
                    options={breeder ? breeder : []}
                    getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Breeder"
                        {...params}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    DATE OF BIRTH <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    className="bg-color"
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    required
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: today }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    GENDER <span style={{ color: "red" }}>*</span>
                  </label>
                  <Autocomplete
                    name="gender"
                    value={
                      horseGender &&
                      formData.gender &&
                      horseGender.find(
                        (item) => item.id === Number(formData.gender)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "gender")
                    }
                    disablePortal
                    id="combo-box-demo"
                    options={horseGender ? horseGender : []}
                    getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                    sx={{ color: "#fff " }}
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Gender"
                        {...params}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    COUNTRY OF RESIDENCE <span style={{ color: "red" }}>*</span>
                  </label>

                  <Autocomplete
                    name="country_of_residence"
                    value={
                      country &&
                      formData.country_of_residence &&
                      country.find(
                        (item) =>
                          item.id === Number(formData.country_of_residence)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "country_of_residence")
                    }
                    disablePortal
                    id="combo-box-demo"
                    options={country ? country : []}
                    getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                    sx={{ color: "#fff " }}
                    renderInput={(params) => (
                      <TextField
                        className="bg-color"
                        required
                        placeholder="Select Country of Residence"
                        {...params}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    MICROCHIP NUMBER <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    className="bg-color"
                    placeholder="Microchip Number"
                    name="microchip_number"
                    value={formData.microchip_number}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    HORSE HEIGHT (Feet/Inch)
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    className="bg-color"
                    type="number"
                    placeholder="Ex: 5.2"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    HORSE WEIGHT (kg)<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    className="bg-color"
                    type="number"
                    placeholder="Ex: 56kg"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    SPECIAL ABILITIES <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    className="bg-color"
                    placeholder="Ex: Jumping Ability"
                    name="ability"
                    value={formData.ability}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    BLOOD GROUP <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    className="bg-color"
                    placeholder="Ex: Aa"
                    name="blood_group"
                    value={formData.blood_group}
                    onChange={handleChange}
                    required
                    fullWidth
                    inputProps={{
                      maxLength: 6,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          {/* FEI Registration */}
          <div className="horse-fei-registration horseregistrationh3">
            <a href="#fei" id="fei" className="bookmark">
              <h3>FEI Registration</h3>
            </a>
          </div>
          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  FEI PASSPORT NUMBER <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="bg-color"
                  required
                  placeholder="Ex: 12345678"
                  value={formData.fei_passport_number}
                  name="fei_passport_number"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  FEI PASSPORT EXPIRY DATE{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="bg-color"
                  placeholder="FEI Password Expiry Date"
                  required
                  type="date"
                  value={formData.fei_passport_expiry_date}
                  name="fei_passport_expiry_date"
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: today }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  FEI REGISTRATION NUMBER{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="bg-color"
                  placeholder="FEI Registration Number"
                  value={formData.fei_registration_number}
                  name="fei_registration_number"
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </div>

          {/* Owner Information */}
          <div className="horse-owner-information horseregistrationh3">
            <a href="#owner" id="owner" className="bookmark">
              <h3>Owner Information</h3>
            </a>
          </div>
          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={formData.owner_id.length < 3 ? 4 : 12}
              >
                <label>
                  SELECT OWNER <span style={{ color: "red" }}>*</span>
                </label>
                {ownerName ? (
                  <Autocomplete
                    name="owner_id"
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "owner_id")
                    }
                    value={
                      ownerName &&
                      formData.owner_id &&
                      ownerName.filter((item, i) => {
                        return formData.owner_id.includes(item.id);
                      })
                    }
                    disablePortal
                    multiple
                    id="owner_id"
                    options={
                      ownerName
                        ? ownerName.map((item) => {
                          return { id: item.id, name: item.name };
                        })
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        // required
                        className="bg-color"
                        placeholder="Select Owner"
                        {...params}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    name="owner_id"
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "owner_id")
                    }
                    // value={ownerName && formData.owner_id && ownerName.filter((item, i) => { return formData.owner_id.includes((item.id)) })}
                    disablePortal
                    multiple
                    id="owner_id"
                    options={
                      ownerName
                        ? ownerName.map((item) => {
                          return { id: item.id, name: item.name };
                        })
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        className="bg-color"
                        placeholder="Select Owner"
                        {...params}
                      // required
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </div>

          {/* Trainer Information */}
          <div className="horse-trainer-information horseregistrationh3">
            <a href="#trainer" id="trainer" className="bookmark">
              <h3>Trainer Information</h3>
            </a>
          </div>
          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={formData.trainer_id.length < 3 ? 4 : 12}
              >
                <label>
                  SELECT TRAINER <span style={{ color: "red" }}>*</span>
                </label>
                {trainerName ? (
                  <Autocomplete
                    name="trainer_id"
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "trainer_id")
                    }
                    disablePortal
                    multiple
                    id="trainer_id"
                    value={
                      trainerName &&
                      formData.trainer_id &&
                      trainerName.filter((item, i) => {
                        return formData.trainer_id.includes(item.id);
                      })
                    }
                    options={trainerName ? trainerName : []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        // required
                        className="bg-color"
                        placeholder="Select Trainer"
                        {...params}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    name="trainer_id"
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "trainer_id")
                    }
                    disablePortal
                    multiple
                    id="trainer_id"
                    // value={trainerName && formData.trainer_id && trainerName.filter((item, i) => { return formData.trainer_id.includes((item.id)) })}
                    options={trainerName ? trainerName : []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        // required
                        className="bg-color"
                        placeholder="Select Trainer"
                        {...params}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </div>

          {/* Upload Photo */}
          <div className=" horse-upload-photo horseregistrationh3">
            <a href="#photo" id="photo" className="bookmark">
              <h3>Upload Photo</h3>
            </a>
          </div>
          <div className="inputparts">
            <span style={{ color: "red", fontSize: "13px" }}>
              Documents size requirements
            </span>
            <br />
            <p
              className="horseRegistration"
              style={{ fontWeight: "bolder", fontSize: "15px" }}
            >
              The Maximum document / file size allowed is: 1MB
            </p>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <div className="App">
                  <label htmlFor="upload-photo">
                    <TextField
                      className="bg-color"
                      id="upload-photo"
                      name="image"
                      type="file"
                      required={!horse_id}
                      accept=".jpg, .jpeg, .png"
                      onChange={handleImageChange}
                      style={{ width: "100%" }}
                    />
                  </label>
                </div>
                <span style={{ color: "red", fontSize: "13px" }}>
                  {imgError}
                </span>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <div style={{ marginBlock: "5%" }}>
                  <ImageList
                    sx={{ width: 400, height: "auto" }}
                    cols={2}
                    rowHeight={"auto"}
                  >
                    <ImageListItem>
                      {formData.imgFile && (
                        <img
                          src={`${ImgUrl}${formData.imgFile}`}
                          alt="Uploaded file"
                          loading="lazy"
                        />
                      )}
                      {formData.image && (
                        <img
                          src={URL.createObjectURL(formData.image)}
                          alt="Uploaded file"
                          loading="lazy"
                        />
                      )}
                    </ImageListItem>
                  </ImageList>
                </div>
              </Grid>
            </Grid>
          </div>

          {/* Upload Documents */}
          <div className="horse-upload-document horseregistrationh3">
            <a href="#doc" id="doc" className="bookmark">
              <h3>Upload Documents</h3>
            </a>
          </div>

          <div className="inputparts">
            {horse_id && (
              <table style={{ width: "100%" }}>
                <thead style={{ textAlign: "left" }}>
                  <tr>
                    <th>Document Type</th>
                    <th>Issue Date</th>
                    <th>Expiry Date</th>
                    <th style={{ width: "100px" }}>Download Link</th>
                    <th></th>
                  </tr>
                </thead>

                {formData.files1.map((item, index) => {
                  return (
                    <>
                      <tbody>
                        <tr>
                          <td>
                            <span>{item.document_type.name}</span>
                          </td>
                          <td>
                            <span>{item.issue_date}</span>
                          </td>
                          <td>
                            <span style={{
                              color:
                                new Date(
                                  item.issue_date
                                ) < new Date()
                                  ? "#ff0000"
                                  : "black",
                            }}>{item.expiry_date}</span>
                          </td>
                          <td>
                            <a
                              href={`${ImgUrl}${item.uploaded_file}`}
                              download
                              target="_blank"
                              className="pdfCard" rel="noreferrer"
                            >
                              <div>
                                <Link
                                  to={`${ImgUrl}${item.uploaded_file}`}
                                  download
                                  target="_blank"
                                  className="pdfCard"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      border: "1px solid  #9f8754",
                                      marginBottom: "5px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "1.3rem",
                                        color: "white",
                                        background:
                                          "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                                        borderRadiusTopleft: "2px",
                                        borderRadiusBottomleft: "2px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "35px",
                                        height: "35px",
                                      }}
                                    >
                                      <FaFilePdf />
                                    </div>

                                    <div
                                      style={{
                                        maxWidth: "250px",
                                        padding: "2px 10px",
                                        fontSize: "0.8rem",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {item.uploaded_file &&
                                        formData.files1[
                                          index
                                        ].uploaded_file.split(
                                          "/media/horse_file/"
                                        )[1]}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </a>
                          </td>
                          <td>
                            <Button
                              className="addbtn"
                              title="Add Fields"
                              onClick={() => deleteHandler(index)}
                            >
                              <AiFillDelete
                                style={{ color: "red", fontSize: "15px" }}
                              />
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </>
                  );
                })}
              </table>
            )}

            {formData.files?.map((document, index) => (
              <div
                style={{ marginTop: "8px" }}
                className="upload_document"
                key={index}
              >
                <Button
                  className="addbtn"
                  title="Remove Fields"
                  onClick={() => handleremove1(index)}
                >
                  <ClearIcon style={{ color: "red", fontSize: "15px" }} />
                </Button>

                <Grid container spacing={2} key={index} sx={{ mb: "10px" }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>
                      SELECT DOCUMENT TYPE{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    {docType && (
                      <Autocomplete
                        name="document_type"
                        value={
                          docType &&
                          docType.find(
                            (item) => item.id === Number(document.document_type)
                          )
                        }
                        onChange={(event, value) =>
                          handleACDocTypeFields(
                            event,
                            value,
                            `document_type`,
                            index
                          )
                        }
                        disablePortal
                        id="combo-box-demo"
                        options={docType ? docType : []}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            required
                            className="bg-color"
                            {...params}
                            placeholder="Select Document Type"
                          />
                        )}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <label>
                      Issue Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      className="bg-color"
                      type="date"
                      value={document.issue_date}
                      // name={`issue_date${index}`}
                      inputProps={{ max: today }}
                      name={`issue_date`}
                      onChange={(event, value) =>
                        handleACDocFields(event, value, `issue_date`)
                      }
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <label>
                      Expiry Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      className="bg-color"
                      type="date"
                      value={document.expiry_date}
                      name={`expiry_date`}
                      inputProps={{ min: today }}
                      onChange={(event, value) =>
                        handleACDocFields(event, value, `expiry_date`)
                      }
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <label htmlFor={`upload-photo-${index}`}>
                      Attach File <span style={{ color: "red" }}>*</span>
                      <TextField
                        // error={attachDoc}
                        className="bg-color"
                        name={`uploaded_file`}
                        type="file"
                        id={`upload-photo`}
                        required
                        onChange={(event) => docUpload(event, index)}
                        style={{ width: "100%" }}
                      // helperText={attachDoc}
                      />
                    </label>
                    <span style={{ color: "red", fontSize: "13px" }}>
                      {attachDoc}
                    </span>
                  </Grid>
                </Grid>
              </div>
            ))}

            <Button
              title="Add Fields"
              onClick={docRender}
              style={{
                backgroundColor: "#826527",
                color: "white",
                fontSize: "12px",
                float: "right",
                marginBlock: "10px",
              }}
            >
              <AddIcon style={{ fontSize: "15px", fontWeight: "bold" }} />
              &nbsp;ADD
            </Button>
          </div>

          {/* Health Documents */}
          <div className=" horseregistrationh3 horse-registration-healthfiles">
            <a href="#healthdoc" id="healthdoc" className="bookmark">
              <h3>Health Document</h3>
            </a>
          </div>
          <div className="inputparts">
            <div style={{ marginBlock: "10px" }}>
              <span
                style={{
                  color: "red",
                  fontSize: "13px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Documents size requirements
              </span>
              <br />
              <p
                className="horseRegistration"
                style={{
                  fontWeight: "bolder",
                  fontSize: "15px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                The Maximum document / file size allowed is: 1MB
              </p>
            </div>

            <Grid container spacing={12}>
              <Grid item xs={6} sm={6} md={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "85%" }}>
                    <label htmlFor="upload-health-file">
                      HEALTH DOCUMENTS <span style={{ color: "red" }}>*</span>
                      <TextField
                        className="bg-color"
                        id="upload-health-file"
                        name="health_file"
                        type="file"
                        multiple
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        required={!horse_id}
                      />
                    </label>
                    <span style={{ color: "red", fontSize: "13px" }}>
                      {healthPdfError}
                    </span>
                  </div>
                </div>
                {horse_id ? (
                  formData.PdfFilesHealth && (
                    <Grid>
                      <Link
                        to={`${ImgUrl}${formData.PdfFilesHealth}`}
                        download
                        target="_blank"
                        className="pdfCard"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            border: "1px solid  #9f8754",
                            borderRadius: "3px",
                            width: "min-content",
                            marginBlock: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "1.3rem",
                              color: "white",
                              background:
                                "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                              borderRadiusTopleft: "2px",
                              borderRadiusBottomleft: "2px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            <FaFilePdf />
                          </div>
                          <div
                            style={{ padding: "0px 10px", fontSize: "0.8rem" }}
                          >
                            {formData.PdfFilesHealth &&
                              formData.PdfFilesHealth.split(
                                "/media/horse_health_File/"
                              )[1]}
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  )
                ) : (
                  <Grid>
                    <Link
                      to={`${ImgUrl}${formData.health_file}`}
                      download
                      target="_blank"
                      className="pdfCard"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          border: "1px solid  #9f8754",
                          borderRadius: "3px",
                          width: "min-content",
                          marginBlock: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.3rem",
                            color: "white",
                            background:
                              "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                            borderRadiusTopleft: "2px",
                            borderRadiusBottomleft: "2px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "35px",
                            height: "35px",
                          }}
                        >
                          <FaFilePdf />
                        </div>
                        <div
                          style={{ padding: "0px 10px", fontSize: "0.8rem" }}
                        >

                          {formData.health_file
                            ? URL.createObjectURL(formData.health_file)
                            : "Not uploaded"}

                        </div>
                      </div>
                    </Link>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ width: "85%" }}>
                    <label htmlFor="upload-vaccination-file">
                      VACCINATION FILE <span style={{ color: "red" }}>*</span>
                      <TextField
                        className="bg-color"
                        id="upload-vaccination-file"
                        name="vaccine_file"
                        type="file"
                        multiple
                        required={!horse_id}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    </label>
                    <span style={{ color: "red", fontSize: "13px" }}>
                      {vacPdfError}
                    </span>
                  </div>
                </div>
                {horse_id ? (
                  formData.PdfFilesVaccine && (
                    <Grid>
                      <Link
                        to={`${ImgUrl}${formData.PdfFilesVaccine}`}
                        download
                        target="_blank"
                        className="pdfCard"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            border: "1px solid  #9f8754",
                            borderRadius: "3px",
                            width: "min-content",
                            marginBlock: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "1.3rem",
                              color: "white",
                              background:
                                "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                              borderRadiusTopleft: "2px",
                              borderRadiusBottomleft: "2px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            <FaFilePdf />
                          </div>
                          <div
                            style={{ padding: "0px 10px", fontSize: "0.8rem" }}
                          >
                            {formData.PdfFilesVaccine &&
                              formData.PdfFilesVaccine.split(
                                "/media/horse_vaccine_File/"
                              )[1]}
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  )
                ) : (
                  <Grid>
                    <Link
                      to={`${ImgUrl}${formData.vaccine_file}`}
                      download
                      target="_blank"
                      className="pdfCard"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          border: "1px solid  #9f8754",
                          borderRadius: "3px",
                          width: "min-content",
                          marginBlock: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.3rem",
                            color: "white",
                            background:
                              "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                            borderRadiusTopleft: "2px",
                            borderRadiusBottomleft: "2px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "35px",
                            height: "35px",
                          }}
                        >
                          <FaFilePdf />
                        </div>
                        <div
                          style={{ padding: "0px 10px", fontSize: "0.8rem" }}
                        >
                          {" "}
                          {formData.vaccine_file
                            ? URL.createObjectURL(formData.vaccine_file)
                            : "Not uploded"}
                        </div>
                      </div>
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>

          {/* Remarks */}
          <div className="horse-registration-remark horseregistrationh3">
            <a href="#remark" id="remark" className="bookmark">
              <h3>Remark</h3>
            </a>
          </div>
          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextareaAutosize
                  style={{ width: "100%", fontSize: "16px", padding: "10px" }}
                  minRows={8}
                  className="bg-color"
                  placeholder="Additional Information"
                  multiline
                  rows={4}
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>

          {/* Terms and Conditions */}
          <div className="horse-registration-tandc horseregistrationh3">
            <a href="#tandc" id="tandc" className="bookmark">
              <h3>Terms & Conditions</h3>
            </a>
          </div>

          <div style={{ marginBlock: "15px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                inputProps={{ "aria-label": "Checkbox demo" }}
                color="success"
                onClick={handleChange}
                name="checkedvalue"
                required
              />
              <p className="horseRegistration">
                I WOULD LIKE TO REGISTER FOR THIS SESSION 2023-2024 Terms and
                Conditions.
              </p>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                inputProps={{ "aria-label": "Checkbox demo" }}
                color="success"
                onClick={handleChange}
                name="checkedvalue"
                required
              />
              <p className="horseRegistration">
                I WOULD LIKE to REGISTER IN FEI Additional Charges.
              </p>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                inputProps={{ "aria-label": "Checkbox demo" }}
                color="success"
                onClick={handleChange}
                name="checkedvalue"
                required
              />
              <p className="horseRegistration">
                BY SELECTING THIS BOX, YOU VERIFY THE INFORMATION'S ACCURACY AND
                AGREE TO TERMS, CONDITIONS, AND GUARANTEES.
              </p>
            </div>
          </div>

          {/* Submit */}
          <div className="btn-submit" style={{ gap: "15px" }}>
            <Button
              variant="contained"
              className="btn-bgColor"
              onClick={() => navigate("/dashboard/horseagreement/horses_list")}
            >
              Cancel
            </Button>

            {loading ? (
              <CommonLoading />
            ) : (
              <Button type="submit" variant="contained" className="btn-bgColor">
                {horse_id ? "Update" : "Create"}
              </Button>
            )}
          </div>
        </form>
      </div>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Document"
        deleteData={handleremove}
      />
    </div>
  );
};

export default HorseRegistration;
