import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import "./OwnerAgreement.css";
import Aos from "aos";
import "aos/dist/aos.css";

const OwnerAgreement = () => {
  
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <section
        className="ownerAgreeSection"
        data-aos="flip-down"
        data-aos-duration="1000"
      >
        <header>
          <div className="registration_top_header">
            <p>Owner Registration</p>
            <span>
              Please fill in all required fields, attach/upload all supporting
              documents to proceed with the registration.
            </span>
          </div>
        </header>
        <body>
          <div className="oAgreeBody">
            <div className="oAgreeHead">
              <h4>IMPORTANT PRE-REQUISITE FOR OBTAINING RIDING LICENSE</h4>
              {/* <p>Horses must be registered with the National Federation (NF) before they can be entered and permitted to take part in an equestrian event.</p> */}
            </div>
            <div className="oAgreeUl">
              <ol>
                <li>
                  Athletes and trainers are obliged to attach all the required
                  documents when submitting the application for registration.
                </li>
                <li>
                  Athletes and trainers agree by applying for registration in
                  the records of the UAE Equestrian and Racing Federation (EQI5)
                  and Federation Equestre Internationale (FEI) abide by the
                  following:
                </li>
                <ul typeof="rectangle">
                  <li>
                    The Charter of Honor of the Federation Equestre
                    Internationale (FEI)
                  </li>
                  <li>By the National and International Olympic Charter</li>
                  <li>
                    The FEI and EQI5 Code of Conduct for the welfare of the
                    Horse.
                  </li>
                  <li>
                    All the National and FEI rules and regulations and laws
                    related to equestrian sport.
                  </li>
                  <li>
                    Maintaining the dignity of horses and sportsmanship is a
                    pre-requisite for equestrianism.
                  </li>
                  <li>
                    Non-discrimination between sports persons on the basis of
                    race, gender, origin, religion, philosophical or political
                    opinion, social status or other reasons.
                  </li>
                  <li>
                    All doping practices at all levels are strictly prohibited.
                    The provisions against doping in the Equine Anti-Doping and
                    Controlled Medication Regulations and the FEI Anti Doping
                    Rules for Human Athletes shall be scrupulously observed.
                  </li>
                  <li>
                    The FEI and EQI5 regulations for anti-doping, controlled
                    drugs and WADA anti-doping rules for athletes and horses
                    must be strictly adhered to.
                  </li>
                  <li>
                    Any form of harassment of athletes, whether physical,
                    professional or sexual including physical or psychological
                    injuries to participants are prohibited.
                  </li>
                  <li>
                    Adhere by the decisions of the judicial committees of the
                    Federation.
                  </li>
                  <li>
                    Adhere to pay all the financial dues of the Federation.
                  </li>
                  <li>
                    It is the responsibility of all participants to ensure that
                    they are aware of and informed of FEI and EQI5 rules and
                    regulations as amended from time to time.
                  </li>
                  <li>
                    Athletes & trainers and anyone subject to the provisions of
                    these regulations accepts the legal system of settlement and
                    dispute resolution mechanism, which includes the
                    Disciplinary Committee, the Appeals Committee and the
                    Emirates Sports Arbitration Centre as a final and binding
                    arbitration body as a precondition for participation in the
                    activities of the Federation.
                  </li>
                  <li>
                    Athletes and trainers, and each person subject to the
                    provisions of this regulation, accept that the provisions,
                    decisions and records issued by the Emirates Sports
                    Arbitration Center are final, binding and covered by
                    expedited enforcement and have the power of the executive
                    bond, and may not be challenged before any other local or
                    international entity.
                  </li>
                  <li>
                    Each person subject to the provisions of this regulation is
                    obliged to know the relevant National and International
                    general laws and regulations and sports rules, lack of such
                    information does not exempt one from the responsibilities
                    provided under these laws.
                  </li>
                </ul>
              </ol>
            </div>
          </div>
        </body>
        <footer>
          <div className="oAgreeFoot">
            <Button
              variant="contained"
              className="btn-bgColor"
              endIcon={<SendIcon />}
              onClick={() => {
                navigate("/dashboard/owner/registration_form");
              }}
            >
              I agree, Continue Registration
            </Button>
          </div>
        </footer>
      </section>
    </>
  );
};

export default OwnerAgreement;
