import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseURL } from '../../Apis';
import api from "../../Apis";
export const GetEvents = createAsyncThunk('GetEvents', async (token) => {
  try {
    const response = await api.get(`get_all_events`, {
      headers: {
        Authorization: `token ${token}`,
      }
    });
    return response.data
  } catch (error) {

  }

})
export const CreateEvent = createAsyncThunk('CreateEvent', async ({ event, token }, { rejectWithValue }) => {
  try {
    const response = await api.post(`create_event`,
      event,
      {
        headers: {
          "Content-Type": "application/json ",
          Authorization: `token ${token}`,
        }
      });

    return response.data

  } catch (error) {
    return rejectWithValue(error.response.data)
  }

})
export const GetUpdatingEvent = createAsyncThunk('GetUpdatingEvent', async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await api.get(`get_event/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      }
    });

    return response.data

  } catch (error) {
    return rejectWithValue(error.response.data)
  }

})
export const UpdateEvent = createAsyncThunk('UpdateEvent', async ({ eventData, token }, { rejectWithValue }) => {

  try {
    const response = await api.put(`update_event/${eventData.id}`,
      eventData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        }
      });

    return response.data

  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})
export const DeleteEvent = createAsyncThunk('DeleteEvent', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`update_event/${id}`)

    return response.data

  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})
const initialState = {
  EventsList: [],
  UpdatingEvent: null,
  Getstatus: "",
  Geterror: "",
  Createstatus: "",
  Createerror: "",
  Updatestatus: "",
  Updateerror: "",
  Deletestatus: "",
  Deleteerror: "",
};

const EventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    Update: (state, action) => { },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetEvents.pending, (state) => {
        state.Getstatus = "loading"
      })
      .addCase(GetEvents.fulfilled, (state, action) => {
        state.Getstatus = "succeeded"
        state.EventsList = action.payload

      })
      .addCase(GetEvents.rejected, (state, action) => {
        state.Getstatus = "failed"


      })
      .addCase(CreateEvent.pending, (state) => {
        state.Createstatus = "loading"
      })
      .addCase(CreateEvent.fulfilled, (state, action) => {
        state.Createstatus = "succeeded"

      })
      .addCase(CreateEvent.rejected, (state, action) => {
        state.Createstatus = "failed"

      })
      .addCase(GetUpdatingEvent.pending, (state) => {
        state.Updatestatus = "loading"
      })
      .addCase(GetUpdatingEvent.fulfilled, (state, action) => {
        state.Updatestatus = "succeeded"
        state.UpdatingEvent = action.payload

      })
      .addCase(GetUpdatingEvent.rejected, (state, action) => {
        state.Updatestatus = "failed"

      })
      .addCase(UpdateEvent.pending, (state) => {
        state.Updatestatus = "loading"
      })
      .addCase(UpdateEvent.fulfilled, (state, action) => {
        state.Updatestatus = "succeeded"
        state.UpdatingEvent = ""

      })
      .addCase(UpdateEvent.rejected, (state, action) => {
        state.Updatestatus = "failed"

      })
  }
})


export const { } = EventsSlice.actions;
export default EventsSlice.reducer;
