import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getGenderMasterData = createAsyncThunk("getGenderMasterData", async (token) => {
    try {
        const response = await api.get("get_all_gender", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createGenderMasterData = createAsyncThunk("createGenderMasterData", async (payload) => {
    try {
        const response = await api.post("create_gender", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteGenderMasterData = createAsyncThunk("deleteGenderMasterData", async (id, token) => {
    try {
        const response = await api.delete(`delete_gender/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateGenderMasterData = createAsyncThunk("updateGenderMasterData", async (payload) => {
    console.log("payload", payload)
    try {
        const response = await api.put(`update_gender/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateGenderMasterData = createAsyncThunk("getupdateGenderMasterData", async (id, token) => {
    try {
        const response = await api.get(`get_gender/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const GenderMasterSlice = createSlice({
    name: "GenderMaster",
    initialState: {
        status: "",
        GenderMasterData: null,
        error: null,
        updateGenderMasterData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getGenderMasterData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getGenderMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.GenderMasterData = action.payload
            
        })
        builder.addCase(getGenderMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteGenderMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteGenderMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteGenderMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createGenderMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createGenderMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createGenderMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateGenderMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateGenderMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateGenderMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateGenderMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateGenderMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateGenderMasterData = action.payload

        })
        builder.addCase(getupdateGenderMasterData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default GenderMasterSlice.reducer;