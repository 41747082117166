import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getBreederData = createAsyncThunk("getBreederData", async (token) => {
    try {
        const response = await api.get("get_all_breeder", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createBreederData = createAsyncThunk("createBreederData", async (payload) => {
    try {
        const response = await api.post("create_breeder", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteBreederData = createAsyncThunk("deleteBreederData", async (id, token) => {
    try {
        const response = await api.delete(`delete_breeder/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateBreederData = createAsyncThunk("updateBreederData", async (payload) => {
    console.log("payload", payload)
    try {
        const response = await api.put(`update_breeder/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateBreederData = createAsyncThunk("getupdateBreederData", async (id, token) => {
    try {
        const response = await api.get(`get_breeder/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const BreederSlice = createSlice({
    name: "Breeder",
    initialState: {
        status: "",
        BreederData: null,
        error: null,
        updateBreederData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getBreederData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getBreederData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.BreederData = action.payload
            
        })
        builder.addCase(getBreederData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteBreederData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteBreederData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteBreederData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createBreederData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createBreederData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createBreederData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateBreederData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateBreederData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateBreederData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateBreederData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateBreederData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateBreederData = action.payload

        })
        builder.addCase(getupdateBreederData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default BreederSlice.reducer;