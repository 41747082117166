import React from 'react'
import nableLogo from "../../Assets/Images/nable_logo.png"
import { FaLinkedinIn } from 'react-icons/fa'
import { BsTwitter, BsFacebook, BsYoutube, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import {AiOutlineMail, AiOutlineWhatsApp} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import MobilePortal  from '../../Assets/Images/Mobile_portal.png'
const LoginPageLeftContainer = () => {
    return (

        <div className="login-left-container">

            <div className="top-left-container">
                <Link to="/"> <img src={nableLogo} alt="logo" width={150} /></Link>
            </div>


            <div className="mid-left-container">
                <div className="left-mid-left-container">
                    <img src= {MobilePortal} alt="Mobile App" width={250} />
                </div>
                <div className="right-mid-left-container">
                    <div className="top-right-mid-left-container">
                        <p style={{ color: "#222222", fontSize: "19.5px", fontWeight: "bold", paddingBottom: "2rem" }}>EQI5 Mobile Application</p>
                        <span style={{ fontSize: "14px" }}>Our application for IOS and Android mobiles is upcoming ...
                            {/* for IOS and Android mobiles. Please download the application from the links below: */}
                        </span>
                    </div>
                    <div className="bottom-right-mid-left-container">
                        <p style={{ color: "#877749", fontSize: "12.5px", fontWeight: "bold" }}>DOWNLOAD NOW</p>
                        <div className="app-installer-container">
                            <Link target="_blank">
                                <div className="left-app-intaller">
                                    <img src="https://portal.uaeerf.ae/images/android.png" alt="Paly-Store" />
                                </div>
                            </Link>
                            <Link target="_blank">
                                <div className="right-app-intaller">
                                    <img src="https://portal.uaeerf.ae/images/ios.png" alt="app-Store" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bottom-left-container">
                <p style={{ color: "#222222", fontSize: "12.5px", fontWeight: "bold" }}>Stay in touch with us:</p>
                <div className="bottom-left-media-container">
                    <ul type="none">
                        <Link target="_blank" to="https://www.linkedin.com/company/nable-invent-solution/"> <li className="media-container"><FaLinkedinIn style={{ fontSize: "22.5px", color: "white", fontWeight: "400" }} /></li></Link>
                        <Link target="_blank" to="https://twitter.com/i/flow/login?redirect_after_login=%2Fnableinvent"> <li className="media-container"><BsTwitter style={{ fontSize: "22.5px", color: "white", fontWeight: "400" }} /></li></Link>
                        <Link target="_blank" to="https://www.facebook.com/nableinvent/?ref=pages_you_manage"> <li className="media-container"><BsFacebook style={{ fontSize: "22.5px", color: "white", fontWeight: "400" }} /></li></Link>
                        <Link target="_blank" to="https://www.youtube.com/channel/UC6YyXYbSALk76w6EveJ5chA"> <li className="media-container"><BsYoutube style={{ fontSize: "22.5px", color: "white", fontWeight: "400" }} /></li></Link>
                        <Link target="_blank" to="https://www.instagram.com/nableinvent/"> <li className="media-container"><BsInstagram style={{ fontSize: "22.5px", color: "white", fontWeight: "400" }} /></li></Link>
                        <Link target="_blank" to="https://www.instagram.com/nableinvent/"> <li className="media-container"><AiOutlineWhatsApp style={{ fontSize: "22.5px", color: "white", fontWeight: "400" }} /></li></Link>
                        <Link target="_blank" to="https://www.instagram.com/nableinvent/"> <li className="media-container"><AiOutlineMail style={{ fontSize: "22.5px", color: "white", fontWeight: "400" }} /></li></Link>
                    </ul>
                </div>
            </div>

        </div>

    )
}

export default LoginPageLeftContainer