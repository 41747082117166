import React, { useEffect, useState } from 'react'
import { BasicTable } from '../../../SharedComponets/Table list/BasicTable';
import CommonModal from '../../components/Modal';
import DeleteModal from '../../../App/Helpers/DeleteModal';
import { toast } from "react-toastify";
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import SlotApi from '../../../App/Apis/RidingSchoolApis/SlotApis/SlotApi.js'
import CourseApi from '../../../App/Apis/RidingSchoolApis/courseApi/course.js'
import { companyId } from '../../../App/Apis/RidingSchoolReuseIds/Ids.js'

import Loader from '../../components/Loader.jsx';
import './slot.css'

function SlotList() {
    const [slotCapacity, setSlotCapacity] = useState();
    const [loading, setLoding] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({ course: null, time: "01:00 AM" });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isErrorResponse, setIsErrorResponse] = useState(null);
    const [cookies, setCookies] = useCookies(["token"]);
    const token = cookies.token;
    const [slotData, setSlotData] = useState([])

    const handleModalInputChange = (e) => {
        console.log(e);
        const { name, value } = e.target;
        console.log('name', name);
        console.log('value', value);
        setModalData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        const updatedFields = [...modalInputFields];
        const courseField = updatedFields.find(field => field.name === name);
        courseField.value = value
        setModalInputFields(updatedFields);
    };
    const handleAutoComplete = async (event, value, fieldName) => {

        const selectedValue = value ? value.id : null;
        console.log('selectedValue', selectedValue);
        if (fieldName === 'course') {
            let isSlotFullRes = null
            if (selectedValue !== null) {

                isSlotFullRes = await SlotApi.checkAssignableStots(selectedValue);
            }
            console.log('isSlotFullRes', isSlotFullRes?.data?.assignable_slots_no <= 0);
            setSlotCapacity(isSlotFullRes?.data ? isSlotFullRes.data : null)

            setModalData((prevFormData) => ({
                ...prevFormData,
                [fieldName]: selectedValue,
            }));

            const updatedFields = [...modalInputFields];
            const courseField = updatedFields.find(field => field.name === fieldName);
            courseField.slotCapacity = isSlotFullRes !== null ? isSlotFullRes.data : null
            courseField.value = selectedValue
            console.log('updatedFields', updatedFields);
            setModalInputFields(updatedFields);

        } else {

            setModalData((prevFormData) => ({
                ...prevFormData,
                [fieldName]: selectedValue,
            }));

            const updatedFields = [...modalInputFields];
            const courseField = updatedFields.find(field => field.name === fieldName);
            courseField.value = selectedValue
            setModalInputFields(updatedFields);
        }


    };
    console.log('slotCapacity', slotCapacity);

    const [modalInputFields, setModalInputFields] = useState([
        {
            label: 'Course',
            type: "select",
            name: "course",
            handleOnChange: handleAutoComplete,
            value: modalData.course,
            placeholder: 'Select Course',
            fullWidth: 'fullWidth',
            required: true,
            options: [

            ]
        },
        {
            label: 'Time',
            type: "time",
            name: "time",
            handleOnChange: handleModalInputChange,
            value: modalData.time,
            placeholder: '',
            fullWidth: 'fullWidth',
            required: true
        }
    ])
    console.log('modalInputFields', modalInputFields);

    const openModal = () => {
        setModalData({ course: "", time: "01:00 AM" });
        const updatedFields = [...modalInputFields];
        const courseField = updatedFields.find(field => field.name === 'course');
        const timeField = updatedFields.find(field => field.name === 'time');
        courseField.value = null
        courseField.slotCapacity = null
        timeField.value = "01:00 AM"
        setModalInputFields(updatedFields);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };



    const header = [
        // {
        //     Header: "ID",
        //     accessor: "id",
        // },
        {
            Header: "Slot",
            accessor: "time",
        },
        {
            Header: "Course",
            accessor: "course",
            Cell: ({ row }) => {
                // console.log(row.original.course.course_name);
                return row.original.course.course_name
            }
        },
    ];

    const validator = () => {
        if (modalData.course === '') {
            toast.error("Course is required");
            return true
        }
        if (slotCapacity.assignable_slots_no <= 0) {
            toast.error("Slot is full");
            return true
        }

        if (modalData.time === '') {
            console.log("modalData.time", modalData.time)
            toast.error("Time is required");
            return true
        }
    }

    const createOrUpdateHandler = async (e) => {
        e.preventDefault()
        try {
            if (modalData.id) {
                const createRes = await SlotApi.updateSlot(modalData)

            } else {
                if (validator()) {
                    return
                } else {
                    modalData.company = companyId
                    const createRes = await SlotApi.createSlot(modalData)
                    console.log(createRes);

                }

            }
            closeModal();
            prefilledData()
            // dispatch(getSlotData(token));
        } catch (error) {

        }
    };

    const updateHandler = (id) => {
        const editData = slotData.find((data) => data.id === id);
        console.log('editData', editData);
        if (editData) {
            const prefilled = {
                id: editData.id,
                course: editData.course.id,
                time: editData.time
            }
            const updatedFields = [...modalInputFields];
            const courseField = updatedFields.find(field => field.name === 'course');
            courseField.disabled = true
            const timeField = updatedFields.find(field => field.name === 'time');
            courseField.value = editData.course.id
            courseField.slotCapacity = null
            timeField.value = editData.time
            setModalInputFields(updatedFields);
            setModalData(prefilled);
            setModalOpen(true);
        }
    }
    console.log('modalData', modalData);
    const deleteDataHandler = (id) => {
        console.log('id', id);
        setDeleteId(id);
        setDeleteModalOpen(true);
    }
    const createHandler = () => {

    }
    const deleteHandler = (id) => {
        setDeleteId(id);
        setDeleteModalOpen(true);
    };
    const deleteData = async () => {
        if (deleteId) {
            try {
                const delRes = await SlotApi.deleteSlot(deleteId)
                prefilledData()
            } catch (error) {
                // if (error.response.data.Error) {
                //     setIsErrorVisible(true);
                //     setIsErrorResponse(error.response.data.Error);
                // }

                // setTimeout(() => {
                //     setIsErrorVisible(false);
                // }, 6000);
            }
        }
    };

    const prefilledData = async () => {
        setLoding(true)
        try {

            const courseListRes = await CourseApi.getCourseList();
            console.log('courseListRes', courseListRes.data);
            const updatedFields = [...modalInputFields];
            const courseField = updatedFields.find(field => field.name === 'course');
            courseField.options = courseListRes.data
            setModalInputFields(updatedFields);
            const slotRes = await SlotApi.getSlotList();
            console.log(slotRes.data.data);
            setSlotData(slotRes.data.data)


        } catch (error) {

        }
        setLoding(false)
    }

    useEffect(() => {

        prefilledData()

    }, [])


    if (loading) {
        return <Loader />
    }
    return (
        <>
            <BasicTable
                colHeader={header}
                rowData={slotData}
                updateHandler={updateHandler}
                deleteHandler={deleteDataHandler}
                createHandler={openModal}
                tableHeading="Slot List"
            />

            <CommonModal
                modalOpen={modalOpen}
                handleModalInputChange={handleModalInputChange}
                createOrUpdateHandler={createOrUpdateHandler}
                openModal={openModal}
                closeModal={closeModal}
                modalData={modalData}
                inputs={modalInputFields}
                label="Update Slot"
                heading="Slot"
            />

            <DeleteModal
                open={deleteModalOpen}
                handleClose={() => setDeleteModalOpen(false)}
                title="Slot"
                deleteData={deleteData}
            />
        </>
    )
}

export default SlotList