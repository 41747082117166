
import Api from "../ApiConfig";

const ridingTypesApi = {
    
    createRidingType : (payload, option) => Api.post(`create_ridingtype`, payload, option),
    
    updateRidingType: (payload, option) => Api.put(`update_ridingtype/${payload.id}`, payload.updatedData, option),
}

export default ridingTypesApi