import React, { useEffect , useState } from 'react'
import {FaPlus} from "react-icons/fa"
import {useNavigate} from "react-router-dom"
import { useDispatch , useSelector } from 'react-redux'
import { DeleteMedicalPlaner, GetMedicalPlanerList, GetUpdatingMedicalPlaner } from '../../App/Reducers/MedicalPlanerReducer'
import { BasicTable } from '../../SharedComponets/Table list/BasicTable'
import { format } from "date-fns";
import StableLoading from '../../SharedComponets/Loading/StableLoading'
import { useCookies } from 'react-cookie'
const MedicalPlanerList = () => {
    const [cookies , setCookie] = useCookies();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = cookies.token
    const State = useSelector((state)=>state.MedicalPlaner)
    const [status , setStatus] = useState(State.status.Updating)
    console.log(status)
    const header = [
      { Header: "ID", accessor: "id" },
      { Header: "Horse Name", accessor: "horse_id.name" },
      { Header: "Medical", accessor: "medical" },
      { Header: "Vaccination", accessor: "vaccination" },
      { Header: "Discription", accessor: "discription" },
      { Header: "Price", accessor: "price" },
      {
        Header: "Staff",
        accessor: "staff",
        Cell: ({ row }) => {
          const { first_name, last_name } = row.original.staff;
          return `${first_name} ${last_name}`;
        },
      },
      { Header: "Other Staff", accessor: "other_staff"},
      { Header: "Start Date", accessor: "start_date" ,
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },},
      { Header: "End Date", accessor: "end_date",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },},
      // { Header: "Is Done", accessor: "is_done" },
     
    ]
    const CreateHandler = () =>{
      navigate("/dashboard/planer/medical");
    }
    const UpdateHandler = (id) =>{
        dispatch(GetUpdatingMedicalPlaner({token , id}))
    }
    const DeleteHandler = (id) =>{
      dispatch(DeleteMedicalPlaner({token , id}))
    }
    useEffect(()=>{
        setStatus(State.status.Updating)
        if(status === "succeeded"){
          navigate("/dashboard/planer/medical")
       }
         dispatch(GetMedicalPlanerList(token))
    },[dispatch , State.status.Updating,status,navigate])
  return (


    <div className="all_table_list">
    {(State.PlanerList && header) ? <BasicTable colHeader={header} rowData={State.PlanerList} deleteHandler={DeleteHandler} updateHandler={UpdateHandler} createHandler={CreateHandler} tableHeading="Medical Planner List"/> : <StableLoading/> }
  </div>
  )
}

export default MedicalPlanerList