import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from "../../../Apis";
export const GetTrainers = createAsyncThunk('GetTrainers', async (token) => {
  try {
    const response = await api.get(`get_all_riding_trainer`, {
      headers:
        { Authorization: `token ${token}` }
    });

    return response.data

  } catch (error) {
    throw error
  }

})
export const CreateTrainer = async (payload) => {
  try {
    const response = await api.post(`create_riding_trainer`,
      payload.fData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });

    return { data: response.data, status: response.status, statusText: response.statusText }
  } catch (error) {

    return error.response
  }

}
export const GetUpdatingTrainer = createAsyncThunk('GetUpdatingTrainer', async (payload, { rejectWithValue }) => {
  try {
    const response = await api.get(`get_riding_trainer/${payload.id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data , application/json",
          Authorization: `token ${payload.token}`
        }
      }
    );

    return response.data

  } catch (error) {
    return rejectWithValue(error.response.data)
  }

})
export const UpdateTrainer = async (payload) => {
  try {
    const response = await api.put(`update_riding_trainer/${payload.id}`, payload.fData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${payload.token}`
        }
      });

    return { data: response.data, status: response.status, statusText: response.statusText }
  } catch (error) {

    return error.response
  }
}
export const DeleteTrainer = createAsyncThunk('DeleteTrainer', async (payload, { rejectWithValue }) => {

  try {
    const response = await api.delete(`delete_riding_trainer/${payload.id}`, {
      headers: {
        Authorization: `token ${payload.token}`
      }
    })
    console.log(response, "Delete")
    return response.data

  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})
const initialState = {
  Trainers: null,
  UpdatingTrainer: null,
  status: {
    Get: "",
    Create: "",
    Update: "",
    Delete: ""
  },
  errors: {
    Get: "",
    Create: "",
    Update: "",
    Delete: "",

  }
};

const TrainerSlice = createSlice({
  name: "trainers",
  initialState,
  reducers: {
    Update: (state, action) => { },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetTrainers.pending, (state) => {
        state.status.Get = "loading"
      })
      .addCase(GetTrainers.fulfilled, (state, action) => {
        state.status.Get = "succeeded"
        state.Trainers = action.payload
        console.log(action.payload, "success")
      })
      .addCase(GetTrainers.rejected, (state, action) => {
        state.status.Get = "failed"
        console.log("failed")

      })
      // .addCase(CreateTrainer.pending, (state) => {
      //   state.status.Create = "loading"
      // })
      // .addCase(CreateTrainer.fulfilled, (state, action) => {
      //   state.status.Create = "succeeded"
      //   console.log(action.payload, "succeeded")
      // })
      // .addCase(CreateTrainer.rejected, (state, action) => {
      //   state.status.Create = "failed"
      //   console.log("failed")
      // })
      .addCase(GetUpdatingTrainer.pending, (state) => {
        state.Updatestatus = "loading"
      })
      .addCase(GetUpdatingTrainer.fulfilled, (state, action) => {
        state.Updatestatus = "succeeded"
        state.UpdatingTrainer = action.payload

      })
      .addCase(GetUpdatingTrainer.rejected, (state, action) => {
        state.Updatestatus = "failed"
        console.log("failed")
      })
    // .addCase(UpdateTrainer.pending, (state) => {
    //   state.status.Update = "loading"
    // })
    // .addCase(UpdateTrainer.fulfilled, (state, action) => {
    //   state.status.Update = "succeeded"
    //   console.log(action.payload, "succeeded")

    // })
    // .addCase(UpdateTrainer.rejected, (state, action) => {
    //   state.status.Update = "failed"
    //   console.log("failed")
    // })
  }
})


export const { } = TrainerSlice.actions;
export default TrainerSlice.reducer;
