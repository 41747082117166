import React, { useEffect, useState } from "react";
import "./Home.css";
import Partners from "../SharedComponets/Partners/Partners";
import CarouselComponent from "../SharedComponets/Carousel";
import Calendar from "../SharedComponets/Calender";
import CalApp from "../SharedComponets/CalApp";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ImgUrl } from "../Config/Config";
import { arabianShow, discipline, flatRace, horseLivery, quarantine, ridingSchool, services, showJumping, stableRent, transportation } from "../Assets/DefaultImagesCarousel";

function Home() {
  const navigatorSelector = useSelector((state) => state.home.pageNavigatorData);

  //Discipline
  const modalSelectorDiscipline = useSelector((state) => state.HeroQuill.discipline);
  const modalSelectorIdDiscipline = modalSelectorDiscipline && modalSelectorDiscipline.find(item => item.id === 1);

  //Flat Race
  const modalSelectorFlatRace = useSelector((state) => state.HeroQuill.flatRace);
  const modalSelectorIdFlatRace = modalSelectorFlatRace && modalSelectorFlatRace.find(item => item.id === 1);

  //Arabian Shows
  const modalSelectorArabianShows = useSelector((state) => state.HeroQuill.arabianshowData);
  const modalSelectorIdArabianShows = modalSelectorArabianShows && modalSelectorArabianShows.find(item => item.id === 1);

  //Show Jumping
  const modalSelectorShowJumping = useSelector((state) => state.HeroQuill.showJumping);
  const modalSelectorIdShowJumping = modalSelectorShowJumping && modalSelectorShowJumping.find(item => item.id === 1);

  //Services
  const modalSelectorServices = useSelector((state) => state.HeroQuill.services);
  const modalSelectorIdServices = modalSelectorServices && modalSelectorServices.find(item => item.id === 1);

  //Horse Livery
  const modalSelectorHorseLivery = useSelector((state) => state.HeroQuill.horselivery);
  const modalSelectorIdHorseLivery = modalSelectorHorseLivery && modalSelectorHorseLivery.find(item => item.id === 1);

  //Stable Rent
  const modalSelectorStableRent = useSelector((state) => state.HeroQuill.stablerents);
  const modalSelectorIdStableRent = modalSelectorStableRent && modalSelectorStableRent.find(item => item.id === 1);

  //Quarantine
  const modalSelectorQuarantine = useSelector((state) => state.HeroQuill.qurantine);
  const modalSelectorIdQuarantine = modalSelectorQuarantine && modalSelectorQuarantine.find(item => item.id === 1);

  //Transportation
  const modalSelectorTransportation = useSelector((state) => state.HeroQuill.transportation);
  const modalSelectorIdTransportation = modalSelectorTransportation && modalSelectorTransportation.find(item => item.id === 1);

  //Riding School
  const modalSelectorRidingSchool = useSelector((state) => state.HeroQuill.transportation);
  const modalSelectorIdRidingSchool = modalSelectorRidingSchool && modalSelectorRidingSchool.find(item => item.id === 1);


  const [headerdivClass, setHeaderDivClass] = useState("rinding");
  const [maindivClass, setMainDivClass] = useState("events");
  const themeSelector = useSelector((state) => state.theme.ThemeColor);

  const themeClasses = {
    "header-white": "events header-white",
    "header-roseate": "events header-roseate",
    "header-skyBlue": "events header-skyBlue",
    "header-grassy": "events header-grassy",
    "header-pink": "events header-pink",
    "header-gold": "events header-gold",
    "header-orange": "events header-orange",
  };

  useEffect(() => {
    setMainDivClass(themeClasses[themeSelector] || "events");
    switch (themeSelector) {
      case "header-white":
        setHeaderDivClass("rinding header-white");
        break;
      case "header-roseate":
        setHeaderDivClass("rinding header-roseate");
        break;
      case "header-skyBlue":
        setHeaderDivClass("rinding header-skyBlue");
        break;
      case "header-grassy":
        setHeaderDivClass("rinding header-grassy");
        break;
      case "header-pink":
        setHeaderDivClass("rinding header-pink");
        break;
      case "header-gold":
        setHeaderDivClass("rinding header-gold");
        break;
      case "header-orange":
        setHeaderDivClass("rinding header-orange");
        break;
      default:
        setHeaderDivClass("rinding header-gold");
        break;
    }
  }, [themeSelector]);

  return (
    <>
      <div>

        <section style={{ borderTop: " 10px solid black", background: "black" }}>
          <CarouselComponent />
        </section>

        <section className={headerdivClass}>
          <div className="card-images">

            {/* Discipline */}
            <>
              {modalSelectorIdDiscipline ? <Link to="/disciplines">
                <div className="my-card">
                  <img
                    src={modalSelectorIdDiscipline && modalSelectorIdDiscipline.image ? `${ImgUrl}${modalSelectorIdDiscipline.image}` : discipline.image}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdDiscipline && modalSelectorIdDiscipline.image_title ? (
                    <p>{modalSelectorIdDiscipline.image_title}</p>
                  ) : (
                    <p>{discipline.image_title}</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/disciplines">
                  <div className="my-card">
                    <img
                      src={discipline.image}
                      alt="Image"
                    />

                    <div className="title">  {discipline.image_title ? (
                      <p>{discipline.image_title}</p>
                    ) : (
                      <p>{discipline.image_title}</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>

            {/* Flat Race */}
            <>
              {modalSelectorIdFlatRace ? <Link to="/disciplines/flat-race">
                <div className="my-card">
                  <img
                    src={modalSelectorIdFlatRace && modalSelectorIdFlatRace.image ? `${ImgUrl}${modalSelectorIdFlatRace.image}` : null}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdFlatRace && modalSelectorIdFlatRace.image_title ? (
                    <p>{modalSelectorIdFlatRace.image_title}</p>
                  ) : (
                    <p>Flat Race</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/disciplines/flat-race">
                  <div className="my-card">
                    <img
                      src={flatRace.image}
                      alt="Image"
                    />

                    <div className="title">  {flatRace.image_title ? (
                      <p>{flatRace.image_title}</p>
                    ) : (
                      <p>Flat Race</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>

            {/* Arabian Shows */}
            <>
              {modalSelectorIdArabianShows ? <Link to="/disciplines/arabian-shows">
                <div className="my-card">
                  <img
                    src={modalSelectorIdArabianShows && modalSelectorIdArabianShows.image ? `${ImgUrl}${modalSelectorIdArabianShows.image}` : arabianShow.image}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdArabianShows && modalSelectorIdArabianShows.image_title ? (
                    <p>{modalSelectorIdArabianShows.image_title}</p>
                  ) : (
                    <p>{arabianShow.image_title}</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/disciplines/arabian-shows">
                  <div className="my-card">
                    <img
                      src={arabianShow.image}
                      alt="Image"
                    />

                    <div className="title">  {arabianShow.image_title ? (
                      <p>{arabianShow.image_title}</p>
                    ) : (
                      <p>{arabianShow.image_title}</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>

            {/* Show Jumping */}
            <>
              {modalSelectorIdShowJumping ? <Link to="/disciplines/show-jumping">
                <div className="my-card">
                  <img
                    src={modalSelectorIdShowJumping && modalSelectorIdShowJumping.image ? `${ImgUrl}${modalSelectorIdShowJumping.image}` : showJumping.image}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdShowJumping && modalSelectorIdShowJumping.image_title ? (
                    <p>{modalSelectorIdShowJumping.image_title}</p>
                  ) : (
                    <p>{showJumping.image_title}</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/disciplines/show-jumping">
                  <div className="my-card">
                    <img
                      src={showJumping.image}
                      alt="Image"
                    />

                    <div className="title">  {showJumping.image_title ? (
                      <p>{showJumping.image_title}</p>
                    ) : (
                      <p>{showJumping.image_title}</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>

            {/* Services */}
            <>
              {modalSelectorIdServices ? <Link to="/e-commerce">
                <div className="my-card">
                  <img
                    src={modalSelectorIdServices && modalSelectorIdServices.image ? `${ImgUrl}${modalSelectorIdServices.image}` : services.image}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdServices && modalSelectorIdServices.image_title ? (
                    <p>{modalSelectorIdServices.image_title}</p>
                  ) : (
                    <p>{services.image_title}</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/e-commerce">
                  <div className="my-card">
                    <img
                      src={services.image}
                      alt="Image"
                    />

                    <div className="title">  {services.image_title ? (
                      <p>{services.image_title}</p>
                    ) : (
                      <p>{services.image_title}</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>

            {/* Horse Livery */}
            <>
              {modalSelectorIdHorseLivery ? <Link to="/e-commerce/horse-livery">
                <div className="my-card">
                  <img
                    src={modalSelectorIdHorseLivery && modalSelectorIdHorseLivery.image ? `${ImgUrl}${modalSelectorIdHorseLivery.image}` : horseLivery.image}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdHorseLivery && modalSelectorIdHorseLivery.image_title ? (
                    <p>{modalSelectorIdHorseLivery.image_title}</p>
                  ) : (
                    <p>{horseLivery.image_title}</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/e-commerce/horse-livery">
                  <div className="my-card">
                    <img
                      src={horseLivery.image}
                      alt="Image"
                    />

                    <div className="title">  {horseLivery.image_title ? (
                      <p>{horseLivery.image_title}</p>
                    ) : (
                      <p>{horseLivery.image_title}</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>

            {/* Stable Rent */}
            <>
              {modalSelectorIdStableRent ? <Link to="/e-commerce/stables-rent">
                <div className="my-card">
                  <img
                    src={modalSelectorIdStableRent && modalSelectorIdStableRent.image ? `${ImgUrl}${modalSelectorIdStableRent.image}` : stableRent.image}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdStableRent && modalSelectorIdStableRent.image_title ? (
                    <p>{modalSelectorIdStableRent.image_title}</p>
                  ) : (
                    <p>{stableRent.image_title}</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/e-commerce/stables-rent">
                  <div className="my-card">
                    <img
                      src={stableRent.image}
                      alt="Image"
                    />

                    <div className="title">  {stableRent.image_title ? (
                      <p>{stableRent.image_title}</p>
                    ) : (
                      <p>{stableRent.image_title}</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>


            {/* Quarantine */}
            <>
              {modalSelectorIdQuarantine ? <Link to="/e-commerce/quarantine">
                <div className="my-card">
                  <img
                    src={modalSelectorIdQuarantine && modalSelectorIdQuarantine.image ? `${ImgUrl}${modalSelectorIdQuarantine.image}` : quarantine.image}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdQuarantine && modalSelectorIdQuarantine.image_title ? (
                    <p>{modalSelectorIdQuarantine.image_title}</p>
                  ) : (
                    <p>{quarantine.image_title}</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/e-commerce/quarantine">
                  <div className="my-card">
                    <img
                      src={quarantine.image}
                      alt="Image"
                    />

                    <div className="title">  {quarantine.image_title ? (
                      <p>{quarantine.image_title}</p>
                    ) : (
                      <p>{quarantine.image_title}</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>

            {/* Transportation */}
            <>
              {modalSelectorIdTransportation ? <Link to="/e-commerce/transportation">
                <div className="my-card">
                  <img
                    src={modalSelectorIdTransportation && modalSelectorIdTransportation.image ? `${ImgUrl}${modalSelectorIdTransportation.image}` : transportation.image}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdTransportation && modalSelectorIdTransportation.image_title ? (
                    <p>{modalSelectorIdTransportation.image_title}</p>
                  ) : (
                    <p>{transportation.image_title}</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/e-commerce/transportation">
                  <div className="my-card">
                    <img
                      src={transportation.image}
                      alt="Image"
                    />

                    <div className="title">  {transportation.image_title ? (
                      <p>{transportation.image_title}</p>
                    ) : (
                      <p>{transportation.image_title}</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>

            {/* Riding School */}
            <>
              {modalSelectorIdRidingSchool ? <Link to="/riding-school">
                <div className="my-card">
                  <img
                    src={modalSelectorIdRidingSchool && modalSelectorIdRidingSchool.image ? `${ImgUrl}${modalSelectorIdRidingSchool.image}` : ridingSchool.image}
                    alt="Image"
                  />
                  <div className="title">  {modalSelectorIdRidingSchool && modalSelectorIdRidingSchool.image_title ? (
                    <p>{modalSelectorIdRidingSchool.image_title}</p>
                  ) : (
                    <p>{ridingSchool.image_title}</p>
                  )}</div>
                </div>
              </Link>
                :
                <Link to="/riding-school">
                  <div className="my-card">
                    <img
                      src={ridingSchool.image}
                      alt="Image"
                    />

                    <div className="title">  {ridingSchool.image_title ? (
                      <p>{ridingSchool.image_title}</p>
                    ) : (
                      <p>{ridingSchool.image_title}</p>
                    )}</div>
                  </div>
                </Link>
              }
            </>



          </div>
        </section>

        {/* <section className={maindivClass}>
          <div className="tab-container">
            <br />
            <br />
            <div class="tab-nav" id="tab-nav">
              FLAT RACE
            </div>
            <ul class="tabs">
              <li>
                <a
                  href="#"
                  class="selected"
                  id="rttab_1"
                  style={{ borderRadius: "0px 5px 0px 0px" }}
                >
                  FLAT RACE
                </a>
              </li>
              <li>
                <a href="#" id="rttab_2">
                  ARABIAN HORSE SHOW
                </a>
              </li>
              <li>
                <a href="#" id="rttab_3">
                  INT. SHOW JUMPING
                </a>
              </li>
              <li>
                <a href="#" id="rttab_4">
                  SHOW JUMPING
                </a>
              </li>
            </ul>
            <div class="tab-content-holder">
              <div class="tab-content">
                <div className="events-desc">
                  <p>
                    {" "}
                    <i
                      class="fa fa-circle"
                      style={{
                        fontSize: "13px",
                        color: "Green",
                        paddingInline: "10px",
                      }}
                    ></i>
                    Flat race event on 28/06/23{" "}
                  </p>
                </div>
                <div className="events-desc">
                  <p>
                    <i
                      class="fa fa-circle"
                      style={{
                        fontSize: "13px",
                        color: "purple",
                        paddingInline: "10px",
                      }}
                    ></i>
                    Flat race event which was going to get organised at 30/06/23
                    got postpond
                  </p>
                </div>
                <div className="events-desc">
                  <p>
                    <i
                      class="fa fa-circle"
                      style={{
                        fontSize: "13px",
                        color: "red",
                        paddingInline: "10px",
                      }}
                    ></i>
                    The flat race event scheduled for 02/07/23 has been
                    canceled.
                  </p>
                </div>
              </div>
              <a href="#" class="eventallreadmore" id="eventLink">
                READ MORE
              </a>
            </div>
          </div>
          <div className="calender_div">
            <Calendar />
            <CalApp />
          </div>
        </section> */}
      </div>
    </>
  );
}

export default Home;
