
import Api from "../ApiConfig";

const pendingBookingApi = {
    
    getPendingBookings : (companyId) => Api.get(`bookings/get_all/pending/${companyId}`),
    
    approvePendingBookings: (bookingId) => Api.get(`approve/pending/cashpayments/${bookingId}`),
}

export default pendingBookingApi