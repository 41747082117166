import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getVisaMasterData = createAsyncThunk("getVisaMasterData", async (token) => {
    try {
        const response = await api.get("get_all_visa", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createVisaMasterData = createAsyncThunk("createVisaMasterData", async (payload) => {
    try {
        const response = await api.post("create_visa", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteVisaMasterData = createAsyncThunk("deleteVisaMasterData", async (id, token) => {
    try {
        const response = await api.delete(`delete_visa/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateVisaMasterData = createAsyncThunk("updateVisaMasterData", async (payload) => {
    console.log("payload", payload)
    try {
        const response = await api.put(`update_visa/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateVisaMasterData = createAsyncThunk("getupdateVisaMasterData", async (id, token) => {
    try {
        const response = await api.get(`get_visa/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const VisaMasterSlice = createSlice({
    name: "VisaMaster",
    initialState: {
        status: "",
        VisaMasterData: null,
        error: null,
        updateVisaMasterData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getVisaMasterData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getVisaMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.VisaMasterData = action.payload
            
        })
        builder.addCase(getVisaMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteVisaMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteVisaMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteVisaMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createVisaMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createVisaMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createVisaMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateVisaMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateVisaMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateVisaMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateVisaMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateVisaMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateVisaMasterData = action.payload

        })
        builder.addCase(getupdateVisaMasterData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default VisaMasterSlice.reducer;