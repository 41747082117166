import Api from "../ApiConfig";

const Registration = {
  getAllStudents: (id) => Api.get(`get_company_students/${id}`),
  getSingleStudent: (id) => Api.get(`get_student/${id}`),
  countryData: () => Api.get(`get_country`),
  createRegistration: (payload) =>
    Api.post(`student_register`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  // createRegistration: (payload) => console.log('payload register student========>', payload),
  studentUpdate: (id, payload) =>
    Api.put(`update_student/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteStudent: (id) => Api.delete(`delete_student/${id}`),
};

export default Registration;
