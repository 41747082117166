import React, { useEffect } from "react";
import "./DashOverview.css";
import userIcon from "../../../Assets/Images/usericon.png";
import { useDispatch, useSelector } from "react-redux";
import api, { ImgApi } from "../../../Apis";
import { baseURL, ImgUrl } from "../../../Config/Config";
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { IoKeyOutline, IoLogOutOutline } from "react-icons/io5";
import { AiFillCheckCircle } from "react-icons/ai";
import { useCookies } from 'react-cookie'
import { useNavigate } from "react-router-dom";
import { setLogout } from "../../../App/Reducers/LoginReducer";
import { USERLOGOUT } from "../../../App/Apis/AuthApi";
import userImg from "../../../Assets/Images/user.jpg";
const UserDp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cookies, removeCookie] = useCookies(["token"]);
  const token = cookies.token;

  const userData = useSelector((state) => state.loginStatus.userData);
  const userDataByID = useSelector((state) => state.getUsers.userDataByID);




  const LOGOUTFunc = async () => {

    USERLOGOUT(token)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          localStorage.setItem("adminloggedIn", false);
          localStorage.setItem("islogout", true);
          localStorage.setItem("isauthorized", false);
          localStorage.setItem("userData", null);
          localStorage.clear();
          dispatch(setLogout({
            status: res.status,
            message: res.data.message,
          }))
          navigate("/login")
        }

        else {
          console.log("error", res.statusText);

          localStorage.setItem("adminloggedIn", false);
          localStorage.setItem("islogout", true);
          localStorage.setItem("isauthorized", false);
          localStorage.setItem("userData", null);
          dispatch(setLogout({
            status: res.status,
            message: res.data.message,
          }))
        }

      })
      .catch((error) => { console.log(error); })

  };
  useEffect(() => {
    const islogout = JSON.parse(localStorage.getItem('islogout'))
    if (islogout) {
      removeCookie('token', { path: "/" })
      navigate("/login");
    }
  }, [JSON.parse(localStorage.getItem('islogout'))]);


  return (
    <div className={"user-dp-container laptop-view mobile-view"}>
      <div className="imgBx">
        {userData?.profile_image ? (
          // <Link to="/">
          <img src={userDataByID ? `${ImgUrl}${userDataByID.profile_image} ` : userImg} alt="user_profile_pic" />
          // </Link>
        ) : (
          <Link to="/">
            <img src={userIcon} alt="user-profile-pic" />
          </Link>
        )}
      </div>
      <div className="user-detail-container">
        <h4>
          Hello,{" "}
          <span style={{ fontSize: "16px" }}>
            {userData?.first_name && userData?.last_name
              ? ` ${userData.first_name} ${userData.last_name}`
              : "username"}
          </span>
        </h4>
        <p>{userData ? userData.company.company_name : "Nable Invent Solution"}</p>
        <Link to={`/dashboard/edit_profile/${userData ? userData.id : null}`}>
          <div>
            <FiSettings />
            <span>My Account</span>
          </div>
        </Link>
        <Link to={`/dashboard/edit_profile/${userData ? userData.id : null}`}>
          <div>
            <IoKeyOutline />
            <span>Change Password</span>
          </div>
        </Link>
        <p>Status:<AiFillCheckCircle className="active" /><span className="active">Active</span></p>
        <button onClick={LOGOUTFunc}>
          <IoLogOutOutline />
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserDp;
