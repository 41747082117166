import api from "../../Apis";

export const GetAllTrainer = async (payload) => {

    try {

        const response = await api.get("get_all_trainer", {
            headers: {
                // "Content-Type": "multipart/form-data",
                Authorization: `token ${payload.token}`
            }
        })
        return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        return error.response
    }
}
