import React, { useEffect, useState } from "react";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import api from "../../Apis";
import { getAllConfirmQuotation } from "../../App/Reducers/QuotationReducer";
import { deleteTempOutpass } from "../../App/Reducers/Stable Slices/TemperoryOutpassSlice";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { Autocomplete, Modal } from "@mui/material";
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { GetUsers } from "../../App/Reducers/UserReducer";
import {
  getTempOpData,
  createTempOpData,
  getTempOpDataID,
  updateTempOpData,
} from "../../App/Reducers/Stable Slices/TemperoryOutpassSlice";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../App/Helpers/DeleteModal";
import AlertMessage from "../../App/Helpers/AlertMessage";
const TemperoryOutpass = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "3px solid #bebb9e",
    borderRadius: "10px",
    height: "fit-content",
    width: "fit-content",
    overflow: "auto",
    p: 4,
  };

  const textStyle = {
    fontSize: "5px",
    marginTop: " 0.5rem",
    color: "black",
    borderRadius: "12px",
  };

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const dispatch = useDispatch();

  const quotation_datas = useSelector(
    (state) => state.quotation.confirmQuotation
  );
  const tempOutPassData = useSelector(
    (state) => state.temperory_outpass.TempOpData
  );
  const quotationHorse = useSelector(
    (state) => state.temperory_outpass.DropDownData.HorseData
  );
  const staffData = useSelector((state) => state.getUsers.users);
  const UpdatetempOutPassData = useSelector(
    (state) => state.temperory_outpass.getDataById
  );
  const tempOutPassStatus = useSelector(
    (state) => state.temperory_outpass.status.Updating
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const [updateBtnToggler, setUpdateBtnToggler] = useState(false);
  const [horseName, setHorseName] = useState([]);
  const [createModalToggler, setCreateModalToggler] = useState(false);
  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addTempOutPassPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_temperoryoutpass"
      )
    : false;
  const changeTempOutPassPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_temperoryoutpass"
      )
    : false;
  const deleteTempOutPassPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_temperoryoutpass"
      )
    : false;

  const [formData, setFormData] = useState({
    quotation: null,
    horse: null,
    staff: null,
    other: null,
    transport: null,
    health_status: null,
    reason: null,
    date: null,
  });

  const getHorseDataByquotationIds = async (id, token) => {
    try {
      const res = await api.get(`get_quotation_tempin_horse/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutoComplete = async (event, value, fieldName) => {
    if (value === null) {
      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,

        // quotation: null,
        // horse: null,
        // staff: null,
        // other: null,
        // transport: null,
        // health_status: null,
        // reason: null,
        // date: null,
      }));
    } else if (fieldName === "quotation") {
      const horseData = await getHorseDataByquotationIds(value.id, token);
      const hData = horseData.map((item) => {
        return { id: item.id, name: item.name };
      });
      setHorseName(() => hData);

      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else {
      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    }
  };

  const HandleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((preData) => {
      return { ...preData, [name]: value };
    });
  };

  const CreateHandlerCheckin = (id) => {
    dispatch(createTempOpData({ Data: formData, token: token }));
    dispatch(getTempOpData(token));
    setCreateModalToggler(false);
  };

  const UpdateHandlerOutpass = () => {
    dispatch(
      updateTempOpData({
        id: formData.id,
        token: token,
        UpdatedData: {
          quotation: formData.quotation,
          horse: formData.horse,
          staff: formData.staff,
          other: formData.other,
          transport: formData.transport,
          health_status: formData.health_status,
          reason: formData.reason,
          date: formData.date,
        },
      })
    );
    setCreateModalToggler(false);
  };

  const colHeader = [
    {
      Header: "Reservation",
      accessor: "quotation.name",
    },
    {
      Header: "Horse",
      accessor: "horse.name",
    },

    {
      Header: "Staff",
      accessor: "staff",
      Cell: ({ row }) => {
        const first_name = row.original.staff?.first_name;
        const last_name = row.original.staff?.last_name;
        const usertype = row.original.staff?.usertype.name;
        return first_name ? `${first_name} ${last_name} (${usertype})` : "";
      },
    },
    {
      Header: "Other",

      accessor: "other",
    },
    {
      Header: "Transport",

      accessor: "transport",
    },
    {
      Header: "Health status",

      accessor: "health_status",
    },
    {
      Header: "Reason",

      accessor: "reason",
    },
    {
      Header: "Date and Time",

      accessor: "date",
      Cell: ({ row }) => {
        // const inputDate = row.original.date;
        // // const formattedDate = format(new Date(inputDate), "dd/MM/yyyy hh:mm a");
        // const dateVal1 = new Date(inputDate);
        // const formattedDate = dateVal1.toISOString().replace('T', ' ').trim().slice(0, 16);

        // return formattedDate;
        const date = new Date(row.original.date);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          // second: '2-digit',
          hour12: true,
          timeZone: "Etc/GMT",
        }).format(date);
        return formattedDate;
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllConfirmQuotation(token));
    dispatch(getTempOpData(token));
    dispatch(GetUsers(token));
  }, [dispatch]);

  useEffect(() => {
    if (UpdatetempOutPassData) {
      // PreFilledData(UpdatetempOutPassData, token)
    } else {
      setFormData({
        quotation: null,
        horse: null,
        staff: null,
        other: null,
        transport: null,
        health_status: null,
        reason: null,
        date: null,
      });
    }
  }, [UpdatetempOutPassData]);

  useEffect(() => {
    dispatch(getTempOpData(token));
  }, [createModalToggler]);

  const handleUpdate = async (id) => {
    try {
      const res = await api.get(`get_temporary_outpass/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
        //     getHorseDataByquotationIds(UpdatetempOutPassData.quotation.id, token)
      });
      const respo = await api.get(
        `get_quotation_tempin_horse/${res.data.quotation.id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      if (Array.isArray(respo.data)) {
        setHorseName(
          respo.data.map((item) => ({ id: item.id, name: item.name }))
        );
      } else {
        console.error("Response is not an array:", respo);
      }
      console.log("Response from get_temporary_outpass:", res);
      console.log("Response from get_quotation_tempin_horse:", respo);
      setFormData({
        id: res.data.id,
        quotation: res.data.quotation.id,
        horse: res.data.horse.id,
        staff: res.data.staff.id,
        other: res.data.other,
        transport: res.data.transport,
        health_status: res.data.health_status,
        reason: res.data.reason,
        date: new Date(res.data.date).toISOString().slice(0, 16),
      });
    } catch (error) {
      console.log("errrororbf", error);
    }
    dispatch(getTempOpData(token));
    setUpdateBtnToggler(true);
    setCreateModalToggler(true);
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(
          `delete_temporary_outpass/${deleteId}`,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getTempOpData(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  const closeModal = () => {
    setCreateModalToggler(false);
  };

  const handleCreate = () => {
    setFormData({
      quotation: null,
      horse: null,
      staff: null,
      other: null,
      transport: null,
      health_status: null,
      reason: null,
      date: null,
    });
    setUpdateBtnToggler(false);
    setCreateModalToggler(true);
  };

  const handllllSumit = (e) => {
    e.preventDefault();
    if (updateBtnToggler) {
      UpdateHandlerOutpass();
    } else {
      CreateHandlerCheckin();
    }
  };

  return (
    <div>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {tempOutPassData ? (
        <BasicTable
          colHeader={colHeader}
          actionFlag={true}
          rowData={tempOutPassData}
          updateHandler={handleUpdate}
          deleteHandler={deleteHandler}
          // createHandler={handleCreate}
          createHandler={
            addTempOutPassPermission || isUserType ? handleCreate : null
          }
          tableHeading="Temporary outpass"
        />
      ) : (
        <BasicTable
          colHeader={colHeader}
          rowData={[]}
          actionFlag={true}
          updateHandler={handleUpdate}
          deleteHandler={deleteHandler}
          // createHandler={handleCreate}
          createHandler={
            addTempOutPassPermission || isUserType ? handleCreate : null
          }
          tableHeading="Temporary outpass"
        />
      )}

      {/* ==========================Create/UpdateCheck_in Modal=============================== */}
      <Modal
        open={createModalToggler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll-bar">
          <Button
            sx={{
              minWidth: "30px",
              color: "#302924",
              border: "0px",
              float: "right",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#e0e0e0", // Remove the border on hover
              },
            }}
          >
            <RxCross2
              style={{ color: "#302924", fontSize: "1.7rem" }}
              onClick={closeModal}
            />
          </Button>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#302924", marginBottom: "5vh", fontWeight: "600" }}
          >
            {updateBtnToggler ? (
              <h3>Update Temporary OutPass</h3>
            ) : (
              <h3>Generate Temporary Outpass</h3>
            )}
          </Typography>

          <form onSubmit={handllllSumit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  RESERVATION <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  name="quotation"
                  value={
                    formData &&
                    formData.quotation &&
                    quotation_datas &&
                    quotation_datas.find(
                      (item) => item.id === Number(formData.quotation)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "quotation")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  disablePortal
                  id="quotation"
                  options={
                    quotation_datas !== undefined
                      ? quotation_datas
                      : [{ id: null, name: "No Quotation Data" }]
                  }
                  getOptionLabel={(option) => `${option.name} (${option.code})`}
                  renderInput={(params) => (
                    <TextField
                      required={true}
                      style={textStyle}
                      placeholder="Select Reservation"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  HORSE NAME<span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    name="horse"
                    value={
                      formData &&
                      formData.horse &&
                      horseName.find(
                        (item) => item.id === Number(formData.horse)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "horse")
                    }
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    disablePortal
                    id="horse"
                    options={horseName}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        required
                        style={textStyle}
                        placeholder="Select Horse"
                        {...params}
                      />
                    )}
                  />
                }
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  STAFF <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  disableClearable
                  name="staff"
                  value={
                    formData &&
                    formData.staff &&
                    staffData.find((item) => item.id === formData.staff)
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "staff")
                  }
                  disablePortal
                  id="staff"
                  options={staffData}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name} (${option.usertype})`
                      ? `${option.first_name} ${option.last_name} (${
                          option.usertype && option.usertype.name
                        })`
                      : null
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      style={textStyle}
                      placeholder="Select staff"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>OTHER</label>
                <TextField
                  type="text"
                  style={textStyle}
                  name="other"
                  value={formData.other}
                  onChange={HandleInputChange}
                  fullWidth
                  // required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  TRANSPORT TYPE <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="text"
                  name="transport"
                  value={formData.transport}
                  style={textStyle}
                  onChange={HandleInputChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  HEALTH STATUS <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="text"
                  name="health_status"
                  style={textStyle}
                  value={formData.health_status}
                  onChange={HandleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  DATE AND TIME <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="datetime-local"
                  name="date"
                  value={formData.date}
                  style={textStyle}
                  onChange={HandleInputChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={8} md={8}>
                <label>
                  REASON <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="textarea"
                  name="reason"
                  value={formData.reason}
                  style={textStyle}
                  onChange={HandleInputChange}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },
                float: "right",
                marginTop: "2rem",
                fontSize: "12px",
                marginInline: "0.5rem",
                backgroundColor: "#302924",
              }}
              // onClick={()=>updateBtnToggler ? UpdateHandlerOutpass():CreateHandlerCheckin()}
            >
              {updateBtnToggler ? "UPDATE" : "GENRATE"}
            </Button>

            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },
                float: "right",
                marginTop: "2rem",
                fontSize: "12px",
                marginInline: "0.5rem",
                backgroundColor: "#302924",
              }}
              onClick={closeModal}
            >
              Close
            </Button>
          </form>
        </Box>
      </Modal>
      {/* ==========================Create /Update Check_in Modal=============================== */}
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Temporary Outpass"
        deleteData={deleteData}
      />
      <ToastContainer />
    </div>
  );
};

export default TemperoryOutpass;
