import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Apis";

export const getFacilityTreeData = createAsyncThunk("getServiceData", async (token) => {

  try {
    const response = await api.get("all_facility_header", {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  } catch (error) {

    throw error;
  }
});

export const createFacilityTreeData = createAsyncThunk("createFacilityTreeData", async (payload) => {
  console.log("payload", payload)
  try {
    const response = await api.post("create_facility_header", payload.modalData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteFacilityTreeData = createAsyncThunk("deleteFacilityTreeData", async ({ id, token }) => {
  try {
    const response = await api.delete(`delete_facility_header/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updateFacilityTreeData = createAsyncThunk("update_facility_header", async (payload) => {
  console.log("payload", payload)
  try {
    const response = await api.put(`update_facility_header/${payload.id}`, payload.updatedData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getUpdateFTData = createAsyncThunk(
  "getUpdateFTData",
  async (id, token) => {
    try {
      const response = await api.get(`get_facility_header/${id}`, {

        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);


const FacilityTreeSlice = createSlice({
  name: "services",
  initialState: {
    facilityTreeData: null,
    facilityServiceTypeData: null,
    update: null,
    status: "",
    updateData: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFacilityTreeData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getFacilityTreeData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.facilityTreeData = action.payload;
    });
    builder.addCase(getFacilityTreeData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(createFacilityTreeData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createFacilityTreeData.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(createFacilityTreeData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(deleteFacilityTreeData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(deleteFacilityTreeData.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(deleteFacilityTreeData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(getUpdateFTData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getUpdateFTData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateData = action.payload;
    });
    builder.addCase(getUpdateFTData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(updateFacilityTreeData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateFacilityTreeData.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.updateData = action.payload
    });
    builder.addCase(updateFacilityTreeData.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default FacilityTreeSlice.reducer;
