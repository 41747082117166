import React, { useEffect, useState } from "react";
import { BasicTable } from "../../../SharedComponets/Table list/BasicTable";
import AlertMessage from "../../../App/Helpers/AlertMessage";
import { useNavigate } from "react-router-dom";
import slotmapping from "../../../App/Apis/RidingSchoolApis/slotmappingAPI/slotmapping";
import Loader from "../../components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { MenuItem } from "@mui/material";
import DropdownMenu from "../../../SharedComponets/Components/DropdownMenu";
import DeleteModal from "../../../App/Helpers/DeleteModal";
import { toast } from "react-toastify";
// DeleteModal
const SlotmappingList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isDelete, setIsDelete] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const columns = [
    {
      Header: "#",
      accessor: "1",
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Slot",
      accessor: "slot1",
      Cell: ({ row }) => {
        return <div>{row.original?.slot?.time}</div>;
      },
    },
    {
      Header: "Course",
      accessor: "slot",
      Cell: ({ row }) => {
        return <div>{row.original?.slot?.course?.course_name}</div>;
      },
    },
    {
      Header: "Student",
      accessor: "student1",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original?.student
              ? `${row.original?.student?.first_name} ${row.original?.student?.last_name} (${row.original?.student?.student_id})`
              : "Not Assigned"}
          </div>
        );
      },
    },
    {
      Header: "Horse",
      accessor: "horse1",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original?.horse?.name} {`(${row.original?.horse?.code})`}
          </div>
        );
      },
    },
    {
      Header: "Trainer",
      accessor: "trainer1",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original?.trainer?.name} {`(${row.original?.trainer?.code})`}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        return (
          <>
            <DropdownMenu
              menus={
                <>
                  <MenuItem
                    onClick={() => handleUpdate(row.original.id)}
                    disableRipple
                  >
                    <EditIcon />
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDelete(row.original.id)}
                    disableRipple
                  >
                    <DeleteIcon sx={{ fontSize: "40px", color: "red" }} />
                    <span>Delete</span>
                  </MenuItem>
                </>
              }
            />
          </>
        );
      },
    },
  ];
  const handleCreate = () => {
    navigate("/riding-club/slot-mapping-create");
  };
  const handleUpdate = (id) => {
    navigate(`/riding-club/slot-mapping-update/${id}`);
  };
  const handleDelete = async (id) => {
    setIsDelete(id);
    setDeleteModalOpen(true);
  };
  const getRequiredData = async () => {
    setIsLoading(true);
    try {
      const res = await slotmapping.all_slot_map();
      console.log(res.data.data);
      setData(res.data.data);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const deleteData = async () => {
    setIsLoading(true);
    if (isDelete) {
      try {
        const res = await slotmapping.delete_slot_map(isDelete);
        toast.success("Delete successfully");
      } catch (error) {
        console.log("error", error);
        toast.error("something went wrong");
      }
      getRequiredData();
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getRequiredData();
  }, []);

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          //   message={isErrorResponse}
          //   isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      <div className="all_table_list">
        {isLoading ? (
          <Loader />
        ) : (
          <BasicTable
            actionFlag={true}
            colHeader={columns}
            rowData={data}
            deleteHandler={handleDelete}
            updateHandler={handleUpdate}
            tableHeading={"Slot allotment"}
            createHandler={handleCreate}
          />
        )}
      </div>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Slot Map"
        deleteData={deleteData}
      />
    </>
  );
};

export default SlotmappingList;
