// FileInput.js
import React from 'react';
import { SiMicrosoftexcel } from "react-icons/si";

const FileInput = ({ onFileSelect, customStyle }) => {
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        onFileSelect(file);
    };

    return (
        <div style={customStyle.container}>
            <label style={customStyle.lebel}>{'Choose Excel'.toUpperCase()}</label>
            <div style={customStyle.inputContent}>
                <label htmlFor="excel-Data">
                    <SiMicrosoftexcel style={customStyle.inputContentIcon} />
                </label>
                <input
                    style={customStyle.fileInput}
                    id='excel-Data'
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                />
            </div>
        </div>
    );
};

export default FileInput;
