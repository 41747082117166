import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { FiArrowRight } from "react-icons/fi";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./studentDashboard.css";
import courses from "../../../../App/Apis/RidingSchoolApis/courseApi/course";
import { LazyLoadImage } from "react-lazy-load-image-component";
import defaultImg from "../../../../Assets/RidingSchoolAssets/eqi5default.jpg";

const StudentDashboard = ({ riding_school }) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [pages, setPages] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const studentToken = JSON.parse(localStorage.getItem("student_token"));

  const getCourses = async () => {
    setIsLoading(true);
    try {
      const res = await courses.getAllCoursesPagination();
      setData(res.data.results);
      setPages(res.data.count);
      setNext(res.data.next);
      setPrev(res.data.previous);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };
  const selectPageHandler = async (selectedPage, search) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(pages / size) &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
      setIsLoading(true);
      try {
        const res = await courses.pagination(selectedPage, query);
        setData(res?.data?.results);
        setPages(res?.data?.count);
        setNext(res?.data?.next);
        setPrev(res?.data?.previous);
      } catch (error) {
        console.log("error", error);
      }
      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (selectedPage === 1) {
      setIsLoading(true);
      try {
        const res = await courses.pagination(selectedPage, search);
        setData(res?.data?.results);
        setPages(res?.data?.count);
        setNext(res?.data?.next);
        setPrev(res?.data?.previous);
      } catch (error) {
        console.log("error", error);
      }
      setIsLoading(false);
    }
  };

  const paginate = ({ current, max }) => {
    if (!current || !max) return null;

    let prev = current === 1 ? null : current - 1,
      next = current === max ? null : current + 1,
      items = [1];

    if (current === 1 && max === 1) return { current, prev, next, items };
    if (current > 4) items.push("…");

    let r = 2,
      r1 = current - r,
      r2 = current + r;

    for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i++) items.push(i);

    if (r2 + 1 < max) items.push("…");
    if (r2 < max) items.push(max);
    return { current, prev, next, items };
  };

  const handleSearch = async (value) => {
    setIsLoading(true);
    if (value) {
      setQuery(value);
      try {
        const res = await courses.pagination(1, query);
        setData(res?.data?.results);
        setPages(res?.data?.count ? res?.data?.count : 2);
        setNext(res?.data?.next);
        setPrev(res?.data?.previous);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setQuery(null);
      getCourses();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCourses();
  }, [studentToken]);

  useEffect(() => {
    if (riding_school) {
      setQuery(riding_school.label);
      selectPageHandler(1, riding_school.label);
    } else {
      setQuery("");
      getCourses();
    }
  }, []);

  return (
    <>
      <header className="studentDashboard__card__container">
        <section className="studentDashboard__right__topSection">
          <div className="studentDashboard__right__info1">
            <div className="studentDashboard__inputs">
              <span>
                <IoSearch />
              </span>
              <input
                type="text"
                value={query}
                placeholder="Search Riding Schools"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </section>

        <span>Total results ({pages}) </span>
        {data.length > 0 ? (
          <section className="studentDashboard__right__bottomSection">
            {data.map((item, index) => (
              <NavLink
                className="studentDashboard__header"
                to={`/course-details/${item.id}`}
              >
                <main className="studentDashboard__main" key={item.id}>
                  <div className="studentDashboard__information">
                    {/* <img src={item?.image} alt="cardimg" /> */}
                    <LazyLoadImage
                      key={item.id}
                      src={item?.image ? item.image : defaultImg}
                      alt="productImage"
                      height={200}
                      width={320}
                      effect="blur"
                      placeholderSrc={item?.image}
                      style={{ objectFit: "cover" }}
                    />
                    <aside>
                      <span>{item?.company.company_name}</span>
                      <p>
                        {item?.lessons} Lessons - {item?.category} -{" "}
                        {item?.sub_category}{" "}
                      </p>
                      <div className="studentDashboard__bottom__details">
                        <span className="studentDashboard__description">
                          {item?.course_name}
                        </span>
                        {/* <p>{item?.lessons} Lessons</p> */}
                        <span>
                          {item?.price.toLocaleString()}&nbsp;{item?.currency}{" "}
                        </span>
                        {/* <span className="studentDashboard__description">
                          {item?.description}
                        </span> */}
                      </div>
                    </aside>
                    <div className="studentDashboard__bookNow">
                      <button>Book Now</button>
                      <span>
                        <MdKeyboardArrowRight />
                      </span>
                    </div>
                  </div>
                  <section className="pagination"></section>
                </main>
              </NavLink>
            ))}
          </section>
        ) : (
          <div className="filtered__containerData">
            <h1>Sorry, we could not find what you were looking for.</h1>
            <span>Try adjusting the dates or removing the filters</span>
            {/* <button onClick={handleRemoveFilters}>Remove all filters</button> */}
          </div>
        )}

        <section className="pagination">
          <span
            className={page > 1 ? "" : "pagination__disable"}
            onClick={() => selectPageHandler(page - 1)}
          >
            <GoArrowLeft />
          </span>
          <div>
            {paginate({
              current: page,
              max: Math.ceil(pages / size),
            }).items?.map((item, index) => (
              <button
                className={
                  page === item
                    ? "pagination__selected"
                    : "pagination__notActive"
                }
                onClick={() => selectPageHandler(item)}
                key={index}
              >
                {" "}
                {item}{" "}
              </button>
            ))}
          </div>
          <span
            className={
              page < Math.ceil(pages / size) ? "" : "pagination__disable"
            }
            onClick={() => selectPageHandler(page + 1)}
          >
            <FiArrowRight />
          </span>
        </section>
      </header>
    </>
  );
};

export default StudentDashboard;
