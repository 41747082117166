import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteGroomingPlaner,
  GetGroomingPlanerList,
  GetUpdatingGroomingPlaner,
} from "../../App/Reducers/GroomingPlanerReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { format } from "date-fns";
import StableLoading from '../../SharedComponets/Loading/StableLoading'
import { useCookies } from 'react-cookie';
const GroomingPlanerList = () => {
  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const State = useSelector((state) => state.GroomingPlaner);
  const [status, setStatus] = useState(State.status.Updating);
  const header = [
    { Header: "ID", accessor: "id" },
    { Header: "Horse Name", accessor: "horse_id.name" },
    { Header: "Grooming", accessor: "grooming" },
    { Header: "Special Treatment", accessor: "special_treatment" },
    { Header: "Discription", accessor: "discription" },
    { Header: "Price", accessor: "price" },
    {
      Header: "Staff",
      accessor: "staff",
      Cell: ({ row }) => {
        const { first_name, last_name } = row.original.staff;
        return `${first_name} ${last_name}`;
      },
    },
    { Header: "Other Staff", accessor: "other_staff" },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },
    },
    {
      Header: "End Date",
      accessor: "end_date",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },
    },
    // { Header: "Is Done", accessor: "is_done" },
  ];
  const CreateHandler = () =>{
    navigate("/dashboard/planer/grooming");
  }
  const UpdateHandler = (id) => {
    dispatch(GetUpdatingGroomingPlaner({id, token}));
  };
  const DeleteHandler = (id) => {
    console.log("delete clicked");
    dispatch(DeleteGroomingPlaner({id, token}));
  };
  useEffect(() => {
    setStatus(State.status.Updating);
    if (status === "succeeded") {
      navigate("/dashboard/planer/grooming");
    }
    dispatch(GetGroomingPlanerList(token));
  }, [dispatch, State.status.Updating, status, navigate, token]);
  return (

    <div className="all_table_list">
      {State.PlanerList && header ? (
        <BasicTable
          colHeader={header}
          rowData={State.PlanerList}
          deleteHandler={DeleteHandler}
          updateHandler={UpdateHandler}
          createHandler={CreateHandler}
          tableHeading={"Grooming Planner List"}
        />
      ) : <StableLoading/>}
    </div>
 
  )
}

export default GroomingPlanerList;