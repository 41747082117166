import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getDocumentTypeMasterData = createAsyncThunk("getDocumentTypeMasterData", async (token) => {
    try {
        const response = await api.get("get_all_document", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createDocumentTypeMasterData = createAsyncThunk("createDocumentTypeMasterData", async (payload) => {
    try {
        const response = await api.post("create_document", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteDocumentTypeMasterData = createAsyncThunk("deleteDocumentTypeMasterData", async (id, token) => {
    try {
        const response = await api.delete(`delete_document/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateDocumentTypeMasterData = createAsyncThunk("updateDocumentTypeMasterData", async (payload) => {
    console.log("payload", payload)
    try {
        const response = await api.put(`update_document/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateDocumentTypeMasterData = createAsyncThunk("getupdateDocumentTypeMasterData", async (id, token) => {
    try {
        const response = await api.get(`get_document/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const DocumentTypeMasterSlice = createSlice({
    name: "DocumentTypeMaster",
    initialState: {
        status: "",
        DocumentTypeMasterData: null,
        error: null,
        updateDocumentTypeMasterData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getDocumentTypeMasterData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getDocumentTypeMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.DocumentTypeMasterData = action.payload
            
        })
        builder.addCase(getDocumentTypeMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteDocumentTypeMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteDocumentTypeMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteDocumentTypeMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createDocumentTypeMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createDocumentTypeMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createDocumentTypeMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateDocumentTypeMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateDocumentTypeMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateDocumentTypeMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateDocumentTypeMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateDocumentTypeMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateDocumentTypeMasterData = action.payload

        })
        builder.addCase(getupdateDocumentTypeMasterData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default DocumentTypeMasterSlice.reducer;