import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";
import { actions } from "react-table";

export const getCategoryMasterData = createAsyncThunk("getCategoryMasterData", async (token) => {
    try {
        const response = await api.get("get_all_category", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createCategoryMasterData = createAsyncThunk("createCategoryMasterData", async (payload) => {
    try {
        const response = await api.post("create_category", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.msg
    }
})

export const deleteCategoryMasterData = createAsyncThunk("deleteCategoryMasterData", async (id, token) => {
    try {
        const response = await api.delete(`delete_category/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateCategoryMasterData = createAsyncThunk("updateCategoryMasterData", async (payload) => {

    try {
        const response = await api.put(`update_category/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateCategoryMasterData = createAsyncThunk("getupdateCategoryMasterData", async (id, token) => {
    try {
        const response = await api.get(`get_category/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const CategoryMasterSlice = createSlice({
    name: "CategoryMaster",
    initialState: {
        status: {
            get : null,
            create : null,
            update : null,
            updating : null,
            delete: null
        },
        CategoryMasterData: null,
        error: null,
        updateCategoryMasterData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getCategoryMasterData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getCategoryMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.CategoryMasterData = action.payload
            
        })
        builder.addCase(getCategoryMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteCategoryMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteCategoryMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteCategoryMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createCategoryMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createCategoryMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createCategoryMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateCategoryMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateCategoryMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateCategoryMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateCategoryMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateCategoryMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateCategoryMasterData = action.payload

        })
        builder.addCase(getupdateCategoryMasterData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default CategoryMasterSlice.reducer;