import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import {
  TextField,
  Grid,
  Autocomplete,
  Button,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonLoading from "../../../SharedComponets/Loading/CommonLoading";
import StableLoading from "../../../SharedComponets/Loading/StableLoading";
import { useCookies } from "react-cookie";
import "./User.css";
import { getCountries } from "../../../App/Reducers/AdminSettingReducer/CountryReducer";
import api from "../../../Apis";
import { ImgUrl } from "../../../Config/Config";
import { toast } from "react-toastify";
import AlertMessage from "../../../App/Helpers/AlertMessage";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const UserRegistrationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fData = new FormData();
  const location = useLocation();

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const countries = useSelector((state) => state.countries.countriesData);

  const [passwordError, setPasswordError] = useState("");
  const [confirmpasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imgError, setImgError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [emailResponse, setEmailResponse] = useState(null);
  const [isVisibila, setIsVisible] = useState(false);
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const [isUpdateVisible, setIsUpdateVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOrgPassword, setShowOrgPassword] = useState(false);
  const [fromOptions, setFromOptions] = useState(null);
  const [toOptions, setToOptions] = useState([]);
  const [toFilterText, setToFilterText] = useState([]);
  const [fromFilterText, setFromFilterText] = useState([]);
  const [fromSearchText, setFromSearchText] = useState("");
  const [toSearchText, setToSearchText] = useState("");
  const [fromtoggable, setFromToggable] = useState(false);
  const [totoggable, setToToggable] = useState(false);
  const [userType, setUserType] = useState(false);
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    phone_number: null,
    country_id: null,
    company_id: null,
    usertype: null,
    email: null,
    address: null,
    image: "",
    city: null,
    state: null,
    password: null,
    password1: null,
    imgFile: null,
  });
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
  };
  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleOrgPasswordToggle = () => {
    setShowOrgPassword(!showOrgPassword);
  };

  const handleImageChange = (e) => {
    const { files } = e.target;

    if (files) {
      const selectedFile = files[0];

      if (!selectedFile) {
        setImgError("");
        return;
      }

      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Invalid file format
        e.target.value = null;
        setFormData((previous) => ({
          ...previous,
          image: null,
        }));
        setImgError(
          "Please upload a file with a .jpg, .jpeg, or .png extension."
        );
      } else if (selectedFile.size > 1024 * 1024) {
        // 1MB
        // File size exceeds 1MB
        e.target.value = null;
        setFormData((previous) => ({
          ...previous,
          image: null,
        }));
        setImgError("Please upload an image that is less than 1MB in size.");
      } else {
        // Valid file format and size, update the form data and clear any errors
        setFormData((previous) => ({
          ...previous,
          image: selectedFile,
          imgFile: null,
        }));
        setImgError("");
      }
    }
  };

  const handleAutoComplete = (event, value, fieldName) => {
    const selectedValue = value ? value.id : null;
    setFormData((prev) => ({
      ...prev,
      [fieldName]: selectedValue,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "email") {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));

      setEmailResponse("");
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handlepassword = (event, value1, name) => {
    const { value } = event.target;
    if (name === "password1") {
      // Check if confirm password matches password
      if (value !== formData.password) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    } else {
      // Update other form fields
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

      // Validation
      let error = "";

      switch (name) {
        case "password":
          if (value.length < 8) {
            error = "Password must be at least 8 characters long";
          } else if (!/[A-Z]/.test(value)) {
            error = "Password must contain at least one uppercase letter";
          } else if (!/[a-z]/.test(value)) {
            error = "Password must contain at least one lowercase letter";
          } else if (!/\d/.test(value)) {
            error = "Password must contain at least one digit";
          } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(value)) {
            error = "Password must contain at least one symbol";
          }
          setPasswordError(error);
          break;
      }
    }
  };

  const handleUserUpdate = async (id) => {
    setIsLoading(true);
    try {
      const response = await api.get(`get_user/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const Data = response.data;

      setFormData({
        first_name: Data.first_name,
        last_name: Data.last_name,
        // phone_number: Data.phone_number,
        country_id: Data.country.id,
        usertype: Data.usertype ? Data.usertype.id : null,
        email: Data.email,
        address: Data.address,
        city: Data.city,
        state: Data.state,
        imgFile: Data.profile_image,
      });
      setPhoneNumber( Data.phone_number)
      setToOptions(Data.user_permissions);
      getUserPermissions(Data.user_permissions);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleToSearchChange = (e) => {
    setToSearchText(e.target.value);
  };

  const handleFromSearchChange = (e) => {
    setFromSearchText(e.target.value);
  };

  const chooseAll = () => {
    setToOptions([...toOptions, ...fromOptions]);
    setFromOptions([]);
    setToToggable(false);
    setFromToggable(false);
    setFromFilterText([]);
    setToFilterText([]);
  };

  const clearAll = () => {
    setFromOptions([...fromOptions, ...toOptions]);
    setToOptions([]);
    setToToggable(false);
    setFromToggable(false);
    setFromFilterText([]);
    setToFilterText([]);
  };

  const toggleFrom = () => {
    const updatedFromOptions = fromOptions.filter((fromOption) => {
      return !fromFilterText.some((filterOption) => {
        // Compare the id and name of the fromOption with fromFilterText
        return (
          fromOption.id === filterOption.id &&
          fromOption.name === filterOption.name
        );
      });
    });
    setToOptions([...fromFilterText, ...toOptions]);
    setFromOptions(updatedFromOptions);
    setFromFilterText([]);
    setFromToggable(false);
    document.getElementById("from-options").selectedIndex = -1;
  };

  const toggleTo = () => {
    const updatedFromOptions = toOptions.filter((fromOption) => {
      return !toFilterText.some((filterOption) => {
        // Compare the id and name of the fromOption with fromFilterText
        return (
          fromOption.id === filterOption.id &&
          fromOption.name === filterOption.name
        );
      });
    });
    setToOptions(updatedFromOptions);
    setFromOptions([...toFilterText, ...fromOptions]);
    setToFilterText([]);
    setToToggable(false);
    document.getElementById("to-options").selectedIndex = -1;
  };

  const handleFromOptionSelect = (e, id, name, index) => {
    const newOption = { id, name };
    setFromToggable(true);
    // const index = filterText.findIndex(option=>option.id === id)
    setFromFilterText((prevFilterText) => {
      const optionIndex = prevFilterText.findIndex(
        (option) => option.id === id
      );

      if (optionIndex !== -1) {
        // Remove the option if it's already present
        const updatedText = [...prevFilterText];
        updatedText.splice(optionIndex, 1);
        return updatedText;
      } else {
        // Add the option if it's not present
        return [...prevFilterText, newOption];
      }
    });
    //   document.getElementById('from-options').options[index].selected  = true
  };

  const handleToOptionSelect = (e, id, name) => {
    const newOption = { id, name };
    // const index = filterText.findIndex(option=>option.id === id)
    setToToggable(true);
    setToFilterText((prevFilterText) => {
      const optionIndex = prevFilterText.findIndex(
        (option) => option.id === id
      );

      if (optionIndex !== -1) {
        // Remove the option if it's already present
        const updatedText = [...prevFilterText];
        updatedText.splice(optionIndex, 1);
        return updatedText;
      } else {
        // Add the option if it's not present
        return [...prevFilterText, newOption];
      }
    });
  };

  const getUserType = async () => {
    try {
      const res = await api.get("get_all_usertype", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      setUserType(res.data);
      console.log("user type data", res.data);
    } catch (error) {
      console.log("usertype error", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userId) {
      fData.append("first_name", formData.first_name);
      fData.append("last_name", formData.last_name);
      fData.append("email", formData.email);
      fData.append("country", formData.country_id);
      fData.append("address", formData.address);
      fData.append("state", formData.state);
      fData.append("usertype", formData.usertype);
      fData.append("phone_number", phoneNumber);
      fData.append("city", formData.city);
      if (toOptions) {
        const userpermissioID = toOptions.map((item) => item.id);
        console.log(
          "toOptions.map((item)=> item.id)",
          toOptions.map((item) => item.id)
        );

        userpermissioID.forEach((item) => {
          fData.append("user_permissions", item);
        });
      }
      if (toOptions.length == 0) {
        fData.append("user_permissions", []);
      }

      // fData.append('password', formData.password)
      if (formData.image) {
        fData.append("profile_image", formData.image);
      }

      // dispatch(UpdateUser({ userId, fData, token }))
      try {
        setLoading(true);
        const response = await api.put(`update_user/${userId}`, fData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "OK" || response.status === "200") {
          setLoading(false);
          setIsUpdateVisible(true);
          navigate("/dashboard/user_list");
          toast.success("User updated successfully")
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    } else {
      fData.append("first_name", formData.first_name);
      fData.append("last_name", formData.last_name);
      fData.append("email", formData.email);
      fData.append("country", formData.country_id);
      fData.append("address", formData.address);
      fData.append("state", formData.state);
      fData.append("usertype", formData.usertype);
      fData.append("phone_number", phoneNumber);
      fData.append("city", formData.city);
      fData.append("password", formData.password);
      fData.append("profile_image", formData.image);
      if (toOptions) {
        const userpermissioID = toOptions.map((item) => item.id);
        console.log(
          "toOptions.map((item)=> item.id)",
          toOptions.map((item) => item.id)
        );

        userpermissioID.forEach((item) => {
          fData.append("user_permissions", item);
        });
      }

      // dispatch(CreateUser({ fData, token }))
      try {
        setLoading(true);
        const response = await api.post("create_user", fData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "Created" || response.status === "201") {
          setLoading(false);
          setIsCreateVisible(true);
          navigate("/dashboard/user_list");
          toast.success("User created successfully")
        }
      } catch (error) {
        setIsVisible(true);
        setEmailResponse(error.response.data.email[0]);

        setTimeout(() => {
          setIsVisible(false);
        }, 3000);
      } finally {
        setLoading(false);
      }
    }
  };

  const getUserPermissions = async (a = "") => {
    try {
      const res = await api.get("permissions/", {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      if (a) {
        const updateFromOptions = res.data.filter((fromOption) => {
          return !a.some((filterOption) => {
            // Compare the id and name of the fromOption with fromFilterText
            return (
              fromOption.id === filterOption.id &&
              fromOption.name === filterOption.name
            );
          });
        });
        setFromOptions(updateFromOptions);
      } else {
        setFromOptions(res.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getCountries(token));
    getUserType();
    try {
      const id = location.pathname.split("/")[3];

      if (id) {
        setUserId(id);
        handleUserUpdate(id);
      } else {
        getUserPermissions();
      }
    } catch (error) {}
  }, []);

  return isLoading ? (
    <StableLoading />
  ) : (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={emailResponse}
          isVisibila={isVisibila}
          severity="error"
        />
        <AlertMessage
          message="User created successfully"
          isVisibila={isCreateVisible}
          severity="success"
        />
        <AlertMessage
          message="User updated successfully"
          isVisibila={isUpdateVisible}
          severity="success"
        />
      </div>
      <div className="form-wrapper-container">
        <div
          className="registration-card"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <header>
            <div className="registration_top_header">
              {userId ? <p>Update User</p> : <p>User Registration</p>}
              <span>
                Please fill in all required fields proceed with the
                registration.
              </span>
            </div>
          </header>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="inputparts-checkIn">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    First Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type="text"
                    className="inputfield bg-color"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                    fullWidth
                    placeholder="First Name"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    Last Name <span style={{ color: "red" }}>*</span>
                  </label>

                  <TextField
                    type="text"
                    className="inputfield bg-color"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                    fullWidth
                    placeholder="Last Name"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    Email<span style={{ color: "red" }}>*</span>
                  </label>

                  <TextField
                    type="email"
                    className="inputfield bg-color"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    fullWidth
                    placeholder="Enter Email"
                  />
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {emailResponse}
                  </span>
                </Grid>
              </Grid>
            </div>
            <div className="inputparts-checkIn">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    Phone Number <span style={{ color: "red" }}>*</span> </label>
                  {/*
                  <TextField
                    type="number"
                    className="inputfield bg-color"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    required
                    fullWidth
                    placeholder="Enter Phone Number"
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                  /> */}
                   <PhoneInput
                    country={"in"}
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    style={{marginTop : '8px'}}
                    enableSearch={true}
                    countryCodeEditable={false}
                    disableSearchIcon={true}
                    inputProps={{
                     
                      required: true,
                      autoFocus: true,
                    }}
                />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    City <span style={{ color: "red" }}>*</span>
                  </label>

                  <TextField
                    type="text"
                    className="inputfield bg-color"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                    fullWidth
                    placeholder="Enter City"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    State<span style={{ color: "red" }}>*</span>
                  </label>

                  <TextField
                    type="text"
                    className="inputfield bg-color"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                    fullWidth
                    placeholder="Enter State"
                  />
                </Grid>
              </Grid>
            </div>
            <div className="inputparts-checkIn">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    Country <span style={{ color: "red" }}>*</span>
                  </label>
                  {
                    <Autocomplete
                      name="country_id"
                      disablePortal
                      id="country"
                      options={countries}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      value={
                        formData &&
                        formData.country_id &&
                        countries &&
                        countries.find(
                          (item) => item.id === Number(formData.country_id)
                        )
                      }
                      // disabled={checkin_id ? true : false}

                      onChange={(event, value) =>
                        handleAutoComplete(event, value, "country_id")
                      }
                      sx={{
                        color: "#302924",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          className="bg-color"
                          placeholder="Select Country"
                        />
                      )}
                    />
                  }
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    Address<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type="text"
                    className="inputfield bg-color"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                    fullWidth
                    placeholder="Enter Address"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    User Type<span style={{ color: "red" }}>*</span>
                  </label>

                  <Autocomplete
                    name="usertype"
                    disablePortal
                    id="usertype"
                    autoComplete="off"
                    options={userType}
                    getOptionLabel={(option) => option.name}
                    value={
                      formData &&
                      formData.usertype &&
                      userType &&
                      userType.find(
                        (item) => item.id === Number(formData.usertype)
                      )
                    }
                    // disabled={checkin_id ? true : false}

                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "usertype")
                    }
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        className="bg-color"
                        placeholder="Select User Type"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="inputparts-checkIn">
              <Grid container spacing={2}>
                {!userId && (
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="password-input-container">
                      <label>
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        type={showOrgPassword ? "text" : "password"}
                        autoComplete="off"
                        className="inputfield bg-color"
                        name="password"
                        value={formData.password}
                        onChange={(event, value) =>
                          handlepassword(event, value, "password")
                        }
                        required
                        fullWidth
                        placeholder="Enter Password"
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      <i
                        className={`password-toggle-icon  ${
                          showOrgPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }`}
                        style={{ marginBlock: "0.8rem" }}
                        onClick={handleOrgPasswordToggle}
                      ></i>
                      <p style={{ color: "red", fontSize: "13px" }}>
                        {passwordError}
                      </p>
                    </div>
                  </Grid>
                )}

                {!userId && (
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="password-input-container">
                      <label>
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        autoComplete="off"
                        className="inputfield bg-color"
                        name="password1"
                        value={formData.password1}
                        onChange={(event, value) =>
                          handlepassword(event, value, "password1")
                        }
                        required
                        fullWidth
                        placeholder="Confirm Password"
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                      />
                      <i
                        className={`password-toggle-icon  ${
                          showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }`}
                        style={{ marginBlock: "0.8rem" }}
                        onClick={handlePasswordToggle}
                      ></i>
                      <p style={{ color: "red", fontSize: "13px" }}>
                        {confirmpasswordError}
                      </p>
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={4}>
                  <label>
                    Profile Image<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    className="bg-color"
                    id="upload-photo"
                    name="profile_image"
                    type="file"
                    onChange={handleImageChange}
                    required={!userId}
                    style={{ width: "100%" }}
                  />
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {imgError}
                  </span>
                </Grid>

                {formData.imgFile && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div style={{ margin: "5%" }}>
                        <ImageList
                          sx={{ width: 400, height: "auto" }}
                          cols={2}
                          rowHeight={"auto"}
                        >
                          <ImageListItem>
                            {formData.imgFile && (
                              <img
                                src={`${ImgUrl}${formData.imgFile}`}
                                alt="Uploaded file"
                                loading="lazy"
                              />
                            )}
                            {formData.image && (
                              <img
                                src={URL.createObjectURL(formData.image)}
                                alt="Uploaded file"
                                loading="lazy"
                              />
                            )}
                          </ImageListItem>
                        </ImageList>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "12px",
                paddingInline: "10px",
                marginBlock: "2rem",
              }}
            >
              {/* Left side user permission for choose all */}
              <div
                style={{
                  height: "500px",
                  width: "100%",
                  border: "1px solid black",
                }}
              >
                <div style={{ backgroundColor: "rgb(154, 129, 75)" }}>
                  <p
                    style={{
                      textAlign: "center",
                      padding: "8px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Available user permissions{" "}
                  </p>
                  <hr />
                </div>
                <div style={{ padding: "10px" }}>
                  <input
                    onChange={handleFromSearchChange}
                    style={{
                      padding: "5px",
                      width: "100%",
                      fontSize: "14.5px",
                    }}
                    type="search"
                    placeholder="search"
                  />
                </div>
                <div style={{ height: "73%" }}>
                  <hr />
                  <select
                    multiple
                    name=""
                    id="from-options"
                    style={{
                      width: "100%",
                      padding: "6px 8px",
                      height: "100%",
                      border: "0",
                      fontSize: "16px",
                    }}
                  >
                    {fromSearchText
                      ? fromOptions
                          .filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(fromSearchText.toLowerCase())
                          )
                          .map((item, index) => (
                            <option
                              onClick={(e) =>
                                handleFromOptionSelect(
                                  e,
                                  item.id,
                                  item.name,
                                  index
                                )
                              }
                              key={index}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))
                      : fromOptions
                      ? fromOptions.map((item, index) => (
                          <option
                            onClick={(e) =>
                              handleFromOptionSelect(
                                e,
                                item.id,
                                item.name,
                                index
                              )
                            }
                            key={index}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <hr />
                </div>
                <div>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "8px",
                    }}
                  >
                    <span onClick={chooseAll} style={{ cursor: "pointer" }}>
                      Choose All
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <ArrowCircleRightIcon
                      onClick={chooseAll}
                      style={{ cursor: "pointer" }}
                    />
                  </p>
                </div>
              </div>

              <div
                style={{
                  height: "500px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "20px",
                  gap: "10px",
                }}
              >
                <button
                  type="button"
                  onClick={toggleFrom}
                  style={{ cursor: "pointer" }}
                  disabled={!fromtoggable}
                >
                  <ArrowCircleRightIcon />
                </button>

                <button
                  type="button"
                  onClick={toggleTo}
                  style={{ cursor: "pointer" }}
                  disabled={!totoggable}
                >
                  <ArrowCircleLeftIcon />
                </button>
              </div>

              <div
                style={{
                  height: "500px",
                  width: "100%",
                  border: "1px solid black",
                }}
              >
                <div style={{ backgroundColor: "rgb(154, 129, 75)" }}>
                  <p
                    style={{
                      textAlign: "center",
                      padding: "8px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Chosen user permissions{" "}
                  </p>
                  <hr />
                </div>
                <div style={{ padding: "10px" }}>
                  <input
                    onChange={handleToSearchChange}
                    style={{
                      padding: "5px",
                      width: "100%",
                      fontSize: "14.5px",
                    }}
                    type="search"
                    placeholder="search"
                  />
                </div>
                <div style={{ height: "73%" }}>
                  <hr />
                  <select
                    multiple
                    name=""
                    id="to-options"
                    style={{
                      width: "100%",
                      padding: "6px 8px",
                      height: "100%",
                      border: "0",
                      fontSize: "16px",
                    }}
                  >
                    {toSearchText
                      ? toOptions
                          .filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(toSearchText.toLowerCase())
                          )
                          .map((item, index) => (
                            <option
                              onClick={(e) =>
                                handleToOptionSelect(e, item.id, item.name)
                              }
                              key={index}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))
                      : toOptions
                      ? toOptions.map((item, index) => (
                          <option
                            onClick={(e) =>
                              handleToOptionSelect(e, item.id, item.name)
                            }
                            key={index}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <hr />
                </div>
                <div>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "8px",
                    }}
                  >
                    <ArrowCircleLeftIcon
                      onClick={clearAll}
                      style={{ cursor: "pointer" }}
                    />
                    &nbsp;&nbsp;
                    <span onClick={clearAll} style={{ cursor: "pointer" }}>
                      Remove All
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="btn-submit" style={{ gap: "15px" }}>
              <Button
                type="submit"
                variant="contained"
                className="btn-bgColor"
                onClick={() => navigate("/dashboard/user_list")}
              >
                Cancel
              </Button>

              {loading ? (
                <CommonLoading />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="btn-bgColor"
                >
                  {userId ? "Update" : "Create"}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserRegistrationForm;
