import axios from 'axios';
import { baseURL, ImgUrl } from "./Config/Config";

const api = axios.create({
  baseURL: baseURL, // Replace with your base URL
  // Other default configuration options can be added here
});

const ImgApi = axios.create({
  imgURL: ImgUrl
})
export { ImgApi }
export default api;




// import axios from "axios";
// import React, { useEffect } from "react";
// import { baseURL, ImgUrl } from "./Config/Config";
// import { useDispatch } from "react-redux";
// import { useCookies } from "react-cookie";

// const api = () => {
//   const [cookies, setCookies] = useCookies(["token"]);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     const instance = axios.create({
//       baseURL: baseURL,
//     });

//     const token = cookies.token;

//     if (token) {
//       instance.defaults.headers.common["Authorization"] = token;
//     }

//     instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

//     instance.interceptors.response.use(
//       function (response) {
//         // Do something with response data
//         return response;
//       },
//       function (error) {
//         // Do something with response error
//         if (!error.response) {
//           // Handle error
//         } else if (error.response.status === 403) {
//           // Handle error
//           dispatch({ type: "LOGOUT" }); // Assuming you have a Redux action for logging out
//         } else if (error.response.status === 401) {
//           dispatch({ type: "LOGOUT" }); // Handle error and dispatch logout action
//         } else {
//           // Handle other errors
//         }
//         return Promise.reject("Server error");
//       }
//     );
//   }, [dispatch]);

//   // Return null or a component if needed
//   return null;
// };

// export default api;


// use

// import AxiosInstance from "./AxiosInstance"; // Import the Axios instance

// const API_VERSION = "v1"; // Replace with your API version

// export const GetSearchApplicants = async (payload) => {
//   try {
//     const response = await AxiosInstance.post(
//       `/${API_VERSION}/webapp/recruiter/get-search-applicants`,
//       {
//         currentPage: payload.current,
//         perPage: payload.pageSize,
//         search: payload.searchText,
//         city_id: payload.city_id,
//         country_id: payload.country_id || "",
//       }
//     );

//     // You can handle the response here, e.g., return data or dispatch an action
//     return response.data;
//   } catch (error) {
//     // Handle errors here, e.g., log the error or throw it further
//     console.error("Error in GetSearchApplicants:", error);
//     throw error;
//   }
// };
