import {
  Box,
  Button,
  Grid,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineStarOutline, MdStarRate } from "react-icons/md";

import "../../SharedComponets/Dashboard/Need/Need.css";
import { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import api from "../../Apis";

const FeedbackModal = (props) => {
  const style = {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    height: "fit-content",
    overflow: "auto",
    p: 4,
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };

  const starIcon = {
    color: "#c39c30",
    fontSize: "40px",
    cursor: "pointer",
  };

  const easyUseData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];

  const performanceData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const [formData, setFormData] = useState({
    easyUse: [],
    performance: [],
    remark: null,
  });

  const easyUseHandler = (id) => {
    console.log("Click handled for ID use:", id);

    console.log("Before:", formData.easyUse);

    const isIdExist = formData.easyUse.includes(id);

    if (isIdExist) {
      const updatedEasyUse = formData.easyUse.filter((item) => item !== id);
      setFormData((prevFormData) => ({
        ...prevFormData,
        easyUse: updatedEasyUse,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        easyUse: [...prevFormData.easyUse, id],
      }));
    }
  };

  const performanceHandler = (id) => {
    console.log("Click handled for ID use:", id);

    console.log("Before:", formData.performance);

    const isIdExist = formData.performance.includes(id);

    if (isIdExist) {
      const updatedEasyUse = formData.performance.filter((item) => item !== id);
      setFormData((prevFormData) => ({
        ...prevFormData,
        performance: updatedEasyUse,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        performance: [...prevFormData.performance, id],
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const fData = new FormData();
    fData.append("easyUseLength", formData.easyUse.length);
    fData.append("performanceLength", formData.performance.length);
    fData.append("remark", formData.remark);

    try {
      const response = await api.post(" ", fData, {
        headers: {
          authorization: `token ${token}`,
        },
      });

      if (
        response.status === "201" ||
        response.statusText === "OK" ||
        response.status === "200" ||
        response.statusText === "Created"
      ) {
        props.feedClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log("formData", formData);
  }, []);

  return (
    <Modal
      open={props.feedOpen}
      onClose={props.feedClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="scroll-bar">
        <Button
          sx={{
            minWidth: "30px",
            color: "#302924",
            border: "0px",
            float: "right",
            padding: "0px",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          <RxCross2
            style={{ color: "#302924", fontSize: "1.7rem" }}
            onClick={props.feedClose}
          />
        </Button>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            color: "#302924",
            marginBottom: "5vh",
            fontWeight: "600",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          Please rate your experience below
        </Typography>

        <form onSubmit={handleSubmit}>
          <div className="feed-value-container">
            <div className="feed-value-rate">
              <h4 htmlFor="esay_to_use">Easy to use</h4>
              <h4 htmlFor="overall_h4erformance">
                Overall Performance and Design
              </h4>
            </div>

            <div>
              <ul style={{ listStyle: "none", display: "flex" }}>
                {easyUseData.map((item) => (
                  <li key={item.id} onClick={() => easyUseHandler(item.id)}>
                    {formData.easyUse.includes(item.id) ? (
                      <MdStarRate style={starIcon} />
                    ) : (
                      <MdOutlineStarOutline style={starIcon} />
                    )}
                  </li>
                ))}
              </ul>

              <ul style={{ listStyle: "none", display: "flex" }}>
                {performanceData.map((item) => (
                  <li key={item.id} onClick={() => performanceHandler(item.id)}>
                    {formData.performance.includes(item.id) ? (
                      <MdStarRate style={starIcon} />
                    ) : (
                      <MdOutlineStarOutline style={starIcon} />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <Grid item xs={12} sm={12} md={12}>
            <div className="feed-value-comment">
              <h5 htmlFor="comments">SUGGESSIONS / COMMENTS (OPTIONAL)</h5>
              <TextareaAutosize
                style={{ width: "100%", fontSize: "16px", padding: "10px" , backgroundColor:"#e0e0e0"}}
                onChange={handleChange}
                minRows={8}
                multiline
                rows={4}
                name="remark"
                fullWidth
              />
            </div>
          </Grid>
          {/* ... (your existing code) */}

          <Button
            variant="outlined"
            type="submit"
            sx={{
              color: "#fff",
              borderColor: "#302924",
              "&:hover": {
                borderColor: "#302924",
                color: "#302924",
              },
              float: "right",
              marginTop: "2rem",
              fontSize: "12px",
              marginInline: "0.5rem",
              backgroundColor: "#302924",
            }}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#fff",
              borderColor: "#302924",
              "&:hover": {
                borderColor: "#302924",
                color: "#302924",
              },
              float: "right",
              marginTop: "2rem",
              fontSize: "12px",
              marginInline: "0.5rem",
              backgroundColor: "#302924",
            }}
            onClick={props.feedClose}
          >
            Close
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
