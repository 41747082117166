import React, { useEffect, useState } from "react";
import { BasicTable } from "../../../SharedComponets/Table list/BasicTable";
import pendingBookingApi from "../../../App/Apis/RidingSchoolApis/PendingBooking/PendingBooking";
import { companyId } from "../../../App/Apis/RidingSchoolReuseIds/Ids";
import { Badge } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Loader from "../../components/Loader";

function PendingBookings() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [approveId, setApproveId] = useState(null);
  const handleOpen = (id) => {
    setApproveId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await pendingBookingApi.getPendingBookings(companyId);
      setData(res.data);
    } catch (error) {
      console.log("eror", error);
    }
    setIsLoading(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const UpdateHandler = async () => {
    console.log("idd", approveId);

    try {
      const res = await pendingBookingApi.approvePendingBookings(approveId);
      getData();
    } catch (error) {
      console.log("error", error);
    }
    handleClose();
  };

  useEffect(() => {
    getData();
  }, []);
  const columns = [
    {
      Header: "Student",
      accessor: "student",
      Cell: ({ row }) => (
        <div>{`${row?.original?.student_id?.first_name} ${row?.original?.student_id?.last_name}`}</div>
      ),
    },
    {
      Header: "Course",
      accessor: "course_name",
    },

    {
      Header: "Category",
      accessor: "category",
      Cell: ({ row }) => <div>{row?.original?.courses_type?.category}</div>,
    },
    {
      Header: "Persons",
      accessor: "course_persons",
    },
    {
      Header: "Slot",
      accessor: "course_slot",
    },
    {
      Header: "Price",
      accessor: "course_total_price",
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Cash Pay</div>,
      accessor: "pending",
      Cell: ({ row }) => (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Badge
            style={{ position: "absolute", textWrap: "nowrap" }}
            color={row.original.is_payment_done == true ? "success" : "warning"}
            badgeContent={
              row.original.is_payment_done == true ? "Payment Done" : "Pending"
            }
          />
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <BasicTable
        actionFlag={false}
        colHeader={columns}
        rowData={data}
        tableHeading="Pending Bookings"
        updateHandler={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            style={{ textAlign: "center" }}
            component="h4"
          >
            Pending Booking
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are your sure do you want to confirm payment
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "0.5rem",
            }}
          >
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={UpdateHandler}>Yes</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default PendingBookings;
