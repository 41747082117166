import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import ModalComp from "./Modal";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import api from "../../Apis";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../App/Helpers/DeleteModal";
import AlertMessage from "../../App/Helpers/AlertMessage";
import { createEventTypeData, getEventTypeData, updateEventType, updateEventTypeData } from "../../App/Reducers/AdminSettingReducer/EventTypeReducer";


const EventType = () => {
  const dispatch = useDispatch();
  // const VisaMasterData = useSelector((state) => state.VisaMaster.VisaMasterData);
  const [modalOpen, setModalOpen] = useState(false);
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const [eventList, setEventList] = useState(null);
  const [modalData, setModalData] = useState({ name: "" });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);


  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addEventTypePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_eventtype"
      )
    : false;
  const changeEventTypePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_eventtype"
      )
    : false;
  const deleteEventTypePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_eventtype"
      )
    : false;

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createOrUpdateHandler = async () => {
    if (modalData.id) {
      try {
        const res = await api.put(
          `update_event_type/${modalData.id}`,
          modalData,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        setEventList(res.data)
      } catch (error) {
        console.log("error", error);
      }
      // dispatch(updateEventType({id : modalData.id, updateData : modalData, token}))
    } else {
        if(modalData.name !== null) {
          dispatch(createEventTypeData({modalData, token }))
        }
    }
    closeModal();
    dispatch(getEventTypeData(token));
    eventLsitapi()
  };

  const editHandler = (id) => {
    const editData = eventList.find((data) => data.id === id);
    if (editData) {
      setModalData(editData);
      setModalOpen(true);
    }
  };

  const deleteHandler = async (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const res = await api.delete(`delete_event_type/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (res.status === "200" || res.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          eventLsitapi();
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  const openModal = () => {
    setModalData({
      name: "",
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const eventLsitapi = async () => {
    try {
      const res = await api.get("get_event_type_list", {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      setEventList(res.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    eventLsitapi();
  }, []);

  const header = [
    {
      Header: "Event Type",
      accessor: "name",
    },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>
      {eventList ? (
        <BasicTable
          colHeader={header}
          rowData={eventList}
          actionFlag={
            deleteEventTypePermission || changeEventTypePermission || isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeEventTypePermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteEventTypePermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addEventTypePermission || isUserType ? openModal : null
          }
          tableHeading="Event Type List"
        />
      ) : (
        <StableLoading />
      )}

      <ModalComp
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        label="ADD EVENT TYPE"
        heading="Event Type"
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Event Type"
        deleteData={deleteData}
      />

      <ToastContainer />
    </>
  );
};
export default EventType;
