import React, { useEffect, useState } from "react";
import SideBar from "../../SharedComponets/Dashboard/Sidebar/SideBar";
import TopBar from "../../SharedComponets/Dashboard/TopBar/TopBar";
import ContentBar from "../../SharedComponets/Dashboard/ContentBar/ContentBar";
import "./Dashboard.css";
import { useSelector, useDispatch } from "react-redux";
import MobileSidebar from "../../SharedComponets/Dashboard/Sidebar/MobileSidebar";
import { ToastContainer, toast } from "react-toastify";
const DashboardLayout = ({ Layout }) => {
  const { mobileNavbar } = useSelector((state) => state.modal);
  const [width, setWidth] = useState(window.innerWidth);
  const [isshowNavbar, setIsShowNavbar] = useState(mobileNavbar);
  useEffect(() => {
    setWidth(window.innerWidth);
    setIsShowNavbar(mobileNavbar);
    console.log(isshowNavbar, "isshowNavbar");
  }, [mobileNavbar, isshowNavbar]);
  return (
    <>
      <ToastContainer />
      <div className="dashboardlayout">
        <TopBar />
        <div className="main-content">
          <SideBar Layout={Layout} />
          <ContentBar />
        </div>
        <div className="footer2">
          <div className="sub-footer">
            <div className="footer-container">
              <p>Copyright © 2024 EQI5 - All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
      <MobileSidebar />
    </>
  );
};

export default DashboardLayout;
