import "./TrainerRegistration.css";
import {
  Checkbox,
  TextField,
  Button,
  Grid,
  ImageList,
  ImageListItem,
  TextareaAutosize,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateTrainer,
  GetUpdatingTrainer,
  UpdateTrainer,
} from "../../App/Reducers/TrainerReducer";
import { toast } from "react-toastify";
import { getDivisionMasterData } from "../../App/Reducers/AdminSettingReducer/DivisionMasterReducer";
import { getDocumentTypeMasterData } from "../../App/Reducers/AdminSettingReducer/DocumentTypeMasterReducer";
import { getVisaMasterData } from "../../App/Reducers/AdminSettingReducer/VisaMasterReducer";
import {
  getCountries,
  getISDcode,
} from "../../App/Reducers/AdminSettingReducer/CountryReducer";
import { useCookies } from "react-cookie";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ImgUrl } from "../../Config/Config";
import { FaFilePdf } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import api from "../../Apis";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import CommonLoading from "../../SharedComponets/Loading/CommonLoading";
import DeleteModal from "../../App/Helpers/DeleteModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const TrainerRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fData = new FormData();
  const { id } = useParams();

  const genders = ["Male", "Female", "Other"];

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  //Date Formate
  const today = new Date().toISOString().split("T")[0];

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [index, setIndex] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [postBoxError, setPostBoxError] = useState(false);
  const [bloodGroupError, setBloodGroupError] = useState(false);
  const [attachDoc, setAttachDoc] = useState(null);
  const [imgError, setImgError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
  };

  //Get Data by store
  const updatedTrainer = useSelector((state) => state.trainers.UpdatingTrainer);
  const division = useSelector(
    (state) => state.divisionMaster.DivisionMasterData
  );
  const docType = useSelector(
    (state) => state.DocumentTypeMaster.DocumentTypeMasterData
  );
  const visa_category = useSelector((state) => state.VisaMaster.VisaMasterData);
  const countries = useSelector((state) => state.countries.countriesData);
  // const ISD_code = useSelector((state) => state.countries.isd_code);

  const [formData, setFormData] = useState({
    id: null,
    national_id: null,
    visa_category: null,
    division: null,
    name: null,
    gender: null,
    date_of_birth: null,
    nationality: null,
    city: null,
    address: null,
    post_box: null,
    training_experience: null,
    achievements: null,
    country: null,
    email: null,
    // isd_code: null,
    mobile: null,
    image: "",
    imgFile: null,
    remark: null,
    blood_group: null,
    files: [
      {
        // id: 0,
        // document_type: null,
        // issue_date: null,
        // expiry_date: null,
        // uploaded_file: [],
      },
    ],
    files1: [],
    checkedvalue: [],
  });

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    console.log("email", name, value);
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (value.match(validRegex)) {
      setEmailError("");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      // console.log('forndataatta',formData);
    } else {
      setEmailError("Invalid email address!");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: null,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "checkedvalue") {
      // If it's a checkedvalue checkbox
      if (checked) {
        // Add the value to the checkedvalue array
        setFormData((previous) => ({
          ...previous,
          checkedvalue: [...previous.checkedvalue, value],
        }));
      }
    } else {
      // For other input fields, update the corresponding value in the formData state
      if (name === "post_box") {
        // Validate that "post_box" only contains numbers
        if (/^[0-9]*$/.test(value) || value === "") {
          setPostBoxError(false); // No error if input is valid
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        } else {
          setPostBoxError(true); // Set error if input is invalid
        }
      } else if (name === "blood_group") {
        // Validate blood_group to allow only 'a' to 'z', 'A' to 'Z', '+', and '-'
        if (/^[A-Za-z\s\+\-]*$/.test(value) || value === "") {
          setBloodGroupError(false); // No error if input is valid
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        } else {
          setBloodGroupError(true); // Set error if input is invalid
        }
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    }
  };

  // Select IMAGE Handler or  onchange values
  const handleImageChange = (e) => {
    const { files } = e.target;

    if (files) {
      const selectedFile = files[0];

      if (!selectedFile) {
        // No file selected, clear any previous errors
        setImgError("");
        return;
      }

      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Invalid file format
        e.target.value = null;
        setFormData((previous) => ({
          ...previous,
          image: null,
        }));
        setImgError(
          "Please upload a file with a .jpg, .jpeg, or .png extension."
        );
      } else if (selectedFile.size > 1024 * 1024) {
        // 1MB
        // File size exceeds 1MB
        e.target.value = null;
        setFormData((previous) => ({
          ...previous,
          image: null,
        }));
        setImgError("Please upload an image that is less than 1MB in size.");
      } else {
        // Valid file format and size, update the form data and clear any errors
        setFormData((previous) => ({
          ...previous,
          image: selectedFile,
        }));
        setImgError("");
      }
    }
  };

  const handleAutoComplete = (event, value, fieldName) => {
    const selectedValue = value ? value.id : null;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValue,
    }));
  };

  const handleAutoCompleteStatic = (event, newValue, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const handleACDocTypeFields = (event, value, field, index) => {
    const updatedFiles = [...formData.files];
    const updatedFile = { ...updatedFiles[index] }; // Create a copy of the object
    updatedFile[field] = value ? value.id : null; // Update the desired property
    updatedFiles[index] = updatedFile; // Replace the object in the array
    setFormData({ ...formData, files: updatedFiles });
  };

  const handleACDocFields = (event, newValue, fieldName) => {
    const actualVal = event.target.value == 0 ? newValue : event.target.value;
    setFormData((prevState) => {
      const updatedDocuments = [...prevState.files];

      updatedDocuments[updatedDocuments.length - 1] = {
        ...updatedDocuments[updatedDocuments.length - 1],
        ...{ [fieldName]: actualVal },
      };

      return {
        ...prevState,
        files: updatedDocuments,
      };
    });
  };

  const docUpload = (event, index) => {
    const file = event.target.files[0];
    const { name, value, type } = event.target;

    setFormData((prevState) => {
      const updatedDocuments = [...prevState.files];
      updatedDocuments[index] = {
        ...updatedDocuments[index],
        [name]: value,
        uploaded_file: file,
      };

      if (type === "file" && name === "uploaded_file") {
        if (event.target.files.length > 0) {
          const file = event.target.files[0];
          const fileSizeLimit = 1 * 1024 * 1024; // 1 MB in bytes
          const fileType = file.type;

          if (fileType === "application/pdf") {
            if (file.size <= fileSizeLimit) {
              // Update the files array in the formData state
              setFormData((previous) => ({
                ...previous,
                files: updatedDocuments,
              }));
              setAttachDoc(" ");
            } else {
              setAttachDoc("PDF file size exceeds 1 MB limit");
            }
          } else {
            // Clear the file input by setting the value to null
            event.target.value = null;
            setAttachDoc("Please select a PDF file");
          }
        }
      }

      return {
        ...prevState,
        files: updatedDocuments,
      };
    });
  };

  const docRender = () => {
    setFormData((prevState) => ({
      ...prevState,
      files: [
        ...prevState.files,
        {
          // id: formData.documents.id + 1,
          // id: formData.documents[0].id + 1,
          // document_type: null,
          // issue_date: null,
          // expiry_date: null,
          // uploaded_file: []
        },
      ],
    }));
  };

  const handleremove = () => {
    if (updatedTrainer) {
      try {
        api.delete(`delete_trainer_file/${updatedTrainer.files[index].id}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        // Update the state after a successful API call
        setFormData((prevState) => {
          const updateDocs = [...prevState.files1];
          updateDocs.splice(index, 1);
          return {
            ...prevState,
            files1: updateDocs,
          };
        });
      } catch (error) {
        throw error; // You can handle the error appropriately
      }
    }
  };

  const deleteHandler = async (index) => {
    setIndex(index);
    setDeleteModalOpen(true);
  };

  const handleremove1 = (index) => {
    if (updatedTrainer) {
      setFormData((prevState) => {
        const updateDocs = [...prevState.files];
        updateDocs.splice(index, 1);
        return {
          ...prevState,
          files: updateDocs,
        };
      });
    }
    if (!updatedTrainer && formData.files.length !== 1) {
      setFormData((prevState) => {
        const updateDocs = [...prevState.files];
        updateDocs.splice(index, 1);
        return {
          ...prevState,
          files: updateDocs,
        };
      });
    }
  };

  const handleupdateTrainer = async (id) => {
    try {
      setIsLoading(true);
      const response = await api.get(`get_trainer/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // Assuming the response data contains the trainer's information
      const updatedTrainer = response.data;
      // Set isUpdateMode to true to indicate you are in update mode
      setIsUpdateMode(true);
      // Set the formData with the updatedTrainer data
      setFormData({
        id: updatedTrainer && updatedTrainer.id ? updatedTrainer.id : null,
        national_id:
          updatedTrainer && updatedTrainer.national_id
            ? updatedTrainer.national_id
            : null,
        visa_category:
          updatedTrainer && updatedTrainer.visa_category.id
            ? updatedTrainer.visa_category.id
            : null,
        division:
          updatedTrainer && updatedTrainer.division.id
            ? updatedTrainer.division.id
            : null,
        name:
          updatedTrainer && updatedTrainer.name ? updatedTrainer.name : null,
        gender:
          updatedTrainer && updatedTrainer.gender
            ? updatedTrainer.gender
            : null,
        date_of_birth:
          updatedTrainer && updatedTrainer.date_of_birth
            ? updatedTrainer.date_of_birth
            : null,
        nationality:
          updatedTrainer && updatedTrainer.nationality.id
            ? updatedTrainer.nationality.id
            : null,
        city:
          updatedTrainer && updatedTrainer.city ? updatedTrainer.city : null,
        address:
          updatedTrainer && updatedTrainer.address
            ? updatedTrainer.address
            : null,
        post_box:
          updatedTrainer && updatedTrainer.post_box
            ? updatedTrainer.post_box
            : null,
        country:
          updatedTrainer && updatedTrainer.country.id
            ? updatedTrainer.country.id
            : null,
        training_experience:
          updatedTrainer && updatedTrainer.training_experience
            ? updatedTrainer.training_experience
            : null,
        achievements:
          updatedTrainer && updatedTrainer.achievements
            ? updatedTrainer.achievements
            : null,
        email:
          updatedTrainer && updatedTrainer.email ? updatedTrainer.email : null,
        // isd_code:
        //   updatedTrainer && updatedTrainer.isd_code.id
        //     ? updatedTrainer.isd_code.id
        //     : null,
        mobile:
          updatedTrainer && updatedTrainer.mobile
            ? updatedTrainer.mobile
            : null,
        imgFile:
          updatedTrainer && updatedTrainer.image ? updatedTrainer.image : null,
        blood_group:
          updatedTrainer && updatedTrainer.blood_group
            ? updatedTrainer.blood_group
            : null,
        remark:
          updatedTrainer && updatedTrainer.remark
            ? updatedTrainer.remark
            : null,
        files: [],
        files1:
          updatedTrainer && updatedTrainer.files ? updatedTrainer.files : null,
        PdfFiles:
          updatedTrainer && updatedTrainer.files ? updatedTrainer.files : null,
        checkedvalue: [],
      });
      setPhoneNumber(
        updatedTrainer && updatedTrainer.mobile ? updatedTrainer.mobile : null
      );
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updatedTrainer) {
      fData.append("national_id", formData.national_id);
      fData.append("visa_category", formData.visa_category);
      fData.append("division", formData.division);
      fData.append("name", formData.name);
      fData.append("gender", formData.gender);
      fData.append("date_of_birth", formData.date_of_birth);
      fData.append("nationality", formData.nationality);
      fData.append("city", formData.city);
      fData.append("address", formData.address);
      fData.append("post_box", formData.post_box);
      fData.append("training_experience", formData.training_experience);
      fData.append("achievements", formData.achievements);
      fData.append("country", formData.country);
      fData.append("email", formData.email);
      fData.append("mobile", phoneNumber);
      if (formData.image) {
        fData.append("image", formData.image);
      }

      if (formData.remark !== null) {
        fData.append("remark", formData.remark);
      }
      fData.append("blood_group", formData.blood_group);

      formData.files.forEach((file) => {
        fData.append(`document_type`, file.document_type);
        fData.append(`issue_date`, file.issue_date);
        fData.append(`expiry_date`, file.expiry_date);
        fData.append(`uploaded_file`, file.uploaded_file);
      });
      try {
        setLoading(true);
        const response = await api.put(`update_trainer/${id}`, fData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "OK" || response.status === "200") {
          setLoading(false);
          navigate("/dashboard/traineragreement/trainers_list");
          toast.success("Trainer updated successfully");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      fData.append("national_id", formData.national_id);
      fData.append("visa_category", formData.visa_category);
      fData.append("division", formData.division);
      fData.append("name", formData.name);
      fData.append("gender", formData.gender);
      fData.append("date_of_birth", formData.date_of_birth);
      fData.append("nationality", formData.nationality);
      fData.append("city", formData.city);
      fData.append("address", formData.address);
      fData.append("post_box", formData.post_box);
      fData.append("training_experience", formData.training_experience);
      fData.append("achievements", formData.achievements);
      fData.append("country", formData.country);
      fData.append("email", formData.email);
      // fData.append("isd_code", formData.isd_code);
      fData.append("mobile", phoneNumber);
      fData.append("image", formData.image);
      if (formData.remark) {
        fData.append("remark", formData.remark);
      }
      fData.append("blood_group", formData.blood_group);

      formData.files.forEach((file, index) => {
        fData.append(`document_type`, file.document_type);
        fData.append(`issue_date`, file.issue_date);
        fData.append(`expiry_date`, file.expiry_date);
        fData.append(`uploaded_file`, file.uploaded_file);
      });
      try {
        setLoading(true);
        const response = await api.post("create_trainer", fData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "Created" || response.status === "201") {
          setLoading(false);
          navigate("/dashboard/traineragreement/trainers_list");
          toast.success("Trainer created successfully");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    let updateTrainerId = null;
    try {
      updateTrainerId = location.pathname.split("/")[4];
    } catch { }
    if (updateTrainerId) {
      handleupdateTrainer(updateTrainerId);
    }
  }, []);

  useEffect(() => {
    dispatch(getDivisionMasterData(token));
    dispatch(getDocumentTypeMasterData(token));
    dispatch(getVisaMasterData(token));
    dispatch(getCountries(token));
    // dispatch(getISDcode(token));
    AOS.init();
  }, [dispatch, token]);

  useEffect(() => {
    const sectionId = window.location.hash
    if (sectionId) {
      const section = document.getElementById(sectionId.split("#")[1]);
      console.log("sectionId", sectionId.split("#"));
      console.log("section", section);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [formData]);

  return isLoading ? (
    <StableLoading />
  ) : (
    <div className="form-wrapper-container">
      <div
        className="registration-card"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <header>
          <div className="registration_top_header">
            <p>
              {" "}
              {id ? (
                <p>Update Trainer Registration</p>
              ) : (
                <p>Trainer Registration</p>
              )}
            </p>
            <span>
              Please fill in all required fields, attach/upload all supporting
              documents to proceed with the registration.
            </span>
          </div>
        </header>

        <form onSubmit={handleSubmit}>
          {/* Registration Information */}
          <div className="registration-information trainerregistrationh3">
            <a href="#info" id="info" className="bookmark">
              <h3>Registration Information</h3>
            </a>
          </div>

          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  NATIONAL ID <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="text"
                  className="inputfield  bg-color"
                  name="national_id"
                  value={formData.national_id}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="National Id"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  VISA CATEGORY <span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  required
                  name="visa_category"
                  value={
                    visa_category &&
                    formData.visa_category &&
                    visa_category.find(
                      (item) => item.id === Number(formData.visa_category)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "visa_category")
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={visa_category}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Visa"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  DISCIPLINE <span style={{ color: "red" }}>*</span>
                </label>

                {division && (
                  <Autocomplete
                    required
                    name="division"
                    value={
                      division &&
                      formData.division &&
                      division.find(
                        (item) => item.id === Number(formData.division)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "division")
                    }
                    disablePortal
                    id="combo-box-demo"
                    options={division}
                    getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the division
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Discipline"
                        {...params}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </div>

          {/* Trainer Information */}
          <div className="trainer-information trainerregistrationh3">
            <a href="#trainer" id="trainer" className="bookmark">
              <h3>Trainer Information</h3>
            </a>
          </div>

          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  TRAINER NAME <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Trainer Name"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  GENDER <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  required
                  value={formData.gender}
                  onChange={(event, value) =>
                    handleAutoCompleteStatic(event, value, "gender")
                  }
                  disablePortal
                  id="combo-box-demo"
                  name="gender"
                  options={genders}
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Gender"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  DATE OF BIRTH <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="bg-color"
                  type="date"
                  name="date_of_birth"
                  value={formData.date_of_birth}
                  onChange={handleChange}
                  inputProps={{ max: today }}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  BLOOD GROUP <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  required
                  className="inputfield  bg-color"
                  name="blood_group"
                  value={formData.blood_group}
                  onChange={handleChange}
                  fullWidth
                  placeholder="B +ve"
                  inputProps={{ maxLength: 6 }}
                  error={bloodGroupError} // Set error state
                  helperText={
                    bloodGroupError ? "Please use alphabets, +ve and -ve " : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  NATIONALITY <span style={{ color: "red" }}>*</span>
                </label>
                {countries && (
                  <Autocomplete
                    name="nationality"
                    value={
                      countries &&
                      formData.nationality &&
                      countries.find(
                        (item) => item.id === Number(formData.nationality)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "nationality")
                    }
                    disablePortal
                    id="combo-box-demo"
                    options={countries}
                    required
                    getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Nationality"
                        {...params}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  CITY <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="City"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  ADDRESS <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Address"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  POST BOX <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield bg-color"
                  name="post_box"
                  value={formData.post_box}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Ex: 452512"
                  error={postBoxError} // Set error state
                  helperText={postBoxError ? "Please enter numbers only." : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  COUNTRY <span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  name="country"
                  value={
                    countries &&
                    formData.country &&
                    countries.find(
                      (item) => item.id === Number(formData.country)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "country")
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={countries}
                  required
                  getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Country"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  TRAINING EXPERIENCE <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  required
                  className="inputfield  bg-color"
                  name="training_experience"
                  value={formData.training_experience}
                  onChange={handleChange}
                  fullWidth
                  placeholder="Ex: 5 Years"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  ACHIEVEMENTS <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  required
                  className="inputfield  bg-color"
                  name="achievements"
                  value={formData.achievements}
                  onChange={handleChange}
                  fullWidth
                  placeholder="Ex: Gold medalist"
                />
              </Grid>
            </Grid>
          </div>

          {/* Trainer Contact Information */}
          <div className="trainer-contact-information trainerregistrationh3">
            <a href="#contact" id="contact" className="bookmark">
              <h3>Trainer Contact Information</h3>
            </a>
          </div>

          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  EMAIL <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleEmailChange}
                  required
                  fullWidth
                  placeholder="example@gmail.com"
                />
                <p style={{ color: "red" }}>{emailError ? emailError : null}</p>
              </Grid>

              {/* <Grid item xs={12} sm={6} md={4}>
                <label>
                  ISD CODE <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  name="isd_code"
                  value={
                    ISD_code &&
                    formData.isd_code &&
                    ISD_code.find(
                      (item) => item.id === Number(formData.isd_code)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "isd_code")
                  }
                  disablePortal
                  required
                  id="combo-box-demo"
                  options={ISD_code}
                  getOptionLabel={(option) => option.country} // Replace "code" with the property that holds the ISD code
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select ISD"
                      {...params}
                    />
                  )}
                />
              </Grid> */}

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  MOBILE NO. <span style={{ color: "red" }}>*</span>
                </label>
                {/* <TextField
                  className="inputfield  bg-color"
                  name="mobile"
                  type="number"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="0123456789"
                  //   min={0}
                  //   inputProps={{
                  //      maxLength: 5,
                  //      type: 'number',
                  //  }}
                  // inputProps={{ min: "0", max: "1"}}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                /> */}
                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  style={{ marginTop: '8px' }}
                  enableSearch={true}
                  countryCodeEditable={false}
                  disableSearchIcon={true}
                  inputProps={{

                    required: true,
                    autoFocus: true,
                  }}
                />
              </Grid>
            </Grid>
          </div>

          {/* Upload Photo */}
          <div className="trainer-upload-photo trainerregistrationh3">
            <a href="#photo" id="photo" className="bookmark">
              <h3>Upload Photo</h3>
            </a>
          </div>

          <div className="inputparts">
            {/* <Grid container spacing={12}> */}
            <span style={{ color: "red", fontSize: "13px" }}>
              Documents size requirements
            </span>
            <br />
            <p
              className="horseRegistration"
              style={{ fontWeight: "bolder", fontSize: "15px" }}
            >
              The Maximum document / file size allowed is: 1MB{" "}
              <span style={{ color: "red" }}>*</span>
            </p>
            {/* </Grid> */}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <div className="App">
                  <label htmlFor="upload-photo">
                    <TextField
                      className="bg-color"
                      id="upload-photo"
                      name="image"
                      type="file"
                      onChange={handleImageChange}
                      required={!updatedTrainer}
                      style={{ width: "100%" }}
                    />
                  </label>
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {imgError}
                  </span>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <div style={{ marginBlock: "5%" }}>
                  <ImageList
                    sx={{ width: 400, height: "auto" }}
                    cols={2}
                    rowHeight={"auto"}
                  >
                    <ImageListItem>
                      {formData.imgFile && (
                        <img
                          src={`${ImgUrl}${formData.imgFile}`}
                          alt="Uploaded file"
                          loading="lazy"
                        />
                      )}
                      {formData.image && (
                        <img
                          src={URL.createObjectURL(formData.image)}
                          alt="Uploaded file"
                          loading="lazy"
                        />
                      )}
                    </ImageListItem>
                  </ImageList>
                </div>
              </Grid>
            </Grid>
          </div>

          {/* Upload Documents */}
          <div className="trainer-upload-documents trainerregistrationh3">
            <a href="#doc" id="doc" className="bookmark">
              <h3>Upload Documents</h3>
            </a>
          </div>

          <div className="inputparts">
            {updatedTrainer ? (
              <table style={{ width: "100%", overflow: "auto" }}>
                <thead style={{ textAlign: "left" }}>
                  <tr>
                    <th>Document Type</th>
                    <th>Issue Date</th>
                    <th>Expiry Date</th>
                    <th style={{ width: "130px" }}>Download Link</th>
                    <th></th>
                  </tr>
                </thead>

                {formData.files1.map((item, index) => {
                  return (
                    <>
                      <tbody>
                        <tr>
                          <td>
                            <span>{item.document_type.name}</span>
                          </td>
                          <td>
                            <span>{item.issue_date}</span>
                          </td>
                          <td>
                            <span style={{
                              color:
                                new Date(
                                  item.expiry_date
                                ) < new Date()
                                  ? "#ff0000"
                                  : "black",
                            }}>{item.expiry_date}</span>
                          </td>
                          {item.uploaded_file ? (
                            <td>
                              <a
                                href={`${ImgUrl}${item.uploaded_file}`}
                                target="_blank"
                                className="pdfCard" rel="noreferrer"
                              >
                                <div>
                                  <Link
                                    to={`${ImgUrl}${item.uploaded_file}`}
                                    target="_blank"
                                    className="pdfCard"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        border: "1px solid  #9f8754",
                                        marginBottom: "5px",
                                        borderRadius: "3px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "1.3rem",
                                          color: "white",
                                          background:
                                            "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                                          borderRadius: "2px 0 0 2px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "35px",
                                          height: "35px",
                                        }}
                                      >
                                        <FaFilePdf />
                                      </div>
                                      <div
                                        style={{
                                          padding: "0px 10px",
                                          fontSize: "0.8rem",
                                        }}
                                      >
                                        {item.uploaded_file
                                          ? updatedTrainer.files[
                                            index
                                          ].uploaded_file.split(
                                            "/media/trainer_file/"
                                          )[1]
                                          : null}
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </a>
                            </td>
                          ) : (
                            "File Not Found"
                          )}
                          <td>
                            <Button
                              className="addbtn"
                              title="Add Fields"
                              onClick={() => deleteHandler(index)}
                            >
                              <RiDeleteBin6Line
                                style={{ color: "red", fontSize: "15px" }}
                              />
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </>
                  );
                })}
              </table>
            ) : null}
            {formData.files?.map((document, index) => {
              return (
                <>
                  <div
                    style={{
                      marginTop: "8px",
                      borderBottom: "1px solid #fff6d8",
                    }}
                    className="upload_document"
                    key={document.document_type}
                  >
                    <Button
                      className="addbtn"
                      title="Add Fields"
                      onClick={() => handleremove1(index)}
                    >
                      <ClearIcon style={{ color: "red", fontSize: "15px" }} />
                    </Button>

                    <Grid container spacing={2} key={index} sx={{ mb: "10px" }}>
                      <Grid item xs={12} sm={6} md={3}>
                        <label>
                          SELECT DOCUMENT TYPE{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Autocomplete
                          name="document_type"
                          value={
                            docType &&
                            docType.find(
                              (item) =>
                                item.id === Number(document.document_type)
                            )
                          }
                          onChange={(event, value) =>
                            handleACDocTypeFields(
                              event,
                              value,
                              `document_type`,
                              index
                            )
                          }
                          disablePortal
                          id="combo-box-demo"
                          options={docType}
                          getOptionLabel={(option) => option.name}
                          required
                          renderInput={(params) => (
                            <TextField
                              required
                              className="bg-color"
                              {...params}
                              placeholder="Select Document Type"
                              {...params}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <label>
                          Issue Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          className="bg-color"
                          type="date"
                          value={document.issue_date}
                          name={`issue_date`}
                          onChange={(event, value) =>
                            handleACDocFields(event, value, `issue_date`)
                          }
                          fullWidth
                          inputProps={{ max: today }}
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <label>
                          Expiry Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          className="bg-color"
                          type="date"
                          value={document.expiry_date}
                          name={`expiry_date`}
                          inputProps={{ min: today }}
                          onChange={(event, value) =>
                            handleACDocFields(event, value, `expiry_date`)
                          }
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <label htmlFor={`upload-photo-${index}`}>
                          Attach File <span style={{ color: "red" }}>*</span>
                          <TextField
                            className="bg-color"
                            name={`uploaded_file`}
                            type="file"
                            id={`upload-photo`}
                            required
                            onChange={(event) => docUpload(event, index)}
                            style={{ width: "100%" }}
                          />
                        </label>
                        <span style={{ color: "red", fontSize: "13px" }}>
                          {attachDoc}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                </>
              );
            })}
            <Button
              title="Add Fields"
              onClick={docRender}
              style={{
                backgroundColor: "#826527",
                color: "white",
                fontSize: "12px",
                float: "right",
                marginBlock: "10px",
              }}
            >
              <AddIcon style={{ fontSize: "15px", fontWeight: "bold" }} />
              &nbsp;Add
            </Button>
          </div>

          {/* Remarks */}
          <div className="trainer-remark trainerregistrationh3">
            <a href="#remark" id="remark" className="bookmark">
              <h3>Remark</h3>
            </a>
          </div>

          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextareaAutosize
                  style={{ width: "100%", fontSize: "16px", padding: "10px" }}
                  minRows={8}
                  className="bg-color"
                  placeholder="Additional Information"
                  multiline
                  rows={4}
                  name="remark"
                  value={formData.remark}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>

          {/* Terms and Conditions */}
          <div className="trainer-tandc trainerregistrationh3">
            <a href="#tandc" id="tandc" className="bookmark">
              <h3>Terms & Conditions</h3>
            </a>
          </div>

          <div style={{ marginBlock: "15px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                inputProps={{ "aria-label": "Checkbox demo" }}
                color="success"
                onClick={handleChange}
                name="checkedvalue"
                required
              />
              <p className="horseRegistration">
                I WOULD LIKE TO REGISTER FOR THE CURRENT SEASON 2022-2023
              </p>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                inputProps={{ "aria-label": "Checkbox demo" }}
                color="success"
                onClick={handleChange}
                name="checkedvalue"
                required
              />
              <p className="horseRegistration">
                I WOULD LIKE TO REGISTER IN FEI (ADDITIONAL CHARGES)
              </p>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                inputProps={{ "aria-label": "Checkbox demo" }}
                color="success"
                onClick={handleChange}
                name="checkedvalue"
                required
              />
              <p className="horseRegistration">
                BY CHECKING THIS BOX, YOU AGREE WITH THE TERMS AND CONDITIONS
                AND GUARANTEES THAT THE INFORMATION YOU ENTERED IS TRUE AND
                CORRECT
              </p>
            </div>
          </div>

          {/* Submit */}

          <div className="btn-submit" style={{ gap: "15px" }}>
            <Button
              type="submit"
              variant="contained"
              className="btn-bgColor"
              onClick={() =>
                navigate("/dashboard/traineragreement/trainers_list")
              }
            >
              Cancel
            </Button>

            {loading ? (
              <CommonLoading />
            ) : (
              <Button type="submit" variant="contained" className="btn-bgColor">
                {id ? "Update" : "Create"}
              </Button>
            )}
          </div>
        </form>
      </div>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Document"
        deleteData={handleremove}
      />
    </div>
  );
};

export default TrainerRegistration;
