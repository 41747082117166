import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { TextField, Grid, Autocomplete, Button, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData } from "../../App/Reducers/FacilityTypeReducer";
import { GetUsers } from "../../App/Reducers/UserReducer";
import { getServiceTypeData } from "../../App/Reducers/ServiceTypeReducer";
import { getFacilityTreeData } from "../../App/Reducers/FacilityTreeReducer";
import { getAllConfirmQuotation } from "../../App/Reducers/QuotationReducer";
import api from "../../Apis";
import { useNavigate, useParams } from "react-router-dom";
import { getCheckInByID } from "../../App/Apis/CheckinApis";
import "./CheckIn.css";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import CommonLoading from "../../SharedComponets/Loading/CommonLoading";

const CheckInRegistration = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkin_id = useParams();
  console.log("checkin_id", checkin_id);
  const checkinTypes = ["Permanent CheckIn", "Temperory CheckIn"];

  const healthTypes = ["Good", "Bad"];

  let facilityServiceType, facilityNameData, horse_datas;

  const QuotationData = useSelector(
    (state) => state.quotation.confirmQuotation
  );

  const staffData = useSelector((state) => state.getUsers.users);
  const facility_types = useSelector(
    (state) => state.facilityTypes.facilityData
  );

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [service, setServiceData] = useState([]);
  const [facilityName, setFacilityName] = useState([]);
  const [facilityService, setFacilityService] = useState([]);
  const [facilityCode, setFacilityCode] = useState([]);
  const [horseName, setHorseName] = useState([]);

  const [formData, setFormData] = useState({
    quotation: null,
    horse: null,
    facility_type: null,
    service_type: null,
    facility_name: null,
    facility_code: null,
    staff: null,
    checkin_type: null,
    health_status: null,
    date: null,
    time: null,
  });

  const getService = async (id, token) => {
    try {
      const response = await api.get(`facility_service_type/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getServiceName = async (id, token) => {
    try {
      const response = await api.get(`service_type_facility_name/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getHorseDataByquotationId = async (id, token) => {
    try {
      const res = await api.get(`get_quotation_horse/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const handleAutoComplete = async (event, value, fieldName) => {
    if (value === null) {
      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
        facility_type: null,
        horse: null,
        facility_type: null,
        service_type: null,
        facility_name: null,
        facility_code: null,
      }));
    } else if (fieldName === "facility_type") {
      facilityServiceType = await getService(value.id, token);

      const sdata = facilityServiceType.map((item) => {
        return { id: item.id, name: item.name };
      });

      setServiceData(() => sdata);

      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else if (fieldName === "service_type") {
      facilityNameData = await getServiceName(value.id, token);
      console.log("facilityNameData", facilityNameData);

      const fSdata = facilityNameData.map((item) => {
        return {
          id: item.id,
          name: item.name,
          facility_header: item.facility_header,
        };
      });

      setFacilityName(fSdata);

      const selectedValue = value === null ? null : value.id;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else if (fieldName === "facility_name") {
      console.log(value);
      const getfacilityCodeByFacilityId = (v) => {
        console.log("FN", v);
        const result = v.facility_header.filter(
          (header) =>
            v.id === header.facility_header.id && header.status === "VACANT"
        );
        console.log("result", result);
        return result;
      };

      const fcData = getfacilityCodeByFacilityId(value);
      setFacilityCode(fcData);
      const selectedValue = value === null ? null : value.id;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else if (fieldName === "horse") {
      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else if (fieldName === "quotation") {
      horse_datas = await getHorseDataByquotationId(value.id, token);
      console.log("horse_datas", horse_datas);

      const hData = horse_datas.map((item) => {
        return { id: item.id, name: item.name };
      });
      setHorseName(() => hData);

      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else {
      const selectedValue = value === null ? null : value.id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    }
  };

  const handleAutoCompleteStatic = (event, newValue, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkin_id.checkin_id) {
      try {
        setLoading(true);
        const response = await api.put(
          `update_checkin/${checkin_id.checkin_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `token ${token}`,
            },
          }
        );

        if (response.statusText === "OK" || response.status === "200") {
          setLoading(false);
          navigate("/dashboard/stable/check_in");
          toast.success("CheckIn updated successfully");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        setLoading(true);
        const response = await api.post("create_checkin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "Created" || response.status === "201") {
          setLoading(false);
          navigate("/dashboard/stable/check_in");
          toast.success("CheckIn created successfully");
        }
      } catch (error) {
        throw error;
      }
    }
  };

  const handleCheckinUpdate = (id) => {
    setIsLoading(true);
    try {
      getCheckInByID({ id: id, token }).then((res) => {
        const { data } = res;
        setServiceData([
          { id: data.service_type.id, name: data.service_type.name },
        ]);
        setHorseName([{ id: data.horse.id, name: data.horse.name }]);
        setFacilityName([
          { id: data.facility_name.id, name: data.facility_name.name },
        ]);
        setFacilityService([
          { id: data.service_type.id, name: data.service_type.name },
        ]);
        setFacilityCode([
          { id: data.facility_code.id, name: data.facility_code.name },
        ]);

        setFormData({
          quotation:
            data.quotation && data.quotation.id ? data.quotation.id : null,
          horse: data.horse && data.horse.id ? data.horse.id : null,
          facility_type:
            data.facility_type && data.facility_type.id
              ? data.facility_type.id
              : null,
          service_type:
            data.service_type && data.service_type.id
              ? data.service_type.id
              : null,
          facility_name:
            data.facility_name && data.facility_name.id
              ? data.facility_name.id
              : null,
          facility_code:
            data.facility_code && data.facility_code.id
              ? data.facility_code.id
              : null,
          staff: data.staff && data.staff.id ? data.staff.id : null,
          checkin_type: data.checkin_type ? data.checkin_type : null,
          health_status: data.health_status ? data.health_status : null,
          date: data.date ? data.date : null,
          time: data.time ? data.time : null,
        });
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(GetUsers(token));
    dispatch(getAllConfirmQuotation(token));
    dispatch(getData(token));
    dispatch(getServiceTypeData(token));
    dispatch(getFacilityTreeData(token));
    dispatch(getAllConfirmQuotation(token));
    dispatch(getFacilityTreeData(token));

    if (checkin_id.checkin_id) {
      handleCheckinUpdate(checkin_id.checkin_id);
    }
  }, []);

  return isLoading ? (
    <StableLoading />
  ) : (
    <div className="form-wrapper-container">
      <div
        className="registration-card"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <header>
          <div className="registration_top_header">
            <p>
              {checkin_id.checkin_id ? <p>Update CheckIn</p> : <p>CheckIn</p>}
            </p>
          </div>
        </header>

        <form onSubmit={handleSubmit}>
          <div className="inputparts-checkIn">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  RESERVATION <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    name="quotation"
                    value={
                      formData &&
                      formData.quotation &&
                      QuotationData &&
                      QuotationData.find(
                        (item) => item.id === Number(formData.quotation)
                      )
                    }
                    disabled={checkin_id.checkin_id ? true : false}
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "quotation")
                    }
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    disablePortal
                    id="combo-box-demo"
                    options={
                      QuotationData !== undefined
                        ? QuotationData
                        : [{ id: null, name: "No Quotation Data" }]
                    }
                    getOptionLabel={(option) =>
                      `${option.name} (${option.code})`
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Quotation"
                        {...params}
                        sx={{
                          "& input": {
                            cursor: checkin_id.checkin_id ? "no-drop" : "auto", // Apply the cursor style conditionally
                          },
                        }}
                      />
                    )}
                  />
                }
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  HORSE NAME <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    name="horse"
                    value={
                      formData &&
                      formData.horse &&
                      horseName.find(
                        (item) => item.id === Number(formData.horse)
                      )
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "horse")
                    }
                    disabled={checkin_id.checkin_id ? true : false}
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    disablePortal
                    id="horse"
                    options={horseName}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Horse"
                        {...params}
                        sx={{
                          "& input": {
                            cursor: checkin_id.checkin_id ? "no-drop" : "auto", // Apply the cursor style to the input field
                          },
                        }}
                      />
                    )}
                  />
                }
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  STAFF <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    name="staff"
                    value={
                      formData.staff
                        ? staffData !== null &&
                          staffData.find(
                            (item) => item.id === Number(formData.staff)
                          )
                        : null
                    }
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "staff")
                    }
                    disabled={checkin_id.checkin_id ? true : false}
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    disablePortal
                    id="combo-box-demo"
                    options={staffData}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name} (${
                        option.usertype && option.usertype.name
                      })`
                        ? `${option.first_name} ${option.last_name} (${
                            option.usertype && option.usertype.name
                          })`
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Staff"
                        {...params}
                        sx={{
                          "& input": {
                            cursor: checkin_id.checkin_id ? "no-drop" : "auto", // Apply the cursor style to the input field
                          },
                        }}
                      />
                    )}
                  />
                }
              </Grid>
            </Grid>
          </div>

          <div className="inputparts-checkIn">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  FACILITY TYPE <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    name="facility_type"
                    disablePortal
                    id="facility_type"
                    options={facility_types}
                    getOptionLabel={(option) => option.name}
                    value={
                      formData &&
                      formData.facility_type &&
                      facility_types &&
                      facility_types.find(
                        (item) => item.id === Number(formData.facility_type)
                      )
                    }
                    disabled={checkin_id.checkin_id ? true : false}
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "facility_type")
                    }
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        className="bg-color"
                        sx={{
                          "& input": {
                            cursor: checkin_id.checkin_id ? "no-drop" : "auto", // Apply the cursor style to the input field
                          },
                        }}
                      />
                    )}
                  />
                }
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  SERVICE TYPE <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    name="service_type"
                    disablePortal
                    id="service_type"
                    options={service}
                    getOptionLabel={(option) => option.name}
                    value={
                      formData &&
                      formData.service_type &&
                      service.length > 0 &&
                      service.find(
                        (item) => item.id === Number(formData.service_type)
                      )
                    }
                    disabled={checkin_id.checkin_id ? true : false}
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "service_type")
                    }
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-color"
                        required
                        sx={{
                          "& input": {
                            cursor: checkin_id.checkin_id ? "no-drop" : "auto", // Apply the cursor style to the input field
                          },
                        }}
                      />
                    )}
                  />
                }
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  FACILITY NAME <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    name="facility_name"
                    disablePortal
                    id="facility_name"
                    options={facilityName}
                    getOptionLabel={(option) => option.name} // Define how to display the label in the Autocomplete
                    value={
                      formData.facility_name &&
                      facilityName.length > 0 &&
                      facilityName.find(
                        (item) => item.id === Number(formData.facility_name)
                      )
                    }
                    disabled={checkin_id.checkin_id ? true : false}
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "facility_name")
                    }
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    // required
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Facility"
                        {...params}
                        sx={{
                          "& input": {
                            cursor: checkin_id.checkin_id ? "no-drop" : "auto", // Apply the cursor style to the input field
                          },
                        }}
                      />
                    )}
                  />
                }
              </Grid>
            </Grid>
          </div>

          <div className="inputparts-checkIn">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  FACILITY CODE <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    name="facility_code"
                    value={
                      formData.facility_code &&
                      facilityCode.find(
                        (item) => item.id === Number(formData.facility_code)
                      )
                    }
                    disabled={checkin_id.checkin_id ? true : false}
                    onChange={(event, value) =>
                      handleAutoComplete(event, value, "facility_code")
                    }
                    sx={{
                      color: "#302924",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                    disablePortal
                    id="facility_code"
                    options={facilityCode}
                    getOptionLabel={(option) => option.name}
                    // required
                    renderInput={(params) => (
                      <TextField
                        required
                        className="bg-color"
                        placeholder="Select Horse"
                        {...params}
                        sx={{
                          "& input": {
                            cursor: checkin_id.checkin_id ? "no-drop" : "auto", // Apply the cursor style to the input field
                          },
                        }}
                      />
                    )}
                  />
                }
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  HEALTH STATUS <span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  name="health_status"
                  value={formData.health_status}
                  onChange={(event, value) =>
                    handleAutoCompleteStatic(event, value, "health_status")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  disablePortal
                  id="health_status"
                  options={healthTypes}
                  // required
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Health Type"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  CHECKIN TYPE <span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  name="checkin_type"
                  value={formData.checkin_type}
                  onChange={(event, value) =>
                    handleAutoCompleteStatic(event, value, "checkin_type")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  disablePortal
                  id="checkin_type"
                  options={checkinTypes}
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Checkin Type"
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>

          <div className="inputparts-checkIn">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  CHECKIN DATE <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="date"
                  className="inputfield  bg-color"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Select Date"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  CHECKIN TIME <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="time"
                  className="inputfield  bg-color"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Select Checkin Time"
                />
              </Grid>
            </Grid>
          </div>

          
          <div className="btn-submit" style={{ gap: "15px" }}>
            <Button
              type="submit"
              variant="contained"
              className="btn-bgColor"
              onClick={() => navigate("/dashboard/stable/check_in")}
            >
              Cancel
            </Button>
            {loading ? (
              <CommonLoading />
            ) : (
              <Button type="submit" variant="contained" className="btn-bgColor">
                {checkin_id.checkin_id ? "Update" : "Create"}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckInRegistration;
