import React, { useEffect, useState } from "react";
import RegistrationCard from "./RegistrationCard";
import "./DashOverview.css";
import horse from "../../../Assets/Images/horse2.svg";
import horse_trainer from "../../../Assets/Images/trainer2.svg";
import horse_owner from '../../../Assets/Images/owner2.svg';
import stall from '../../../Assets/Images/stall.png';
import cell from '../../../Assets/Images/cell.png'

import { getHorseData } from "../../../App/Reducers/HorseRegistrationReducer";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerData } from "../../../App/Reducers/OwnerReducer";
import { GetTrainers } from "../../../App/Reducers/TrainerReducer";
import { useCookies } from "react-cookie";
import { getStallCellData } from "../../../App/Reducers/Stable Slices/CellSlices";
import { getFacilityTreeData } from "../../../App/Reducers/FacilityTreeReducer";
import { MdOutlineAppRegistration } from "react-icons/md";
import tcourse from "../../../Assets/RidingSchoolAssets/tcourse.svg"
import studentSVG from "../../../Assets/RidingSchoolAssets/student.svg"
import RegistrationListOverView from "../../../App/Apis/RidingSchoolApis/RegistrationListOverview/RegistraionListOverView";
import { companyId } from "../../../App/Apis/RidingSchoolReuseIds/Ids";



const RegistrationListOverview = ({ DashboardName }) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["token"]);

  const [getAllRidingTrainer, setAllRidingTrainer] = useState([])
  const [getAllCourse, setAllRidingCourses] = useState([])
  const [student, setAllStudent] = useState([])

  const token = cookies.token
  const HorseData = useSelector((state) => state.horseRegistration.data);
  const trainerData = useSelector((state) => state.trainers.Trainers)
  const OwnerData = useSelector((state) => state.owner.ownerData)
  const facilityData = useSelector((state) => state.facilityTree.facilityTreeData)
  const CellData = useSelector((state) => state.cell.stallCellData)


  const permissions = JSON.parse(localStorage.getItem('userData'))
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false
  const ViewHorsePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_horse") : false;
  const viewOwnerPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_owner") : false;
  const viewTrainerPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_trainer") : false;
  const viewFacilityTreePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_facility") : false;
  const viewCheckInPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_horsecheckin") : false;

  useEffect(() => {
    if (DashboardName && DashboardName === 'riding-club') {

    } else {
      dispatch(getHorseData(token));
      dispatch(getOwnerData(token));
      dispatch(GetTrainers(token));
      // dispatch(getStallData(token))
      dispatch(getFacilityTreeData(token))
      dispatch(getStallCellData(token))
    }
  }, []);

  const GetAllridingTrainer = async () => {
    try {
      const res = await RegistrationListOverView.getAllRidingTrainer()
      setAllRidingTrainer(res.data)
    } catch (error) {
      console.log("Something went wrong", error)
    }
  }

  const setAllRidingCoursesData = async () => {
    try {
      const res = await RegistrationListOverView.getAllCourses(companyId)

      setAllRidingCourses(res.data)
    } catch (error) {
      console.log(`Something Went Wrong`, error)
    }
  }

  const getAllStudent = async () => {
    try {
      const res = await RegistrationListOverView.getAllRidingStudent()
      setAllStudent(res.data)
    } catch (error) {
      console.log(`something went wrong`, error)
    }
  }
  useEffect(() => {
    GetAllridingTrainer()
    setAllRidingCoursesData()
    getAllStudent()
  }, [])

  const allRidingTrainers = getAllRidingTrainer.length
  const allRidingCourses = getAllCourse.length
  const getAllstudent = student.length
  return (
    <div className="registration-card-wrapper">
      <div className='status-top'>
        <h2 className='dashboard-header'><MdOutlineAppRegistration style={{ backgroundColor: "#e7e2d5", borderRadius: "50%", padding: "5px", fontSize: "2rem" }} />&nbsp;<span>My Registration</span></h2>
      </div>
      {DashboardName && DashboardName === 'riding-club' ? (< div className="registration-card-container">
        <RegistrationCard title="Course" count={allRidingCourses} pic={tcourse} path={`/riding-club/course/course_list`} duration="800" />
        <RegistrationCard title="Trainer" count={allRidingTrainers} pic={horse_trainer} path={`/riding-club/traineragreement/trainers_list`} duration="800" />
        <RegistrationCard title="Students" count={getAllstudent} pic={studentSVG} path={`/riding-club/course/student-registration`} duration="800" />
      </div>)
        : (< div className="registration-card-container">
          <RegistrationCard title="Horse" count={HorseData ? HorseData.length : 0} pic={horse} path={(isUserType || ViewHorsePermission) && '/dashboard/horseagreement/horses_list'} duration="800" />
          <RegistrationCard title="Owner" count={OwnerData ? OwnerData.length : 0} pic={horse_owner} path={(isUserType || viewOwnerPermission) && '/dashboard/owneragreement/owners_list'} duration="1200" />
          <RegistrationCard title="Trainer" count={trainerData ? trainerData.length : 0} pic={horse_trainer} path={(isUserType || viewTrainerPermission) && '/dashboard/traineragreement/trainers_list'} duration="1600" />
          <RegistrationCard title="Facility" count={facilityData ? facilityData.length : 0} pic={stall} path={(isUserType || viewFacilityTreePermission) && '/dashboard/settings/facility-tree'} duration="2000" />
          <RegistrationCard title="CheckIn" count={CellData ? CellData.length : 0} pic={cell} path={(isUserType || viewCheckInPermission) && '/dashboard/stable/check_in'} duration="2400" />
        </div>)}
    </div >
  );
};

export default RegistrationListOverview;
