import React, { useState, useEffect, useContext } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./Header.css";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { ChangeTheme } from "../App/Reducers/ThemeReducer";
import axios from "axios";
import { useCookies } from "react-cookie";
import { ImgUrl } from "../Config/Config";
import api from "../Apis";
import { siteLogo } from "../Assets/Images/logo";
import Footer from "./Footer";
import { MdOutlineDashboard } from "react-icons/md";
import { IoIosCart } from "react-icons/io";
import Badge from "./badge";
import Context from '../Store/Context/Context.js';



axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
function Header() {
  const { localStorageData } = useContext(Context)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, removeCookie] = useCookies(["token"]);
  const token = cookies.token;
  const [headerdivClass, setHeaderDivClass] = useState("header");
  const [mainTheme, setMainTheme] = React.useState("");
  const [LogoFile, setLogoFile] = useState(null);
  const [activehomebg, setActivehomebg] = useState(false);
  const [activeaboutbg, setActiveaboutbg] = useState(false);
  const [activedisciplinebg, setActivedisciplinebg] = useState(false);
  const [activeecombg, setActiveecombg] = useState(false);
  const [activeridingbg, setActiveridingbg] = useState(false);
  const [activegallarybg, setActivegallarybg] = useState(false);
  const [activecontactusbg, setActivecontactusbg] = useState(false);
  const islogout = useSelector((state) => state.loginStatus.islogout);
  // const isloggedIn = useSelector((state) => state.loginStatus.adminloggedIn);
  const [isloggedIn, setIsloggedIn] = useState(
    JSON.parse(localStorage.getItem("adminloggedIn"))
  );

  const [isStudentLoggedIn, setIsStudentLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isStudent"))
  )

  const Apilogo = useSelector((state) => state.Logo.logo);
  const isauthorized = useSelector((state) => state.loginStatus.isauthorized);
  const userData = useSelector((state) => state.loginStatus.userData);
  const userDataByID = useSelector((state) => state.getUsers.userDataByID);

  const themeSelector = useSelector((state) => state.theme.ThemeColor);
  const [sideBar, setSideBar] = useState(false);
  const [logo, setLogo] = useState(siteLogo);
  const [open, setOpen] = useState(false);
  const handleSideBar = () => {
    setSideBar(!sideBar);
  };
  const handleSideBarClose = () => {
    setSideBar(false);
  };

  const handleLogo = (e) => {
    setLogoFile(e.target.files[0]);
    console.log(LogoFile);
    if (LogoFile) {
      console.log("if statement");
      PostLogo();
    }
    console.log(LogoFile);
  };

  // const GetLogo = async () => {
  //   try {
  //     const response = await api.get(
  //       `get_logo/${userData && userData.company.id}`,
  //       {
  //         headers: {
  //           Authorization: `token ${token}`,
  //         },
  //       }
  //     );
  //     setLogo(response.data.logo);
  //     console.log(response.data, "Logo");
  //   } catch (error) {
  //     console.log("logo error", error);
  //   }
  // };

  const PostLogo = async () => {
    const headers = {
      "Content-Type": "multipart/form-data , application/json, text/plain",
      Authorization: `token ${token}`,
    };
    try {
      const response = await api.post(
        `upload_logo/`,
        { logo: LogoFile },
        { headers }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateLogo = async () => {
    const headers = {
      "Content-Type": "multipart/form-data , application/json, text/plain",
      Authorization: `token ${token}`,
    };
    try {
      const response = await api.put(
        `update_logo/1`,
        { logo: LogoFile },
        { headers }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleThemeChange = (event) => {
    setMainTheme(event.target.value);
    console.log(event.target.value, "selected theme from dropdown");
    dispatch(ChangeTheme(event.target.value));
    console.log("now");
  };

  const homebg = () => {
    setActivehomebg(true);
    setActiveaboutbg(false);
    setActivedisciplinebg(false);
    setActiveecombg(false);
    setActiveridingbg(false);
    setActivegallarybg(false);
    setActivecontactusbg(false);
  };
  const aboutbg = () => {
    setActiveaboutbg(true);
    setActivehomebg(false);

    setActivedisciplinebg(false);
    setActiveecombg(false);
    setActiveridingbg(false);
    setActivegallarybg(false);
    setActivecontactusbg(false);
  };
  const disciplinebg = () => {
    setActivedisciplinebg(true);
    setActiveaboutbg(false);
    setActivehomebg(false);

    setActiveecombg(false);
    setActiveridingbg(false);
    setActivegallarybg(false);
    setActivecontactusbg(false);
  };
  const ecombg = () => {
    setActiveecombg(true);
    setActiveaboutbg(false);
    setActivehomebg(false);

    setActivedisciplinebg(false);

    setActiveridingbg(false);
    setActivegallarybg(false);
    setActivecontactusbg(false);
  };
  const ridingbg = () => {
    setActiveridingbg(true);
    setActiveaboutbg(false);
    setActivehomebg(false);

    setActivedisciplinebg(false);
    setActiveecombg(false);

    setActivegallarybg(false);
    setActivecontactusbg(false);
  };
  const gallarybg = () => {
    setActivegallarybg(true);
    setActiveaboutbg(false);
    setActivehomebg(false);

    setActivedisciplinebg(false);
    setActiveecombg(false);
    setActiveridingbg(false);

    setActivecontactusbg(false);
  };
  const contactusbg = () => {
    setActivecontactusbg(true);
    setActiveaboutbg(false);
    setActivehomebg(false);

    setActivedisciplinebg(false);
    setActiveecombg(false);
    setActiveridingbg(false);
    setActivegallarybg(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    width: "40%",
    padding: "5px",
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [theme, setTheme] = useState(false);
  const Toogletheme = () => {
    setTheme(!theme);
  };

  // const cartData = localStorage.getItem("Cart") ? JSON.parse(localStorage.getItem("Cart")) : null;
  const cartData = localStorage.getItem("Cart") ? JSON.parse(localStorage.getItem("Cart")) : null;
  console.log("Cart Item", localStorageData);

  useEffect(() => {
    switch (themeSelector) {
      case "header-white":
        setHeaderDivClass("header header-white");
        break;
      case "header-roseate":
        setHeaderDivClass("header header-roseate");
        break;
      case "header-skyBlue":
        setHeaderDivClass("header header-skyBlue");
        break;
      case "header-grassy":
        setHeaderDivClass("header header-grassy");
        break;
      case "header-pink":
        setHeaderDivClass("header header-pink");
        break;
      case "header-gold":
        setHeaderDivClass("header header-gold");
        break;
      case "header-orange":
        setHeaderDivClass("header header-orange");
        break;
      default:
        // setHeaderDivClass("header");
        setHeaderDivClass("header header-gold");
        break;
    }
    setIsloggedIn(JSON.parse(localStorage.getItem("adminloggedIn")));
    if (Apilogo) {
      setLogo(Apilogo);
    }
    // if (isloggedIn) {
    //   GetLogo();
    // }

    if (userData && userData.usertype?.name === "Admin") {
      localStorage.setItem("isauthorized", true);
    } else {
      localStorage.setItem("isauthorized", false);
    }
  }, [themeSelector, Apilogo, isloggedIn, islogout, token]);

  return (
    <>
      <div className={headerdivClass}>

        <div className="header-logo">
          {isloggedIn && isauthorized && (<i onClick={(e) => setOpen(true)} class="fas fa-edit" style={{ fontSize: "20px", color: "#bebb9e", cursor: "pointer", width: "200px", }} title="Custom1  QAze Logo" />)}
          {isloggedIn ? userDataByID && userDataByID.usertype?.name === "Admin" ? <img src={`${ImgUrl}${userDataByID.profile_image} `} alt="Logo" width={80} /> : <img src={`${logo}`} alt="logo" width={80} /> : <img src={`${logo}`} alt="logo" width={80} />}
        </div>


        {!isStudentLoggedIn &&
          <div style={{ display: "flex", paddingLeft: "50px", }}>
            {!isloggedIn ? (
              <Button variant="outlined" sx={{ color: "black", borderColor: "#bebb9e", borderWidth: "1px", height: "40px", "&:hover": { borderColor: "#bebb9e", borderWidth: "2px", }, fontSize: "12px", }} onClick={() => { navigate("/login"); }}  >
                LOGIN
              </Button>
            ) : isloggedIn ? (
              <Button
                variant="outlined"
                sx={{ color: "black", borderColor: "#bebb9e", borderWidth: "1px", height: "40px", "&:hover": { borderColor: "#bebb9e", borderWidth: "2px", }, fontSize: "12px", }}
                onClick={() => { navigate("/dashboard"); }} >
                Dashboard <MdOutlineDashboard />
              </Button>
            ) : (
              <div></div>
            )}

            <div>


              {true && (
                <div style={{ height: "23px", width: "100px" }}>
                  <div className="form-control" style={{ display: "flex", alignItems: "center" }}>
                    <select id="theme" name="theme" value={mainTheme} onChange={handleThemeChange}
                      style={{ borderColor: "#bebb9e", borderRadius: "5px", padding: "8px", fontSize: "14px", background: "transparent", marginInline: "10px" }}>
                      <option value="header-gold">Themes</option>
                      <option value="header-white">White</option>
                      <option value="header-pink">Roseate</option>
                      <option value="header-roseate">Bluish</option>
                      <option value="header-skyBlue">Cerulean</option>
                      <option value="header-grassy">Grassy</option>
                      <option value="header-gold">Caucasion</option>
                      <option value="header-orange">Orange</option>
                    </select>
                  </div>
                </div>
              )}
            </div>
            <Button
              variant="outlined"
              sx={{ marginLeft: '1.5rem', color: "black", borderColor: "#bebb9e", borderWidth: "1px", height: "40px", "&:hover": { borderColor: "#bebb9e", borderWidth: "2px", }, fontSize: "12px", }}
              onClick={() => navigate('/cart')}
            >

              {cartData && cartData.length ? (<Badge content={cartData && cartData.length}>
                <IoIosCart style={{ fontSize: '2rem', color: 'brown', cursor: 'pointer' }} />
              </Badge>) : (<IoIosCart style={{ fontSize: '2rem', color: 'brown', cursor: 'pointer' }} />)}
            </Button>
          </div >}

        {
          isStudentLoggedIn ?
            <div style={{ display: "flex", paddingLeft: "50px", }}>
              {!isStudentLoggedIn ? (
                <Button variant="outlined" sx={{ color: "black", borderColor: "#bebb9e", borderWidth: "1px", height: "40px", "&:hover": { borderColor: "#bebb9e", borderWidth: "2px", }, fontSize: "12px", }} onClick={() => { navigate("/login"); }}  >
                  LOGIN
                </Button>
              ) : isStudentLoggedIn ? (
                <Button
                  variant="outlined"
                  sx={{ color: "black", borderColor: "#bebb9e", borderWidth: "1px", height: "40px", "&:hover": { borderColor: "#bebb9e", borderWidth: "2px", }, fontSize: "12px", }}
                  onClick={() => { navigate("/student-dashboard"); }} >
                  Dashboard <MdOutlineDashboard />
                </Button>
              ) : (
                <div></div>
              )}
              <div>


                {true && (
                  <div style={{ height: "23px", width: "100px" }}>
                    <div className="form-control" style={{ display: "flex", alignItems: "center" }}>
                      <select id="theme" name="theme" value={mainTheme} onChange={handleThemeChange}
                        style={{ borderColor: "#bebb9e", borderRadius: "5px", padding: "8px", fontSize: "14px", background: "transparent", marginInline: "10px" }}>
                        <option value="header-gold">Themes</option>
                        <option value="header-white">White</option>
                        <option value="header-pink">Roseate</option>
                        <option value="header-roseate">Bluish</option>
                        <option value="header-skyBlue">Cerulean</option>
                        <option value="header-grassy">Grassy</option>
                        <option value="header-gold">Caucasion</option>
                        <option value="header-orange">Orange</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <Button
                variant="outlined"
                sx={{ marginLeft: '1.5rem', color: "black", borderColor: "#bebb9e", borderWidth: "1px", height: "40px", "&:hover": { borderColor: "#bebb9e", borderWidth: "2px", }, fontSize: "12px", }}
                onClick={() => navigate('/cart')}
              >

                {cartData && cartData.length ? (<Badge content={cartData && cartData.length}>
                  <IoIosCart style={{ fontSize: '2rem', color: 'brown', cursor: 'pointer' }} />
                </Badge>) : (<IoIosCart style={{ fontSize: '2rem', color: 'brown', cursor: 'pointer' }} />)}
              </Button>

            </div> : null
        }



      </div >
      <div></div>

      {
        sideBar ? (
          <div></div>
        ) : (
          <div>
            <div
              className="SideBarbtn"
              style={{ backgroundColor: "#000000", color: "white" }}
            >
              <button onClick={handleSideBar}>
                <i class="fa-solid fa-bars"></i>
              </button>
            </div>
          </div>
        )
      }

      <nav>
        <ul
          className={
            sideBar
              ? "ResponsiveNav"
              : headerdivClass == "header"
                ? "navx"
                : "navx navx-light-theme"
          }
        >
          <li>
            <NavLink
              className={
                activehomebg ? "main-nav main-nav-bgcolor" : "main-nav"
              }
              to="/"
              onClick={homebg}
            >
              Home{" "}
            </NavLink>
            <div className="SideBarbtnClose">
              <button onClick={handleSideBarClose}>X</button>
            </div>
          </li>
          <li>
            <NavLink
              className={
                activeaboutbg ? "main-nav main-nav-bgcolor" : "main-nav"
              }
              to="/about"
              onClick={aboutbg}
            >
              about{" "}
            </NavLink>
          </li>
          <li>
            <div className="dropdown">
              <NavLink
                className={
                  activedisciplinebg ? "main-nav main-nav-bgcolor" : "main-nav"
                }
                to="/disciplines"
                onClick={disciplinebg}
              >
                Disciplines{" "}
              </NavLink>
              {/* <button class="dropbtn">Dropdown</button> */}
              <div className="dropdown-content">
                <NavLink onClick={disciplinebg} to="/disciplines/flat-race">
                  FLAT RACE
                </NavLink>
                <NavLink onClick={disciplinebg} to="/disciplines/arabian-shows">
                  ARABIAN SHOWS
                </NavLink>
                <NavLink onClick={disciplinebg} to="/disciplines/show-jumping">
                  SHOW JUMPING
                </NavLink>
              </div>
            </div>
          </li>
          <li>
            <div className="dropdown">
              <NavLink
                className={
                  activeecombg ? "main-nav main-nav-bgcolor" : "main-nav"
                }
                to="/e-commerce"
                onClick={ecombg}
              >
                Services{" "}
              </NavLink>
              {/* <button class="dropbtn">Dropdown</button> */}
              <div className="dropdown-content">
                <NavLink onClick={ecombg} to="/e-commerce/horse-livery">
                  HORSE LIVERY
                </NavLink>
                <NavLink onClick={ecombg} to="/e-commerce/stables-rent">
                  STABLES RENT
                </NavLink>
                <NavLink onClick={ecombg} to="/e-commerce/quarantine">
                  QUARANTINE
                </NavLink>
                <NavLink onClick={ecombg} to="/e-commerce/transportation">
                  TRANSPORTATION
                </NavLink>
              </div>
            </div>
          </li>
          <li>
            <NavLink
              className={
                activeridingbg ? "main-nav main-nav-bgcolor" : "main-nav"
              }
              to="/riding-school"
              onClick={ridingbg}
            >
              Riding School
            </NavLink>
          </li>
          <li>
            <div className="dropdown">
              <NavLink
                className={
                  activegallarybg ? "main-nav main-nav-bgcolor" : "main-nav"
                }
                onClick={gallarybg}
              // to="/gallery"
              >
                Gallary{" "}
              </NavLink>
              {/* <button class="dropbtn">Dropdown</button> */}
              <div className="dropdown-content">
                <NavLink onClick={gallarybg} to="/gallery/photo">
                  PHOTO
                </NavLink>
                <NavLink onClick={gallarybg} to="/gallery/video">
                  VIDEO
                </NavLink>
              </div>
            </div>
          </li>
          <li>
            <NavLink
              className={
                activecontactusbg ? "main-nav main-nav-bgcolor" : "main-nav"
              }
              onClick={contactusbg}
              to="/contact-us"
            >
              contact{" "}
            </NavLink>
          </li>
        </ul>
      </nav>

      {/* ADMIN MODAL  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            variant="outlined"
            sx={{
              color: "#302924",
              border: "0px",
              "&:hover": {
                border: "0px",
                backgroundColor: "#e0e0e0",
                color: "black",
              },

              float: "right",
              padding: "0px",
              justifyContent: "right",
              fontSize: "18px",
            }}
            onClick={() => setOpen(false)}
          >
            <i class="fa fa-close" />
          </Button>
          <form>
            <input type="file" onChange={handleLogo} />
            <Button
              variant="outlined"
              sx={{
                color: "#302924",
                border: "0px",
                "&:hover": {
                  border: "0px",
                  backgroundColor: "#e0e0e0",
                  color: "black",
                },

                padding: "0px",
                justifyContent: "right",
                fontSize: "18px",
              }}
              onClick={() => {
                PostLogo();
              }}
            >
              save
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#302924",
                border: "0px",
                "&:hover": {
                  border: "0px",
                  backgroundColor: "#e0e0e0",
                  color: "black",
                },

                padding: "0px",
                justifyContent: "right",
                fontSize: "18px",
              }}
              onClick={() => {
                UpdateLogo();
              }}
            >
              Update
            </Button>
          </form>
        </Box>
      </Modal>

      <div>
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default Header;

