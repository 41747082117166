import { FaTachometerAlt, BsShopWindow, FaTableCells } from 'react-icons/fa'
import horse_trainer from "../../../../Assets/Images/trainer2.svg";
import horse_owner from '../../../../Assets/Images/owner2.svg';
import stall from '../../../../Assets/Images/stall.png';
import cell from '../../../../Assets/Images/cell.png'
import horse from "../../../../Assets/Images/horse2.svg";
import Calendar from "../../../../Assets/Images/calendar.png";
import planner from "../../../../Assets/Images/to-do-list.png";
import quotation from "../../../../Assets/Images/quotation.png";
import tachometer from "../../../../Assets/Images/tachometer.png";
import ridingClub from "../../../../Assets/Images/riding_club.png";
import setting from "../../../../Assets/Images/setting.png";
import availability from "../../../../Assets/Images/event.png";
import { json } from 'react-router-dom';

const permissions = JSON.parse(localStorage.getItem('userData'))
console.log('permissions.permissions', permissions?.user_permissions);
const addCustomPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "add_customuser") : false;
const viewCustomPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_customuser") : false;
const viewUserTypeMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_usertypemaster") : false;
const viewReservationPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_reservation") : false;
const addReservationPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "add_reservation") : false;
const ViewHorsePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_horse") : false;
const addHorsePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "add_horse") : false;
const addOwnerPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "add_owner") : false;
const viewOwnerPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_owner") : false;
const viewTrainerPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_trainer") : false;
const addTrainerPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "add_trainer") : false;
const addPlannerPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "add_planer") : false;
const viewPlannerPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_planer") : false;
const viewTempOutPassPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_temperoryoutpass") : false;
const viewTempInPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_temporaryinpass") : false;
const viewEventPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_stableevent") : false;
const viewCheckInPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_horsecheckin") : false;
const viewCheckOutPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_permanentcheckout") : false;
const viewFacilityMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_facilitymaster") : false;
const viewServiceTypePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_servicetype") : false;
const viewPlannerMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_planermaster") : false;
const viewStableServicePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_stableservice") : false;
const viewFacilityTreePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_facility") : false;
const viewEventTypePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_eventtype") : false;
const viewTaxPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_taxmaster") : false;
const viewBreedPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_breedmaster") : false;
const viewBreederPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_breedermaster") : false;
const viewStableClassPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_stableclass") : false;
const viewDivisionMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_divisionmaster") : false;
const viewHorseGenderMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_horsegender") : false;
const viewTrainingMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_trainingmaster") : false;
const viewColorMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_colormaster") : false;
const viewVisaMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_visamaster") : false;
const viewDocTypePermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_documenttype") : false;
const viewHeathMasterPermission = permissions?.user_permissions ? permissions.user_permissions.some(obj => obj.codename === "view_healthmaster") : false;

const MenuItems = [
  {
    title: "RIDING CLUB",
    img: ridingClub,
    url: "/riding-club",
    styles: { filter: 'brightness(20.5)' }
  },
  {
    title: "DASHBOARD",
    img: tachometer,
    url: "/dashboard",
  },

  ((viewReservationPermission || ViewHorsePermission || viewOwnerPermission || viewTrainerPermission) || permissions?.usertype?.name === "Admin") && {
    header: "REGISTRATION",
    mainmenu: [
      (viewReservationPermission || permissions?.usertype?.name === "Admin") && {
        title: "RESERVATION",
        img: quotation,
        url: "/dashboard/quotation",
        submenu: [
          (viewReservationPermission || permissions?.usertype?.name === "Admin") && {
            title: "RESERVATION LIST",
            url: "/dashboard/quotation/quotation_list",
          },
          (addReservationPermission || permissions?.usertype?.name === "Admin") && {
            title: "CREATE RESERVATION",
            url: "/dashboard/quotation/quotation_registration",
          },
        ].filter(Boolean),
      },
      (ViewHorsePermission || permissions?.usertype?.name === "Admin") && {
        title: "HORSES",
        img: horse,
        url: "/dashboard/horses",
        submenu: [
          (ViewHorsePermission || permissions?.usertype?.name === "Admin") && {
            title: "HORSE lIST",
            url: "/dashboard/horseagreement/horses_list",
          },
          (addHorsePermission || permissions?.usertype?.name === "Admin") && {
            title: "REGISTER NEW HORSE",
            url: "/dashboard/horseagreement",
          },

        ].filter(Boolean)
      },
      (viewOwnerPermission || permissions?.usertype?.name === "Admin") && {
        title: "OWNERS",
        img: horse_owner,
        url: "/dashboard/owner",
        submenu: [
          (viewOwnerPermission || permissions?.usertype?.name === "Admin") && {
            title: "OWNER LIST",
            url: "/dashboard/owneragreement/owners_list",
          },
          (addOwnerPermission || permissions?.usertype?.name === "Admin") && {
            title: "REGISTER NEW OWNER",
            url: "/dashboard/owneragreement/registration_form",
          },
        ].filter(Boolean)
      },
      (viewTrainerPermission || permissions?.usertype?.name === "Admin") && {
        title: "TRAINERS",
        img: horse_trainer,
        url: "/dashboard/trainer",
        submenu: [
          (viewTrainerPermission || permissions?.usertype?.name === "Admin") && {
            title: "Trainers LIST",
            url: "/dashboard/traineragreement/trainers_list",
          },
          (addTrainerPermission || permissions?.usertype?.name === "Admin") && {
            title: "REGISTER NEW TRAINER",
            url: "/dashboard/traineragreement/registration_form",
          },
        ].filter(Boolean)
      },


    ].filter(Boolean)
  },
  ((viewTempOutPassPermission || viewTempInPermission) || permissions?.usertype?.name === "Admin") && {
    title: "STABLE",
    img: stall,
    url: "/dashboard/stable",
    submenu: [
      (viewCheckInPermission || permissions?.usertype?.name === "Admin") && {
        title: "CHECK IN",
        url: "/dashboard/stable/check_in",
      },
      (viewTempInPermission || permissions?.usertype?.name === "Admin") && {
        title: "TEMPORARY INPASS",
        url: "/dashboard/stable/temporary_inpass",
      },
      (viewTempOutPassPermission || permissions?.usertype?.name === "Admin") && {
        title: "TEMPORARY OUTPASS",
        url: "/dashboard/stable/temporary_outpass",
      },
      (viewCheckOutPermission || permissions?.usertype?.name === "Admin") && {
        title: "PERMANENT OUTPASS",
        url: "/dashboard/stable/permanent_outpass",
      },
    ].filter(Boolean),
  },
  (viewEventPermission || permissions?.usertype?.name === "Admin") && {
    header: "Calendar",
    mainmenu: [
      {
        title: "EVENTS",
        img: Calendar,
        url: "/dashboard/events_calendar",
      },
    ]
  },

  ((viewCheckInPermission || viewPlannerPermission || viewCheckOutPermission) || permissions?.usertype?.name === "Admin") && {
    header: "SERVICES",
    mainmenu: [
      (viewPlannerPermission || permissions?.usertype?.name === "Admin") && {
        title: "PLANNER",
        img: planner,
        url: "/dashboard/planner",
        submenu: [
          // {
          //   title: "FOOD",
          //   url: "/dashboard/planer/food_list",
          // },
          // {
          //   title: "MEDICAL",
          //   url: "/dashboard/planer/medical_list",
          // },
          // {
          //   title: "TRAINING",
          //   url: "/dashboard/planer/training_list",
          // },
          // {
          //   title: "HEALTH",
          //   url: "/dashboard/planer/health_list",
          // },
          // {
          //   title: "GROOMING",
          //   url: "/dashboard/planer/grooming_list",
          // },
          (viewPlannerPermission || permissions?.usertype?.name === "Admin") && {
            title: "PLANNER LIST",
            url: "/dashboard/planer/planer_list",
          },
          (addPlannerPermission || permissions?.usertype?.name === "Admin") && {
            title: "CREATE PLANNER",
            url: "/dashboard/planer/create",
          },
        ].filter(Boolean),
      },
    ].filter(Boolean)
  },

  // ((viewCheckInPermission || viewCheckOutPermission ) || permissions?.usertype?.name === "Admin") && {
  //   title: "AVAILABILITY",
  //   img: availability,
  //   url: "/dashboard/availability",
  //   submenu: [
  //     (viewCheckInPermission || permissions?.usertype?.name === "Admin") && {
  //       title: "CHECK IN",
  //       url: "/dashboard/stable/check_in",
  //     },
  //     (viewCheckOutPermission || permissions?.usertype?.name === "Admin") && {
  //       title: "PERMANENT OUTPASS",
  //       url: "/dashboard/stable/permanent_outpass",
  //     },
  //   ].filter(Boolean),
  // },

  // {
  //   title: "SETTINGS",
  //   img: setting,
  //   url: "/dashboard/settings",
  //   submenu: [
  //     (viewCustomPermission || permissions?.usertype === "Admin") && { 
  //       title: "USERS",
  //       url: "/dashboard/user_list",
  //     },
  //     (viewFacilityMasterPermission || permissions?.usertype === "Admin") && {
  //       title: "Facility Type",
  //       url: "/dashboard/settings/facility",
  //     },
  //     (viewServiceTypePermission || permissions?.usertype === "Admin") && {
  //       title: "Service Type",
  //       url: "/dashboard/settings/service-type",
  //     },
  //     (viewPlannerMasterPermission || permissions?.usertype === "Admin") && {
  //       title: "Planner Type",
  //       url: "/dashboard/settings/planner",
  //     },
  //     (viewStableServicePermission || permissions?.usertype === "Admin") && {
  //       title: "SERVICES",
  //       url: "/dashboard/settings/services",
  //     },
  //     (viewFacilityTreePermission || permissions?.usertype === "Admin") && {
  //       title: "Facility Tree",
  //       url: "/dashboard/settings/facility-tree",
  //     },
  //     (viewEventTypePermission || permissions?.usertype === "Admin") && {
  //       title: "Event Type",
  //       url: "/dashboard/settings/event_type",
  //     },

  //     (viewTaxPermission || permissions?.usertype === "Admin") && {
  //       title: "TAX",
  //       url: "/dashboard/settings/tax",
  //     },
  //     (viewBreedPermission || permissions?.usertype === "Admin") && {
  //       title: "BREED",
  //       url: "/dashboard/settings/breed",
  //     },
  //     (viewBreederPermission || permissions?.usertype === "Admin") && {
  //       title: "BREEDER",
  //       url: "/dashboard/settings/breeder",
  //     },
  //     (viewStableClassPermission || permissions?.usertype === "Admin") && {
  //       title: "STABLE CLASS",
  //       url: "/dashboard/settings/stable_class",
  //     },
  //     // {
  //     //   title: "CATEGORY MASTER",
  //     //   url: "/dashboard/settings/category_master",
  //     // },
  //     (viewDivisionMasterPermission || permissions?.usertype === "Admin") && {
  //       title: "DIVISION MASTER",
  //       url: "/dashboard/settings/division_master",
  //     },
  //     (viewHorseGenderMasterPermission || permissions?.usertype === "Admin") && {
  //       title: "HORSE GENDER MASTER",
  //       url: "/dashboard/settings/horse_gender_master",
  //     },
  //     (viewTrainingMasterPermission || permissions?.usertype === "Admin") && {
  //       title: "TRAINING MASTER",
  //       url: "/dashboard/settings/training_master",
  //     },
  //     (viewColorMasterPermission || permissions?.usertype === "Admin") && {
  //       title: "COLOR MASTER",
  //       url: "/dashboard/settings/color_master",
  //     },
  //     (viewVisaMasterPermission || permissions?.usertype === "Admin") && {
  //       title: "VISA MASTER",
  //       url: "/dashboard/settings/visa_master",
  //     },
  //     (viewDocTypePermission || permissions?.usertype === "Admin") && {
  //       title: "DOCUMENT TYPE MASTER",
  //       url: "/dashboard/settings/document_type_master",
  //     },
  //     (viewHeathMasterPermission || permissions?.usertype === "Admin") && {
  //       title: "HEALTH MASTER",
  //       url: "/dashboard/settings/health_master",
  //     },
  //     (viewHeathMasterPermission || permissions?.usertype === "Admin") && {
  //       title: "USER Type MASTER",
  //       url: "/dashboard/settings/usertype_master",
  //     },
  //   ].filter(Boolean),
  // },


  {
    header: "settings",
    mainmenu: [
      {
        title: "SETTINGS",
        img: setting,
        url: "/dashboard/settings",
      },
    ]
  },


].filter(Boolean);

export default MenuItems;
