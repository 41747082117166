import React, { useEffect, useState } from "react";
import "../../../../pages/Dashboard/Dashboard.css";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ContentBar from "../../../../SharedComponets/Dashboard/ContentBar/ContentBar";
import TopBar from "../../../../SharedComponets/Dashboard/TopBar/TopBar";
import { Link } from "react-router-dom";
import avtar from "../../../../Assets/RidingSchoolAssets/avtar.jpg";
import "./styles.css";
import { CgMenuRound } from "react-icons/cg";
import { ImHome } from "react-icons/im";
import { CgProfile } from "react-icons/cg";
import { BsJournalBookmark } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { VscFeedback } from "react-icons/vsc";
import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";

const StudentComponent = ({ Layout }) => {
  const { mobileNavbar } = useSelector((state) => state.modal);
  const [width, setWidth] = useState(window.innerWidth);
  const [isshowNavbar, setIsShowNavbar] = useState(mobileNavbar);
  const [menuActive, setMenuActive] = useState(false);
  const studentData = JSON.parse(localStorage?.getItem("student_data"));
  useEffect(() => {
    setWidth(window.innerWidth);
    setIsShowNavbar(mobileNavbar);
  }, [mobileNavbar, isshowNavbar]);
  return (
    <>
      <ToastContainer />
      <div className="dashboardlayout">
        <TopBar />
        <div className="s-main-content">
          {/* Student Menus @start */}
          <div className="student-sidebar">
            <nav>
              <button
                style={{ border: "none" }}
                onClick={() => {
                  setMenuActive(!menuActive);
                }}
              >
                <CgMenuRound className="student-menu-icon" />
              </button>
              <ul className={menuActive ? "st-menu-active" : "st-menu-hide"}>
                <Tooltip
                  sx={{ backgroundColor: "#fff" }}
                  title="Home"
                  arrow
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 600 }}
                >
                  <li>
                    <Link to="/student-dashboard">
                      <ImHome className="student-menu-icon" />
                    </Link>
                  </li>
                </Tooltip>
                <Tooltip
                  title="Profile"
                  arrow
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 600 }}
                >
                  <li>
                    <Link to={`edit_profile/${studentData?.id}`}>
                      <CgProfile className="student-menu-icon" />
                    </Link>{" "}
                  </li>
                </Tooltip>
                <Tooltip
                  title="Course"
                  arrow
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 600 }}
                >
                  <li>
                    <Link to="/student-dashboard/course">
                      <BsJournalBookmark className="student-menu-icon" />
                    </Link>
                  </li>
                </Tooltip>
                <Tooltip
                  title="Calender"
                  arrow
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 600 }}
                >
                  <li>
                    <Link to="/student-dashboard/events">
                      <SlCalender className="student-menu-icon" />
                    </Link>
                  </li>
                </Tooltip>
                <Tooltip
                  title="Feedback"
                  arrow
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 600 }}
                >
                  <li>
                    <Link to="/student-dashboard/feedback">
                      <VscFeedback className="student-menu-icon" />
                    </Link>
                  </li>
                </Tooltip>
              </ul>
            </nav>
          </div>
          {/* Student Menus @end */}
          <ContentBar sx={{ width: "100%" }} />
        </div>
        <div className="footer2">
          <div className="sub-footer">
            <div className="footer-container">
              <p>Copyright © 2024 EQI5 - All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentComponent;
