import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getEventTypeData = createAsyncThunk("getEventTypeData", async (token) => {
    try {
        const response = await api.get("get_event_type_list", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createEventTypeData = createAsyncThunk("createEventTypeData", async (payload) => {
    try {
        const response = await api.post("create_event_type", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteVisaMasterData = createAsyncThunk("deleteVisaMasterData", async (id, token) => {
    try {
        const response = await api.delete(`delete_visa/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateEventTypeData = createAsyncThunk("updateEventTypeData", async (payload) => {

    try {
        const response = await api.put(`update_visa/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateEventTypeData = createAsyncThunk("getupdateEventTypeData", async (id, token) => {
    try {
        const response = await api.get(`get_visa/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const EventTypeSlice = createSlice({
    name: "EventType",
    initialState: {
        status: "",
        EventTypeData: null,
        error: null,
        updateEventTypeData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getEventTypeData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getEventTypeData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.VisaMasterData = action.payload
            
        })
        builder.addCase(getEventTypeData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteVisaMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteVisaMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteVisaMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createEventTypeData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createEventTypeData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createEventTypeData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateEventTypeData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateEventTypeData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateEventTypeData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateEventTypeData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateEventTypeData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateVisaMasterData = action.payload

        })
        builder.addCase(getupdateEventTypeData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default EventTypeSlice.reducer;