import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./cart.module.css";
import { ImgUrl } from "../Config/Config";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import SlotApi from "../App/Apis/RidingSchoolApis/SlotApis/SlotApi.js";
import { useNavigate } from "react-router-dom";
import Loader from "../Ridingschool/components/Loader";
import { useStripe } from "@stripe/react-stripe-js";
import courses from "../App/Apis/RidingSchoolApis/courseApi/course.js";
import defalutImg from "../Assets/RidingSchoolAssets/eqi5default.jpg";
import PaymentModal from "../Ridingschool/pages/PaymentModal/PaymentModal.jsx";
import Context from "../Store/Context/Context.js";
import noCartItem from "../Assets/Images/noCartItem.png";

const CratItems = (props) => {
  const {
    index,
    picture,
    company,
    lesson,
    time,
    availableSlotInfo,
    CourseName,
    price,
    childPrice,
    totalPrice,
    count,
    handleCount,
    handleDelete,
  } = props;
  // console.log('props', props);

  return (
    <div className={styles.cart}>
      <div
        style={availableSlotInfo.seats === 0 ? { filter: "blur(1px)" } : {}}
        className={styles.cartContainer}
      >
        <div className={styles.cartContent}>
          <img
            src={picture ? ImgUrl + picture : defalutImg}
            alt={picture}
            srcset={picture ? ImgUrl + picture : defalutImg}
          />
          <div className={styles.cartData}>
            <p className={styles.cartCourse}>{CourseName}</p>
            <p className={styles.cartCompany}>{company?.company_name}</p>
            <p className={styles.cartLesson}>{time}</p>
            <p className={styles.cartLesson}>{lesson} Lessons</p>
            <p
              style={{ color: availableSlotInfo.color }}
              className={styles.cartLesson}
            >
              {availableSlotInfo.msg}
            </p>
          </div>
        </div>
        <div className={styles.cartPrice}>
          <div className="checkout__booking__details">
            <div className="checkout__booking__cart1">
              <span>Rider(s)</span>
              <span>{price}</span>
            </div>
            <div className="checkout__booking__cart2">
              <button
                className={count.adult === 0 ? "checkout__inActive" : ""}
                onClick={() => handleCount("adult", "minus", index)}
              >
                <FaMinus />{" "}
              </button>
              <span> {count.adult} </span>
              <button
                disabled={
                  count.adult + count.child === availableSlotInfo.seats
                    ? true
                    : false
                }
                className={
                  availableSlotInfo.seats
                    ? count.adult + count.child === availableSlotInfo.seats
                      ? "checkout__inActive"
                      : null
                    : null
                }
                onClick={() => handleCount("adult", "plus", index)}
              >
                <FaPlus />{" "}
              </button>
            </div>
          </div>
          {/* <div className="checkout__booking__details">
                    <div className="checkout__booking__cart1">
                        <span>Children</span><span>{childPrice}</span>
                    </div>
                    <div className="checkout__booking__cart2">
                        <button
                            className={count.child === 0 ? "checkout__inActive" : ""}
                            onClick={() => handleCount("child", "minus", index)}
                        >
                            <FaMinus />{" "}
                        </button>
                        <span> {JSON.stringify(count.child)}</span>
                        <button
                            className={
                                availableSlotInfo.seats
                                    ? count.adult + count.child ===
                                        availableSlotInfo.seats
                                        ? "checkout__inActive"
                                        : null
                                    : null
                            }
                            
                            disabled={
                                count.adult + count.child === availableSlotInfo.seats
                                    ? true
                                    : false
                            }
                            onClick={() => handleCount("child", "plus", index)}
                        >
                            <FaPlus />{" "}
                        </button>
                    </div>
                </div> */}
          <p style={{ textAlign: "center" }} className={styles.cartLesson}>
            Total:{totalPrice}
          </p>
        </div>
      </div>
      <button onClick={() => handleDelete(index)} className={styles.deleteBtn}>
        <MdDelete style={{ fontSize: "2.5rem" }} />
      </button>
    </div>
  );
};

function Cart() {
  const { getLocalStorage } = useContext(Context);
  const stripe = useStripe();
  const navigate = useNavigate();
  const cartData = localStorage.getItem("Cart")
    ? JSON.parse(localStorage.getItem("Cart"))
    : null;

  const [cartArray, setCartArray] = useState(cartData);
  const [subTotal, setSubTotal] = useState(0);
  const [availableSlotInfo, setAvailableSlotInfo] = useState({
    color: null,
    msg: null,
    seats: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [slotactive, setSlotActive] = useState(null);
  const [isCheckout, setIsCheckout] = useState(false);

  const handleCount = (name, type, index) => {
    const cartArrayDuplicate = [...cartArray];
    const newCounts = cartArrayDuplicate[index].counts;
    if (type === "minus" && newCounts[name] > 0) {
      newCounts[name] -= 1;
    } else if (type === "plus") {
      newCounts[name] += 1;
    }
    cartArrayDuplicate[index].counts = newCounts;
    // const total =
    //   newCounts.adult * cartArrayDuplicate[index].price +
    //   newCounts.child * cartArrayDuplicate[index].child_price;
    const total = newCounts.adult * cartArrayDuplicate[index].price;
    cartArrayDuplicate[index].total_price = total;
    setCartArray(cartArrayDuplicate);
  };
  const handleDelete = (index) => {
    const cartArrayDuplicate = [...cartArray];
    cartArrayDuplicate.splice(index, 1);
    localStorage.setItem("Cart", JSON.stringify(cartArrayDuplicate));
    setCartArray(cartArrayDuplicate);
    getLocalStorage();
  };

  const handleSlotActive = async () => {
    if (cartArray !== null) {
      const slotsId = cartArray.map((item) => {
        return item.slot.id;
      });

      setIsLoading(true);
      try {
        const res = await SlotApi.getCartAssignableStots({ ids: slotsId });
        console.log("res", res.data);
        const updatedSeatInfo = cartArray.map((data) => {
          const availableSlot = res.data.filter(
            (item) => data.slot.id === item.id
          )[0];
          setIsCheckout(true);
          if (availableSlot.seats === 0) {
          }
          if (availableSlot.seats > 0) {
            data.slot.availableSlotInfo = {
              color: "green",
              msg: `${availableSlot.seats} Seats are available`,
              seats: availableSlot.seats,
            };
          } else {
            data.slot.availableSlotInfo = {
              color: "red",
              msg: `0 Seats are available`,
              seats: 0,
            };
          }
          return data;
        });
        // console.log("updatedSeatInfo", updatedSeatInfo);
        const availableSlot = res.data.every((item) => item.seats === 0);
        console.log("availableSlot", availableSlot);
        setIsCheckout(availableSlot);

        setCartArray(updatedSeatInfo);
      } catch (error) {
        console.log("error", error);
      }
      setIsLoading(false);
    }
  };

  const [payloadData, setPayloadData] = useState([]);

  const cashPayment = () => {
    const studentData = JSON.parse(localStorage.getItem("student_data"));
    cartArray.forEach((data) => {
      const p = {
        total_price: data.total_price,
        courseId: data.courseId,
        studentId: studentData?.id,
        coursedata: data?.coursedata,
        slot_id: data?.slot?.id,
        persons: data.counts.adult,
        // childs: data.counts.child,
      };
      setPayloadData((prev) => [...prev, p]);
    });
  };

  const handleCheckout = async () => {
    try {
      const isStudent = localStorage.getItem("isStudent");

      if (isStudent) {
        const studentData = JSON.parse(localStorage.getItem("student_data"));

        const payload = [];
        cartArray.forEach((data) => {
          const p = {
            price: data.total_price,
            courseId: data.courseId,
            studentId: studentData?.id,
            coursedata: data?.coursedata,
            slot_id: data?.slot?.id,
            persons: data.counts.adult,
            // childs: data.counts.child,
          };
          payload.push(p);
        });

        localStorage.setItem("cart-payload", JSON.stringify(payload));

        const res = await courses.buyCartCourse({
          coursedata: "Checkout cart courses",
          list: payload,
          total_price: subTotal,
        });
        console.log("checkout Res::", res);
        stripe.redirectToCheckout({ sessionId: res.data.sessionId });
      } else {
        navigate("/student-login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useMemo(() => {
    if (cartArray !== null && cartArray.length) {
      const cartArrayDuplicate = [...cartArray];
      let subtotal = 0;
      cartArrayDuplicate.forEach((data, index) => {
        subtotal += cartArrayDuplicate[index].total_price;
      });
      setSubTotal(subtotal);
    }
  }, [cartArray]);

  useEffect(() => {
    handleSlotActive();
  }, []);

  const [paymentModalOpen, setPaymentModal] = useState(false);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.cartWrapper}>
      <div className={styles.cartMainContainer}>
        <h2>Cart Summary</h2>
        {cartArray !== null && Boolean(cartArray.length) ? (
          cartArray.map((data, i) => (
            <div key={`item${i}`}>
              <CratItems
                index={i}
                picture={data.image}
                company={data.company}
                lesson={data.slot.lesson}
                time={data.slot.time}
                availableSlotInfo={data.slot.availableSlotInfo}
                CourseName={data.coursedata}
                price={data.price}
                childPrice={data.child_price}
                totalPrice={data.total_price}
                count={data.counts}
                handleCount={handleCount}
                handleDelete={handleDelete}
              />
            </div>
          ))
        ) : (
          <>
            <img src={noCartItem} alt="no cart item" />
            <p>No cart item is available</p>
          </>
        )}
        {cartArray !== null && Boolean(cartArray.length) && (
          <>
            <div className={styles.total}>
              <p>Subtotal ({cartArray.length} items)</p>
              <p>{subTotal}</p>
            </div>
            <button
              // onClick={handleCheckout}
              onClick={() => setPaymentModal(true)}
              disabled={isCheckout}
              style={isCheckout ? { cursor: "not-allowed", color: "#ccc" } : {}}
              className="checkout__buyNow"
            >
              Checkout{isCheckout}
            </button>
          </>
        )}
      </div>
      <div>
        {paymentModalOpen ? (
          <PaymentModal
            isCart={"isCart"}
            handleCheckout={handleCheckout}
            setPaymentModalOpen={setPaymentModal}
            payloadData={payloadData}
            cashPayment={cashPayment}
            setPayloadData={setPayloadData}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Cart;

// const cartPayload = {
//     image: courseDetail.image,
//     counts,
//     courseId: courseId,
//     coursedata: courseDetail?.course_name,
//     company: { id: courseDetail.company.id, company_name: courseDetail.company },
//     slot: courseDetail.slot,
//     price: courseDetail.price,
//     child_price: courseDetail.child_price,
//     total_price: total,
//     studenId: studentData?.id,
//     slot_id: slotactive,
//     persons: counts.adult,
//     childs: counts.child,
//   }
