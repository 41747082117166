import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";
export const getCountries= createAsyncThunk("getCountries", async (_, {rejectWithValue}, token) => {
    try {
        const response = await api.get(`get_country`, {
            headers: {
              Authorization: `token ${token}`,
            },
          });
       return response.data
    } catch (error) {
        console.log("Failed to contries data");
    }
})

export const getISDcode = createAsyncThunk("getISDcode", async (_ , {rejectWithValue}, token) => {
    try {
        const response = await api.get(`isd_code`, {
            headers: {
              Authorization: `token ${token}`,
            },
          });
               return response.data
    } catch (error) {
        console.log("Failed to contries data");
    }
})

const CountriesSlice = createSlice({

    name: "countries",
    initialState: {
        status: "",
        countriesData: null,
        error: null,
        isd_code : null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getCountries.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getCountries.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.countriesData = action.payload
        })
        builder.addCase(getCountries.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getISDcode.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getISDcode.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.isd_code=action.payload
        })
        builder.addCase(getISDcode.rejected, (state) => {
            state.status = "failed"
        })
       
    
       
    }
})

export default CountriesSlice.reducer;