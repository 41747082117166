import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Apis";

export const uploadPartners = createAsyncThunk("uploadPartners", async ({ token, formData }) => {
  try {
    const response = await api.post("add_sponser", formData, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `token ${token}`
      }
    })
    console.log(response);
    return response.data
  } catch (error) {
    throw error
  }
})

export const updatePartners = createAsyncThunk("updatePartners", async ({ token }) => {
  try {
    const response = await api.put("update_sponser", {
      headers: {
        Authorization: `token ${token}`,
      }
    })
    return response.data
  } catch (error) {
    throw error
  }
})

export const getAllPartners = createAsyncThunk("getAllPartners", async (token) => {
  try {

    if (token) {
      const res = await api.get('get_sponser', {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      return res.data;
    } else {

      return null;
    }
  } catch (error) {

    throw error;
  }
})

export const deletePartners = createAsyncThunk("deletePartners", async ({ id, token }) => {
  try {
    const response = await api.delete(`delete_sponser/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      }
    })
    return response.data
  } catch (error) {
    throw error
  }
})


// 

export const PartnersSlice = createSlice({
  name: "parteners",
  initialState: {
    partnersData: null,
    status: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadPartners.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(uploadPartners.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.partnersData = action.payload
    })
    builder.addCase(uploadPartners.rejected, (state) => {
      state.status = "failed"
    })


    builder.addCase(updatePartners.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(updatePartners.fulfilled, (state, action) => {
      state.status = "succeeded"
    })
    builder.addCase(updatePartners.rejected, (state) => {
      state.status = "failed"
    })



    builder.addCase(getAllPartners.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getAllPartners.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.partnersData = action.payload
    })
    builder.addCase(getAllPartners.rejected, (state) => {
      state.status = "failed"
    })


    builder.addCase(deletePartners.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(deletePartners.fulfilled, (state, action) => {
      state.status = "succeeded"
    })
    builder.addCase(deletePartners.rejected, (state) => {
      state.status = "failed"
    })

  }
});

// Action creators are generated for each case reducer function
export const { Upload, Edit, Delete } = PartnersSlice.actions;

export default PartnersSlice.reducer;