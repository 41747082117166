import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { useCookies } from "react-cookie";
import { Modal } from "@mui/material";
import { TextField, Button, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  createPlannerData,
  deletePlannerData,
  getPlannerData,
  getUpdatePlannerData,
  updatePlannerData,
} from "../../App/Reducers/PlannerReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import ModalComp from "./Modal";
import DeleteModal from "../../App/Helpers/DeleteModal";
import api from "../../Apis";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../App/Helpers/AlertMessage";

const PlannerList = () => {
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addPlannerMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_planermaster"
      )
    : false;
  const changePlannerMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_planermaster"
      )
    : false;
  const deletePlannerMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_planermaster"
      )
    : false;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const plannerData = useSelector((state) => state.Planner.plannerData);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ name: "" });

  const openModal = () => {
    setModalData({
      name: "",
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createOrUpdateHandler = () => {
    if (modalData.id) {
      if (modalData.name !== "") {
        dispatch(
          updatePlannerData({
            id: modalData.id,
            updatedData: {
              name: modalData.name,
            },
            token,
          })
        );
      } else {
        return;
      }
    } else {
      if (modalData.name !== "") {
        dispatch(createPlannerData({ modalData, token }));
      } else {
        return;
      }
    }
    closeModal();
    dispatch(getPlannerData(token));
  };

  const editHandler = (id) => {
    const editData = plannerData.find((data) => data.id === id);
    if (editData) {
      setModalData(editData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_planner/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getPlannerData(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  useEffect(() => {
    dispatch(getPlannerData(token));
  }, [dispatch, token, modalOpen]);

  const header = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Planners",
      accessor: "name",
    },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>
      {plannerData ? (
        <BasicTable
          colHeader={header}
          rowData={plannerData}
          actionFlag={
            deletePlannerMasterPermission ||
            changePlannerMasterPermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changePlannerMasterPermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deletePlannerMasterPermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addPlannerMasterPermission || isUserType ? openModal : null
          }
          tableHeading="Planners"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            deletePlannerMasterPermission ||
            changePlannerMasterPermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changePlannerMasterPermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deletePlannerMasterPermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addPlannerMasterPermission || isUserType ? openModal : null
          }
          tableHeading="Planners"
        />
      )}

      <ModalComp
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        label="ADD PLANNER"
        heading="Planner"
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Planner"
        deleteData={deleteData}
      />
      <ToastContainer />
    </>
  );
};

export default PlannerList;
