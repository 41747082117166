import React, { useEffect, useState } from "react";
import "./EditProfile.css";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import api from "../../Apis";
import { useCookies } from "react-cookie";
import { getCountries } from "../../App/Reducers/AdminSettingReducer/CountryReducer";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { fetchUserById } from "../../App/Reducers/UserReducer";
import CommonLoading from "../../SharedComponets/Loading/CommonLoading";

export const EditProfile = () => {
  const { profile_id } = useParams();
  const dispatch = useDispatch();
  const fData = new FormData();

  const countries = useSelector((state) => state.countries.countriesData);

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const [imgError, setImgError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    first_name: null,
    last_name: null,
    phone_number: null,
    city: null,
    country: null,
    address: null,
    email: null,
    profile_image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAutoComplete = (event, value, fieldName) => {
    const selectedValue = value ? value.id : null;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValue,
    }));
  };

  const handleSaveChanges = async (id) => {
    fData.append("first_name", formData.first_name || "");
    fData.append("last_name", formData.last_name || "");
    fData.append("phone_number", formData.phone_number || "");
    fData.append("city", formData.city || "");
    fData.append("country", formData.country ? formData.country : "");
    fData.append("address", formData.address || "");
    if (formData.profile_image) {
      fData.append("profile_image", formData.profile_image || "");
    }

    try {
      setLoading(true);
      const response = await api.put(`update_profile/${profile_id}`, fData, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      toast.success(`user updated successfully.`, {
        autoClose: 3000,
      });
      // Set userData in local storage
      localStorage.setItem("userData", JSON.stringify(response.data));
      dispatch(fetchUserById(profile_id, token));
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const { files } = e.target;

    if (files) {
      const selectedFile = files[0];

      if (!selectedFile) {
        // No file selected, clear any previous errors
        setImgError("");
        return;
      }

      const allowedExtensions = ["jpg", "jpeg", "png", "jfif"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Invalid file format
        e.target.value = null;
        setFormData((previous) => ({
          ...previous,
          profile_image: null,
        }));
        setImgError(
          "Please upload a file with a .jpg, .jpeg, .jfif or .png extension."
        );
      } else if (selectedFile.size > 1024 * 1024) {
        // 1MB
        // File size exceeds 1MB
        e.target.value = null;
        setFormData((previous) => ({
          ...previous,
          profile_image: null,
        }));
        setImgError(
          "Please upload an profile_image that is less than 1MB in size."
        );
      } else {
        // Valid file format and size, update the form data and clear any errors
        setFormData((previous) => ({
          ...previous,
          profile_image: selectedFile,
        }));
        setImgError("");
      }
    }
  };

  const allAPI = async () => {
    try {
      const response = await api.get(`get_user/${profile_id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Use useEffect to observe changes in formData and trigger the API call
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getCountries(token));
      // dispatch(GetUsersById({ id: profile_id, fData, token }))
      dispatch(fetchUserById(profile_id, token));

      const userData = await allAPI();
      if (userData) {
        setFormData({
          id: userData.id,
          first_name: userData.first_name,
          last_name: userData.last_name,
          phone_number: userData.phone_number,
          city: userData.city,
          country: userData.country == null ? " " : userData.country.id,
          address: userData.address,
          email: userData.email,
          usertype: userData.usertype,
        });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("formData", formData);
  }, []);

  return (
    <div className="reset-password-container">
      <div style={{ position: "fixed" }}>
        <ToastContainer />

      </div>
      <div className="reset-passord-text">
        <h1 className="reset-password-text-header">Edit Profile</h1>
      </div>
      <form className="change-password-form">
        <div className="reset-password-input-fields">
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <div className="edit-profile-form-group">
                <label className="form-label">FIRST NAME</label>
                <div className="password-field">
                  <TextField
                    name="first_name"
                    className="input-field"
                    value={formData ? formData.first_name : null}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </div>
              </div>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <div className="edit-profile-form-group">
                <label className="form-label">LAST NAME</label>
                <div className="password-field">
                  <TextField
                    name="last_name"
                    className="input-field"
                    value={formData ? formData.last_name : null}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <div className="edit-profile-form-group">
                <label className="form-label">EMAIL</label>
                <div className="password-field">
                  <TextField
                    name="email"
                    className="inputfield  bg-color"
                    value={formData ? formData.email : null}
                    // onChange={handleChange}
                    readOnly
                    required
                    fullWidth
                    sx={{
                      "& input": {
                        cursor: "no-drop", // Apply the cursor style to the input field
                      },
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <div className="edit-profile-form-group">
                <label className="form-label">PHONE NUMBER</label>
                <div className="password-field">
                  <TextField
                    name="phone_number"
                    className="input-field"
                    value={formData ? formData.phone_number : null}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <div className="edit-profile-form-group">
                <label className="form-label">CITY</label>
                <div className="password-field">
                  <TextField
                    name="city"
                    className="input-field"
                    value={formData ? formData.city : null}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div style={{ marginRight: "15px" }}>
                <label>
                  COUNTRY <span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  name="country"
                  value={
                    countries && formData.country
                      ? countries.find(
                        (item) => item.id === Number(formData.country)
                      )
                      : null
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "country")
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={countries}
                  required
                  getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="select country"
                      {...params}
                    />
                  )}
                />
              </div>
            </Grid>
            {/* Repeat for other fields as needed */}
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <div className="edit-profile-form-group">
                <label className="form-label">ADDRESS</label>
                <div className="password-field">
                  <TextField
                    name="address"
                    className="input-field"
                    value={formData ? formData.address : null}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </div>
              </div>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <div className="edit-profile-form-group">
                <label className="form-label">PROFILE IMAGE</label>
                <TextField
                  // className="bg-color"
                  id="upload-photo"
                  name="profile_image"
                  type="file"
                  onChange={handleImageChange}
                  fullWidth
                />
              </div>
              <span style={{ fontSize: "13px", color: "red" }}>{imgError}</span>
            </Grid>
          </Grid>
        </div>
        <div className="reset-password-button">
          <Button
            type="button"
            onClick={handleSaveChanges}
            variant="contained"
            className="btn-bgColor"
          >
            {loading ? (
              <CommonLoading height="20px" width="20px" color="white" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};
