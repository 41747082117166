import React, { useEffect, useState, useRef } from "react";
import "./TopBar.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "../../../App/Reducers/LoginReducer";
import { useCookies } from "react-cookie";
import userImg from "../../../Assets/Images/user.jpg";
import { FaCloudSun } from "react-icons/fa";
import { BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";
import { BiTachometer, BiUserCircle } from "react-icons/bi";
import { IoKeyOutline, IoLogOutOutline } from "react-icons/io5";
import { USERLOGOUT } from "../../../App/Apis/AuthApi";
import { fetchUserById } from "../../../App/Reducers/UserReducer";
import { ImgUrl } from "../../../Config/Config";
import LoginApi from "../../../App/Apis/RidingSchoolApis/studentApi/login";

const TopBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(["token", "email", "password"]);
  const token = cookies.token;

  const [, , removeCookie] = useCookies(["token"]);

  const [ismenuShow, setIsmenuShow] = useState(false);
  const [notifications, setNotifications] = useState(["notification"]);

  let userData = useSelector((state) => state.loginStatus.userData);
  const studentDatas = JSON.parse(localStorage.getItem("student_data"));
  const studentImage = studentDatas?.image;

  const detailsRef = useRef(null);

  const SetListActive = (i) => {
    setIsmenuShow(!ismenuShow);
  };

  const isStudentLoggedIn = JSON.parse(localStorage.getItem("isStudent"));
  const studentData = isStudentLoggedIn
    ? JSON.parse(localStorage.getItem("student_data"))
    : "";
  const studentToken = isStudentLoggedIn
    ? JSON.parse(localStorage.getItem("student_token"))
    : "";
  const studentId = studentData ? studentData.id : null;

  const userId = JSON.parse(localStorage.getItem("userData"));
  function getCurrentFormattedDate() {
    const date = new Date();
    const options = {
      day: "numeric",
      month: "long",
      weekday: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedDate = formatter.format(date);
    return formattedDate.replace(",", "");
  }

  const LOGOUTFunc = async () => {
    USERLOGOUT(token)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          // Clear cookies
          removeCookie("token");

          // Clear localStorage
          localStorage.removeItem("adminloggedIn");
          localStorage.removeItem("islogout");
          localStorage.removeItem("userData");
          localStorage.removeItem("token");
          localStorage.clear();

          dispatch(
            setLogout({
              status: res.status,
              message: res.data.message,
            })
          );
          navigate("/login");
        } else {
          dispatch(
            setLogout({
              status: res.status,
              message: res.data.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        removeCookie("token");
        // Clear localStorage
        localStorage.removeItem("adminloggedIn");
        localStorage.removeItem("islogout");
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/login");
      });
  };

  const studentLogout = async () => {
    try {
      const res = await LoginApi.studentLogOut(studentId, {
        token: studentToken,
      });
      localStorage.removeItem("isStudent");
      localStorage.removeItem("student_data");
      localStorage.removeItem("student_token");
      console.log(res.data);
      window.location.reload();
      navigate("/student-login");
    } catch (err) {
      console.log(err);
      localStorage.removeItem("isStudent");
      localStorage.removeItem("student_data");
      localStorage.removeItem("student_token");
      window.location.reload();
      navigate("/student-login");
    }
  };

  // const getUseenNotification = async () => {
  //   try {
  //     const res = await api.get(`useen_notifications_of_user/${userId.id}`, {
  //       headers: {
  //         'Authorization': `token ${token}`
  //       }
  //     })
  //     setNotifications(res.data)
  //   } catch (error) {

  //   }
  // }

  const connectToWebsocket = async () => {
    try {
      const socket = new WebSocket(
        `ws://127.0.0.1:8000/ws/connect/${userId.id}`
      );
      socket.onopen = () => {
        console.log("WebSocket connected");
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        setNotifications((prevNotifications) => {
          const notification = [...prevNotifications];
          if (notification.length) {
            const n = notification.filter(
              (item) => item.id !== data.message.id
            );
            // n.push(data.message)
            return [data.message, ...n];
          } else {
            return [data.message];
          }
        });
      };
    } catch (error) { }
  };

  // useEffect(() => {

  // }, [userData?.first_name])

  useEffect(() => {
    const islogout = JSON.parse(localStorage.getItem("islogout"));
    const token = localStorage.getItem("token");

    if (islogout) {
      localStorage.setItem("token", "");
      removeCookie("token", { path: "/" });
      navigate("/login");
    } else if (!islogout && token !== "") {
      const token = localStorage.getItem("token");
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      setCookie("token", token, { path: "/", expires: expirationDate });
    } else {
      removeCookie("token", { path: "/" });
      navigate("/login");
    }
    const handleClickOutside = (event) => {
      if (detailsRef.current && !detailsRef.current.contains(event.target)) {
        setIsmenuShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [
    JSON.parse(localStorage.getItem("islogout")),
    token,
    studentToken,
    userData,
  ]);

  useEffect(() => {
    if (userData && !isStudentLoggedIn) {
      if (userData.id) {
        dispatch(fetchUserById(userData.id, token));
      }
    }
    // getUseenNotification()
    // connectToWebsocket()
  }, []);

  const userDataByID = useSelector((state) => state.getUsers.userDataByID);

  // Notification center
  const [isNotificationCenterVisible, setNotificationCenterVisibility] =
    useState(false);

  const handleNotificationIconClick = () => {
    setNotificationCenterVisibility(!isNotificationCenterVisible);
  };

  const handleNotificationClose = () => {
    setNotificationCenterVisibility(false);
  };

  return (
    <>
      <div className="dashboard-topbar" ref={detailsRef}>
        <Link to="/">
          <div className="logo-imgBx">
            <FaCloudSun style={{ color: "white" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!isStudentLoggedIn && (
                <h4>
                  {userDataByID
                    ? userDataByID.company.company_name
                    : "Nable Invent Solution"}
                </h4>
              )}
              <span className="toptime">{getCurrentFormattedDate()}</span>
            </div>
          </div>
        </Link>

        <div>
          <ul>
            {/* <IconButton
              className="notification-icon"
              onClick={handleNotificationIconClick}
            >
              <NotificationsIcon style={{ fontSize: "24px", color: "white" }} />
              <span className="notification-badge">
                {notifications ? notifications.length : 0}
              </span>
            </IconButton> */}

            {/* <Notification
              open={isNotificationCenterVisible}
              notifications={notifications}
              handleNotificationIconClick={handleNotificationIconClick}
              handleNotificationClose={handleNotificationClose}
            /> */}

            <li
              className="menu-items"
              onClick={(e) => {
                SetListActive(e);
              }}
            >
              <div>
                <div className="user_dp">
                  <img
                    src={
                      userDataByID
                        ? `${ImgUrl}${userDataByID.profile_image} `
                        : studentDatas
                          ? `${ImgUrl}${studentImage} `
                          : userImg
                    }
                    alt="user_dp"
                  />
                </div>
                {isStudentLoggedIn ? (
                  <span style={{ fontSize: "16px" }}>
                    {" "}
                    {studentData?.first_name} {studentData?.last_name}{" "}
                  </span>
                ) : (
                  <span style={{ fontSize: "16px" }}>
                    {userDataByID?.first_name && userDataByID?.last_name
                      ? ` ${userDataByID?.first_name} ${userDataByID?.last_name}`
                      : `Nable Invent Solution`}
                  </span>
                )}

                {ismenuShow ? (
                  <BsFillCaretUpFill className="icon" />
                ) : (
                  <BsFillCaretDownFill />
                )}
              </div>
              {ismenuShow ? (
                <ul className="dropdown">
                  <li>
                    <BiTachometer />
                    {!isStudentLoggedIn ? (
                      <Link to="/dashboard">Dashboard</Link>
                    ) : (
                      <Link to="/student-dashboard">Dashboard</Link>
                    )}
                  </li>
                  <li>
                    <BiUserCircle />
                    {!isStudentLoggedIn ? (
                      <Link
                        to={`/dashboard/edit_profile/${userData ? userData.id : null
                          }`}
                      >
                        Edit Profile
                      </Link>
                    ) : (
                      <Link
                        to={`./edit_profile/${studentData ? studentData.id : null
                          }`}
                      >
                        Edit Profile
                      </Link>
                    )}
                  </li>
                  {!isStudentLoggedIn ? (
                    <li>
                      <IoKeyOutline />
                      {/* <Link to="/dashboard/change_password" onClick={()=>handleNavigate(userData.id)}>Change Password</Link> */}
                      <Link
                        to={`/dashboard/edit_profile/${userData ? userData.id : null
                          }`}
                      >
                        Change Password
                      </Link>
                    </li>
                  ) : null}
                  <li>
                    <IoLogOutOutline />
                    {!isStudentLoggedIn ? (
                      <Link onClick={LOGOUTFunc}>Logout</Link>
                    ) : (
                      <Link onClick={studentLogout}>Logout</Link>
                    )}
                  </li>
                </ul>
              ) : null}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TopBar;
