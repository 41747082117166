import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { useCookies } from "react-cookie";
import { Modal } from "@mui/material";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getFacilityTreeData,
  createFacilityTreeData,
  deleteFacilityTreeData,
  updateFacilityTreeData,
} from "../../App/Reducers/FacilityTreeReducer";
import { getData } from "../../App/Reducers/FacilityTypeReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import api from "../../Apis";
import DeleteModal from "../../App/Helpers/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../App/Helpers/AlertMessage";

const FacilityTreeList = () => {
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "fit-content",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    height: "fit-content",
    overflow: "auto",
    p: 4,
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };

  const facilityTreeData = useSelector(
    (state) => state.facilityTree.facilityTreeData
  );
  const facilityData = useSelector((state) => state.facilityTypes.facilityData);
  const plannerData = useSelector((state) => state.Planner.plannerData);
  let facilityServiceType;
  const [ErrorValue, setErrorValue] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [service, setServiceData] = useState([]);
  const [modalData, setModalData] = useState({
    name: null,
    facility: null,
    service: null,
    capacity: null,
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addFacilityTreePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_facility"
      )
    : false;
  const changeFacilityTreePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_facility"
      )
    : false;
  const deleteFacilityTreePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_facility"
      )
    : false;

  const openModal = () => {
    dispatch(getData(token));
    setModalData({
      name: null,
      facility: null,
      service: null,
      capacity: null,
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getService = async (id, token) => {
    try {
      const response = await api.get(`facility_service_type/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleAutoComplete = async (event, value, fieldName) => {
    console.log(value);
    if (value === null) {
      const selectedValue = value === null ? null : value.id;
      setModalData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else {
      if (fieldName === "facility") {
        console.log("fieldName", fieldName);
        facilityServiceType = await getService(value.id, token);
        console.log("facilityServiceType", facilityServiceType);
        const sdata = facilityServiceType.map((item) => {
          return { id: item.id, name: item.name };
        });
        setServiceData(() => sdata);
        console.log("service", service);
        const selectedValue = value === null ? null : value.id;
        setModalData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: selectedValue,
        }));
      } else {
        const selectedValue = value === null ? null : value.id;
        setModalData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: selectedValue,
        }));
      }
    }
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "capacity" &&
      !/^\d*$/.test(value) &&
      modalData.capacity !== ""
    ) {
      setErrorValue(true);
      return;
    } else {
      setErrorValue(false);
      setModalData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const createOrUpdateHandler = () => {
    if (
      modalData.capacity !== null &&
      modalData.capacity !== "" &&
      modalData.name !== null &&
      modalData.facility !== null &&
      modalData.service !== null
    ) {
      if (modalData.id) {
        dispatch(
          updateFacilityTreeData({
            id: modalData.id,
            updatedData: {
              name: modalData.name,
              price: modalData.price,
              service: modalData.service,
            },
            token,
          })
        );
        closeModal();
      } else {
        dispatch(createFacilityTreeData({ modalData, token }));

        closeModal();
      }
      dispatch(getFacilityTreeData(token));
    } else {
      return;
    }
  };

  const editHandler = async (id) => {
    dispatch(getData(token));
    console.log(facilityTreeData);
    const editData = facilityTreeData.find((data) => data.id === id);
    console.log(editData);
    let updateDatas = {
      id: editData.id,
      name: editData.name,
      facility: editData.facility.id,
      service: editData.service.id,
      capacity: editData.capacity,
    };
    console.log("updateDatas>>", updateDatas);
    if (editData.facility.id) {
      facilityServiceType = await getService(editData.facility.id, token);
      console.log("facilityServiceType", facilityServiceType);
      const sdata = facilityServiceType.map((item) => {
        return { id: item.id, name: item.name };
      });
      console.log("sdata", sdata);
      setServiceData(sdata);
    }

    // if (editData.service.id) {

    // }
    console.log("edited Data :", editData);
    if (editData) {
      setModalData(updateDatas);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async() => {
    if(deleteId){
        try {
            const response = await api.delete(`delete_facility_header/${deleteId}`,{
                headers:{
                    Authorization : `token ${token}`
                }
            })
            if(response.status === "201"  || response.statusText === "OK"){
                setDeleteModalOpen(false)
                toast.success("Delete successfully")
                dispatch(getFacilityTreeData(token))
            }
        } catch (error) {
          if (error.response.data.Error) {
            setIsErrorVisible(true);
            setIsErrorResponse(error.response.data.Error);
          }
  
          setTimeout(() => {
            setIsErrorVisible(false);
          }, 6000);
        }
    }
  }

  useEffect(() => {}, facilityTreeData);

  useEffect(() => {
    dispatch(getFacilityTreeData(token));
  }, [dispatch, token, modalOpen]);

  const header = [
    {
      Header: "Facility Code",
      accessor: "id",
    },
    {
      Header: "Facility Name",
      accessor: "name",
    },
    {
      Header: "Facility Type",
      accessor: "facility.name",
    },
    {
      Header: "Service",
      accessor: "service.name",
    },
    {
      Header: "Capacity",
      accessor: "capacity",
    },
  ];

  return (
    <>
     <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {facilityTreeData ? (
        <BasicTable
          colHeader={header}
          rowData={facilityTreeData}
          actionFlag={
            deleteFacilityTreePermission ||
            changeFacilityTreePermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeFacilityTreePermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteFacilityTreePermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addFacilityTreePermission || isUserType ? openModal : null
          }
          tableHeading="Facility Tree"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            deleteFacilityTreePermission ||
            changeFacilityTreePermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeFacilityTreePermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteFacilityTreePermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addFacilityTreePermission || isUserType ? openModal : null
          }
          tableHeading="Facility Tree"
        />
      )}

      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll-bar">
          <Button
            sx={{
              color: "#302924",
              border: "0px",
              float: "right",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RxCross2
              style={{ color: "#302924", fontSize: "1.7rem" }}
              onClick={closeModal}
            />
          </Button>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: "#302924",
              marginBottom: "5vh",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            {modalData.id ? "UPDATE FACILITY-TREE" : "CREATE FACILITY-TREE"}
          </Typography>
          <form
            onSubmit={(e) => {
              createOrUpdateHandler(e);
            }}
          >
            <Grid container spacing={2}>
              {/* First Row */}
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label>FACILITY NAME</label>
                    <TextField
                      type="text"
                      name="name"
                      onChange={handleModalInputChange}
                      value={modalData.name}
                      placeholder="Enter Facility Name"
                      fullWidth
                      required
                      style={{ marginBlock: "5px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Second Row */}
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label>FACILITY TYPE</label>
                    <Autocomplete
                      name="facility"
                      disablePortal
                      id="facility"
                      options={facilityData}
                      getOptionLabel={(option) => option.name}
                      value={
                        modalData &&
                        modalData.facility &&
                        facilityData.find(
                          (item) => item.id === Number(modalData.facility)
                        )
                      }
                      onChange={(event, value) =>
                        handleAutoComplete(event, value, "facility")
                      }
                      sx={{
                        color: "#302924",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required />
                      )}
                      style={{ marginBlock: "5px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Third Row */}
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label>SERVICE TYPE</label>
                    <Autocomplete
                      name="service"
                      disablePortal
                      id="service"
                      options={service}
                      getOptionLabel={(option) => option.name}
                      value={
                        modalData &&
                        modalData.facility &&
                        service.find(
                          (item) => item.id === Number(modalData.service)
                        )
                      }
                      onChange={(event, value) =>
                        handleAutoComplete(event, value, "service")
                      }
                      sx={{
                        color: "#302924",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required />
                      )}
                      style={{ marginBlock: "5px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Fourth Row */}
              <Grid item xs={12} sm={6} md={6}>
                <label>CAPACITY</label>
                <TextField
                  type="text"
                  name="capacity"
                  disablePortal
                  onChange={handleModalInputChange}
                  value={modalData.capacity}
                  placeholder="Enter Capacity"
                  fullWidth
                  required
                  error={ErrorValue}
                  helperText={ErrorValue ? "Please enter a Capacity." : ""}
                  style={{ marginBlock: "5px" }}
                />
              </Grid>
            </Grid>

            <Button
              variant="outlined"
              type="submit"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },
                marginTop: "15px",
                float: "right",
                fontSize: "12px",
                backgroundColor: "#302924",
              }}
              // onClick={createOrUpdateHandler}
            >
              {modalData.id ? "UPDATE" : "CREATE"}
            </Button>
          </form>
        </Box>
      </Modal>

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Facility Tree"
        deleteData={deleteData}
      />

      <ToastContainer/>
    </>
  );
};

export default FacilityTreeList;
