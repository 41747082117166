// Local Server
// export const baseURL = "http://192.168.29.155:8000/api";
// export const ImgUrl = "http://192.168.29.155:8000";

// export const baseURL = "http://192.168.29.155:8002/api";
// export const ImgUrl = "http://192.168.29.155:8002";

//  Live Server
export const baseURL = "https://nable.eqi5.com/api";
export const ImgUrl = "https://nable.eqi5.com";
