import api from "../../Apis";


export const getCheckInByID = async (payload) => {
    try {
        const response = await api.get(`get_checkin/${payload.id}`, {
            headers: {
                Authorization: `token ${payload.token}`,
            },
        });
        return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        console.log("error.response.data", error.response.data);
        return error.response

    }
}

export const createCheckIn = async (payload) => {
  
    try {
        const response = await api.post(
            "create_checkin",
            payload.formData,

            {
                headers: {
                    "Content-Type": "multipart/form-data,application/json",
                    Authorization: `token ${payload.token}`,
                },
            }
        );
        return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        console.log("error.response.data", error.response.data);
        return error.response

    }
}

export const updateCheckIn = async (payload) => {

    try {
      
        const response = await api.put(
            `update_checkin/${payload.id}`,
            payload.formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data, application/json",
                    // Authorization: `token ${updatedData.token}`,
                    Authorization: `token ${payload.token}`,
                },
            }
        );
        return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        console.log("error.response.data", error.response.data);
        return error.response

    }
}
