// Import required libraries
import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "./StableLoading.css"

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'black'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

// PropTypes for CircularProgressWithLabel component
CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

// Define CircularWithValueLabel component
export default function CircularWithValueLabel(props) {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress style={{ height: props.height ? props.height : '50px', width: props.width ? props.width : '50px', color: props.color ? props.color : "#9e8653" }} />
    </div>
  );
}
