import React, { useEffect, useState } from "react";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { getPermOpData } from "../../App/Reducers/Avaiblity Slices/PermanentOutpassSlice";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../App/Helpers/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Apis";
import AlertMessage from "../../App/Helpers/AlertMessage";

const PermanentOutpass = () => {
  const navigate = useNavigate();

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const dispatch = useDispatch();

  const PermOpData = useSelector((state) => state.permanent_outpass.PermOpData);

  const colHeader = [
    {
      Header: "Reservation",

      accessor: "code",
    },
    {
      Header: "Horse Name",

      accessor: "horse.name",
    },
    {
      Header: "Staff Name",
      accessor: "staff",
      Cell: ({ row }) => {
        const first_name = row.original.staff?.first_name;
        const last_name = row.original.staff?.last_name;
        const usertype = row.original.staff?.usertype;
        return first_name ? `${first_name} ${last_name} (${usertype.name})` : "";
      },
    },
    {
      Header: "Date & Time",
      accessor: "date",
      Cell: ({ value }) => {
        const date = new Date(value);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          // second: '2-digit',
          hour12: true,
          timeZone: "Etc/GMT",
        }).format(date);
        return formattedDate;
      },
    },

    // {
    //   Header: "Description",
    //   accessor: "description",
    // },
    // {
    //   Header: "Reason",

    //   accessor: "reason",
    // },
  ];

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(
          `delete_temporary_outpass/${deleteId}`,
          {
            //api not created at backend
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getPermOpData(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  const handleCreate = () => {
    navigate("/dashboard/horses/checkout");
  };

  const updateHandler = async (id) => {
    navigate(`/dashboard/horses/checkout/${id}`);
  };

  useEffect(()=>{
    dispatch(getPermOpData(token))
  },[])

  return (
    <div>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {PermOpData ? (
        <BasicTable
          colHeader={colHeader}
          rowData={PermOpData}
          createHandler={handleCreate}
          deleteHandler={deleteHandler}
          updateHandler={updateHandler}
          tableHeading="Permanent outpass"
        />
      ) : (
        <BasicTable
          colHeader={colHeader}
          rowData={[]}
          createHandler={handleCreate}
          deleteHandler={deleteHandler}
          updateHandler={updateHandler}
          tableHeading="Permanent outpass"
        />
      )}

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Permanent Outpass"
        deleteData={deleteData}
      />
      <ToastContainer />
    </div>
  );
};

export default PermanentOutpass;
