import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs/AboutUs";
import Disciplines from "../pages/Disciplines/Disciplines/Disciplines";
import FlatRace from "../pages/Disciplines/FlatRace/FlatRace";
import ArabianShows from "../pages/Disciplines/ArabianShows/ArabianShows";
import ShowJumping from "../pages/Disciplines/ShowJumping/ShowJumping";
import Ecommerce from "../pages/Ecommerce/Ecommerce";
import HorseLivery from "../pages/Ecommerce/HorseLivery/HorseLivery";
import StablesRent from "../pages/Ecommerce/StablesRent/StablesRent";
import Quarantine from "../pages/Ecommerce/Quarantine/Quarantine";
import RidingSchool from "../pages/RidingSchool/RidingSchool";
import Photo from "../pages/Gallery/Photo/Photo";
import Video from "../pages/Gallery/Video/Video";
import ContactUs from "../pages/ContactUs/ContactUs";
import Header from "../SharedComponets/Header";
import Transportation from "../pages/Ecommerce/Transporation/Transportation";
import Login from "../pages/LoginRegister/Login";
import HorseRegistration from "../pages/HorseManagement/HorseRegistration";
import Dashboard from "../pages/Dashboard/Dashboard";
import DashboardLayout from "../pages/Dashboard/DashboardLayout";
import OwnerRegistration from "../pages/OwnerManagement/OwnerRegistration";
import Register from "../pages/LoginRegister/Register";
import HorseAgreement from "../pages/HorseManagement/HorseAgreement";
import OwnerAgreement from "../pages/OwnerManagement/OwnerAgreement";
import TrainerAgreement from "../pages/TrainerRegistration/TrainerAgreement";
import TrainerRegistration from "../pages/TrainerRegistration/TrainerRegistration";
import HorseList from "../pages/HorseManagement/HorseList";
import EventCalendar from "../SharedComponets/EventScheduler/EventCalendar";
import TrainerList from "../pages/TrainerRegistration/TrainerList";
import FoodPlanerList from "../pages/Planer/FoodPlanerList";
import FoodPlaner from "../pages/Planer/FoodPlaner";
import MedicalPlanerList from "../pages/Planer/MedicalPlanerList";
import MedicalPlaner from "../pages/Planer/MedicalPlaner";
import TrainingPlaner from "../pages/Planer/TrainingPlaner";
import GroomingPlaner from "../pages/Planer/GroomingPlaner";
import TrainingPlanerList from "../pages/Planer/TrainingPlanerList";
import GroomingPlanerList from "../pages/Planer/GroomingPlanerList";
import HealthPlaner from "../pages/Planer/HealthPlaner";
import HealthPlanerList from "../pages/Planer/HealthPlanerList";
import FacilityTypeList from "../pages/AdminSetting/FacilityTypeList";
import ServiceTypeList from "../pages/AdminSetting/ServiceTypeList";
import PlannerList from "../pages/AdminSetting/PlannerList";
import ServiceList from "../pages/AdminSetting/ServiceList";
import FacilityTreeList from "../pages/AdminSetting/FacilityTreeList";
import Breed from "../pages/AdminSetting/Breed";
import Breeder from "../pages/AdminSetting/Breeder";
import StableClass from "../pages/AdminSetting/StableClass";
import CategoryMaster from "../pages/AdminSetting/CategoryMaster";
import DivisionMaster from "../pages/AdminSetting/DivisionMaster";
import GenderMaster from "../pages/AdminSetting/GenderMaster";
import CheckIn from "../pages/Avaibility/CheckIn";
import ColorMaster from "../pages/AdminSetting/ColorMaster";
import VisaMaster from "../pages/AdminSetting/VisaMaster";
import EventType from "../pages/AdminSetting/EventType";
import DocumentTypeMaster from "../pages/AdminSetting/DocumentTypeMaster";
import HealthMaster from "../pages/AdminSetting/HealthMaster";
import TrainingMaster from "../pages/AdminSetting/TrainingMaster";
import Quotation from "../pages/Quotation/Quotation";
import CloseQuotation from "../pages/Quotation/CloseQuotation";
import OwnerList from "../pages/OwnerManagement/OwnerList";
import ForgetPassword from "../pages/LoginRegister/ForgetPassword";
import ForgetPasswordOTP from "../pages/LoginRegister/ForgetPasswordOTP";
import SetNewPassword from "../pages/LoginRegister/SetNewPassword";
import ConfirmedMessage from "../pages/LoginRegister/ConfirmedMessage";
import QuotationList from "../pages/Quotation/QuotationList";
import Cell from "../pages/Stable/Cell";
import Stall from "../pages/Stable/Stall";
import TemperoryOutpass from "../pages/Stable/TemperoryOutpass";
import TemperoryInpass from "../pages/Stable/TemperoryInpass";
import ProtectedRoutes from "./ProtectedRoutes";
import PermanentOutpass from "../pages/Avaibility/PermanentOutpass";
import PageNotFound from "../SharedComponets/PageNotFound";
import Main_Horse_Profile from "../pages/HorseProfile/Main_Horse_Profile";
import ToggleButton from "../pages/HorseProfile/ToggleButton";
import TaxMaster from "../pages/AdminSetting/TaxMaster";
import UserTypeMaster from "../pages/AdminSetting/UserTypeMaster";
import Checkout from "../pages/Avaibility/Checkout";
import CheckInRegistration from "../pages/Avaibility/CheckInRegistration";
import UsersList from "../pages/AdminSetting/User/UsersList";
import ProfileContainer from "../pages/LoginRegister/ProfileContainer";
import UserRegistrationForm from "../pages/AdminSetting/User/UserRegistrations";
import PrintQuotationhtml from "../pages/Quotation/PrintQuotationhtml";
import Settings from "../pages/Settings/Settings";
import OwnerProfile from "../pages/OwnerManagement/OwnerProfile";
import TrainerProfile from "../pages/TrainerRegistration/TrainerProfile";
import QuotationProfile from "../pages/Quotation/QuotationProfile";
import Support from "../SharedComponets/Dashboard/Need/Support";

// Riding School Components @start@

import RidingDetails from "../Ridingschool/pages/RidingDetails";
import Courses from "../Ridingschool/pages/course/Courses";
import SlotList from "../Ridingschool/pages/Slot/SlotList";
import RidingType from "../Ridingschool/pages/RidingType/RidingType";
import StudentRegistration from "../pages/Student/studentRegistration/studentRegistration";
import RTrainerAgreement from "../Ridingschool/pages/TrainerRegistration/TrainerAgreement";
import RTrainerRegistration from "../Ridingschool/pages/TrainerRegistration/TrainerRegistration";
import RTrainerList from "../Ridingschool/pages/TrainerRegistration/TrainerList";
import RTrainerProfile from "../Ridingschool/pages/TrainerRegistration/TrainerProfile";
import StudentLogin from "../pages/Student/studentLogin/StudentLogin";
import StudentProfileEdit from "../Ridingschool/pages/StudentPage/studentDashboard/StudentProfileEdit";
// Riding School Components @end@
import CourseCreate from "../Ridingschool/pages/course/CourseCreate";
import SlotmappingList from "../Ridingschool/pages/SlotMapping/slotmappingList";
import CreateSlotMap from "../Ridingschool/pages/SlotMapping/CreateSlotMap";
// import EventCalendar from "../SharedComponets/EventScheduler/EventCalendar";
import REventCalendar from "../../src/Ridingschool/components/EventScheduler/EventCalendar";
import StudentList from "../pages/Student/StudentList";
import StudentLogin_OTP from "../pages/Student/studentLogin/StudentLogin_OTP";
import { ToastContainer } from "react-toastify";
import StudentDashboard from "../Ridingschool/pages/StudentPage/studentDashboard/StudentDashboard";
import StudentProtectedRoute, {
  ProtectedLoginRoute,
  StudentProtecteAuth,
} from "./StudentProtectedRoute";
import StudentComponent from "../Ridingschool/pages/StudentPage/studentDashboard/studentComponent";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import PaymentThankyouPage from "../Ridingschool/pages/PaymentThankyouPage";
import Course from "../Ridingschool/pages/StudentPage/Course/Course";
import StudentRegistrations from "../pages/LoginRegister/StudentRegistrations";
import CourseLessons from "../Ridingschool/pages/StudentPage/Lessons/CourseLessons";
import FeedBack from "../Ridingschool/pages/Feedback/FeedBack";
import { useSelector } from "react-redux";

import StudentLessionList from "../Ridingschool/pages/StudentPage/Course/StudentLessionList";
import Cart from "../SharedComponets/Cart";
import StudentEvent from "../Ridingschool/pages/StudentPage/StudentEvents/StudentEvent";
import PaymentModal from "../Ridingschool/pages/PaymentModal/PaymentModal";
import PendingBookings from "../Ridingschool/pages/PendingBookings/PendingBookings";

function RoutesComponent() {
  const userdaata = useSelector((state) => state.loginStatus.userData);
  const userData = JSON.parse(localStorage?.getItem("userData"));
  const isStudentLoggedIn = JSON.parse(localStorage?.getItem("isStudent"));
  const adminloggedIn = JSON.parse(localStorage?.getItem("adminloggedIn"));
  const stripePromise = loadStripe(
    "pk_test_51KPW8fAVF8SFwylH71wuY8fvNVwNylYd4B3wkwPn4uXvaLyr5ooZw8WzEjsdZ5cwUMl7pnKfshEfjFFGk9RBqomN00PZzjpN0g"
  );

  return (
    <>
      {/* <Elements stripe={stripePromise}> */}
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          {/* Login  */}
          <Route
            element={
              <ProtectedLoginRoute isAuthenticatedStudent={isStudentLoggedIn} />
            }
          >
            <Route path="/login" element={<Login />} />

            <Route path="/forget_password" element={<ForgetPassword />} />
            <Route
              path="/forget_password/verify_otp"
              element={<ForgetPasswordOTP />}
            />
            <Route
              path="/forget_password/reset_password"
              element={<SetNewPassword />}
            />
            <Route
              path="/forget_password/reset_password/confirmed"
              element={<ConfirmedMessage />}
            />
            <Route path="/register" element={<Register />} />
            {/* <Route
              path="/payment/success/:courseId/:sessionId/:studentId/:total_price/:persons/:slot_id"
              element={<PaymentThankyouPage />}
            />
            <Route
              path="/payment/success/:session_id"
              element={<PaymentThankyouPage />}
            /> */}
            <Route
              path="/student-registrations"
              element={<StudentRegistrations />}
            />
          </Route>
          <Route
            element={
              <StudentProtecteAuth
                isAuthenticatedStudent={isStudentLoggedIn}
                isAdminloggedIn={adminloggedIn}
              />
            }
          >
            <Route path="/student-login" element={<StudentLogin />} />

            <Route path="/student-login-otp" element={<StudentLogin_OTP />} />
          </Route>

          {/* Landing page layout before LogIn */}
          <Route path="/" element={<Header />}>
            <Route
              path="/cart"
              index
              element={
                <Elements stripe={stripePromise}>
                  <Cart />
                </Elements>
              }
            />
            <Route path="/" index element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/disciplines" element={<Disciplines />} />
            <Route path="/disciplines/flat-race" element={<FlatRace />} />
            <Route
              path="/disciplines/arabian-shows"
              element={<ArabianShows />}
            />
            <Route path="/disciplines/show-jumping" element={<ShowJumping />} />
            <Route path="/e-commerce" element={<Ecommerce />} />
            <Route path="/e-commerce/horse-livery" element={<HorseLivery />} />
            <Route path="/e-commerce/stables-rent" element={<StablesRent />} />
            <Route path="/e-commerce/quarantine" element={<Quarantine />} />
            <Route
              path="/e-commerce/transportation"
              element={<Transportation />}
            />
            <Route path="/riding-school" element={<RidingSchool />} />
            <Route
              path="/course-details/:courseId"
              element={
                <Elements stripe={stripePromise}>
                  <RidingDetails />
                </Elements>
              }
            />
            {/* <Route
              path="/course-details/:courseId/payment-confirmation"
              element={<PaymentModal />}
            /> */}
            {!userData && (
              <Route
                path="/student-registration"
                element={<StudentRegistration />}
              />
            )}

            <Route path="/gallery/photo" element={<Photo />} />
            <Route path="/gallery/video" element={<Video />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Route>

          {/* Dashboard Layout After LogIn */}
          {(userData || userdaata) && (
            <Route
              path="/dashboard"
              element={
                <ProtectedRoutes Layout="equi5" Component={DashboardLayout} />
              }
            >
              <Route path="/dashboard" index element={<Dashboard />} />
              <Route
                path="/dashboard/edit_profile/:profile_id"
                element={<ProfileContainer />}
              />
              <Route
                path="/dashboard/events_calendar"
                element={<EventCalendar />}
              />
              <Route
                path="/dashboard/horseagreement"
                element={<HorseAgreement />}
              />
              <Route
                path="/dashboard/horse/registration_form"
                element={<HorseRegistration />}
              />
              <Route
                path="/dashboard/horse/registration_form/:horse_id"
                element={<HorseRegistration />}
              />
              <Route
                path="/dashboard/horse/horse-profile/:hProfileId"
                element={<Main_Horse_Profile />}
              />
              <Route
                path="/dashboard/horse/horse-profile-details"
                element={<ToggleButton />}
              />
              <Route
                path="/dashboard/horseagreement/horses_list"
                element={<HorseList />}
              />
              <Route
                path="/dashboard/owneragreement/registration_form"
                element={<OwnerAgreement />}
              />
              <Route
                path="/dashboard/owner/registration_form"
                element={<OwnerRegistration />}
              />
              <Route
                path="/dashboard/owner/update_form/:id"
                element={<OwnerRegistration />}
              />
              <Route
                path="/dashboard/owneragreement/owners_list"
                element={<OwnerList />}
              />
              <Route
                path="/dashboard/owner/owner-profile/:oProfileId"
                element={<OwnerProfile />}
              />
              <Route
                path="/dashboard/traineragreement/registration_form"
                element={<TrainerAgreement />}
              />
              <Route
                path="/dashboard/trainer/registration_form"
                element={<TrainerRegistration />}
              />
              <Route
                path="/dashboard/trainer/update_form/:id"
                element={<TrainerRegistration />}
              />
              <Route
                path="/dashboard/traineragreement/trainers_list"
                element={<TrainerList />}
              />
              <Route
                path="/dashboard/trainer/trainer-profile/:tProfileId"
                element={<TrainerProfile />}
              />
              <Route
                path="/dashboard/planer/planer_list"
                element={<FoodPlanerList />}
              />
              <Route path="/dashboard/planer/create" element={<FoodPlaner />} />
              <Route
                path="/dashboard/planer/update_planer/:id"
                element={<FoodPlaner />}
              />
              <Route
                path="/dashboard/planer/medical_list"
                element={<MedicalPlanerList />}
              />
              <Route
                path="/dashboard/planer/medical"
                element={<MedicalPlaner />}
              />
              <Route
                path="/dashboard/planer/update_medical/:id"
                element={<MedicalPlaner />}
              />
              <Route
                path="/dashboard/planer/training_list"
                element={<TrainingPlanerList />}
              />
              <Route
                path="/dashboard/planer/training"
                element={<TrainingPlaner />}
              />
              <Route
                path="/dashboard/planer/update_training/:id"
                element={<TrainingPlaner />}
              />
              <Route
                path="/dashboard/planer/grooming"
                element={<GroomingPlaner />}
              />
              <Route
                path="/dashboard/planer/update_grooming/:id"
                element={<GroomingPlaner />}
              />
              <Route
                path="/dashboard/planer/grooming_list"
                element={<GroomingPlanerList />}
              />
              <Route
                path="/dashboard/planer/health"
                element={<HealthPlaner />}
              />
              <Route
                path="/dashboard/planer/update_health/:id"
                element={<HealthPlaner />}
              />
              <Route
                path="/dashboard/planer/health_list"
                element={<HealthPlanerList />}
              />

              <Route path="/dashboard/settings" element={<Settings />} />

              <Route
                path="/dashboard/settings/facility"
                element={<FacilityTypeList />}
              />
              <Route
                path="/dashboard/settings/service-type"
                element={<ServiceTypeList />}
              />
              <Route
                path="/dashboard/settings/planner"
                element={<PlannerList />}
              />
              <Route
                path="/dashboard/settings/services"
                element={<ServiceList />}
              />
              <Route
                path="/dashboard/settings/facility-tree"
                element={<FacilityTreeList />}
              />
              <Route path="/dashboard/settings/tax" element={<TaxMaster />} />
              <Route path="/dashboard/settings/breed" element={<Breed />} />
              <Route path="/dashboard/settings/breeder" element={<Breeder />} />
              <Route path="/dashboard/stable/check_in" element={<CheckIn />} />
              <Route
                path="/dashboard/stable/check_in_registration"
                element={<CheckInRegistration />}
              />
              <Route
                path="/dashboard/stable/check_in_registration/:checkin_id"
                element={<CheckInRegistration />}
              />
              <Route path="/dashboard/stable/livery" element={<Cell />} />
              <Route path="/dashboard/stable/stalls" element={<Stall />} />
              <Route
                path="/dashboard/stable/temporary_outpass"
                element={<TemperoryOutpass />}
              />
              <Route
                path="/dashboard/stable/temporary_inpass"
                element={<TemperoryInpass />}
              />
              <Route path="/dashboard/horses/checkout" element={<Checkout />} />
              <Route
                path="/dashboard/horses/checkout/:checkout_id"
                element={<Checkout />}
              />
              <Route
                path="/dashboard/stable/permanent_outpass"
                element={<PermanentOutpass />}
              />
              <Route
                path="/dashboard/settings/services"
                element={<ServiceList />}
              />
              <Route path="/dashboard/settings/tax" element={<TaxMaster />} />
              <Route path="/dashboard/settings/breed" element={<Breed />} />
              <Route path="/dashboard/settings/breeder" element={<Breeder />} />
              <Route
                path="/dashboard/settings/stable_class"
                element={<StableClass />}
              />
              <Route
                path="/dashboard/settings/category_master"
                element={<CategoryMaster />}
              />
              <Route
                path="/dashboard/settings/division_master"
                element={<DivisionMaster />}
              />
              <Route
                path="/dashboard/settings/horse_gender_master"
                element={<GenderMaster />}
              />
              {/* {userdaata && userdaata.usertype === 'Admin' ? <Route path="/dashboard/user_list" element={<UsersList />} /> : null}
            {userdaata && userdaata.usertype === 'Admin' ? <Route path="/dashboard/user_register" element={<UserRegistrationForm />} /> : null}
  {userdaata && userdaata.usertype === 'Admin' ? <Route path="/dashboard/user_update/:id" element={<UserRegistrationForm />} /> : null} */}
              <Route path="/dashboard/user_list" element={<UsersList />} />
              <Route
                path="/dashboard/user_register"
                element={<UserRegistrationForm />}
              />
              <Route
                path="/dashboard/user_update/:id"
                element={<UserRegistrationForm />}
              />
              <Route
                path="/dashboard/settings/training_master"
                element={<TrainingMaster />}
              />
              <Route
                path="/dashboard/settings/color_master"
                element={<ColorMaster />}
              />
              <Route
                path="/dashboard/settings/visa_master"
                element={<VisaMaster />}
              />
              <Route
                path="/dashboard/settings/event_type"
                element={<EventType />}
              />
              <Route
                path="/dashboard/settings/usertype_master"
                element={<UserTypeMaster />}
              />
              <Route
                path="/dashboard/settings/document_type_master"
                element={<DocumentTypeMaster />}
              />
              <Route
                path="/dashboard/settings/health_master"
                element={<HealthMaster />}
              />
              <Route
                path="/dashboard/quotation/quotation_registration"
                element={<Quotation />}
              />
              <Route
                path="/dashboard/quotation/quotation_registration/:quot_id"
                element={<Quotation />}
              />
              <Route
                path="/dashboard/quotation/close_quotation/:quot_id"
                element={<CloseQuotation />}
              />
              <Route
                path="/dashboard/quotation/quotation_list"
                element={<QuotationList />}
              />
              <Route
                path="/dashboard/quotation/quotation-details/:qId"
                element={<QuotationProfile />}
              />
              <Route
                path="/dashboard/quotation/support"
                element={<Support />}
              />
            </Route>
          )}

          <Route
            path="/riding-club"
            element={
              <ProtectedRoutes
                Layout="ridingclub"
                Component={DashboardLayout}
              />
            }
          >
            <Route
              path="/riding-club"
              element={<Dashboard DashboardName="riding-club" />}
            />
            <Route path="course/course_list" element={<Courses />} />
            <Route path="pending/booking" element={<PendingBookings />} />
            <Route path="course/create_course" element={<CourseCreate />} />
            <Route path="/riding-club/course/:id" element={<Course />} />
            <Route
              path="student-lession-list/:booking_id/:course_id/:student_id"
              element={<StudentLessionList />}
            />
            <Route path="course/create_course/:id" element={<CourseCreate />} />

            <Route path="slot-mapping-list" element={<SlotmappingList />} />
            {userData && (
              <Route
                path="course/student-registration"
                element={<StudentList />}
              />
            )}
            {userData && (
              <Route
                path="course/student-create-registration"
                element={<StudentRegistration />}
              />
            )}
            {userData && (
              <Route
                path="course/student-update-registration/:id"
                element={<StudentRegistration />}
              />
            )}

            <Route path="slot-mapping-create" element={<CreateSlotMap />} />
            <Route path="slot-mapping-update/:id" element={<CreateSlotMap />} />
            <Route path="/riding-club/slot/slot-list" element={<SlotList />} />
            <Route
              path="/riding-club/riding-type-list"
              element={<RidingType />}
            />

            <Route
              path="/riding-club/traineragreement/registration_form"
              element={<RTrainerAgreement />}
            />
            <Route
              path="/riding-club/trainer/registration_form"
              element={<RTrainerRegistration />}
            />
            <Route
              path="/riding-club/trainer/update_form/:id"
              element={<RTrainerRegistration />}
            />
            <Route
              path="/riding-club/traineragreement/trainers_list"
              element={<RTrainerList />}
            />
            <Route
              path="/riding-club/trainer/trainer-profile/:tProfileId"
              element={<RTrainerProfile />}
            />

            <Route path="/riding-club/events" element={<REventCalendar />} />
          </Route>
          <Route
            path="/dashboard/quotation/print-quotation/:qId"
            element={<PrintQuotationhtml />}
          />
          <Route
            path="/payment/success/:courseId/:sessionId/:studentId/:total_price/:persons/:slot_id"
            element={<PaymentThankyouPage />}
          />
          <Route
            path="/payment/success/:session_id"
            element={<PaymentThankyouPage />}
          />
          <Route path="/*" element={<PageNotFound />} />

          <Route
            path="/student-dashboard"
            element={
              <StudentProtectedRoute
                Layout="equi5"
                Component={StudentComponent}
              />
            }
          >
            <Route path="" element={<StudentDashboard />} />
            <Route
              path="/student-dashboard/edit_profile/:id"
              element={<StudentProfileEdit />}
            />
            <Route path="/student-dashboard/course" element={<Course />} />
            <Route
              path="/student-dashboard/events"
              element={<StudentEvent />}
            />

            <Route
              path="/student-dashboard/:booking/:course/:student"
              element={<CourseLessons />}
            />
            <Route path="/student-dashboard/feedback" element={<FeedBack />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* </Elements> */}
    </>
  );
}

export default RoutesComponent;
