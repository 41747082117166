import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "./HorseAgreement.css";
import AOS from "aos";
import "aos/dist/aos.css";
const HorseAgreement = () => {

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>
      <section className="horseAgreeSection" data-aos="flip-down" data-aos-duration="1000">
      
        <header>
          <div className="registration_top_header">
            <p>Horses Registration</p>
            <span>Please fill in all required fields, attach/upload all supporting documents to proceed with the registration.</span>
          </div>
        </header>

        <body>
          <div className="hAgreeBody">
            <div className="hAgreeHead">
              <h4>IMPORTANT PRE-REQUISITE FOR THE REGISTRATION OF HORSE</h4>
              <p>
                Horses must be registered with the National Federation (NF)
                before they can be entered and permitted to take part in an
                equestrian event.
              </p>
            </div>
            <div className="hAgreeUl">
              <ul>
                <li>
                  A horse must be registered with the EQI5 under the specific
                  sport discipline. before it can be entered in
                  National/lnternational equestrian event or a EQI5
                  qualification ride in the UAE.
                </li>
                <li>
                  A horse must have valid approved National or International
                  Passport preferably in English, before it can be registered
                  for the specific sport event.
                </li>
                <li>
                  If a horse is jointly owned by several persons or by a
                  company, one of the joint owners or an authorized
                  person/entity must be entered at the time of registration, to
                  represent all the owners or the company.
                </li>
                <li>
                  When the horse is registered for the sport of Endurance, the
                  name of the trainer for the horse must also be registered and
                  the registration fee paid.
                </li>
                <li>
                  In FEI rides, If the registered trainer of the horse changes,
                  the horse may not participate in any competition for a period
                  of 30 days, starting from the date of notification of the
                  change.
                </li>
                <li>
                  For registering a horse for the sport of endurance, the horse
                  must be 5yrs or older at the time of registration.
                </li>
              </ul>
            </div>
          </div>
        </body>
        
        <footer>
          <div className="hAgreeFoot">
            <Button variant="contained" className="btn-bgColor" sx={{ backgroundColor: "red", borderRadius: "10px", fontFamily: "sans-serif", padding: "8px 15px", textTransform: "capitalize", color: "white", fontWeight: "bolder" }} onClick={() => { navigate("/dashboard/horse/registration_form"); }}>
              I agree, Continue Registration
            </Button>
          </div>
        </footer>

      </section>
    </>
  );
};

export default HorseAgreement;
