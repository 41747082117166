import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../Apis";


export const getHealthMasterData = createAsyncThunk("getHealthMasterData", async (token) => {
    try {
        const response = await api.get("get_all_health", {
            headers: {
                Authorization: `token ${token}`,
            },
        })

        return response.data
    } catch (error) {
        throw error;
    }
})


export const createHealthMasterData = createAsyncThunk("createHealthMasterData", async (payload) => {
    try {
        const response = await api.post("create_health", payload.modalData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        return response.data
    } catch (error) {
        return error.message
    }
})

export const deleteHealthMasterData = createAsyncThunk("deleteHealthMasterData", async (id, token) => {
    try {
        const response = await api.delete(`delete_health/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                }
            }
            
        )
        return response.data
    } catch (error) {
        throw error;
    }
})

export const updateHealthMasterData = createAsyncThunk("updateHealthMasterData", async (payload) => {
    console.log("payload", payload)
    try {
        const response = await api.put(`update_health/${payload.id}`, payload.updatedData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        });
        return response.data;
    } catch (error) {
               throw error;
    }
});
export const getupdateHealthMasterData = createAsyncThunk("getupdateHealthMasterData", async (id, token) => {
    try {
        const response = await api.get(`get_health/${id}`,
            {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        return response.data


    } catch (error) {
        console.log(error)
    }
})
const HealthMasterSlice = createSlice({
    name: "HealthMaster",
    initialState: {
        status: "",
        HealthMasterData: null,
        error: null,
        updateHealthMasterData: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getHealthMasterData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getHealthMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            console.log(action.payload)
            state.HealthMasterData = action.payload
            
        })
        builder.addCase(getHealthMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(deleteHealthMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(deleteHealthMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(deleteHealthMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(createHealthMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(createHealthMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(createHealthMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(updateHealthMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(updateHealthMasterData.fulfilled, (state) => {
            state.status = "succeeded"

        })
        builder.addCase(updateHealthMasterData.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(getupdateHealthMasterData.pending, (state) => {
            state.status = "loading"
        })

        builder.addCase(getupdateHealthMasterData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateHealthMasterData = action.payload

        })
        builder.addCase(getupdateHealthMasterData.rejected, (state) => {
            state.status = "failed"
        })
    }
})

export default HealthMasterSlice.reducer;