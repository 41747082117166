import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { useCookies } from "react-cookie";
import { Modal } from "@mui/material";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  createData,
  deleteData,
  getServiceTypeData,
  updateData,
} from "../../App/Reducers/ServiceTypeReducer";
import { getData } from "../../App/Reducers/FacilityTypeReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import DeleteModal from "../../App/Helpers/DeleteModal";
import api from "../../Apis";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../App/Helpers/AlertMessage";

const ServiceTypeList = () => {


  const dispatch = useDispatch();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] =useState(null)
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addServiceTypePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_servicetype"
      )
    : false;
  const changeServiceTypePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_servicetype"
      )
    : false;
  const deleteServiceTypePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_servicetype"
      )
    : false;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    width: "30rem",
    height: "fit-content",
    overflow: "auto",
    p: 4,
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };

  const serviceData = useSelector((state) => state.ServiceType.serviceTypeData);
  const facilityData = useSelector((state) => state.facilityTypes.facilityData);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    name: null,
    facility: null,
  });

  const openModal = () => {
    dispatch(getData(token));
    setModalData({
      name: null,
      facility: null,
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleAutoComplete = (event, value, fieldName) => {
    console.log(value);
    const selectedValue = value === null ? null : value.id;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValue,
    }));
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createOrUpdateHandler = () => {
    if (modalData.id) {
      if (
        modalData.facility !== null &&
        modalData.facility !== "" &&
        modalData.facility !== "undefined" &&
        modalData.name !== null &&
        modalData.name !== "" &&
        modalData.name !== "undefined"
      ) {
        dispatch(
          updateData({
            id: modalData.id,
            updatedData: {
              name: modalData.name,
              facility: modalData.facility,
            },
            token,
          })
        );
      } else {
        return;
      }
    } else {
   
      if (
        modalData.facility !== null &&
        modalData.facility !== "" &&
        modalData.facility !== "undefined" &&
        modalData.name !== null &&
        modalData.name !== "" &&
        modalData.name !== "undefined"
      ) {
        dispatch(createData({ modalData, token }));
      } else {
        return;
      }
    }
    closeModal();
    dispatch(getServiceTypeData(token));
  };

  const editHandler = (id) => {
    dispatch(getData(token));
    const editData = serviceData.find((data) => data.id === id);
    let { name, facility } = editData;
    let editParseData = {
      id: id,
      name,
      facility: facility.id,
    };

 
    if (editParseData) {
      setModalData(editParseData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id)
    setDeleteModalOpen(true)
  };

  const deleteData = async() =>{
    if(deleteId){
        try {
            const response = await api.delete(`delete_service_type/${deleteId}`, {
                headers:{
                    Authorization :  `token ${token}`
                }
            })
            if(response.status === "200" || response.statusText === "OK"){
                setDeleteModalOpen(false)
                toast.success("Delete successfully")
                dispatch(getServiceTypeData(token))
            }
        } catch (error) {
          if (error.response.data.Error) {
            setIsErrorVisible(true);
            setIsErrorResponse(error.response.data.Error);
          }
  
          setTimeout(() => {
            setIsErrorVisible(false);
          }, 6000);
        }
    }
  }

  useEffect(() => {
    dispatch(getServiceTypeData(token));
  }, [dispatch, token, modalOpen, modalData]);

  const header = [
    {
      Header: "Facility Type",
      accessor: "facility.name",
    },
    {
      Header: "Service Type",
      accessor: "name",
    },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {serviceData ? (
        <BasicTable
          colHeader={header}
          rowData={serviceData}
          actionFlag={
            deleteServiceTypePermission ||
            changeServiceTypePermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeServiceTypePermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteServiceTypePermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addServiceTypePermission || isUserType ? openModal : null
          }
          tableHeading="Service Type"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            deleteServiceTypePermission ||
            changeServiceTypePermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeServiceTypePermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteServiceTypePermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addServiceTypePermission || isUserType ? openModal : null
          }
          tableHeading="Service Type"
        />
      )}

      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll-bar">
          <Button
            sx={{
              color: "#302924",
              border: "0px",
              float: "right",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RxCross2
              style={{ color: "#302924", fontSize: "1.7rem" }}
              onClick={closeModal}
            />
          </Button>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: "#302924",
              marginBottom: "5vh",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            {modalData.id ? "UPDATE SERVICE TYPE" : "CREATE SERVICE TYPE"}
          </Typography>

          <form>
            <Grid xs={12} sm={6} md={4}>
              <Grid item xs={12} sm={6} md={6}>
                <label>FACILITY TYPE</label>
                <Autocomplete
                  name="facility"
                  disablePortal
                  id="combo-box-demo"
                  options={facilityData}
                  getOptionLabel={(option) => option.name}
                  value={
                    facilityData &&
                    modalData.facility &&
                    facilityData.find(
                      (item) => item.id === Number(modalData.facility)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "facility")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder="Select Facility Type"
                    />
                  )}
                  style={{ marginBlock: "5px" }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>SERVICE TYPE</label>
                <TextField
                  type="text"
                  name="name"
                  onChange={handleModalInputChange}
                  value={modalData.name}
                  placeholder="Enter Service Type"
                  fullWidth
                  required
                  style={{ marginBlock: "5px" }}
                />
              </Grid>
            </Grid>

            <Button
              variant="outlined"
              type="submit"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },
                marginTop: "15px",
                float: "right",
                fontSize: "12px",
                backgroundColor: "#302924",
              }}
              onClick={createOrUpdateHandler}
            >
              {modalData.id ? "UPDATE" : "CREATE"}
            </Button>
          </form>
        </Box>
      </Modal>

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Service Type"
        deleteData={deleteData}
      />

      <ToastContainer/>
    </>
  );
};

export default ServiceTypeList;
