import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Apis";
export const AdminLogin = createAsyncThunk(
  "AdminLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(`user_login`, data, {
        headers: {
          "content-type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const USERLOGOUT = createAsyncThunk(
  "USERLOGOUT",
  async (token, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `user_logout`,
        { key: token },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      console.log("LogOut", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const Login = createSlice({
  name: "loginStatus",
  initialState: {
    adminloggedIn: false || JSON.parse(localStorage.getItem("adminloggedIn")),
    status: null,
    islogout: '',
    userData: null || JSON.parse(localStorage.getItem("userData")),
    isauthorized: false || JSON.parse(localStorage.getItem(" isauthorized ")),
    token: null,
    message: null,
    LoginCredentials: null,

  },
  reducers: {
    Toggle(state, action) {
      state.adminloggedIn = !state.adminloggedIn;
    },
    setLogout(state, action) {
      state.adminloggedIn = false
      state.status = action.payload.status
      state.message = action.payload.message
      state.userData = null
      state.islogout = true
      state.isauthorized = false
      state.token = null
      state.LoginCredentials = null

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AdminLogin.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload.Message;

      })
      .addCase(AdminLogin.pending, (state, action) => {
        state.status = "loading";
        state.message = action.payload;
      })
      .addCase(AdminLogin.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action.payload.data;
        state.token = action.payload.token;
        state.userData = action.payload.data;
        const data = JSON.stringify(action.payload.data);
        localStorage.setItem("islogout", false);
        localStorage.setItem("userData", data);
        localStorage.setItem("adminloggedIn", true);
        localStorage.setItem("token", action.payload.token);
      })
      .addCase(USERLOGOUT.fulfilled, (state, action) => {

        localStorage.setItem("adminloggedIn", false);
        localStorage.setItem("islogout", true);
        localStorage.setItem("isauthorized", false);
        localStorage.setItem("userData", null);
        // state.islogout=true
        state.adminloggedIn = false
        state.status = 'success'
        state.islogout = ''
        state.userData = null
        state.isauthorized = false
        state.token = null
        state.message = null
        state.LoginCredentials = null
        state.token = null
      });
  },
});
export const { Toggle, SignIn, setCookie, setLogout } = Login.actions;
export default Login.reducer;
