import React from 'react'
import avtar from '../../../Assets/RidingSchoolAssets/avtar.jpg'
import { ImgUrl } from '../../../Config/Config'


function Avtar({ path }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>{path !== null ? (<img src={`${ImgUrl}${path}`} alt="avtar" />) : <img src={avtar} />}</div>
    )
}

export default Avtar