import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";

const ProtectedRoutes = ({ Component, Layout }) => {
  const [cookie, setCookie] = useCookies();
  const token = cookie.token;
  console.log("layout", Layout);
  const navigate = useNavigate();

  const isloggedIn = JSON.parse(localStorage.getItem("adminloggedIn"));
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : false;

  useEffect(() => {
    if (token === null || !userData) {
      console.log("layout1", Layout);
      navigate("/login");
    }
  }, [navigate, token, userData]);
  console.log("layout2", Layout);
  return (
    <>
      <Component Layout={Layout} />
    </>
  );
};

export default ProtectedRoutes;
