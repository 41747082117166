import React, { useState, useEffect } from "react";
import {
  DeleteEvent,
  GetEvents,
  GetUpdatingEvent,
} from "../../App/Reducers/EventReducer";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { EventFormStatus } from "../../App/Reducers/ModalReducer";
import { useCookies } from "react-cookie";
import DeleteModal from "../../App/Helpers/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Apis";
import AlertMessage from "../../App/Helpers/AlertMessage";

function ListView() {
  const dispatch = useDispatch();
  const Events = useSelector((state) => state.events.EventsList);
  const update = useSelector((state) => state.modal.update);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const header = [
    { Header: "Event Title", accessor: "name" },
    { Header: "Horse Name", accessor: "horse_name.name" },
    { Header: "Trainer Name", accessor: "trainer.name" },
    {
      Header: "Rider Name",
      accessor: "rider",
      Cell: ({ row }) => {
        const first_name = row.original.rider?.first_name
          ? row.original.rider?.first_name
          : "";
        const last_name = row.original.rider?.last_name
          ? row.original.rider?.last_name
          : "";

        return `${first_name} ${last_name}`;
      },
    },
    { Header: "Others", accessor: "others" },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: ({ value }) => {
        const date = new Date(value);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          // second: '2-digit',
          hour12: true,
          timeZone: "Etc/GMT",
        }).format(date);
        return formattedDate;
      },
    },

    {
      Header: "End Date",
      accessor: "end_date",
      Cell: ({ value }) => {
        const date = new Date(value);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          // second: '2-digit',
          hour12: true,
          timeZone: "Etc/GMT",
        }).format(date);
        return formattedDate;
      },
    },
  ];

  const handleUpdate = (id) => {
    dispatch(EventFormStatus({ open: true, update: !update }));
    dispatch(GetUpdatingEvent({ id, token }));
    dispatch(GetEvents(token));
    dispatch(GetEvents(token));
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_event/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(GetEvents(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  const handleCreate = () => {
    dispatch(EventFormStatus({ open: true, update: update }));
  };
  useEffect(() => {
    dispatch(GetEvents(token));
  }, [dispatch, Events.hasOwnProperty()]);

  return (
    <div>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {Events ? (
        <BasicTable
          colHeader={header}
          rowData={Events}
          updateHandler={handleUpdate}
          deleteHandler={deleteHandler}
          createHandler={handleCreate}
          tableHeading="Events"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          updateHandler={handleUpdate}
          deleteHandler={deleteHandler}
          createHandler={handleCreate}
          tableHeading="Events"
        />
      )}
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Event"
        deleteData={deleteData}
      />
      <ToastContainer />
    </div>
  );
}

export default ListView;
