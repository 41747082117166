import Api from "../ApiConfig";

export default {
    get_all_students: (id) => Api.get(`get_company_students/${id}`),
    getstudentById: (id) => Api.get(`get_student/${id}`),
    all_horse: () => Api.get('get_all_horse'),
    updateStudent: (payload, id) => Api.put(`update_student/${id}`, payload, {
        headers: {
            'content-type': 'multipart/form-data',
        }
    })

}