import Api from "../ApiConfig";
import { companyId } from "../../RidingSchoolReuseIds/Ids";

export default {
  assignable_slot_map: (id) => Api.get(`assignable_slots_map/${id}`),
  all_slot_map: (id) => Api.get(`get_all_slotmapping/${companyId}`),
  get_slot_map: (id) => Api.get(`get_slotmapping/${id}`),
  create_slot_map: (payload) =>
    Api.post(`create_slotmapping`, { ...payload, company: companyId }),

  update_slot_map: (id, payload) =>
    Api.put(`update_slotmapping/${id}`, { ...payload, company: companyId }),
  delete_slot_map: (id) => Api.delete(`delete_slotmapping/${id}`),
  get_all_trainer: (id) => Api.get(`get_all_riding_trainer`),
  get_all_trainer_public: (id) =>
    Api.get(`get_all_riding_trainer_public/${id}`),
};
