import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseURL } from '../../Apis';
import api from "../../Apis";
export const GetTrainingPlanerList = createAsyncThunk('GetTrainingPlanerList', async (token) => {
  try {
    const response = await api.get(`get_all_training_planer` , {headers : {Authorization : `token ${token}`}});
    console.log(response, "api/get_all_food_planer")
    return response.data

  } catch (error) {
    console.log(error)
  }

})
export const CreateTrainingPlaner = createAsyncThunk('CreateTrainingPlaner', async ({formData , token} , { rejectWithValue }) => {
    try {
        const response = await api.post(`create_training_planer`,
        formData , 
        {headers:{
            "Content-Type": "application/json",
            Authorization : `token ${token}`
          }});
        console.log(response, "/api/create_food_planer")
        return response.data
  
      } catch (error) {
        return rejectWithValue(error.response.data)
      }
  
  })
  export const GetUpdatingTrainingPlaner = createAsyncThunk('GetUpdatingTrainingPlaner', async ({id , token}, { rejectWithValue }) => {
    try {
      const response = await api.get(`get_training_planer/${id}` ,  {headers : {Authorization : `token ${token}`}});
      console.log(response, "api/get_food_planer/id")
      return response.data
  
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
  
  })

  export const UpdateTrainingPlaner = createAsyncThunk('UpdateTrainingPlaner', async ({trainingData , token}) => {
    console.log('sdbsjdhsjdhjsdh',trainingData);
    try {
      const response = await api.put(`update_training_planer/${trainingData.id}`,
      trainingData,
      {headers:{
          "Content-Type": "application/json",
          Authorization : `token ${token}`
        }});
      console.log(response, "sahjfgdeudjgfvbdrvjfdk")
      return response.data

    } catch (error) {
      return console.log(error)
    }  
  
  })
  export const DeleteTrainingPlaner = createAsyncThunk('DeleteTrainingPlaner', async ({id , token} , { rejectWithValue }) => {
    try {
        const response = await api.delete(`delete_medical_planer/${id}` ,  {headers : {Authorization : `token ${token}`}})      
        console.log(response, "Delete")
        return response.data
  
      } catch (error) {
        console.log(error)
         return rejectWithValue(error.response.data)
      }  
  })
const initialState = {
  List: null ,
  UpdatingPlaner : null,
  status :{
    Get :"",
    Create:"",
    Updating:"",
    Update:"",
    Delete:""
  },
  errors :{
    Get:"",
    Create:"",
    Update:"",
    Delete:"",

  }
};

const TrainingPlanerSlice = createSlice({
  name: "TrainingPlaner",
  initialState,
  reducers: {
    Update: (state, action) => { },
  },
  extraReducers : (builder) =>{
    builder
    .addCase(GetTrainingPlanerList.pending , (state)=>{
        state.status.Get = "loading"
    })
    .addCase(GetTrainingPlanerList.fulfilled , (state , action)=>{
        state.status.Get = "succeeded"
        state.List = action.payload
        console.log(action.payload , "success")
    })
    .addCase(GetTrainingPlanerList.rejected , (state , action)=>{
        state.status.Get = "failed"
        console.log("failed")
       
    })
    .addCase(CreateTrainingPlaner.pending , (state)=>{
        state.status.Create = "loading"
    })
    .addCase(CreateTrainingPlaner.fulfilled , (state , action)=>{
        state.status.Create = "succeeded"
        console.log(action.payload , "succeeded")
    })
    .addCase(CreateTrainingPlaner.rejected , (state , action)=>{
        state.status.Create = "failed"
        console.log("failed")  
    })
    .addCase(GetUpdatingTrainingPlaner.pending , (state)=>{
        state.status.Updating = "loading"
    })
    .addCase(GetUpdatingTrainingPlaner.fulfilled , (state , action)=>{
        state.status.Updating = "succeeded"
        state.UpdatingPlaner  = action.payload
        console.log(action.payload , "succeeded")
        console.log(state.UpdatingPlaner  , "updating")
    })
    .addCase(GetUpdatingTrainingPlaner.rejected , (state , action)=>{
        state.status.Updating = "failed"
        console.log("failed")  
    })
    .addCase(UpdateTrainingPlaner.pending , (state)=>{
        state.status.Update = "loading"
    })
    .addCase(UpdateTrainingPlaner.fulfilled , (state , action)=>{
        state.status.Update = "succeeded"
        console.log(action.payload , "succeeded")

    })
    .addCase(UpdateTrainingPlaner.rejected , (state , action)=>{
        state.status.Update = "failed"
        console.log("failed")  
      
    })
}
})


export const { } = TrainingPlanerSlice.actions;
export default TrainingPlanerSlice.reducer;
