import React, { useEffect, useState } from "react";
import "./Planer.css";
import {
  TextField,
  Button,
  Grid,
  Autocomplete,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateGroomingPlaner,
  UpdateGroomingPlaner,
} from "../../App/Reducers/GroomingPlanerReducer";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getHorseData } from "../../App/Reducers/HorseRegistrationReducer";
// import TextareaAutosize from '@mui/base/TextareaAutosize';
import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import api from "../../Apis";
import { getAllStaff } from "../../App/Reducers/Avaiblity Slices/CheckinSlice";
import { GetUsers } from "../../App/Reducers/UserReducer";
const GroomingPlaner = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const State = useSelector((state) => state.GroomingPlaner);
  const Data = useSelector((state) => state.GroomingPlaner.UpdatingPlaner);
  const Horses_list = useSelector((state) => state.horseRegistration.data);
  const users = useSelector((state) => state.getUsers.users);
  const [status, setStatus] = useState(State.status.Create);
  const [staffs, setStaffs] = useState(null);
  const [groomingData, setGroomingData] = useState({
    horse_id:"",
    grooming: "",
    special_treatment: "",
    staff: "",
    other_staff: "",
    price: "",
    discription: "",
    start_date: null,
    end_date: null,
  });
  console.log("GroomingPlaner", groomingData);
  const EventHandler = (e) => {
    const { name, value } = e.target;
    setGroomingData({ ...groomingData, [name]: value });
  };
  const EventAutoCompleteHandler = (e, value, fieldName) => {
    setGroomingData({ ...groomingData, [fieldName]: value });
  };
  const CreateHandler = () => {
    dispatch(CreateGroomingPlaner({ groomingData, token }));
    if (status === "succeeded") {
      navigate("/dashboard/planer/food_list");
    }
  };
  const UpdateHandler = () => {
    dispatch(UpdateGroomingPlaner({ groomingData, token }));
  };
  useEffect(() =>{
    dispatch(getHorseData(token));
    dispatch(GetUsers(token))

},[token])
  useEffect(() => {
    setStaffs(users)
    setStatus(State.status.Create);
    if (Data) {
      setGroomingData({
        id: Data.id,
        horse_id: String(Data.horse_id.id),
        grooming: Data.grooming,
        special_treatment: Data.special_treatment,
        staff: String(Data.staff.id),
        other_staff: Data.other_staff,
        price: String(Data.price),
        discription: Data.discription,
        start_date: Data.start_date
          ? new Date(Data.start_date).toISOString().slice(0, -1)
          : "",
        end_date: Data.end_date
          ? new Date(Data.end_date).toISOString().slice(0, -1)
          : "",
      });
    }
  }, [State.status.Create, Data , users]);
  return (
    <div className="form-wrapper-container">

    <div className="form-container">
      <h2 className="planer-header"> Grooming Planer Of Horse</h2>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <label>Horse Name</label>
            <Autocomplete
              id="combo-box-demo"
              name="horse_id"
              value={
                Horses_list &&
                groomingData.horse_id &&
                Horses_list.find(
                  (item) => item.id === Number(groomingData.horse_id)
                )
              }
              onChange={(event, value) =>
                EventAutoCompleteHandler(event, value, "horse_id")
              }
              required
              fullWidth
              options={Horses_list && Horses_list}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField className="bg-color" {...params} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Grooming</label>
            <TextField
              type="text"
              name="grooming"
              onChange={EventHandler}
              value={groomingData.grooming}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Special Treatment</label>
            <TextField
              type="text"
              name="special_treatment"
              onChange={EventHandler}
              value={groomingData.special_treatment}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
              <label>Staff</label>
              
              <Autocomplete
                name="staff"
                disablePortal
                id="combo-box-demo"
                value={
                  staffs &&
                  groomingData.staff &&
                  staffs.find((item) => item.id === Number(groomingData.staff))
                }
                onChange={(event, value) =>
                  EventAutoCompleteHandler(event, value, "staff")
                }
                required
                fullWidth
                options={staffs && staffs}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                renderInput={(params) => (
                  <TextField className="bg-color" {...params} />
                )}
              />      
            </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Others Staff</label>
            <TextField
              type="text"
              name="other_staff"
              value={groomingData.other_staff}
              onChange={EventHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Price</label>
            <TextField
              type="text"
              name="price"
              value={groomingData.price}
              onChange={EventHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <label>Description</label>
            <TextareaAutosize
              rows={4}
              placeholder="Empty"
              value={groomingData.discription}
              name="discription"
              onChange={EventHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>Start Date</label>
            <TextField
              type="datetime-local"
              name="start_date"
              onChange={EventHandler}
              value={groomingData.start_date}
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <label>End Date</label>
            <TextField
              type="datetime-local"
              name="end_date"
              onChange={EventHandler}
              value={groomingData.end_date}
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        {!Data ? (
          <Button
            variant="outlined"
            sx={{
              color: "#fff",
              borderColor: "#302924",
              "&:hover": {
                borderColor: "#302924",
                color: "#302924",
              },

              marginTop: "2rem",
              fontSize: "12px",
              marginInline: "0.5rem",
              backgroundColor: "#302924",
            }}
            onClick={CreateHandler}
          >
            CREATE
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{
              color: "#fff",
              borderColor: "#302924",
              "&:hover": {
                borderColor: "#302924",
                color: "#302924",
              },

              marginInline: "0.5rem",
              marginTop: "2rem",
              fontSize: "12px",
              backgroundColor: "#302924",
            }}
            onClick={UpdateHandler}
          >
            UPDATE
          </Button>
        )}
      </form>
    </div>
    </div>
  );
};

export default GroomingPlaner;
