import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuotationData,
  getUpdateQuotationData,
  ConfirmQuotationData,
  RejectQuotationData,
  deleteQuotationData
} from "../../App/Reducers/QuotationReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { Link, useNavigate, useParams } from "react-router-dom";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import { useCookies } from "react-cookie";
import DropdownMenu from "../../SharedComponets/Components/DropdownMenu";
import { Badge, Divider, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ArchiveIcon from "@mui/icons-material/Archive";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import numeral from "numeral";
import DeleteIcon from "@mui/icons-material/Delete";
import PaymentIcon from '@mui/icons-material/Payment';
import DeleteModal from '../../App/Helpers/DeleteModal'
import api from "../../Apis";
import AlertMessage from "../../App/Helpers/AlertMessage";

const QuotationList = () => {

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const [quotationReg, setQuotationReg] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quotationData = useSelector((state) => state.quotation.quotationData);
  const updateQuotationData = useSelector(
    (state) => state.quotation.updateQuotationData
  );
  const status = useSelector((state) => state.quotation.status);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const NumberFormater = (amount) => {
    return numeral(amount).format("0,0");
  };
  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addReservationPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
      (obj) => obj.codename === "add_reservation"
    )
    : false;
  const changeReservationPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
      (obj) => obj.codename === "change_reservation"
    )
    : false;
  const deleteReservationPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
      (obj) => obj.codename === "delete_reservation"
    )
    : false;

  const profileViewer = (id) => {
    navigate(`/dashboard/quotation/quotation-details/${id}`);
  };

  const header = [
    {
      Header: "Id",
      accessor: "code",
    },
    {
      Header: "Customer Name",
      accessor: "name",
      Cell: ({ row }) => (
        <span
          onClick={() => profileViewer(row.original.id)}
          style={{ cursor: "pointer", color: "#7c5e1e", fontWeight: "bold" }}
          title="View profile"
        >
          {row.original.name}
        </span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Mobile Number",
      accessor: "phone",
      Cell : ({row}) =>(
        <span>{`+${row.original.phone}`}</span>
      )
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Country",
      accessor: "country.name",
    },
    {
      Header: "Total Amount",
      accessor: "total_net_amount",
      style: { textAlign: "right" },
      Cell: ({ value }) => NumberFormater(value),
    },
    {
      Header: "Status",
      accessor: "status",
      style: { width: "80px" },
      Cell: ({ row }) => (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Badge
            style={{ position: "absolute" }}
            color={
              row.original.status === "Confirmed"
                ? "success"
                : row.original.status === "Pending"
                  ? "warning"
                  : "error"
            }
            badgeContent={row.original.status}
          />
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        return (
          <>
            <DropdownMenu
              menus={
                userData.usertype?.name === "Admin" ? (
                  <>
                    {/* <MenuItem onClick={() => handlePrint(row.original.id)} */}
                    <MenuItem disableRipple>
                      <PrintIcon />
                      <Link
                        to={`/dashboard/quotation/print-quotation/${row.original.id}`}
                        target="_blank"
                      >
                        Print
                      </Link>
                      {/* <button style={{ border: "none", background: "transparent", cursor: "pointer" }}>Print</button> */}
                    </MenuItem>

                    <MenuItem
                      onClick={() => updateHandler(row.original.id)}
                      disableRipple
                    >
                      <EditIcon />
                      Edit
                    </MenuItem>

                    {row.original.status !== "Rejected" && (
                      <MenuItem
                        onClick={() => RejectHandler(row.original.id)}
                        disableRipple
                      >
                        <CancelIcon />
                        <span>Reject</span>
                      </MenuItem>
                    )}
                    {row.original.status !== "Confirmed" && (
                      <MenuItem
                        onClick={() =>
                          ConfirmHandler(row.original.id, row.original.status)
                        }
                        disableRipple
                      >
                        <CheckIcon sx={{ fontSize: "40px" }} />
                        <span>Confirm</span>
                      </MenuItem>
                    )}
                    
                    <MenuItem
                      onClick={() => deleteDataBTN(row.original.id)}
                    >
                      <DeleteIcon sx={{ fontSize: "40px" }} />
                      <span>Delete</span>
                    </MenuItem>

                    <MenuItem
                      onClick={() => CloseReservation(row.original.id)}
                      disableRipple
                    >
                      <PaymentIcon sx={{ fontSize: "40px" }} />
                      <span>Close Reservation</span>
                    </MenuItem>

                  </>
                ) : (
                  <>
                    {/* <MenuItem onClick={() => handlePrint(row.original.id)} */}
                    <MenuItem disableRipple>
                      <PrintIcon />
                      <Link
                        to={`/dashboard/quotation/print-quotation/${row.original.id}`}
                        target="_blank"
                      >
                        Print
                      </Link>
                      {/* <button style={{ border: "none", background: "transparent", cursor: "pointer" }}>Print</button> */}
                    </MenuItem>
                    {row.original.status !== "Confirmed" &&
                      row.original.status !== "Rejected" &&
                      changeReservationPermission && (
                        <MenuItem
                          onClick={() => updateHandler(row.original.id)}
                          disableRipple
                        >
                          <EditIcon />
                          Edit
                        </MenuItem>
                      )}

                    {row.original.status !== "Confirmed" &&
                      row.original.status !== "Rejected" &&
                      changeReservationPermission && (
                        <MenuItem
                          onClick={() => RejectHandler(row.original.id)}
                          disableRipple
                        >
                          <CancelIcon />
                          <span>Reject</span>
                        </MenuItem>
                      )}
                    {row.original.status !== "Confirmed" &&
                      row.original.status !== "Rejected" &&
                      changeReservationPermission && (
                        <MenuItem
                          onClick={() =>
                            ConfirmHandler(row.original.id, row.original.status)
                          }
                          disableRipple
                        >
                          <CheckIcon sx={{ fontSize: "40px" }} />
                          <span>Confirm</span>
                        </MenuItem>
                      )}
                    {row.original.status !== "Confirmed" &&
                      row.original.status !== "Rejected" &&
                      deleteReservationPermission && (
                        <MenuItem
                          onClick={() => deleteDataBTN(row.original.id)}
                          disableRipple
                        >
                          <DeleteIcon sx={{ fontSize: "40px" }} />
                          <span>Delete</span>
                        </MenuItem>
                      )}
                    {row.original.status !== "Confirmed" &&
                      row.original.status !== "Rejected" &&
                      deleteReservationPermission && (
                        <MenuItem
                          onClick={() => CloseReservation(row.original.id)}
                          disableRipple
                        >
                          <PaymentIcon sx={{ fontSize: "40px" }} />
                          <span>Close Reservation</span>
                        </MenuItem>
                      )}
                  </>
                )
              }
            />
          </>
        );
      },
    },
  ];

  // (userData.usertype === "Staff" || userData.usertype === "Manager")
  const createHandler = () => {
    setQuotationReg(false);
    navigate("/dashboard/quotation/quotation_registration");
  };

  const updateHandler = async (id) => {
    dispatch(getUpdateQuotationData({ id, token }));
    navigate(`/dashboard/quotation/quotation_registration/${id}`);
    setQuotationReg(true);
  };

  const RejectHandler = async (id) => {
    dispatch(RejectQuotationData({ id, token }));
    dispatch(getQuotationData(token));
    dispatch(getQuotationData(token));
  };

  const deleteDataBTN = (id) => {
    if (id) {
      setDeleteId(id);
      setDeleteModalOpen(true)
    }
  }
  const CloseReservation = (id) => {
    if (id) {
      // alert(`you sure to close Reservation:${id}`)
      navigate(`/dashboard/quotation/close_quotation/${id}`)
    }
  }

  const deleteData = async () => {
    if (deleteId) {
      try {
        const response = await api.delete(`delete_quotation/${deleteId}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.status === "200" || response.statusText === "OK") {
          setDeleteModalOpen(false);
          toast.success("Delete successfully");
          dispatch(getQuotationData(token));
        }
      } catch (error) {
        if (error.response.data.Error) {
          setIsErrorVisible(true);
          setIsErrorResponse(error.response.data.Error);
        }

        setTimeout(() => {
          setIsErrorVisible(false);
        }, 6000);
      }
    }
  };

  const ConfirmHandler = async (id, status) => {
    dispatch(ConfirmQuotationData({ id, token }));
    dispatch(getQuotationData(token));
    dispatch(getQuotationData(token));
  };

  // const handlePrint = useReactToPrint({ content: () => printComponentRef.current });
  const handlePrint = (id) => {
    dispatch(getUpdateQuotationData({ id, token }));
    navigate("/dashboard/quotation/print-quotation");
  };

  const profileUpdation = () => { };

  useEffect(() => {
    dispatch(getQuotationData(token));
  }, [dispatch, token]);

  useEffect(() => {
    // Check if updateQuotationData has been updated and status is "succeeded"
    if (status === "succeeded" && updateQuotationData) {
      quotationReg && navigate("/dashboard/quotation/quotation_registration");
    }
  }, [status, updateQuotationData, navigate]);

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      <ToastContainer />

      {quotationData ? (
        <BasicTable
          actionFlag={true}
          colHeader={header}
          rowData={quotationData}
          updateHandler={updateHandler}
          profileUpdation={profileUpdation}
          createHandler={
            addReservationPermission || isUserType ? createHandler : null
          }
          tableHeading="Reservation"
        />
      ) : (
        <BasicTable
          actionFlag={true}
          colHeader={header}
          rowData={[]}
          updateHandler={updateHandler}
          profileUpdation={profileUpdation}
          createHandler={
            addReservationPermission || isUserType ? createHandler : null
          }
          tableHeading="Reservation"
        />
      )}

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Reservation"
        deleteData={deleteData}
      />

    </>
  );
};

export default QuotationList;
