import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Apis";
export const getQuotationData = createAsyncThunk("getQuotationData", async (token) => {
    try {
        const res = await api.get("get_all_quotation", {
            headers: {
                Authorization: `token ${token}`,
            },
        })
        return res.data
    } catch (error) {
        console.log(error)
    }
})

export const getAllConfirmQuotation = createAsyncThunk("getAllConfirmQuotation", async (token) => {
    try {
        const res = await api.get("all_confirm_quotation", {
            headers: {
                Authorization: `token ${token}`,
            },
        })
        return res.data
    } catch (error) {
        console.log(error)
    }
})
export const createQuotationData = createAsyncThunk("createQuotationData", async (payload, { rejectWithValue }) => {
    try {
        const response = await api.post("create_quotation", payload.formData, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `token ${payload.token}`,
            }
        })
        console.log("response", response.status);
        return { data: response.data, status: response.status, statusText: response.statusText }
    } catch (error) {
        console.log("error.response.data", error.response.data);
        return rejectWithValue(error.response.data)
    }
})
export const updateQuotationData = createAsyncThunk("updateQuotationData", async (payload) => {
    try {
        const res = await api.put(`update_quotation/${payload.id}`, payload.formData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        })
        return res.data
    } catch (error) {
        console.log("error", error)
        return error
    }
})

export const ConfirmQuotationData = createAsyncThunk("ConfirmQuotationData", async (payload) => {
    try {
        const res = await api.put(`confirm_quotation/${payload.id}`, payload.formData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        })
        return res.data
    } catch (error) {
        console.log("error", error)
        return error
    }
})

export const RejectQuotationData = createAsyncThunk("RejectQuotationData", async (payload) => {
    try {
        const res = await api.put(`reject_quotation/${payload.id}`, payload.formData, {
            headers: {
                "Content-Type": "multipart/form-data, application/json",
                Authorization: `token ${payload.token}`,
            },
        })
        return res.data
    } catch (error) {
        console.log("error", error)
        return error
    }
})

export const getUpdateQuotationData = createAsyncThunk("getUpdateQuotationData", async (payload) => {
    try {
        const res = await api.get(`get_quotation/${payload.id}`, { headers: { Authorization: `token ${payload.token}` } })
        console.log("res.data", res)
        return res.data
    } catch (error) {
        console.log("error", error)
        return error
    }
})
export const deleteQuotationData = createAsyncThunk("deleteQuotationData", async (payload) => {
    try {
        const res = await api.delete(`delete_quotation/${payload.id}`, { headers: { Authorization: `token ${payload.token}` } })
        console.log("res.data", res)
        return res.data
    } catch (error) {
        console.log("error", error)
        return error
    }
})


const quotationSlice = createSlice({
    name: "quotation",
    initialState: {
        status: "",
        resStatus: {},
        message: null,
        quotationData: null,
        updateQuotationData: null,
        confirmQuotation: null,
    },
    reducers: {
        setUpdateQuotationData: (state, action) => {
            state.updateQuotationData = action.payload
        },
        setMessage: (state, action) => {
            state.message = action.payload
        },
        getMessage: (state, action) => {
            state.message = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        getStatus: (state, action) => {
            state.status = action.payload
        },
        setResStatus: (state, action) => {
            state.resStatus = action.payload
        },
        getResStatus: (state, action) => {
            state.status = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getQuotationData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getQuotationData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.quotationData = action.payload
        })
        builder.addCase(getQuotationData.rejected, (state) => {
            state.status = "error"
        })
        builder.addCase(createQuotationData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(createQuotationData.fulfilled, (state, action) => {
            state.status = "succeeded"
            console.log("action.payload", action.payload);
            state.resStatus.status = action.payload.status
            state.resStatus.statusText = action.payload.statusText
        })
        builder.addCase(createQuotationData.rejected, (state, action) => {
            state.status = "error"
            state.message = action.payload
        })
        builder.addCase(updateQuotationData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(updateQuotationData.fulfilled, (state, action) => {
            state.status = "succeeded"
        })
        builder.addCase(updateQuotationData.rejected, (state) => {
            state.status = "error"
        })
        builder.addCase(ConfirmQuotationData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(ConfirmQuotationData.fulfilled, (state, action) => {
            state.status = "succeeded"
        })
        builder.addCase(ConfirmQuotationData.rejected, (state) => {
            state.status = "error"
        })
        builder.addCase(RejectQuotationData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(RejectQuotationData.fulfilled, (state, action) => {
            state.status = "succeeded"
        })
        builder.addCase(RejectQuotationData.rejected, (state) => {
            state.status = "error"
        })
        builder.addCase(getUpdateQuotationData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getUpdateQuotationData.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.updateQuotationData = action.payload
            console.log(action.payload, "updating")
        })
        builder.addCase(getUpdateQuotationData.rejected, (state) => {
            state.status = "error"
        })
        builder.addCase(deleteQuotationData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(deleteQuotationData.fulfilled, (state, action) => {
            state.status = "succeeded"
        })
        builder.addCase(deleteQuotationData.rejected, (state) => {
            state.status = "error"
        })

        builder.addCase(getAllConfirmQuotation.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getAllConfirmQuotation.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.confirmQuotation = action.payload
        })
        builder.addCase(getAllConfirmQuotation.rejected, (state) => {
            state.status = "error"
        })

    }
})

export const { setUpdateQuotationData, setMessage, getMessage, setStatus, getStatus, setResStatus, getResStatus } = quotationSlice.actions;
export default quotationSlice.reducer;