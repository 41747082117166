import {
  Checkbox,
  TextField,
  Button,
  Grid,
  TextareaAutosize,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getVisaMasterData } from "../../App/Reducers/AdminSettingReducer/VisaMasterReducer";
import { getDivisionMasterData } from "../../App/Reducers/AdminSettingReducer/DivisionMasterReducer";
import {
  getCountries,
  getISDcode,
} from "../../App/Reducers/AdminSettingReducer/CountryReducer";
import { getDocumentTypeMasterData } from "../../App/Reducers/AdminSettingReducer/DocumentTypeMasterReducer";
import {
  createOwnerData,
  updateOwnerData,
} from "../../App/Reducers/OwnerReducer";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import { ImgUrl } from "../../Config/Config";
import api from "../../Apis";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./OwenerRegistration.css";
import CommonLoading from "../../SharedComponets/Loading/CommonLoading";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import DeleteModal from "../../App/Helpers/DeleteModal";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const OwnerRegistration = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const genders = ["Male", "Female", "Other"];

  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fData = new FormData();

  //data formate
  const today = new Date().toISOString().split("T")[0];

  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
  };

  const visa_category = useSelector((state) => state.VisaMaster.VisaMasterData);
  const division = useSelector(
    (state) => state.divisionMaster.DivisionMasterData
  );
  const country = useSelector((state) => state.countries.countriesData);
  // const isd_code = useSelector((state) => state.countries.isd_code);
  const docType = useSelector(
    (state) => state.DocumentTypeMaster.DocumentTypeMasterData
  );

  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedOwner, setUpdatedOwner] = useState(null);
  const [postBoxError, setPostBoxError] = useState(false);
  const [bloodGroupError, setBloodGroupError] = useState(false);
  const [attachDoc, setAttachDoc] = useState(null);

  const [formData, setFormData] = useState({
    national_id: null,
    visa_category: null,
    divison: null,
    blood_group: null,
    name: null,
    gender: null,
    date_of_birth: null,
    nationality: null,
    city: null,
    address: null,
    post_box: null,
    country_id: null,
    email: null,
    // isd_code: null,
    mobile: null,
    remark: null,
    files: [
      {
        // id: 1,
        // document_type: null,
        // issue_date: null,
        // expiry_date: null,
        // uploaded_file: null,
      },
    ],
    files1: [],
    checkedvalue: [],
  });

  // Handler for input changes
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "checkedvalue") {
      // If it's a checkedvalue checkbox
      if (checked) {
        // Add the value to the checkedvalue array
        setFormData((previous) => ({
          ...previous,
          checkedvalue: [...previous.checkedvalue, value],
        }));
      }
    } else {
      // For other input fields, update the corresponding value in the formData state
      if (name === "post_box") {
        // Validate that "post_box" only contains numbers
        if (/^[0-9]*$/.test(value) || value === "") {
          setPostBoxError(false); // No error if input is valid
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        } else {
          setPostBoxError(true); // Set error if input is invalid
        }
      } else if (name === "blood_group") {
        // Validate blood_group to allow only 'a' to 'z', 'A' to 'Z', '+', and '-'
        if (/^[A-Za-z\s\+\-]*$/.test(value) || value === "") {
          setBloodGroupError(false); // No error if input is valid
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        } else {
          setBloodGroupError(true); // Set error if input is invalid
        }
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    }
  };

  // Handler for Autocomplete changes
  const handleAutoComplete = (event, newValue, fieldName) => {
    const selectedValue = newValue ? newValue.id : null;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValue,
    }));
  };

  const handleAutoCompleteStatic = (event, newValue, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const handleACDocTypeFields = (event, value, field, index) => {
    const updatedFiles = [...formData.files];
    const updatedFile = { ...updatedFiles[index] }; // Create a copy of the object
    updatedFile[field] = value ? value.id : null; // Update the desired property
    // updatedFile['doc_name']=docType.find((item) => item.id === value.id).name
    updatedFiles[index] = updatedFile; // Replace the object in the array
    setFormData({ ...formData, files: updatedFiles });
  };

  const handleACDocFields = (event, newValue, fieldName) => {
    const actualVal =
      event.target.value === "0" ? newValue : event.target.value;
    setFormData((prevState) => {
      const updatedDocuments = [...prevState.files];
      updatedDocuments[updatedDocuments.length - 1] = {
        ...updatedDocuments[updatedDocuments.length - 1],
        [fieldName]: actualVal,
      };

      return {
        ...prevState,
        files: updatedDocuments,
      };
    });
  };

  const docUpload = (event, index) => {
    const file = event.target.files[0];
    const { name, value, type } = event.target;

    setFormData((prevState) => {
      const updatedDocuments = [...prevState.files];
      updatedDocuments[index] = {
        ...updatedDocuments[index],
        [name]: value,
        uploaded_file: file,
      };

      if (type === "file" && name === "uploaded_file") {
        // If it's a file input
        if (event.target.files.length > 0) {
          const file = event.target.files[0];
          const fileSizeLimit = 1 * 1024 * 1024; // 1 MB in bytes
          const fileType = file.type;

          // Check if the file is a PDF
          if (fileType === "application/pdf") {
            // Check if the file size is within the limit
            if (file.size <= fileSizeLimit) {
              // Update the files array in the formData state
              setFormData((previous) => ({
                ...previous,
                files: updatedDocuments,
              }));
              setAttachDoc("");
            } else {
              // Handle validation error (e.g., display an error message)
              setAttachDoc("PDF file size exceeds 1 MB limit");
            }
          } else {
            // Handle validation error (e.g., display an error message)
            event.target.value = null;
            setAttachDoc("Please select a PDF file");
          }
        }
      }

      return {
        ...prevState,
        files: updatedDocuments,
      };
    });
  };

  const docRender = () => {
    setFormData((prevState) => ({
      ...prevState,
      files: [
        ...prevState.files,
        {
          // id: prevState.files.length + 1,
          // document_type: null,
          // issue_date: null,
          // expiry_date: null,
          // uploaded_file: null,
        },
      ],
    }));
  };

  const deleteHandler = async (index) => {
    setIndex(index);
    setDeleteModalOpen(true);
  };

  const handleremove = () => {
    if (updatedOwner) {
      try {
        api.delete(`delete_owner_file/${updatedOwner.files[index].id}`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        setFormData((prevState) => {
          const updateDocs = [...prevState.files1];
          updateDocs.splice(index, 1);
          return {
            ...prevState,
            files1: updateDocs,
          };
        });
      } catch (error) {
        throw error;
      }
    }
  };

  const handleremove1 = (index) => {
    if (updatedOwner) {
      setFormData((prevState) => {
        const updateDocs = [...prevState.files];
        updateDocs.splice(index, 1);
        return {
          ...prevState,
          files: updateDocs,
        };
      });
    }
    if (!updatedOwner && formData.files.length !== 1) {
      setFormData((prevState) => {
        const updateDocs = [...prevState.files];
        updateDocs.splice(index, 1);
        return {
          ...prevState,
          files: updateDocs,
        };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updatedOwner) {
      fData.append("national_id", formData.national_id);
      fData.append("visa_category", formData.visa_category);
      fData.append("divison", formData.divison);
      fData.append("blood_group", formData.blood_group);
      fData.append("name", formData.name);
      fData.append("gender", formData.gender);
      fData.append("date_of_birth", formData.date_of_birth);
      fData.append("nationality", formData.nationality);
      fData.append("city", formData.city);
      fData.append("address", formData.address);
      fData.append("post_box", formData.post_box);
      fData.append("country_id", formData.country_id);
      fData.append("email", formData.email);
      // fData.append("isd_code", formData.isd_code);
      fData.append("mobile", phoneNumber);
      if (formData.remark !== null) {
        fData.append("remark", formData.remark);
      }
      formData.files.forEach((file, index) => {
        fData.append(`document_type`, file.document_type);
        fData.append(`issue_date`, file.issue_date);
        fData.append(`expiry_date`, file.expiry_date);
        fData.append(`uploaded_file`, file.uploaded_file);
      });
      setLoading(true);
      try {
        const response = await api.put(`update_owner/${id}`, fData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "OK" || response.status === "200") {
          setLoading(false);
          navigate("/dashboard/owneragreement/owners_list");
          toast.success("Owner updated successfully");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      fData.append("national_id", formData.national_id);
      fData.append("visa_category", formData.visa_category);
      fData.append("divison", formData.divison);
      fData.append("blood_group", formData.blood_group);
      fData.append("name", formData.name);
      fData.append("gender", formData.gender);
      fData.append("date_of_birth", formData.date_of_birth);
      fData.append("nationality", formData.nationality);
      fData.append("city", formData.city);
      fData.append("address", formData.address);
      fData.append("post_box", formData.post_box);
      fData.append("country_id", formData.country_id);
      fData.append("email", formData.email);
      // fData.append("isd_code", formData.isd_code);
      fData.append("mobile", phoneNumber);
      if (formData.remark) {
        fData.append("remark", formData.remark);
      }
      formData.files.forEach((file, index) => {
        fData.append(`document_type`, file.document_type);
        fData.append(`issue_date`, file.issue_date);
        fData.append(`expiry_date`, file.expiry_date);
        fData.append(`uploaded_file`, file.uploaded_file);
      });
      setLoading(true);
      try {
        const response = await api.post("create_owner", fData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "Created" || response.status === "201") {
          setLoading(false);
          navigate("/dashboard/owneragreement/owners_list");
          toast.success("Owner created successfully");
        }
      } catch (error) {
        throw error;
      }
    }
  };

  const handleOwnerUpdate = async (id) => {
    // dispatch(getUpdateOwnerData({id,token}))
    try {
      setIsLoading(true);
      const response = await api.get(`get_owner/${id}`, {
        headers: {
          Authorization: ` token ${token}`,
        },
      });

      setUpdatedOwner(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getVisaMasterData(token));
    dispatch(getDivisionMasterData(token));
    dispatch(getCountries(token));
    // dispatch(getISDcode(token));
    dispatch(getDocumentTypeMasterData(token));
    if (updatedOwner) {
      setFormData({
        national_id:
          updatedOwner && updatedOwner.national_id
            ? updatedOwner.national_id
            : null,
        visa_category:
          updatedOwner && updatedOwner.visa_category.id
            ? updatedOwner.visa_category.id
            : null,
        divison:
          updatedOwner && updatedOwner.divison.id
            ? updatedOwner.divison.id
            : null,
        blood_group:
          updatedOwner && updatedOwner.blood_group
            ? updatedOwner.blood_group
            : null,
        name: updatedOwner && updatedOwner.name ? updatedOwner.name : null,
        gender:
          updatedOwner && updatedOwner.gender ? updatedOwner.gender : null,
        date_of_birth:
          updatedOwner && updatedOwner.date_of_birth
            ? updatedOwner.date_of_birth
            : null,
        nationality:
          updatedOwner &&
            updatedOwner.nationality &&
            updatedOwner.nationality.id
            ? updatedOwner.nationality.id
            : null,
        city: updatedOwner && updatedOwner.city ? updatedOwner.city : null,
        address:
          updatedOwner && updatedOwner.address ? updatedOwner.address : null,
        post_box:
          updatedOwner && updatedOwner.post_box ? updatedOwner.post_box : null,
        country_id:
          updatedOwner && updatedOwner.country_id && updatedOwner.country_id.id
            ? updatedOwner.country_id.id
            : null,
        email: updatedOwner && updatedOwner.email ? updatedOwner.email : null,
        // isd_code:
        //   updatedOwner && updatedOwner.isd_code && updatedOwner.isd_code.id
        //     ? updatedOwner.isd_code.id
        //     : null,
        mobile:
          updatedOwner && updatedOwner.mobile ? updatedOwner.mobile : null,
        remark:
          updatedOwner && updatedOwner.remark ? updatedOwner.remark : null,
        files1: updatedOwner && updatedOwner.files ? updatedOwner.files : null,
        files: [],
        // PdfFiles: updatedOwner.files,
        checkedvalue: [],
      });
      setPhoneNumber(
        updatedOwner && updatedOwner.mobile ? updatedOwner.mobile : null
      );
    }
    AOS.init();
  }, [updatedOwner, dispatch, token]);

  useEffect(() => {
    let ownerUpdateId = null;
    try {
      ownerUpdateId = location.pathname.split("/")[4];
    } catch { }
    if (ownerUpdateId) {
      handleOwnerUpdate(ownerUpdateId);
    }
  }, []);

  useEffect(() => {
    const sectionId = window.location.hash
    if (sectionId) {
      const section = document.getElementById(sectionId.split("#")[1]);
      console.log("sectionId", sectionId.split("#"));
      console.log("section", section);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [formData]);

  return isLoading ? (
    <StableLoading />
  ) : (
    <div className="form-wrapper-container">
      <div
        className="registration-card"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <header>
          <div className="registration_top_header">
            <p>
              {id ? (
                <p>Update Owner Registration</p>
              ) : (
                <p>Owner Registration</p>
              )}
            </p>
            <span>
              Please fill in all required fields, attach/upload all supporting
              documents to proceed with the registration.
            </span>
          </div>
        </header>

        <form onSubmit={handleSubmit} encType="multipart/form-data">
          {/* Registration Information */}
          <div className="registration-information owneregistrationh3">
            <a href="#owner" id="owner" className="bookmark">
              <h3>Registration Information</h3>
            </a>
          </div>

          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  NATIONAL ID <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="text"
                  className="inputfield bg-color"
                  name="national_id"
                  value={formData.national_id}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="National Id"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  VISA CATEGORY <span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  name="visa_category"
                  value={
                    visa_category &&
                    formData.visa_category &&
                    visa_category.find(
                      (item) => item.id === Number(formData.visa_category)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "visa_category")
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={visa_category}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Visa"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  DISCIPLINE<span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  name="divison"
                  value={
                    division &&
                    formData.divison &&
                    division.find(
                      (item) => item.id === Number(formData.divison)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "divison")
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={division}
                  getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the divison
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Discipline"
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>

          {/* Owner Information */}
          <div className="owner-information owneregistrationh3">
            <a href="#ownerinfo" id="ownerinfo" className="bookmark">
              <h3>Owner Information</h3>
            </a>
          </div>

          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  OWNER NAME <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Owner Name"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  GENDER <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  value={formData.gender}
                  onChange={(event, value) =>
                    handleAutoCompleteStatic(event, value, "gender")
                  }
                  disablePortal
                  id="combo-box-demo"
                  name="gender"
                  options={genders}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Gender"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  DATE OF BIRTH <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="bg-color"
                  type="date"
                  name="date_of_birth"
                  value={formData.date_of_birth}
                  inputProps={{ max: today }}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  NATIONALITY <span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  name="nationality"
                  value={
                    country &&
                    formData.nationality &&
                    country.find(
                      (item) => item.id === Number(formData.nationality)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "nationality")
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={country}
                  getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the nationality
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Nationality"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  CITY <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="City"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  ADDRESS <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Address"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  POST BOX <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="post_box"
                  value={formData.post_box}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Ex: 452512"
                  error={postBoxError} // Set error state
                  helperText={postBoxError ? "Please enter numbers only." : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  COUNTRY <span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  name="country_id"
                  value={
                    country &&
                    formData.country_id &&
                    country.find(
                      (item) => item.id === Number(formData.country_id)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "country_id")
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={country}
                  getOptionLabel={(option) => option.name} // Replace "name" with the property that holds the name of the country
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select Country"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  BLOOD GROUP <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="blood_group"
                  value={formData.blood_group}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="B +ve"
                  inputProps={{
                    maxLength: 6,
                  }}
                  error={bloodGroupError} // Set error state
                  helperText={
                    bloodGroupError ? "Please use alphabets, +ve and -ve " : ""
                  }
                />
              </Grid>
            </Grid>
          </div>

          <div className="owner-contact-information owneregistrationh3">
            <a href="#ownercontact" id="ownercontact" className="bookmark">
              <h3> Owner Contact Information</h3>
            </a>
          </div>

          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  EMAIL <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="inputfield  bg-color"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="example@gmail.com"
                />
              </Grid>

              {/* <Grid item xs={12} sm={6} md={4}>
                <label>
                  ISD CODE <span style={{ color: "red" }}>*</span>
                </label>

                <Autocomplete
                  name="isd_code"
                  value={
                    isd_code &&
                    formData.isd_code &&
                    isd_code.find(
                      (item) => item.id === Number(formData.isd_code)
                    )
                  }
                  // defaultValue={}
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "isd_code")
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={isd_code}
                  getOptionLabel={(option) => option.country} // Replace "code" with the property that holds the ISD code
                  renderInput={(params) => (
                    <TextField
                      required
                      className="bg-color"
                      placeholder="Select ISD"
                      {...params}
                    />
                  )}
                />
              </Grid> */}

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  MOBILE NO. <span style={{ color: "red" }}>*</span>
                </label>
                {/* <TextField
                  className="inputfield  bg-color"
                  name="mobile"
                  type="number"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="0123456789"
                  error={postBoxError} // Set error state
                  helperText={postBoxError ? "Please enter numbers only." : ""}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                /> */}

                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  style={{ marginTop: '8px' }}
                  enableSearch={true}
                  countryCodeEditable={false}
                  disableSearchIcon={true}
                  inputProps={{

                    required: true,
                    autoFocus: true,
                  }}
                />
              </Grid>
            </Grid>
          </div>

          {/* Upload Documents */}
          <div className="owner-upload-documents  owneregistrationh3">
            <a href="#doc" id="doc" className="bookmark">
              <h3>Upload Documents</h3>
            </a>
          </div>

          <div className="inputparts">
            {updatedOwner && (
              <table style={{ width: "100%" }}>
                <thead style={{ textAlign: "left" }}>
                  <tr>
                    <th>Document Type</th>
                    <th>Issue Date</th>
                    <th>Expiry Date</th>
                    <th style={{ width: "100px" }}>Download Link</th>
                    <th></th>
                  </tr>
                </thead>

                {formData.files1.map((item, index) => {
                  return (
                    <>
                      <tbody>
                        <tr>
                          <td>
                            <span>{item.document_type.name}</span>
                          </td>
                          <td>
                            <span>{item.issue_date}</span>
                          </td>
                          <td>
                            <span style={{
                              color:
                                new Date(item.expiry_date) < new Date()
                                  ? "#ff0000"
                                  : "black",
                            }}>{item.expiry_date}</span>
                          </td>
                          <td>
                            <a
                              href={`${ImgUrl}${item.uploaded_file}`}
                              download
                              target="_blank"
                              className="pdfCard" rel="noreferrer"
                            >
                              <div>
                                <Link
                                  to={`${ImgUrl}${item.uploaded_file}`}
                                  download
                                  target="_blank"
                                  className="pdfCard"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      border: "1px solid #9f8754",
                                      marginBottom: "5px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "1.3rem",
                                        color: "white",
                                        background:
                                          "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                                        borderTopLeftRadius: "2px",
                                        borderBottomLeftRadius: "2px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "35px",
                                        height: "35px",
                                      }}
                                    >
                                      <FaFilePdf />
                                    </div>
                                    <div
                                      style={{
                                        maxWidth: "250px",
                                        padding: "2px 10px",
                                        fontSize: "0.8rem",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {item.uploaded_file &&
                                        formData.files1[
                                          index
                                        ].uploaded_file.split(
                                          "/media/owner_file/"
                                        )[1]}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </a>
                          </td>
                          <td>
                            <Button
                              className="addbtn"
                              title="Add Fields"
                              onClick={() => deleteHandler(index)}
                            >
                              <RiDeleteBin6Line
                                style={{ color: "red", fontSize: "15px" }}
                              />
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </>
                  );
                })}
              </table>
            )}

            {formData.files?.map((document, index) => (
              <div
                style={{ marginTop: "8px" }}
                className="upload_document"
                key={index}
              >
                <Button
                  className="addbtn"
                  title="Remove Fields"
                  onClick={() => handleremove1(index)}
                >
                  <ClearIcon style={{ color: "red", fontSize: "15px" }} />
                </Button>

                <Grid container spacing={2} key={index} sx={{ mb: "10px" }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <label>
                      SELECT DOCUMENT TYPE{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    {docType && (
                      <Autocomplete
                        name="document_type"
                        value={
                          docType &&
                          docType.find(
                            (item) => item.id === Number(document.document_type)
                          )
                        }
                        onChange={(event, value) =>
                          handleACDocTypeFields(
                            event,
                            value,
                            `document_type`,
                            index
                          )
                        }
                        disablePortal
                        id="combo-box-demo"
                        options={docType}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            required
                            className="bg-color"
                            {...params}
                            placeholder="Select Document Type"
                            {...params}
                          />
                        )}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <label>
                      Issue Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      className="bg-color"
                      type="date"
                      value={document.issue_date}
                      // name={`issue_date${index}`}
                      inputProps={{ max: today }}
                      name={`issue_date`}
                      onChange={(event, value) =>
                        handleACDocFields(event, value, `issue_date`)
                      }
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <label>
                      Expiry Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      className="bg-color"
                      type="date"
                      value={document.expiry_date}
                      name={`expiry_date`}
                      // name={`expiry_date${index}`}
                      inputProps={{ min: today }}
                      onChange={
                        (event, value) =>
                          handleACDocFields(event, value, `expiry_date`)
                        // handleACDocFields(event, value, `expiry_date${index}`)
                      }
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <label htmlFor={`upload-photo-${index}`}>
                      Attach File <span style={{ color: "red" }}>*</span>
                      <TextField
                        className="bg-color"
                        // name={`uploaded_file${index}`}
                        name={`uploaded_file`}
                        type="file"
                        id={`upload-photo`}
                        required
                        onChange={(event) => docUpload(event, index)}
                        style={{ width: "100%" }}
                      />
                    </label>
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                      }}
                    >
                      {attachDoc}
                    </span>
                  </Grid>

                  {updatedOwner && formData.PdfFiles && (
                    <Grid>
                      <Link
                        to={`${ImgUrl}${formData.PdfFiles[index].uploaded_file}`}
                        download
                        target="_blank"
                        className="pdfCard"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #9f8754",
                            borderRadius: "3px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "1.3rem",
                              color: "white",
                              background:
                                "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                              borderRadiusTopleft: "2px",
                              borderRadiusBottomleft: "2px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            <FaFilePdf />
                          </div>

                          <div
                            style={{ padding: "0px 10px", fontSize: "0.8rem" }}
                          >
                            {formData.PdfFiles &&
                              formData.PdfFiles[index].uploaded_file.split(
                                "/media/owner_file/"
                              )[1]}
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </div>
            ))}

            <Button
              title="Add Fields"
              onClick={docRender}
              style={{
                backgroundColor: "#826527",
                color: "white",
                fontSize: "12px",
                float: "right",
                marginBlock: "10px",
              }}
            >
              <AddIcon style={{ fontSize: "15px", fontWeight: "bold" }} />
              &nbsp;ADD
            </Button>
          </div>

          {/* Remark */}
          <div className="owner-remark owneregistrationh3">
            <a href="#remark" id="remark" className="bookmark">
              <h3>Remarks</h3>
            </a>
          </div>

          <div className="inputparts">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextareaAutosize
                  style={{ width: "100%", fontSize: "16px", padding: "10px" }}
                  minRows={8}
                  className="bg-color"
                  placeholder="Additional Information"
                  multiline
                  name="remark"
                  value={formData.remark}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>

          {/* Terms and Conditions */}
          <div className="owner-tandc owneregistrationh3">
            <a href="#tandc" id="tandc" className="bookmark">
              <h3>Terms & Conditions</h3>
            </a>
          </div>

          <div style={{ marginBlock: "15px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBlock: "15px",
              }}
            >
              <Checkbox
                inputProps={{ "aria-label": "Checkbox demo" }}
                color="success"
                name="checkedvalue"
                onClick={handleChange}
                required
              />
              <p className="horseRegistration">
                BY CHECKING THIS BOX, YOU AGREE WITH THE TERMS AND CONDITIONS
                AND GUARANTEES THAT THE INFORMATION YOU ENTERED IS TRUE AND
                CORRECT
              </p>
            </div>
          </div>

          {/* Submit */}
          <div className="btn-submit" style={{ gap: "15px" }}>
            <Button
              type="submit"
              variant="contained"
              className="btn-bgColor"
              onClick={() => navigate("/dashboard/owneragreement/owners_list")}
            >
              Cancel
            </Button>

            {loading ? (
              <CommonLoading />
            ) : (
              <Button type="submit" variant="contained" className="btn-bgColor">
                {id ? "Update" : "Create"}
              </Button>
            )}
          </div>
        </form>
      </div>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Document"
        deleteData={handleremove}
      />
    </div>
  );
};

export default OwnerRegistration;
