import React, { useState } from 'react'
import LoginPageLeftContainer from '../../LoginRegister/LoginPageLeftContainer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../Ridingschool/components/Loader';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useFormik } from 'formik';
import LoginApi from '../../../App/Apis/RidingSchoolApis/studentApi/login';
import { toast } from 'react-toastify';

const StudentLogin_OTP = () => {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const { search } = useLocation()
    const email = search.split("?")[1]

    const initialValues = {
        otp: "",
        email: email
    }

    const loginOTP = async (payload) => {
        setIsLoading(true)
        try {
            const res = await LoginApi.studentLogin_OTP__Verify(payload)
            localStorage.setItem("student_token", JSON.stringify(res.data?.Token))
            localStorage.setItem("student_data", JSON.stringify(res.data?.student_data))
            localStorage.setItem("isStudent", true)
            navigate('/student-dashboard')
            console.log(res.data)
        }
        catch (err) {
            console.log(err)
            toast.error("Invalid OTP ! Please try again")
        }
        setIsLoading(false)
    }

    const handleResendOTP = async () => {
        setIsLoading(true)
        try {
            await LoginApi.studentLoginOtp({ email: values.email })
            toast.success("OTP resend successfully, check your email and verify OTP !")
        }
        catch (err) {
            console.log(err)

        }
        setIsLoading(false)
    }

    const { values, handleChange, handleSubmit } = useFormik({
        initialValues,
        onSubmit: (value, action) => {
            console.log(value)
            loginOTP(value)
            action.resetForm()
        }
    })

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <section className="login-section login-page">
                <LoginPageLeftContainer />
                <div className="login-right-container">
                    <div className="login-module-container">
                        <div className="login-portal-container">
                            <h2 style={{ color: '#826426' }}>PORTAL</h2>
                            <p style={{ color: '#222222', fontSize: '12.5px', fontWeight: 'bold' }}>
                                PLEASE ENTER OTP BELOW:
                            </p>
                        </div>
                        <div className="container">
                            <h1
                                className="login-header"
                                style={{
                                    fontSize: '1.3rem',
                                }}
                            >
                                To Login Enter OTP
                            </h1>
                            <p
                                style={{
                                    color: '#bebb9c',
                                    fontWeight: '300',
                                    fontSize: '0.9rem',
                                    textAlign: 'center',
                                    marginTop: '0.5rem',
                                    lineSpacing: '0.5rem',
                                    wordSpacing: '0.1rem',
                                }}
                            >
                                Enter your OTP and verify
                            </p>

                            <form className="login-form" onSubmit={handleSubmit}>
                                <div style={{ marginBlock: "2.5rem" }}>
                                    <input
                                        value={values.otp}
                                        name="otp"
                                        type="number"
                                        onChange={handleChange}
                                        className="input-field"
                                        id="OTPname"
                                        placeholder="Enter Your OTP"
                                        required
                                        style={{ '-moz-appearance': 'textfield' }}
                                    />


                                    <Link style={{ color: 'black', fontSize: '1rem' }}>
                                        <span onClick={handleResendOTP}>Resend OTP</span>

                                    </Link>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <button
                                        className="login-button"
                                        type='button'
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '45%',
                                            backgroundColor: 'transparent',
                                            border: '1px solid #bebb9e',
                                            color: '#bebb9c',
                                            marginTop: '8px',
                                            fontWeight: '400',
                                        }}
                                        onClick={() => navigate('/student-login')}
                                    >
                                        <BsArrowLeftShort style={{ fontSize: '25px' }} />
                                        &nbsp;&nbsp;<span>Back to Login</span>
                                    </button>
                                    <button className="login-button" type="submit" style={{ width: '45%', marginTop: '8px' }}>
                                        Verify OTP
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {/* Mobile View */}
            <div className="login-right-container-mobile">
                <div >
                    <div >
                        <h2 style={{ color: '#826426' }}>PORTAL</h2>
                        <p style={{ color: '#222222', fontSize: '12.5px', fontWeight: 'bold' }}>
                            PLEASE ENTER OTP BELOW:
                        </p>
                    </div>
                    <div className="forget-password-container">
                        <h1 className="login-header" style={{ fontSize: '1.3rem', }}>Forgot Your Password ?</h1>
                        <p
                            style={{
                                color: '#bebb9c',
                                fontWeight: '300',
                                fontSize: '0.9rem',
                                textAlign: 'center',
                                marginTop: '0.5rem',
                                lineSpacing: '0.5rem',
                                wordSpacing: '0.1rem',
                            }}
                        >
                            Enter your OTP and verify
                        </p>

                        <form className="login-form" onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <input
                                    value={values.otp}
                                    name="otp"
                                    type="number"
                                    onChange={handleChange}
                                    className="input-field"
                                    id="OTPname"
                                    placeholder="Enter Your OTP"
                                    required
                                    style={{ '-moz-appearance': 'textfield' }}
                                />


                                <Link style={{ color: 'black', fontSize: '1rem' }}>
                                    <p onClick={handleResendOTP}>Resend OTP</p>
                                </Link>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button
                                    className="login-button"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '45%',
                                        backgroundColor: 'transparent',
                                        border: '1px solid #bebb9e',
                                        color: '#bebb9c',
                                        marginTop: '8px',
                                        fontWeight: '400',
                                    }}
                                    onClick={() => navigate('/login')}
                                >
                                    <BsArrowLeftShort style={{ fontSize: '25px' }} />
                                    &nbsp;&nbsp;<span>Back to Login</span>
                                </button>
                                <button className="login-button" style={{ width: '45%', marginTop: '8px' }} >
                                    Verify OTP
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StudentLogin_OTP
