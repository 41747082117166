import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Autocomplete,
  Button,
  ImageList,
  ImageListItem,
} from "@mui/material";
import "../../AdminSetting/User/User.css";
import { useNavigate, useParams } from "react-router-dom";
import { ImgUrl } from "../../../Config/Config";
import Registration from "../../../App/Apis/RidingSchoolApis/studentApi/registration";
import { companyId } from "../../../App/Apis/RidingSchoolReuseIds/Ids";
import { toast } from "react-toastify";
import Loader from "../../../Ridingschool/components/Loader";
import { useFormik } from "formik";

const StudentRegistration = () => {
  // const dispatch = useDispatch();
  // const location = useLocation();
  // const [cookies, setCookies] = useCookies(["token"]);
  // const token = cookies.token;
  // const countries = useSelector((state) => state.countries.countriesData);
  // const [isVisibila, setIsVisible] = useState(false);
  // const [isCreateVisible, setIsCreateVisible] = useState(false);
  // const [isUpdateVisible, setIsUpdateVisible] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  // const [showOrgPassword, setShowOrgPassword] = useState(false);
  // const [passwordError, setPasswordError] = useState("");
  // const [confirmpasswordError, setConfirmPasswordError] = useState("");

  const navigate = useNavigate();
  const studentId = useParams().id;
  const [isLoading, setIsLoading] = useState(false);
  const [singleStudentData, setSingleStudentData] = useState()
  const [countries, setCountries] = useState([])
  const company_UID = companyId


  const getCountryData = async () => {
    try {
      const res = await Registration.countryData()
      setCountries(res.data)
    }
    catch (err) {
      console.log(err)
    }
  }


  const initialValues = {
    first_name: "",
    last_name: "",
    mobile: "",
    country_id: "",
    company_id: company_UID,
    email: "",
    address: "",
    image: null,
    city: "",
    state: "",
    password: "",
    age: "",
    riding_experience: 0,
    date_of_birth: "",
  }

  const handleCountry = (e, data) => {
    const country = data?.value;
    setFieldValue("country_id", country)
  }

  const countryOption = countries.map((con) => (
    { value: con.id, label: con.name }
  ))

  function handleFile(e) {
    const { files } = e.target;

    if (files) {
      const selectedFile = files[0];
      console.log(selectedFile)

      if (!selectedFile) {
        return;
      }
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      console.log(fileExtension)

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setFieldValue("image", null)
        toast.error("Please upload a file with a .jpg, .jpeg, or .png extension.")
      } else if (selectedFile.size > 1024 * 1024) {
        e.target.value = null;
        setFieldValue("image", null)
        toast.error("Please upload an image that is less than 1MB in size.");
      } else {
        setFieldValue("image", e.target.files[0])
      }

    }
  }

  const studentRegister = async (payload) => {
    setIsLoading(true)
    try {
      const res = await Registration.createRegistration(payload)
      navigate("/riding-club/course/student-registration")
      toast.success("Student Created Successfully")
      console.log("Response data after Created >> ", res.data)
    }
    catch (err) {
      console.log(err)
    }
    setIsLoading(false)
  }


  const studentUpdate = async (id, payload) => {
    try {
      const res = await Registration.studentUpdate(id, payload)
      navigate("/riding-club/course/student-registration")
      toast.success("Student Updated Successfully")
      console.log("Response data after Updated >> ", res.data)
    }
    catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    const singleStudentDetails = async () => {
      setIsLoading(true)
      try {
        const res = await Registration.getSingleStudent(studentId)
        setSingleStudentData(res.data)
        console.log("single data response", res.data)
      }
      catch (err) {
        console.log(err)
      }
      setIsLoading(false)
    }
    if (studentId) {
      singleStudentDetails()
    }
  }, [studentId])

  useEffect(() => {
    if (singleStudentData) {
      setFieldValue("first_name", singleStudentData?.first_name || "");
      setFieldValue("last_name", singleStudentData?.last_name || "");
      setFieldValue("email", singleStudentData?.email || "");
      setFieldValue("country_id", singleStudentData?.country_id?.id || "");
      setFieldValue("address", singleStudentData?.address || "");
      setFieldValue("state", singleStudentData?.state || "");
      setFieldValue("mobile", singleStudentData?.mobile || "");
      setFieldValue("city", singleStudentData?.city || "");
      setFieldValue("password", singleStudentData?.password || "");
      setFieldValue("image", singleStudentData?.image || "");
      setFieldValue("age", singleStudentData?.age || "");
      setFieldValue("riding_experience", singleStudentData?.riding_experience || "");
      setFieldValue("date_of_birth", singleStudentData?.date_of_birth || "")
    }
  }, [singleStudentData])


  useEffect(() => {
    getCountryData()
  }, []);



  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    onSubmit: (value, action) => {
      console.log(value)
      const fData = new FormData();
      fData.append("first_name", values?.first_name);
      fData.append("last_name", values?.last_name);
      fData.append("email", values?.email);
      fData.append("country_id", values?.country_id);
      fData.append("address", values?.address);
      fData.append("mobile", values?.mobile)
      fData.append("state", values?.state);
      fData.append("city", values?.city);
      fData.append("password", values?.password);
      fData.append("age", values?.age);
      fData.append("riding_experience", values?.riding_experience);
      fData.append("date_of_birth", values?.date_of_birth);
      fData.append("company", company_UID)
      if (values.image instanceof File) {
        fData.append("image", values?.image);
      }
      if (!studentId) studentRegister(fData)
      else studentUpdate(studentId, fData)
      action.resetForm()
    }
  })


  if (isLoading) {
    return <Loader />
  }


  return (
    <div className="form-wrapper-container">
      <div
        className="registration-card"

      >
        <header>
          <div className="registration_top_header">
            {studentId ? <p>Update Student</p> : <p>Student Registration</p>}
            <span>
              Please fill in all required fields proceed with the
              registration.
            </span>
          </div>
        </header>

        <form onSubmit={handleSubmit} encType="multipart/form-data">

          <div className="inputparts-checkIn">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  First Name <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="text"
                  className="inputfield bg-color"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="First Name"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  Last Name <span style={{ color: "red" }}>*</span>
                </label>

                <TextField
                  type="text"
                  className="inputfield bg-color"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Last Name"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  Email<span style={{ color: "red" }}>*</span>
                </label>

                <TextField
                  type="email"
                  className="inputfield bg-color"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Enter Email"
                />
              </Grid>
            </Grid>
          </div>


          <div className="inputparts-checkIn">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  Phone Number <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="number"
                  className="inputfield bg-color"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Enter Phone Number"
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  City <span style={{ color: "red" }}>*</span>
                </label>

                <TextField
                  type="text"
                  className="inputfield bg-color"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Enter City"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  State<span style={{ color: "red" }}>*</span>
                </label>

                <TextField
                  type="text"
                  className="inputfield bg-color"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Enter State"
                />
              </Grid>
            </Grid>
          </div>


          <div className="inputparts-checkIn">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  Country <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <Autocomplete
                    id="combo-box-demo"
                    name="country_id"
                    value={values.country_id && countryOption.find((v) => values.country_id === v.value)}
                    onChange={handleCountry}
                    required
                    fullWidth
                    options={countryOption}
                    renderInput={(params) => (
                      <TextField required className="bg-color" {...params} />
                    )}

                  />
                }
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  Address<span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  type="text"
                  className="inputfield bg-color"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Enter Address"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  Age<span style={{ color: "red" }}>*</span>
                </label>

                <TextField
                  type="number"
                  className="inputfield bg-color"
                  name="age"
                  value={values.age}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Enter Your Age"
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>


          <div className="inputparts-checkIn">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  DOB<span style={{ color: "red" }}>*</span>
                </label>

                <TextField
                  type="date"
                  className="inputfield bg-color"
                  name="date_of_birth"
                  value={values.date_of_birth}
                  onChange={handleChange}
                  required
                  fullWidth
                  placeholder="Enter Date of Birth"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label>
                  Riding Experience in Year
                  <span style={{ color: "red" }}>*</span>
                </label>

                <TextField
                  type="number"
                  className="inputfield bg-color"
                  name="riding_experience"
                  value={values.riding_experience}
                  onChange={handleChange}
                  required
                  fullWidth
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  placeholder="Enter Riding Experience"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>
                  Profile Image<span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  className="bg-color"
                  // className="inputfield bg-color"
                  id="upload-photo"
                  name="image"
                  type="file"
                  onChange={handleFile}
                  style={{ width: "100%" }}
                />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div style={{ marginBlock: "5%", marginTop: "1rem" }}>
                      <ImageList
                        sx={{ width: 400, height: "auto" }}
                        cols={2}
                        rowHeight={"auto"}
                      >
                        <ImageListItem>
                          {typeof values.image === "string" && (
                            <img
                              src={`${ImgUrl}${values.image}`}
                              alt="Uploaded file"
                              loading="lazy"
                            />
                          )}
                          {values.image instanceof File && (
                            <img
                              src={URL.createObjectURL(values.image)}
                              alt="Uploaded file"
                              loading="lazy"
                            />
                          )}
                        </ImageListItem>
                      </ImageList>
                    </div>
                  </Grid>
                </Grid>
              </Grid>


            </Grid>
          </div>


          {/* <div className="inputparts-checkIn">
              <Grid container spacing={2}>
                {!studentId && (
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="password-input-container">
                      <label>
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        type={showOrgPassword ? "text" : "password"}
                        autoComplete="off"
                        className="inputfield bg-color"
                        name="password"
                        value={formData.password}
                        onChange={(event, value) =>
                          handlepassword(event, value, "password")
                        }
                        required
                        fullWidth
                        placeholder="Enter Password"
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      <i
                        className={`password-toggle-icon  ${showOrgPassword ? "fa fa-eye" : "fa fa-eye-slash"
                          }`}
                        style={{ marginBlock: "0.8rem" }}
                        onClick={handleOrgPasswordToggle}
                      ></i>
                      <p style={{ color: "red", fontSize: "13px" }}>
                        {passwordError}
                      </p>
                    </div>
                  </Grid>
                )}

                {!studentId && (
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="password-input-container">
                      <label>
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        autoComplete="off"
                        className="inputfield bg-color"
                        name="password1"
                        value={formData.password1}
                        onChange={(event, value) =>
                          handlepassword(event, value, "password1")
                        }
                        required
                        fullWidth
                        placeholder="Confirm Password"
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                      />
                      <i
                        className={`password-toggle-icon  ${showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                          }`}
                        style={{ marginBlock: "0.8rem" }}
                        onClick={handlePasswordToggle}
                      ></i>
                      <p style={{ color: "red", fontSize: "13px" }}>
                        {confirmpasswordError}
                      </p>
                    </div>
                  </Grid>
                )}


              </Grid>
            </div> */}
          <div className="btn-submit" style={{ gap: "15px" }}>
            <Button
              type="submit"
              variant="contained"
              className="btn-bgColor"
              onClick={() => navigate("../course/student-registration")}
            >
              Cancel
            </Button>
            {studentId ? (
              <Button
                type="submit"
                variant="contained"
                className="btn-bgColor"
              >
                Update
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                className="btn-bgColor"
              >
                Register
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
};

export default StudentRegistration;


