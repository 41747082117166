import React from 'react';
import './support.css';

const Support = () => {
    return (
        <>
            <div className="support-container">
                <div className="mapContainer">
                    <h1 className="support-heading">
                        <span className="headingSpan">Find Our Location Through Google Map</span>
                    </h1>
                    <div className="mapDiv">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3697.492688491368!2d82.14278377601781!3d22.06899355123841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a280bbf02dd0d65%3A0x7024a8e995e4bd2a!2sNable%20Invent%20Solution%20(INDIA)!5e0!3m2!1sen!2sin!4v1701234383841!5m2!1sen!2sin"
                            width="100%"
                            height="100%"
                            style={{ position: 'absolute', top: 0, left: 0, border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
                <div className="addressContainer">
                    <h1 className="support-heading">
                        <span className="headingSpan">Our Address</span>
                    </h1>
                    <p className="para">
                        <b>Nable Invent Solutions</b>
                        <br />
                        Karan Chamber, Vyapar Vihar, Bilaspur, Chhattisgarh 495004
                    </p>
                    <h1 className="support-heading">
                        <span className="headingSpan">Contact Details</span>
                    </h1>
                    <p className="para">
                        Telephone Number:{' '}
                        <a className="txtColor" href="tel:07752415058">
                            077524 15058
                        </a>
                        <br />
                        Karan Chamber, Vyapar Vihar, Bilaspur, Chhattisgarh 495004
                        <br />
                        <br />
                        Email address:{' '}
                        <a className="txtColor" href="mailto:Info@nableinvent.com">
                            Info@nableinvent.com
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
};

export default Support;
