import { createSlice } from "@reduxjs/toolkit";

const ThemeReducer = createSlice({
  name: "theme",
  initialState: {
    ThemeColor: "black",
  },
  reducers: {
    ChangeTheme(state, action) {
      console.log(action.payload, "Selected theme from reducer");
      state.ThemeColor = action.payload;
    },
  },
});
export default ThemeReducer.reducer;

export const { ChangeTheme } = ThemeReducer.actions;
