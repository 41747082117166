import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import "./table.css";
import { Checkbox } from "./Checkbox";
import { GlobalFilter } from "./GlobalFilter";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
import { FaEdit } from "react-icons/fa";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";
import moment from "moment";
// import { PropaneSharp } from "@mui/icons-material";

export const BasicTable = ({
  colHeader,
  rowData,
  deleteHandler,
  updateHandler,
  profileUpdation = () => false, // Default to a function that returns false
  tableHeading,
  createHandler,
  handleImport,
  downloadTemplate,
  profile,
  actionFlag = false,
}) => {
  const tableRef = useRef(null);
  const [densityState, setDensityState] = useState("hoverEffect");
  const [gg, setGg] = useState(true);
  const handleState = (e) => {
    switch (e.target.value) {
      case "density1":
        setDensityState("density1");
        break;
      case "density2":
        setDensityState("density2");
        break;
      case "density3":
        setDensityState("density3");
        break;

      default:
        setDensityState("");
        break;
    }
  };

  const csvRowData =
    rowData.length > 0
      ? rowData.map((item) => {
          const filteredItem = {};

          for (const header of colHeader) {
            const accessor = header.accessor;
            if (item.hasOwnProperty(accessor)) {
              if (header.Cell) {
                const headerCell = header.Cell;
              } else {
                filteredItem[accessor] = item[accessor];
              }
            }
          }
          return filteredItem;
        })
      : [];

  const columns = useMemo(() => colHeader, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable(
    {
      data: rowData,
      columns,
    },
    useGlobalFilter,
    usePagination
  );

  const { pageIndex } = state;
  const { globalFilter } = state;

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const download = () => {
    setGg(false);
    setTimeout(() => {
      onDownload();
    }, 500);
    setTimeout(() => {
      setGg(true);
    }, 700);
  };

  // const compPdf=useRef();
  const generatePDF = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: "dem",
  });
  const generatePdf = () => {
    setGg(false);
    setTimeout(() => {
      generatePDF();
    }, 500);
    setTimeout(() => {
      setGg(true);
    }, 700);
  };
  const detailsRef = useRef(null);

  useEffect(() => {
    AOS.init();
    const handleClickOutside = (event) => {
      // Check if the clicked element is outside the details element
      if (detailsRef.current && !detailsRef.current.contains(event.target)) {
        // Close the details element if it's open
        if (detailsRef.current.hasAttribute("open")) {
          detailsRef.current.removeAttribute("open");
        }
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [query, setQuery] = useState("");

  return (
    <>
      <ToastContainer />
      <div
        className="all_table_list"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <div className="table_header registration_top_header_table">
          <p>{tableHeading}</p>

          <div className="table-btn">
            <div className="table-btn-left">
              {downloadTemplate && (
                <button onClick={() => downloadTemplate()}>
                  Download Template
                </button>
              )}
              {handleImport && (
                <button onClick={() => handleImport()}>Import as Excel</button>
              )}
              <button onClick={() => download()}>Export as Excel</button>
              <button onClick={() => generatePdf()}>Export as PDF</button>
              <button className="Csv">
                <CSVLink data={csvRowData.slice(0, 10)}>Export as CSV</CSVLink>
              </button>
              {/* <button onClick={() => navigate("/dashboard/horse/horse-profile")}>{profile}</button> */}
            </div>
            {createHandler && (
              <button onClick={createHandler} style={{ marginInline: "15px" }}>
                Create +
              </button>
            )}
          </div>
        </div>
        <div className="table">
          <div className="Main-div">
            <div className="Features-section">
              {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
              <div className="Search-box">
                <input
                  value={query || ""}
                  placeholder="search"
                  style={{ color: "black" }}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                />
              </div>
              <details
                ref={detailsRef}
                style={{ cursor: "pointer", zIndex: "1000" }}
              >
                <summary style={{ textWrap: "nowrap" }}>Filter Columns</summary>
                <div className="Header-column">
                  <div>
                    <Checkbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                  {allColumns.map((column) => (
                    <div key={column.id} className="Toggle-list">
                      <label>
                        <input
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                        />{" "}
                        {column.Header}
                      </label>
                    </div>
                  ))}
                  <br />
                </div>
              </details>
            </div>

            <div className="table-list" ref={tableRef}>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} style={column.style}>
                          {column.render("Header")}
                        </th>
                      ))}
                      {!actionFlag && gg && <th className="p-fixed">Action</th>}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page
                    .filter((row) => {
                      prepareRow(row);
                      return Object.values(row.original).some((cell) => {
                        const value = cell;
                        // console.log("value is", row.original);
                        if (Array.isArray(value)) {
                          return value.some(
                            (item) =>
                              item !== null &&
                              item
                                .toString()
                                .toLowerCase()
                                .includes(query.trim().toLowerCase())
                          );
                        }

                        if (typeof value === "object" && value !== null) {
                          return Object.values(value).some((nestedValue) => {
                            if (
                              typeof nestedValue === "object" &&
                              nestedValue !== null
                            ) {
                              return Object.values(nestedValue).some(
                                (objValue) =>
                                  objValue !== null &&
                                  objValue
                                    .toString()
                                    .toLowerCase()
                                    .includes(query.trim().toLowerCase())
                              );
                            } else {
                              // Otherwise, treat it as a regular value and apply the filter
                              return (
                                nestedValue !== null &&
                                nestedValue
                                  .toString()
                                  .toLowerCase()
                                  .includes(query.trim().toLowerCase())
                              );
                            }
                          });
                        }

                        if (
                          row.original?.date ||
                          row.original?.date_time ||
                          row.original?.salary_month
                        ) {
                          const formattedDate = moment(
                            row.original.date
                              ? row.original.date
                              : row.original?.date_time
                              ? row.original?.date_time
                              : row.original?.salary_month
                          ).format("DD/MM/YYYY");
                          return (
                            formattedDate
                              .toLowerCase()
                              .includes(query.trim().toLowerCase()) ||
                            (value &&
                              value
                                .toString()
                                .toLowerCase()
                                .includes(query.trim().toLowerCase()))
                          );
                        }

                        return (
                          value !== null &&
                          value !== undefined &&
                          value
                            .toString()
                            .toLowerCase()
                            .includes(query.trim().toLowerCase())
                        );
                      });
                    })
                    .map((row, i) => {
                      prepareRow(row);

                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, i) => {
                            return (
                              <td
                                className={densityState}
                                id="td_column"
                                style={cell.column.style}
                                onClick={() => {
                                  profileUpdation(row.original.id);
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}

                          {!actionFlag && gg && (
                            <td className={`${densityState} p-fixed`}>
                              <div id="btn_action">
                                {updateHandler && (
                                  <FaEdit
                                    color="#7c5e1e"
                                    fontSize="17px"
                                    paddingInline="5px 15px"
                                    cursor="pointer"
                                    margin="5px"
                                    title="Edit Details"
                                    onClick={() => {
                                      updateHandler(row.original.id);
                                    }}
                                  />
                                )}

                                {deleteHandler && (
                                  <MdDelete
                                    color="#7c5e1e"
                                    fontSize="18px"
                                    paddingInline="5px 10px"
                                    cursor="pointer"
                                    margin="5px"
                                    title="Delete "
                                    onClick={() => {
                                      deleteHandler(row.original.id);
                                    }}
                                  />
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="Navigation">
              <div className="NavigationButton">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <GrPrevious />
                </button>

                <span>
                  page{" "}
                  <strong>
                    {" "}
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <GrNext />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
