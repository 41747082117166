
import React from 'react'
import { useSelector } from 'react-redux'
import { createContact, getContact, updateContact } from '../../App/Reducers/HeroQuillReducer'
import CommonEditablePage from '../../SharedComponets/CommonEditablePage'
import { contactUs } from '../../Assets/DefaultImagesCarousel'

const ContactUs = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.contact);
  return (
    <>
      <CommonEditablePage 
      updateApiFunc={updateContact} 
      getApiFunc={getContact} 
      createApiFunc = {createContact}
      modalSelector={modalSelector}
      default_image_title= {contactUs.image_title}
      default_image={contactUs.image}
      default_body_content=" Nable Invent Solution - 07752415058"/>
    </>
  )
}

export default ContactUs