import React, { useEffect, useState } from "react";
import "./Planer.css";
import {
  TextField,
  Button,
  Grid,
  Autocomplete,
  TextareaAutosize,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateHealthPlaner,
  UpdateHealthPlaner,
} from "../../App/Reducers/HealthPlanerReducer";
import { getHorseData } from "../../App/Reducers/HorseRegistrationReducer";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getColorMasterData } from "../../App/Reducers/AdminSettingReducer/ColorMasterReducer";
import { GetUsers } from "../../App/Reducers/UserReducer";
import { getHealthMasterData } from "../../App/Reducers/AdminSettingReducer/HealthMasterReducer";

const HealthPlaner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const State = useSelector((state) => state.HealthPlaner);
  const colors = useSelector((state) => state.ColorMaster.ColorMasterData);
  const health_type_list = useSelector(
    (state) => state.HealthMaster.HealthMasterData
  );

  const Data = useSelector((state) => state.HealthPlaner.UpdatingPlaner);
  const [status, setStatus] = useState(State.status.Create);
  const Horses_list = useSelector((state) => state.horseRegistration.data);
  const users = useSelector((state) => state.getUsers.users);
  const [staffs, setStaffs] = useState(users);
  const [healthType, setHealthType] = useState(health_type_list);
  console.log(healthType);
  const [healthData, setHealthData] = useState({
    horse_id: null,
    health_type: "",
    health_status: "",
    staff: "",
    other_staff: "",
    price: "",
    discription: "",
    start_date: null,
    end_date: null,
  });

  console.log("HealthPlaner", healthData);
  const EventHandler = (e) => {
    const { name, value } = e.target;
    setHealthData({ ...healthData, [name]: value });
  };
  const EventAutoCompleteHandler = (e, value, fieldName) => {
    setHealthData({ ...healthData, [fieldName]: value });
  };
  const CreateHandler = () => {
    dispatch(CreateHealthPlaner({ healthData, token }));
    if (status === "succeeded") {
      navigate("/dashboard/planer/food_list");
    }
  };
  const UpdateHandler = () => {
    console.log("healthData", healthData);
    dispatch(UpdateHealthPlaner({ healthData, token }));
  };
  useEffect(() => {
    dispatch(getHorseData(token));
    dispatch(GetUsers(token));
    dispatch(getHealthMasterData(token));
  }, [token]);
  useEffect(() => {
    setStaffs(users);
    setHealthType(health_type_list);
    setStatus(State.status.Create);
    if (Data) {
      setHealthData({
        id: Data.id,
        horse_id: String(Data.horse_id.id),
        health_type: Data.health_type.id,
        health_status: Data.health_status,
        staff: String(Data.staff.id),
        other_staff: Data.other_staff,
        price: String(Data.price),
        discription: Data.discription,
        start_date: Data.start_date
          ? new Date(Data.start_date).toISOString().slice(0, -1)
          : "",
        end_date: Data.end_date
          ? new Date(Data.end_date).toISOString().slice(0, -1)
          : "",
      });
    }
  }, [State.status.Create, Data, users, health_type_list]);
  return (
    <div className="form-wrapper-container">
      <div className="form-container">
        <h2 className="planer-header">Health Planner Of Horse</h2>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <label>Horse Name</label>
              <Autocomplete
                id="combo-box-demo"
                name="horse_id"
                value={
                  Horses_list &&
                  healthData.horse_id &&
                  Horses_list.find(
                    (item) => item.id === Number(healthData.horse_id)
                  )
                }
                onChange={(event, value) =>
                  EventAutoCompleteHandler(event, value, "horse_id")
                }
                required
                fullWidth
                options={Horses_list && Horses_list}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField className="bg-color" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Health Type</label>
              <Autocomplete
                name="health_type"
                disablePortal
                id="combo-box-demo"
                value={
                  healthType &&
                  healthData.health_type &&
                  healthType.find(
                    (item) => item.id === Number(healthData.health_type)
                  )
                }
                onChange={(event, value) =>
                  EventAutoCompleteHandler(event, value, "health_type")
                }
                options={healthType && healthType}
                getOptionLabel={(option) => option.name}
                required
                renderInput={(params) => (
                  <TextField placeholder="Choose" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Health Status</label>
               <TextField
                type="text"
                nname="health_status"
                value={healthData.health_status}
                onChange={EventHandler}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>Staff</label>

              <Autocomplete
                name="staff"
                disablePortal
                id="combo-box-demo"
                value={
                  staffs &&
                  healthData.staff !== null &&
                  staffs.find((item) => item.id === Number(healthData.staff))
                }
                onChange={(event, value) =>
                  EventAutoCompleteHandler(event, value, "staff")
                }
                required
                fullWidth
                options={staffs && staffs}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                renderInput={(params) => (
                  <TextField className="bg-color" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Others Staff</label>
              <TextField
                type="text"
                name="other_staff"
                value={healthData.other_staff}
                onChange={EventHandler}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Price</label>
              <TextField
                type="text"
                name="price"
                value={healthData.price}
                onChange={EventHandler}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <label>Description</label>
              <TextareaAutosize
                rows={4}
                placeholder="Empty"
                value={healthData.discription}
                name="discription"
                onChange={EventHandler}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>Start Date</label>
              <TextField
                type="datetime-local"
                name="start_date"
                onChange={EventHandler}
                value={healthData.start_date}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>End Date</label>
              <TextField
                type="datetime-local"
                name="end_date"
                onChange={EventHandler}
                value={healthData.end_date}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          {!Data ? (
            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },

                marginTop: "2rem",
                fontSize: "12px",
                marginInline: "0.5rem",
                backgroundColor: "#302924",
              }}
              onClick={CreateHandler}
            >
              CREATE
            </Button>
          ) : (
            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },

                marginInline: "0.5rem",
                marginTop: "2rem",
                fontSize: "12px",
                backgroundColor: "#302924",
              }}
              onClick={UpdateHandler}
            >
              UPDATE
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default HealthPlaner;
