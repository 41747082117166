import React, { useEffect, useState } from "react";
import "./Planer.css";
import {
  TextField,
  Button,
  Grid,
  Autocomplete,
  TextareaAutosize,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../Apis";
import { useCookies } from "react-cookie";
import { getCheckInData } from "../../App/Reducers/Avaiblity Slices/CheckinSlice";
import { getPlannerData } from "../../App/Reducers/PlannerReducer";
import { GetUsersMaster } from "../../App/Apis/UserApis";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import CommonLoading from "../../SharedComponets/Loading/CommonLoading";
import { toast } from "react-toastify";

const FoodPlaner = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const paramId = location.pathname.split("/")[4]
    ? location.pathname.split("/")[4]
    : null;
  const State = useSelector((state) => state.FoodPlaner);
  const masterPlannerData = useSelector((state) => state.Planner.plannerData);
  const horseCheckInData = useSelector((state) => state.checkin.checkinData);
  const [staffs, setStaffs] = useState(null);
  const [Data, setData] = useState(null);

  const now = new Date();
  const formattedDate = now.toISOString().slice(0, 16);
  const [plannerData, setPlannerData] = useState({
    id: null,
    horse_checkin: null,
    planner: null,
    staff: [],
    food_grazing: "",
    vitamins_nutrients: "",
    discription: "",
    remark: "",
    start_date: null,
    end_date: null,
  });

  const EventHandler = (e) => {
    const { name, value } = e.target;
    setPlannerData({ ...plannerData, [name]: value });
  };

  const EventAutoCompleteHandler = (e, value, fieldName) => {
    if (fieldName === "staff") {
      const oid = value !== null ? value.map((i) => i.id) : null;
      setPlannerData((prevFormData) => {
        return {
          ...prevFormData,
          [fieldName]: oid,
        };
      });
    } else {
      const selectedValue = value ? value.id : null;
      setPlannerData({ ...plannerData, [fieldName]: selectedValue });
    }
  };

  const CreateHandler = async (e) => {
    e.preventDefault();
    if (id) {
      setLoading(true);
      try {
        const response = await api.put(`update_planer/${id}`, plannerData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });

        if (response.statusText === "OK" || response.status === "200") {
          setLoading(false);
          navigate("/dashboard/planer/planer_list");
          toast.success("Planner updated successfully");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setLoading(true);
      try {
        const response = await api.post(`create_planer`, plannerData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });

        if (
          response.statusText === "OK" ||
          response.status === "200" ||
          response.statusText === "Created" ||
          response.status === "201"
        ) {
          setLoading(false);
          navigate("/dashboard/planer/planer_list");
          toast.success("Planner created successfully");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleGetUpdateId = async (id) => {
    setIsLoading(true);
    try {
      const res = await api.get(`get_planer/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      setPlannerData({
        id: res.data.id ? res.data.id : null,
        horse_checkin: res.data.horse_checkin.id
          ? res.data.horse_checkin.id
          : null,
        food_grazing: res.data.food_grazing ? res.data.food_grazing : null,
        vitamins_nutrients: res.data.vitamins_nutrients
          ? res.data.vitamins_nutrients
          : null,
        staff: res.data.staff ? res.data.staff.map((item) => item.id) : [],
        planner: res.data.planner.id ? res.data.planner.id : null,
        discription: res.data.discription ? res.data.discription : null,
        remark: res.data.remark ? res.data.remark : null,
        start_date: res.data.start_date
          ? new Date(res.data.start_date).toISOString().slice(0, -1)
          : "",
        end_date: res.data.end_date
          ? new Date(res.data.end_date).toISOString().slice(0, -1)
          : "",
      });
      setData(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getCheckInData(token));
    dispatch(getPlannerData(token));
    GetUsersMaster(token)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((error) => {});
  }, [token, users.hasOwnProperty()]);

  useEffect(() => {
    const id = location.pathname.split("/")[4];
    if (id) {
      handleGetUpdateId(id);
    }
  }, []);

  useEffect(() => {
    setStaffs(users);
  }, [State.status.Create, Data]);

  return isLoading ? (
    <StableLoading />
  ) : (
    <div className="form-wrapper-container">
      <div
        className="registration-card form-container"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <header>
          <div className="registration_top_header">
            <p>{id ? <p>Update Horse Planner</p> : <p>Horse Planner</p>}</p>
          </div>
        </header>

        <form onSubmit={CreateHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <label>
                Horse CheckIn <span style={{ color: "red" }}>*</span>
              </label>
              <Autocomplete
                id="combo-box-demo"
                name="horse_checkin"
                value={
                  horseCheckInData &&
                  plannerData.horse_checkin &&
                  horseCheckInData.find(
                    (item) => item.id === Number(plannerData.horse_checkin)
                  )
                }
                onChange={(event, value) =>
                  EventAutoCompleteHandler(event, value, "horse_checkin")
                }
                required
                fullWidth
                options={horseCheckInData && horseCheckInData}
                getOptionLabel={(option) => option.horse.name}
                renderInput={(params) => (
                  <TextField required className="bg-color" {...params} />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>
                Planner <span style={{ color: "red" }}>*</span>
              </label>
              <Autocomplete
                id="combo-box-demo"
                name="planner"
                value={
                  masterPlannerData &&
                  plannerData.horse_checkin &&
                  masterPlannerData.find(
                    (item) => item.id === Number(plannerData.planner)
                  )
                }
                onChange={(event, value) =>
                  EventAutoCompleteHandler(event, value, "planner")
                }
                required
                fullWidth
                options={masterPlannerData && masterPlannerData}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField required className="bg-color" {...params} />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>
                Food Grazing <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                type="text"
                name="food_grazing"
                onChange={EventHandler}
                value={plannerData.food_grazing}
                fullWidth
                required
                className="bg-color"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>
                Start Date <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                type="datetime-local"
                name="start_date"
                onChange={EventHandler}
                value={plannerData.start_date}
                fullWidth
                required
                className="bg-color"
                inputProps={{ min: paramId ? null : formattedDate }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>
                End Date <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                type="datetime-local"
                name="end_date"
                onChange={EventHandler}
                value={plannerData.end_date}
                fullWidth
                required
                className="bg-color"
                inputProps={{ min: paramId ? null : formattedDate }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <label>
                Vitamins Nutrients <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                type="text"
                name="vitamins_nutrients"
                onChange={EventHandler}
                value={plannerData.vitamins_nutrients}
                fullWidth
                required
                className="bg-color"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={plannerData.staff.length < 2 ? 4 : 12}
            >
              <label>
                Staff <span style={{ color: "red" }}>*</span>
              </label>
              {users ? (
                <Autocomplete
                  name="staff"
                  disablePortal
                  id="staff_f"
                  value={
                    users &&
                    plannerData.staff &&
                    users.filter((item, i) => {
                      return plannerData.staff.includes(item.id);
                    })
                  }
                  onChange={(event, value) =>
                    EventAutoCompleteHandler(event, value, "staff")
                  }
                  multiple
                  required
                  fullWidth
                  options={
                    users
                      ? users.map((item) => {
                          return {
                            id: item.id,
                            first_name: item.first_name,
                            last_name: item.last_name,
                            usettypestff: `(${item.usertype?.name})`,
                          };
                        })
                      : []
                  }
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name} ${option.usettypestff}`
                  }
                  renderInput={(params) => (
                    <TextField
                      required={plannerData.staff[0] ? false : true}
                      className="bg-color"
                      {...params}
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  name="staff"
                  disablePortal
                  id="staff_f"
                  onChange={(event, value) =>
                    EventAutoCompleteHandler(event, value, "staff")
                  }
                  multiple
                  required
                  fullWidth
                  options={
                    users
                      ? users.map((item) => {
                          return {
                            id: item.id,
                            first_name: item.first_name,
                            last_name: item.last_name,
                            usertype: `(${item.usertype})`,
                          };
                        })
                      : []
                  }
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name} ${option.usertype}`
                  }
                  renderInput={(params) => (
                    <TextField required className="bg-color" {...params} />
                  )}
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
            <label>Description</label>
            <TextareaAutosize
              // rows={4}
              minRows={6}
              // placeholder="Empty"
              value={plannerData.discription}
              name="discription"
              onChange={EventHandler}
              className="bg-color"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
            <label>Remark</label>
            <TextareaAutosize
              // rows={4}
              minRows={6}
              // placeholder="Empty"
              value={plannerData.remark}
              name="remark"
              onChange={EventHandler}
              className="bg-color"
            />
          </Grid>

          {/* Submit */}
       

          <div className="btn-submit" style={{ gap: "15px" }}>
            <Button
              type="submit"
              variant="contained"
              className="btn-bgColor"
              onClick={() => navigate("/dashboard/planer/planer_list")}
            >
              Cancel
            </Button>
            {loading ? (
              <CommonLoading />
            ) : (
              <Button type="submit" variant="contained" className="btn-bgColor">
                {id ? " Update" : "Create"}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FoodPlaner;
