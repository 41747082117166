import { createSlice } from "@reduxjs/toolkit";

const FlatRaceReducer = createSlice({
  name: "Para",
  initialState: [
    "The official start of flat races in the country was in 1983 at the track of the Sharjah Equestrian and Racing Club. The racetrack has witnessed several stages of development to keep pace with the global development in the horse racing tracks"
  ],
  reducers: {
    editPara(state, action) {
      state.push(action.payload);
    }
  }
});
export const { editPara } = FlatRaceReducer.actions;

export default FlatRaceReducer.reducer;
