
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from "../../Apis";
import { photoGallary } from '../../Assets/DefaultImagesCarousel';


export const getPhotoData = createAsyncThunk("getPhotoData", async (token) => {
    console.log(token)
    try {
        if (token) {
            const response = await api.get("gets_all_images", {
                headers: {
                    Authorization: `token ${token}`,
                },
            })
        
            return response.data
        } else {
            return null
        }
    } catch (error) {
        console.log("Photo Error", error)
    }
})

export const createPhotoData = createAsyncThunk("createPhotoData", async (payload) => {
    try {
        if (payload.token) {
            const response = await api.post("add_images", payload.formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data, application/json",
                        Authorization: `token ${payload.token}`,
                    }
                })
            console.log("create photo response", response.data)
            return response.data
        } else {
            return null
        }
    } catch (error) {
        console.log(error)
    }
})

export const deletePhotoData = createAsyncThunk("deletePhotoData", async ({id, token}) => {
    try {
        if(token){
            
        }
        const response = await api.delete(`delete_images/${id}`,{
            headers:{
                Authorization: `token ${token}`,
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const updatePhotoData = createAsyncThunk("updatePhotoData", async (photo) => {
    console.log("update photo", photo)
    try {
        console.log(photo)
        const response = await api.put(`update_images`, photo, {
            headers: {
                "Content-Type": "multipart/form-data, application/json"
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const photoGallarySlice = createSlice({
    name: 'photogallary',
    initialState: {
        status: null,
        photoGallary: photoGallary,
        error: null
    },
    reducers: {
        // Upload: (state, action) => {
        //     console.log('action.payload')
        //     console.log(action.payload)
        //     const NewId = state.photoGallaryData.length + 1
        //     const NewAlt = `Image ${NewId}`
        //     const NewImage = {
        //         id: NewId,
        //         src: action.payload.imgsrc,
        //         alt: NewAlt
        //     }
        //     state.photoGallaryData.push(NewImage)
        // },
        // Edit: (state, action) => {
        //     console.log(action.payload, "edit")
        //     //    const updatedItems = state.photoGallaryData.filter((obj) => obj.id === action.payload.id);
        //     state.photoGallaryData[action.payload.id].src = action.payload.imgsrc
        // },
        // Delete: (state, action) => {
        //     // console.log(action.payload + 1)
        //     const id = action.payload
        //     //   state.photoGallaryData.splice(id , 1)
        //     const updatedItems = state.photoGallaryData.filter((obj) => obj.id !== id);
        //     state.photoGallaryData = updatedItems
        //     console.log(updatedItems)
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(getPhotoData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(getPhotoData.fulfilled, (state, action) => {
            state.status = "succeded"
            state.photoGallary = action.payload
        })

        builder.addCase(getPhotoData.rejected, (state) => [
            state.status = "failed"
        ])
        builder.addCase(createPhotoData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(createPhotoData.fulfilled, (state) => {
            state.status = "succeded"
        })
        builder.addCase(createPhotoData.rejected, (state) => [
            state.status = "failed"
        ])
        builder.addCase(deletePhotoData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(deletePhotoData.fulfilled, (state) => {
            state.status = "succeded"

        })

        builder.addCase(deletePhotoData.rejected, (state) => [
            state.status = "failed"
        ])
        builder.addCase(updatePhotoData.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(updatePhotoData.fulfilled, (state) => {
            state.status = "succeded"

        })

        builder.addCase(updatePhotoData.rejected, (state) => [
            state.status = "failed"
        ])
    }
})

// Action creators are generated for each case reducer function

export default photoGallarySlice.reducer