import React, { useEffect, useState } from "react";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import {
  getStallData,
  deleteStallData,
  createStallData,
  getStallDataID,
  updateStallData,
} from "../../App/Reducers/Stable Slices/StallSlice";
// import "./CheckIn.css";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { BsFillBookmarkCheckFill } from "react-icons/bs";
import { Modal } from "@mui/material";
import { useCookies } from "react-cookie";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Autocomplete,
  TextareaAutosize,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import StableLoading from "../../SharedComponets/Loading/StableLoading";

const Stall = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  // const HorseDropDownSelector = useSelector(
  //   (state) => state.checkin.DropDownData.HorseData
  // );
  // const StaffDropDownSelector = useSelector(
  //   (state) => state.checkin.DropDownData.StaffData
  // );
  // //console.log(HorseDropDownSelector, "HorseDropDownSelector");
  const updateSelector = useSelector((state) => state.stall.getDataById);

  const [formData, setFormData] = useState({
    StallType: "",
    StallName: "",
    Price: "",
    Capacity: "",
  });
  const [formDataUpdate, setFormDataUpdate] = useState({
    Id: "",
    StallType: "",
    StallName: "",
    Price: "",
    Capacity: "",
  });
  const HandleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((preData) => {
      return { ...preData, [name]: value };
    });
  };
  const HandleUpdateChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormDataUpdate((preData) => {
      return { ...preData, [name]: value };
    });
  };
  const CreateHandlerStall = () => {
    // //console.log(formData, "CreateHandlerCheckin");
    dispatch(
      createStallData({
        token: token,
        data: {
          stall_type: formData.StallType,
          stall_name: formData.StallName,
          price: formData.Price,
          capacity: formData.Capacity,
        },
      })
    );
    setCreateModalToggler(false);
  };
  const UpdateHandlerCheckin = () => {
    dispatch(
      updateStallData({
        id: formDataUpdate.Id,
        token: token,

        UpdatedData: {
          stall_type: formDataUpdate.StallType,
          stall_name: formDataUpdate.StallName,
          price: formDataUpdate.Price,
          capacity: formDataUpdate.Capacity,
        },
      })
    );
    setUpdateModalToggler(false);
  };

  const dispatch = useDispatch();
  const StallApiData = useSelector((state) => state.stall.stallData);
  // //console.log(CheckInApiData, "CheckInApiData77777777777777777");
  //   Create Check in modal start
  const [createModalToggler, setCreateModalToggler] = useState(false);
  const [updateModalToggler, setUpdateModalToggler] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    height: "350px",
    overflow: "auto",
    p: 4,
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };
  //   Create Check in modal end

  const colHeader = [
    {
      Header: "Stall Type",

      accessor: "stall_type",
    },
    {
      Header: "Stall Name",

      accessor: "stall_name",
    },
    {
      Header: "Price",

      accessor: "price",
    },
    {
      Header: "Capacity",

      accessor: "capacity",
    },
    {
      Header: "Date",

      accessor: "created_date",
      Cell: ({ value }) => {
        return format(new Date(value), "dd/MM/yyyy");
      },
    },
  ];
  useEffect(() => {
    //console.log("hello");
    dispatch(getStallData(token));

    if (updateSelector) {
      setFormDataUpdate({
        Id: String(updateSelector.id),
        StallType: String(updateSelector.stall_type),
        StallName: String(updateSelector.stall_name),
        Price: String(updateSelector.price),
        Capacity: String(updateSelector.capacity),
      });
    }
  }, [dispatch, updateSelector, createModalToggler, updateModalToggler]);
  const handleUpdate = (id) => {
    //console.log(id);

    dispatch(getStallDataID({ id, token }));
    setUpdateModalToggler(true);
    // setTimeout(() => {
    //   setUpdateModalToggler(false);
    // }, 1000);
  };
  const handleDelete = (id) => {
    //console.log(id);
  };

  const closeModal = () => {
    setCreateModalToggler(false);
  };
  const closeUpdateModal = () => {
    setUpdateModalToggler(false);
  };
  const handleCreate = () => {
    setCreateModalToggler(true);
  };
  return (
    <div>
      {/* <button onClick={() => setCreateModalToggler(true)}>Check in</button> */}
      {StallApiData ? (
        <BasicTable
          colHeader={colHeader}
          rowData={StallApiData}
          updateHandler={handleUpdate}
          deleteHandler={handleDelete}
          createHandler={handleCreate}
          tableHeading="Stalls"
        />
      ) : (
        <StableLoading />
      )}

      {/* ==========================Create Check_in Modal=============================== */}
      <Modal
        open={createModalToggler}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll-bar">
          <Button
            sx={{
              color: "#302924",
              border: "0px",
              float: "right",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#e0e0e0", // Remove the border on hover
              },
            }}
          >
            <RxCross2
              style={{ color: "#302924", fontSize: "1.7rem" }}
              onClick={closeModal}
            />
          </Button>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: "#302924",
              marginBottom: "5vh",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            <BsFillBookmarkCheckFill
              style={{ fontSize: "15px", marginRight: "5px" }}
            />
            Check In
          </Typography>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              CreateHandlerStall();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} sx={{ fontFamily: "Poppins" }}>
                <FormControl sx={{ width: "100%" }}>
                  <label style={{ fontSize: "15px" }}>Stall Type</label>
                  <Select
                    name="StallType"
                    onChange={HandleInputChange}
                    value={formData.StallType}
                    required
                  >
                    <MenuItem value="Non Air Conditioned box">
                      Non-Air conditioned
                    </MenuItem>
                    <MenuItem value="Air Conditioned box">
                      Air Conditioned
                    </MenuItem>
                    <MenuItem value="Indoor box">Indoor box</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ fontFamily: "Poppins" }}>
                <label style={{ fontSize: "15px" }}>Stall Name</label>

                <TextField
                  type="text"
                  name="StallName"
                  value={formData.StallName}
                  onChange={HandleInputChange}
                  // onChange={EventHandler}
                  // value={foodData.vitamins_nutrients}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ fontFamily: "Poppins" }}>
                {/* <FormControl sx={{ width: "100%" }}>
                  <label style={{ fontSize: "15px" }}>Check In Type</label>
                  <Select
                    name="CheckInType"
                    value={formData.CheckInType}
                    onChange={HandleInputChange}
                  >
                    <MenuItem value="Temperory CheckIn">
                      Temperory Checkin
                    </MenuItem>
                    <MenuItem value="Permanent CheckIn">
                      Permanent Checkin
                    </MenuItem>
                  </Select>
                </FormControl> */}
                <label style={{ fontSize: "15px" }}>Price</label>
                <TextField
                  type="text"
                  name="Price"
                  value={formData.Price}
                  onChange={HandleInputChange}
                  // onChange={EventHandler}
                  // value={foodData.vitamins_nutrients}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ fontFamily: "Poppins" }}>
                <label style={{ fontSize: "15px" }}>Capacity</label>
                <TextField
                  type="text"
                  name="Capacity"
                  value={formData.Capacity}
                  onChange={HandleInputChange}
                  // onChange={EventHandler}
                  // value={foodData.vitamins_nutrients}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },

                marginTop: "2rem",
                fontSize: "12px",
                marginInline: "0.5rem",
                backgroundColor: "#302924",
              }}
              // onClick={CreateHandlerStall}
              type="submit"
            >
              CREATE
            </Button>
          </form>
        </Box>
      </Modal>
      {/* ==========================Create Check_in Modal=============================== */}
      {/* ==========================Update Check_in Modal=============================== */}
      {updateSelector && (
        <Modal
          open={updateModalToggler}
          onClose={closeUpdateModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="scroll-bar">
            <Button
              sx={{
                color: "#302924",
                border: "0px",
                float: "right",
                padding: "0px",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#e0e0e0", // Remove the border on hover
                },
              }}
            >
              <RxCross2
                style={{ color: "#302924", fontSize: "1.7rem" }}
                onClick={closeModal}
              />
            </Button>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                color: "#302924",
                marginBottom: "5vh",
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              <BsFillBookmarkCheckFill
                style={{ fontSize: "15px", marginRight: "5px" }}
              />
              Update
            </Typography>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                UpdateHandlerCheckin();
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} sx={{ fontFamily: "Poppins" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <label style={{ fontSize: "15px" }}>Horse Name</label>

                    <Select
                      name="StallType"
                      onChange={HandleUpdateChange}
                      value={formDataUpdate.StallType}
                      required
                    >
                      <MenuItem value="Non Air Conditioned box">
                        Non-Air conditioned
                      </MenuItem>
                      <MenuItem value="Air Conditioned box">
                        Air Conditioned
                      </MenuItem>
                      <MenuItem value="Indoor box">Indoor box</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{ fontFamily: "Poppins" }}>
                  <label style={{ fontSize: "15px" }}>Stall Name</label>

                  <TextField
                    type="text"
                    name="StallName"
                    value={formDataUpdate.StallName}
                    onChange={HandleUpdateChange}
                    // onChange={EventHandler}
                    // value={foodData.vitamins_nutrients}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{ fontFamily: "Poppins" }}>
                  <label style={{ fontSize: "15px" }}>Price</label>

                  <TextField
                    type="text"
                    name="Price"
                    value={formDataUpdate.Price}
                    onChange={HandleUpdateChange}
                    // onChange={EventHandler}
                    // value={foodData.vitamins_nutrients}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} sx={{ fontFamily: "Poppins" }}>
                  <label style={{ fontSize: "15px" }}>Capacity</label>
                  <TextField
                    type="text"
                    name="Capacity"
                    value={formDataUpdate.Capacity}
                    onChange={HandleUpdateChange}
                    // onChange={EventHandler}
                    // value={foodData.vitamins_nutrients}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Button
                variant="outlined"
                sx={{
                  color: "#fff",
                  borderColor: "#302924",
                  "&:hover": {
                    borderColor: "#302924",
                    color: "#302924",
                  },

                  marginTop: "2rem",
                  fontSize: "12px",
                  marginInline: "0.5rem",
                  backgroundColor: "#302924",
                }}
                // onClick={UpdateHandlerCheckin}
                type="submit"
              >
                UPDATE
              </Button>
            </form>
          </Box>
        </Modal>
      )}
      {/* ==========================Update Check_in Modal=============================== */}
    </div>
  );
};

export default Stall;
