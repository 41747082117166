import React, { useState, useEffect } from "react";
import "../Assets/animation.css";

const WarnMSG = ({ msg, animation }) => {
  const style = {
    container: {
      position: "absolute",
      top: "2rem",
      right: "2rem",
      width: "250px",
      color: "#ffffff",
      border: "1px solid #dc8585",
      padding: "20px",
      background: "#f6653c",
      borderRadius: "10px",
      borderLeft: "10px solid #881511",
      fontWeight: "bold",
      boxShadow: "1px 1px 6px #ffffff",
      zIndex: 200,
    },
  };

  return (
    <div style={style.container} className={animation}>
      {msg}
    </div>
  );
};

function WarningMessage({ warnMsg, delay }) {
  const [showComponent, setShowComponent] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(false);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>{showComponent && <WarnMSG msg={warnMsg} animation={"slide-left"} />}</>
  );
}

export default WarningMessage;
