
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Apis";
import { defaultImages } from "../../Assets/DefaultImagesCarousel";


export const uploadCarousel = createAsyncThunk("uploadCarousel", async ({ token, formData }) => {
  try {
    if (token) {
      const response = await api.post("upload_carousel", formData, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `token ${token}`
        }
      })
      console.log("response create", response.data);
      return response.data
    }
    else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
})
export const updateCarousel = createAsyncThunk("updateCarousel", async (payload) => {
  try {
    if (payload.token) {

      const response = await api.put(`update_carousel_id/${payload.id}`, payload.data, {
        headers: {
          Authorization: `token ${payload.token}`,
        }
      })
      return response.data
    } else {
      return null
    }
  }
  catch (error) {
    console.log(error)
  }

})

export const getCarouselById = createAsyncThunk("getCarouselById", async (payload) => {
  try {
    const response = await api.get(`get_carousel/${payload.id}`, {
      headers: {
        Authorization: `token ${payload.token}`,
      }
    })

    return response.data.carousel
  } catch (error) {
    console.log(error)
  }
})

export const getAllCarousel = createAsyncThunk("getAllCarousel", async (token) => {
  try {
    if (token) {
      const response = await api.get(`get_all_carousel`, {
        headers: {
          Authorization: `token ${token}`,
        }
      })
      return response.data
    } else {

      return null;
    }
  } catch (error) {
    console.log(error)
  }

})
export const deleteCarousel = createAsyncThunk("deleteCarousel", async ({ id, token }) => {
  try {
    if (token) {
      const response = await api.delete(`delete_carousel/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        }
      })
      return response.data
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  status: null,
  homecarouselApiData: null,
  homecarouselApiDataAll: defaultImages

};

export const homecarouselSlice = createSlice({
  name: "homecarousel",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(uploadCarousel.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(uploadCarousel.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.homecarouselApiData = action.payload
    })
    builder.addCase(uploadCarousel.rejected, (state) => {
      state.status = "failed"
    })


    builder.addCase(updateCarousel.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(updateCarousel.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.homecarouselApiData = action.payload
    })
    builder.addCase(updateCarousel.rejected, (state) => {
      state.status = "failed"
    })


    builder.addCase(getCarouselById.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getCarouselById.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.homecarouselApiData = action.payload
    })
    builder.addCase(getCarouselById.rejected, (state) => {
      state.status = "failed"
    })


    builder.addCase(getAllCarousel.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getAllCarousel.fulfilled, (state, action) => {
      state.status = "succeeded"
      state.homecarouselApiDataAll = action.payload
    })
    builder.addCase(getAllCarousel.rejected, (state) => {
      state.status = "failed"
    })


    builder.addCase(deleteCarousel.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(deleteCarousel.fulfilled, (state, action) => {
      state.status = "succeeded"
      // state.homecarouselApiData = action.payload
    })
    builder.addCase(deleteCarousel.rejected, (state) => {
      state.status = "failed"
    })

  }
});

// Action creators are generated for each case reducer function
export const { Upload, Edit, Delete } = homecarouselSlice.actions;

export default homecarouselSlice.reducer;
