import React from 'react'
import CommonEditablePage from '../../../SharedComponets/CommonEditablePage'
import { useSelector } from 'react-redux'
import { updateDiscipline, getDiscipline, createDiscipline } from '../../../App/Reducers/HeroQuillReducer'
import { discipline } from '../../../Assets/DefaultImagesCarousel'


const ArabianShows = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.discipline);

  const defaultContet = `
  Dressage sport is commonly known as ballet or dancing with horses. Modern dressage is derived from the natural movement of the horse and those they were taught in cavalry training.
  Riders are asked to perform a series of movements with their horses at levels of increasing difficulty. Each competitive dressage test has a prescribed design of movements in walk, trot, and canter. The movements are then scored 1-10 and turned into a percentage.
  Show Jumping
   
  In this sport, rider and horse teams compete to be the fastest around a course of jumps without knocking down any rails.
  There is a set time to complete the course, and riders gain penalty points if their ride takes longer than the optimum time.
  They also receive penalties for knocking rails down. The rider with the fastest time and the least time penalties wins.
  Cross Country
  In this timed event, rider and horse compete to be the fastest around an outdoor jumping course with 30 to 40 jumps. The course also includes ditches, banks, and more.
  It is designed to replicate the feeling of riding a horse at high speeds across the countryside and tests the skills and obedience of the horse.
  Eventing
  Also known as three-day eventing, this sport combines the three disciplines of cross country, show jumping, and dressage over three days of competition. The horse and rider with the lowest score or least amount of faults takes home the top prize.
  #5 Hunter Jumper
  This is a jumping class that focuses solely on the horse. It is mainly found in North America. The judges score the horse on the way they move, jump, their behavior, and their stride, among other things.
  Jumping rounds are scored on a scale from 0 to 100 and the horse & rider combination with the highest score is the winner.
  Equitation
  This is a jumping and flatwork competition where the focus of judging is on the rider instead of the horse.
  Riders are judged on their position over jumps and on the flat, decision-making while on the course, and the quietness of their movements, among other things.
  Similar to the hunter jumper ring, horse and rider teams are scored from 0 to 100 and the ones with the top score are the winners.
  #7 Saddle Seat
  In this discipline, gaited horses that have highly animated gaits, such as American Saddlebreds are ridden in a ring to show off their flashiness. (3)
  The horse and rider pair that is the best representative of the breed are the winners. Riders are judged on their attire and sportsmanship in the ring, as well as showmanship.
  #8 Polo
  In this sport, riders hit balls into goals using mallets on horseback. (4) Essentially, it is croquet on horseback.
  What is Saddle Seat Riding?
  What is Saddle Seat Riding? Saddle Seat riding is an English style of riding (as are Hunt Seat and Dressage), but it is a uniquely American style of riding used primarily on Morgan horses, American…`


  return (
    <>
      <CommonEditablePage
        updateApiFunc={updateDiscipline}
        createApiFunc={createDiscipline}
        getApiFunc={getDiscipline}
        modalSelector={modalSelector}
        default_image_title= {discipline.image_title}
        default_image={discipline.image}
        default_body_content= {defaultContet}
      />
    </>
  )
}

export default ArabianShows