import { createSlice } from "@reduxjs/toolkit";

const TitleReducer = createSlice({
  name: "Title",
  initialState: [],
  reducers: {
    editTitle(state, action) {},
  },
});
export const { editTitle } = TitleReducer.actions;

export default TitleReducer.reducer;
