// import BasicDateCalendar from './DateCalendar';
// import { GoArrowRight } from "react-icons/go";
// import { CiCalendar } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { FiArrowRight } from "react-icons/fi";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./rideview.css";
import Select from "react-select";
// import PriceFilter from "./PriceFilter";
// import CourseCard from "./CourseCard";
import { rideOptions } from "./rideOptions";
import courses from "../../../App/Apis/RidingSchoolApis/courseApi/course";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loader from "../../components/Loader";
import defaultImg from "../../../Assets/RidingSchoolAssets/eqi5default.jpg";

const RideView = () => {
  // const [calendar, setCalendar] = useState(false)
  // const [filterOpen, setFilterOpen] = useState(false)

  //   const options = [
  //     { value: "chocolate", label: "Chocolate" },
  //     { value: "strawberry", label: "Strawberry" },
  //     { value: "vanilla", label: "Vanilla" },
  //   ];
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const select__style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: "auto",
      borderColor: "hsl(0, 0%, 100%)",
      borderRadius: "32px",
      borderStyle: "solid",
      borderWidth: "1px",
      boxShadow: "none",
      minHeight: "38px",
      outline: "none !important",
      position: "relative",
      boxSizing: "border-box",
      border: "1px solid #E6E6E6",
      padding: "7px 8px",
      zIndex: "1",
    }),
  };

  const getALLCompanies = async () => {
    setIsLoading(true);
    try {
      const res = await courses.getAllCompanies();
      setOptions(
        res.data.map((item) => {
          return {
            label: item.company_name,
            value: item.id,
          };
        })
      );
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getALLCompanies();
  }, []);

  const riding_school = selectedOption
    ? options.find((item) => item.value === selectedOption)
    : null;

  // course card data start here
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [pages, setPages] = useState(10);

  // console.log("data is data new data", data)

  const getCourses = async () => {
    setIsLoading(true);
    try {
      const res = await courses.getAllCoursesPagination();
      setData(res.data.results);
      setPages(res.data.count);
      setNext(res.data.next);
      setPrev(res.data.previous);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const selectPageHandler = async (selectedPage, search, price) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(pages / size) &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
      setIsLoading(true);
      try {
        const res = await courses.pagination(selectedPage, query, price);
        setData(res?.data?.results);
        setPages(res?.data?.count);
        setNext(res?.data?.next);
        setPrev(res?.data?.previous);
      } catch (error) {
        console.log("error", error);
      }
      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (selectedPage === 1) {
      setIsLoading(true);
      try {
        const res = await courses.pagination(selectedPage, search, price);
        setData(res?.data?.results);
        setPages(res?.data?.count);
        setNext(res?.data?.next);
        setPrev(res?.data?.previous);
      } catch (error) {
        console.log("error", error);
      }
      setIsLoading(false);
    }
  };

  const paginate = ({ current, max }) => {
    if (!current || !max) return null;

    let prev = current === 1 ? null : current - 1,
      next = current === max ? null : current + 1,
      items = [1];

    if (current === 1 && max === 1) return { current, prev, next, items };
    if (current > 4) items.push("…");

    let r = 2,
      r1 = current - r,
      r2 = current + r;

    for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i++) items.push(i);

    if (r2 + 1 < max) items.push("…");
    if (r2 < max) items.push(max);
    // console.log(current, prev, next, items);
    return { current, prev, next, items };
  };

  const handleSearch = async (value) => {
    setIsLoading(true);
    if (value) {
      setQuery(value);
      try {
        const res = await courses.pagination(1, query);
        setData(res?.data?.results);
        setPages(res?.data?.count ? res?.data?.count : 2);
        setNext(res?.data?.next);
        setPrev(res?.data?.previous);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setQuery(null);
      getCourses();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (riding_school) {
      setQuery(riding_school?.label);
      selectPageHandler(1, riding_school?.label);
    } else {
      setQuery("");
      getCourses();
    }
  }, [riding_school]);
  // course card data ends here

  // price filter data starts here
  const [price, setPrice] = useState(0);
  const [priceRange, setPriceRange] = useState({
    maxRange: "",
    minRange: "",
  });

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleMouse = async () => {
    try {
      const res = await courses.pagination(page === 1 ? page : 1, query, price);
      setData(res.data?.results);
      setPages(res.data?.count);
      setNext(res.data?.next);
      setPrev(res.data?.previous);
    } catch (err) {
      console.log(err);
    }
  };

  const minMax = async () => {
    try {
      const res = await courses.minMaxPrice();
      setPriceRange((prev) => ({
        ...prev,
        minRange: res.data?.min_price,
        maxRange: res.data?.max_price,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = async () => {
    setIsLoading(true);
    try {
      const res = await courses.pagination(1, "", "");
      setPage(1);
      setData(res.data?.results);
      setPages(res.data?.count);
      setNext(res.data?.next);
      setPrev(res.data?.previous);
      setQuery("");
      setSelectedOption(null);
      setPrice(priceRange.maxRange);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(true);
  };

  useEffect(() => {
    minMax();
  }, []);

  const [hovered, setHovered] = useState(false);

  // price filter data ends here

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <header className="rideView__header">
      <main className="rideView__left__main">
        <span>Filters</span>

        <section className="rideView__left__section1">
          <div>
            <span>Select School</span>
            <Select
              isClearable={true}
              options={options}
              styles={select__style}
              value={
                options &&
                selectedOption &&
                options.find((item) => item.value === selectedOption)
              }
              onChange={(e) => setSelectedOption(e ? e?.value : null)}
            />
          </div>
        </section>

        <section className="rideView__left__section2">
          <div>
            <span>Price Range</span>

            <div className="rideView__priceFilter">
              {/* <PriceFilter query={query} page={page} /> */}
              <div className="price__filter">
                {price ? (
                  <span
                    className="price_hover_tag"
                    style={{
                      display: !hovered ? "none" : "inline",
                      position: "absolute",
                      bottom: "3.5rem",
                      left: `${(price * 100) / priceRange.maxRange}%`,
                    }}
                  >
                    {" "}
                    {price}{" "}
                  </span>
                ) : null}
                <input
                  type="range"
                  min={priceRange.minRange}
                  max={priceRange.maxRange}
                  value={price ? price : priceRange.maxRange}
                  onChange={handlePriceChange}
                  className="price__slider"
                  onMouseUp={handleMouse}
                  onMouseOver={() => setHovered(true)}
                  onMouseOut={() => setHovered(false)}
                />
                <div className="price__indicator">
                  <span>Min {priceRange.minRange}</span>
                  {/* <span>SAR</span> */}
                  <span>Max {priceRange.maxRange}</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <p className="rideView__clearFilter" onClick={handleReset}>
          Clear All Filters
        </p>
      </main>

      <main className="rideView__right__main">
        <section>
          {/* <RideOptionsCard /> */}
          {/* <CourseCard
            riding_school={
              selectedOption
                ? options.find((item) => item.value === selectedOption)
                : null
            }
            SelectedOptionFunc={SelectedOptionFunc}
          /> */}

          <header className="ridesView__card__container">
            <section className="rideView__right__topSection">
              <div className="rideView__right__info1">
                <div className="rideView__inputs">
                  <span>
                    <IoSearch />
                  </span>
                  <input
                    type="search"
                    value={query}
                    placeholder="Search Riding Schools"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>
              </div>
            </section>
            <span>Total results ({pages}) </span>

            {data.length > 0 ? (
              <section className="rideView__right__bottomSection">
                {data.map((item, index) => (
                  <NavLink
                    className="ridesOptionCard__header"
                    to={`/course-details/${item.id}`}
                  >
                    <main className="ridesOptionCard__main" key={item.id}>
                      <div className="ridesOptionCard__information">
                        {/* <img src={item?.image} alt="cardimg" loading="lazy" /> */}

                        <LazyLoadImage
                          key={item.id}
                          src={item?.image ? item.image : defaultImg}
                          alt="productImage"
                          height={200}
                          width={320}
                          effect="blur"
                          placeholderSrc={item?.image}
                          style={{ objectFit: "cover" }}
                        />

                        <aside>
                          <span>{item?.company.company_name}</span>
                          <p>
                            {item?.lessons} Lessons - {item?.category} -{" "}
                            {item?.sub_category}{" "}
                          </p>
                          <div className="ridesOptionCard__bottom__details">
                            <span className="ridesOptionCard__description">
                              {item?.course_name}
                            </span>
                            {/* <p>{item?.lessons} Lessons</p> */}
                            <span>
                              {item?.price.toLocaleString()}&nbsp;
                              {item?.currency}{" "}
                            </span>
                            {/* <span className="ridesOptionCard__description">
                          {item?.description}
                        </span> */}
                          </div>
                        </aside>
                        <div className="ridersOptionCard__bookNow">
                          <button>Book Now</button>
                          <span>
                            <MdKeyboardArrowRight />
                          </span>
                        </div>
                      </div>
                      <section className="pagination"></section>
                    </main>
                  </NavLink>
                ))}
              </section>
            ) : (
              <div className="filtered__containerData">
                <h1>Sorry, we could not find what you were looking for.</h1>
                <span>Try adjusting the dates or removing the filters</span>
                {/* <button onClick={handleRemoveFilters}>Remove all filters</button> */}
              </div>
            )}

            <section className="pagination">
              <span
                className={page > 1 ? "" : "pagination__disable"}
                onClick={() => selectPageHandler(page - 1)}
              >
                <GoArrowLeft />
              </span>
              <div>
                {paginate({
                  current: page,
                  max: Math.ceil(pages / size),
                })?.items?.map((item, index) => (
                  <button
                    className={
                      page === item
                        ? "pagination__selected"
                        : "pagination__notActive"
                    }
                    onClick={() => selectPageHandler(item)}
                    key={index}
                  >
                    {" "}
                    {item}{" "}
                  </button>
                ))}
              </div>
              <span
                className={
                  page < Math.ceil(pages / size) ? "" : "pagination__disable"
                }
                onClick={() => selectPageHandler(page + 1)}
              >
                <FiArrowRight />
              </span>
            </section>
          </header>
        </section>
        {/* {calendar ? <BasicDateCalendar /> : null} */}
      </main>
    </header>
  );
};

export default RideView;

const RideOptionsCard = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState(rideOptions);
  const [filteredData, setFilteredData] = useState(data);
  const [query, setQuery] = useState("");
  const keys = ["title", "description", "price", "currency"];

  useEffect(() => {
    const filtered = data.filter((option) =>
      keys.some((key) =>
        key === "price" && typeof option[key] === "number"
          ? option[key].toString().includes(query?.toLowerCase())
          : option[key]?.toLowerCase().includes(query?.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [query, data]);

  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= data.length / 10 &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  function paginate({ current, max }) {
    if (!current || !max) return null;

    let prev = current === 1 ? null : current - 1,
      next = current === max ? null : current + 1,
      items = [1];

    if (current === 1 && max === 1) return { current, prev, next, items };
    if (current > 4) items.push("…");

    let r = 2,
      r1 = current - r,
      r2 = current + r;

    for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i++) items.push(i);

    if (r2 + 1 < max) items.push("…");
    if (r2 < max) items.push(max);

    return { current, prev, next, items };
  }

  const handleRemoveFilters = () => {
    setQuery("");
  };

  return (
    <header className="ridesView__card__container">
      <section className="rideView__right__topSection">
        <div className="rideView__right__info1">
          <div className="rideView__inputs">
            <span>
              <IoSearch />
            </span>
            <input
              type="text"
              value={query}
              placeholder="Search Riding Schools"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          {/* <div className='rideView__dates__container'>
                            <div className='rideView__dates'>
                                <span style={{ fontSize: "1.3rem" }}><CiCalendar /></span>
                                <span onClick={() => setCalendar(!calendar)}>Dates</span>
                            </div>
                            <button>Go <span><GoArrowRight /></span></button>
                        </div> */}
        </div>
        {/* <div className='rideView__right__info2__container'>
                        <div className='rideView__right__info2'>
                            <span>Sort by</span>
                            <button onClick={() => setFilterOpen(!filterOpen)}>Featured</button>
                        </div>
                        {filterOpen ?
                            <div className='rideView__right__filter'>
                                <ul>
                                    <li>Featured</li>
                                    <li>Popularity</li>
                                    <li>Newest</li>
                                    <li>Rating</li>
                                </ul>
                            </div>
                            : null}
                    </div> */}
      </section>
      <span>Total results ({filteredData.length}) </span>
      {filteredData.length > 0 ? (
        <section className="rideView__right__bottomSection">
          {filteredData.slice(page * 10 - 10, page * 10).map((ride) => (
            <NavLink className="ridesOptionCard__header" to={"/ridingdetails"}>
              <main className="ridesOptionCard__main" key={ride.id}>
                <div className="ridesOptionCard__information">
                  <img src={ride.img.card1} alt="cardimg" />
                  <aside>
                    <span>{ride.title}</span>
                    <div className="ridesOptionCard__bottom__details">
                      <span className="ridesOptionCard__description">
                        {ride.description}
                      </span>
                      <p>{ride.startFrom}</p>
                      <span>
                        {ride.price.toLocaleString()}&nbsp;{ride.currency}{" "}
                      </span>
                    </div>
                  </aside>
                  <div className="ridersOptionCard__bookNow">
                    <button>Book Now</button>
                    <span>
                      <MdKeyboardArrowRight />
                    </span>
                  </div>
                </div>

                <section className="pagination"></section>
              </main>
            </NavLink>
          ))}
        </section>
      ) : (
        <div className="filtered__containerData">
          <h1>Sorry, we could not find what you were looking for.</h1>
          <span>Try adjusting the dates or removing the filters</span>
          <button onClick={handleRemoveFilters}>Remove all filters</button>
        </div>
      )}

      {filteredData?.length > 0 && (
        <section className="pagination">
          <span
            className={page > 1 ? "" : "pagination__disable"}
            onClick={() => selectPageHandler(page - 1)}
          >
            <GoArrowLeft />
          </span>
          <div>
            {paginate({
              current: page,
              max: Math.ceil(filteredData.length / 10),
            }).items.map((item, index) => (
              <button
                className={
                  page === item
                    ? "pagination__selected"
                    : "pagination__notActive"
                }
                onClick={() => selectPageHandler(item)}
                key={index}
              >
                {" "}
                {item}{" "}
              </button>
            ))}
          </div>
          <span
            className={
              page < Math.ceil(filteredData.length / 10)
                ? ""
                : "pagination__disable"
            }
            onClick={() => selectPageHandler(page + 1)}
          >
            <FiArrowRight />
          </span>
        </section>
      )}
    </header>
  );
};
