// import React, { useState, useEffect } from "react";
// import HeroImage from "../../../SharedComponets/HeroImage";
// import ArticalSection from "../../../SharedComponets/ArticalSection";
// import LiveryContents from "./LiveryContents";
// import Button from "@mui/material/Button";
// import ReactQuill from "react-quill";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import TextField from "@mui/material/TextField";
// import Modal from "@mui/material/Modal";
// import { data } from "../../../SharedComponets/MainContent";
// import { useSelector } from "react-redux";

// function HorseLivery() {
//   const selector = useSelector((state) => state.loginStatus.adminloggedIn);
//   const ImageUrlLink =
//     "https://www.serc.ae/en/imageviewer.ashx?i=10&f=information_en";
//     // "https://content.knightfrank.com/property/vir180025/images/4163aa05-aa83-41c5-88f8-d289325e21ad-0.jpg?cio=true&w=1200"
//   // const [backgroundImageUrl, setBackgroundImageUrl] = useState(urlImgLink);
//   // const handleEditImage = () => {
//   //   const TempImage = prompt("Enter Image Url");
//   //   setBackgroundImageUrl(TempImage);
//   // };
//   // const [title, setTitle] = useState("Livery");
//   // const [titlelimitWarn, setTitlelimitWarn] = useState(false);
//   // const handleEditTitle = () => {
//   //   const editedTitle = prompt("Enter Title:");
//   //   if (editedTitle) {
//   //     if (editedTitle.length < 118) {
//   //       setTitle(editedTitle);
//   //     } else {
//   //       setTitlelimitWarn(true);
//   //     }
//   //   }
//   // };

//   // ========================================Article Section Editable Start===========================================
//   const [value, setValue] = useState(data.horselivery);
//   const [paraEditToggle, setParaToggle] = useState(false);
//   const handleParaEdidor = () => {
//     setParaToggle(!paraEditToggle);
//     console.log(paraEditToggle, "paraEditToggle");
//   };
//   const toolbarOptions = {
//     toolbar: [
//       ["bold", "italic", "underline", "strike"], // toggled buttons
//       ["code-block"],

//       [{ header: 1 }], // custom button values
//       [{ list: "ordered" }, { list: "bullet" }],
//       [{ script: "sub" }, { script: "super" }], // superscript/subscript

//       [{ header: [1, 2, 3, 4, 5, 6, false] }],

//       [{ color: [] }, { background: [] }], // dropdown with defaults from theme
//     ],
//   };

//   // Variables and functions Headings Modal
//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     backgroundColor: "#e0e0e0",
//     border: "5px solid #bebb9e",
//     borderRadius: "10px",
//     boxShadow: 24,
//     p: 4,
//     "@media (max-width: 576px)": {
//       width: "90%",
//     },
//   };
//   const [open, setOpen] = useState(false);
//   const handleOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   const [articalHeading, setArticalHeading] = useState(
//     ":: Disciplines :: Horse Livery"
//   );
//   const [articalSubHeading, setArticalSubHeading] = useState("HORSE LIVERY");
//   const [articalHeadingMain, setArticalHeadingMain] = useState(
//     ":: Disciplines ::  Horse Livery"
//   );
//   const [articalSubHeadingMain, setArticalSubHeadingMain] =
//     useState("HORSE LIVERY");

//   const handleHeadingsModal = () => {
//     setArticalHeadingMain(articalHeading);
//     setArticalSubHeadingMain(articalSubHeading);
//     setOpen(false);
//   };
//   const headerLimit = 30 - articalHeading.length;
//   const subheaderLimit = 20 - articalSubHeading.length;
//   const [headerLimitexceed, setHeaderLimitexceed] = useState(false);
//   const [subheaderLimitexceed, setSubheaderLimitexceed] = useState(false);

//   useEffect(() => {
//     const headerLimit = 30 - articalHeading.length;
//     const subheaderLimit = 20 - articalSubHeading.length;

//     if (headerLimit <= 10) {
//       setHeaderLimitexceed(true);
//     } else {
//       setHeaderLimitexceed(false);
//     }

//     if (subheaderLimit <= 5) {
//       setSubheaderLimitexceed(true);
//     } else {
//       setSubheaderLimitexceed(false);
//     }
//   }, [articalHeading, articalSubHeading]);

//   // ...

//   // Variables and functions Headings Hero Modal
//   const [openHero, setOpenHero] = useState(false);
//   const handleOpenHero = () => {
//     setOpenHero(true);
//   };
//   const handleCloseHero = () => {
//     setOpenHero(false);
//   };

//   const [heroHeading, setHeroHeading] = useState("Livery");
//   const [heroImage, setHeroImage] = useState(ImageUrlLink);
//   const [heroHeadingMain, setHeroHeadingMain] = useState("Livery");
//   const [heroImageMain, setHeroImageMain] = useState(ImageUrlLink);
//   const handleHeroImageModal = () => {
//     setHeroHeadingMain(heroHeading);
//     setHeroImageMain(heroImage);
//     setOpenHero(false);
//   };
//   const heroHeadingLimit = 120 - heroHeading.length;

//   return (
//     <>
//       <div style={{ position: "relative" }}>
//         {/* -----------------Hero image Section Start----------------- */}
//         <div style={{ position: "relative" }}>
//           <HeroImage text={heroHeadingMain} imageUrl={heroImageMain} />
//           <div
//             style={{
//               position: "absolute",
//               bottom: "10px",
//               right: "20px",
//               zIndex: "1000",
//             }}
//           >
//             {/* <button onClick={handleEditTitle}>Edit Title</button> */}

//             {selector && (
//               <Button
//                 variant="outlined"
//                 sx={{
//                   color: "#bebb9e",
//                   borderColor: "#e4e0bd",
//                   "&:hover": {
//                     borderColor: "white",
//                   },
//                   margin: "10px 5px",
//                   width: "180px",
//                   fontSize: "12px",
//                 }}
//                 onClick={handleOpenHero}
//               >
//                 Edit Header Section
//               </Button>
//             )}
//           </div>
//         </div>

//         {/* -----------------Hero image Section End----------------- */}
//         {/* -----------------Artical Editor Section Start----------------- */}

//         <div
//           className={
//             paraEditToggle
//               ? "Article-Editor Article-Editor-show"
//               : "Article-Editor Article-Editor-hide"
//           }
//         >
//           <div className="Article-Editor-maindiv">
//             <button
//               style={{
//                 color: "black",
//                 float: "right",
//                 border: "none",
//                 backgroundColor: "#e0e0e0",
//                 padding: "5px",
//               }}
//               onClick={() => setParaToggle(false)}
//             >
//               <i class="fa fa-close" />
//             </button>
//             <ReactQuill
//               theme="snow"
//               // modules={toolbarOptions}
//               value={value}
//               onChange={setValue}
//             />
//           </div>
//         </div>
//         {/* -----------------Artical Editor Section End----------------- */}

//         {/* -----------------Artical Section Start----------------- */}

//         <div className="aboutus-articalsection">

//           <ArticalSection
//             // mainheading={articalHeadingMain}
//             subheading={articalSubHeadingMain}
//             func2 ={handleOpen}
//             func1 = {handleParaEdidor}
//             // bodyComponent={<AboutComp />} //Uncomment for more features
//             bodyComponent={<LiveryContents value={`${value}`} />}
//           />
//         </div>
//         {/* -----------------Artical Section End----------------- */}

//         {/* ----------------------------- MODAL FOR ARTICAL SECTION START--------------------------------------- */}

//         <Modal
//           open={open}
//           onClose={handleClose}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <Box sx={style}>
//             <Button
//               variant="outlined"
//               sx={{
//                 color: "#302924",
//                 border: "0px",
//                 "&:hover": {
//                   border: "0px",
//                   backgroundColor: "#e0e0e0",
//                   color: "black",
//                 },

//                 float: "right",
//                 padding: "0px",
//                 justifyContent: "right",
//                 fontSize: "18px",
//               }}
//               onClick={() => setOpen(false)}
//             >
//               <i class="fa fa-close" />
//             </Button>

//             <Typography
//               id="modal-modal-title"
//               variant="h6"
//               component="h2"
//               sx={{ color: "#1c1816", marginBottom: "5vh" }}
//             >
//              UPDATE HEADING
//             </Typography>
//             {/* <TextField
//               id="outlined-basic"
//               label="Edit Heading"
//               variant="outlined"
//               value={articalHeading.slice(0, 29)}
//               onChange={(e) => setArticalHeading(e.target.value)}
//               sx={{
//                 width: "98%",
//                 margin: "10px",
//                 "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//                   borderColor: "rgb(190, 187, 158) !important",
//                 },
//                 "& input": {
//                   color: "#302924",
//                 },
//               }}
//             />
//             {headerLimitexceed ? (
//               <span style={{ color: "red" }}>
//                 You have only {headerLimit} characters remaining{" "}
//               </span>
//             ) : (
//               <span>You have {headerLimit} characters remaining </span>
//             )} */}

//             <TextField
//               id="outlined-basic"
//               label="Edit Sub Heading"
//               variant="outlined"
//               value={articalSubHeading.slice(0, 19)}
//               onChange={(e) => setArticalSubHeading(e.target.value)}
//               sx={{
//                 width: "98%",
//                 margin: "10px",
//                 "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//                   borderColor: "rgb(190, 187, 158) !important",
//                 },
//                 margin: "10px",
//                 "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//                   borderColor: "rgb(190, 187, 158) !important",
//                 },
//                 "& input": {
//                   color: "#302924", // Replace "blue" with your desired text color
//                 },
//               }}
//             />
//             {subheaderLimitexceed ? (
//               <span style={{ color: "red" }}>
//                 You have only {subheaderLimit} characters remaining{" "}
//               </span>
//             ) : (
//               <span>You have {subheaderLimit} characters remaining </span>
//             )}
//             <Button
//               variant="outlined"
//               sx={{
//                 color: "#302924",
//                 borderColor: "#302924",
//                 "&:hover": {
//                   borderColor: "#302924",
//                 },
//                 marginTop: "15px",
//                 float: "right",

//                 fontSize: "12px",
//               }}
//               onClick={handleHeadingsModal}
//             >
//               SAVE
//             </Button>
//           </Box>
//         </Modal>
//         {/* ----------------------------- MODAL FOR ARTICAL SECTION END --------------------------------------- */}
//         {/* -------------------------------Modal for hero Section -------------------------------- */}
//         <Modal
//           open={openHero}
//           onClose={handleCloseHero}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <Box sx={style}>
//             <Button
//               variant="outlined"
//               sx={{
//                 color: "#302924",
//                 border: "0px",
//                 "&:hover": {
//                   border: "0px",
//                   backgroundColor: "#e0e0e0",
//                   color: "black",
//                 },

//                 float: "right",
//                 padding: "0px",
//                 justifyContent: "right",
//                 fontSize: "18px",
//               }}
//               onClick={() => setOpenHero(false)}
//             >
//               <i class="fa fa-close" />
//             </Button>
//             <Typography
//               id="modal-modal-title"
//               variant="h6"
//               component="h2"
//               sx={{ color: "#1c1816", marginBottom: "5vh" }}
//             >
//               EDIT HEADER AND IMAGE
//             </Typography>
//             <TextField
//               id="outlined-basic"
//               label="Edit Heading"
//               variant="outlined"
//               value={heroHeading.slice(0, 119)}
//               onChange={(e) => setHeroHeading(e.target.value)}
//               sx={{
//                 width: "98%",
//                 margin: "10px",
//                 "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//                   borderColor: "rgb(190, 187, 158) !important",
//                 },
//                 "& input": {
//                   color: "#302924", // Replace "blue" with your desired text color
//                 },
//               }}
//             />
//             <span>You have {heroHeadingLimit} characters remaining </span>
//             <TextField
//               id="outlined-basic"
//               label="Edit Sub Heading"
//               variant="outlined"
//               value={heroImage}
//               onChange={(e) => setHeroImage(e.target.value)}
//               sx={{
//                 width: "98%",
//                 margin: "10px",
//                 "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//                   borderColor: "rgb(190, 187, 158) !important",
//                 },
//                 margin: "10px",
//                 "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//                   borderColor: "rgb(190, 187, 158) !important",
//                 },
//                 "& input": {
//                   color: "#302924",
//                 },
//               }}
//             />
//             <span>Please Uplaod a Vaild URL Link </span>
//             <Button
//               variant="outlined"
//               sx={{
//                 color: "#302924",
//                 borderColor: "#302924",
//                 "&:hover": {
//                   borderColor: "#302924",
//                 },
//                 marginTop: "15px",
//                 float: "right",
//                 // width: "80px",
//                 fontSize: "12px",
//               }}
//               onClick={handleHeroImageModal}
//             >
//               SAVE
//             </Button>
//           </Box>
//         </Modal>
//       </div>
//     </>
//   );
// }

// export default HorseLivery;

import React from "react";
import CommonEditablePage from "../../../SharedComponets/CommonEditablePage";
import { useSelector } from "react-redux";
import {
  createHorseLivery,
  getHorselivery,
  updateHorselivery,
} from "../../../App/Reducers/HeroQuillReducer";
import { horseLivery } from "../../../Assets/DefaultImagesCarousel";

const AboutUs = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.horselivery);
  const defaultContent = `Horse livery is the term given to a fully managed yard, where horse owners pay a premium for all their horses needs to be met, including feeding, grooming, mucking out and exercising as necessary. The yard staff are responsible for the horses welfare at all times, with the owner having free access to their horse.This “livery” also meant the food, shelter, etc., given to horses, which is where “livery stables” (where food, grooming, etc., is included in the fee) got their name. A “livery cab” was originally a horse-drawn cab that was available to the public for hire.`
  return (
    <>
      <CommonEditablePage
        updateApiFunc={updateHorselivery}
        getApiFunc={getHorselivery}
        createApiFunc = {createHorseLivery}
        modalSelector={modalSelector}
        default_image_title="Horse Livery"
        default_image={horseLivery.image}
        default_body_content={defaultContent}
      />
    </>
  );
};

export default AboutUs;
