import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import InteractionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { useEffect, useState } from 'react';
import moment from 'moment';
import './calendar.css'
import { RxCrossCircled } from "react-icons/rx";

// a custom render function
function renderEventContent1(eventInfo) {
  console.log('eventInfo', eventInfo);
  const { Lesson, course } = JSON.parse(eventInfo.event.title)

  return (
    <>
      {/* <b>{eventInfo.timeText}</b> */}
      <div>
        <h3>{course}</h3>
        <i>Lesson:{Lesson}</i>

      </div>

    </>
  )
}



const renderEventContent = (events) => {
  return (
    <>
      <div style={{ cursor: 'pointer' }}>More <sup>+{events.event.title}</sup></div>
    </>
  );

};

function Calender({ Heading, Events }) {


  const [currentView, setCurrentView] = useState('');
  const [modalEvents, setModalEvents] = useState([]);

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const handleViewChange = (viewInfo) => {
    setCurrentView(viewInfo.view.type);
  };
  const handleEventClick = (e) => {
    console.log("Event Clicked", e);
    const startDate = e.event.start;
    console.log("startDate", startDate);
    const filteredEvents = Events.filter((item) => {
      const startDate = moment.utc(item.start).startOf('day'); // Extract the UTC start date without time
      const targetDate = moment.utc(startDate).format("YYYY-MM-DD"); // Format the target date

      return moment.utc(startDate).isSame(moment.utc(targetDate)); // Compare if the UTC start date matches the target date
    });
    console.log('handleEventClick', filteredEvents);
    setModalEvents(filteredEvents)
    handleOpen()
  }


  let moreEvent = []

  if (Events && Events.length) {
    const duplicateEvents = [...Events]
    const countEventsByStartDate = (events) => {
      const result = duplicateEvents.reduce((acc, event) => {
        // Parse the start date string to a Date object
        const startDate = new Date(event.start);

        const formattedStartDate = moment.utc(startDate).format("YYYY-MM-DD")
        // console.log('formattedStartDate', formattedStartDate);
        // console.log('acc', acc);

        if (acc[formattedStartDate]) {
          acc[formattedStartDate].title++;
        } else {
          acc[formattedStartDate] = { title: 1, start: formattedStartDate };
        }
        return acc;
      }, {});

      return Object.values(result);
    };

    console.log('More Event', countEventsByStartDate(Events));
    moreEvent = countEventsByStartDate(Events)
  }


  const customViews = {
    dayGrid: {
      // Options apply to dayGridMonth, dayGridWeek, and dayGridDay views
      eventOverlap: false, // For example, disable event overlap for dayGrid views

    },
    timeGrid: {
      // Options apply to timeGridWeek and timeGridDay views
      // Add specific options for timeGrid views if needed
      events: Events,
      eventContent: renderEventContent1
    },
    week: {
      // Options apply to dayGridWeek and timeGridWeek views
      // Add specific options for week view if needed

    },
    day: {
      // Options apply to dayGridDay and timeGridDay views
      // Add specific options for day view if needed
      events: Events,
      eventContent: renderEventContent1
    },
    list: {
      // Options apply to dayGridDay and timeGridDay views
      // Add specific options for day view if needed
      events: Events,
      eventContent: renderEventContent1
    },


  };

  const [modalEventData, setModalEventData] = useState([])

  useEffect(() => {
    const parsedEventData = modalEvents.map(item => JSON.parse(item.title));
    setModalEventData(parsedEventData);
  }, [modalEvents])



  return (
    <>
      <div>
        <h1>{Heading}</h1>

        <FullCalendar
          height={'auto'}

          plugins={[
            dayGridPlugin,
            InteractionPlugin,
            timeGridPlugin,
            listPlugin,
          ]}
          initialView='dayGridMonth'
          headerToolbar={{
            start: "today prev,next",
            center: "title",
            end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
          }}
          weekends={true}
          events={currentView === 'dayGridMonth' ? moreEvent : Events}
          eventClick={(e) => handleEventClick(e)}
          dateClick={() => { console.log("Date Clicked"); }}
          // eventContent={renderEventContent}
          eventContent={renderEventContent}
          views={customViews}
          viewDidMount={handleViewChange}
          viewWillUnmount={() => setCurrentView('')}
        />
      </div>

      <div>
        {open && (
          <div className='student__calendar__wrapper'>
            <div className='student__calendar'>
              <h2 style={{margin : "0 auto",width:'100%',textAlign:'center',paddingBlock:'.2rem', color : "white", background : "#9f8653"}}>Schedule Courses</h2>
              <span className='close__modal_icons' onClick={() => setOpen(false)}><RxCrossCircled style={{backgroundColor : "red", fontSize : "1.8rem", color : "white", borderRadius : "50%"}}/></span>
              {modalEventData.map((item, i) => (
                <>
                  <span className='student__calendar__details'> <strong>Course: </strong> {item.course} </span>
                  <span className='student__calendar__details'> <strong>Lessons: </strong> {item.Lesson} </span>
                </>
              ))}
            </div>
          </div>
        )}
      </div>

    </>
  )
}




export default Calender