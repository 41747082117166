import React, { useEffect, useState, useMemo } from "react";
import "./login.css";
import { useDispatch, useSelector } from "react-redux";
import { AdminLogin } from "../../App/Reducers/LoginReducer";
import { useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import "react-toastify/dist/ReactToastify.css";
import LoginPageLeftContainer from "./LoginPageLeftContainer";
import AlertMessage from "../../App/Helpers/AlertMessage";
import CommonLoading from '../../SharedComponets/Loading/CommonLoading'

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["token", "email", "password"]);
  const [, , removeCookie] = useCookies(["email", "password"]);
  const isloggedIn = useSelector((state) => state.loginStatus.adminloggedIn);
  const Token_val = useSelector((state) => state.loginStatus.token) || cookies.token;
  const Status = useSelector((state) => state.loginStatus.status);
  const [isLoginVisibility, setIsLoginVisibility] = useState(false)
  const [isLoginMessage, setIsLoginMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState({
    email: cookies.email || "",
    password: cookies.password || "",
  });

  const [isremeberMe, setIsRemeberMe] = useState(
    false || JSON.parse(localStorage.getItem("isRemeberMe"))
  );

  const [isValidEmail, setIsValidEmail] = useState("");
  const [isValidPassword, setIsValidPassword] = useState();
  const [message, setMessage] = useState("")

  const FormValidation = () => {
    const email = "/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i";
    if (!user.email || !email.test(user.email)) {
      setIsValidEmail(false);
    }
    if (!user.password || !user.password.length < 9) {
      setIsValidPassword(false);
    }
    if (isValidEmail && isValidPassword) {
      return true;
    }
    return false;
  };

  const handleLogin = (e) => {
    e.preventDefault();

    localStorage.setItem("isRemeberMe", isremeberMe);
    if (isremeberMe) {
      setCookie("email", user.email, { path: "/" });
      setCookie("password", user.password, { path: "/" });
    } else {
      removeCookie("email", { path: "/" });
      removeCookie("password", { path: "/" });
    }
    if (FormValidation) {
      dispatch(AdminLogin(user));
    }
  };

  const handleSetToken = (val) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);
    setCookie('token', val, { path: '/', expires: expirationDate });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    handleSetToken(Token_val);
    if (isloggedIn || Status === "success") {
      setUser({
        email: "",
        password: "",
      });
      setIsLoading(false)
      navigate(`/dashboard`);
      window.location.reload()
    }
    else if (isloggedIn || Status === "failed") {
      setUser({
        email: "",
        password: "",
      });
      setIsLoginVisibility(true)
      setIsLoginMessage("Invalid email or password !")

      setTimeout(() => {
        setIsLoginVisibility(false);
      }, 3000);

      navigate(`/login`);
    }
    else {
      navigate('/login')
    }
  }, [Status, isloggedIn]);

  return (
    <>
      <div className="login-section login-page">

        <div style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}>
          <AlertMessage message={isLoginMessage} isVisibila={isLoginVisibility} severity="error" />
        </div>
        <LoginPageLeftContainer />

        <div className="login-right-container">
          <div className="login-module-container">

            <div className="login-portal-container">
              <h2 style={{ color: "#826426" }}>PORTAL</h2>
              <p style={{ color: "#222222", fontSize: "12.5px", fontWeight: "bold" }}>PLEASE LOGIN YOUR ACCOUNT BELOW:</p>
            </div>

            <div className="container">
              <form className="login-form" onSubmit={handleLogin}>
                <div className="form-group">
                  <label className="form-label" htmlFor="username">
                    EMAIL ADDRESS
                  </label>
                  <input
                    value={user.email}
                    name="email"
                    onChange={(e) => {
                      setUser({ ...user, [e.target.name]: e.target.value });
                    }}
                    className="input-field login-input-field"
                    id="username"
                    type="text"
                    placeholder="Enter Your Email"
                    required
                  />
                </div>
                <div className="form-group password-input-container">
                  <label className="form-label" htmlFor="password">
                    PASSWORD
                  </label>
                  <input
                    value={user.password}
                    name="password"
                    onChange={(e) => {
                      setUser({ ...user, [e.target.name]: e.target.value });
                    }}
                    className="input-field login-input-field"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    required
                  />
                  <i
                    className={`password-toggle-icon  ${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
                    style={{ marginBlock: "0.8rem" }}
                    onClick={handlePasswordToggle}
                  ></i>
                  <p style={{ color: "red", fontWeight: "bold", fontSize: "0.9rem", marginTop: "0.5rem", lineSpacing: "0.5rem", wordSpacing: "0.1rem" }}>{message ? message : null}</p>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px", paddingBlock: "20px" }}>
                  <input type="checkbox" onChange={(e) => setIsRemeberMe(e.target.checked)} checked={isremeberMe} />
                  <label style={{ paddingBottom: "3px" }}>REMEMBER ME</label>
                </div>
                {isLoading ? <CommonLoading /> : null}
                <button className="login-button" type="submit">Login </button>
              </form>
              <div style={{ marginTop: "0.5rem", display: "flex", justifyContent: "space-between" }} className="login__footer">
                <Link to="/forget_password" style={{ color: "#bebb9e", fontSize: "0.95rem" }}> Forget Password ?</Link>
                <Link to="/register" style={{ color: "#836628", fontSize: "0.95rem", fontWeight: "bold", }}>Create <span style={{ textTransform: "lowercase" }}>an account</span></Link>
              </div>
            </div>

            <Link to={'/student-login'} className="login_as_student">Login as a student</Link>
          </div>
        </div>

      </div >


      {/* Mobile View */}
      {/* <div className="login-right-container-mobile">
        <div >

          <div>
            <h2 style={{ color: "#826426" }}>PORTAL</h2>
            <p style={{ color: "#222222", fontSize: "12.5px", fontWeight: "bold" }}>PLEASE LOGIN YOUR ACCOUNT BELOW:</p>
          </div>

          <div>
            <form className="login-form" onSubmit={handleLogin}>
              <div className="form-group">
                <label className="form-label" htmlFor="username">
                  EMAIL ADDRESS
                </label>
                <input
                  value={user.email}
                  name="email"
                  onChange={(e) => {
                    setUser({ ...user, [e.target.name]: e.target.value });
                  }}
                  className="input-field login-input-field"
                  id="username"
                  type="text"
                  placeholder="Enter Your Email"
                  required
                />
              </div>
              <div className="form-group password-input-container">
                <label className="form-label" htmlFor="password">
                  PASSWORD
                </label>
                <input
                  value={user.password}
                  name="password"
                  onChange={(e) => {
                    setUser({ ...user, [e.target.name]: e.target.value });
                  }}
                  className="input-field login-input-field"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  required
                />
                <i
                  className={`password-toggle-icon  ${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
                  style={{ marginBlock: "0.8rem" }}
                  onClick={handlePasswordToggle}
                ></i>
                <p style={{ color: "red", fontWeight: "bold", fontSize: "0.9rem", marginTop: "0.5rem", lineSpacing: "0.5rem", wordSpacing: "0.1rem" }}>{message ? message : null}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px", paddingBlock: "20px" }}>
                <input type="checkbox" onChange={(e) => setIsRemeberMe(e.target.checked)} checked={isremeberMe} />
                <label style={{ paddingBottom: "3px" }}>REMEMBER ME</label>
              </div>
              {isLoading ? <CommonLoading /> : null}
              <button className="login-button" type="submit">Login </button>
            </form>
            <div style={{ marginTop: "0.5rem", display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "10px" }}>
              <Link to="/forget_password" style={{ color: "#836628", fontSize: "0.95rem", fontWeight: "bold", }}> Forget Password ?</Link>
              <Link to="/register" style={{ color: "#836628", fontSize: "0.95rem", fontWeight: "bold", }}>Create <span style={{ textTransform: "lowercase" }}>an account</span></Link>
            </div>
          </div>

        </div>
      </div> */}
    </>
  );
}

export default Login;
