import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Apis";
export const AdminRegistration = createAsyncThunk("AdminRegistration", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`create_company`,
      data,
      {
        headers: {
          "content-type": "application/json",

        }
      })


    return response.data
    // return { data: response.data, status: response.status, statusText: response.statusText }
  }
  catch (error) {
    return rejectWithValue(error.response.data)
  }
})
export const UserRegistration = createAsyncThunk("UserRegistration", async (data, { rejectWithValue }) => {

  try {
    const response = await api.post(`create_user`,
      data,
      {
        headers: {
          "content-type": "multipart/form-data application/json",

        }
      })
  
    return response.data
  }
  catch (error) {
    return rejectWithValue(error.response.data)
  }
})
const Registration = createSlice({
  name: "RegistrationStatus",
  initialState: {
    adminloggedIn: false,
    adminstatus: null,
    userstatus: null,
    RegistrationCredentials: null,
  },
  reducers: {
    Toggle(state, action) {
      state.adminloggedIn = !state.adminloggedIn;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(AdminRegistration.rejected, (state, action) => {
        state.adminstatus = "failed"
       
      })
      .addCase(AdminRegistration.pending, (state, action) => {
        state.adminstatus = "loading"
      
      })
      .addCase(AdminRegistration.fulfilled, (state, action) => {
        state.adminstatus = "success"
      
      })
      .addCase(UserRegistration.rejected, (state, action) => {
        state.status = "failed"
        
      })
      .addCase(UserRegistration.pending, (state, action) => {
        state.status = "loading"
      
      })
      .addCase(UserRegistration.fulfilled, (state, action) => {
        state.userstatus = "success"
       
      })
  }
});
export const { Toggle, SignIn } = Registration.actions;
export default Registration.reducer;
