import React, { useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

const StudentProtectedRoute = ({ Component, Layout }) => {

    const navigate = useNavigate();

    const isloggedIn = localStorage.getItem("isStudent") ? JSON.parse(localStorage.getItem("isStudent")) : false;

    useEffect(() => {
        if (!isloggedIn && navigate !== '/student-login') {
            navigate('/student-login');
        }
    }, [isloggedIn, navigate]);


    return (
        <>
            <Component Layout={Layout} />
        </>
    )
}

export default StudentProtectedRoute


const StudentProtecteAuth = ({ isAuthenticatedStudent, isAdminloggedIn }) => {

    if (isAuthenticatedStudent) {
        return <Navigate to={"/student-dashboard"} />;
    }
    else if (isAdminloggedIn) {
        return <Navigate to={'/dashboard'} />
    }

    return <Outlet />;


}


export { StudentProtecteAuth };

const ProtectedLoginRoute = ({ isAuthenticatedStudent }) => {

    if (isAuthenticatedStudent) {
        return <Navigate to={"/student-dashboard"} />;
    }


    return <Outlet />;
}

export { ProtectedLoginRoute }