

import React from "react";
import CommonEditablePage from "../../SharedComponets/CommonEditablePage";
import { useSelector } from "react-redux";
import {
  createServices,
  getServices,
  updateServices,
} from "../../App/Reducers/HeroQuillReducer";
import { services } from "../../Assets/DefaultImagesCarousel";

const AboutUs = () => {
  const modalSelector = useSelector((state) => state.HeroQuill.services);

  const defaultContent = `Whether you list or go into detail, you need to make sure you let potential customers know what services you offer. Sit down with your staff and best clients and make a list of what you have, what they enjoy and what some of their favorite amenities are at your facility. That might include:
  Full board , Partial board ,Pasture board (owner care) ,Hay included in board , Individual paddocks , Large fields for turnout , Run-in sheds, Riding paths , Access to public trails or lands , Indoor and/or outdoor wash stalls ,Indoor and/or outdoor arenas, Lights on outdoor arenas, Trailer parking , Certified instructors ,Lesson horses , Horses for lease, Type of riding instruction offered ,Obstacles, jumps, dressage letters, mirrors, cattle, etc. amenities in arenas
 If you are a trainer or offer riding lessons, your website can promote your own farm or the services you provide at one or more facilities. These services might include.These services might include:
 Certifications (including CHA or PATH Intl.) with explanations of what those certifications mean , Types of lessons (beginner, intermediate, advanced, individual, group, travel to owner’s farm for lesson, etc.) ,Disciplines (working cow horse, western dressage, jumping, obstacle, etc.), Facilities (either your own or those owned by others where you teach) ,Amenities (board, leasing, cattle, obstacles, jumps, cross country course, etc.) ,Training horses (initial breaking/training, discipline).`

  return (
    <>
      <CommonEditablePage
        updateApiFunc={updateServices}
        getApiFunc={getServices}
        createApiFunc={createServices}
        modalSelector={modalSelector}
        default_image_title={services.image_title}
        default_image={services.image}
        default_body_content={defaultContent}
      />
    </>
  );
};

export default AboutUs;
