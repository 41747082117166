import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  getBreedData,
  createBreedData,
  updateBreedData,
  deleteBreedData,
  getupdateBreedData,
} from "../../App/Reducers/AdminSettingReducer/BreedReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import ModalComp from "./Modal";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import DeleteModal from "../../App/Helpers/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Apis";
import AlertMessage from "../../App/Helpers/AlertMessage";

const Breed = () => {
  const dispatch = useDispatch();
  const BreedData = useSelector((state) => state.breed.breedData);
  const [modalOpen, setModalOpen] = useState(false);
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addBreedMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_breedmaster"
      )
    : false;
  const changeBreedMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_breedmaster"
      )
    : false;
  const deleteBreedMasterPermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_breedmaster"
      )
    : false;

  const openModal = () => {
    setModalData({
      name: "",
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [modalData, setModalData] = useState({
    name: "",
  });

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createOrUpdateHandler = () => {
    if (modalData.id) {
      dispatch(
        updateBreedData({
          id: modalData.id,
          updatedData: {
            name: modalData.name,
          },
          token,
        })
      );
    } else {
      dispatch(createBreedData({ modalData, token }));
    }
    closeModal();
    dispatch(getBreedData(token));
  };

  const editHandler = (id) => {
    const editData = BreedData.find((data) => data.id === id);
    if (editData) {
      setModalData(editData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = async () => {
    try {
      const response = await api.delete(`delete_breed/${deleteId}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      if (response.status === "200" || response.statusText === "OK") {
        setDeleteModalOpen(false);
        toast.success("Delete successfully");
        dispatch(getBreedData(token));
      }
    } catch (error) {
      if (error.response.data.Error) {
        setIsErrorVisible(true);
        setIsErrorResponse(error.response.data.Error);
      }

      setTimeout(() => {
        setIsErrorVisible(false);
      }, 6000);
    }
  };

  useEffect(() => {
    dispatch(getBreedData(token));
  }, [dispatch, token, modalOpen]);

  const header = [
    {
      Header: "Breed Name",
      accessor: "name",
    },
  ];

  return (
    <>
      <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>
      {BreedData ? (
        <BasicTable
          colHeader={header}
          rowData={BreedData}
          actionFlag={
            deleteBreedMasterPermission ||
            changeBreedMasterPermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeBreedMasterPermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteBreedMasterPermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addBreedMasterPermission || isUserType ? openModal : null
          }
          tableHeading="Breed "
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            deleteBreedMasterPermission ||
            changeBreedMasterPermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeBreedMasterPermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteBreedMasterPermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addBreedMasterPermission || isUserType ? openModal : null
          }
          tableHeading="Breed "
        />
      )}

      <ModalComp
        modalOpen={modalOpen}
        handleModalInputChange={handleModalInputChange}
        createOrUpdateHandler={createOrUpdateHandler}
        openModal={openModal}
        closeModal={closeModal}
        modalData={modalData}
        label="ADD BREED"
        heading="Breed"
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Breed"
        deleteData={deleteData}
      />
      <ToastContainer />
    </>
  );
};
export default Breed;
