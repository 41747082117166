
import video1 from '../Assets/videos/video1.mp4'
import video2 from '../Assets/videos/video2.mp4'
import video3 from '../Assets/videos/video3.mp4'
import video4 from '../Assets/videos/video4.mp4'


import hCarousel1 from '../Assets/Images/HomeCarousel/carousel1.jpg'
import hCarousel2 from '../Assets/Images/HomeCarousel/carousel2.jpg'
import hCarousel3 from '../Assets/Images/HomeCarousel/carousel3.jpg'
import hCarousel4 from '../Assets/Images/HomeCarousel/carousel4.jpg'




export const defaultImages = [
  {
    id: 1,
    image: hCarousel1,
    image_title: "Carousel image first"
  },
  {
    id: 2,
    image: hCarousel2,
    image_title: "Carousel image second"
  },
  {
    id: 1,
    image: hCarousel3,
    image_title: "Carousel image third"
  },
  {
    id: 1,
    image: hCarousel4,
    image_title: "Carousel image fourth"
  },
]

export const partnersData = [
  {
    image: "https://serc.ae/en/imageviewer.ashx?i=17&f=sponsor",
    image_title: 1,
  },
  {
    image: "https://serc.ae/en/imageviewer.ashx?i=20&f=sponsor",
    image_title: 2,
  },
  {
    image: "https://serc.ae/en/imageviewer.ashx?i=19&f=sponsor",
    image_title: 3,
  },
  {
    image: "https://serc.ae/en/imageviewer.ashx?i=21&f=sponsor",
    image_title: 4,
  },
  {
    image: "https://serc.ae/en/imageviewer.ashx?i=23&f=sponsor",
    image_title: 5,
  },

  {
    image: "https://serc.ae/en/imageviewer.ashx?i=24&f=sponsor",
    image_title: 7,
  },
  {
    image: "https://serc.ae/en/imageviewer.ashx?i=25&f=sponsor",
    image_title: 8,
  },


]

export const pageNavigator = [
  {
    img: "https://wallpaperaccess.com/full/5497357.jpg",
    lable: "Discipline",
    alt: "Discipline img",
    url: "/disciplines",
  },
  {
    img: hCarousel1,
    lable: "Flat Race",
    alt: "Flat Race img",
    url: "/disciplines/flat-race",
  },
  {
    img: "https://www.azcentral.com/gcdn/-mm-/1ce4c7b9bfd2a7eac8e4ff541693ed5383f61d83/c=0-11-4044-2296/local/-/media/2018/02/15/Phoenix/Phoenix/636543192894918051-Scottsdale-Arabian-Horse-Show-13.jpg",
    lable: "Arabian shows",

    alt: "Arabian shows img",
    url: "/disciplines/arabian-shows",
  },
  {
    img: "https://www.balmoralshow.co.uk/media/2034/balmoral21-135.jpg",
    lable: "Show jumping",

    alt: "show jumping img",
    url: "/disciplines/show-jumping",
  },
  {
    img: "https://www.thejockeyclub.co.uk/contentassets/e00e2e9cd7df4f18afa6e167c6943937/pa-67746629-1.jpg",
    lable: "Services",

    alt: "Services img",
    url: "/e-commerce",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7gsXrp13ps6ddflEMLlk0YZsrdxHXnCR67QPlZYOnQr2muBysvOijVbPbHqUwRB0VjkM&usqp=CAU",
    lable: "Horse livery",
    alt: "Horse livery img",
    url: "/e-commerce/horse-livery",
  },
  {
    img: "https://i.pinimg.com/originals/cf/c2/3a/cfc23a0059d52aab4d28c5eeb03d74fb.jpg",
    lable: "Quarantine",
    alt: "Quarantine img",
    url: "/e-commerce/quarantine",
  },
  {
    img: "https://thumbs.dreamstime.com/b/horse-vehicle-carriage-horses-auto-trailer-transportation-horses-transportation-livestock-horse-transportation-van-175235572.jpg",
    lable: "Transportation",
    alt: "Transportation img",
    url: "/e-commerce/transportation",
  },
  {
    img: "https://media.istockphoto.com/id/533500197/photo/curious-horses-in-indoors-stall-at-stables.jpg?s=612x612&w=0&k=20&c=-f8ZMf-KC-GDGGKDS4pKhClOHraIDw9S0bncwCf_rns=",
    lable: "Stables rent",
    alt: "Stables rent img",
    url: "/e-commerce/stables-rent",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6-eL4J86-69NCcR94ca1BwiPCQYlL4-aXaAThKc9S1TUYdHx0UhrR6p7XLTaHnTyvuU0&usqp=CAU",
    lable: "Riding school",
    alt: "Riding school img",
    url: "/riding-school",
  },
]

export const photoGallary = [
  {
    id: 1,
    image: hCarousel1,
  },
  {
    id: 2,
    image: hCarousel2,
  },
  {
    id: 3,
    image: hCarousel3,
  },
  {
    id: 4,
    image: hCarousel4,
  }
]

export const videoGallary = [
  {
    id: 1,
    video: video1,
  },
  {
    id: 2,
    video: video2,
  },
  {
    id: 3,
    video: video3,
  },
  {
    id: 4,
    video: video4,
  }

]

export const discipline = { image_title: "Discipline", image: "https://wallpaperaccess.com/full/5497357.jpg"}
export const flatRace = { image_title: "Flat Race", image: "https://getwallpapers.com/wallpaper/full/3/5/a/1025722-horse-racing-wallpaper-2560x1600-1080p.jpg"}
export const arabianShow = { image_title: "Arabian Show", image: "https://www.azcentral.com/gcdn/-mm-/1ce4c7b9bfd2a7eac8e4ff541693ed5383f61d83/c=0-11-4044-2296/local/-/media/2018/02/15/Phoenix/Phoenix/636543192894918051-Scottsdale-Arabian-Horse-Show-13.jpg" }
export const showJumping = { image_title: "Show Jumping", image: "https://www.balmoralshow.co.uk/media/2034/balmoral21-135.jpg" }
export const services = { image_title: "Services", image: "https://th.bing.com/th/id/R.a6c0a2529bafa4d1e612304495fb7ea7?rik=%2bd9a3KtU%2fG6fSA&riu=http%3a%2f%2fyesofcorsa.com%2fwp-content%2fuploads%2f2017%2f12%2f4K-Horses-Desktop-Wallpaper.jpg&ehk=H%2fcclohIWgcboCbxG1NEbIpUSotSA56vd9APFgpfvoA%3d&risl=&pid=ImgRaw&r=0" }
export const horseLivery = { image_title: "Horse Livery", image: "https://th.bing.com/th/id/R.19a86822b5314d91109b677d13000fc4?rik=yjHMHAy5OYE8kQ&riu=http%3a%2f%2fyesofcorsa.com%2fwp-content%2fuploads%2f2017%2f12%2f4K-Horses-Desktop-Wallpaper-HD.jpg&ehk=9YdqnTMoChyrPXCpG26w3R1GHkKJPnPPvRyLzCP0zDw%3d&risl=&pid=ImgRaw&r=0" }
export const stableRent = { image_title: "Stable Rent", image: "https://media.istockphoto.com/photos/white-horse-in-stable-picture-id157394741?k=6&m=157394741&s=612x612&w=0&h=foRjnImEwNjPE0Zy1mJnIMLIFoDJy88mzJJKKUt7TW8=" }
export const quarantine = { image_title: "Quarantine", image: "https://i.pinimg.com/originals/cf/c2/3a/cfc23a0059d52aab4d28c5eeb03d74fb.jpg" }
export const transportation = { image_title: "Transportation", image: "https://thumbs.dreamstime.com/b/horse-vehicle-carriage-horses-auto-trailer-transportation-horses-transportation-livestock-horse-transportation-van-175235572.jpg" }
export const ridingSchool = { image_title: "Riding School", image: "https://img1.wsimg.com/isteam/stock/26187/:/cr=t:0%25,l:0%25,w:100%25,h:64.44%25/rs=w:600,h:300,cg:true" }
export const contactUs = { image_title: "Contact Us", image: "https://blog.hdwallsource.com/wp-content/uploads/2015/05/beautiful-horse-reflection-hd-wallpaper-59329-61114-hd-wallpapers.jpg" }
export const about = { image_title: "About", image: "https://vetericyn.com/Vetericyn/wp-content/uploads/2021/03/Horse-herd-run-in-sunlightwith-dust-at-summer-pasture.jpg" }