import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { RxCross2 } from "react-icons/rx";
import { useCookies } from "react-cookie";
import { Modal } from "@mui/material";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  createServiceData,
  deleteServiceData,
  getServiceData,
  updateServiceData,
} from "../../App/Reducers/ServiceReducer";
import StableLoading from "../../SharedComponets/Loading/StableLoading";
import { getPlannerData } from "../../App/Reducers/PlannerReducer";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { getData } from "../../App/Reducers/FacilityTypeReducer";
import api from "../../Apis";
import numeral from "numeral";
import DeleteModal from "../../App/Helpers/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../App/Helpers/AlertMessage";

const ServiceList = () => {
  const dispatch = useDispatch();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [ deleteId, setDeleteId] = useState(null)
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(null);

  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;
  const permissions = JSON.parse(localStorage.getItem("userData"));
  const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
  const addStableServicePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "add_stableservice"
      )
    : false;
  const changeStableServicePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "change_stableservice"
      )
    : false;
  const deleteStableServicePermission = permissions?.user_permissions
    ? permissions.user_permissions.some(
        (obj) => obj.codename === "delete_stableservice"
      )
    : false;

  let facilityServiceType;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "60%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e0e0e0",
    border: "5px solid #bebb9e",
    borderRadius: "10px",
    height: "fit-content",
    overflow: "auto",
    p: 4,
    "@media (max-width: 576px)": {
      width: "90%",
    },
  };

  const serviceData = useSelector((state) => state.services.serviceData);
  const facilityData = useSelector((state) => state.facilityTypes.facilityData);
  const plannerData = useSelector((state) => state.Planner.plannerData);

  const [modalOpen, setModalOpen] = useState(false);
  const [ErrorValue, setErrorValue] = useState(false);
  const [service, setServiceData] = useState([]);
  const [modalData, setModalData] = useState({
    facility: null,
    service: null,
    planner: null,
    price: null,
  });

  const openModal = () => {
    dispatch(getData(token));
    dispatch(getPlannerData(token));
    setModalData({
      facility: null,
      service: null,
      planner: null,
      price: null,
      unit_of_measurement: null,
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getService = async (id, token) => {
    try {
      const response = await api.get(`facility_service_type/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleAutoComplete = async (event, value, fieldName) => {
    console.log(value);
    if (value === null) {
      const selectedValue = value === null ? null : value.id;
      setModalData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: selectedValue,
      }));
    } else {
      if (fieldName === "facility") {
        console.log("fieldName", fieldName);
        facilityServiceType = await getService(value.id, token);
        console.log("facilityServiceType", facilityServiceType);
        const sdata = facilityServiceType.map((item) => {
          return { id: item.id, name: item.name };
        });
        setServiceData(() => sdata);
        console.log("service", service);
        const selectedValue = value === null ? null : value.id;
        setModalData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: selectedValue,
        }));
        console.log(modalData);
      } else {
        const selectedValue = value === null ? null : value.id;
        setModalData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: selectedValue,
        }));
      }
    }
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    if (!/^\d*$/.test(value) && modalData.price !== "") {
      setErrorValue(true);
      return;
    } else {
      setErrorValue(false);
      setModalData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const createOrUpdateHandler = (e) => {
    e.preventDefault();

    if (modalData.id) {
      if (
        modalData.facility !== null &&
        modalData.facility !== "" &&
        modalData.facility !== "undefined" &&
        modalData.planner !== null &&
        modalData.planner !== "" &&
        modalData.planner !== "undefined" &&
        modalData.price !== null &&
        modalData.service !== null &&
        modalData.service !== "" &&
        modalData.service !== "undefined"
      ) {
        dispatch(
          updateServiceData({
            id: modalData.id,
            updatedData: modalData,

            token,
          })
        );
      } else {
        return;
      }
    } else {
      if (
        modalData.facility !== null &&
        modalData.planner !== null &&
        modalData.price !== null &&
        modalData.service !== null
      ) {
        dispatch(createServiceData({ modalData, token }));
      } else {
        return;
      }
    }
    closeModal();
    dispatch(getServiceData(token));
  };

  const editHandler = async (id) => {
    dispatch(getData(token));
    dispatch(getPlannerData(token));
    console.log("id", id);
    const editData = serviceData.find((data) => data.id === id);
    let getUpdateData = {
      id: editData.id,
      facility: editData.facility.id,
      service: editData.service.id,
      planner: editData.planner.id,
      price: editData.price,
    };
    if (editData.facility.id) {
      facilityServiceType = await getService(editData.facility.id, token);
      console.log("facilityServiceType", facilityServiceType);
      const sdata = facilityServiceType.map((item) => {
        return { id: item.id, name: item.name };
      });
      console.log("sdata", sdata);
      setServiceData(() => sdata);
    }
    console.log("serviceData", getUpdateData);
    if (getUpdateData) {
      setModalData(getUpdateData);
      setModalOpen(true);
    }
  };

  const deleteHandler = (id) => {
    setDeleteId(id)
    setDeleteModalOpen(true)
  };

  const deleteData = async() =>{
    if(deleteId){
        try {
            const response = await api.delete(`delete_service/${deleteId}`,{
                headers:{
                    Authorization : `token ${token}`
                }
            })
            if(response.status === "200" || response.statusText === "OK"){
                setDeleteModalOpen(false)
                toast.success("Delete successfully")
                dispatch(getServiceData(token))
            }
        }  catch (error) {
          if (error.response.data.Error) {
            setIsErrorVisible(true);
            setIsErrorResponse(error.response.data.Error);
          }
  
          setTimeout(() => {
            setIsErrorVisible(false);
          }, 6000);
        }
    }
  }

  useEffect(() => {
    dispatch(getServiceData(token));
  }, [dispatch, token, modalOpen, service]);

  const NumberFormater = (amount) => {
    return numeral(amount).format("0,0");
  };

  const header = [
    {
      Header: "Facility Type",
      accessor: "facility.name",
    },
    {
      Header: "Service Type",
      accessor: "service.name",
    },
    {
      Header: "Planner",
      accessor: "planner.name",
    },
    {
      Header: "Price",
      accessor: "price",
      style: { textAlign: "right" },
      Cell: ({ value }) => NumberFormater(value),
    },
    {
      Header: "Unit of Measurment",
      accessor: "unit_of_measurement",
    },
  ];

  return (
    <>
     <div
        style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
      >
        <AlertMessage
          message={isErrorResponse}
          isVisibila={isErrorVisible}
          severity="error"
        />
      </div>

      {serviceData ? (
        <BasicTable
          colHeader={header}
          rowData={serviceData}
          actionFlag={
            deleteStableServicePermission ||
            changeStableServicePermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeStableServicePermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteStableServicePermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addStableServicePermission || isUserType ? openModal : null
          }
          tableHeading="Services"
        />
      ) : (
        <BasicTable
          colHeader={header}
          rowData={[]}
          actionFlag={
            deleteStableServicePermission ||
            changeStableServicePermission ||
            isUserType
              ? false
              : true
          }
          // updateHandler={editHandler}
          updateHandler={
            changeStableServicePermission || isUserType ? editHandler : null
          }
          // deleteHandler={deleteHandler}
          deleteHandler={
            deleteStableServicePermission || isUserType ? deleteHandler : null
          }
          // createHandler={openModal}
          createHandler={
            addStableServicePermission || isUserType ? openModal : null
          }
          tableHeading="Services"
        />
      )}

      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll-bar">
          <Button
            sx={{
              color: "#302924",
              border: "0px",
              float: "right",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RxCross2
              style={{ color: "#302924", fontSize: "1.7rem" }}
              onClick={closeModal}
            />
          </Button>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: "#302924",
              marginBottom: "5vh",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            {modalData.id ? "UPDATE SERVICES" : "CREATE SERVICES"}
          </Typography>

          <form onSubmit={(e) => createOrUpdateHandler(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label>FACILITY TYPE</label>
                <Autocomplete
                  name="facility"
                  disablePortal
                  id="combo-box-demo"
                  options={facilityData}
                  getOptionLabel={(option) => option.name}
                  value={
                    modalData &&
                    modalData.facility &&
                    facilityData.find(
                      (item) => item.id === Number(modalData.facility)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "facility")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBlock: "5px",
                  }}
                  renderInput={(params) => <TextField {...params} required />}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>SERVICE TYPE</label>
                <Autocomplete
                  name="service"
                  disablePortal
                  id="combo-box-demo"
                  options={service}
                  getOptionLabel={(option) => option.name}
                  value={
                    modalData &&
                    modalData.facility &&
                    service.find(
                      (item) => item.id === Number(modalData.service)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "service")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBlock: "5px",
                  }}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>PLANNER</label>
                <Autocomplete
                  name="planner"
                  disablePortal
                  id="combo-box-demo"
                  options={plannerData}
                  getOptionLabel={(option) => option.name}
                  value={
                    modalData &&
                    modalData.planner &&
                    plannerData.find(
                      (item) => item.id === Number(modalData.planner)
                    )
                  }
                  onChange={(event, value) =>
                    handleAutoComplete(event, value, "planner")
                  }
                  sx={{
                    color: "#302924",
                    fontWeight: "600",
                    marginBlock: "5px",
                  }}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>PRICE</label>
                <TextField
                  type="text"
                  name="price"
                  onChange={handleModalInputChange}
                  value={modalData.price}
                  placeholder="Enter Price"
                  fullWidth
                  required
                  accept="number"
                  error={ErrorValue}
                  helperText={ErrorValue ? "Please enter a valid Price." : ""}
                  style={{ marginBlock: "5px" }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <label>UNIT OF MEASURMENT</label>
                <TextField
                  style={{ marginBlock: "5px" }}
                  type="text"
                  name="UoM"
                  value="Day"
                  placeholder="Unit of Measurement"
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>

            <Button
              variant="outlined"
              type="submit"
              sx={{
                color: "#fff",
                borderColor: "#302924",
                "&:hover": {
                  borderColor: "#302924",
                  color: "#302924",
                },
                marginTop: "15px",
                float: "right",
                fontSize: "12px",
                backgroundColor: "#302924",
              }}
            >
              {modalData.id ? "UPDATE" : "CREATE"}
            </Button>
          </form>
        </Box>
      </Modal>

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title="Service"
        deleteData={deleteData}
      />

      <ToastContainer/>
    </>
  );
};

export default ServiceList;
