import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { BasicTable } from "../../SharedComponets/Table list/BasicTable";
import { useDispatch, useSelector } from "react-redux";
import { getHorseData } from "../../App/Reducers/HorseRegistrationReducer";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ImgUrl } from "../../Config/Config";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Apis";
import DeleteModal from "../../App/Helpers/DeleteModal";
import ModalComponent from "./ModalComponent";
import { getAllConfirmQuotation } from "../../App/Reducers/QuotationReducer";
import { getDocumentTypeMasterData } from "../../App/Reducers/AdminSettingReducer/DocumentTypeMasterReducer";
import ParseExcel from "../../SharedComponets/Components/excelParser/ParseExcel";
import AlertMessage from "../../App/Helpers/AlertMessage";
import DownloadTemplate from './DownloadTemplate'

function HorseList() {
    const [cookies, setCookies] = useCookies(["token"]);
    const token = cookies.token;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isErrorResponse, setIsErrorResponse] = useState(null);

    const HorseData = useSelector((state) => state.horseRegistration.data);

    const permissions = JSON.parse(localStorage.getItem("userData"));

    const isUserType = permissions?.usertype?.name === "Admin" ? true : false;
    const addHorsePermission = permissions?.user_permissions
        ? permissions.user_permissions.some((obj) => obj.codename === "add_horse")
        : false;
    const changeHorsePermission = permissions?.user_permissions
        ? permissions.user_permissions.some(
            (obj) => obj.codename === "change_horse"
        )
        : false;
    const deleteHorsePermission = permissions?.user_permissions
        ? permissions.user_permissions.some(
            (obj) => obj.codename === "delete_horse"
        )
        : false;

    // Quotation Modal @start
    const contract = useSelector((state) => state.quotation.confirmQuotation);
    const docType = useSelector(
        (state) => state.DocumentTypeMaster.DocumentTypeMasterData
    );
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({
        quotation: null,
        excelData: null,
    });

    function addDocsToUsers(data) {
        data.Users.forEach(user => {
            const matchingDocs = data?.docs && data?.docs.filter(doc => {
                if (doc.horse_id === user.horse_id) {
                    doc.uploaded_file = null
                    delete doc.horse_id
                    return doc
                }
            });
            if (matchingDocs !== undefined && matchingDocs.length > 0) {
                user.docs = matchingDocs;
            }
            else {
                user.docs = [{
                    id: 0,
                    document_type: null,
                    issue_date: null,
                    expiry_date: null,
                    uploaded_file: null
                }]
            }
        });
    }

    const handleFileSelect = async (file) => {
        const data = await ParseExcel(file);
        addDocsToUsers(data);
        setModalData((prevFormData) => ({
            ...prevFormData,
            excelData: [data.Users],
        }));
    };

    const openModal = () => {
        setModalData({
            quotation: null,
        });
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleImport = () => {
        openModal()
    }
    const downloadTemplate = () => {
        DownloadTemplate()
    }


    // Handler for Autocomplete changes
    const handleAutoComplete = (event, value, fieldName, index) => {
        if (index) {

        } else {
            const selectedValue = value ? value.id : null;
            setModalData((prevFormData) => ({
                ...prevFormData,
                [fieldName]: selectedValue,
            }));
        }
    };

    const handleACDocFields = (event, newValue, field, index) => {

        const { sheetName, fieldName, sheetIndex, row_number, column_number, doc_index } = index
        let actualVal = newValue?.id !== undefined ? newValue.id : newValue
        if (field === 'horse_profile' || field === 'health_file' || field === 'vaccine_file') {
            // if (field !== "docs") {
            const PrevmodalData = modalData.excelData[sheetName]

            PrevmodalData[row_number][fieldName] = actualVal

            modalData.excelData[sheetName] = PrevmodalData



            setModalData((prevState) => {
                return {
                    ...prevState,
                    excelData: [PrevmodalData],
                };
            });
        } else {
            if (field === "issue_date" || field === "expiry_date") {
                const [YY, MM, DD] = newValue.split("-");
                actualVal = `${MM}/${DD}/${YY[YY.length - 2]}${YY[YY.length - 1]}`

            }

            const PrevmodalData = modalData.excelData[sheetName]

            PrevmodalData[row_number][fieldName][doc_index][field] = actualVal

            modalData.excelData[sheetName] = PrevmodalData



            setModalData((prevState) => {
                return {
                    ...prevState,
                    excelData: [PrevmodalData],
                };
            });
        }
    };

    const createOrUpdateHandler = () => {
        if (modalData.excelData.length) {

        } else {
            // dispatch(createBreedData({ modalData, token }));
        }
        closeModal();
        // dispatch(getBreedData(token));
    };
    // Quotation Modal @end

    const profileUpdation = (id) => {
        if (id) {
            navigate(`/dashboard/horse/horse-profile/${id}`);
        }
    };

    const shouldUseDefaultProfileUpdation = !HorseData;

    const header = [
        {
            Header: "Horse Id",
            accessor: "code",
        },
        {
            Header: "Profile",
            accessor: "profile",
            Cell: ({ row }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={`${ImgUrl}${row.original.image}`}
                        width={50}
                        height={50}
                        style={{ borderRadius: "50%", cursor: "pointer" }}
                        alt="Horse Profile"
                        onClick={() => profileUpdation(row.original.id)}
                    />
                    <span
                        onClick={() => profileUpdation(row.original.id)}
                        style={{
                            cursor: "pointer",
                            color: "#7c5e1e",
                            fontWeight: "bold",
                            marginLeft: "10px",
                        }}
                        title="View profile"
                    >
                        {row.original.name}
                    </span>
                </div>
            ),
        },

        {
            Header: "Date Of Birth",
            accessor: "dob",
            Cell: ({ value }) => {
                return format(new Date(value), "dd/MM/yyyy");
            },
        },
        {
            Header: "Gender",
            accessor: "gender.name",
        },
        {
            Header: "Birth Origin",
            accessor: "birth_origin.name",
        },
        {
            Header: "Breed",
            accessor: "breed.name",
        },
        {
            Header: "Breeder Master",
            accessor: "breeder_master.name",
        },
        {
            Header: "Color",
            accessor: "color.name",
        },

        {
            Header: "Country of Residence",
            accessor: "country_of_residence.name",
        },
        {
            Header: "Microchip Number",
            accessor: "microchip_number",
        },
        {
            Header: "Height",
            accessor: "height",
        },
        {
            Header: "Weight",
            accessor: "weight",
        },
        {
            Header: "Ability",
            accessor: "ability",
        },
        {
            Header: "Blood Group",
            accessor: "blood_group",
        },
        {
            Header: "Fei Passport Number",
            accessor: "fei_passport_number",
        },
        {
            Header: "Fei Passport Expiry Data",
            accessor: "fei_passport_expiry_date",
        },
        {
            Header: "Fei Registration Number",
            accessor: "fei_registration_number",
        },
        {
            Header: "Owner Name",
            accessor: "owner_id",
            Cell: ({ cell }) => {
                const ownerName = cell.value.map((item) => item.name).join(", ");
                return <span>{ownerName}</span>;
            },
        },
        {
            Header: "Trainer Name",
            accessor: "trainer_id",
            Cell: ({ cell }) => {
                const trainerName = cell.value.map((item) => item.name).join(", ");
                return <span>{trainerName}</span>;
            },
        },
    ];

    const createHandler = () => {
        navigate("/dashboard/horseagreement");
    };

    const updateHandler = (id) => {
        navigate(`/dashboard/horse/registration_form/${id}`);
    };

    const deleteHandler = async (id) => {
        setDeleteId(id);
        setDeleteModalOpen(true);
    };

    const deleteData = async (id) => {
        if (deleteId) {
            try {
                const response = await api.delete(`delete_horse/${deleteId}`, {
                    headers: { Authorization: `token ${token}` },
                });
                if (response.status === "200" || response.statusText === "OK") {
                    setDeleteModalOpen(false);
                    toast.success("Deleted successfully");
                    dispatch(getHorseData(token));
                }
                return response;
            } catch (error) {
                if (error.response.data.Error) {
                    setIsErrorVisible(true);
                    setIsErrorResponse(error.response.data.Error);
                }

                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 6000);
            }
        }
    };


    useEffect(() => {
        dispatch(getHorseData(token))
        dispatch(getAllConfirmQuotation(token));
        dispatch(getDocumentTypeMasterData(token));
    }, [dispatch, token])

    return (
        <>
            <div
                style={{ position: "fixed", top: "50px", right: "20px", zIndex: "999" }}
            >
                <AlertMessage
                    message={isErrorResponse}
                    isVisibila={isErrorVisible}
                    severity="error"
                />
            </div>
            <ToastContainer />
            {HorseData ? (
                <BasicTable
                    profile="Horse Profile"
                    colHeader={header}
                    rowData={HorseData}
                    downloadTemplate={downloadTemplate}
                    handleImport={handleImport}
                    deleteHandler={deleteHandler}
                    profileUpdation={
                        shouldUseDefaultProfileUpdation ? () => true : profileUpdation
                    }
                    updateHandler={updateHandler}
                    createHandler={createHandler}
                    tableHeading="Horse list"
                />
            ) : (
                <BasicTable
                    profile="Horse Profile"
                    colHeader={header}
                    rowData={[]}
                    downloadTemplate={downloadTemplate}
                    handleImport={handleImport}
                    deleteHandler={deleteHandler}
                    profileUpdation={profileUpdation}
                    updateHandler={updateHandler}
                    createHandler={createHandler}
                    tableHeading="Horse list"
                />
            )}
            <DeleteModal
                open={deleteModalOpen}
                handleClose={() => setDeleteModalOpen(false)}
                title="Horse"
                deleteData={deleteData}
            />
            <ModalComponent
                modalOpen={modalOpen}
                handleAutoComplete={handleAutoComplete}
                handleACDocFields={handleACDocFields}
                handleFileSelect={handleFileSelect}
                createOrUpdateHandler={createOrUpdateHandler}
                openModal={openModal}
                closeModal={closeModal}
                modalData={modalData}
                contract={contract}
                docType={docType}
            />
        </>
    );
}

export default HorseList;
