import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ImgUrl } from '../../../Config/Config'
import { useDispatch, useSelector } from 'react-redux';
import { deletePhotoData, getPhotoData } from '../../../App/Reducers/PhotoGallaryReducer';
import { useCookies } from 'react-cookie';
import { photoGallary } from '../../../Assets/DefaultImagesCarousel';

const PhotosModal = (props) => {

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#e0e0e0",
        border: "5px solid #bebb9e",
        borderRadius: "10px",
        boxShadow: 24,
        height: "400px",
        overflow: "auto",
        p: 4,
        "@media (max-width: 576px)": {
            width: "90%",
        },
    };

    const [defaultImages, setDefaultImages] = useState(photoGallary)
    const Images = useSelector((state) => state.photogallary.photoGallary)

    const removeItem = (i) => {
        const updatedImages = [...defaultImages];
        updatedImages.splice(i, 1);
        setDefaultImages(updatedImages);
    };

    const dispatch = useDispatch()
    const [cookies, setCookies] = useCookies(["token"])
    const token = cookies.token;

    const deleteHandler = (id) => {

        dispatch(deletePhotoData({ id, token }))
        dispatch(getPhotoData(token))
        dispatch(getPhotoData(token))
    }

    return (
        <>
            <Modal open={props.modalOpen} onClose={props.handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} className="scroll-bar">
                    <Button
                        variant="outlined" sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, margin: "5px", float: "right", fontSize: "12px", backgroundColor: "#302924", }}
                        onClick={props.handleModalClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="outlined" sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, margin: "5px", float: "right", fontSize: "12px", backgroundColor: "#302924", }}
                        onClick={props.handleSubmit}
                    >
                        Save
                    </Button>
                    <Button variant="outlined" sx={{ color: "#302924", borderColor: "#bebb9e", "&:hover": { borderColor: "#302924 ", color: "black", }, margin: "5px", float: "right", fontSize: "12px" }}>
                        <input type="file" name="images" onChange={props.handleImageChange} required />
                    </Button>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ color: "#302924", marginBottom: "5vh", fontWeight: "600" }}
                    >
                        EDIT IMAGES
                    </Typography>

                    <div className="img-list-div">
                        <div className="img-list" >

                            {Images && Images.length > 0 ?
                                (Images.length > 0 && Images.map((item, i) => (
                                    <div className="img-list-left">
                                        <div className="img-list-with-remove">
                                            <img src={`${ImgUrl}${item.image}`} alt={item.image && item.id} />
                                            <Button variant="outlined" onClick={() => deleteHandler(item.id)} sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, fontSize: "12px", backgroundColor: "#302924", }}>Remove</Button>
                                        </div>
                                    </div>
                                ))) :
                                (
                                    defaultImages.length > 0 && defaultImages.map((item, i) => (
                                        <div className="img-list-left">
                                            <div className="img-list-with-remove">
                                                <img src={item.image} alt={item.id} />
                                                <Button variant="outlined" onClick={() => removeItem(i)} sx={{ color: "#fff", borderColor: "#302924", "&:hover": { borderColor: "#302924", color: "#302924", }, fontSize: "12px", backgroundColor: "#302924", }}>Remove</Button>
                                            </div>
                                        </div>
                                    ))
                                )
                            }


                        </div>
                    </div>
                </Box>
            </Modal >
        </>
    )
}

export default PhotosModal