import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from "react-responsive-carousel";
import "./Display_Profile.css";

const Display_profile = (props) => {
  return (
    <Carousel
      showThumbs={false} // Hides the thumbnail navigation
      showIndicators={false} // Hides the slide indicators
      infiniteLoop={true} // Enables infinite loop of slides
      autoPlay={true} // Automatically advances the slides
      interval={1000} // Interval between slides (in milliseconds)
    >

      {props.imgSrc.map((item,index) => {
        return(
        <div key={index}>
        <img
          src={item.imgSrc}
          alt="Slide 2"
        />
      </div>
        )
      })}
    </Carousel>
  );
};

export default Display_profile;
