import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Apis";


export const getServiceTypeData = createAsyncThunk("getServiceTypeData", async (token) => {

  try {
    const response = await api.get("get_all_service_type", {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  } catch (error) {

    throw error;
  }
});

export const createData = createAsyncThunk("createData", async (payload) => {
  console.log("payload", payload)
  try {
    const response = await api.post("create_service_type", payload.modalData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteData = createAsyncThunk("deleteData", async ({ id, token }) => {
  try {
    const response = await api.delete(`delete_service_type/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updateData = createAsyncThunk("updateData", async (payload) => {

  try {
    const response = await api.put(`update_service_type/${payload.id}`, payload.updatedData, {
      headers: {
        "Content-Type": "multipart/form-data, application/json",
        Authorization: `token ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getUpdateData = createAsyncThunk(
  "getUpdateData",
  async (id, token) => {
    try {
      const response = await api.get(`get_service_type/${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const ServiceTypeSlice = createSlice({
  name: "services",
  initialState: {
    serviceTypeData: null,
    update: null,
    status: "",
    message: null,
    updateData: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceTypeData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getServiceTypeData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.serviceTypeData = action.payload;
    });
    builder.addCase(getServiceTypeData.rejected, (state, action) => {
      console.log("action",action);
      state.status = "failed";
      state.message = action.error.message
      state.serviceTypeData = [];
    });
    builder.addCase(createData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createData.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(createData.rejected, (state, action) => {
      state.status = "failed";
      state.status = "failed";
    });
    builder.addCase(deleteData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(deleteData.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(deleteData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(getUpdateData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getUpdateData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateData = action.payload;
    });
    builder.addCase(getUpdateData.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(updateData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateData.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.updateData = action.payload
    });
    builder.addCase(updateData.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default ServiceTypeSlice.reducer;
