import Api from "../ApiConfig";
import StudentApi from "../StudentApiConfig";

const LoginApi = {
  studentLogin: (payload) => Api.post(`student_otp_generate`, payload),
  studentLogin_OTPVerify: (payload) => Api.post(`student_otp_verify`, payload),

  studentLoginOtp: (payload) => Api.post(`student_login_otp`, payload),
  studentLogin_OTP__Verify: (payload) =>
    Api.post(`student_login_verify`, payload),
  studentLogOut: (id, payload) =>
    StudentApi.post(`student_logout/${id}`, payload),
};

export default LoginApi;
