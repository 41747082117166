import { useEffect, useState } from 'react';
import "./ToggleButton.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"
import Display_profile from './Display_profile';
import { useDispatch, useSelector } from 'react-redux';
import { getHorseData, getUpdateHorseData } from '../../App/Reducers/HorseRegistrationReducer';
import { useCookies } from 'react-cookie';
import { Grid } from '@mui/material';
import { ImgUrl } from '../../Config/Config';
import TopBar from '../../SharedComponets/Dashboard/TopBar/TopBar';


const ToggleButton = ({ horse }) => {
  const dummyImg = [{
    imgSrc: "https://images.pexels.com/photos/793235/pexels-photo-793235.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  },
  {
    imgSrc: "https://images.pexels.com/photos/1203309/pexels-photo-1203309.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  },
  {
    imgSrc: "https://images.pexels.com/photos/1462369/pexels-photo-1462369.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  },
  {
    imgSrc: "https://images.pexels.com/photos/3854867/pexels-photo-3854867.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  }
  ]

  const [expandedButton, setExpandedButton] = useState(null);
  const dispatch = useDispatch()
  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;
  const HorseData = useSelector((state) => state.horseRegistration.data)


  const handleButtonClick = (buttonNumber) => {
    setExpandedButton((prevButton) =>
      prevButton === buttonNumber ? null : buttonNumber
    );
  };

  const getButtonIcon = (buttonNumber) => {
    if (isButtonExpanded(buttonNumber)) {
      return <AiOutlineMinus />;
    } else {
      return <AiOutlinePlus />;
    }
  };

  const isButtonExpanded = (buttonNumber) => {
    return expandedButton === buttonNumber;
  };

  const getContent = (buttonNumber) => {
    switch (buttonNumber) {
      case 1:
        return (

          <div
            style={{ display: "flex", justifyContent: "space-evenly", marginBlock: "30px" }}
            className="mainContainerOfHorseProfile horse_details"
          >
            <div className='horse_data'>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>Name :</strong> {HorseData[5].name}</p>
                  <p><strong>Color :</strong> {HorseData[5].color}</p>



                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>Country of Birth :</strong> {HorseData[5].countryOfBirth}</p>
                  <p><strong>Date of Birth :</strong> {HorseData[5].dateOfBirth}</p>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>Breed :</strong> {HorseData[5].breed}</p>
                  <p><strong>Breeder :</strong> {HorseData[5].breeder}</p>

                </Grid>
                <Grid item xs={12} sm={4} md={4}>


                  <p><strong>Country of Resident :</strong> {HorseData[5].countryOfResident}</p>
                  <p><strong>Microchip Number :</strong> {HorseData[5].microchipNumber}</p>

                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>Gender :</strong> {HorseData[5].gender}</p>
                  <p><strong>Blood Group :</strong> {HorseData[5].bloodGroup}</p>
                  <p><strong>Special Ability :</strong> {HorseData[5].specialAbility}</p>

                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>Height :</strong> {HorseData[5].height}</p>
                  <p><strong>Weight :</strong> {HorseData[5].weight}</p>
                

                </Grid>
              </Grid>
            </div>

          </div>
        );
      case 2:
        return (
          <div
            style={{ display: "flex", justifyContent: "space-evenly" }}
            className="mainContainerOfHorseProfile horse_details"
          >

            <div style={{ width: "80%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>FEI Registration Number :</strong> {HorseData[5].fei_registration_number}</p>

                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>FEI Passport Number :</strong> {HorseData[5].fei_passport_number}</p>

                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>FEI Expiry Date :</strong> {HorseData[5].fei_passport_expiry_date}</p>

                </Grid>
              </Grid>
            </div>
          </div>
        );
      case 3:
        return (
          <div
            style={{ display: "flex", justifyContent: "space-evenly" }}
            className="mainContainerOfHorseProfile horse_details"
          >
            <div style={{ width: "80%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>Owner Name :</strong> {HorseData[5].owner_id}</p>

                </Grid>
              </Grid>
            </div>
          </div>
        );
      case 4:
        return (
          <div
            style={{ display: "flex", justifyContent: "space-evenly" }}
            className="mainContainerOfHorseProfile horse_details"
          >
            <div style={{ width: "80%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <p><strong>Trainer Name :</strong> {HorseData[5].trainer_id}</p>

                </Grid>
              </Grid>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(getHorseData(token))
    // dispatch(getUpdateHorseData({id, token}))
    if (HorseData) {
      console.log("horse data", HorseData);
      // console.log("jjjj",HorseDataid);
    }
  }, [])
  return (
    <>
      <TopBar />
      <Display_profile imgSrc={dummyImg} />
      <div>
        <div style={{ display: "flex", justifyContent: "center", gap: "25px", alignItems: "center" }}>
          <img src={`${ImgUrl}${HorseData[5].image}`} alt="Horse Profile" style={{ width: "4rem", height: "4rem", display: "inline-block", borderRadius: "50%" }} />
          <p style={{ display: "inline-block", fontWeight: "bolder" }}>Welcome to the extraordinary world of <span style={{ fontSize: "30px", color: "#302924" }}>{HorseData[5].name} </span></p>

        </div>
        <div className="card-detail">
        </div>
      </div>
      <div className="ContainerOfHorseProfile" style={{ display: "flex", marginBlock: "30px" }}>

        <div className="buttons" >
          <button onClick={() => handleButtonClick(1)}>
            <b style={{ width: '100%', color: "#302924" }}>Horse Information <b style={{ float: 'right' }}>{getButtonIcon(1)}</b></b>
            {/* <FontAwesomeIcon icon="fa-solid fa-plus" /> */}

          </button>
          <div
            className={
              isButtonExpanded(1) ? "expanded-content" : "collapsed-content"
            }
          >
            {isButtonExpanded(1) && getContent(1)}
          </div>
          <button onClick={() => handleButtonClick(2)}>
            <b style={{ width: '100%', color: "#302924" }}>FEI Registration <b style={{ float: 'right' }}>{getButtonIcon(2)}</b></b>
          </button>
          <div
            className={
              isButtonExpanded(2) ? "expanded-content" : "collapsed-content"
            }
          >
            {isButtonExpanded(2) && getContent(2)}
          </div>
          <button onClick={() => handleButtonClick(3)}>
            <b style={{ width: '100%', color: "#302924" }}>Owner Information <b style={{ float: 'right' }}>{getButtonIcon(3)}</b></b>
          </button>
          <div
            className={
              isButtonExpanded(3) ? "expanded-content" : "collapsed-content"
            }
          >
            {isButtonExpanded(3) && getContent(3)}
          </div>
          <button onClick={() => handleButtonClick(4)}>
            <b style={{ width: '100%', color: "#302924" }}>Trainer Information <b style={{ float: 'right' }}>{getButtonIcon(4)}</b></b>
          </button>
          <div
            className={
              isButtonExpanded(4) ? "expanded-content" : "collapsed-content"
            }
          >
            {isButtonExpanded(4) && getContent(4)}
          </div>
        </div>
      </div>
    </>

  );
};

export default ToggleButton;
