
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import {   videoGallary } from '../../../Assets/DefaultImagesCarousel'
import VideoGallary from '../../../SharedComponets/VideoGallary'
import VideosModal from './VideosModal'
import { createVideoData, getVideoData } from '../../../App/Reducers/VideoGallaryReducer'

const Video = () => {




  const [formData, setFormData] = useState({
    id: null,
    image: null
  })
  const dispatch = useDispatch()
  const [cookies, setCookies] = useCookies(["token"])
  const token = cookies.token;

  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleImageChange = (e) => {
    const { files } = e.target;

    if (files) {
      const selectedFile = files[0];
      setFormData((previous) => ({
        ...previous,
        image: selectedFile,
      }));

    }

  }

  const handleSubmit = (e) => {
    if (formData) {
      dispatch(createVideoData({ formData, token }))
      dispatch(getVideoData(token))
    }
    dispatch(getVideoData(token))
  };

  useEffect(() => {
    dispatch(getVideoData(token))
  }, [])

  return (
    <>
      <VideoGallary formData={formData} handleModalOpen={handleModalOpen} />
      <VideosModal formData={formData}  handleModalOpen={handleModalOpen} handleModalClose={handleModalClose} modalOpen={modalOpen}  handleImageChange={handleImageChange} handleSubmit = {handleSubmit}/>
    </>
  )
}

export default Video


