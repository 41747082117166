import "./Assets/styles.css";
import "./App.css";
import RoutesComponent from "./Routes/Routes";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import store from "./Store/Store";

function App() {
  return (
    <>
      <CookiesProvider>
        <Provider store={store}>
          <RoutesComponent />
        </Provider>
      </CookiesProvider>
    </>
  );
}

export default App;
