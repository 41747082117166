import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ImgUrl } from "../../../Config/Config";
import { AiOutlineCaretRight } from "react-icons/ai";
import { FaFilePdf } from "react-icons/fa";
import { getUpdateTrainerData } from "../../../App/Reducers/OwnerReducer";
import { GetUpdatingTrainer } from "../../../App/Reducers/TrainerReducer";
import AOS from "aos";
import "aos/dist/aos.css";
import StableLoading from "../../../SharedComponets/Loading/StableLoading";

const TrainerProfile = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const token = cookies.token;

  const { tProfileId } = useParams();
  const dispatch = useDispatch();

  const TrainerData = useSelector((state) => state.trainers.UpdatingTrainer);
  console.log("TrainerData", TrainerData);
  const [activeLink, setActiveLink] = useState("Documents");

  useEffect(() => {
    // dispatch(getUpdateTrainerData({ id: tProfileId, token }));
    dispatch(GetUpdatingTrainer({ id: tProfileId, token }));
    AOS.init();
  }, []);

  return TrainerData ? (
    <>
      <div className="form-wrapper-container">
        <div data-aos="fade-left" data-aos-duration="1000">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              alignItems: "center",
              paddingBlock: "30px 10px",
            }}
            className="welcome-area"
          >
            <img
              src={`${ImgUrl}${TrainerData && TrainerData.image}`}
              alt="Owner Profile"
              style={{
                width: "5.5rem",
                height: "5.5rem",
                display: "inline-block",
                borderRadius: "50%",
              }}
            />
            <p style={{ display: "inline-block", fontWeight: "bolder" }}>
              <span style={{ fontSize: "18px" }}> </span>Step into the enticing
              profile of{" "}
              <span style={{ fontSize: "40px", color: "#302924" }}>
                {" "}
                {TrainerData ? TrainerData.name : "Owner Name"}{" "}
              </span>
            </p>
          </div>
          {/* <div style={{display:"flex", alignItems: "start", width:"100%"}}> */}
          <div className="horse-profile-container">
            <div className="profile-details-container">
              <div className="profile-details-detail">
                <div className="profile-common-layout horse-info" id="h-info">
                  <a href="#h-info" id="h-info" className="bookmark">
                    <h3>Registration Information</h3>
                  </a>
                </div>
                <div className="profile-ditails-single-data-container">
                  <div className="profile-image-side-div-top">
                    <img
                      src={`${ImgUrl}${TrainerData && TrainerData.image}`}
                      alt="Tainer Profile"
                      className="profile-image-side-top"
                    />
                  </div>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;National Id&nbsp;:&nbsp;
                      </span>
                      <span>{TrainerData.national_id}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Visa Category&nbsp;:&nbsp;
                      </span>
                      <span>{TrainerData.visa_category.name}</span>
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        <AiOutlineCaretRight />
                        &nbsp;Discipline&nbsp;:&nbsp;
                      </span>
                      <span>{TrainerData.division.name}</span>
                    </li>
                  </ul>
                </div>
                <div className="data-image-container">
                  <div>
                    <div className="profile-common-layout fei-reg" id="fei-reg">
                      <a href="#fei-reg" id="fei-reg" className="bookmark">
                        <h3>Trainer Information</h3>
                      </a>
                    </div>
                    <div className="profile-ditails-single-data-container">
                      <ul style={{ listStyleType: "none" }}>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Name&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.name}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Gender&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.gender}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Date of Birth&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.date_of_birth}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Nationality&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.nationality.name}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;City&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.city}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Address&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.address}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Post Box&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.post_box}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Country&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.country.name}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Blood Group&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.blood_group}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Achievements&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.achievements}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Training Experience&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.training_experience}</span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="profile-common-layout owner-info"
                      id="owner-info"
                    >
                      <a
                        href="#owner-info"
                        id="owner-info"
                        className="bookmark"
                      >
                        <h3>Trainer Contact Information</h3>
                      </a>
                    </div>
                    <div className="profile-ditails-single-data-container">
                      <ul style={{ listStyleType: "none" }}>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Email&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.email}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;ISD Code&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.isd_code.country}</span>
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            <AiOutlineCaretRight />
                            &nbsp;Mobile No&nbsp;:&nbsp;
                          </span>
                          <span>{TrainerData.mobile}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className='profile-image-side-div'>
                                    <Link to={`${ImgUrl}${TrainerData.image}`} download target="_blank">  <img src={`${ImgUrl}${TrainerData && TrainerData.image}`} alt="Trainer Profile" className='profile-image-side' /></Link>
                                </div> */}
                <div className="profile-common-layout doc-info" id="doc-info">
                  <a href="#doc-info" id="doc-info" className="bookmark">
                    <h3>Documents</h3>
                  </a>
                </div>

                <div style={{ width: "100%" }}>
                  <table style={{ width: "90%", margin: "30px 50px" }}>
                    <thead>
                      <tr
                        className="tableHeader  table-list"
                        style={{ textAlign: "center", color: "white" }}
                      >
                        <th>Document Name</th>
                        <th>Issue Date</th>
                        <th>Expiry Date</th>
                        <th>Uploaded File</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TrainerData && TrainerData.files
                        ? TrainerData.files.map((file) => (
                            <tr className="table-list">
                              <td>{file.document_type.name}</td>
                              <td>{file.issue_date}</td>
                              <td
                                style={{
                                  color:
                                    new Date(file.expiry_date) < new Date()
                                      ? "#ff0000"
                                      : "black",
                                }}
                              >
                                {file.expiry_date}&nbsp;
                                {new Date(file.expiry_date) < new Date()
                                  ? "Expired"
                                  : null}
                              </td>
                              <td>
                                <Link
                                  to={`${ImgUrl}${file.uploaded_file}`}
                                  download
                                  target="_blank"
                                  style={{ display: "inline-block" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      border: "1px solid  #9f8754",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "1.3rem",
                                        color: "white",
                                        background:
                                          "linear-gradient(to bottom, #9f8754 0, #72520e 100%)",
                                        borderRadiusTopleft: "2px",
                                        borderRadiusBottomleft: "2px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "36px",
                                        height: "36px",
                                      }}
                                    >
                                      <FaFilePdf />
                                    </div>
                                    <div
                                      style={{
                                        padding: "0px 10px",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      {
                                        file.uploaded_file.split(
                                          "/media/trainer_file/"
                                        )[1]
                                      }
                                    </div>
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <StableLoading />
  );
};

export default TrainerProfile;
