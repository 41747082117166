import axios from "axios";
import { baseURL } from "../../../Config/Config";

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      config.headers["Authorization"] = `token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (!error.response) {
      // handle error
    } else if (error.response.status === 403) {
      // handle error
    } else if (error.response.status === 401) {
      // handle error
    } else {
      // handle error
    }
    return Promise.reject(error);
  }
);

export default instance;
