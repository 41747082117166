import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Apis";

export const getStallData = createAsyncThunk("getStallData", async (token) => {
  try {
    console.log(token, "jhgkjghghghghj");
    const t = "fa9915cea178d2a857b9cf58cd5b589fafe2950d";
    const res = await api.get("get_all_stall", {
      headers: {
        Authorization: `token ${token}`,
        // Authorization: `token ${t}`,
      },
    });
    const data = res.data;

    return data;
  } catch (error) {
    console.log(`You got an error ${error}`);
  }
});
export const deleteStallData = createAsyncThunk(
  "deleteCheckInData",
  async (id) => {
    try {
      const res = await api.delete(
        `http://127.0.0.1:8000/api/delete_checkin/${id}`
      );
      const data = res.data;

      return data;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);
export const createStallData = createAsyncThunk(
  "createStallData",
  async (payload) => {
    try {
      console.log(
        "createStallData---------------================++++++++++++++++",
        payload
      );
      const t = "fa9915cea178d2a857b9cf58cd5b589fafe2950d";
      const res = await api.post("create_stall", payload.data, {
        headers: {
          "Content-Type": "multipart/form-data,application/json",
          Authorization: `token ${payload.token}`,
          // Authorization: `token ${t}`,
        },
      });
      const ApiData = res.data;
      console.log("Create response=---------------", ApiData);
      return ApiData;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);
export const getStallDataID = createAsyncThunk(
  "getCheckInDataID",
  async (payload) => {
    try {
      const res = await api.get(`get_stall/${payload.id}`, {
        headers: {
          Authorization: `token ${payload.token}`,
        },
      });
      const ApiData = res.data;
      console.log(ApiData, "Get data from Async func");
      return ApiData;
    } catch (error) {
      console.log(`You got an error ${error}`);
    }
  }
);
// export const getAllHorse = createAsyncThunk("getAllHorse", async () => {
//   try {
//     const res = await api.get("http://192.168.1.42:8000/api/get_all_horses");
//     const ApiData = res.data;
//     console.log(ApiData, "Horse Data===========");
//     return ApiData;
//   } catch (error) {
//     console.log(`You got into error : ${error}`);
//   }
// });
// export const getAllStaff = createAsyncThunk("getAllStaff", async () => {
//   try {
//     const res = await api.get("http://192.168.1.42:8000/api/get_all_users");
//     const ApiData = res.data;
//     console.log(ApiData, "Staff Data===========");
//     return ApiData;
//   } catch (error) {
//     console.log(`You got into error : ${error}`);
//   }
// });

export const updateStallData = createAsyncThunk(
  "updateCheckInData",
  async (updatedData) => {
    try {
      console.log(updatedData);
      const res = await api.put(
        `update_stall/${updatedData.id}`,
        updatedData.UpdatedData,
        {
          headers: {
            "Content-Type": "multipart/form-data,application/json",
            Authorization: `token ${updatedData.token}`,
          },
        }
      );
      const ApiData = res.data;
      console.log(ApiData, "Updated Api data");

      return ApiData;
    } catch (error) {
      console.log(`You got an Error : ${error}`);
    }
  }
);
const initialState = {
  stallData: null,
  getDataById: null,
  temp: "temp",

  status: {
    Get: "",
    Create: "",
    Updating: "",
    Update: "",
    Delete: "",
  },
  errors: {
    Get: "",
    Create: "",
    Update: "",
    Delete: "",
  },
};

const StallSlice = createSlice({
  name: "stall",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getStallData.pending, (state) => {
      state.status.Get = "loading";
    });
    builder.addCase(getStallData.fulfilled, (state, action) => {
      state.status.Get = "succeed";
      state.stallData = action.payload;
    });
    builder.addCase(getStallData.rejected, (state) => {
      state.status.Get = "failed";
    });

    builder.addCase(deleteStallData.pending, (state) => {
      state.status.Delete = "loading";
    });
    builder.addCase(deleteStallData.fulfilled, (state, action) => {
      state.status.Delete = "succeed";
    });
    builder.addCase(deleteStallData.rejected, (state) => {
      state.status.Delete = "failed";
    });

    builder.addCase(createStallData.pending, (state) => {
      state.status.Create = "loading";
    });
    builder.addCase(createStallData.fulfilled, (state, action) => {
      state.status.Create = "succeed";
      console.log(action.payload);
    });
    builder.addCase(createStallData.rejected, (state) => {
      state.status.Create = "failed";
    });

    builder.addCase(updateStallData.pending, (state) => {
      state.status.Update = "loading";
    });
    builder.addCase(updateStallData.fulfilled, (state, action) => {
      state.status.Update = "succeed";
      console.log(action.payload);
    });
    builder.addCase(updateStallData.rejected, (state) => {
      state.status.Update = "failed";
    });

    builder.addCase(getStallDataID.pending, (state) => {
      state.status.Updating = "loading";
    });
    builder.addCase(getStallDataID.fulfilled, (state, action) => {
      state.status.Updating = "succeed";
      state.getDataById = action.payload;
      console.log(action.payload, "Get obj by data-----------");
    });
    builder.addCase(getStallDataID.rejected, (state) => {
      state.status.Updating = "failed";
    });

    // // builder.addCase(getAllHorse.pending, (state) => {
    // //   state.status.Updating = "loading";
    // // });
    // builder.addCase(getAllHorse.fulfilled, (state, action) => {
    //   // state.status.Updating = "succeed";
    //   state.DropDownData.HorseData = action.payload;
    //   console.log(action.payload, "Horse data for dropdown-----------");
    // });
    // // builder.addCase(getAllHorse.rejected, (state) => {
    // //   state.status.Updating = "failed";
    // // });

    // // builder.addCase(getAllHorse.pending, (state) => {
    // //   state.status.Updating = "loading";
    // // });
    // builder.addCase(getAllStaff.fulfilled, (state, action) => {
    //   // state.status.Updating = "succeed";
    //   state.DropDownData.StaffData = action.payload;
    //   console.log(action.payload, "Horse data for dropdown-----------");
    // });
    // // builder.addCase(getAllHorse.rejected, (state) => {
    //   state.status.Updating = "failed";
    // });
  },
});
export default StallSlice.reducer;
