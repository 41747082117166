import React from "react";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import "./TrainerAgreement.css";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const TrainerAgreement = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <section
        className="trainerAgreeSection"
        data-aos="flip-down"
        data-aos-duration="1000"
      >
        <header>
          <div className="registration_top_header">
            <p>Trainer Registration</p>
            <span>
              Please fill in all required fields, attach/upload all supporting
              documents to proceed with the registration.
            </span>
          </div>
        </header>
        <body>
          <div className="tAgreeBody">
            <div className="tAgreeHead">
              <h4>CRITERIA TO OBTAIN AN ENDURANCE TRAINER LICENSE</h4>
            </div>
            <div className="tAgreeUl">
              <ol>
                <li>Minimum age of applicant should be 21 years.</li>
                <li>
                  The applicant should have a good experience in endurance with
                  a knowledge of national and international endurance rules;
                  preference will be given to endurance riders.
                </li>
                <li>
                  A no objection letter / letter of consent from the applicant's
                  stable addressed to the UAE Equestrian and Racing Federation
                  (EQI5).
                </li>
                <li>
                  An Experience Certificate from an active trainer licensed by
                  the EQI5 valid for more than two seasons with a participation
                  in a minimum of ten endurance rides in the two-years period.
                  The certificate issued should state that the applicant has
                  worked as an assistant trainer for a period of not less than
                  one year from the date of submitting the application.
                </li>
                <li>
                  A trainer providing the experience letter can issue only one
                  certificate during the entire endurance season except the
                  experienced trainers who can issue two certificates if they
                  have more than 25 horses.
                </li>
                <li>
                  The applicant should have a minimum of five endurance horses
                  under his training registered with EQI5 endurance section,
                  with a declaration in writing. In case the horses are not
                  under the applicants trainin, approval must be obtained from
                  the owner of the horses.
                </li>
                <li>
                  The applicant from any foreign nation must provide a No
                  Objection Letter from the Federation of his country of origin
                  stating that his/her National Federation has no objection for
                  training in the UAE.
                </li>
                <li>
                  An Electronic online application with the following
                  attachments:
                </li>
                <ul>
                  <li>A valid passport copy</li>
                  <li>Valid Emirates ID Copy</li>
                  <li>Photograph</li>
                  <li>VISA page in case of foreign nationals</li>
                  <li>FEI Registration number, if any</li>
                  <li>All the above to be submitted to the EQI5</li>
                </ul>
                <li>
                  The applicant must obtain a minimum of 70% in the theoretical
                  examination.
                </li>
                <li>
                  If the applicant fails the theoretical examinationon the first
                  attempt, he/she will be allowed a second attempt after a
                  period of not less than two weeks. Maximum three attempts will
                  be allowed in one season.
                </li>
                <li>
                  A payment of AED 1000/- to be paid as trainer license fee,
                  after successful completion of the examination.
                </li>
                <li>
                  Submitting the application or signing the examination paper is
                  considered as an implicit approval of all conditions and laws.
                </li>
                <li>
                  EQI5 reserves the right to issue, suspend or revoke the
                  license of any trainer for any reason according to the local
                  and international regulations, regulations and law of
                  endurance and other laws in the country.
                </li>
              </ol>
            </div>
          </div>
        </body>
        <footer>
          <div className="tAgreeFoot">
            <Button
              variant="contained"
              className="btn-bgColor"
              endIcon={<SendIcon />}
              onClick={() => {
                navigate("/riding-club/trainer/registration_form");
              }}
            >
              I agree, Continue Registration
            </Button>
          </div>
        </footer>
      </section>
    </>
  );
};

export default TrainerAgreement;
