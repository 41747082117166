import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  EventForm: false,
  create: false,
  update: false,
  calDate: null,
  currentMonth: null,
  mobileNavbar: false,

};

const ModalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    EventFormStatus: (state, action) => {
      state.EventForm = action.payload.open
      state.create = action.payload?.create
      state.update = action.payload?.update
      state.calDate = action.payload?.calDate
      state.currentMonth = action.payload?.currentMonth
    },
    ShowMobileNavbar: (state, action) => {
      console.log(action.payload)
      state.mobileNavbar = !state.mobileNavbar
      // state.mobileNavbar = action.payload
    }
  },

})


export const { EventFormStatus, ShowMobileNavbar } = ModalSlice.actions;
export default ModalSlice.reducer;
