import React, { useEffect, useState } from "react";
import CourseCard from "../../../components/CourseCard/CourseCard";
import CourseApi from "../../../../App/Apis/RidingSchoolApis/courseApi/course.js";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader.jsx";

function Course() {
  let studentId = JSON.parse(localStorage.getItem("student_data"))?.id;
  const { id } = useParams();

  console.log("id ===>", id);
  studentId = studentId !== undefined ? studentId : id;
  console.log("StudentId ===>", studentId);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const prefilled = async () => {
    setIsLoading(true);
    try {
      const EnrolledCourseRes = await CourseApi.getEnrolledCourse(studentId);
      setData(EnrolledCourseRes.data);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    prefilled();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          padding: "2rem",
          marginLeft: "2rem",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "1.3rem",
        }}
      >
        {data[0] ? (
          data?.map((item, index) => (
            <CourseCard itemData={item} index={index} studentId={studentId} />
          ))
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "2rem" }}>
            No Course Purchased yet
          </h2>
        )}
      </div>
    </>
  );
}

export default Course;
