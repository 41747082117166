import React, { useEffect, useState } from "react";
import Calender from "../../../components/calendar/Calendar";
import courses from "../../../../App/Apis/RidingSchoolApis/courseApi/course";
import moment from "moment";

function StudentEvent() {
  const studentId = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))?.id
    : null;
  const [events, setEvents] = useState();
  // const events = [
  //     { title: 'Meeting', start: new Date() }
  // ]
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  console.log("month", month);
  console.log("year", year);
  const getEvents = async () => {
    try {
      const res = await courses.getStudentEvents({
        month: month + 1,
        year,
        student: studentId,
      });
      console.log("res", res.data);
      const eventData = res.data.map((item) => {
        return {
          title: JSON.stringify({
            Lesson: item.lesson_no,
            course: item.course.course_name,
          }),
          start: new Date(item.schedule_date + " " + item.slot.time),
        };
        // return {
        //     id: item.id ? item.id : null,
        //     title: item.lesson_no ? `Lesson : ${item.lesson_no}` : null,
        //     start: item.schedule_date
        //         ? moment.utc(item.schedule_date).format("YYYY-MM-DD")
        //         : null,

        // }
      });
      setEvents(eventData);
      // console.log(eventData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEvents();
  }, []);
  return (
    <div>
      <div className="student-event" style={{ padding: "2rem" }}>
        <Calender Heading="Student Calender" Events={events} />
      </div>
    </div>
  );
}

export default StudentEvent;
